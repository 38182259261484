import Modal from "react-modal";
import { useState, useEffect } from "react";
import { VaultFolderType } from "../../types/vaultTypes";
import MainButton from "../../components/buttons/mainButton";
import SearchIcon from "../../assets/svg/search.svg";
import { useVault } from "./VaultProvider";
import { useMoveVaultItemMutation } from "../../api/vault/vault-mutations";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";

export const VaultMoveDocumentModal = ({
  modalIsOpen,
  folders,
  closeModal,
}: {
  modalIsOpen: boolean;
  folders: VaultFolderType[];
  closeModal: () => void;
  customStyles?: any;
}) => {
  const [showBlock, setShowBlock] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState("");
  const [boxText, setBoxText] = useState("-Select a folder-");
  const [searchQuery, setSearchQuery] = useState("");
  const [allFolders, setAllFolders] = useState<VaultFolderType[]>([]);
  const { currentFolder } = useVault();

  useEffect(() => {
    let foldersCopy = folders.slice(1).map((folder: VaultFolderType) => folder);
    let mainVaultCopy = { ...folders[0], name: `Main Vault` };
    foldersCopy.push(mainVaultCopy);
    setAllFolders(foldersCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredFolders: any[] = allFolders?.filter(
    (folder: any) =>
      (folder.name.toLowerCase().includes(searchQuery.toLocaleLowerCase()) && folder.id !== currentFolder?.id) ||
      (folder.name.toLowerCase().includes(searchQuery.toLocaleLowerCase()) && folder.id === folders[0].id)
  );

  const handleSearchQuery = (e: any) => {
    setSearchQuery(e.target.value);
  };

  const handleSelectFolder = (e: any, id: any) => {
    setBoxText(e.target.outerText);
    setSelectedFolderId(id);
    setShowBlock(false);
  };

  const { currentMovingItem, setCurrentMovingItem } = useVault();
  const { mutateAsync: moveVaultItem } = useMoveVaultItemMutation();

  const handleMoveVaultItem = async (folderId: string) => {
    try {
      if (!currentMovingItem?.documentId || !currentMovingItem?.type) return;

      await moveVaultItem({
        id: currentMovingItem?.documentId,
        type: currentMovingItem?.type,
        vault_folder_id: folderId,
      });

      setCurrentMovingItem(null);
    } catch (error) {
      console.error("fail: handleMoveVaultItem", error);
    }
  };

  const isNative = Capacitor.isNativePlatform();

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Move Vault Document Modal"
      className={classNames("confirmationModal relative", isNative && "native-modal")}
      overlayClassName={"modal-container-overlay"}
    >
      <div className="flex justify-end">
        <button type="button" onClick={closeModal} className="cursor-pointer">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>
      <div className={`text-content`}>
        <h3 className="text-type text-h4">Move to Folder</h3>
      </div>
      <form className="mt-6 pb-10">
        <div className="mb-5 text-type  w-full flex-col flex justify-center items-start">
          <label className=" mb-1 text-p2">Folder Name</label>
          <div
            onClick={() => setShowBlock(true)}
            className="bg-neutral-300 p-3 cursor-pointer text-p2 border border-neutral-200 w-full rounded flex justify-between items-center"
          >
            {boxText}
            <ChevronDownIcon className="h-5 w-5" />
          </div>
        </div>

        <div
          className={`bg-white w-full rounded-md shadow-md flex flex-col justify-start items-start mb-7 transform transition overflow-hidden p-0 duration-500 ${
            showBlock ? "p-2 h-full opacity-1 translate-y-0" : "opacity-0 -translate-y-8 p-0 h-0"
          }`}
        >
          <div className="bg-neutral-300 p-3 border border-neutral-200 w-full rounded-md flex justify-between items-center">
            <input
              type="text"
              className="bg-transparent text-type-100 text-p2 p-0 border-0 w-full focus:border-0 focus:outline-none focus:ring-0"
              placeholder="Search folders"
              onChange={(e: any) => handleSearchQuery(e)}
              value={searchQuery}
            />
            <img src={SearchIcon} alt="search" className="cursor-pointer" />
          </div>
          <div className="flex flex-col justify-start items-start w-full max-h-36 overflow-y-auto">
            {filteredFolders.length > 0 ? (
              filteredFolders?.map((folder: any) => (
                <p
                  key={folder.id}
                  onClick={(e) => handleSelectFolder(e, folder.id)}
                  className="p-2 text-p2 cursor-pointer transition hover:bg-primary-beau-hover focus:bg-primary-beau-hover rounded-md my-1 w-full"
                >
                  {folder.name}
                </p>
              ))
            ) : (
              <p className="p-2 text-p2 cursor-pointer text-center transition hover:bg-primary-beau-hover focus:bg-primary-beau-hover rounded-md my-1 w-full">
                Oops. We can't find that folder.
              </p>
            )}
          </div>
        </div>
      </form>
      <div className="asset-class-footer">
        <MainButton size="small" type="secondary" click={closeModal}>
          Cancel
        </MainButton>
        <MainButton
          size="small"
          type="primary"
          id="verificationBtn"
          disabled={boxText === "-Select a folder-" ? true : false}
          click={() => handleMoveVaultItem(selectedFolderId)}
          extraClasses="ml-4"
        >
          Move Document
        </MainButton>
      </div>
    </Modal>
  );
};
