import Modal from "react-modal";
import { toast } from "react-toastify";
import { useCallback, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Textinput } from "../../components/inputs/textinput";
import { Errornotf } from "../../components/notifications/errornotf";
import { RenameVaultItemInput } from "../../types/vaultTypes";
import { folderAlreadyExists } from "../../pages/vault/vault-utils";
import { useCreateVaultFolderMutation, useRenameVaultItemMutation } from "../../api/vault/vault-mutations";
import MainButton from "../../components/buttons/mainButton";
import { getFileNameWithoutType } from "../../components/utilities/utilityFunctions";
import { useVault } from "./VaultProvider";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const VaultManageFileFolderModal = ({
  modalIsOpen,
  header,
  vaultItem,
  folders,
  inputLabel,
  placeholder,
  onCloseModal,
}: any) => {
  const inputRef = useRef<HTMLInputElement>(null); // Explicitly declare the type as HTMLInputElement
  const value =
    vaultItem?.item?.name || (vaultItem?.item?.file_name && getFileNameWithoutType(vaultItem?.item?.file_name));
  const isRenaming = !!(vaultItem && vaultItem?.item?.id && (vaultItem?.item?.name || vaultItem?.item?.file_name));
  const { mutateAsync: createFolder } = useCreateVaultFolderMutation();

  useEffect(() => {
    if (modalIsOpen) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 100);
    }
  }, [modalIsOpen]);

  const { handleSubmit, errors, control } = useForm({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: {
      name: value,
    },
  });

  const { setCurrentManagedItem } = useVault();
  const { mutateAsync: renameVaultItem, isLoading } = useRenameVaultItemMutation();

  const onSubmit = useCallback(
    async (data: any) => {
      const handleCreateFolder = async (data: { name: string }) => {
        if (folderAlreadyExists(data?.name, folders)) {
          toast.error("Folder already exists. Please use a different name.", {
            autoClose: 5000,
          });
          return;
        }
        try {
          const { name } = data;
          await createFolder({ name });
          onCloseModal();
        } catch (error) {
          console.error(error);
        }
      };

      const handleRenameVaultItem = async (data: RenameVaultItemInput) => {
        try {
          await renameVaultItem({
            id: data?.id,
            name: data?.name,
            fileType: vaultItem.type === "folder" ? "folder" : "document",
          });
        } catch (error) {
          console.error("fail: handleRenameVaultItem", error);
        }
      };

      if (isRenaming) {
        try {
          await handleRenameVaultItem({ id: vaultItem.item.id, name: data?.name });
          setCurrentManagedItem(null);
        } catch (error) {
          console.error("fail: onSubmit manage vault item", error);
        }
        return;
      }

      try {
        await handleCreateFolder(data);
        setCurrentManagedItem(null);
      } catch (error) {
        console.error("fail: onSubmit manage vault item", error);
      }
    },
    [
      isRenaming,
      folders,
      createFolder,
      onCloseModal,
      renameVaultItem,
      vaultItem?.type,
      vaultItem?.item?.id,
      setCurrentManagedItem,
    ]
  );

  const isNative = Capacitor.isNativePlatform();

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={onCloseModal}
      contentLabel="Vault Modal"
      className={classNames("modal-container", isNative && "native-modal")}
      overlayClassName={"modal-container-overlay"}
    >
      {/* Header */}
      <div className="modal-header px-4 flex items-center justify-between">
        <h1 className="text-h4 font-semibold">{header}</h1>
        <button type="button" onClick={onCloseModal} className="cursor-pointer">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        {/* Body */}
        <div className="modal-body px-4 md:px-8 pt-5 pb-10">
          <Errornotf style={{ marginBottom: "20px" }} message={null} />
          <Controller
            name="name"
            rules={{ required: true }}
            control={control}
            defaultValue={value}
            render={(props) => (
              <Textinput
                ref={inputRef} // Pass the ref
                onChange={(e: any) => {
                  const { value } = e.target;
                  props.onChange(value);
                }}
                label={inputLabel}
                inputid="name"
                type="text"
                value={props.value || vaultItem?.item?.name || getFileNameWithoutType(vaultItem?.item?.file_name || "")} // Access field from props
                placeholder={placeholder}
                iserror={errors.name}
                message={"Please fill this field"}
                autocomplete="off"
                disabled={isLoading}
              />
            )}
          />
        </div>

        {/* Footer */}
        <div className="modal-footer p-4 gap-4 flex justify-end items-center border-t bg-white">
          <MainButton size="small" type="primary" click={() => handleSubmit(onSubmit)} disabled={isLoading}>
            Save
          </MainButton>
          <MainButton size="small" type="secondary" click={onCloseModal}>
            Cancel
          </MainButton>
        </div>
      </form>
    </Modal>
  );
};
