import Modal from "react-modal";
import { ReactComponent as Plus } from "../../../assets/svg/plus-blue.svg";
import { useState } from "react";
import MainButton from "../../buttons/mainButton";
import { lastSyncTime } from "../viewassets";
import { ConfirmationModal } from "../ConfirmationModal";
import usePortfolio from "../../../hooks/usePortfolio";
import EditAccountLabels from "./editAccountLabels";
import LabelAccounts from "./labelAccounts";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { PencilIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import "./labels.scss";

interface IManageLabelsModal {
  modalIsOpen: boolean;
  closeModal: () => void;
  labels: any;
  handleSaveLabel: (accountIds: string[], labelId: string) => void;
}

const ManageLabelsModal: React.FC<IManageLabelsModal> = ({ modalIsOpen, closeModal, labels, handleSaveLabel }) => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [isChoosen, setIsChoosen] = useState<any>({});
  const [editModal, setEditModal] = useState<boolean>(false);
  const { handleDeleteLabel } = usePortfolio();
  const [addNewLabel, setAddNewLabel] = useState<boolean>(false);

  const handleAddNewLabel = () => {
    setAddNewLabel(true);
  };

  const deleteLabel = () => {
    handleDeleteLabel(isChoosen.id);
    setDeleteModal(false);
  };

  const askToDelete = (idx: number) => {
    setIsChoosen(labels[idx]);
    setDeleteModal(true);
  };

  const handleEditModal = (idx: number) => {
    setIsChoosen(labels[idx]);
    setEditModal(true);
  };

  const isNative = Capacitor.isNativePlatform();

  return (
    <>
      {addNewLabel && (
        <LabelAccounts
          title="Add a new label"
          closeModal={() => setAddNewLabel(false)}
          isOpen={addNewLabel}
          closeManageLabelModal={closeModal}
          handleSaveLabel={handleSaveLabel}
        />
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className={classNames("viewassetsdetails postionmoal", isNative && "native-modal")}
        overlayClassName={"modal-container-overlay"}
      >
        <div className="flex justify-end">
          <button type="button" onClick={closeModal} className="closeicon cursor-pointer">
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>
        <div className={`text-content`}>
          <h3 className="text-type font-semibold mb-2">Manage Labels</h3>
          <p className="text-type-100 text-p2">Edit and control your labels</p>
          <div className="flex justify-end mb-4">
            <button className="flex items-center text-primary font-semibold" onClick={handleAddNewLabel}>
              <Plus />
              Add a new label
            </button>
          </div>
        </div>
        <div className="manage-labels">
          {labels?.map(({ name, updated_at }: any, idx: number) => (
            <div className="flex justify-between items-center mb-6" key={`labels-${idx}`}>
              <div>
                <p className="text-p2 capitalize">{name}</p>
                <small className="text-p3">Last edited {lastSyncTime(updated_at)}</small>
              </div>
              <div className="flex">
                <button onClick={() => handleEditModal(idx)}>
                  <PencilIcon className="w-5 h-5" />
                </button>
                <button className="brightness-0 ml-4" onClick={() => askToDelete(idx)}>
                  <TrashIcon className="w-5 h-5" />
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-end gap-3 border-t mt-8 absolute bottom-0 w-full right-0 p-6">
          <MainButton type="secondary" size="small" click={closeModal}>
            Cancel
          </MainButton>
        </div>
      </Modal>

      {editModal && (
        <EditAccountLabels
          isOpen={editModal}
          setIsChoosen={setIsChoosen}
          label={isChoosen}
          closeModal={() => setEditModal(false)}
          handleAddNewLabel={() => {
            setEditModal(false);
            handleAddNewLabel();
          }}
        />
      )}
      <ConfirmationModal
        modalIsOpen={deleteModal}
        closeModal={() => {
          setDeleteModal(false);
        }}
        submitAction={() => {
          deleteLabel();
        }}
        confirmationText={
          <span>
            Delete {<span className="capitalize font-bold">{isChoosen?.name}</span>} ? Your accounts will not be
            affected
          </span>
        }
      />
    </>
  );
};

export default ManageLabelsModal;
