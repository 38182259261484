import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ConfirmationModal } from "../../../components/modals/ConfirmationModal";
import { SuccessToast } from "../../../components/notifications/toasts";
import { useMFAGql } from "../../../graphql/mfa.gql-hook";

export const SMSAuthentication = ({ closeView, getCurrentUser }: any) => {
  const {
    phone_number,
    preferences: { sms_mfa_enabled },
  } = useSelector((state: any) => state.currentUser);
  const { disableSms: disableSmsApi } = useMFAGql();
  const [confirmDisable, setConfirmDisable] = useState(false);

  const disableSms = () => {
    disableSmsApi().then(({ errors }) => {
      if (errors) {
        toast.error(errors[0].message);
        return;
      }

      toast(<SuccessToast message="SMS authentication has been turned off" />, {
        className: "custom-toast-success",
      });
      setConfirmDisable(false);
    });
  };

  if (!sms_mfa_enabled) {
    return <></>;
  }

  return (
    <>
      <div className="flex mb-8 justify-between items-end securitylist-items">
        <div className="flex flex-col first-side-1">
          <p className="text-type font-medium mb-1 text-p1">SMS authentication</p>
          <p className="text-type-100 text-p2">{`(${phone_number})`}</p>
        </div>
        <div className="flex first-side-2">
          <p className="first-side-21 p-semibold cursor-pointer mr-1" onClick={() => setConfirmDisable(true)}>
            Remove
          </p>
        </div>
      </div>

      <ConfirmationModal
        modalIsOpen={confirmDisable}
        closeModal={() => setConfirmDisable(false)}
        submitAction={disableSms}
        confirmationTitle="Confirm"
        submitText="Turn Off"
        confirmationText={
          "Please confirm that you want to turn off SMS Authentication. We will no longer send you a code to your phone number. Do not forget to enable other 2FA methods offered."
        }
      />
    </>
  );
};
