import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { GET_RECONNECT_DATA } from "../graphql/portfolio";
import { loaderData, setUtilityData } from "../store/actions/utility";

const useReconnectAsset = () => {
  const dispatch = useDispatch();

  const [fetchReconnectData] = useLazyQuery(GET_RECONNECT_DATA, {
    errorPolicy: "all",
    onCompleted(data) {
      return data;
    },
    onError(error: any) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  const getReconnectData = async (id: string, connection_id: string) => {
    const resp = await fetchReconnectData({
      variables: {
        id,
        connection_id,
      },
    });
    return resp;
  };

  return {
    getReconnectData,
  };
};

export default useReconnectAsset;
