import { useSelector } from "react-redux";
import { useGetInvoicesQuery, useGetSubscriptionQuery } from "../../../../api/subscription/subscription-queries";
import { Plan, SubscriptionStatus, updateSubscriptionInfo } from "../../../../types/subscriptionTypes";
import { StripeCancelPrompt } from "./StripeCancelPrompt";
import { StripeInvoices } from "./StripeInvoices";
import { StripePaymentMethods } from "./StripePaymentMethods";
import { UserState } from "../../../../types/authTypes";
import { useEffect, useState } from "react";
import { useUpdateSubscriptionMutation } from "../../../../api/subscription/subscription-mutations";
import { StripeSubscriptionModal } from "./StripeSubscriptionModal";
import { useTranslation } from "react-i18next";
import { ConfirmationModal } from "../../../../components/modals/ConfirmationModal";
import ChangeBillingType from "../../../../components/modals/subscription/chagneBillingType";
import AddStripePaymentMethod from "./AddStripePaymentMethodModal";
import ChooseStripePaymentMethodModal from "./ChooseStripePaymentMethodModal";
import SubscriptionInfo from "../SubscriptionInfo";
import MainButton from "../../../../components/buttons/mainButton";
import BillingFeatures from "../../../../components/billing/BillingFeatures";
import dayjs from "dayjs";
import StripePlanSelector from "./StripePlanSelector";
import { Capacitor } from "@capacitor/core";
import { useUserQuery } from "../../../../api/user/user-queries";
import StripeResubscribeModal from "./StripeResubscribeModal";

export const StripeManager = ({ setShowFaq }: { setShowFaq: (value: boolean) => void }) => {
  const { t } = useTranslation();
  const isNative = Capacitor.isNativePlatform();
  const [showPlans, setShowPlans] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [showChangeBilling, setShowChangeBilling] = useState<boolean>(false);
  const [addCardModal, setAddCardModal] = useState(false);
  const [addPaymentMethodModal, setAddPaymentMethodModal] = useState<boolean>(false);
  const [addPaymentMethodActionType, setAddPaymentMethodActionType] = useState<"add-card" | "make-payment" | "">("");
  const [chosePaymentMethodStatus, setChoosePaymentMethodStatus] = useState<"PAYMENT_PENDING" | "NEW_PAYMENT" | "">("");
  const [choosePaymentMethodModal, setChoosePaymentMethodModal] = useState<boolean>(false);
  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState<boolean>(false);

  const [showResubscribeModal, setShowResubscribeModal] = useState<boolean>(false);

  const { id: activePortfolioId } = useSelector((state: any) => state.activePortfolio);
  const plans = useSelector((state: { user: UserState }) => state.user.config.plans);

  const { data: invoices } = useGetInvoicesQuery({ portfolioId: activePortfolioId });
  const { data: user, refetch: refetchUser } = useUserQuery();
  const { data: subscription } = useGetSubscriptionQuery({ id: activePortfolioId });
  const paymentMethods = user?.payment_methods;

  useEffect(() => {
    setShowPlans(!subscription || Object.keys(subscription).length === 0);
  }, [subscription]);

  const { mutateAsync: updateSubscription } = useUpdateSubscriptionMutation();

  const onUpdateSubscription = async (updateInfo: updateSubscriptionInfo) => {
    try {
      await updateSubscription(updateInfo);
    } catch (e) {
      console.error("fail: onUpdateSubscription", e);
    }
  };

  const onChangeSubscriptionPaymentMethod = () => {
    setShowChangeBilling(false);

    try {
      onUpdateSubscription({ id: subscription.id });
    } catch (e) {
      console.error("fail: onChangeSubscriptionPaymentMethod", e);
    }
  };

  const onChangeSubscriptionPlan = (planId: string) => {
    setShowChangeBilling(false);

    try {
      onUpdateSubscription({ id: subscription.id, planId });
    } catch (e) {
      console.error("fail: onChangeSubscriptionPlan", e);
    }
  };

  const onRenewSubscription = () => {
    try {
      onUpdateSubscription({ id: subscription.id, cancelAtPeriodEnd: false });
    } catch (e) {
      console.error("fail: onRenewSubscription", e);
    }
  };

  const onCancelSubscription = () => {
    if (!subscription) return;
    setCancelSubscriptionModal(false);

    try {
      onUpdateSubscription({ id: subscription.id, cancelAtPeriodEnd: true });
    } catch (e) {
      console.error("fail: onCancelSubscription", e);
    }
  };

  const hasActiveSub = subscription && subscription?.status === SubscriptionStatus.ACTIVE;
  const hasPendingSub = subscription && subscription?.status === SubscriptionStatus.PAYMENT_PENDING;

  if (isNative && (hasActiveSub || hasPendingSub)) {
    return (
      <div className="flex justify-center items-center p-4">Please visit us on the web to manage your billing.</div>
    );
  }

  return (
    <div>
      {showChangeBilling && subscription && (
        <ChangeBillingType
          closeModal={() => setShowChangeBilling(false)}
          showChangeBilling={showChangeBilling}
          changePlan={onChangeSubscriptionPlan}
          plans={plans}
          currentPlanId={subscription.plan.id}
        />
      )}

      {addPaymentMethodModal && (
        <AddStripePaymentMethod
          closeModal={() => setAddPaymentMethodModal(false)}
          addPaymentMethodActionType={addPaymentMethodActionType}
          addPaymentMethodModal={addPaymentMethodModal}
          callback={onChangeSubscriptionPaymentMethod}
          setDefault={false}
        />
      )}

      {choosePaymentMethodModal && subscription && (
        <ChooseStripePaymentMethodModal
          isOpen={choosePaymentMethodModal}
          closeModal={() => {
            setChoosePaymentMethodModal(false);
            setChoosePaymentMethodStatus("");
          }}
          plan={selectedPlan}
          status={chosePaymentMethodStatus}
          updatePaymentMethod={onChangeSubscriptionPaymentMethod}
          updateUserData={refetchUser}
          setAsDefaultPaymentMethod
        />
      )}

      {showResubscribeModal && (
        <StripeResubscribeModal
          showChangeBilling={showResubscribeModal}
          closeModal={() => setShowResubscribeModal(false)}
          plans={plans}
        />
      )}

      {addCardModal && (
        <StripeSubscriptionModal
          plan={selectedPlan}
          modalIsOpen={addCardModal}
          closeModal={() => {
            setAddCardModal(false);
            setSelectedPlan(null);
          }}
          onSuccess={() => {
            refetchUser();
            setSelectedPlan(null);
          }}
          isRenew={false}
          abandonedCart={false}
        />
      )}

      {cancelSubscriptionModal && subscription && (
        <ConfirmationModal
          modalIsOpen={cancelSubscriptionModal}
          closeModal={() => setCancelSubscriptionModal(false)}
          verificationText=""
          submitAction={onCancelSubscription}
          confirmationText={`Are you sure? If you cancel your subscription, you will still have access to your premium features till ${dayjs(
            subscription?.current_period_end
          ).format("MMM DD, YYYY")} and from then, you will be downgraded to our free plan`}
          confirmationTitle={"Cancel Subscription?"}
          submitText={"Cancel Subscription"}
          cancelText={"Go back"}
        />
      )}

      {/* Show plans when no subscription */}
      {showPlans ? (
        <div className="no-payment m-auto max-w-2xl">
          <div className="">
            {/* Header */}
            <div className="flex justify-center w-full text-center text-gray-700 mb-10">
              <div className="text-xl font-semibold max-w-[300px]">
                {`Upgrade to get access to all ${t("orgName")} features`}
              </div>
            </div>

            {/* Plans - Stripe */}
            <StripePlanSelector
              plans={plans}
              setSelectedPlan={setSelectedPlan}
              paymentMethods={paymentMethods}
              setChoosePaymentMethodModal={setChoosePaymentMethodModal}
              setChoosePaymentMethodStatus={setChoosePaymentMethodStatus}
              setAddCardModal={setAddCardModal}
            />

            <div className="w-full flex flex-col justify-center items-center gap-y-10">
              <BillingFeatures setShowFaq={setShowFaq} />
            </div>
          </div>
        </div>
      ) : (
        <>
          <SubscriptionInfo subscription={subscription}>
            <MainButton
              type="primary"
              size="small"
              extraClasses="w-full whitespace-nowrap"
              click={() => {
                if (subscription?.status === SubscriptionStatus.CANCELED) {
                  setShowResubscribeModal(true);
                } else if (subscription?.cancel_at) {
                  onRenewSubscription();
                } else {
                  setShowChangeBilling(true);
                }
              }}
            >
              {subscription?.cancel_at && subscription?.status === SubscriptionStatus.ACTIVE && "Undo Cancellation"}
              {!subscription?.cancel_at && subscription?.status === SubscriptionStatus.ACTIVE && "Change Billing Plan"}
              {subscription?.status === SubscriptionStatus.CANCELED && "Resubscribe"}
              {subscription?.status === SubscriptionStatus.PAYMENT_PENDING && "Update Payment Method"}
            </MainButton>
          </SubscriptionInfo>

          {/* Payment Methods */}
          <div className="mb-6 mt-12 pb-6 md:pb-0 border-b md:border-none">
            <StripePaymentMethods
              setAddPaymentMethodModal={setAddPaymentMethodModal}
              setAddPaymentMethodActionType={setAddPaymentMethodActionType}
            />
          </div>

          {/* Invoice History */}
          <div className="w-full mb-6 mt-12">
            <StripeInvoices invoices={invoices} />
          </div>

          {/* Cancel Subscription */}
          {subscription?.status === SubscriptionStatus.ACTIVE && !subscription?.cancel_at && (
            <div className="w-full max-w-xl mt-12">
              <StripeCancelPrompt subscription={subscription} setCancelSubscriptionModal={setCancelSubscriptionModal} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
