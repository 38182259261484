import React from "react";
import Stars from "../../../../assets/svg/bi_stars.svg";
import { Plan, PlanIntervals } from "../../../../types/subscriptionTypes";
import classNames from "classnames";

interface StripePlanSelectorProps {
  plans: Plan[];
  setSelectedPlan: (plan: Plan) => void;
  paymentMethods: any[];
  setChoosePaymentMethodModal: (isOpen: boolean) => void;
  setChoosePaymentMethodStatus: (status: "PAYMENT_PENDING" | "NEW_PAYMENT" | "") => void;
  setAddCardModal: (isOpen: boolean) => void;
}

const StripePlanSelector: React.FC<StripePlanSelectorProps> = ({
  plans,
  setSelectedPlan,
  paymentMethods,
  setChoosePaymentMethodModal,
  setChoosePaymentMethodStatus,
  setAddCardModal,
}) => {
  return (
    <div className="flex justify-center items-center flex-wrap gap-x-10 mb-6 py-8">
      {plans.map((plan: Plan) => (
        <div
          className={classNames(
            "relative text-center",
            "w-full min-w-lg max-w-[300px] p-8",
            "border border-gray-200 rounded-md shadow-md"
          )}
          key={plan.id}
        >
          {/* Card Topper */}
          {plan.interval === PlanIntervals.YEAR && (
            <div
              className={classNames(
                "absolute flex justify-center items-center",
                "bg-[#dbf0da] text-[#245421] outline outline-[#dbf0da] text-sm rounded-t-md",
                "border border-[#dbf0da] border-2",
                "py-2 px-2"
              )}
              style={{ width: "calc(100% + 2px)", top: "-36px", left: "-1px" }}
            >
              <div className="flex justify-center items-center gap-x-2">
                <img src={Stars} alt="stars" /> Most Popular
              </div>
            </div>
          )}

          <div className="w-full">
            {/* Plan Info */}
            <div className="text-xl font-semibold">
              {`${plan.currency.symbol}${plan.amount / 100}`}{" "}
              {plan.interval === PlanIntervals.YEAR && <span className="text-sm">(Save $20)</span>}
            </div>
            <div className="text-xs text-gray-600 my-1 mb-5">
              Paid {plan.interval === PlanIntervals.DAY ? "dai" : plan.interval}ly
            </div>

            {/* Subscribe Button */}
            <button
              onClick={() => {
                setSelectedPlan(plan);

                if (paymentMethods.length > 0) {
                  setChoosePaymentMethodModal(true);
                  setChoosePaymentMethodStatus("NEW_PAYMENT");
                } else {
                  setAddCardModal(true);
                }
              }}
              type="button"
              id="login-submit"
              className="w-full btn-secondary btn-md hover:opacity-80"
            >
              Choose Billing
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StripePlanSelector;
