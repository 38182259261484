import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/svg/logo1.svg";
import { ReactComponent as LifecheckLogo } from "../../../assets/svg/logo-lifecheck.svg";
import QrScan from "../../../assets/images/qrcode.png";
import Star from "../../../assets/svg/star.svg";
import User from "../../../assets/images/user-img.png";
import Typewriter from "typewriter-effect";
import { useTranslation } from "react-i18next";

const SignupPanelInfo = () => {
  const isProd = process.env.NODE_ENV === "production";
  const { t } = useTranslation();

  return (
    <div className="firsthalf">
      <Link to="/" className="w-36 mb-11">
        {isProd ? <Logo /> : <LifecheckLogo />}
      </Link>
      <div className="bottom">
        <div className="block">
          <h1 className="heading">
            Organize your{" "}
            <span className="blue-text">
              <Typewriter
                options={{
                  strings: ["assets", "bank accounts", "wallets", "stocks", "real estate", "art", "documents"],
                  autoStart: true,
                  loop: true,
                }}
              />
            </span>
          </h1>

          <p className="text">{t("auth.signup.info.subtitle")}</p>

          <div className="secure-box">
            <img src={QrScan} alt="Qr Code" />
            <p className="secure-text">{t("auth.signup.info.downloadCTA")}</p>
          </div>
        </div>

        <div className="rate">
          <p className="rate-text">{t("auth.signup.info.userReview")}</p>
          <div className="user-section">
            <img alt="" src={User} className="user" />

            <div className="star-section">
              {[...Array(5)].map((_) => (
                <img alt="" src={Star} key={Math.random()} className="star" />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPanelInfo;
