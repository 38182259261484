import { useState } from "react";
import { print } from "graphql";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../../hooks/useAPI";
import { CREATE_NOTE, DELETE_NOTE, REMOVE_FILE, UPDATE_NOTE, UPLOAD_FILE } from "../../graphql/notesFiles";
import ActionStateToast from "../../components/ActionStateToast";
import CheckCir from "../../assets/svg/successcheck.svg";

export function useUploadNoteFileMutation() {
  const queryClient = useQueryClient();
  const Api = useApi({ "Content-Type": "multipart/form-data" });
  const [uploadFileToast, setUploadFileToast] = useState<any>();

  return useMutation(
    async (variables: { file: File; createFileInput: { account_id: string } }) => {
      const { file, createFileInput } = variables;
      const formData = new FormData();

      if (file) {
        formData.append(
          "operations",
          JSON.stringify({
            operationName: "uploadFile",
            variables: {
              file: null, // This will be replaced by the actual file reference in the map
              createFileInput,
            },
            query: print(UPLOAD_FILE),
          })
        );

        // Map to link the file to the operation
        formData.append("map", JSON.stringify({ "1": ["variables.file"] }));
        formData.append("1", file, file.name);

        const response = await Api.post("", formData);
        return response?.data?.data?.uploadFile;
      } else {
        throw new Error("File is undefined");
      }
    },
    {
      onMutate: () => {
        setUploadFileToast(
          toast(<ActionStateToast message="Uploading File..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: (response) => {
        if (response) {
          toast.dismiss(uploadFileToast);

          toast.success(
            <div className="toast-div">
              <img src={CheckCir} alt="check circle" />
              Successfully uploaded file.
            </div>
          );

          queryClient.invalidateQueries(["vaultFolders"]); // todo: update this.
        } else {
          console.error("upload file response is null");
        }
      },
      onError: () => {
        toast.dismiss(uploadFileToast);
        toast("Something went wrong..", {
          autoClose: 3000,
          type: "error",
        });
      },
      onSettled: () => {
        toast.dismiss(uploadFileToast);
        setUploadFileToast(null);
      },
    }
  );
}

export function useRemoveNoteFileMutation() {
  const queryClient = useQueryClient();
  const Api = useApi();

  const [removeFileToast, setRemoveFileToast] = useState<any>();

  return useMutation(
    async (variables: { id: string }) => {
      const { id } = variables;

      const response = await Api.post("", {
        query: print(REMOVE_FILE),
        variables: {
          id,
        },
      });
      return response.data;
    },
    {
      onMutate: () => {
        setRemoveFileToast(
          toast(<ActionStateToast message="Removing File..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: (response) => {
        if (response) {
          toast.dismiss(removeFileToast);

          toast.success(
            <div className="toast-div">
              <img src={CheckCir} alt="check circle" />
              Successfully removed file.
            </div>
          );

          queryClient.invalidateQueries(["vaultFolders"]); // todo: update this.
        } else {
          console.error("remove file response is null");
        }
      },
      onError: () => {
        toast.dismiss(removeFileToast);
        toast("Something went wrong..", {
          autoClose: 3000,
          type: "error",
        });
      },
      onSettled: () => {
        toast.dismiss(removeFileToast);
        setRemoveFileToast(null);
      },
    }
  );
}

export function useCreateNoteMutation() {
  const queryClient = useQueryClient();
  const Api = useApi();

  const [createNoteToast, setCreateNoteToast] = useState<any>();

  return useMutation(
    async (variables: { createNoteInput: any }) => {
      const { createNoteInput } = variables;

      const response = await Api.post("", {
        query: print(CREATE_NOTE),
        variables: {
          createNoteInput,
        },
      });

      return response?.data?.data?.createNote;
    },
    {
      onMutate: () => {
        setCreateNoteToast(
          toast(<ActionStateToast message="Creating Note..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: (response) => {
        if (response) {
          toast.dismiss(createNoteToast);

          toast.success(
            <div className="toast-div">
              <img src={CheckCir} alt="check circle" />
              Successfully created note.
            </div>
          );

          queryClient.invalidateQueries(["vaultFolders"]); // todo: update this.
        } else {
          console.error("create note response is null");
        }
      },
      onError: () => {
        toast.dismiss(createNoteToast);
        toast("Something went wrong..", {
          autoClose: 3000,
          type: "error",
        });
      },
      onSettled: () => {
        toast.dismiss(createNoteToast);
        setCreateNoteToast(null);
      },
    }
  );
}

export function useUpdateNoteMutation() {
  const queryClient = useQueryClient();
  const Api = useApi();

  const [updateNoteToast, setUpdateNoteToast] = useState<any>();

  return useMutation(
    async (variables: { id: string; updateNoteInput: any }) => {
      const { id, updateNoteInput } = variables;

      const response = await Api.post("", {
        query: print(UPDATE_NOTE),
        variables: {
          id,
          updateNoteInput,
        },
      });

      return response?.data?.data?.updateNote;
    },
    {
      onMutate: () => {
        setUpdateNoteToast(
          toast(<ActionStateToast message="Updating Note..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: (response) => {
        if (response) {
          toast.dismiss(updateNoteToast);

          toast.success(
            <div className="toast-div">
              <img src={CheckCir} alt="check circle" />
              Successfully updated note.
            </div>
          );

          queryClient.invalidateQueries(["vaultFolders"]); // todo: update this.
        } else {
          console.error("update note response is null");
        }
      },
      onError: () => {
        toast.dismiss(updateNoteToast);
        toast("Something went wrong..", {
          autoClose: 3000,
          type: "error",
        });
      },
      onSettled: () => {
        toast.dismiss(updateNoteToast);
        setUpdateNoteToast(null);
      },
    }
  );
}

export function useDeleteNoteMutation() {
  const queryClient = useQueryClient();
  const Api = useApi();

  const [deleteNoteToast, setDeleteNoteToast] = useState<any>();

  return useMutation(
    async (variables: { id: string }) => {
      const { id } = variables;

      const response = await Api.post("", {
        query: print(DELETE_NOTE),
        variables: {
          id,
        },
      });

      // todo: "Cannot return null for non-nullable field Note.id."
      return response?.data;
    },
    {
      onMutate: () => {
        setDeleteNoteToast(
          toast(<ActionStateToast message="Removing Note..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: (response) => {
        if (response) {
          toast.dismiss(deleteNoteToast);

          toast.success(
            <div className="toast-div">
              <img src={CheckCir} alt="check circle" />
              Successfully removed file.
            </div>
          );

          queryClient.invalidateQueries(["vaultFolders"]); // todo: update this.
        } else {
          console.error("remove note response is null");
        }
      },
      onError: () => {
        toast.dismiss(deleteNoteToast);
        toast("Something went wrong..", {
          autoClose: 3000,
          type: "error",
        });
      },
      onSettled: () => {
        toast.dismiss(deleteNoteToast);
        setDeleteNoteToast(null);
      },
    }
  );
}
