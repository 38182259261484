import React, { createContext, useContext, useState, ReactNode } from "react";
import { VaultFolderType, VaultNoteType } from "../../types/vaultTypes";

// Define the type for the Vault context
interface VaultContextType {
  currentFolder: VaultFolderType | null;
  setCurrentFolder: React.Dispatch<React.SetStateAction<VaultFolderType | null>>;
  currentNote: VaultNoteType | null;
  setCurrentNote: React.Dispatch<React.SetStateAction<VaultNoteType | null>>;
  currentManagedItem: {
    type: "folder" | "file" | "note";
    item: VaultFolderType | null;
  } | null;
  setCurrentManagedItem: React.Dispatch<React.SetStateAction<{ type: "folder" | "file" | "note"; item: any } | null>>;
  currentMovingItem: {
    type: "file" | "note";
    documentId: string;
  } | null;
  setCurrentMovingItem: React.Dispatch<React.SetStateAction<{ type: "file" | "note"; documentId: string } | null>>;
  currentDeletingItem: {
    type: "folder" | "file" | "note";
    item: VaultFolderType | null;
  } | null;
  setCurrentDeletingItem: React.Dispatch<React.SetStateAction<{ type: "folder" | "file" | "note"; item: any } | null>>;
}

// Use the defined type when creating the context
const VaultContext = createContext<VaultContextType | null>(null);

export function useVault() {
  const context = useContext(VaultContext);
  if (!context) {
    throw new Error("useVault must be used within a VaultProvider");
  }
  return context;
}

interface VaultProviderProps {
  children: ReactNode; // Define the type for the children prop
}

// Use the defined props type for the VaultProvider component
export function VaultProvider({ children }: VaultProviderProps) {
  const [currentFolder, setCurrentFolder] = useState<VaultFolderType | null>(null);
  const [currentNote, setCurrentNote] = useState<VaultNoteType | null>(null);
  const [currentManagedItem, setCurrentManagedItem] = useState<{
    type: "folder" | "file" | "note";
    item: VaultFolderType | null;
  } | null>(null);
  const [currentMovingItem, setCurrentMovingItem] = useState<{
    type: "file" | "note";
    documentId: string;
  } | null>(null);
  const [currentDeletingItem, setCurrentDeletingItem] = useState<{
    type: "folder" | "file" | "note";
    item: VaultFolderType | null;
  } | null>(null);

  // Now TypeScript knows what value is expected by the provider
  return (
    <VaultContext.Provider
      value={{
        currentFolder,
        currentNote,
        currentManagedItem,
        currentMovingItem,
        currentDeletingItem,
        setCurrentFolder,
        setCurrentNote,
        setCurrentManagedItem,
        setCurrentMovingItem,
        setCurrentDeletingItem,
      }}
    >
      {children}
    </VaultContext.Provider>
  );
}
