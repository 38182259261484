import { gql } from "@apollo/client";

export const VAULT_FOLDERS = gql`
  mutation VaultFolders {
    vaultFolders {
      created_at
      id
      name
      updated_at
      user_id
      vaultDocuments {
        created_at
        file_key
        file_name
        file_size
        file_url
        id
        user_id
      }
      vaultNotes {
        created_at
        description
        id
        title
        updated_at
        user_id
      }
    }
  }
`;

export const UPLOAD_VAULT_DOCUMENT = gql`
  mutation UploadVaultDocument($file: Upload!, $vaultFolderId: String) {
    uploadVaultDocument(file: $file, vault_folder_id: $vaultFolderId) {
      id
    }
  }
`;

export const CREATE_VAULT_FOLDER = gql`
  mutation CreateVaultFolder($name: String!) {
    createVaultFolder(name: $name) {
      id
    }
  }
`;

export const CREATE_VAULT_NOTE = gql`
  mutation CreateVaultNote($createVaultNoteInput: CreateVaultNoteInput!) {
    createVaultNote(createVaultNoteInput: $createVaultNoteInput) {
      id
    }
  }
`;

export const UPDATE_VAULT_NOTE = gql`
  mutation UpdateVaultNote($updateVaultNoteInput: UpdateVaultNoteInput!) {
    updateVaultNote(updateVaultNoteInput: $updateVaultNoteInput) {
      id
    }
  }
`;

export const REMOVE_VAULT_FOLDER = gql`
  mutation RemoveVaultFolder($removeVaultFolderId: String!) {
    removeVaultFolder(id: $removeVaultFolderId) {
      id
    }
  }
`;

export const REMOVE_VAULT_NOTE = gql`
  mutation RemoveVaultNote($removeVaultNoteId: ID!) {
    removeVaultNote(id: $removeVaultNoteId) {
      id
    }
  }
`;

export const REMOVE_VAULT_DOCUMENT = gql`
  mutation RemoveVaultDocument($removeVaultDocumentId: Float!) {
    removeVaultDocument(id: $removeVaultDocumentId) {
      id
    }
  }
`;

export const RENAME_VAULT_FOLDER = gql`
  mutation RenameVaultFolder($renameVaultFolder: RenameVaultItemInput!) {
    renameVaultFolder(renameVaultFolder: $renameVaultFolder) {
      id
    }
  }
`;

export const RENAME_VAULT_DOCUMENT = gql`
  mutation RenameVaultDocument($renameVaultDocument: RenameVaultItemInput!) {
    renameVaultDocument(renameVaultDocument: $renameVaultDocument) {
      id
    }
  }
`;

export const MOVE_VAULT_DOCUMENT = gql`
  mutation MoveVaultDocument($moveVaultDocumentInput: MoveVaultItemInput!) {
    moveVaultDocument(moveVaultDocumentInput: $moveVaultDocumentInput) {
      id
    }
  }
`;

export const MOVE_VAULT_NOTE = gql`
  mutation MoveVaultNote($moveVaultNoteInput: MoveVaultItemInput!) {
    moveVaultNote(moveVaultNoteInput: $moveVaultNoteInput) {
      id
    }
  }
`;

export const DOWNLOAD_VAULT_DOCUMENT = gql`
  mutation DownloadVaultDocument($downloadVaultDocumentId: String!) {
    downloadVaultDocument(id: $downloadVaultDocumentId)
  }
`;
