import React from "react";

interface TextInput {
  label?: string;
  inputid?: string;
  linfo?: React.ReactNode;
  message?: string;
  iserror?: unknown;
  onChange?: (e?: any) => void;
  value?: any;
  prefix?: string;
  suffix?: string;
  placeholder?: string;
  row?: string;
  checked?: string;
  type?: "text" | "tel" | "password" | undefined;
}

export const Textboxinput = ({ label, inputid, message, iserror, ...rest }: TextInput) => {
  return (
    <div className="forminput">
      <label className="p-medium" htmlFor={inputid}>
        {label}
      </label>
      <textarea
        rows={7}
        className={`bg-neutral-300 rounded placeholder-type-200 text-type text-p2 font-normal px-2 py-3 transition focus:border-type focus:ring-0
        ${iserror ? "border-status-danger" : "border-neutral-200"}`}
        id={inputid}
        {...rest}
      ></textarea>
      {iserror ? <small className="text-p3 mt-2.5 border-status-danger">{message}</small> : null}
    </div>
  );
};
