import { useSelector } from "react-redux";
import { DashboardDateRangeOption } from "./DashboardDateRangeOption";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { Dropdown } from "../../components/Dropdown";

const DashboardTopbar = ({
  selectedDateRange,
  selectOptions,
  setSelectedDateRange,
}: {
  selectedDateRange: DashboardDateRangeOption;
  selectOptions: DashboardDateRangeOption[];
  setSelectedDateRange: (config?: DashboardDateRangeOption) => void;
}) => {
  const currentUser = useSelector((state: any) => state.user.currentUser);
  const firstName = currentUser.first_name;

  return (
    <div className="welcome flex justify-between">
      <div>
        <h1 className="text-h5 font-semibold">Welcome {firstName},</h1>
        <p className="text-type-100 text-p3">Your overview for today</p>
      </div>
      <Dropdown
        buttonContent={
          <div className="p-2 px-3 flex items-center gap-x-2 bg-gray-50 hover:bg-gray-100 border border-gray-200 rounded-md shadow-sm">
            <CalendarDaysIcon className="w-5 h-5" />
            <span className="text-md font-normal">{selectedDateRange.text}</span>
          </div>
        }
        contentClassName="rounded-md mt-2 mr-3 md:mr-0"
      >
        <div className="w-36 bg-white shadow-lg rounded-md px-2 py-4 flex flex-col border border-solid border-gray-100">
          {selectOptions.map((option, i) => (
            <div
              key={i}
              className="p-2 rounded-md cursor-pointer hover:bg-gray-100"
              onClick={() => setSelectedDateRange(option)}
            >
              {option.text}
            </div>
          ))}
        </div>
      </Dropdown>
    </div>
  );
};

export default DashboardTopbar;
