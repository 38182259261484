import GrowthRate from "../../components/growthRateV2/growth";
import { ConvertNumberCur } from "../../services/utilty";
import { AccountType } from "../../types/accountTypes";
import { lastSyncTime } from "../../components/modals/viewassets";
import DashboardTopPerformingCardSkeleton from "./DashboardTopPerformingCardSkeleton";

const DashboardTopPerforming = ({ isLoading, accounts, openAddAccountModal }: TopPerformingProps) => {
  accounts = accounts.sort((a: any, b: any) => b.growth - a.growth).splice(0, 2);

  return (
    <div className="p-4 h-full bg-white rounded-md border border-neutral-200 md:border-none">
      <div className="flex items-center mb-4">
        <small className="text-type-100 inline-block text-p2 mr-2">Top Performing Account</small>
        <div className="font-medium"></div>
      </div>

      {isLoading && <DashboardTopPerformingCardSkeleton />}

      {!isLoading && accounts.length === 0 && (
        <div className="bg-gray-100 rounded-md flex justify-center">
          <div className="my-8 py-8 text-type-200 text-center">
            <p className="mb-1">No data to display yet.</p>
            <button className="underline" onClick={openAddAccountModal}>
              Connect Account
            </button>
          </div>
        </div>
      )}
      {!isLoading &&
        accounts.map((account: AccountType, i: number) => (
          <div className="flex justify-between mb-8" key={i}>
            <div>
              <div className="flex">
                <p className="text-p2 text-type">
                  {account?.display_name.substring(0, 10)}
                  {account?.display_name.length > 10 ? "..." : ""}
                </p>
                <small className="text-type-100 font-semibold inline-block text-p2 mr-2">
                  <GrowthRate growthRate={+account.growth.toFixed(0)} />
                </small>
              </div>
              <p className="text-p2 text-type-300">{account?.institution.name}</p>
            </div>
            <div className="ml-8 text-right">
              <p className="text-p2 type-text">{ConvertNumberCur(account?.balance?.value, account?.balance?.symbol)}</p>
              <p className="text-p2 text-type-300">{lastSyncTime(account?.synced_at)}</p>
            </div>
          </div>
        ))}
    </div>
  );
};

interface TopPerformingProps {
  accounts: AccountType[];
  isLoading: boolean;
  openAddAccountModal: () => void;
}

export default DashboardTopPerforming;
