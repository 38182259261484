import { BackarrowIcon } from "../../icons/backarrow";

export const PanelActions = ({ onClickBack }: { onClickBack: () => void }) => {
  return (
    <div className="w-full flex justify-end items-center">
      <button
        className="p-semibold cursor-pointer items-center md:hidden cursor-pointer mb-1 pr-3 flex gap-x-2"
        onClick={onClickBack}
      >
        <BackarrowIcon height={20} /> Back
      </button>
    </div>
  );
};
