import React, { useState, useRef, useEffect, ReactNode } from "react";

interface DropdownProps {
  children: ReactNode;
  className?: string;
  contentClassName?: string;
  buttonContent: ReactNode;
  closeOnClick?: boolean;
}

export const Dropdown: React.FC<DropdownProps> = ({
  children,
  className = "",
  contentClassName = "",
  buttonContent,
  closeOnClick = true,
}) => {
  const [isShow, setIsShow] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsShow(false);
      }
    };

    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    if (isShow && wrapperRef.current && dropdownContentRef.current) {
      const buttonRect = wrapperRef.current.getBoundingClientRect();
      const dropdownRect = dropdownContentRef.current.getBoundingClientRect();

      const rightEdge = window.innerWidth;
      if (buttonRect.right + dropdownRect.width > rightEdge) {
        // Adjust positioning if dropdown bleeds off the right edge
        dropdownContentRef.current.style.right = "0px";
      } else {
        dropdownContentRef.current.style.right = "auto";
      }
    }
  }, [isShow]);

  // Use `pointer-events` to prevent clicks on the hidden dropdown
  const dropdownStyles = isShow
    ? "opacity-100 max-h-screen pointer-events-auto" // Enable pointer events when visible
    : "opacity-0 max-h-0 pointer-events-none"; // Disable pointer events when hidden

  return (
    <div ref={wrapperRef} className={className}>
      <button onClick={() => setIsShow(!isShow)} className="relative cursor-pointer">
        {buttonContent}
      </button>
      <div
        ref={dropdownContentRef}
        className={`z-10 overflow-hidden absolute ${contentClassName} transition-all duration-300 transform origin-top ${dropdownStyles}`}
        style={{
          transitionProperty: "opacity, max-height",
        }}
        onClick={() => closeOnClick && setIsShow(false)}
      >
        {children}
      </div>
    </div>
  );
};
