import { ChangepasswordTab, SecurityTab, InviteTab, LanguageTab } from "./settingstabs";
import Billing from "./Billing";
import { UserProfileTab } from "./UserProfileTab";

export const settingsTabs = [
  {
    id: "profile",
    label: "Profile",
    component: UserProfileTab,
  },
  {
    id: "changePassword",
    label: "Change Password",
    component: ChangepasswordTab,
  },
  {
    id: "billing",
    label: "Billing",
    component: Billing,
  },
  {
    id: "invite",
    label: "Invite a Friend",
    component: InviteTab,
  },
  {
    id: "security",
    label: "Security",
    component: SecurityTab,
  },
  {
    id: "language",
    label: "Language (Beta)",
    component: LanguageTab,
  },
];
