import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { logoutaUser } from "../../store/actions/userdata";
import { Dropdown } from "../Dropdown";
import UserAvatar from "../user/Avatar";
import Skeleton from "react-loading-skeleton";
import { ConfirmationModal } from "../modals/ConfirmationModal";
import { useUserQuery } from "../../api/user/user-queries";
import { useState } from "react";
import UseraccountIcon from "../../assets/svg/profile-fallback-icon.svg";
import classNames from "classnames";

export const ProfileDropdown = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: user, isFetching: isFetchingUser } = useUserQuery();
  const { portfolioView, portfolioInfo } = useSelector((state: any) => state.utility);
  const [showConfirmLogoutModal, setShowConfirmLogoutModal] = useState(false);

  const gotoSetting = () => {
    navigate("/settings");
  };

  const logOutUser = () => {
    dispatch(logoutaUser({}))
      //@ts-expect-error
      .then(() => {
        navigate("/signin");
      });
  };

  return (
    <div>
      {showConfirmLogoutModal && (
        <ConfirmationModal
          modalIsOpen={showConfirmLogoutModal}
          closeModal={() => setShowConfirmLogoutModal(false)}
          submitAction={logOutUser}
          confirmationTitle="Logout"
          confirmationText={<span>Are you sure you want to logout of {t("orgName")}?</span>}
        />
      )}
      <Dropdown
        buttonContent={
          isFetchingUser ? (
            <Skeleton width={75} height={18} />
          ) : (
            <div className="flex items-center gap-x-2 cursor-pointer">
              <UserAvatar
                size="sm"
                avatarUrl={portfolioView ? portfolioInfo?.ownerImageURL ?? UseraccountIcon : user.image_url ?? null}
                onClickAvatar={() => null}
              />
              <span className="text-gray-500 text-md font-medium">
                {portfolioView ? `${portfolioInfo?.ownerName.split(" ")[0] ?? "User"}'s Portfolio` : user.first_name}
              </span>
            </div>
          )
        }
        contentClassName="rounded-md mt-2 right-4"
      >
        <div
          className={classNames(
            "flex flex-col",
            "bg-white shadow-lg rounded-md w-48 p-2",
            "border border-solid border-gray-100"
          )}
        >
          {!portfolioView && (
            <button onClick={gotoSetting} className="p-2 text-p2 text-type rounded-sm hover:bg-primary-alice text-left">
              View Profile
            </button>
          )}
          <button
            onClick={() => setShowConfirmLogoutModal(true)}
            className="p-2 text-p2 rounded-sm transition text-status-danger hover:bg-status-danger-bg text-left"
          >
            Logout
          </button>
        </div>
      </Dropdown>
    </div>
  );
};
