import { useState } from "react";
import classNames from "classnames";
import { CardBrands, PaymentMethod } from "../../../../types/subscriptionTypes";
import JCB from "../../../../assets/svg/JCB.svg";
import UnionPay from "../../../../assets/svg/UnionPay.svg";
import AmericanExpress from "../../../../assets/svg/american-express.svg";
import Discover from "../../../../assets/svg/discover-network.svg";
import MasterCard from "../../../../assets/svg/master-card.svg";
import { ConfirmationModal } from "../../../../components/modals/ConfirmationModal";
import { StarIcon, TrashIcon } from "@heroicons/react/24/outline";
import { StarIcon as StarIconSolid } from "@heroicons/react/24/solid";
import Visa from "../../../../assets/svg/visa.svg";
import { useUpdateUserDefaultPaymentMethodMutation } from "../../../../api/user/user-mutations";

interface IPaymentMethodComp {
  paymentMethod: PaymentMethod;
  active?: boolean;
  deletePaymentMethod: (id: string) => void;
}

export const StripePaymentMethod = ({ paymentMethod, active, deletePaymentMethod }: IPaymentMethodComp) => {
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [confirmChange, setConfirmChange] = useState<boolean>(false);

  const { mutate: updateUserDefaultPaymentMethod } = useUpdateUserDefaultPaymentMethodMutation();

  const onUpdateUserDefaultPaymentMethod = async () => {
    try {
      await updateUserDefaultPaymentMethod(paymentMethod.id);
    } catch (error) {
      console.error("fail: updateUserDefaultPaymentMethod", error);
    }
  };

  return (
    <>
      {confirmDelete && (
        <ConfirmationModal
          modalIsOpen={confirmDelete}
          closeModal={() => setConfirmDelete(false)}
          verificationText=""
          submitAction={() => {
            setConfirmDelete(false);
            deletePaymentMethod(paymentMethod.id);
          }}
          confirmationText={`Are you sure? This card will be removed from your billing payment methods`}
          confirmationTitle={`Delete ${paymentMethod.card_brand} card ending in ${paymentMethod.card_last4}?`}
          submitText={"Delete Card"}
          cancelText={"Cancel"}
        />
      )}

      {confirmChange && (
        <ConfirmationModal
          modalIsOpen={confirmChange}
          closeModal={() => setConfirmChange(false)}
          verificationText=""
          submitAction={() => {
            setConfirmChange(false);
            onUpdateUserDefaultPaymentMethod();
          }}
          confirmationText={`Are you sure you want to set your ${paymentMethod.card_brand} card ending in ${paymentMethod.card_last4} as your default payment method?`}
          confirmationTitle="Change Payment Method"
          submitText={"Change Payment Method"}
          cancelText={"Cancel"}
        />
      )}

      {/* Card Details */}
      <div
        className={classNames(
          "cursor-default",
          "w-full md:max-w-md flex flex-row flex-wrap justify-between gap-2",
          "p-4 rounded-md border border-solid border-gray-200",
          active ? "bg-blue-50" : "bg-gray-100"
        )}
      >
        <div className="flex flex-row">
          {paymentMethod.card_brand === CardBrands.visa && <img src={Visa} alt="card" className="mr-3 my-auto w-12" />}
          {paymentMethod.card_brand === CardBrands.mastercard && (
            <img src={MasterCard} alt="card" className="mr-3 my-auto w-12" />
          )}
          {paymentMethod.card_brand === CardBrands.amex && (
            <img src={AmericanExpress} alt="card" className="mr-3 my-auto w-12" />
          )}
          {paymentMethod.card_brand === CardBrands.jcb && <img src={JCB} alt="card" className="mr-3 my-auto w-12" />}
          {paymentMethod.card_brand === CardBrands.unionpay && (
            <img src={UnionPay} alt="card" className="mr-3 my-auto w-12" />
          )}
          {paymentMethod.card_brand === CardBrands.discover && (
            <img src={Discover} alt="card" className="mr-3 my-auto w-12" />
          )}

          <div className="block pr-2 my-auto">
            <p className="text-sm">{`**** **** **** ${paymentMethod?.card_last4}`}</p>
            <p className="text-xs text-gray-600">
              Expires {paymentMethod.card_exp_month}/{paymentMethod.card_exp_year}
            </p>
          </div>
        </div>

        {/* Actions */}
        <div className={classNames("flex gap-2 items-center", active ? "flex-row-reverse" : "flex-row")}>
          {/* Set Default Payment Method */}
          <button
            type="button"
            className={classNames(
              active ? "cursor-default" : "cursor-pointer",
              active ? "text-blue-600" : "text-gray-400 hover:text-blue-600",
              "p-1 border border-1 border-solid border-transparent"
            )}
            onClick={() => {
              if (!active) {
                setConfirmChange(true);
              }
            }}
          >
            {active ? <StarIconSolid className="h-6 w-6" /> : <StarIcon className="h-6 w-6" />}
          </button>

          {/* Delete Payment Method */}
          {!active && (
            <button
              type="button"
              onClick={() => setConfirmDelete(true)}
              className={classNames(
                "p-1 border border-1 border-solid border-transparent text-gray-400 hover:text-red-600",
                "cursor-pointer"
              )}
            >
              <TrashIcon className="h-6 w-6" />
            </button>
          )}
        </div>
      </div>
    </>
  );
};
