import { useState, useEffect, useCallback } from "react";
import { getFileData, getNameFromURL } from "../viewassets/index";
import { SelectoptionfordeleteIcon } from "../../inputs/selectoptionforassetstable";
import { subStringofName, convertBytestoMB, convertStampDatev2 } from "../../utilities/utilityFunctions";
import { VaultFileDropzone } from "../../../pages/vault/VaultFileDropzone";
import { useRemoveNoteFileMutation, useUploadNoteFileMutation } from "../../../api/note/note-mutations";
import { AccountType } from "../../../types/accountTypes";
import { useDispatch, useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { SET_DID_OPTIMISTIC_UPDATE } from "../../../store/actions/general-store.actions";
import MainButton from "../../buttons/mainButton";
import { VaultFileIcon } from "../../icons/vaultfileicon";

/**
 * AccountFilesManager
 * - Purpose: Manages files associated with a specific portfolio account.
 * - Features:
 *   - Displays a list of current files associated with the account.
 *   - Supports uploading new files using a drag-and-drop interface or a file picker.
 *   - Provides functionality to delete files from the account.
 * - Performance: Optimistically updates the local cache upon adding or removing files, preventing full-page re-renders and ensuring immediate UI updates.
 */
export const AccountFilesManager = ({ account, closeModal }: { account: AccountType; closeModal: () => void }) => {
  const [showFileUploadZone, setShowFileUploadZone] = useState(false);
  const [uploadFileSize] = useState<number>(0);

  useEffect(() => {
    setShowFileUploadZone(account?.files?.length === 0);
  }, [account?.files]);

  const { mutateAsync: uploadFile, isLoading } = useUploadNoteFileMutation();
  const { mutateAsync: removeFile } = useRemoveNoteFileMutation();

  // Manage optimistic updates
  const { id: portfolioId } = useSelector((state: any) => state.activePortfolio);
  const xCurrency = useSelector((state: any) => state.assets.currency.code);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const updateInstitutionsForNewFile = (newFile: any, accountId: string) => {
    queryClient.setQueryData(
      ["X_FETCH_INSTITUTIONS", { id: portfolioId, currency: xCurrency }],
      (oldQueryData: any) => {
        return oldQueryData.map((institution: any) => {
          return {
            ...institution,
            accounts: institution.accounts.map((account: any) => {
              if (account.id === accountId) {
                // Add new file to the account's files array
                return { ...account, files: [...account.files, newFile] };
              }
              return account;
            }),
          };
        });
      }
    );
  };

  const updateInstitutionsForRemovedFile = (fileId: string, accountId: string) => {
    queryClient.setQueryData(
      ["X_FETCH_INSTITUTIONS", { id: portfolioId, currency: xCurrency }],
      (oldQueryData: any) => {
        return oldQueryData.map((institution: any) => {
          return {
            ...institution,
            accounts: institution.accounts.map((account: any) => {
              if (account.id === accountId) {
                // Remove the file from the account's files array
                return { ...account, files: account.files.filter((file: any) => file.id !== fileId) };
              }
              return account;
            }),
          };
        });
      }
    );
  };

  const onDidOptimisticUpdate = () => {
    dispatch({
      type: SET_DID_OPTIMISTIC_UPDATE,
      payload: {
        value: true,
        page: "InstitutionsPage",
      },
    });
  };

  // File actions
  const handleUploadFile = useCallback(
    async (data: { file: File; account_id: string }) => {
      const { file, account_id } = data;

      if (!file) {
        console.error("No file to upload");
        return;
      }

      try {
        const newFile = await uploadFile({
          file,
          createFileInput: {
            account_id: account_id,
          },
        });
        updateInstitutionsForNewFile(newFile, account_id); // Optimistically update the cache
        onDidOptimisticUpdate(); // Register optimistic update in store for use on exit page
        setShowFileUploadZone(false);
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadFile]
  );

  const onDrop = useCallback(
    (files) => {
      handleUploadFile({ file: files?.[0], account_id: account.id });
    },
    [account, handleUploadFile]
  );

  const deleteFile = async (id: string) => {
    try {
      await removeFile({ id });
      updateInstitutionsForRemovedFile(id, account.id); // Optimistically update the cache
      onDidOptimisticUpdate(); // Register optimistic update in store for use on exit page
    } catch (error) {
      console.error("fail: deleteFile", error);
    }
  };

  return (
    <div className="files-tab">
      {showFileUploadZone ? (
        <VaultFileDropzone
          onDrop={onDrop}
          subtitle="Keep important, sensitive documents and files safe e.g contracts, receipts, agreements."
          disabled={isLoading}
        >
          Drag and drop a file or <span className="text-blue-500 p-semibold">Click</span> to upload a single file.
        </VaultFileDropzone>
      ) : (
        <div>
          <div className="w-full flex justify-end py-4">
            <p onClick={() => setShowFileUploadZone(true)} className="text-blue-700 cursor-pointer p-semibold">
              + Upload File
            </p>
          </div>

          <div className="flex flex-col gap-y-5">
            {account?.files?.map(
              (
                file: {
                  created_at: string;
                  id: string;
                  name: string;
                  path: string;
                  size: number;
                },
                i: number
              ) => {
                return (
                  <div key={i} className="w-full flex">
                    <div className="w-full gap-x-4 flex items-end hover:bg-gray-10">
                      <div className="vault-file-icon relative">
                        <VaultFileIcon
                          size="md"
                          mainColor={getFileData(file.name).backgroundColor}
                          foldColor={getFileData(file.name).foldColor}
                        />
                        <p className="absolute font-semibold text-white text-p2"> {getFileData(file.name).filetype}</p>
                      </div>
                      <div
                        className="cursor-pointer hover:text-blue-700"
                        onClick={() => window.open(file.path, "_blank")}
                      >
                        <p className="text-sm font-semibold">
                          {subStringofName(file.name, 37) || getNameFromURL(file.path)}
                        </p>
                        <p className="text-xs italic text-gray-500">
                          <span className="mbl">{convertBytestoMB(file.size || uploadFileSize)}</span>{" "}
                          <span>{convertStampDatev2(file.created_at)}</span>
                        </p>
                      </div>
                    </div>
                    <div>
                      <SelectoptionfordeleteIcon deleteitem={() => deleteFile(file.id)} />
                    </div>
                  </div>
                );
              }
            )}
          </div>
          <div className="asset-class-footer">
            <MainButton size="small" type="secondary" click={closeModal}>
              Close
            </MainButton>
          </div>
        </div>
      )}
    </div>
  );
};
