import { useState } from "react";
import { EmptyNoteComponent } from "../../../pages/portfolio/assets";
import { ConfirmationModal } from "../ConfirmationModal";
import { DocumentsAlert, lastSyncTime } from "../viewassets/index";
import { SelectOptionForNotesIcon } from "../../inputs/selectoptionforassetstable";
import { AccountType } from "../../../types/accountTypes";
import { AccountNotesAddEdit } from "./AccountNotesAddEdit";
import { useDeleteNoteMutation } from "../../../api/note/note-mutations";
import { useTranslation } from "react-i18next";
import MainButton from "../../buttons/mainButton";
import { useDispatch, useSelector } from "react-redux";
import { SET_DID_OPTIMISTIC_UPDATE } from "../../../store/actions/general-store.actions";
import { useQueryClient } from "@tanstack/react-query";

export const AccountNotesManager = ({ account, closeModal }: { account: AccountType; closeModal: () => void }) => {
  const { t } = useTranslation();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [isViewingNote, setIsViewingNote] = useState(false);
  const [note, setNote] = useState({ id: "", title: "", content: "" });
  const [isAddingNote, setIsAddingNote] = useState<boolean>(false);
  const [activeNoteId, setActiveNoteId] = useState<string>("");

  const goBack = () => {
    setIsAddingNote(false);
    setNote({ id: "", title: "", content: "" });
    setIsViewingNote(false);
  };

  const viewNote = (data: any) => {
    setIsAddingNote(true);
    setIsViewingNote(true);
    setNote({
      id: data.id,
      title: data.title,
      content: data.content,
    });
  };

  const openDeleteModal = (noteId: string) => {
    setConfirmDelete(true);
    setActiveNoteId(noteId);
  };

  const openEditModeFromModal = (data: any) => {
    viewNote(data);
    setIsViewingNote(false);
  };

  // Manage optimistic updates
  const { id: portfolioId } = useSelector((state: any) => state.activePortfolio);
  const xCurrency = useSelector((state: any) => state.assets.currency.code);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutateAsync: deleteNote } = useDeleteNoteMutation();

  const updateInstitutionsOptimistically = (deletedNoteId: string) => {
    queryClient.setQueryData(
      [
        "X_FETCH_INSTITUTIONS",
        {
          id: portfolioId,
          currency: xCurrency,
        },
      ],
      (oldQueryData: any) => {
        // Deep clone oldQueryData to avoid direct mutation
        const newQueryData = oldQueryData.map((inst: any) => ({
          ...inst,
          accounts: inst.accounts.map((acc: any) => ({
            ...acc,
            notes: acc.notes.filter((note: any) => note.id !== deletedNoteId),
          })),
        }));

        return newQueryData;
      }
    );
  };

  // Notes actions
  const onDeleteNote = async () => {
    setConfirmDelete(false);

    try {
      await deleteNote({ id: activeNoteId });
      updateInstitutionsOptimistically(activeNoteId); // Optimistic update cache

      // register optimistic update with store to trigger a re-fetch on exit
      dispatch({
        type: SET_DID_OPTIMISTIC_UPDATE,
        payload: {
          value: true,
          page: "InstitutionsPage",
        },
      });
    } catch (error) {
      console.error("fail: onDeleteNote", error);
    }
  };

  return (
    <>
      {confirmDelete && (
        <ConfirmationModal
          modalIsOpen={confirmDelete}
          closeModal={() => {
            setConfirmDelete(false);
          }}
          submitAction={onDeleteNote}
          confirmationText={`This will permanently delete this note and it can not be retrieved on ${t("orgName")}.`}
        />
      )}

      {isAddingNote && (
        <AccountNotesAddEdit
          goBack={goBack}
          note={note}
          setNote={setNote}
          accountId={account.id}
          isViewingNote={isViewingNote}
          setIsViewingNote={setIsViewingNote}
        />
      )}

      {!isAddingNote && (
        <div className="flex flex-col flex-grow w-full">
          <DocumentsAlert />

          {account?.notes?.length === 0 ? (
            <div className="w-full py-8 gap-y-5 flex flex-col justify-center">
              <EmptyNoteComponent
                title="You have no notes"
                message="Save special notes, instructions and important information e.g reference numbers, contact persons."
              />
              <MainButton click={() => setIsAddingNote(true)} type="primary" size="big">
                Add a Note
              </MainButton>
            </div>
          ) : (
            <>
              <div className="">
                <div className="w-full flex justify-end py-4">
                  <p onClick={() => setIsAddingNote(true)} className="text-blue-700 cursor-pointer p-semibold">
                    + Add Note
                  </p>
                </div>
                <div>
                  {account?.notes?.map((note: any, index: number) => {
                    return (
                      <div key={index} className="flex justify-between items-center note-item">
                        <div className="p-3 w-full cursor-pointer hover:bg-neutral-200" onClick={() => viewNote(note)}>
                          <div className="text-type text-p2 font-medium">
                            {!note?.title ? note?.content?.substring(0, 20) : note?.title}
                          </div>
                          <div className="text-type-200 text-p3 font-normal">
                            Last edited:{" "}
                            {note?.updated_at ? lastSyncTime(note?.updated_at) : lastSyncTime(note?.created_at)}
                          </div>
                        </div>
                        <SelectOptionForNotesIcon
                          openDeleteModal={() => openDeleteModal(note?.id)}
                          openEditModeFromModal={() => openEditModeFromModal(note)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="asset-class-footer">
                <MainButton size="small" type="secondary" click={closeModal}>
                  Close
                </MainButton>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
