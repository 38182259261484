export const CopyIcon = ({ height, width, color }: any) => {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39 40H13C12.2 40 11.5 39.7 10.9 39.1C10.3 38.5 10 37.8 10 37V5C10 4.2 10.3 3.5 10.9 2.9C11.5 2.3 12.2 2 13 2H29.15C29.55 2 29.9417 2.08333 30.325 2.25C30.7083 2.41667 31.0333 2.63333 31.3 2.9L41.1 12.7C41.3667 12.9667 41.5833 13.2917 41.75 13.675C41.9167 14.0583 42 14.45 42 14.85V37C42 37.8 41.7 38.5 41.1 39.1C40.5 39.7 39.8 40 39 40ZM28.9 5H13V37H39V14.9H30.4C29.9667 14.9 29.6083 14.7583 29.325 14.475C29.0417 14.1917 28.9 13.8333 28.9 13.4V5ZM7 46C6.2 46 5.5 45.7 4.9 45.1C4.3 44.5 4 43.8 4 43V13.55C4 13.1167 4.14167 12.7583 4.425 12.475C4.70833 12.1917 5.06667 12.05 5.5 12.05C5.93333 12.05 6.29167 12.1917 6.575 12.475C6.85833 12.7583 7 13.1167 7 13.55V43H30.4C30.8333 43 31.1917 43.1417 31.475 43.425C31.7583 43.7083 31.9 44.0667 31.9 44.5C31.9 44.9333 31.7583 45.2917 31.475 45.575C31.1917 45.8583 30.8333 46 30.4 46H7ZM13 5V14.9V5V37V5Z"
        fill={color || "#1E2329"}
      />
    </svg>
  );
};
