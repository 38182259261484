import { ArrowUpCircleIcon } from "@heroicons/react/24/outline";
interface UpgradeBtnProps {
  label?: string;
  onClick?: () => void;
}

const UpgradeBtn = (props: UpgradeBtnProps) => {
  const { label = "Upgrade to Premium", onClick } = props;

  return (
    <button
      type="button"
      onClick={onClick}
      className="w-full bg-primary-beau rounded-md py-2 gap-x-2 flex items-center justify-center text-sm text-primary font-normal"
    >
      <ArrowUpCircleIcon className="w-5 h-5" />
      <span className="text-md">{label}</span>
    </button>
  );
};

export default UpgradeBtn;
