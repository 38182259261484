import Skeleton from "react-loading-skeleton";

const VaultSkeleton = () => {
  return (
    <div className="flex flex-wrap gap-x-4 gap-y-4">
      {[...Array(6)].map((_, index) => (
        <div key={index} className="w-full md:w-48 h-max-[176px] rounded-md">
          <div className="flex flex-col p-4 pb-5 h-full border border-neutral-200 rounded-md">
            <div className="mt-2 mb-5 ml-2.5">
              <Skeleton width={55} height={55} borderRadius={6} />
            </div>
            <Skeleton width={150} height={15} borderRadius={4} className="mb-3 ml-1.5" />
            <Skeleton width={125} height={15} borderRadius={4} className="ml-1.5" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default VaultSkeleton;
