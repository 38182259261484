import { gql } from "@apollo/client";

export const FETCH_PORTFOLIOS = gql`
  query FetchPortfolios {
    portfolios {
      id
      name
      owner
      user_id
      created_at
      user {
        first_name
        last_name
      }
    }
  }
`;

export const FETCH_HISTORY = gql`
  query FetchHistory($for: String!, $from: String!, $id: String!, $interval: String!, $to: String!, $currency: String) {
    history(for: $for, from: $from, id: $id, interval: $interval, to: $to, currency: $currency) {
      interval
      money
    }
  }
`;
