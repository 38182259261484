import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { toast } from "react-toastify";
import { store, persistor } from "./store";
import { Pageloader } from "./components/utilities/pageloader";
import { MyRouter } from "./components/router";
import reportWebVitals from "./reportWebVitals";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./api/client";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import "./App.scss";
import "react-loading-skeleton/dist/skeleton.css";
import { VaultProvider } from "./pages/vault/VaultProvider";
import { Capacitor } from "@capacitor/core";
import "./i18n/config";
import NetworkStatusHandler from "./components/NetworkStatusHandler";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  tracesSampleRate: process.env.REACT_APP_DEPLOY_ENV === "beta" ? 0.1 : 0.5,
  beforeSend: (event) => {
    if (window.location.hostname === "localhost") {
      return null;
    }
    return event;
  },
});

toast.configure({
  draggable: false,
  autoClose: 4000,
  hideProgressBar: true,
  closeButton: false,
  className: Capacitor.isNativePlatform() ? "Toastify--native" : "",
});

const rootElement = document.getElementById("root");

const app = (
  <QueryClientProvider client={queryClient}>
    <VaultProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <NetworkStatusHandler>
            <React.StrictMode>
              <Pageloader />
              <MyRouter />
            </React.StrictMode>
          </NetworkStatusHandler>
        </PersistGate>
      </Provider>
    </VaultProvider>
  </QueryClientProvider>
);

if (rootElement!.hasChildNodes()) {
  ReactDOM.hydrate(app, rootElement);
} else {
  ReactDOM.render(app, rootElement);
}

reportWebVitals();
