import Modal from "react-modal";
import { memo, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CreateVaultNoteInput, UpdateVaultNoteInput } from "../../types/vaultTypes";
import { useCreateVaultNoteMutation, useUpdateVaultNoteMutation } from "../../api/vault/vault-mutations";
import { VaultNoteManage } from "./VaultNoteManage";
import { useVault } from "./VaultProvider";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";

const EMPTY_NOTE = { id: "", title: "", entry: "" };

export const VaultNoteModal = memo(
  ({
    details,
    folderId,
    modalIsOpen,
    closeModal,
  }: {
    details?: any | null;
    folderId: string;
    modalIsOpen: boolean;
    closeModal: () => void;
  }) => {
    const { setCurrentNote } = useVault();
    const [note, setNote] = useState(EMPTY_NOTE);
    const { mutateAsync: createNote, isLoading: isCreating } = useCreateVaultNoteMutation();
    const { mutateAsync: updateVaultNote, isLoading: isUpdating } = useUpdateVaultNoteMutation();

    const handleCreateNote = async (data: CreateVaultNoteInput) => {
      try {
        await createNote({
          createVaultNoteInput: data,
        });
        setNote(EMPTY_NOTE);
        setCurrentNote(null);
        closeModal();
      } catch (error) {
        console.error(error);
      }
    };

    const handleUpdateVaultNote = async (data: UpdateVaultNoteInput) => {
      try {
        await updateVaultNote(data);
        setNote(EMPTY_NOTE);
        setCurrentNote(null);
      } catch (error) {
        console.error("fail: handleUpdateVaultNote", error);
      }
    };

    useEffect(() => {
      setNote({ id: details?.id, title: details?.title, entry: details?.description });

      return () => {
        setNote(EMPTY_NOTE);
      };
    }, [details]);

    const isNewNote = !details;

    const submit = () => {
      if (note?.entry === "" || note?.title === "") {
        toast.error("Please enter a title and description");
        return;
      }

      if (isNewNote) {
        let data: CreateVaultNoteInput = {
          description: note?.entry,
          title: note?.title,
          vault_folder_id: folderId,
        };
        handleCreateNote(data);
      } else {
        if (note) {
          let data: UpdateVaultNoteInput = {
            description: note?.entry,
            title: note?.title,
            id: note.id,
          };
          handleUpdateVaultNote(data);
        }
      }

      setCurrentNote(null);
    };

    const closeAction = () => {
      setNote(EMPTY_NOTE);
      setCurrentNote(null);
      closeModal();
    };

    const isNative = Capacitor.isNativePlatform();

    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Vault Note Modal"
        className={classNames("modal-container", isNative && "native-modal")}
        overlayClassName={"modal-container-overlay"}
      >
        <VaultNoteManage
          isNewNote={isNewNote}
          note={note}
          setNote={setNote}
          submitAction={submit}
          cancelAction={closeAction}
          closeModal={closeAction}
          isLoading={isCreating || isUpdating}
        />
      </Modal>
    );
  }
);
