import { Lifecheckservice } from "../../../services/lifecheck";
import { formatedError } from "../../../services/formaterrors";
import { SET_BENEFICIARY_DATA, SET_INHERITED_PORTFOLIO_DATA } from "./types";

let LifecheckService = new Lifecheckservice();

export const fetchInheritedPortfolios = () => (dispatch: any) => {
  return LifecheckService.fetchInheritedPortfolios().then(
    (data) => {
      dispatch({
        type: SET_INHERITED_PORTFOLIO_DATA,
        payload: data,
      });
      dispatch({
        type: SET_BENEFICIARY_DATA,
        payload: { inheritedFetched: true },
      });
      return Promise.resolve(data);
    },

    (error) => {
      dispatch({
        type: "",
      });
      let response = formatedError(error, dispatch);
      return Promise.reject(response);
    }
  );
};

export const getQuestions = (payload: string) => (dispatch: any) => {
  return LifecheckService.getQuestions(payload).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      dispatch({
        type: "",
      });
      let response = formatedError(error, dispatch);
      return Promise.reject(response);
    }
  );
};

export const verifyAnswer =
  (payload: { recipientEmail: string; userId: number; questionId: string; answer: string }) => (dispatch: any) => {
    return LifecheckService.verifyAnswer(payload).then(
      (data) => {
        return Promise.resolve(data);
      },
      (error) => {
        dispatch({
          type: "",
        });
        let response = formatedError(error, dispatch);
        return Promise.reject(response);
      }
    );
  };

export const nonUserPasswordSetup = (payload: { recipientEmail: string; password: string }) => (dispatch: any) => {
  return LifecheckService.nonUserPasswordSetup(payload).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      dispatch({
        type: "",
      });
      let response = formatedError(error, dispatch);
      return Promise.reject(response);
    }
  );
};

export const fetchSharedPortfolio = (recipientId: string) => (dispatch: any) => {
  return LifecheckService.fetchSharedPortfolio(recipientId).then(
    (res: any) => {
      const payload = {
        assetList: res?.assetList,
        liabilityList: res?.liabilityList,
        vaultFiles: res?.vaultFolderResponseList,
      };

      dispatch({
        type: SET_BENEFICIARY_DATA,
        payload: { activePortfolio: payload },
      });
      return Promise.resolve(res);
    },

    (error) => {
      dispatch({
        type: "",
      });
      let response = formatedError(error, dispatch);
      return Promise.reject(response);
    }
  );
};
