import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ConnectAccountModal from "../../components/modals/connectAccount/ConnectAccountModal";
import { useCurrentUserGql } from "../../graphql/current-user.gql-hook";
import { FETCH_USER } from "../../graphql/current-user.gql-query";
import { logoutaUser } from "../../store/actions/userdata";
import { loaderData, setUtilityData } from "../../store/actions/utility";
import { SET_USER_STATE } from "../../store/reducers/userdatav2/types";
import useAuth from "../../hooks/useAuth";
import { PaymentPartners } from "../../types/subscriptionTypes";
import usePortfolio from "../../hooks/usePortfolio";

const MobileConnection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const jwt = location.search.split("jwt=")[1];
  const { fetchUser, fetchUserPreferences } = useCurrentUserGql();
  const { getUserConfig } = useAuth();
  const { handleGetPortfolio } = usePortfolio();
  const { handleGetActivePortfolio } = usePortfolio();
  const activePortfolioId = useSelector((state: any) => state.activePortfolio.id);

  const getAppData = () =>
    Promise.all([
      getUserConfig({ variables: { partner: PaymentPartners.STRIPE } }),
      handleGetPortfolio(),
      fetchUser(),
      fetchUserPreferences(),
    ]);

  const [verifyJwt] = useLazyQuery(FETCH_USER, {
    context: {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    },
    errorPolicy: "none",
    onCompleted() {
      dispatch({ type: SET_USER_STATE, payload: { token: jwt } });
      getAppData();
    },
    onError(error: any) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
      navigate("/signin?mobile-connection=false");
    },
  });

  useEffect(() => {
    loginUser();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (activePortfolioId) {
      dispatch(setUtilityData(loaderData(true, "")));
      handleGetActivePortfolio(activePortfolioId);
    }
    // eslint-disable-next-line
  }, [activePortfolioId]);

  const logOutUser = () => {
    dispatch(logoutaUser({}))
      //@ts-expect-error
      .then(() => {
        navigate("/signin");
      });
  };

  const loginUser = () => {
    if (!jwt) return logOutUser();
    dispatch(setUtilityData(loaderData(true, "")));
    verifyJwt();
  };

  return (
    <div>
      <ConnectAccountModal fromMobileApp />
    </div>
  );
};

export default MobileConnection;
