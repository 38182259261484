import { useEffect } from "react";

export const useAssetClassSubmitBtn = (cb: any) => {
  useEffect(() => {
    let submitBtn = document.getElementById("cryptoFormBtn");
    submitBtn?.classList.remove("hidden");
    if (submitBtn) {
      submitBtn.addEventListener("click", cb);
    }
    return () => {
      submitBtn?.classList.add("hidden");
      submitBtn?.removeEventListener("click", cb);
    };
  }, [cb]);
};
