export const AsyncSelectCustomStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    border: state.isSelected || state.isFocused ? "1px solid black" : "1px solid #EDEFF2",
    outline: "0px",
    boxShadow: "none",
    fontSize: "14px",
    padding: "5px 0px",
    background: "#F6F7F9",
    transform: "translateY(-2px)",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: "14px",
  }),
  placeholder: (base: any) => ({
    ...base,
    fontWeight: 400,
    fontSize: "14px",
    color: "#A1ACAF",
  }),
};
