import "./collaboration.scss";
import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";
import MainButton from "../../buttons/mainButton";
import CreatableSelect from "react-select/creatable";
import { StylesConfig } from "react-select";
import { ReactComponent as Lock } from "../../../assets/svg/lock.svg";
import { ReactComponent as Unchecked } from "../../../assets/svg/selected-empty.svg";
import { ReactComponent as Checked } from "../../../assets/svg/selected.svg";
import { ReactComponent as Tag } from "../../../assets/svg/tag.svg";
import { useDispatch, useSelector } from "react-redux";
import { emailValidator } from "../../../helper/helper";
import { toast } from "react-toastify";
import { LabelType } from "../../../types/categoryTypes";
import { ConfirmationModal } from "../ConfirmationModal";
import { loaderData, setUtilityData } from "../../../store/actions/utility";
import { useNavigate } from "react-router-dom";
import { CollaboratorType } from "../../../types/userTypes";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import dayjs from "dayjs";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface ICollaborationModal {
  modalIsOpen: boolean;
  portfolioModalView: string;
  emails: string[];
  setEmails: React.Dispatch<React.SetStateAction<string[]>>;
  labelIds: string[];
  setLabelIds: React.Dispatch<React.SetStateAction<string[]>>;
  setPortfolioModalView: React.Dispatch<React.SetStateAction<string>>;
  handleSharePortfolio: () => void;
  selectedCollaborator: CollaboratorType;
  removeCollaborator: () => void;
  leavePortfolio: () => void;
  declinePortofolioInvite: () => void;
  updatePortfolioShared: () => void;
}
export const CollaborationModal = ({
  modalIsOpen,
  portfolioModalView,
  emails,
  setEmails,
  labelIds,
  setLabelIds,
  setPortfolioModalView,
  handleSharePortfolio,
  selectedCollaborator,
  removeCollaborator,
  leavePortfolio,
  declinePortofolioInvite,
  updatePortfolioShared,
}: ICollaborationModal) => {
  const closeModal = () => setPortfolioModalView("");
  const isNative = Capacitor.isNativePlatform();

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Share Portfolio Modal"
      className={classNames("viewassetsdetails postionmoal", isNative && "native-modal")}
      overlayClassName={"modal-container-overlay"}
    >
      {portfolioModalView === "share-portfolio" && (
        <SharePortfolioModal
          closeModal={closeModal}
          setPortfolioModalView={setPortfolioModalView}
          setEmails={setEmails}
          emails={emails}
        />
      )}
      {portfolioModalView === "select-portfolio" && (
        <SelectPortfolio
          closeModal={closeModal}
          labelIds={labelIds}
          setLabelIds={setLabelIds}
          handleSharePortfolio={handleSharePortfolio}
        />
      )}
      {portfolioModalView === "edit-portfolio" && (
        <EditCollaborator
          selectedCollaborator={selectedCollaborator}
          closeModal={closeModal}
          labelIds={labelIds}
          setLabelIds={setLabelIds}
          removeCollaborator={removeCollaborator}
          setPortfolioModalView={setPortfolioModalView}
          updatePortfolioShared={updatePortfolioShared}
        />
      )}
      {portfolioModalView === "view-shared-portfolio" && (
        <ViewCollaboratorPortfolio
          closeModal={closeModal}
          selectedCollaborator={selectedCollaborator}
          leavePortfolio={leavePortfolio}
          setPortfolioModalView={setPortfolioModalView}
          declinePortofolioInvite={declinePortofolioInvite}
        />
      )}
    </Modal>
  );
};

interface ISharePortfolioModal {
  closeModal: () => void;
  setPortfolioModalView: React.Dispatch<React.SetStateAction<string>>;
  emails: string[];
  setEmails: React.Dispatch<React.SetStateAction<string[]>>;
}

export const SharePortfolioModal = ({ closeModal, setPortfolioModalView, emails, setEmails }: ISharePortfolioModal) => {
  const customStylesSelect: StylesConfig<any, true> = {
    control: (provided: any) => ({
      ...provided,
      border: "1px solid #EDEFF2",
      outline: "0px",
      boxShadow: "none",
      fontSize: "14px",
      padding: "0rem",
      background: "#F6F7F9;",
    }),
    option: (provided: any) => ({
      ...provided,
      fontSize: "14px",
      width: "100%",
      color: "#1E2329",
      background: "#fff",
      "&:hover": {
        background: "#F6F7F9",
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      boxShadow: "0px 9px 20px 6px rgba(79, 93, 109, 0.2)",
    }),
  };
  const portfolioOtp = useSelector((state: any) => state.portfolios.portfolioOtp);
  const nextModal = () => {
    if (emails.length < 1) {
      toast.error("Collaboarator email address is required");
      return;
    }
    setPortfolioModalView("select-portfolio");
  };
  // const [showAccess, setShowAccess] = useState<boolean>(false);

  const addNewEmail = (e: string) => {
    if (!emailValidator(e)) {
      toast.error("Invalid email address");
      return;
    }
    let allEmails: string[] = [...emails];
    if (allEmails.includes(e)) return;
    else allEmails.push(e);
    setEmails(allEmails);
  };

  const handleRemoveMail = (email: string) => {
    let allEmails: string[] = [...emails];
    allEmails.splice(allEmails.indexOf(email), 1);
    setEmails(allEmails);
  };
  return (
    <Fragment>
      <div className="flex justify-end mt-6">
        <button type="button" onClick={closeModal} className="closeicon cursor-pointer">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>
      <div className="flex flex-col justify-between controller">
        <div>
          <h3 className="font-semibold text-h4">Share Portfolio</h3>
          <p className="mt-4 mb-2 text-p2">Collaborators emails</p>
          <div className="relative permission">
            <CreatableSelect
              formatCreateLabel={(inputText) => `Add ${inputText}`}
              styles={customStylesSelect}
              //   onChange={handleSelectOnchange}
              onCreateOption={addNewEmail}
              placeholder="Enter collaborator email"
            />
            {/* <div className="access">
              <div className="flex bg-transparent cursor-pointer text-p2" onClick={() => setShowAccess(!showAccess)}>
                Can view <ArrowdownIcon />
              </div>
              {showAccess && (
                <OutsideClickHandler onOutsideClick={() => setShowAccess(false)}>
                  <div className="select-permision">
                    <button>Can View</button>
                    <button>Can Edit</button>
                    <button className="text-status-danger">Remove</button>
                  </div>
                </OutsideClickHandler>
              )}
            </div> */}
          </div>
          <div className="mt-5 mails">
            {emails.map((mail: string, i: number) => (
              <div key={i} className="my-4 text-type flex items-center justify-between">
                <p className="flex items-center text-p2">
                  <span className="text-p3 mr-2 text-primary-cobalt bg-white">
                    {mail.substring(0, 1).toLocaleUpperCase()}
                  </span>
                  {mail}
                </p>
                <button onClick={() => handleRemoveMail(mail)}>
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </div>
            ))}
          </div>
        </div>
        <div>
          <h2 className="font-semibold text-h4 text-center mb-6">{portfolioOtp}</h2>
          <p className="bg-primary-alice text-center text-type text-p3 flex justify-center items-center p-2">
            <Lock />
            This portfolio is OTP protected. Share this passcode only with your collaborators.
          </p>
        </div>
      </div>
      <div className="flex justify-end gap-3 border-t mt-8 absolute bottom-0 w-full right-0 p-6">
        <MainButton type="secondary" size="small" click={closeModal}>
          Cancel
        </MainButton>
        <MainButton type="primary" size="small" click={nextModal}>
          Next
        </MainButton>
      </div>
    </Fragment>
  );
};

interface ISelectPortfolio {
  closeModal: () => void;
  labelIds: string[];
  setLabelIds: React.Dispatch<React.SetStateAction<string[]>>;
  handleSharePortfolio: () => void;
}

export const SelectPortfolio = ({ closeModal, labelIds, setLabelIds, handleSharePortfolio }: ISelectPortfolio) => {
  const labels = useSelector((state: any) => state.portfolios.labels);

  const selectLabels = (id: string) => {
    let allIds: string[] = [...labelIds];
    if (allIds.includes(id)) allIds.splice(allIds.indexOf(id), 1);
    else allIds.push(id);
    setLabelIds(allIds);
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const allIds: string[] = labels.map((label: LabelType) => label.id);
    if (e.target.checked) setLabelIds(allIds);
    else setLabelIds([]);
  };

  return (
    <Fragment>
      <div className="flex justify-end mt-6">
        <button type="button" onClick={closeModal} className="closeicon cursor-pointer">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>

      <div className="select-portfolio">
        <h3 className="font-semibold text-h4">Share Portfolio</h3>
        <p className="mt-4 mb-2 text-p2">What would you like to share with your collaborators?</p>

        <div className="labels mb-2">
          <label>
            {labels.length === labelIds.length ? <Checked /> : <Unchecked />}
            <Tag />
            <input type="checkbox" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectAll(e)} /> All
            Label
          </label>
        </div>
        <div className="labels others">
          <p className="text-p2 text-type-300">Select Label</p>
          {labels.map((label: LabelType, i: number) => (
            <label className="my-2" key={i}>
              {labelIds.includes(label.id) ? <Checked /> : <Unchecked />}
              <input type="checkbox" onChange={() => selectLabels(label.id)} /> {label.name}
            </label>
          ))}
        </div>
      </div>

      <div className="flex justify-end gap-3 border-t mt-8 absolute bottom-0 w-full right-0 p-6 buttons">
        <MainButton type="secondary" size="small" click={closeModal}>
          Cancel
        </MainButton>
        <MainButton type="primary" size="small" extraClasses="flex items-center" click={handleSharePortfolio}>
          <span>
            <Lock />
          </span>
          Invite Collaborators
        </MainButton>
      </div>
    </Fragment>
  );
};

interface IEditCollaborator {
  closeModal: () => void;
  selectedCollaborator: CollaboratorType;
  labelIds: string[];
  setLabelIds: React.Dispatch<React.SetStateAction<string[]>>;
  removeCollaborator: (args: { variables: {} }) => void;
  setPortfolioModalView: React.Dispatch<React.SetStateAction<string>>;
  updatePortfolioShared: (args: { variables: {} }) => void;
}

const EditCollaborator = ({
  closeModal,
  selectedCollaborator,
  labelIds,
  setLabelIds,
  removeCollaborator,
  setPortfolioModalView,
  updatePortfolioShared,
}: IEditCollaborator) => {
  const { activePortfolioId, labels } = useSelector((state: any) => state.portfolios);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const dispatch = useDispatch();

  const selectLabels = (id: string) => {
    let allIds: string[] = [...labelIds];
    if (allIds.includes(id)) allIds.splice(allIds.indexOf(id), 1);
    else allIds.push(id);
    setLabelIds(allIds);
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const allIds: string[] = labels.map((label: LabelType) => label.id);
    if (e.target.checked) setLabelIds(allIds);
    else setLabelIds([]);
  };

  const handleRemovePortfolio = () => {
    dispatch(setUtilityData(loaderData(true, "")));
    setDeleteModal(false);
    removeCollaborator({
      variables: {
        portfolioUnShareInput: {
          emails: [selectedCollaborator.email],
          portfolio_id: activePortfolioId,
        },
      },
    });
    setPortfolioModalView("");
  };

  const handleUpdatePortfolio = () => {
    dispatch(setUtilityData(loaderData(true, "")));
    setDeleteModal(false);
    updatePortfolioShared({
      variables: {
        portfolioShareEditInput: {
          id: selectedCollaborator.id,
          label_ids: labelIds,
        },
      },
    });
    setPortfolioModalView("");
  };

  useEffect(() => {
    setLabelIds(selectedCollaborator.shared_labels.map((label: any) => label.label_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollaborator]);

  return (
    <Fragment>
      {deleteModal && (
        <ConfirmationModal
          modalIsOpen={deleteModal}
          closeModal={() => {
            setDeleteModal(false);
          }}
          submitAction={handleRemovePortfolio}
          confirmationText={
            <span>
              Are you sure to remove{" "}
              <span className="font-semibold">
                {selectedCollaborator.user
                  ? selectedCollaborator.user.first_name + " " + selectedCollaborator.user.last_name
                  : selectedCollaborator.email}
              </span>{" "}
              from this portfolio?
            </span>
          }
        />
      )}
      <div className="flex justify-end mt-6">
        <button type="button" onClick={closeModal} className="cursor-pointer">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>
      <h3 className="text-h5 text-type font-semibold">
        {selectedCollaborator.user
          ? selectedCollaborator.user.first_name + " " + selectedCollaborator.user.last_name
          : selectedCollaborator.email}
      </h3>

      <span className="text-type-200 text-p2">{dayjs(selectedCollaborator.created_at).format("D/MM/YYYY")}</span>
      <span className={`mobile-status ml-2 ${selectedCollaborator.status.toLocaleLowerCase()}`}>
        {selectedCollaborator.status}
      </span>

      <div className="select-portfolio mt-5">
        <p className="text-type-100 text-p3 mb-2"> What the collaborator sees</p>
        <div className="labels mb-2">
          <label>
            {labels.length === labelIds.length ? <Checked /> : <Unchecked />}
            <Tag />
            <input type="checkbox" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectAll(e)} /> All
            Label
          </label>
        </div>
        <div className="labels edit-other-labels">
          <p className="text-p2 text-type-300">Select Label</p>
          {labels.map((label: LabelType, i: number) => (
            <label className="my-2" key={i}>
              {labelIds.includes(label.id) ? <Checked /> : <Unchecked />}
              <input type="checkbox" onChange={() => selectLabels(label.id)} /> {label.name}
            </label>
          ))}
        </div>
      </div>
      <button className="text-p2 text-status-danger underline font-semibold mt-5" onClick={() => setDeleteModal(true)}>
        Remove Collaborator
      </button>
      <div className="flex justify-end gap-3 border-t mt-8 absolute bottom-0 w-full right-0 p-6 buttons">
        <MainButton type="secondary" size="small" click={closeModal}>
          Cancel
        </MainButton>
        <MainButton type="primary" size="small" extraClasses="flex items-center" click={handleUpdatePortfolio}>
          Update
        </MainButton>
      </div>
    </Fragment>
  );
};

interface IViewCollaboratorPortfolio {
  closeModal: () => void;
  selectedCollaborator: CollaboratorType;
  leavePortfolio: (args: { variables: {} }) => void;
  setPortfolioModalView: React.Dispatch<React.SetStateAction<string>>;
  declinePortofolioInvite: (args: { variables: {} }) => void;
}

const ViewCollaboratorPortfolio = ({
  closeModal,
  selectedCollaborator,
  leavePortfolio,
  setPortfolioModalView,
  declinePortofolioInvite,
}: IViewCollaboratorPortfolio) => {
  const [leaveModal, setLeaveModal] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const modalAction = () => {
    dispatch(setUtilityData(loaderData(true, "")));
    switch (leaveModal) {
      case "leave":
        leavePortfolio({
          variables: {
            portfolioId: selectedCollaborator.portfolio.id,
          },
        });
        break;
      case "decline":
        declinePortofolioInvite({
          variables: {
            portfolioId: selectedCollaborator.portfolio.id,
          },
        });
        break;
      case "delete":
        leavePortfolio({
          variables: {
            portfolioId: selectedCollaborator.portfolio.id,
          },
        });
        break;

      default:
        return;
    }
    setLeaveModal("");
    setPortfolioModalView("");
  };

  const acceptInvite = () => {
    navigate("/verify-portfolio-access?id=" + selectedCollaborator.portfolio.id);
  };

  return (
    <Fragment>
      {leaveModal !== "" && (
        <ConfirmationModal
          modalIsOpen={leaveModal !== ""}
          closeModal={() => {
            setLeaveModal("");
          }}
          submitAction={modalAction}
          confirmationText={
            leaveModal === "leave" ? (
              <span>
                Are you sure to leave{" "}
                <span className="font-semibold">{selectedCollaborator?.portfolio?.user?.first_name + "'s"}</span>{" "}
                portfolio?
              </span>
            ) : leaveModal === "decline" ? (
              "Decline portfolio invite? You can always be reinvited"
            ) : (
              "Delete invite? You can always be reinvited"
            )
          }
        />
      )}
      <div className="flex justify-end mt-6">
        <button type="button" onClick={closeModal} className="closeicon cursor-pointer">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>
      <h3 className="text-h5 text-type font-semibold">
        {selectedCollaborator.portfolio.user?.first_name + " " + selectedCollaborator.portfolio.user?.last_name}
      </h3>
      <div className="mt-2 mb-6">
        <span className="text-type-200 text-p2">{dayjs(selectedCollaborator.created_at).format("D/MM/YYYY")}</span>
        <span className={`mobile-status ml-2 ${selectedCollaborator.status.toLocaleLowerCase()}`}>
          {selectedCollaborator.status}
        </span>
      </div>

      {selectedCollaborator.status === "ACCEPTED" && (
        <>
          <div className="mb-6">
            <p className="text-p2 font-medium text-type">Open Portfolio</p>
            <p className="text-p3">
              You collaborate with{" "}
              {selectedCollaborator.portfolio.user?.first_name + " " + selectedCollaborator.portfolio.user?.last_name}{" "}
              on their portfolio with access to view only. This means that you are limited to view and download only. To
              do more, request edit access from portfolio owner.
            </p>
            <button
              className="mt-5 block text-p2 text-primary underline font-semibold"
              onClick={() => navigate(`/shared/label/${selectedCollaborator.portfolio.id}`)}
            >
              Open Portfolio
            </button>
          </div>
          <div>
            <p className="text-p2 font-medium text-type">Leave Portfolio</p>
            <p className="text-p3">
              Leaving this portfolio means you lose your access to the portfolio and will no longer be able to
              collaborate with{" "}
              {selectedCollaborator.portfolio.user?.first_name + " " + selectedCollaborator.portfolio.user?.last_name}.
            </p>
            <button
              className="mt-5 block text-p2 text-status-danger underline font-semibold"
              onClick={() => setLeaveModal("leave")}
            >
              Leave Portfolio
            </button>
          </div>
        </>
      )}

      {selectedCollaborator.status === "PENDING" && (
        <>
          <div className="mb-6">
            <p className="text-p2 font-medium text-type">Accept Invite</p>
            <p className="text-p3">
              {selectedCollaborator.portfolio.user?.first_name + " " + selectedCollaborator.portfolio.user?.last_name}{" "}
              has granted you rights to view and edit their portfolio with them. Accept Invite to gain access and begin
              collaboration
            </p>
            <button className="mt-5 block text-p2 text-primary underline font-semibold" onClick={acceptInvite}>
              Accept Invite
            </button>
          </div>
          <div>
            <p className="text-p2 font-medium text-type">Decline Invite</p>
            <p className="text-p3">
              Not interested in collaborating with{" "}
              {selectedCollaborator.portfolio.user?.first_name + " " + selectedCollaborator.portfolio.user?.last_name}.
            </p>
            <button
              className="mt-5 block text-p2 text-status-danger underline font-semibold"
              onClick={() => setLeaveModal("decline")}
            >
              Decline Invite
            </button>
          </div>
        </>
      )}

      {selectedCollaborator.status === "DECLINED" && (
        <div>
          <p className="text-p2 font-medium text-type">Delete</p>
          <p className="text-p3">Delete this to completely remove this from your log.</p>
          <button
            className="mt-5 block text-p2 text-status-danger underline font-semibold"
            onClick={() => setLeaveModal("delete")}
          >
            Delete
          </button>
        </div>
      )}
    </Fragment>
  );
};
