import { combineReducers } from "redux";
import userdatatReducer from "./userdata";
import userdataReducer from "./userdatav2";
import utilityReducer from "./utility";
import assetsReducer from "./assetsreducers";
import LifecheckReducer from "./lifecheckreducers";
import vaultReducer from "./vaultreducers";
import portfolioReducer from "./portfolioreducer";
import reportReducer from "./report";
import activePortfolio from "./portfolio.reducer";
import currentUser from "./current-user.reducer";
import generalStore from "./general-store.reducer";

export default combineReducers({
  userdata: userdatatReducer,
  // TODO: state.user should/would be replaced by state.currentUser
  user: userdataReducer,
  utility: utilityReducer,
  // TODO: state.assets should/would be replaced by state.activePortfolio
  assets: assetsReducer,
  lifecheck: LifecheckReducer,
  vault: vaultReducer,
  // TODO: state.portfolios should/would be replaced by state.activePortfolio
  portfolios: portfolioReducer,
  report: reportReducer,
  activePortfolio,
  currentUser,
  generalStore,
});
