import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as FileUploadIcon } from "../../assets/svg/file-upload.svg";
import { ReactComponent as FolderUploadIcon } from "../../assets/svg/folder-upload.svg";
import { ReactComponent as NoteIcon } from "../../assets/svg/document.svg";
import { ReactComponent as FolderIcon } from "../../assets/svg/foldericon.svg";
import { VerticalMoreIcon } from "../icons/verticalmore";
import { ReactComponent as ThreeDotsIcon } from "../../assets/svg/more-horizontal.svg";
import { downloadFileV2 } from "../../services/utilty/fileDownload";
import { ReactComponent as PremiumCrown } from "../../assets/svg/premium-crown.svg";
import { PortfolioState } from "../../types/portfolioTypes";
import { SubscriptionStatus } from "../../types/subscriptionTypes";
import { DOWNLOAD_VAULT_DOCUMENT } from "../../graphql/vault";
import { ApolloError, useMutation } from "@apollo/client";
import { loaderData, setUtilityData } from "../../store/actions/utility";
import { toast } from "react-toastify";
import {
  PlusIcon,
  ChevronDownIcon,
  PencilIcon,
  ArrowDownTrayIcon,
  ChevronUpDownIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

export const MainVaultDropdown = ({
  openNewNoteModal,
  openNewFolderModal,
  openUploadPage,
  type,
  extraBtnClass,
  disabled,
}: any) => {
  const [isShow, setIsShow] = useState(false);
  const [dropdown, setdropdown] = useState("none");
  useEffect(() => {
    if (isShow) {
      setdropdown("flex");
    } else {
      setdropdown("none");
    }
  }, [isShow]);
  let opendropdown = () => {
    setIsShow(!isShow);
  };

  const wrapperRef: any = useRef(null);
  const subscription = useSelector(
    (state: { portfolios: { portfolio: PortfolioState } }) => state.portfolios.portfolio.subscription
  );

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsShow(false);
    }
  };

  return (
    <div ref={wrapperRef}>
      <div x-data={`{ dropdownOpen: ${isShow} }`} className="relative">
        <button
          onClick={opendropdown}
          className={`flex items-center justify-between relative border cursor-pointer capitalize font-semibold rounded text-p3 sm:text-p2 text-center transition px-3 sm:px-4 py-1.5 sm:py-2
          ${
            type === "upload"
              ? "bg-primary border-transparent text-white hover:bg-primary-cobalt"
              : "bg-white border-primary text-primary hover:bg-neutral-200"
          }
          ${extraBtnClass}`}
        >
          {type === "create" && <PlusIcon className="w-5 h-5" color="#0056CD" />}
          <span className={type === "upload" ? "mr-1" : "mx-1"}>{type}</span>
          <span className={`${isShow ? "svg-rotate" : ""}`}>
            <ChevronDownIcon className="w-5 h-5" color={type === "upload" ? "#FFFFFF" : "#0056CD"} />
          </span>
        </button>

        <div
          x-show="dropdownOpen "
          className={`overflow-hidden absolute assnewassetsoptionsdown cursor-pointer transition transform origin-top ${
            dropdown === "flex" ? "p-2 opacity-1 translate-y-0" : "opacity-0 h-0 -translate-y-2 p-0"
          }`}
        >
          <button
            onClick={() => {
              opendropdown();
              if (type === "upload") {
                openUploadPage();
              } else openNewNoteModal();
            }}
            disabled={disabled}
            className={`px-2 py-2.5 text-p2 text-type rounded-sm w-full text-left flex
            ${disabled ? "cursor-not-allowed" : "cursor-pointer hover:bg-primary-alice"}`}
          >
            <div className={`flex ${disabled ? "opacity-50" : ""}`}>
              {type === "upload" ? (
                <>
                  <FileUploadIcon className="mr-2" />
                  File
                </>
              ) : (
                <>
                  <NoteIcon className="mr-2" />
                  Note
                </>
              )}
            </div>
          </button>

          {type !== "upload" && (
            <button
              onClick={() => {
                if (subscription && subscription.status === SubscriptionStatus.ACTIVE) {
                  opendropdown();
                  if (type === "upload") {
                    openUploadPage("folder");
                  } else openNewFolderModal();
                }
              }}
              data-tip=""
              data-for={
                (!subscription || (subscription && subscription.status !== SubscriptionStatus.ACTIVE)) && "mainVaultTip"
              }
              className={`px-2 py-2.5 text-p2 text-type rounded-sm  w-full text-left flex justify-between ${
                !subscription || (subscription && subscription.status !== SubscriptionStatus.ACTIVE)
                  ? "cursor-not-allowed"
                  : "cursor-pointer hover:bg-primary-alice"
              }`}
            >
              <div
                className={`flex ${
                  !subscription || (subscription && subscription.status !== SubscriptionStatus.ACTIVE)
                    ? "opacity-50"
                    : ""
                }`}
              >
                {type === "upload" ? <FolderUploadIcon className="mr-2" /> : <FolderIcon className="mr-2" />} Folder
              </div>
              {(!subscription || (subscription && subscription.status !== SubscriptionStatus.ACTIVE)) && (
                <PremiumCrown />
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export const VaultDocumentDropdown = ({
  openEditNoteModal,
  openRenameModal,
  openMoveModal,
  openDeleteModal,
  fileId,
  name,
  type,
  folderId,
  disabled,
  isShared,
}: any) => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [dropdown, setdropdown] = useState("none");
  useEffect(() => {
    if (isShow) {
      setdropdown("flex");
    } else {
      setdropdown("none");
    }
  }, [isShow]);
  let opendropdown = () => {
    setIsShow(!isShow);
  };
  const subscription = useSelector(
    (state: { portfolios: { portfolio: PortfolioState } }) => state.portfolios.portfolio.subscription
  );
  const wrapperRef: any = useRef(null);

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsShow(false);
    }
  };

  const [downloadVaultDocument, { data: downloadVaultDocumentResponse }] = useMutation(DOWNLOAD_VAULT_DOCUMENT, {
    errorPolicy: "all",
    onCompleted: () => {
      if (downloadVaultDocumentResponse?.downloadVaultDocument) {
        downloadFileV2(name, downloadVaultDocumentResponse.downloadVaultDocument);
      } else {
        toast.error("Something went wrong. Please try again.");
      }

      dispatch(setUtilityData(loaderData(false, "")));
    },
    onError(error: ApolloError) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  const handleDownloadVaultDocument = () => {
    dispatch(setUtilityData(loaderData(true, "")));
    downloadVaultDocument({
      variables: {
        downloadVaultDocumentId: fileId,
      },
    });
  };

  return (
    <div ref={wrapperRef}>
      <div x-data={`{ dropdownOpen: ${isShow} }`} className="relative">
        <button
          disabled={disabled}
          onClick={opendropdown}
          className={`relative z-10 p-4 pr-1 ${disabled ? "cursor-not-allowed" : "pointer"}`}
        >
          <span>
            <VerticalMoreIcon />
          </span>
        </button>

        <div x-show="dropdownOpen " style={{ display: dropdown }} className="absolute assnewassetsoptionsdown -right-4">
          {!isShared && (
            <button
              onClick={() => {
                opendropdown();
                if (type === "note") {
                  openEditNoteModal();
                } else openRenameModal();
              }}
              className="px-2 py-2.5 text-p2 text-type rounded-sm hover:bg-primary-alice  cursor-pointer w-full text-left flex gap-3"
            >
              <PencilIcon className="w-5 h-5" />
              {type === "note" ? "Edit" : "Rename"}
            </button>
          )}

          {type === "file" && (
            <button
              onClick={() => {
                opendropdown();
                handleDownloadVaultDocument();
              }}
              className="px-2 py-2.5 text-p2 text-type rounded-sm hover:bg-primary-alice  cursor-pointer w-full text-left flex gap-3"
            >
              <ArrowDownTrayIcon className="w-5 h-5" />
              Download
            </button>
          )}

          {!isShared && (
            <>
              {!folderId && (
                <button
                  onClick={() => {
                    openMoveModal();
                    opendropdown();
                  }}
                  disabled={!subscription || (subscription && subscription.status !== SubscriptionStatus.ACTIVE)}
                  className={`px-2 py-2.5 text-p2 text-type rounded-sm  w-full text-left flex justify-between ${
                    !subscription || (subscription && subscription.status !== SubscriptionStatus.ACTIVE)
                      ? "cursor-not-allowed"
                      : "cursor-pointer hover:bg-primary-alice"
                  }`}
                >
                  <div
                    className={`flex gap-3 ${
                      !subscription || (subscription && subscription.status !== SubscriptionStatus.ACTIVE)
                        ? "opacity-50"
                        : ""
                    }`}
                  >
                    <ChevronUpDownIcon className="w-5 h-5" />
                    Move
                  </div>
                  {(!subscription || (subscription && subscription.status !== SubscriptionStatus.ACTIVE)) && (
                    <PremiumCrown />
                  )}
                </button>
              )}

              <button
                onClick={() => {
                  opendropdown();
                  openDeleteModal();
                }}
                className="px-2 py-2.5 text-p2 text-status-danger rounded-sm hover:bg-status-danger-bg  cursor-pointer w-full text-left flex gap-3"
              >
                <TrashIcon className="w-5 h-5" />
                Delete
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const FolderDropdown = ({ openRenameModal, openDeleteModal }: any) => {
  const [showDropDown, setShowDropDown] = useState(false);
  useEffect(() => {
    const closeDropDown = () => {
      setShowDropDown(false);
    };
    window.addEventListener("click", closeDropDown);
    return () => window.removeEventListener("click", closeDropDown);
  }, []);

  return (
    <div id="dropdown" className="relative ml-2">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setShowDropDown((oldVal) => !oldVal);
        }}
        style={{ transform: "translateY(3px)" }}
      >
        <ThreeDotsIcon />
      </button>
      <div
        className={`overflow-hidden w-40 flex flex-col transition rounded-md absolute bg-white  ${
          showDropDown
            ? "p-2 opacity-1 translate-y-0 showdropdown z-[11]"
            : "opacity-0 h-0 -translate-y-4 p-0 shadow-none"
        }`}
      >
        <button
          onClick={openRenameModal}
          className="px-2 py-2.5 text-p2 text-type rounded-sm hover:bg-primary-alice  cursor-pointer w-full text-left flex gap-3"
        >
          <PencilIcon className="w-5 h-5" />
          Rename
        </button>
        <button
          onClick={openDeleteModal}
          className="px-2 py-2.5 text-p2 text-status-danger rounded-sm hover:bg-status-danger-bg  cursor-pointer w-full text-left flex gap-3"
        >
          <TrashIcon className="w-5 h-5" />
          Delete
        </button>
      </div>
    </div>
  );
};
