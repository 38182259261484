import { useState } from "react";
import Modal from "react-modal";
import { useForm, Controller } from "react-hook-form";
import { Textinput } from "../../components/inputs/textinput";
import { Errornotf } from "../../components/notifications/errornotf";
import { useDispatch, useSelector } from "react-redux";
import { loaderData, setUtilityData } from "../../store/actions/utility";
import { toast } from "react-toastify";
import { SuccessToast } from "../../components/notifications/toasts";
import PinInput from "react-pin-input";
import MainButton from "../../components/buttons/mainButton";
import { Textboxinput } from "../../components/inputs/textboxinput";
import { giveFeedBack } from "../../store/actions/feedbackaction";
import { CHANGE_EMAIL } from "../../graphql/auth";
import { useMutation } from "@apollo/client";
import { UserState } from "../../types/authTypes";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { useTranslation } from "react-i18next";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const ChangeEmailModal = ({ modalIsOpen, closeModal, verifyEmail }: any) => {
  const userEmail = useSelector((state: { user: UserState }) => state.user.currentUser.email);
  const dispatch = useDispatch();
  const [pin, setPin] = useState("");
  const [pinError, setPinError] = useState("");
  const [errorMessage, seterrorMessage] = useState(null);
  const [email1, setemail1] = useState("");
  const [enterToken, setenterToken] = useState(false);
  const { handleSubmit, errors, control } = useForm({ mode: "onBlur" });
  const [formError, setFormError] = useState<any>(null);

  const [changeemail] = useMutation(CHANGE_EMAIL, {
    onCompleted() {
      setenterToken(true);
      dispatch(setUtilityData(loaderData(false, "")));
    },
    onError(error: any) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  const onSubmit = (data: any) => {
    setFormError(null);
    setemail1(data.name);

    dispatch(setUtilityData(loaderData(true, "")));
    changeemail({
      variables: {
        email: data.name,
      },
    });
  };

  const resendEmail = () => {
    onSubmit({ name: email1 });
  };

  let submit = () => {
    seterrorMessage(null);
    setPinError("");
    if (pin && pin.length !== 6) {
      setPinError("Enter a valid pin");
      return;
    }

    dispatch(setUtilityData(loaderData(true, "")));
    verifyEmail(pin, email1);
  };

  const isNative = Capacitor.isNativePlatform();

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      className={classNames("modal-container", isNative && "native-modal")}
      overlayClassName={"modal-container-overlay"}
    >
      {!enterToken ? (
        <>
          {/* Header */}
          <div className="modal-header px-4 flex items-center justify-between">
            <h1 className="text-h4 font-semibold">Change your email</h1>
            <button type="button" onClick={closeModal} className="cursor-pointer">
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>

          {/* Body */}
          <div className="modal-body px-4 md:px-8 pt-5 pb-10">
            <p className="text-md mb-4">Enter your new email address</p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Errornotf style={{ marginBottom: "20px" }} message={formError} />
              <div className="form-control">
                <Controller
                  name="name"
                  defaultValue={null}
                  rules={{
                    required: {
                      value: true,
                      message: "Please fill this field",
                    },
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please fill in a valid email address",
                    },
                  }}
                  control={control}
                  render={(props) => (
                    <Textinput
                      onChange={(e: any) => {
                        props.onChange(e.target.value);
                      }}
                      checked={props.value}
                      label="New Email Address"
                      inputid="name"
                      type="email"
                      iserror={errors.name}
                      message={errors.name?.message}
                    />
                  )}
                />
              </div>
            </form>
          </div>

          {/* Footer */}
          <div className="modal-footer p-4 gap-4 flex justify-end items-center border-t bg-white">
            <MainButton click={closeModal} type="secondary" size="big">
              Cancel
            </MainButton>
            <MainButton click={handleSubmit(onSubmit)} type="primary" size="big">
              Change Email
            </MainButton>
          </div>
        </>
      ) : (
        <>
          {/* Header */}
          <div className="modal-header px-4 flex items-center justify-end">
            <button type="button" onClick={closeModal} className="cursor-pointer">
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>

          {/* Body */}
          <div className="modal-body px-4 md:px-8 pt-5 pb-10">
            <p className="text-xl font-semibold text-center mb-2">We Sent A Code To</p>
            <p className="text-md font-medium text-center mb-6">{userEmail}</p>

            <p className="text-center mb-6">
              Please enter the 6 digit authentication code we sent to your email address. The code will expire in 5
              minutes.
            </p>

            <div className="flex flex-col justify-center items-center mb-6">
              <Errornotf style={{ marginTop: "40px", marginBottom: "25px" }} message={errorMessage} />
              <div className="">
                <PinInput
                  type="numeric"
                  inputMode="number"
                  initialValue=""
                  secret
                  length={6}
                  onComplete={(value, index) => {
                    setPin(value);
                  }}
                  onChange={(value, index) => {
                    setPin(value);
                  }}
                />
                {pinError ? <small className="helper-text errormessage danger">{pinError}</small> : null}
              </div>
            </div>

            <p className="text-center">
              <span className="p-normal">Didn't get a code?</span>{" "}
              <span className="font-semibold hover:text-blue-700 underline cursor-pointer" onClick={resendEmail}>
                Resend Code{" "}
              </span>
            </p>
          </div>

          {/* Footer */}
          <div className="modal-footer p-4 gap-4 flex justify-end items-center border-t bg-white">
            <MainButton click={submit} type="primary" size="big">
              Verify my email address
            </MainButton>
          </div>
        </>
      )}
    </Modal>
  );
};

export const FeedbackModal = ({ modalIsOpen, afterOpenModal, closeModal, customStyles }: any) => {
  const { t } = useTranslation();
  const isNative = Capacitor.isNativePlatform();
  const { handleSubmit, errors, control } = useForm({
    criteriaMode: "all",
    mode: "onChange",
  });
  const currentUser = useSelector((state: any) => state.currentUser);
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();
  const onSubmit = (data: any) => {
    setDisableBtn(true);
    const payload = {
      ...data,
      name: `${currentUser.first_name} ${currentUser.last_name}`,
      email: `${currentUser.email}`,
    };
    //You will receive the form data here
    dispatch(giveFeedBack(payload))
      // @ts-expect-error
      .then(() => {
        closeModal();
        toast(<SuccessToast message="Feedback sent successfully" />, {
          className: "custom-toast-success",
        });
        setDisableBtn(false);
      });
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Give Feedback Modal"
      className={classNames("modal-container", isNative && "native-modal")}
      overlayClassName={"modal-container-overlay"}
    >
      {/* Header */}
      <div className="modal-header px-4 flex items-center justify-between">
        <h1 className="text-h4 font-semibold">Give Us Feedback!</h1>
        <button type="button" onClick={closeModal} className="cursor-pointer">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>

      {/* Body */}
      <div className="modal-body px-4 md:px-8 pt-5 pb-10">
        <p className="text-md mb-6">
          We would love to hear your thoughts or feedback on how we can improve your experience on {t("orgName")}.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Errornotf style={{ marginBottom: "20px" }} message={null} />
          <div className="mb-6">
            <Controller
              name="type"
              defaultValue={""}
              rules={{ required: true }}
              control={control}
              render={(props) => (
                <div className="forminput">
                  <label className="p-medium">Feedback Type</label>

                  <select
                    onChange={(e: any) => {
                      props.onChange(e.target.value);
                    }}
                    value={props.value}
                    className="selectfield"
                    name="cars"
                    id="cars"
                  >
                    <option>Select Type</option>
                    <option value="Comments">Comments</option>
                    <option value="Questions">Questions</option>
                    <option value="Bug Reports">Bug Reports</option>
                    <option value="Feature Request">Feature Request</option>
                  </select>
                </div>
              )}
            />
          </div>
          <div className="">
            <Controller
              name="comment"
              defaultValue={null}
              rules={{ required: true }}
              control={control}
              render={(props) => (
                <Textboxinput
                  onChange={(e: any) => {
                    props.onChange(e.target.value);
                  }}
                  checked={props.value}
                  label="Comment"
                  inputid="zipcode"
                  type="text"
                  placeholder=""
                  iserror={errors.zipcode}
                  message={"Please fill this field."}
                />
              )}
            />
          </div>
        </form>
      </div>

      {/* Footer */}
      <div className="modal-footer p-4 gap-4 flex justify-end items-center border-t bg-white">
        <MainButton click={closeModal} type="secondary" size="big">
          Cancel
        </MainButton>
        <MainButton click={() => handleSubmit(onSubmit)()} type="primary" size="big" disabled={disableBtn}>
          Submit
        </MainButton>
      </div>
    </Modal>
  );
};
