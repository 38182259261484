import { useState } from "react";
import Modal from "react-modal";
import MainButton from "../../buttons/mainButton";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { useTranslation } from "react-i18next";
import { XMarkIcon } from "@heroicons/react/24/outline";

const OneFaq = ({ question, selected, answer, click }: any) => {
  return (
    <div
      className={`w-full my-2.5 ${
        selected ? "border-primary-beau bg-primary-alice" : "border-neutral-100 bg-white"
      }  border rounded my-2.5 px-4 py-4 transition-all flex flex-col justify-start items-start cursor-pointer`}
      onClick={click}
    >
      <div className="flex justify-start items-center font-medium text-p2 text-type mb-1">{question}</div>
      {selected && <div className="text-xs leading-5 text-type-100">{answer}</div>}
    </div>
  );
};

const FAQs = ({ closeModal, afterOpenModal, modalIsOpen, customStyles }: any) => {
  const { t } = useTranslation();

  const list = [
    {
      question: "Do you offer a free trial?",
      answer: `Starting with ${t(
        "orgName"
      )} is free! We are sure you would see enough value to upgrade to the paid version at any time you choose.`,
    },
    {
      question: "What payment methods do you offer?",
      answer: `${t(
        "orgName"
      )} uses Stripe to handle payments and does not store your credit card information. You can use Mastercard, Visa, Discover, and Amex.`,
    },
    {
      question: `How do I access my ${t("orgName")} account?`,
      answer: `${t(
        "orgName"
      )} is connected to the cloud, which means you can access your account from any device with an internet connection. We however have plans for a mobile app in the near future.`,
    },
    {
      question: "Do you have discounts?",
      answer: (
        <div>
          We offer group discounts for businesses and groups on our {t("orgName")} for Business platform. Contact us at{" "}
          <a style={{ color: "#0056cd", fontWeight: 500 }} href="mailto:support@getcova.com">
            support@getcova.com
          </a>{" "}
          for more information.
        </div>
      ),
    },
    {
      question: "Can I cancel anytime?",
      answer:
        "Yes, you can cancel your subscription at any time. Once canceled, you would still have access to our free-forever plan at no cost.",
    },
    {
      question: "Are there additional/hidden charges to my subscription?",
      answer: "With us, what you see is what you get. There are no hidden costs associated with your subscription.",
    },
  ];

  const [select, setSelect] = useState(0);
  const isNative = Capacitor.isNativePlatform();
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        className={classNames(
          "Addassets manageAssets postionmoal relative h-95 pr-5 overflow-y-auto",
          isNative && "native-modal"
        )}
        overlayClassName={"modal-container-overlay"}
      >
        <div className="header">
          <div className="header-assetsheader h4-semibold "></div>
          <button type="button" onClick={closeModal} className="closeicon cursor-pointer">
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>
        <h2 className="header-assetsheader h4-semibold mb-5">Frequently Asked Questions</h2>
        {list.map((item, idx) => (
          <OneFaq
            key={item.question}
            question={item.question}
            answer={item.answer}
            click={() => setSelect(idx)}
            selected={select === idx}
          />
        ))}
        <div className="flex justify-end items-center w-full mt-6">
          <MainButton type="secondary" size="small" extraClasses="mr-2" click={closeModal}>
            Okay
          </MainButton>
        </div>
      </Modal>
    </div>
  );
};

export default FAQs;
