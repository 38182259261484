import { useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Edit } from "../../assets/svg/edit-blue.svg";
import { ReactComponent as Plus } from "../../assets/svg/plus-blue-bg.svg";
import { ConfirmationModal } from "../../components/modals/ConfirmationModal";
import { LifecheckActions, LifecheckStepType } from "../../types/lifecheckTypes";
import { UserType } from "../../types/userTypes";
import { AddEditLifecheckStep } from "./AddEditStepModal";
import { AddContactsToStepModal } from "./ChooseContactsModal";
import { LifeCheckDayModifier } from "./DayModifierModal";
import { useGetLifecheckQuery } from "../../api/lifecheck/lifecheck-queries";
import { useGetContactsQuery } from "../../api/contact/contact-queries";
import { useRemoveLifecheckStepMutation } from "../../api/lifecheck/lifecheck-mutations";
import { useUserQuery } from "../../api/user/user-queries";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { TrashIcon } from "@heroicons/react/24/outline";

interface IStepsLayout {
  step: LifecheckStepType;
}

const StepsLayout = ({ step }: IStepsLayout) => {
  const { t } = useTranslation();

  const stepTitle = {
    [LifecheckActions.NOTIFY_CONTACT]: (step: LifecheckStepType) => `Step ${step.sort_index} - Notify your contact`,
    [LifecheckActions.SHARE_PORTFOLIO]: (step: LifecheckStepType) => `Step ${step.sort_index} - Share your portfolio`,
    [LifecheckActions.NOTIFY_OWNER]: (step: LifecheckStepType) => `Step ${step.sort_index} - We Check On You`,
  };

  const stepMessage = {
    [LifecheckActions.NOTIFY_CONTACT]: (step: LifecheckStepType) => (
      <p className="text-p3 md:text-p2">
        If you continue to remain inactive after our{" "}
        <span className="font-semibold">
          {step.trigger_after} {step.trigger_after_unit} notification,
        </span>{" "}
        we will send a notification to your designated contacts, including the notes you have left for them, asking them
        to reach out to you.
      </p>
    ),
    [LifecheckActions.SHARE_PORTFOLIO]: (step: LifecheckStepType) => (
      <p className="text-p3 md:text-p2">
        If you continue to remain inactive for{" "}
        <span className="font-semibold">
          {step.trigger_after} {step.trigger_after_unit} notification,
        </span>{" "}
        after we have notified your designated contact, we will send your contact a notification containing a link to
        access your portfolio.
      </p>
    ),
    [LifecheckActions.NOTIFY_OWNER]: (step: LifecheckStepType, user: UserType) => (
      <p className="text-p3 md:text-p2">
        In the event that we detect&nbsp;
        <span className="font-semibold">{step.trigger_after + " " + step.trigger_after_unit}</span>
        &nbsp;of inactivity or no sign-ins to ${t("orgName")}, we will send a notification to&nbsp;
        <span className="text-primary-cobalt font-medium">{user.email}</span> to check in on you.
      </p>
    ),
  };

  const { data: user } = useUserQuery();
  const { id: portfolioId } = useSelector((state: any) => state.activePortfolio);
  const { data: lifecheck } = useGetLifecheckQuery({ id: portfolioId });
  const { data: contacts } = useGetContactsQuery({ id: portfolioId });
  const { mutateAsync: removeLifecheckStep } = useRemoveLifecheckStepMutation();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isAddContactModalOpen, setAddContactModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const enabled = lifecheck?.enabled;
  const names = step.contacts.map((el) => el.first_name + " " + el.last_name).join(", ");

  const handleRemoveLifecheckStep = async () => {
    try {
      await removeLifecheckStep({
        id: portfolioId,
        removeLifeCheckStepId: step.id,
      });
      setDeleteModalOpen(false);
    } catch (e) {
      console.log("fail: handleRemoveLifecheckStep", e);
    }
  };

  return (
    <div>
      {isDeleteModalOpen && (
        <ConfirmationModal
          modalIsOpen={isDeleteModalOpen}
          closeModal={() => setDeleteModalOpen(false)}
          submitAction={handleRemoveLifecheckStep}
          confirmationText="Are you sure to remove this step?"
        />
      )}

      {isEditModalOpen && step.action !== LifecheckActions.NOTIFY_OWNER && (
        <AddEditLifecheckStep contacts={contacts} closeModal={() => setEditModalOpen(false)} step={step} />
      )}

      {isEditModalOpen && step.action === LifecheckActions.NOTIFY_OWNER && (
        <LifeCheckDayModifier closeModal={() => setEditModalOpen(false)} step={step} />
      )}

      <AddContactsToStepModal
        isOpen={isAddContactModalOpen}
        contacts={contacts}
        closeModal={() => setAddContactModalOpen(false)}
        step={step}
      />

      <div
        className={classNames(
          "flex flex-col md:flex-row justify-between items-center",
          "mt-8 steps",
          enabled && "opacity-50"
        )}
      >
        <div className="flex md:w-3/4">
          <div className="lifecheck-sort-index text-xl md:text-2xl text-blue-700 flex justify-center">
            <span className="mt-1">{step.sort_index}</span>
          </div>
          <div className="ml-4 md:ml-10">
            <p className="md:text-h5 text-p2 font-medium flex items-center">{stepTitle[step.action](step)}</p>

            {stepMessage[step.action](step, user)}

            {step.action !== LifecheckActions.NOTIFY_OWNER && (
              <div className="flex items-center mt-2">
                <p className="font-medium text-p2">{names}</p>
                <button className="ml-2" onClick={() => (enabled ? null : setAddContactModalOpen(true))}>
                  <Plus />
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="w-full flex items-center justify-end mt-6 md:mt-0 px-4">
          <button onClick={() => (enabled ? null : setEditModalOpen(true))}>
            <Edit />
          </button>
          {step.action !== LifecheckActions.NOTIFY_OWNER && (
            <button className="ml-6" onClick={() => (enabled ? null : setDeleteModalOpen(true))}>
              <TrashIcon className="w-5 h-5" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StepsLayout;
