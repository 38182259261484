import styled, { keyframes } from "styled-components";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Logo } from "../../assets/svg/logo-light.svg";
import { ReactComponent as LifecheckLogo } from "../../assets/svg/logo-lifecheck-light.svg";
import classNames from "classnames";

const isProd = process.env.NODE_ENV === "production";

const messages = ["Organize your wealth.", "Protect your loved ones.", "Plan for the future."];

const shimmer = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const AnimatedDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent, white, transparent);
  animation: ${shimmer} 2.5s linear infinite;
`;

export const Pageloader = () => {
  const utility = useSelector((state: any) => state.utility.loader);
  const [messageIndex, setMessageIndex] = useState(Math.floor(Math.random() * messages.length));

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!utility.disappear) {
      setTimeout(() => {
        const pageLoader = document.getElementById("page-loader");
        pageLoader?.classList?.add("hidden");
      }, 1000);
    }
  }, [utility, utility.disappear]);

  return (
    <div
      id="page-loader"
      className={classNames(
        "fixed inset-0 z-50 flex items-center justify-center transition-opacity ease-linear duration-300",
        "select-none",
        utility.disappear ? "opacity-100" : "opacity-0"
      )}
      style={{
        backgroundColor: "rgba(0, 69, 164, 1)",
        color: "#fff",
      }}
    >
      <div className="flex flex-col items-center -mt-56">
        {isProd ? <Logo width={100} height={70} /> : <LifecheckLogo width={160} height={70} />}
        <div className="relative overflow-hidden">
          <p className="text-white py-1 px-4 pr-6 relative z-10">{messages[messageIndex]}</p>
          <AnimatedDiv />
        </div>
      </div>
    </div>
  );
};
