import classNames from "classnames";
import Modal from "react-modal";
import MainButton from "../../components/buttons/mainButton";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Textinput } from "../../components/inputs/textinput";
import { emailValidator } from "../../helper/helper";
import { AddContactType } from "../../types/lifecheckTypes";
import { SelectCountryCodeModal } from "../../components/modals/SelectCountryCodeModal";
import { useAddContactMutation, useUpdateContactMutation } from "../../api/contact/contact-mutations";
import { Capacitor } from "@capacitor/core";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";

interface IAddContactModal {
  isOpen: boolean;
  closeModal: () => void;
  contact?: AddContactType;
}
export const AddEditContactModal = ({ isOpen: isModalOpen, closeModal, contact: defaultContact }: IAddContactModal) => {
  const isNative = Capacitor.isNativePlatform();
  const { id: portfolioId } = useSelector((state: any) => state.activePortfolio);
  const { mutateAsync: addContactMutation, isLoading: isAddingContact } = useAddContactMutation();
  const { mutateAsync: updateContactMutation, isLoading: isUpdatingContact } = useUpdateContactMutation();
  const [showCountries, setShowCountries] = useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<any>({});

  const [contact, setContact] = useState<AddContactType>({
    id: defaultContact?.id || "",
    first_name: defaultContact?.first_name || "",
    last_name: defaultContact?.last_name || "",
    email: defaultContact?.email || "",
    phone_number: defaultContact?.phone_number || "",
    phone_number_country_code: defaultContact?.phone_number_country_code || "1",
    security_questions: [
      {
        answer: defaultContact?.security_questions[0]?.answer || "",
        question: defaultContact?.security_questions[0]?.question || "",
      },
      {
        answer: defaultContact?.security_questions[1]?.answer || "",
        question: defaultContact?.security_questions[1]?.question || "",
      },
      {
        answer: defaultContact?.security_questions[2]?.answer || "",
        question: defaultContact?.security_questions[2]?.question || "",
      },
    ],
  });

  const validations: any = {
    first_name: () => [contact.first_name.length < 2, "At least 2 characters required"],
    last_name: () => [contact.last_name.length < 2, "At least 2 characters required"],
    email: () => [!emailValidator(contact.email), "Email is invalid"],
    phone_number: () => [contact.phone_number.length < 6, "Phone number is invalid"],
  };

  const handleSubmit = async () => {
    const errorsToUpdate: any = {};

    const hasError = Object.keys(validations)
      .map((key) => {
        const [hasError, message] = validations[key]();

        if (hasError) {
          errorsToUpdate[key] = [message];
        }

        return hasError;
      })
      .some((x) => x);

    if (hasError) {
      setValidationErrors(errorsToUpdate);
      return;
    }

    setHasSubmitted(true);

    // check if security questions are empty
    const hasEmptySecurityQuestions = contact.security_questions.some((next) => next.question.length < 1);
    if (hasEmptySecurityQuestions) {
      return;
    }

    try {
      let data;

      if (!contact.id) {
        data = await addContactMutation({
          portfolioId,
          firstName: contact.first_name,
          lastName: contact.last_name,
          email: contact.email,
          phoneNumber: contact.phone_number,
          phoneNumberCountryCode: contact.phone_number_country_code,
          securityQuestions: contact.security_questions,
        });
      } else {
        data = await updateContactMutation({
          contactId: contact.id,
          firstName: contact.first_name,
          lastName: contact.last_name,
          email: contact.email,
          phoneNumber: contact.phone_number,
          phoneNumberCountryCode: contact.phone_number_country_code,
          securityQuestions: contact.security_questions,
        });

        const hasErrors = data?.errors?.length;

        // todo: needs better error handling/validation
        // this can be submitted with validation errors on client
        if (!hasErrors) {
          closeModal();
          return;
        }

        const {
          graphQLErrors: [{ extensions }],
        } = data?.errors[0] as any;
        const { CreateContactInput: validationErrors } = extensions || {};

        setValidationErrors(validationErrors as object);
      }

      closeModal();
    } catch (e) {
      console.error("fail: handleSubmit", e);
    }
  };

  const handleSelectCode = (data: any) => {
    setContact({ ...contact, phone_number_country_code: data.phone });
    setShowCountries(false);
  };

  const hasError = (error: []) => {
    if (!error) return false;
    return error.length;
  };

  const printError = (error: []) => {
    if (!error) return "";
    return error.join("\n");
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel="Add - Edit Contact Modal"
      className={classNames("modal-container", isNative && "native-modal")}
      overlayClassName="modal-container-overlay"
    >
      {/* Header */}
      <div className="modal-header px-4 flex items-center justify-between">
        <p className="text-h4 font-semibold">{`${contact.id ? "Update" : "Add"} Contact`}</p>
        <button type="button" onClick={closeModal} className="cursor-pointer">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>

      {/* Body */}
      <div className="modal-body px-4 md:px-8 pb-10">
        <SelectCountryCodeModal
          modalIsOpen={showCountries}
          closeModal={() => setShowCountries(false)}
          setCodeData={handleSelectCode}
        />

        <p className="text-p2 mt-2 mb-4">
          Confirm that your portfolio is secure by setting the security questions carefully and ensuring that your
          contact knows the answers.
        </p>

        <div className="flex flex-col gap-y-4">
          {/* Name */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Textinput
              onChange={(e: any) => {
                setContact({ ...contact, first_name: e.target.value });
              }}
              label="First name"
              inputid="first_name"
              name="first_name"
              iserror={hasError(validationErrors?.first_name)}
              message={printError(validationErrors?.first_name)}
              value={contact.first_name}
            />
            <Textinput
              onChange={(e: any) => {
                setContact({ ...contact, last_name: e.target.value });
              }}
              label="Last name"
              inputid="last_name"
              name="last_name"
              value={contact.last_name}
              iserror={hasError(validationErrors?.last_name)}
              message={printError(validationErrors?.last_name)}
            />
          </div>

          {/* Email */}
          <Textinput
            onChange={(e: any) => {
              setContact({ ...contact, email: e.target.value });
            }}
            label="Email address"
            inputid="email"
            name="email"
            type="email"
            value={contact.email}
            iserror={hasError(validationErrors?.email)}
            message={printError(validationErrors?.email)}
          />

          {/* Phone Number */}
          <div className="forminput">
            <label className="text-p2 font-medium mb-1">Phone Number</label>
            <div className="flex">
              <p
                onClick={() => setShowCountries(true)}
                className={classNames(
                  "w-24 flex justify-between items-center bg-neutral-300 text-p2 px-2 py-3",
                  "border-r-0 rounded-bl rounded-tl",
                  hasError(validationErrors?.phone_number) ? "border-status-danger" : "border-neutral-200"
                )}
                style={{ borderWidth: "1px" }}
              >
                + {contact.phone_number_country_code}
                <ChevronDownIcon className="w-5 h-5" />
              </p>
              <input
                required
                onChange={(e: any) => {
                  let telephone = e.target.value;

                  if (telephone === "" || /^[0-9\b]+$/.test(telephone)) {
                    let telp = telephone + "";
                    if (telp.length < 14) {
                      setContact({ ...contact, phone_number: e.target.value });
                    }
                  }
                }}
                value={contact.phone_number}
                name="phone_number"
                className={`border-l-0 bg-neutral-300 w-full rounded-tl-none rounded-bl-none rounded-tr rounded-br placeholder-type-200 text-p2 font-normal px-2 py-3 transition focus:border-0 focus:ring-0
                ${hasError(validationErrors?.phone_number) ? "border-status-danger" : "border-neutral-200"}`}
                type="tel"
                pattern="[0-9.]+"
              />
            </div>
            {hasError(validationErrors?.phone_number) ? (
              <small className="text-status-danger mt-1">{printError(validationErrors?.phone_number)}</small>
            ) : null}
          </div>
          <hr className="my-3" />
          <div className="flex flex-col gap-y-6">
            {contact.security_questions.map((next, index: number) => (
              <div key={index} className="flex flex-col gap-y-2">
                <Textinput
                  onChange={(e: any) => {
                    let data = [...contact.security_questions];
                    data[index].question = e.target.value;
                    setContact({ ...contact, security_questions: data });
                  }}
                  value={next.question}
                  label={`Security Question ${index + 1}`}
                  iserror={hasSubmitted && contact.security_questions[index].question.length < 1}
                  placeholder="Enter a security question"
                  message={"Please provide a question."}
                />
                <Textinput
                  onChange={(e: any) => {
                    let data = [...contact.security_questions];
                    data[index].answer = e.target.value;
                    setContact({ ...contact, security_questions: data });
                  }}
                  value={next.answer}
                  iserror={hasSubmitted && contact.security_questions[index].answer.length < 1}
                  placeholder="Answer"
                  message={"Please provide an answer"}
                />
                <p className="text-p3">No Caps, No Special Characters</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="modal-footer p-4 gap-3 flex justify-end border-t bg-white">
        <MainButton type="secondary" size="small" click={closeModal} disabled={isAddingContact || isUpdatingContact}>
          Cancel
        </MainButton>
        <MainButton type="primary" size="small" click={handleSubmit} disabled={isAddingContact || isUpdatingContact}>
          {contact.id ? "Update" : "Add"} Contact
        </MainButton>
      </div>
    </Modal>
  );
};
