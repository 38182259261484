import { CurrencySymbol, Currencytype, CountryName, SingleFileDto } from "../../../models/assets";
import {
  ADD_BANK_ASSETDATA,
  ADD_CRYPTO_ASSETDATA,
  ADD_CUSTOM_ASSETDATA,
  ADD_FILES,
  ADD_STOCKS_ASSETDATA,
  DELETE_FILES,
  RESET_ASSETDATA,
  SET_COUNTRY_CURRENCY,
  SET_CURRENCY_DATA,
  CREATE_ASSETS_FOLDERS,
  ADD_STARTUP_ASSETDATA,
} from "../../actions/assetsaction/types";

const initialState = {
  assetFolders: [],
  assetTabId: 0,
  allAssets: [],
  asset: {},
  bankAssets: [],
  cryptoAssets: [],
  realEstateAssets: [],
  othersAssets: [],
  stocksAssets: [],
  startupAssets: [],
  sumOfAssets: 0,
  netWorth: 0,
  isAsset: false,
  chartValue: [78, 20, 30, 20, 90],
  chartLabel: ["Robinhood Account", "Robinhood Account", "Robinhood Account", "Robinhood Account", "Robinhood Account"],
  topAssets: [
    {
      name: "Robinhood Account",
      value: 50000,
      cost: 50000,
    },
    {
      name: "Robinhood Account",
      value: 50000,
      cost: 50000,
    },
    {
      name: "Robinhood Account",
      value: 50000,
      cost: 50000,
    },
    {
      name: "Robinhood Account",
      value: 50000,
      cost: 50000,
    },
  ],
  chartData: {
    datasets: [
      {
        data: [10, 20, 30, 20, 10],
        backgroundColor: ["#EB5757", "#F2C94C", "#56CCF2", "#2F80ED", "#2F80ED", "green"],
      },
    ],
    labels: ["Robinhood Account", "Robinhood Account", "Robinhood Account", "Robinhood Account", "Robinhood Account"],
    options: {
      datalabels: {
        display: true,
      },
      title: { display: false },
      legend: { display: false },
    },
  },
  CAD: 1.252507,
  NGN: 380.499977,
  GBP: 0.72304,
  currency: {
    code: "",
    symbol: "",
    name: "",
  },
  files: [],
  isCountryFetch: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
  switch (action.type) {
    case ADD_FILES:
      return {
        ...state,
        files: [...state.files, action.payload],
      };

    case DELETE_FILES:
      return {
        ...state,
        files: state.files.filter((value: SingleFileDto, i) => {
          return value.id !== action.payload.id;
        }),
      };
    case CREATE_ASSETS_FOLDERS:
      return {
        ...state,
      };

    case SET_CURRENCY_DATA:
      return {
        ...state,
        currency: {
          ...action.payload,
        },
      };
    case ADD_BANK_ASSETDATA:
      return {
        ...state,
        bankAssets: [...state.bankAssets, action.payload],
      };
    case ADD_CRYPTO_ASSETDATA:
      return {
        ...state,
        cryptoAssets: [...state.cryptoAssets, action.payload],
      };
    case ADD_STOCKS_ASSETDATA:
      return {
        ...state,
        stocksAssets: [...state.stocksAssets, action.payload],
      };
    case ADD_STARTUP_ASSETDATA:
      return {
        ...state,
        startupAssets: [...state.startupAssets, action.payload],
      };
    case ADD_CUSTOM_ASSETDATA:
      return {
        ...state,
        othersAssets: [...state.othersAssets, action.payload],
      };

    case RESET_ASSETDATA:
      return {
        ...initialState,
      };

    case SET_COUNTRY_CURRENCY:
      return setTheCountryCurrency(state, action.payload);
    default:
      return state;
  }
}

function setTheCountryCurrency(state = initialState, payload: any) {
  let countryName: CountryName;
  let country: Currencytype;
  let symbol: CurrencySymbol;
  if (payload.continent === "Europe") {
    country = Currencytype.GBP;
    countryName = CountryName.GBP;
    symbol = CurrencySymbol.GBP;
  } else if (payload.country === "Nigeria") {
    country = Currencytype.NGN;
    countryName = CountryName.NGN;
    symbol = CurrencySymbol.NGN;
  } else if (payload.country === "Canada") {
    country = Currencytype.CAD;
    countryName = CountryName.CAD;
    symbol = CurrencySymbol.CAD;
  } else {
    country = Currencytype.USD;
    countryName = CountryName.USD;
    symbol = CurrencySymbol.USD;
  }

  return {
    ...state,
    currency: {
      country,
      symbol,
      countryName,
    },
    isCountryFetch: true,
  };
}
