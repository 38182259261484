import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const SignupMeta = () => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>Get Started | Secure Wealth Tracking & Portfolio Management.</title>
      <meta
        name="description"
        content={`Create a free account. ${t(
          "orgName"
        )} helps you to organize your assets, track your investment, plan your estate, in one place.`}
      />
      <meta property="og:title" content="Get Started | Secure Wealth Tracking & Portfolio Management." />
      <meta
        property="og:description"
        content={`Create a free account. ${t(
          "orgName"
        )} helps you to organize your assets, track your investment, plan your estate, in one place.`}
      />
      <meta property="og:url" content="https://app.getcova.com/signup" />
    </Helmet>
  );
};

export default SignupMeta;
