import { CreateAccountInput, Partners } from "../../../types/accountTypes";

export interface Props {
  isEdit?: boolean;
  account?: any;
  submit: (values: CreateAccountInput, partner?: Partners) => void;
  onClickBack: () => void;
}

export type InstitutionOption = {
  value: string;
  label: string;
};

export enum ManualAccountTypes {
  BankAccount = "BankAccount",
  CreditCard = "CreditCard",
  Loan = "Loan",
  Startup = "Startup",
  Crypto = "Crypto",
  Stock = "Stock",
  Other = "Other",
  RealEstate = "RealEstate",
}
