import Modal from "react-modal";
import { useState } from "react";
import { ReactComponent as Unchecked } from "../../assets/svg/selected-empty.svg";
import { ReactComponent as Checked } from "../../assets/svg/selected.svg";
import { LifecheckStepType } from "../../types/lifecheckTypes";
import { AddEditContactModal } from "./AddEditContactModal";
import { useUpdateLifecheckStepMutation } from "../../api/lifecheck/lifecheck-mutations";
import MainButton from "../../components/buttons/mainButton";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface IAddContactsToStepModal {
  isOpen: boolean;
  contacts: any[];
  closeModal: () => void;
  step: LifecheckStepType;
}
export const AddContactsToStepModal = ({ isOpen = false, contacts, closeModal, step }: IAddContactsToStepModal) => {
  const [selectedContactIds, setSelectedContactIds] = useState(step?.contacts?.map((contact: any) => contact.id) || []);
  const [isAddContactModalOpen, setAddContactModalOpen] = useState(false);
  const { mutateAsync: updateLifecheckStep } = useUpdateLifecheckStepMutation();

  const performAddContactsToStep = async () => {
    try {
      await updateLifecheckStep({
        stepId: step.id,
        contacts: selectedContactIds,
      });
      closeModal();
    } catch (e) {
      console.error("fail: performAddContactsToStep", e);
    }
  };

  const handleContactSelect = (id: string) => {
    const index = selectedContactIds.indexOf(id);
    if (index === -1) {
      setSelectedContactIds([...selectedContactIds, id]);
    } else {
      setSelectedContactIds([...selectedContactIds.slice(0, index), ...selectedContactIds.slice(index + 1)]);
    }
  };

  const isNative = Capacitor.isNativePlatform();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Choose Contacts Modal"
      className={classNames("modal-container", isNative && "native-modal")}
      overlayClassName={"modal-container-overlay"}
    >
      <AddEditContactModal
        isOpen={isAddContactModalOpen}
        contact={undefined}
        closeModal={() => setAddContactModalOpen(false)}
      />

      {/* Header */}
      <div className="modal-header px-4 flex items-center justify-between">
        <p className="text-h4 font-semibold">Add who to contact</p>
        <button type="button" onClick={closeModal} className="cursor-pointer">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>

      {/* Body */}
      <div className="modal-body px-4 md:px-8 pt-5 pb-10">
        <p className="text-p2 mb-8">Select or add who you&#39;d like us to contact during this step</p>
        {contacts?.map((contact: any) => (
          <div
            key={contact.id}
            className="mb-5 flex items-center cursor-pointer"
            onClick={() => handleContactSelect(contact.id)}
          >
            {selectedContactIds.includes(contact.id) ? <Checked /> : <Unchecked />}
            <span className="text-sm pl-2">{`${contact.first_name} ${contact.last_name} (${contact.email})`}</span>
          </div>
        ))}

        <button
          className="flex items-center font-bold text-sm text-primary"
          onClick={() => setAddContactModalOpen(true)}
        >
          + Create new contact
        </button>
      </div>

      {/* Footer */}
      <div className="modal-footer p-4 gap-3 flex justify-end items-center border-t bg-white">
        <MainButton type="secondary" size="small" click={closeModal}>
          Do this later
        </MainButton>
        <MainButton type="primary" size="small" click={performAddContactsToStep}>
          Save
        </MainButton>
      </div>
    </Modal>
  );
};
