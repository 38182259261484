import { AccountType } from "../../types/accountTypes";
import { SortableGroup } from "../../types/categoryTypes";
import { SortOrder, SortRule, SortType, UserSortRule } from "../../types/sortTypes";

export const generateAccountSorter = (rule: SortRule) => {
  const { type, order } = rule;

  // This function relies on `name` and `balance_usd` fields being present on the data sent here (received from the API)
  return (a: AccountType, b: AccountType) => {
    if (type === SortType.NAME) {
      return order === SortOrder.ASC
        ? a.display_name.localeCompare(b.display_name)
        : b.display_name.localeCompare(a.display_name);
    }

    // Else, assume sorting by value
    return order === SortOrder.ASC
      ? a.balance_usd.value - b.balance_usd.value
      : b.balance_usd.value - a.balance_usd.value;
  };
};

export const generateGroupSorter = (rule: SortRule) => {
  const { type, order } = rule;

  // This function relies on `name` and `balance_usd` fields being present on the data sent here (received from the API)
  return (a: SortableGroup, b: SortableGroup) => {
    if (type === SortType.NAME) {
      return order === SortOrder.ASC ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
    }

    // Else, assume sorting by value
    return order === SortOrder.ASC
      ? a.balance_usd[0].value - b.balance_usd[0].value
      : b.balance_usd[0].value - a.balance_usd[0].value;
  };
};

// Sorts Institutions, Classes, Labels, and the Accounts within them (ALL IN PLACE!!!)
export const sortGroup = (sortable: SortableGroup[], rule: UserSortRule) => {
  const { group, account } = rule || {};

  if (!group || !account || !sortable || !sortable.length) {
    return;
  }

  sortable.sort(generateGroupSorter(group)).forEach((next) => {
    next.accounts.sort(generateAccountSorter(account));
  });
};
