import { TotpMFA } from "./totp";
import { SMSAuthentication } from "./sms";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const TwoFactorAuthentication = () => {
  const { t } = useTranslation();
  const {
    preferences: { sms_mfa_enabled: isSMSEnabled },
  } = useSelector((state: any) => state.currentUser);

  return (
    <>
      <div className="securitytab ">
        <div className="securitylist pt-0">
          <div className="mb-10">
            <div className="text-type font-medium mb-2 text-h4">2 Step Verification</div>
            <div className="text-type-100 text-p2">Add extra steps to securing your account.</div>
            <div className="text-type-100 text-p2 mb-2">
              A two-factor authentication adds an extra layer of protection to your {t("orgName")} account. Once set up,
              you will be required to enter your password and a one time authentication code from your phone to log in.
            </div>
            <a
              className="text-primary font-semibold text-p2"
              href="https://getcova.com/security"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Learn More{" "}
            </a>
          </div>

          {/* Legacy - Phasing out SMS so only display if user already has it set up */}
          {isSMSEnabled && <SMSAuthentication />}

          <TotpMFA />
          <div className="flex mb-8 justify-between items-end securitylist-items">
            <div className="flex flex-col first-side-1">
              <p className="text-type-100 font-medium mb-1 text-p1 opacity-50">Universal second factor (U2F)</p>
              <p className="text-type-100 text-p2 opacity-50">Authenticate with a U2F hardware device.</p>
            </div>
            <div className="flex first-side-2">
              <div className=" bg-status-good-bg text-status-good text-p3 font-medium px-2 py-1 text-center rounded w-auto">
                Coming Soon
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
