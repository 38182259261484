import Skeleton from "react-loading-skeleton";
import { getNumberBetween } from "../../components/utilities/utilityFunctions";

const DashboardTopAccountsCardTopAccountsSkeleton = () => {
  return (
    <div className="flex flex-col items-start gap-y-10">
      {[...Array(2)].map((_, outerIndex) => (
        <div className="w-full" key={`outer-${outerIndex}`}>
          <Skeleton width={125} height={20} className="mb-4" />
          <div className="flex flex-col gap-y-4">
            {[...Array(2)].map((_, innerIndex) => (
              <div key={`inner-${innerIndex}`}>
                <Skeleton width="100%" height={15} />
                <Skeleton width={getNumberBetween(50, 100)} height={15} />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardTopAccountsCardTopAccountsSkeleton;
