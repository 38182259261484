import { useCallback } from "react";
import { toast } from "react-toastify";
import { BackarrowIcon } from "../../components/icons/backarrow";
import { VaultFileDropzone } from "./VaultFileDropzone";
import { useUploadFileMutation } from "../../api/vault/vault-mutations";

export const VaultUploadToMain = ({ done, uploadFolder, isFolder }: any) => {
  const { mutateAsync: uploadFile, isLoading } = useUploadFileMutation();

  const handleUploadFile = useCallback(
    async (files: File[]) => {
      try {
        await uploadFile({ file: files[0] });
        done();
      } catch (error) {
        console.error(error);
      }
    },
    [uploadFile, done]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      // for folder drops
      if (acceptedFiles.length === 0) {
        toast.error("Please choose a folder with at least one file", {
          autoClose: 5000,
        });
        return;
      }

      if (!isFolder) {
        handleUploadFile(acceptedFiles);
      } else {
        const folderName = acceptedFiles[0]?.path.split("/")[1];
        const data = {
          folderName: folderName ?? "New Folder",
          files: acceptedFiles,
        };
        uploadFolder(data, done);
      }
    },
    [isFolder, handleUploadFile, uploadFolder, done]
  );

  return (
    <div className="w-full">
      <div className="flex cursor-pointer items-center mb-6" onClick={done}>
        <BackarrowIcon />
        <span className="ml-2">Back</span>
      </div>
      <VaultFileDropzone
        onDrop={onDrop}
        subtitle="Keep important, sensitive documents and files safe in your vault e.g contracts, receipts, agreements."
        disabled={isLoading}
      >
        Drag and drop a file or <span className="text-blue-500 p-semibold">Click</span> to upload a single file.
      </VaultFileDropzone>
    </div>
  );
};

export default VaultUploadToMain;
