import { HistoryIntervalTypes } from "../../types/accountTypes";
import { DashboardDateRangeOption } from "./DashboardDateRangeOption";

const now: Date = new Date();

const DASHBOARD_DATERANGE_OPTIONS: DashboardDateRangeOption[] = [
  {
    text: "Today",
    days: 1,
    interval: HistoryIntervalTypes.HOUR,
    from: new Date(new Date(now).setDate(now.getDate() - 1)),
    to: now,
    tooltipTitle: {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    },
  },
  {
    text: "Last 7 days",
    days: 7,
    interval: HistoryIntervalTypes.DAY,
    from: new Date(new Date(now).setDate(now.getDate() - 7)),
    to: now,
    tooltipTitle: {
      day: "2-digit",
      month: "short",
    },
  },
  {
    text: "Last 30 days",
    interval: HistoryIntervalTypes.WEEK,
    days: 30,
    from: new Date(new Date(now).setDate(now.getDate() - 30)),
    to: now,
    tooltipTitle: {
      day: "2-digit",
      month: "short",
    },
  },
  {
    text: "Last quarter",
    interval: HistoryIntervalTypes.MONTH,
    from: new Date(new Date(now).setDate(now.getDate() - 180)),
    to: now,
    days: 180,
    tooltipTitle: {
      month: "short",
      year: "numeric",
    },
  },
];

export default DASHBOARD_DATERANGE_OPTIONS;
