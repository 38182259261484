import Modal from "react-modal";
import { toast } from "react-toastify";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Unchecked } from "../../assets/svg/selected-empty.svg";
import { ReactComponent as Checked } from "../../assets/svg/selected.svg";
import { LifecheckActions, LifecheckDuration, LifecheckStepType } from "../../types/lifecheckTypes";
import { useGetLifecheckQuery } from "../../api/lifecheck/lifecheck-queries";
import { useAddLifecheckStepMutation, useUpdateLifecheckStepMutation } from "../../api/lifecheck/lifecheck-mutations";
import MainButton from "../../components/buttons/mainButton";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface IAddEditLifecheckStep {
  contacts: any[];
  closeModal: (latestStep?: LifecheckStepType) => void;
  step?: LifecheckStepType;
  onAddContact?: (latestStep: LifecheckStepType) => void;
}
export const AddEditLifecheckStep = ({
  contacts,
  closeModal,
  step: defaultStep,
  onAddContact,
}: IAddEditLifecheckStep) => {
  const { mutateAsync: addLifecheckStep } = useAddLifecheckStepMutation();
  const { mutateAsync: updateLifecheckStep } = useUpdateLifecheckStepMutation();

  const [step, setStep] = useState(
    Object.assign(
      {
        trigger_after: 1,
        trigger_after_unit: LifecheckDuration.DAYS as any,
      },
      defaultStep
    )
  );
  const { id: portfolioId } = useSelector((state: any) => state.activePortfolio);
  const { data: lifecheck } = useGetLifecheckQuery({ id: portfolioId });
  const steps = lifecheck?.steps || [];
  const lastStep = steps[steps.length - 1];
  const isLastStep = lastStep && lastStep.id === step?.id;
  const showSharePortfolio = isLastStep || !step?.id;

  const handleNextAction = async () => {
    const isNew = !step.id;

    // validate step
    if (!step.action) {
      toast.error("Please select an action");
      return;
    }

    if (step.trigger_after_unit === "DAYS" && step.trigger_after && step.trigger_after < 5) {
      toast.error("Minimum duration of 5 days");
      return;
    }

    // save step
    try {
      let steps;

      if (isNew) {
        steps = await addLifecheckStep({
          portfolioId,
          action: step.action,
          triggerAfter: step.trigger_after,
          triggerAfterUnit: step.trigger_after_unit,
          customNote: step.custom_note,
        });
      } else {
        steps = await updateLifecheckStep({
          stepId: step.id,
          action: step.action,
          triggerAfter: step.trigger_after,
          triggerAfterUnit: step.trigger_after_unit,
          customNote: step.custom_note,
          contacts: step.contacts?.map((contact) => contact.id),
        });
      }

      setStep(steps[steps.length - 1]);

      if (isNew) {
        const latestStep = steps[steps.length - 1];
        if (onAddContact && latestStep) {
          onAddContact(latestStep);
          closeModal(latestStep);
        }
        return;
      }
    } catch (e) {
      console.error("fail: handleNextAction", e);
    }
  };

  const isNative = Capacitor.isNativePlatform();

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => closeModal()}
      contentLabel="Add - Edit Step Modal"
      className={classNames("modal-container", isNative && "native-modal")}
      overlayClassName="modal-container-overlay"
    >
      {/* Header */}
      <div className="modal-header px-4 flex items-center justify-between">
        <p className="text-h4 font-semibold">{step.id ? `Update "Step ${step.sort_index}"` : "Add Step"}</p>
        <button type="button" onClick={() => closeModal()} className="cursor-pointer">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>

      {/* Body */}
      <div className="modal-body px-4 md:px-8 pb-10">
        <p className="mb-3 text-p2">Select from these two choices below</p>

        <div
          className="mb-5 flex flex-start cursor-pointer"
          onClick={() => setStep({ ...step, action: LifecheckActions.NOTIFY_CONTACT })}
        >
          {step.action === LifecheckActions.NOTIFY_CONTACT ? <Checked /> : <Unchecked />}
          <div className="ml-2">
            <p className="text-p2">Notify someone</p>
            <p className="text-p3">This person will be reached out to concerning the situation</p>
          </div>
        </div>

        {showSharePortfolio && (
          <div
            className="mb-5 flex flex-start cursor-pointer"
            onClick={() => setStep({ ...step, action: LifecheckActions.SHARE_PORTFOLIO })}
          >
            {step.action === LifecheckActions.SHARE_PORTFOLIO ? <Checked /> : <Unchecked />}
            <div className="ml-2">
              <p className="text-p2">Share your portfolio</p>
              <p className="text-p3">Your portfolio will be shared immediately with this person</p>
            </div>
          </div>
        )}

        <p className="text-p2 mb-4">When would you like us to do this? </p>

        <div className="flex items-center gap-x-3">
          <select
            className="bg-neutral-200 text-p2 rounded-md border-neutral-300 cursor-pointer"
            defaultValue={step.trigger_after}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setStep({ ...step, trigger_after: +e.target.value })}
          >
            <option>1</option>
            <option>3</option>
            <option>7</option>
            <option>14</option>
            <option>30</option>
            <option>60</option>
          </select>

          <select
            className="bg-neutral-200 text-p2 rounded-md border-neutral-300 cursor-pointer"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setStep({ ...step, trigger_after_unit: e.target.value })
            }
            defaultValue={step.trigger_after_unit}
          >
            <option value={LifecheckDuration.DAYS}>DAYS</option>
            <option value={LifecheckDuration.WEEKS}>WEEKS</option>
            <option value={LifecheckDuration.MONTHS}>MONTHS</option>
          </select>
        </div>

        <p className="text-p2 mt-8 mb-1">Add a custom note</p>
        <textarea
          defaultValue={step.custom_note}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setStep({ ...step, custom_note: e.target.value })}
          className="w-full h-20 resize-none bg-neutral-200 border border-neutral-300 rounded-md p-2"
        ></textarea>
        <p className="text-p3 mt-1">This note will be sent to inform your contact and what their next steps are.</p>
      </div>

      {/* Footer */}
      <div className="modal-footer p-4 gap-3 flex justify-end border-t bg-white">
        <MainButton type="secondary" size="small" click={closeModal}>
          Cancel
        </MainButton>
        <MainButton type="primary" size="small" click={handleNextAction}>
          {step.id ? "Save changes" : "Next"}
        </MainButton>
      </div>
    </Modal>
  );
};
