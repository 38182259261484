import { print } from "graphql";
import { useQuery } from "@tanstack/react-query";
import { VAULT_FOLDERS } from "../../graphql/vault";
import { useApi } from "../../hooks/useAPI";

export function useGetFoldersQuery(variables: any) {
  const Api = useApi({});

  return useQuery(
    ["vaultFolders", variables],
    async () => {
      const payload = {
        operationName: "VaultFolders",
        variables: {
          input: variables,
        },
        query: print(VAULT_FOLDERS),
      };
      const data = await Api.post("", JSON.stringify(payload));
      return data?.data?.data?.vaultFolders;
    },
    {
      onError: (error) => {
        console.log("fail: vaultFolders query", error);
      },
      placeholderData: [],
      staleTime: 1000 * 60 * 60, // 1 hour
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    }
  );
}
