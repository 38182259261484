type buttonType = "primary" | "secondary" | "delete" | "warning";
type sizeType = "small" | "big";

interface IButton {
  size: sizeType;
  type: buttonType;
  click?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  id?: string;
  extraClasses?: string;
}

const MainButton = ({ type, children, click, disabled, id, extraClasses, size }: IButton) => {
  return (
    <button
      id={id}
      disabled={disabled}
      onClick={click}
      className={`font-semibold rounded text-p2 text-center transition
        ${extraClasses}
        ${size === "big" ? `px-6 py-3` : size === "small" ? `px-4 py-2` : "px-4 py-2"}
        ${disabled ? `opacity-40 cursor-not-allowed` : `opacity-100`}
        ${
          type === "primary"
            ? `bg-primary text-white hover:bg-primary-cobalt`
            : type === "secondary"
            ? `bg-neutral-200 text-type-100 hover:bg-neutral-100`
            : type === "delete"
            ? `bg-status-danger text-white hover:bg-status-danger-100`
            : type === "warning"
            ? "bg-[#A76401] text-white hover:bg-yellow-800"
            : "bg-primary text-white hover:bg-primary-cobalt"
        }`}
    >
      {children}
    </button>
  );
};

export default MainButton;
