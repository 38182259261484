import { print } from "graphql";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../hooks/useAPI";
import { X_FETCH_SUBSCRIPTION, FETCH_PARTNER_PLANS } from "../../graphql/portfolio.gql-query";
import { GET_INVOICES } from "../../graphql/subscription";

export const useGetSubscriptionQuery = (variables: { id: number }) => {
  const Api = useApi();
  const enabled = !!variables.id;

  return useQuery(
    [`X_FETCH_SUBSCRIPTION_${variables.id}`, variables],
    async () => {
      const payload = {
        operationName: "FetchSubscription",
        variables,
        query: print(X_FETCH_SUBSCRIPTION),
      };

      const response = await Api.post("", JSON.stringify(payload));
      const portfolio = response?.data?.data?.portfolio;
      return portfolio?.subscription || {};
    },
    {
      enabled,
      onError: (error) => {
        console.error("fail: useGetSubscriptionQuery", error);
      },
      onSuccess: (data) => {
        // dispatch({ type: UPDATE_ACTIVE_PORTFOLIO, payload: data });
      },
      initialData: null,
      staleTime: 1000 * 60 * 10, // 10 minutes
      cacheTime: 1000 * 60 * 10,
    }
  );
};

export const useGetPlansByPartnerQuery = (variables: { partner: string }) => {
  const Api = useApi();

  // Query will only run if the variables are defined
  const enabled = !!variables.partner;

  return useQuery(
    [FETCH_PARTNER_PLANS, variables],
    async () => {
      const payload = {
        operationName: "FetchPartnerPlans",
        variables,
        query: print(FETCH_PARTNER_PLANS),
      };

      const response = await Api.post("", JSON.stringify(payload));
      const config = response?.data?.data?.config;
      return config.plans;
    },
    {
      enabled, // Query will be enabled when variables are defined
      onError: (error) => {
        console.error("fail: useGetPlansByPartnerQuery", error);
      },
      onSuccess: (data) => {
        // dispatch({ type: UPDATE_ACTIVE_PORTFOLIO, payload: data });
      },
      //  placeholderData: null,
      staleTime: 1000 * 60 * 60, // 1 hour
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    }
  );
};

export const useGetInvoicesQuery = (variables: { portfolioId: number }) => {
  const Api = useApi();
  const enabled = !!variables.portfolioId;

  return useQuery(
    [GET_INVOICES, variables],
    async () => {
      const payload = {
        operationName: "Invoices",
        variables,
        query: print(GET_INVOICES),
      };

      const response = await Api.post("", JSON.stringify(payload));
      const portfolio = response?.data?.data?.portfolio;
      return portfolio?.invoices;
    },
    {
      enabled,
      onError: (error) => {
        console.error("fail: useGetInvoicesQuery", error);
      },
      onSuccess: (data) => {
        // dispatch({ type: UPDATE_ACTIVE_PORTFOLIO, payload: data });
      },
      placeholderData: [],
      staleTime: 1000 * 60 * 60, // 1 hour
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    }
  );
};
