import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SortDataDropdown from "../../components/sortcomp/SortDataDropdown";
import usePortfolio from "../../hooks/usePortfolio";
import { SET_USER_DATA } from "../../store/actions/userdata/types";
import { InstitutionType } from "../../types/categoryTypes";
import { PageTypes } from "../../types/pageTypes";
import { UserSortKey } from "../../types/sortTypes";
import { EmptyAssets, IndividualTable } from "./sharedcomponents";
import { sortGroup } from "./sort-helper";
import InstitutionsMeta from "./institutions/InstitutionsMeta";
import PortfolioSkeletons from "../portfolio/PortfolioSkeletons";
import { useGetInstitutionsQuery } from "../../api/institution/institution-queries";
import { AssetListMobile } from "./institutions/AssetListMobile";
import { useQueryClient } from "@tanstack/react-query";
import { SET_DID_OPTIMISTIC_UPDATE } from "../../store/actions/general-store.actions";
import { ArrowPathIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import "./assets-v2.scss";

const Institutions = () => {
  const dispatch = useDispatch();
  const { handleAccountLabel, handleUpdateLabelAccounts } = usePortfolio();
  const lastAddedAccount = useSelector((state: any) => state.userdata.valueX);

  const { id: portfolioId, owner: ownsPortfolio } = useSelector((state: any) => state.activePortfolio);
  const xCurrency = useSelector((state: any) => state.assets.currency.code);

  const {
    data: institutions,
    isFetched: isFetchedInstitutions,
    isFetching: isFetchingInstitutions,
    refetch: refetchInstitutions,
  } = useGetInstitutionsQuery({
    id: portfolioId,
    currency: xCurrency,
  });

  const {
    preferences: { institutions_sort },
  } = useSelector((state: any) => state.currentUser);

  sortGroup(institutions, institutions_sort); //Sorts in place

  // TODO: Come back to this to see if it can be refactored or removed
  useEffect(() => {
    if (lastAddedAccount) {
      const element = document.getElementById(lastAddedAccount);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
          dispatch({
            type: SET_USER_DATA,
            payload: {
              valueX: null,
            },
          });
        }, 2000);
      }
    }
    // eslint-disable-next-line
  }, [institutions]);

  const handleRemoveAccountLabel = async (id: string) => {
    await handleAccountLabel(id, null);
  };

  const handleSaveLabel = (accountIds: any, labelId: string) => {
    handleUpdateLabelAccounts(accountIds, labelId);
  };

  // Collapse/Expand State
  let savedCollapsedInstitutions: any = localStorage.getItem("savedCollapsedInstitutions");
  const [collapsedInstitutions, setCollapsedInstitutions] = useState<any[]>([]);
  const isAllCollapsed = collapsedInstitutions?.length === institutions?.length;

  useEffect(() => {
    if (savedCollapsedInstitutions) setCollapsedInstitutions(JSON.parse(savedCollapsedInstitutions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem("savedCollapsedInstitutions", JSON.stringify(collapsedInstitutions));
  }, [collapsedInstitutions]);

  // Page Text
  const pageTitle =
    ownsPortfolio === undefined || ownsPortfolio || !portfolioId
      ? "Portfolio by Institutions"
      : "Shared Accounts By Institutions";

  // Handle optimistic updates on exit page
  const didOptimisticUpdateInfo = useSelector((state: any) => state.generalStore.didOptimisticUpdate);
  const queryClient = useQueryClient();
  const invalidateInstitutionsQuery = () => {
    queryClient.invalidateQueries(["X_FETCH_INSTITUTIONS"]);
  };

  useEffect(() => {
    return () => {
      if (didOptimisticUpdateInfo.value && didOptimisticUpdateInfo.page === "InstitutionsPage") {
        dispatch({
          type: SET_DID_OPTIMISTIC_UPDATE,
          payload: {
            value: false,
            page: "InstitutionsPage",
          },
        });
        invalidateInstitutionsQuery();
      }
    };
    // Only run on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-4 md:p-8 dashboardbage acc-container">
      <div style={{ maxWidth: "1440px", margin: "0px auto" }}>
        <InstitutionsMeta pageTitle={pageTitle} />
        <div className="relative">
          <div className="py-2 md:py-0 flex flex-col md:flex-row gap-y-2 md:gap-y-0 justify-between items-center mb-4 sm:mb-10">
            <h2 className="font-semibold text-xl md:text-2xl">{pageTitle}</h2>
            {/* Action Bar */}
            <div className="flex items-center gap-x-2">
              {/* Reload */}
              <button
                className={classNames(
                  "bg-gray-100 hover:bg-gray-200 p-2 rounded-md flex items-center justify-center text-gray-500",
                  isFetchedInstitutions && isFetchingInstitutions && "resync"
                )}
                onClick={() => refetchInstitutions()}
              >
                <ArrowPathIcon className="w-5 h-5" />
              </button>

              {/* Sort */}
              <SortDataDropdown
                parent="Institution"
                sortPreferenceKey={UserSortKey.INSTITUTIONS}
                rule={institutions_sort}
              />

              {/* Collapse All */}
              <button
                className={classNames(
                  "hidden sm:block bg-gray-100 hover:bg-gray-200 p-2 rounded-md flex items-center justify-center",
                  isFetchingInstitutions && "cursor-not-allowed"
                )}
                onClick={() => setCollapsedInstitutions(collapsedInstitutions?.length > 0 ? [] : institutions)}
                disabled={isFetchingInstitutions}
              >
                <span className={`${!isAllCollapsed ? "rotate-180" : ""} text-gray-500 transition-all`}>
                  <ChevronDownIcon className="w-5 h-5" />
                </span>
              </button>
            </div>
          </div>

          {/* Institutions Table */}
          {!isFetchedInstitutions || isFetchingInstitutions ? (
            <PortfolioSkeletons showIcon />
          ) : (
            <>
              {isFetchedInstitutions && !isFetchingInstitutions && institutions?.length === 0 ? (
                <EmptyAssets />
              ) : (
                <>
                  {/* Assets by Institution */}
                  <div className="pb-10">
                    {institutions?.map((institution: InstitutionType, index: number) => (
                      <Fragment key={index}>
                        <div className="table-wr-sm">
                          <AssetListMobile
                            institution={institution}
                            handleRemoveAccountLabel={handleRemoveAccountLabel}
                            handleSaveLabel={handleSaveLabel}
                          />
                        </div>

                        <div className="table-wr-lg">
                          <IndividualTable
                            institution={institution}
                            accounts={institution.accounts}
                            closedInstitutions={collapsedInstitutions}
                            setClosedInstitutions={setCollapsedInstitutions}
                            page={PageTypes.institutions}
                            handleSaveLabel={handleSaveLabel}
                            handleRemoveAccountLabel={handleRemoveAccountLabel}
                          />
                        </div>
                      </Fragment>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Institutions;
