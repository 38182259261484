import React, { useState } from "react";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";

type AvatarSize = "sm" | "md" | "lg" | "xl";

interface UserAvatarProps {
  avatarUrl?: string;
  altText?: string;
  size?: AvatarSize;
  onClickAvatar?: () => void;
}

const sizeClasses: Record<AvatarSize, string> = {
  sm: "w-8 h-8",
  md: "w-16 h-16",
  lg: "w-24 h-24",
  xl: "w-32 h-32",
};

const UserAvatar: React.FC<UserAvatarProps> = ({ avatarUrl, altText = "User Avatar", size = "md", onClickAvatar }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const containerClass = classNames("rounded-full", sizeClasses[size]);
  const avatarClass = classNames(
    "rounded-full object-cover w-full h-full",
    onClickAvatar ? "cursor-pointer" : "cursor-default"
  );

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className={containerClass}>
      {!imageLoaded && <Skeleton className={sizeClasses[size]} circle={true} />}
      {avatarUrl && (
        <img
          className={`${avatarClass} ${!imageLoaded ? "hidden" : ""}`}
          src={avatarUrl}
          alt={altText}
          onLoad={handleImageLoad}
          onClick={onClickAvatar}
          style={!imageLoaded ? { display: "none" } : {}}
        />
      )}
    </div>
  );
};

export default UserAvatar;
