import { useQuery } from "@tanstack/react-query";
import { GET_PORTFOLIOS, GET_PORTFOLIO_DATA } from "../../graphql/portfolio";
import { print } from "graphql";
import { useApi } from "../../hooks/useAPI";
import dayjs from "dayjs";
import { useSwitchActivePortfolio } from "../../graphql/portfolio.gql-hook";
import { useDispatch } from "react-redux";
import { SET_ACTIVE_PORTFOLIO_ID, SET_PORTFOLIOS } from "../../store/actions/portfoliosaction/types";

// Get portfolio data for a specific portfolio
export function usePortfolioQuery(
  variables: {
    id: string;
    currency: string;
  },
  opts = {}
) {
  const Api = useApi();

  const fetchPortfolioData = async () => {
    try {
      const payload = {
        operationName: "portfolioData",
        variables,
        query: print(GET_PORTFOLIO_DATA),
      };

      const data = await Api.post("", JSON.stringify(payload));
      return data?.data?.data?.portfolio;
    } catch (error) {
      console.error("fail: fetchPortfolioData", error);
      return {};
    }
  };

  return useQuery(["portfolio"], fetchPortfolioData, {
    retry: false,
    refetchOnWindowFocus: false,
    initialData: {},
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60 * 24,
    initialDataUpdatedAt: dayjs().subtract(10, "minute").unix(),
    ...opts,
  });
}

// Get all portfolios
export function usePortfoliosQuery(opts = {}) {
  const Api = useApi();

  const dispatch = useDispatch();
  const switchActivePortfolio = useSwitchActivePortfolio();

  const fetchPortfolios = async () => {
    try {
      const payload = {
        operationName: "portfolios",
        query: print(GET_PORTFOLIOS),
      };

      const data = await Api.post("", JSON.stringify(payload));
      const { portfolios } = data?.data?.data;

      if (data) {
        const userPortfolio = portfolios.find((portfolio: any) => portfolio.owner === true);

        dispatch({
          type: SET_PORTFOLIOS,
          payload: data,
        });

        if (userPortfolio) {
          dispatch({
            type: SET_ACTIVE_PORTFOLIO_ID,
            payload: userPortfolio.id,
          });

          // Assuming switchActivePortfolio is an action creator
          switchActivePortfolio(userPortfolio.id);
        }
      }

      return data?.data?.data?.portfolios;
    } catch (error) {
      console.error("fail: fetchPortfolios", error);
      return [];
    }
  };

  return useQuery(["portfolios"], fetchPortfolios, {
    retry: false,
    refetchOnWindowFocus: false,
    initialData: [],
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60 * 24,
    initialDataUpdatedAt: dayjs().subtract(10, "minute").unix(),
    ...opts,
  });
}
