import { Assetsservice } from "../../../services/assets";
import { formatedError } from "../../../services/formaterrors";
import { SET_COUNTRY_CURRENCY, SET_CURRENCY_DATA } from "./types";

let assetsService = new Assetsservice();

export const getVezgoDetails = () => (dispatch: any) => {
  return assetsService.getVezgoDetails().then(
    (data) => {
      return Promise.resolve(data);
    },

    (error) => {
      dispatch({
        type: "",
      });
      let response = formatedError(error, dispatch);
      // return Promise.reject(response);
      return console.log(response?.message ?? "");
    }
  );
};

export const setCountryCurrency = (payload: any) => (dispatch: any) => {
  dispatch({
    type: SET_COUNTRY_CURRENCY,
    payload,
  });
};
export const setCurrencyData = (payload: any) => (dispatch: any) => {
  dispatch({
    type: SET_CURRENCY_DATA,
    payload,
  });
};
