export const BackarrowIcon = ({ width, height, color }: any) => {
  return (
    <svg
      width={width || "9"}
      height={height || "16"}
      viewBox="0 0 14 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9496 22.9L1.04961 13C0.882942 12.8333 0.766276 12.6667 0.69961 12.5C0.632943 12.3333 0.599609 12.15 0.599609 11.95C0.599609 11.75 0.632943 11.5667 0.69961 11.4C0.766276 11.2333 0.882942 11.0667 1.04961 10.9L10.9996 0.95C11.2996 0.65 11.6579 0.5 12.0746 0.5C12.4913 0.5 12.8496 0.65 13.1496 0.95C13.4496 1.25 13.5913 1.61667 13.5746 2.05C13.5579 2.48333 13.3996 2.85 13.0996 3.15L4.29961 11.95L13.1496 20.8C13.4496 21.1 13.5996 21.45 13.5996 21.85C13.5996 22.25 13.4496 22.6 13.1496 22.9C12.8496 23.2 12.4829 23.35 12.0496 23.35C11.6163 23.35 11.2496 23.2 10.9496 22.9Z"
        fill={color || "#1E2329"}
      />
    </svg>
  );
};
