import { useEffect, useRef, useState } from "react";
import { VerticalMoreIcon } from "../icons/verticalmore";
import { PencilIcon, ArrowDownTrayIcon, TrashIcon } from "@heroicons/react/24/outline";

export const SelectOptionForNotesIcon = ({ openDeleteModal, openEditModeFromModal, downloadNote }: any) => {
  const [isShow, setIsShow] = useState(false);
  const [dropdown, setdropdown] = useState("none");
  useEffect(() => {
    if (isShow) {
      setdropdown("flex");
    } else {
      setdropdown("none");
    }
  }, [isShow]);
  let opendropdown = () => {
    setIsShow(!isShow);
  };

  const wrapperRef: any = useRef(null);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsShow(false);
    }
  };

  return (
    <div ref={wrapperRef}>
      <div x-data={`{ dropdownOpen: ${isShow} }`} className="relative">
        <button onClick={() => opendropdown()} className="relative z-10 cursor-pointer p-4 pr-1">
          <span>
            <VerticalMoreIcon />
          </span>
        </button>
        <div
          x-show="dropdownOpen "
          className={`overflow-hidden absolute z-50 top-3 right-9 mt-0 assnewassetsoptionsdown cursor-pointer transition transform origin-top ${
            dropdown === "flex" ? "p-2 opacity-1 translate-y-0" : "opacity-0 h-0 -translate-y-2 p-0"
          }`}
        >
          <button
            onClick={() => {
              opendropdown();
              openEditModeFromModal();
            }}
            className="px-2 py-2.5 text-p2 text-type rounded-sm hover:bg-primary-alice  cursor-pointer w-full text-left flex gap-2"
          >
            <PencilIcon className="w-5 h-5" />
            Edit
          </button>
          {downloadNote && (
            <button
              onClick={() => {
                opendropdown();
                downloadNote();
              }}
              className="px-2 py-2.5 text-p2 text-type rounded-sm hover:bg-primary-alice  cursor-pointer w-full text-left flex gap-2"
            >
              <ArrowDownTrayIcon className="w-5 h-5" />
              Download
            </button>
          )}
          <button
            onClick={() => {
              opendropdown();
              openDeleteModal();
            }}
            className="px-2 py-2.5 text-p2 text-status-danger rounded-sm hover:bg-status-danger-bg  cursor-pointer w-full text-left flex gap-2"
          >
            <TrashIcon className="w-5 h-5" />
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export const SelectoptionfordeleteIcon = ({ deleteitem }: any) => {
  const [isShow, setIsShow] = useState(false);
  const [dropdown, setdropdown] = useState("none");
  useEffect(() => {
    if (isShow) {
      setdropdown("flex");
    } else {
      setdropdown("none");
    }
  }, [isShow]);
  let opendropdown = () => {
    setIsShow(!isShow);
  };

  const wrapperRef: any = useRef(null);

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsShow(false);
    }
  };
  return (
    <div ref={wrapperRef}>
      <div x-data={`{ dropdownOpen: ${isShow} }`} className="relative">
        <button onClick={opendropdown} className="relative z-10 p-4 pr-1 cursor-pointer">
          <span>
            <VerticalMoreIcon />
          </span>
        </button>

        <div
          x-show="dropdownOpen "
          style={{ display: dropdown }}
          className="absolute assnewassetsoptionsdown cursor-pointer top-0 right-9"
        >
          <div
            onClick={() => {
              opendropdown();
              deleteitem();
            }}
            className="p-2 text-p2 text-status-danger rounded-sm  hover:bg-status-danger-bg flex gap-2"
          >
            <TrashIcon className="w-5 h-5" />
            Delete
          </div>
        </div>
      </div>
    </div>
  );
};
