import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BankIcon } from "../../icons/bankicon";
import { ReactComponent as TickerIcon } from "../../../assets/svg/crypto-ticker.svg";
import { ReactComponent as PenIcon } from "../../../assets/svg/pen.svg";
import { CountrySelector, getCountryName } from "../../inputs/CountrySelector";
import { AssetTab } from "../../assetClass/AssetTab";
import usePlaidMonoV2 from "../../../hooks/usePlaidMonoV2";
import { useCryptoAsset } from "../../../hooks/useCryptoAsset";
import MainButton from "../../buttons/mainButton";
import useAccountConnect from "../../../hooks/useAccountConnect";
import { loaderData, setUtilityData } from "../../../store/actions/utility";
import { toast } from "react-toastify";
import CheckCir from "../../../assets/svg/successcheck.svg";
import { HandlePartnerCallbackData, Partners } from "../../../types/accountTypes";
import { SET_USER_DATA } from "../../../store/actions/userdata/types";
import { UserState } from "../../../types/authTypes";
import { PortfolioState } from "../../../types/portfolioTypes";
import { SubscriptionStatus } from "../../../types/subscriptionTypes";
import "./connect-account.scss";

export enum ViewCategories {
  categories = "categories",
  zillow = "zillow",
  manual = "manual",
}

interface StateCountry {
  name: string;
  flag: string;
  partners: string[];
}

interface ConnectData {
  expiration: string;
  link_token: string;
  request_id: string;
}

interface ICategories {
  closeModal: () => void;
  setActiveView: (value: string) => void;
  country: string;
  setCountry: (value: string) => void;
  selectedCountry: string;
  setSelectedCountry: (value: string) => void;
}

export const Categories = ({
  closeModal,
  setActiveView,
  country,
  setCountry,
  selectedCountry,
  setSelectedCountry,
}: ICategories) => {
  const countries: StateCountry[] = useSelector((state: { user: UserState }) => state.user.config.countries);
  const connect_plaid_us: ConnectData = useSelector((state: any) => state.portfolios.portfolio.connect_plaid_us);
  const connect_plaid_ca: ConnectData = useSelector((state: any) => state.portfolios.portfolio.connect_plaid_ca);
  const { initiateVezgo } = useCryptoAsset();
  const { handleConnectCallback } = useAccountConnect();
  const activePortfolioId = useSelector((state: any) => state.portfolios.activePortfolioId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscription = useSelector(
    (state: { portfolios: { portfolio: PortfolioState } }) => state.portfolios.portfolio.subscription
  );

  useEffect(() => {
    setCountry(getCountryName(selectedCountry));
    //eslint-disable-next-line
  }, [selectedCountry]);

  const connectCallback = (partner: Partners, data: string) => {
    dispatch(setUtilityData(loaderData(true, "")));

    const payload: HandlePartnerCallbackData = {
      portfolioId: activePortfolioId,
      partner,
      data,
    };

    handleConnectCallback(payload)
      .then((res: any) => {
        if (!res?.errors) {
          const response: { id: string }[] = res?.data?.handlePartnerCallback;

          if (response.length > 0) {
            dispatch({
              type: SET_USER_DATA,
              payload: {
                valueX: response[0]?.id,
              },
            });
          }

          toast.success(
            <div className="toast-div">
              <img src={CheckCir} alt="check circle" />
              Account connected successfully
            </div>
          );
          closeModal();
          dispatch(setUtilityData(loaderData(false, "")));
          navigate("/portfolio/institutions/?rfr=true");
        } else {
          toast.error("Something went wrong, please try again");
          dispatch(setUtilityData(loaderData(false, "")));
        }
      })
      .catch(() => {
        dispatch(setUtilityData(loaderData(false, "")));
      });
  };

  const { getPlaidorMono } = usePlaidMonoV2(country, connectCallback);

  const checkAction: (value: string) => boolean = (partner: string) => {
    if (countries.length === 0) {
      return false;
    }

    const countryIndex = countries.findIndex((item: { name: string }) => selectedCountry === item.name);

    if (countries[countryIndex].partners.includes(partner)) {
      return true;
    } else {
      return false;
    }
  };

  const getConnectToken = (country: string) => {
    switch (country) {
      case "USA":
        return connect_plaid_us?.link_token;
      case "Canada":
        return connect_plaid_ca?.link_token;
      default:
        return "";
    }
  };

  return (
    <div className="categories">
      {/* Body */}
      <div className="modal-body px-4 md:px-8 pt-5 pb-10">
        <h2 className="text-xl font-semibold mb-1">Ready to add your assets?</h2>
        <p className="text-sm text-gray-500 my-2">Connect your bank accounts, crypto, stocks and more.</p>

        <div>
          <div className="flex justify-end mb-4">
            <CountrySelector setcountry={setSelectedCountry} options={countries} country={selectedCountry} />
          </div>

          <div className="mb-6">
            <AssetTab
              Icon={BankIcon}
              title="Connect your banks & brokerages"
              description="Sync updates from your bank and investment apps automatically."
              action={() => {
                if (checkAction(Partners.PLAID) || checkAction(Partners.MONO)) {
                  getPlaidorMono(getConnectToken(country));
                }
              }}
              disabled={
                !subscription ||
                (subscription && subscription.status !== SubscriptionStatus.ACTIVE) ||
                !(checkAction(Partners.PLAID) || checkAction(Partners.MONO)) ||
                !connect_plaid_us?.link_token
              }
              premium={!subscription || (subscription && subscription.status !== SubscriptionStatus.ACTIVE)}
              fullWidth
            />
          </div>

          <div className="">
            <AssetTab
              Icon={TickerIcon}
              title="Connect your crypto"
              description="Connect your virtual wallets and sync."
              action={() => {
                initiateVezgo();
              }}
              disabled={!subscription || (subscription && subscription.status !== SubscriptionStatus.ACTIVE)}
              premium={!subscription || (subscription && subscription.status !== SubscriptionStatus.ACTIVE)}
            />
          </div>

          <div className="divider-xt2 my-4">
            <div className="d-line"></div>
            <div className="text-sm">Or</div>
            <div className="d-line"></div>
          </div>

          <div>
            <AssetTab
              Icon={PenIcon}
              title="Add manually"
              description="Manually enter the details of your banks and cash assets."
              action={() => setActiveView(ViewCategories.manual)}
              fullWidth
              extraClasses="mb-0"
            />
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="modal-footer p-4 gap-4 flex justify-end items-center border-t bg-white">
        <MainButton size="small" type="secondary" click={closeModal}>
          Continue later
        </MainButton>
      </div>
    </div>
  );
};

export default Categories;
