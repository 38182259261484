type SizeKey = "sm" | "md" | "lg";

interface VaultFileIconProps {
  mainColor: string;
  foldColor: string;
  size?: SizeKey;
}

export const VaultFileIcon = ({ mainColor, foldColor, size = "md" }: VaultFileIconProps) => {
  const sizes: Record<SizeKey, { width: number; height: number }> = {
    sm: { width: 29, height: 36 },
    md: { width: (29 + 58) / 2, height: (36 + 72) / 2 }, // Midpoint between sm and old md
    lg: { width: 58, height: 72 }, // Old md becomes new lg
  };

  const { width, height } = sizes[size];

  return (
    <svg width={width} height={height} viewBox="0 0 58 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.9275 1.06384H8.14488C6.30277 1.06384 4.53612 1.79707 3.23355 3.10221C1.93099 4.40735 1.19922 6.17751 1.19922 8.02326V63.6986C1.19922 65.5444 1.93099 67.3145 3.23355 68.6197C4.53612 69.9248 6.30277 70.6581 8.14488 70.6581H49.8188C51.6609 70.6581 53.4276 69.9248 54.7301 68.6197C56.0327 67.3145 56.7645 65.5444 56.7645 63.6986V21.9421L35.9275 1.06384Z"
        fill={mainColor}
        stroke={mainColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.0542 1.17389V21.913H56.8245"
        fill={foldColor}
        stroke={foldColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
