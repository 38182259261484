import { useState } from "react";
import { print } from "graphql";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../../hooks/useAPI";
import { ADD_CONTACT_GQL, REMOVE_CONTACT_GQL, UPDATE_CONTACT_GQL } from "../../graphql/contacts.gql-query";
import ToastSuccess from "../../components/toasts/ToastSuccess";
import ActionStateToast from "../../components/ActionStateToast";

interface SecurityQuestion {
  answer: string;
  question: string;
}

interface AddContactVariables {
  portfolioId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  phoneNumberCountryCode: string;
  securityQuestions: SecurityQuestion[];
}

interface UpdateContactVariables {
  contactId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  phoneNumberCountryCode: string;
  securityQuestions: SecurityQuestion[];
}

export function useAddContactMutation() {
  const queryClient = useQueryClient();
  const Api = useApi();
  const [contactAddToast, setContactAddToast] = useState<any>();

  return useMutation(
    async ({
      portfolioId,
      firstName,
      lastName,
      email,
      phoneNumber,
      phoneNumberCountryCode,
      securityQuestions,
    }: AddContactVariables) => {
      const payload = {
        operationName: "CreateContact",
        variables: {
          contact: {
            portfolio_id: portfolioId,
            first_name: firstName,
            last_name: lastName,
            email,
            phone_number: phoneNumber,
            phone_number_country_code: phoneNumberCountryCode,
            security_questions: securityQuestions,
          },
        },
        query: print(ADD_CONTACT_GQL),
      };

      const { data } = await Api.post("", JSON.stringify(payload));
      return data?.data?.contact || {};
    },
    {
      onMutate: () => {
        setContactAddToast(
          toast(<ActionStateToast message="Adding contact..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["portfolio"]);
        toast.dismiss(contactAddToast);
        toast.success(<ToastSuccess message="Contact added." />, {
          autoClose: 3000,
        });
      },
      onError: () => {
        toast.dismiss(contactAddToast);
        toast.error("Something went wrong..", {
          autoClose: 3000,
        });
      },
    }
  );
}

export function useUpdateContactMutation() {
  const queryClient = useQueryClient();
  const Api = useApi();
  const [contactUpdateToast, setContactUpdateToast] = useState<any>();

  return useMutation(
    async ({
      contactId,
      firstName,
      lastName,
      email,
      phoneNumber,
      phoneNumberCountryCode,
      securityQuestions,
    }: UpdateContactVariables) => {
      const payload = {
        operationName: "UpdateContact",
        variables: {
          contact: {
            id: contactId,
            first_name: firstName,
            last_name: lastName,
            email,
            phone_number: phoneNumber,
            phone_number_country_code: phoneNumberCountryCode,
            security_questions: securityQuestions,
          },
        },
        query: print(UPDATE_CONTACT_GQL),
      };

      const { data } = await Api.post("", JSON.stringify(payload));
      return data?.data?.contact || {};
    },
    {
      onMutate: () => {
        setContactUpdateToast(
          toast(<ActionStateToast message="Updating contact..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["portfolio"]);
        toast.dismiss(contactUpdateToast);
        toast.success(<ToastSuccess message="Contact updated." />, {
          autoClose: 3000,
        });
      },
      onError: () => {
        toast.dismiss(contactUpdateToast);
        toast.error("Something went wrong..", {
          autoClose: 3000,
        });
      },
    }
  );
}

export function useRemoveContactMutation() {
  const queryClient = useQueryClient();
  const Api = useApi();
  const [contactRemoveToast, setContactRemoveToast] = useState<any>();

  return useMutation(
    async ({ contactId }: { contactId: string }) => {
      const payload = {
        operationName: "RemoveContact",
        variables: {
          removeContactId: contactId,
        },
        query: print(REMOVE_CONTACT_GQL),
      };

      const { data } = await Api.post("", JSON.stringify(payload));
      return data?.data?.contact || {};
    },
    {
      onMutate: () => {
        setContactRemoveToast(
          toast(<ActionStateToast message="Removing contact..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["portfolio"]);
        toast.dismiss(contactRemoveToast);
        toast.success(<ToastSuccess message="Contact removed." />, {
          autoClose: 3000,
        });
      },
      onError: () => {
        toast.dismiss(contactRemoveToast);
        toast.error("Something went wrong..", {
          autoClose: 3000,
        });
      },
    }
  );
}
