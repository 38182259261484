export const pageContainerId = "main-secx2c";

export const scrollToPageTop = (smooth?: boolean) => {
  const myElement = document.getElementById(pageContainerId);
  myElement?.scrollTo({ top: 0, ...(smooth && { behavior: "smooth" }) });
};

export const emailValidator = (mail: string) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    mail
  );
};
