import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Textinput } from "../../components/inputs/textinput";
import MainButton from "../../components/buttons/mainButton";
import queryString from "query-string";
import useLifeCheck from "../../hooks/useLifeCheck";
import { Authheader } from "../auth/authheader";
import { fetchInheritedPortfolios } from "../../store/actions/lifecheckaction";
import { loaderData, setUtilityData } from "../../store/actions/utility";
import RecipientSetupPassword from "../../components/modals/recipientSetupPassword/recipientSetupPassword";
import { logoutaUser } from "../../store/actions/userdata";
import CheckCir from "../../assets/svg/successcheck.svg";
import { ReactComponent as Handshake } from "../../assets/svg/handshake.svg";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

const VerifySecurityQuestion = ({ hideAuthHeader }: { hideAuthHeader?: boolean }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [expandQuestions, setExpandQuestions] = useState<boolean>(false);
  const [portfolio, setPortfolio] = useState<any>(null);
  const [recipientEmail, setRecipientEmail] = useState<string>("");
  const { inheritedPortfolios } = useSelector((state: any) => state.lifecheck);
  const { email } = useSelector((state: any) => state.userdata);
  const { handleVerifyAnswer, handleGetQuestions, handleNonUserPasswordSetup } = useLifeCheck();
  const [setupPassword, setSetupPassword] = useState<boolean>(false);

  useEffect(() => {
    let parseddata: any = queryString.parse(location.search);

    if (hideAuthHeader) {
      if (inheritedPortfolios.length === 0) {
        dispatch(setUtilityData(loaderData(true, "")));
      }

      dispatch(fetchInheritedPortfolios())
        // @ts-expect-error
        .finally(() => {
          dispatch(setUtilityData(loaderData(false, "")));
        });
    } else if (parseddata?.hash) {
      dispatch(logoutaUser({}));

      handleGetQuestions(parseddata?.hash).then((res: any) => {
        setPortfolio(res);
        setRecipientEmail(res?.recipientEmail);
      });
    } else {
      toast.error("Verification requirements incomplete");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let parseddata: any = queryString.parse(location.search);

    if (parseddata?.p_idx) {
      setPortfolio(inheritedPortfolios[parseddata?.p_idx]);
      setRecipientEmail(email);
    }

    // eslint-disable-next-line
  }, [inheritedPortfolios]);

  const { handleSubmit, errors, control } = useForm({
    criteriaMode: "all",
    mode: "onChange",
  });

  const onSubmit = (values: any) => {
    const requestData = {
      recipientEmail: recipientEmail,
      userId: portfolio?.portfolioOwner?.id,
      questionId: values?.question?.questionId,
      answer: values?.answer,
    };

    handleVerifyAnswer(requestData).then(() => {
      if (!hideAuthHeader) {
        toast.success(
          <div className="toast-div">
            <img src={CheckCir} alt="check circle" />
            Security check successful
          </div>
        );
        setSetupPassword(true);
      } else {
        dispatch(fetchInheritedPortfolios());

        toast.success(
          <div className="toast-div">
            <img src={CheckCir} alt="check circle" />
            Portfolio access granted
          </div>
        );

        dispatch(
          setUtilityData({
            portfolioView: true,
            portfolioInfo: {
              ownerEmail: portfolio?.portfolioOwner?.email,
              ownerName: portfolio?.portfolioOwner?.name,
              ownerImageURL: portfolio?.portfolioOwner?.imageUrl,
              ownerId: portfolio?.portfolioOwner?.id,
              recipientId: portfolio?.recipientId,
              totalAssetValue: portfolio?.netWorthSummaryResponse?.totalAssetValue,
              totalLiabilityValue: portfolio?.netWorthSummaryResponse?.totalLiabilityValue,
            },
          })
        );

        navigate(`/shared/${portfolio?.recipientId}/assets`);
      }
    });
  };

  const toggleQuestionsDropdown = () => {
    setExpandQuestions(!expandQuestions);
  };

  const submitPassword = (password: string) => {
    handleNonUserPasswordSetup({ recipientEmail: portfolio?.recipientEmail, password }).then(() => {
      setSetupPassword(false);
      navigate("/signin");
    });
  };

  return (
    <div className={hideAuthHeader ? "" : "authpage"}>
      {setupPassword && (
        <RecipientSetupPassword
          modalIsOpen={setupPassword}
          closeModal={() => {
            setSetupPassword(false);
          }}
          submit={submitPassword}
        />
      )}

      <Helmet>
        <title>Verify Security Question</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Verify Security Question" />
        <meta property="og:description" content="" />
        <meta property="og:url" content="https://app.getcova.com/verify-security-question" />
      </Helmet>

      {!hideAuthHeader && (
        <div className="header-wrapper margin23">
          <Authheader />
        </div>
      )}

      {!hideAuthHeader && portfolio?.passwordSetup ? (
        <PasswordSet />
      ) : (
        <div className="l-c-question">
          <div className="l-c-q-title">Answer these security questions</div>

          <div className="l-c-q-desc">
            To ensure that someone else does not gain access to this portfolio instead of you,{" "}
            {portfolio?.portfolioOwner?.name ?? "The account owner"} has set a security question that only you might be
            able to answer. Please answer these questions to gain access to this portfolio.
          </div>

          <form className="l-c-q-form" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="question"
              defaultValue={null}
              rules={{ required: true }}
              control={control}
              render={(props) => (
                <div className="relative">
                  <div className="text-sm mb-1 font-medium">Select a security question</div>
                  <div
                    className="q-f-select flex flex-row justify-between items-center click-item select-none"
                    onClick={toggleQuestionsDropdown}
                  >
                    <div className={`text-sm ${props.value?.question ? "" : "gray-q"}`}>
                      {props.value?.question ?? "Select a security question"}
                    </div>

                    <div className={`transition transform ${expandQuestions ? "rotate-180" : "rotate-0"}`}>
                      <ChevronDownIcon className="h-5 w-5" />
                    </div>
                  </div>

                  {errors.question && (
                    <div className="text-p3 text-status-danger mt-1">Please select security question</div>
                  )}

                  {expandQuestions && (
                    <div className="q-f-questions-wrapper w-full mt-2">
                      <div className="font-semibold mb-2">Security Question</div>
                      <div className="text-xs mb-3 text-gray-600">
                        Make your answer as breif and simple as possible to have a better chance at being correct.
                      </div>

                      {portfolio?.questions.map((item: { questionId: number; question: string }) => (
                        <div
                          key={item?.questionId}
                          className={`q-f-item text-xs p-3 click-item select-none ${
                            item?.question === props.value?.question ? "active-i" : ""
                          }`}
                          onClick={() => {
                            props.onChange(item);
                            toggleQuestionsDropdown();
                          }}
                        >
                          {item?.question}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            />

            <div className="mt-4">
              <Controller
                name="answer"
                defaultValue={""}
                rules={{
                  required: {
                    value: true,
                    message: "Please fill this field",
                  },
                  pattern: {
                    value: /^[ a-z0-9]*$/,
                    message: "No Caps, No Special Characters",
                  },
                }}
                control={control}
                render={(props) => (
                  <Textinput
                    onChange={(e: any) => {
                      props.onChange(e.target.value);
                    }}
                    value={props.value}
                    inputid="answer"
                    type="text"
                    placeholder="Enter your answer"
                    iserror={errors.answer}
                    message={"No Caps, No Special Characters"}
                  />
                )}
              />
            </div>

            <MainButton size="big" type="primary" extraClasses="w-full mt-6">
              View Portfolio
            </MainButton>
          </form>

          <div className="text-xs mt-8 mb-3 p-3 rounded-md notification-message">
            Think carefully before answering. After a third trial for each question, you will lose access to this
            portfolio
          </div>
        </div>
      )}
    </div>
  );
};

const PasswordSet = () => {
  return (
    <div className="password-set">
      <div className="p-s-row-one">
        <Handshake className="r-o-img" />
      </div>

      <div className="p-s-row-two">Portfolio Access Verified</div>
      <div className="p-s-row-three">
        You have already completed the security check for this portfolio. Continue with the button below to access the
        portfolio.
      </div>

      <Link to={"/signin"}>
        <MainButton size="big" type="primary">
          Proceed to login
        </MainButton>
      </Link>
    </div>
  );
};

export default VerifySecurityQuestion;
