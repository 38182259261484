import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import rootSaga from "./sagas";

import { persistStore, persistReducer } from "redux-persist";
import localForage from "localforage";

const enhancers = [];
const __DEV__ = process.env.NODE_ENV !== "production";
if (__DEV__) {
  const devToolsExtension = window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const persistConfig = {
  key: "persistcova",
  storage: localForage,
  debug: __DEV__,
  whitelist: ["userdata", "user"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer); // create a persisted reducer

const sagaMiddleware = createSagaMiddleware();

const initialState = {};

let middleware = [];
if (process.env.NODE_ENV === "development") {
  middleware = [logger, thunk, sagaMiddleware];
} else {
  middleware = [thunk, sagaMiddleware];
}

const store = createStore(persistedReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers));

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store); // used to create the persisted store, persistor will be used in the next step

export { store, persistor };
