import React from "react";

export const BankIcon = ({ height, width, color }: any) => {
  return (
    <svg
      width={width || "66"}
      height={height || "66"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.1 33.95C7.66667 33.95 7.30833 33.8084 7.025 33.525C6.74167 33.2417 6.6 32.8834 6.6 32.45V18.45C6.6 18.05 6.75 17.7084 7.05 17.425C7.35 17.1417 7.7 17 8.1 17C8.53333 17 8.89167 17.1417 9.175 17.425C9.45833 17.7084 9.6 18.0667 9.6 18.5V32.5C9.6 32.9 9.45 33.2417 9.15 33.525C8.85 33.8084 8.5 33.95 8.1 33.95ZM20.2 33.95C19.7667 33.95 19.4083 33.8084 19.125 33.525C18.8417 33.2417 18.7 32.8834 18.7 32.45V18.45C18.7 18.05 18.85 17.7084 19.15 17.425C19.45 17.1417 19.8 17 20.2 17C20.6333 17 20.9917 17.1417 21.275 17.425C21.5583 17.7084 21.7 18.0667 21.7 18.5V32.5C21.7 32.9 21.55 33.2417 21.25 33.525C20.95 33.8084 20.6 33.95 20.2 33.95ZM1.45 39.95C1.05 39.95 0.708333 39.8 0.425 39.5C0.141667 39.2 0 38.85 0 38.45C0 38.0167 0.141667 37.6584 0.425 37.375C0.708333 37.0917 1.06667 36.95 1.5 36.95H38.55C38.95 36.95 39.2917 37.1 39.575 37.4C39.8583 37.7 40 38.05 40 38.45C40 38.8834 39.8583 39.2417 39.575 39.525C39.2917 39.8084 38.9333 39.95 38.5 39.95H1.45ZM31.9 33.95C31.4667 33.95 31.1083 33.8084 30.825 33.525C30.5417 33.2417 30.4 32.8834 30.4 32.45V18.45C30.4 18.05 30.55 17.7084 30.85 17.425C31.15 17.1417 31.5 17 31.9 17C32.3333 17 32.6917 17.1417 32.975 17.425C33.2583 17.7084 33.4 18.0667 33.4 18.5V32.5C33.4 32.9 33.25 33.2417 32.95 33.525C32.65 33.8084 32.3 33.95 31.9 33.95ZM21.5 0.800024L38.9 10.7C39.2333 10.8667 39.5 11.1084 39.7 11.425C39.9 11.7417 40 12.0834 40 12.45C40 12.9167 39.8167 13.2917 39.45 13.575C39.0833 13.8584 38.6667 14 38.2 14H1.8C1.33333 14 0.916667 13.8584 0.55 13.575C0.183334 13.2917 0 12.9167 0 12.45C0 12.0834 0.108333 11.75 0.325 11.45C0.541667 11.15 0.816667 10.9 1.15 10.7L18.5 0.800024C18.9667 0.533358 19.4667 0.400024 20 0.400024C20.5333 0.400024 21.0333 0.533358 21.5 0.800024ZM6.7 11H33.3H6.7ZM6.7 11H33.3L20 3.40002L6.7 11Z"
        fill={color || "#0056CD"}
      />
    </svg>
  );
};
