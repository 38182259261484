import classNames from "classnames";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SortDataDropdown from "../../components/sortcomp/SortDataDropdown";
import usePortfolio from "../../hooks/usePortfolio";
import { InstitutionType } from "../../types/categoryTypes";
import { PageTypes } from "../../types/pageTypes";
import { UserSortKey } from "../../types/sortTypes";
import { AssetsMobile, EmptyAssets, IndividualTable } from "./sharedcomponents";
import { sortGroup } from "./sort-helper";
import PortfolioSkeletons from "../portfolio/PortfolioSkeletons";
import ClassesMeta from "./classes/ClassesMeta";
import { useGetClassesQuery } from "../../api/class/class-queries";
import { ArrowPathIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import "./assets-v2.scss";

const PortfolioByClasses = () => {
  const { handleAccountLabel, handleUpdateLabelAccounts } = usePortfolio();
  const { id: portfolioId, owner: ownsPortfolio } = useSelector((state: any) => state.activePortfolio);
  const xCurrency = useSelector((state: any) => state.assets.currency.code);
  const {
    preferences: { classes_sort },
  } = useSelector((state: any) => state.currentUser);

  const {
    data: classes,
    isFetched: isFetchedClasses,
    isFetching: isFetchingClasses,
    refetch: refetchClasses,
  } = useGetClassesQuery({
    id: portfolioId,
    currency: xCurrency,
  });

  sortGroup(classes, classes_sort); //Sorts in place

  const handleRemoveAccountLabel = async (id: string) => {
    await handleAccountLabel(id, null);
  };

  const handleSaveLabel = (accountIds: any, labelId: string) => {
    handleUpdateLabelAccounts(accountIds, labelId);
  };

  // Collapse/Expand State
  let savedClosedClasses: any = localStorage.getItem("savedClosedClasses");
  const [collapsedClasses, setCollapsedClasses] = useState<any>([]);
  const isAllCollapsed = collapsedClasses?.length === classes?.length;

  useEffect(() => {
    if (savedClosedClasses) setCollapsedClasses(JSON.parse(savedClosedClasses));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem("savedClosedClasses", JSON.stringify(collapsedClasses));
  }, [collapsedClasses]);

  // Page Text
  const pageTitle =
    ownsPortfolio === undefined || ownsPortfolio || !portfolioId
      ? "Asset/Liability Classes"
      : "Shared Asset/Liability Classes";

  return (
    <div className="p-4 md:p-8 dashboardbage acc-container">
      <div style={{ maxWidth: "1440px", margin: "0px auto" }}>
        <ClassesMeta pageTitle={pageTitle} />
        <div className="relative">
          <div className="py-2 md:py-0 flex flex-col md:flex-row gap-y-2 md:gap-y-0 justify-between items-center mb-4 sm:mb-10">
            <h2 className="font-semibold text-xl md:text-2xl">{pageTitle}</h2>

            {/* Action Bar */}
            <div className="flex items-center gap-x-2">
              {/* Reload */}
              <button
                className={classNames(
                  "bg-gray-100 hover:bg-gray-200 p-2 rounded-md flex items-center justify-center text-gray-500",
                  isFetchedClasses && isFetchingClasses && "resync"
                )}
                onClick={() => refetchClasses()}
              >
                <ArrowPathIcon className="w-5 h-5" />
              </button>

              {/* Sort */}
              <SortDataDropdown parent="Class" sortPreferenceKey={UserSortKey.CLASSES} rule={classes_sort} />

              {/* Collapse All */}
              <button
                className={classNames(
                  "hidden sm:block bg-gray-100 hover:bg-gray-200 p-2 rounded-md flex items-center justify-center",
                  isFetchingClasses && "cursor-not-allowed"
                )}
                onClick={() => {
                  setCollapsedClasses(collapsedClasses?.length > 0 ? [] : classes);
                }}
                disabled={isFetchingClasses}
              >
                <span className={`${!isAllCollapsed ? "rotate-180" : ""} text-gray-500 transition-all`}>
                  <ChevronDownIcon className="w-5 h-5" />
                </span>
              </button>
            </div>
          </div>

          {/* Classes Table */}
          {!isFetchedClasses || isFetchingClasses ? (
            <PortfolioSkeletons />
          ) : (
            <>
              {!isFetchingClasses && classes?.length === 0 ? (
                <EmptyAssets />
              ) : (
                <div className="pb-10">
                  <div className="table-wr-sm">
                    <AssetsMobile
                      classes={classes}
                      handleRemoveAccountLabel={handleRemoveAccountLabel}
                      handleSaveLabel={handleSaveLabel}
                    />
                  </div>
                  <div className="table-wr-lg">
                    {classes.map((assetClass: InstitutionType, idx: number) => (
                      <IndividualTable
                        key={idx}
                        institution={assetClass}
                        accounts={assetClass.accounts}
                        closedInstitutions={collapsedClasses}
                        setClosedInstitutions={setCollapsedClasses}
                        page={PageTypes.classes}
                        handleRemoveAccountLabel={handleRemoveAccountLabel}
                        handleSaveLabel={handleSaveLabel}
                      />
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PortfolioByClasses;
