export enum Currencytype {
  NGN = "NGN",
  CAD = "CAD",
  USD = "USD",
  GBP = "GBP",
}
export enum CountryName {
  NGN = "Nigeria",
  CAD = "Canada",
  USD = "USA",
  GBP = "UK",
}

export enum CurrencySymbol {
  NGN = "₦",
  CAD = "C$",
  USD = "$",
  GBP = "£",
}
export enum AssetModes {
  MANUAL = "MANUAL",
  TICKER = "TICKER",
  CONNECTED = "CONNECTED",
}

export interface CryptoWalletsdto {
  cryptoValue: number;
  currency: string;
  fullName: string;
  id: number;
  shortName: string;
  value: number;
}

export interface SingleFileDto {
  dateAdded: number;
  fileName: string;
  fileSize: number;
  fileUrl: string;
  id: number;
}

export interface VaultFolderType {
  id: string;
  name: string;
  vaultDocumentViewList: Array<VaultDocumentType>;
  vaultNotes: Array<VaultNoteType>;
  dateAdded?: string;
  size?: number;
}

export interface VaultDocumentType {
  id: number;
  dateAdded: number;
  fileUrl: string;
  fileName: string;
  fileSize: number;
}
export interface VaultNoteType {
  id: number;
  dateCreated: string;
  lastEdited: string | null;
  vaultFolderId: string;
  title: string;
  entry: string;
}
