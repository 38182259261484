import { Helmet } from "react-helmet";

const ClassesMeta = ({ pageTitle }: { pageTitle: string }) => {
  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content="Add banks and investments, stocks, real estate, crypto and other assets" />
      <meta property="og:title" content="Assets" />
      <meta
        property="og:description"
        content="Add banks and investments, stocks, real estate, crypto and other assets"
      />
      <meta property="og:url" content="https://app.getcova.com/portfolio/classes" />
      <meta name="twitter:card" content="Add banks and investments, stocks, real estate, crypto and other assets" />
    </Helmet>
  );
};

export default ClassesMeta;
