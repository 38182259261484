import { useState } from "react";
import Modal from "react-modal";
import MainButton from "../buttons/mainButton";
import { Textinput } from "../inputs/textinput";
import { Errornotf } from "../notifications/errornotf";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const ConfirmationModal = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  customStyles,
  verificationText,
  submitBtnCustomClass,
  submitAction,
  confirmationText,
  confirmationTitle,
  submitText,
  cancelText,
  extraContent,
}: any) => {
  const [valueStr, setValueStr] = useState("");
  const isNative = Capacitor.isNativePlatform();

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Confirmation Modal"
      className={classNames("modal-container max-h-80", isNative && "native-modal")}
      overlayClassName={"modal-container-overlay"}
    >
      {/* Header */}
      <div className="modal-header px-4 flex items-center justify-end">
        <button type="button" onClick={closeModal} className="cursor-pointer">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>

      {/* Body */}
      <div className="modal-body px-4 md:px-8 pb-10">
        <div className={`${!verificationText ? "pb-20" : "pb-0"}`}>
          <h3 className="text-xl font-semibold mb-2">{confirmationTitle ? confirmationTitle : "Are you sure?"}</h3>
          <p className="text-type-100 text-p2">
            {confirmationText} {extraContent}
          </p>
        </div>

        {verificationText && (
          <form
            className="mt-6 pb-10"
            onSubmit={(e) => {
              e.preventDefault();
              if (verificationText && valueStr === verificationText) {
                submitAction();
              }
            }}
          >
            <Errornotf style={{ marginBottom: "20px" }} message={null} />
            <div className="form-control">
              <Textinput
                onChange={(e: any) => {
                  const { value } = e.target;
                  setValueStr(value);
                }}
                label={`Type ‘${verificationText}’ to confirm`}
                inputid="name"
                type="text"
                value={valueStr}
                autocomplete="off"
              />
            </div>
          </form>
        )}
      </div>

      {/* Footer */}
      <div className="modal-footer p-4 gap-3 flex justify-end border-t bg-white">
        <MainButton size="small" type="secondary" click={closeModal}>
          {cancelText ? cancelText : "Cancel"}
        </MainButton>
        <MainButton
          size="small"
          type="delete"
          id="verificationBtn"
          disabled={verificationText && valueStr !== verificationText}
          click={submitAction}
          extraClasses={submitBtnCustomClass}
        >
          {submitText ? submitText : " Yes, I’m Sure"}
        </MainButton>
      </div>
    </Modal>
  );
};
