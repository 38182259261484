const LoginPanelForgotPassword = ({ onClickForgotPassword }: { onClickForgotPassword: () => void }) => {
  return (
    <div className="text-center text-p2 text-type w-11/12 mx-auto">
      <p onClick={onClickForgotPassword} className="cursor-pointer text-primary font-semibold text-center mb-1">
        Forgot your Password
      </p>
      <p className=" text-center">
        Don't have an account?{" "}
        <a href="/signup" className="cursor-pointer text-primary font-semibold text-center">
          Sign Up
        </a>
      </p>
    </div>
  );
};

export default LoginPanelForgotPassword;
