import { hasActiveSubscription } from "../../components/utilities/utilityFunctions";
import { VaultFolderType } from "../../types/vaultTypes";

export const VAULT_FREEMIUM_LIMIT = 10;

// todo: should update the api to check if the folder exists as well
// without this client-side check, api allows duplicate folders
export const folderAlreadyExists = (name: string, existingFolders: VaultFolderType[]): boolean => {
  let exists = false;

  for (let i = 0; i < existingFolders.length; i++) {
    if (name === existingFolders[i]?.name) {
      exists = true;
    }
  }

  return exists;
};

export const isVaultEmpty = (folders: VaultFolderType[]): boolean => {
  return !!(
    folders?.length === 0 ||
    (folders?.length === 1 && folders?.[0]?.vaultDocuments?.length === 0 && folders?.[0]?.vaultNotes?.length === 0)
  );
};

export const isVaultUploadEnabled = (folders: VaultFolderType[], subscription: any): boolean => {
  return !!(
    hasActiveSubscription(subscription) &&
    folders?.[0]?.vaultNotes?.length + folders?.[0]?.vaultDocuments.length >= VAULT_FREEMIUM_LIMIT
  );
};
