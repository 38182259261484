import { Feedbackservice } from "../../../services/feedback";
import { formatedError } from "../../../services/formaterrors";

let feedbackService = new Feedbackservice();

export const giveFeedBack = (payload: any) => (dispatch: any) => {
  return feedbackService.submit(payload.type, payload.comment, payload.name, payload.email).then(
    (data) => {
      return Promise.resolve(data);
    },

    (error) => {
      dispatch({
        type: "",
      });
      let response = formatedError(error, dispatch);
      return Promise.reject(response);
    }
  );
};
