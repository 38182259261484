import React from "react";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { colorArray } from "../../config";
import { forMatterNumber } from "../../services/utilty";
import { Currency, HistoryType } from "../../types/accountTypes";
import { LabelType } from "../../types/categoryTypes";
import DashboardNetworthHistoryCardSkeleton from "./DashboardNetworthHistoryCardSkeleton";
import "./dashboard.scss";

const DashboardNetworthHistoryCard = ({
  config,
  isLoading,
  history,
  currencies,
  openAddAccountModal,
}: NetworthHistoryCardProps) => {
  const isTabletOrMobile = window.innerWidth < 768;
  const { code } = useSelector((state: any) => state.assets.currency);
  const isEmpty = !history || history.length === 0;

  let allCurrenciesData: any = currencies.map((currency: any, i: number) => {
    return {
      label: currency.code,
      data: history
        ?.filter((item: HistoryType) => item.money.currency === currency.code)
        .map((item: HistoryType) => ({ x: item.interval, y: item.money.value })),
      fill: false,
      backgroundColor: colorArray[i],
      borderColor: colorArray[i],
      maxHeight: "5px",
      tension: 0.3,
    };
  });

  const singleCurrencyData = [
    {
      label: code,
      data: history?.map((item: HistoryType) => ({ x: item.interval, y: item.money.value })),
      fill: true,
      backgroundColor: "#EBF3FF",
      borderColor: "#0056CD",
      tension: 0.1,
    },
  ];

  const graphData = {
    datasets: code ? singleCurrencyData : allCurrenciesData,
  };

  const options = {
    legend: {
      labels: {
        boxWidth: 40,
        boxHeight: 50,
        fontColor: "#A1ACAF",
      },
    },
    pointHitRadius: 1,
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          const point = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          const value = point.y;
          return (
            (value < 0 ? "-" : "") +
            (code ?? currencies[tooltipItem.datasetIndex].code) +
            forMatterNumber(Math.abs(value))
          );
        },
        title: (tooltipItem: any) => {
          return new Date(tooltipItem[0].xLabel).toLocaleString("en-US", config.tooltipTitle);
        },
      },
      mode: "index",
      intersect: false,
      padding: [10, 20, 30, 40],
      bodySpacing: 5,
      position: "nearest",
    },
    scales: {
      xAxes: [
        {
          time: {
            unit: config.interval.toLowerCase(),
          },
          type: "time",
          display: true,
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: true,
            callback: (label: number) => {
              return (label < 0 ? "-" : "") + forMatterNumber(Math.abs(label), 0);
            },
          },
          type: "linear",
          stacked: true,
          gridLines: {
            display: false,
          },
          userCallback: function (value: LabelType) {
            return Number(value).toLocaleString();
          },
        },
      ],
    },
    maintainAspectRatio: false,
    aspectRatio: 1,
    hover: {
      mode: "index",
      intersect: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <div className="p-4 bg-white rounded-md border border-neutral-200 md:border-none worthcard nethistory asset">
      <p className="title mb-6">Net Worth</p>

      <div style={{ maxHeight: !isEmpty ? "350px" : "auto", height: "100%", width: "100%" }}>
        {isLoading ? (
          <DashboardNetworthHistoryCardSkeleton />
        ) : isEmpty ? (
          <div className="bg-gray-100 rounded-md flex justify-center items-center py-12">
            <div className="text-type-200 text-center">
              <p className="mb-1">No data to display yet.</p>
              <button className="underline" onClick={openAddAccountModal}>
                Connect Account
              </button>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <div className="chartdatachart" style={{ height: "100%", position: "relative" }}>
              <Line
                data={graphData}
                height={isTabletOrMobile ? 300 : 400}
                width={isTabletOrMobile ? 300 : 400}
                options={options}
                redraw={false}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

interface NetworthHistoryCardProps {
  config: any;
  isLoading: boolean;
  history: [];
  currencies: Currency[];
  openAddAccountModal: () => void;
}

export default DashboardNetworthHistoryCard;
