import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConvertNumberCur } from "../../../services/utilty";
import { useDispatch } from "react-redux";
import { ConfirmationModal } from "../ConfirmationModal";
import { loaderData, setUtilityData } from "../../../store/actions/utility";
import { toast } from "react-toastify";
import { Partners, AccountType, CreateAccountInput, UpdateAccountInput } from "../../../types/accountTypes";
import { SET_USER_DATA } from "../../../store/actions/userdata/types";
import { useTranslation } from "react-i18next";
import { AccountFilesManager } from "./AccountFilesManager";
import { AccountHistory } from "./AccountHistory";
import { AccountNotesManager } from "./AccountNotesManager";
import { AccountDetails } from "./AccountDetails";
import CheckCir from "../../../assets/svg/successcheck.svg";
import useAccountConnect from "../../../hooks/useAccountConnect";
import EditAccount from "../connectAccount/EditAccount";
import MainButton from "../../buttons/mainButton";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import Modal from "react-modal";
import "./accountDetails.scss";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const AccountManager: React.FC<{
  isOpen: boolean;
  account: AccountType;
  closeModal: () => void;
  handleAddStocks: () => void;
  handleAddCrypto: () => void;
  handleRemoveAccountLabel: (id: string) => Promise<void>;
  handleSaveLabel: (accountIds: string[], labelId: string) => void;
  isShared?: boolean;
}> = ({
  isOpen,
  account,
  closeModal,
  handleAddStocks,
  handleAddCrypto,
  handleRemoveAccountLabel,
  handleSaveLabel,
  isShared,
}) => {
  const { t } = useTranslation();
  const isNative = Capacitor.isNativePlatform();
  const tabs = ["Details", "History", "Notes", "Files"];
  const [modal, setModal] = useState<string>("Details");
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const { handleRemoveAccount, handleUpdateCustomAccount } = useAccountConnect();
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState<boolean>(false);
  const navigate = useNavigate();

  const deleteAccount = () => {
    setConfirmDelete(false);
    dispatch(setUtilityData(loaderData(true, "")));
    handleRemoveAccount(account?.id)
      .then((res) => {
        if (!res?.errors || res?.data?.removeAccount) {
          toast.success(
            <div className="toast-div">
              <img src={CheckCir} alt="check circle" />
              Account deleted successfully
            </div>
          );
          closeModal();
        } else {
          toast.error("Something went wrong, please try again");
          dispatch(setUtilityData(loaderData(false, "")));
        }
      })
      .catch((error: any) => {
        dispatch(setUtilityData(loaderData(false, "")));
        toast.error(error.message);
      });
  };

  const updateCustomAccount = (values: CreateAccountInput) => {
    const payload: UpdateAccountInput = {
      balance: values.balance,
      currency: values.currency,
      id: account.id,
      display_name: values.name,
      institution_id: values.institution_id,
      number: values.number,
    };

    if (values.address) {
      payload.address = values.address;
    }
    if (values.balance_original) {
      payload.balance_original = values.balance_original;
    }

    dispatch(setUtilityData(loaderData(true, "")));

    handleUpdateCustomAccount(payload)
      .then((res: any) => {
        if (!res?.errors) {
          const response: { id: string }[] = res?.data?.updateAccount;

          if (response.length > 0) {
            dispatch({
              type: SET_USER_DATA,
              payload: {
                valueX: response[0]?.id,
              },
            });
          }

          toast.success(
            <div className="toast-div">
              <img src={CheckCir} alt="check circle" />
              Account updated successfully
            </div>
          );
          closeModal();
          dispatch(setUtilityData(loaderData(false, "")));
          navigate("/portfolio/institutions/?rfr=true");
        } else {
          toast.error("Something went wrong, please try again");
          dispatch(setUtilityData(loaderData(false, "")));
        }
      })
      .catch(() => {
        dispatch(setUtilityData(loaderData(false, "")));
      });
  };

  return (
    <>
      {confirmDelete && (
        <ConfirmationModal
          modalIsOpen={confirmDelete}
          closeModal={() => {
            setConfirmDelete(false);
          }}
          submitAction={deleteAccount}
          confirmationText={t("accounts.deleteInfo")}
        />
      )}

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Account Manager"
        className={classNames("modal-container", isNative && "native-modal")}
        overlayClassName="modal-container-overlay"
      >
        {/* Header */}
        <div className="modal-header px-4 flex items-center justify-end">
          <button type="button" onClick={closeModal} className="cursor-pointer">
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>
        <div className="modal-body px-4 md:px-8">
          {/* Account + Balance */}
          <div className="flex flex-col mb-4">
            <h2 className="text-lg font-semibold text-black">
              {account?.display_name ? account?.display_name : account?.name}
            </h2>
            <p className="text-sm">{ConvertNumberCur(account?.balance?.value, account?.balance?.symbol)}</p>
          </div>

          {/* Tabs */}
          {!editMode && (
            // Tab Switcher
            <div>
              <div className="flex border-b mt-3 pb-2">
                {tabs.map((tab, index) => (
                  <button
                    key={`button-${index}`}
                    onClick={() => setModal(tab)}
                    className={`mr-4 px-4 py-1 font-medium text-p2 rounded-full ${
                      modal === tab ? "bg-primary-beau text-primary" : "bg-neutral-300 text-type-100"
                    }`}
                  >
                    {tab}
                  </button>
                ))}
              </div>

              {/* Selected View */}
              <div className="py-4 flex flex-col h-full">
                {modal === "History" && <AccountHistory accountId={account?.id} />}
                {modal === "Notes" && <AccountNotesManager account={account} closeModal={closeModal} />}
                {modal === "Files" && <AccountFilesManager account={account} closeModal={closeModal} />}
                {modal === "Details" && (
                  <AccountDetails
                    account={account}
                    closeModal={closeModal}
                    setConfirmDelete={setConfirmDelete}
                    handleAddStocks={handleAddStocks}
                    handleAddCrypto={handleAddCrypto}
                    handleRemoveAccountLabel={handleRemoveAccountLabel}
                    handleSaveLabel={handleSaveLabel}
                    isShared={isShared}
                  />
                )}
              </div>
            </div>
          )}

          {/* Editing Account View */}
          {editMode && (
            <div className="py-4">
              <EditAccount
                account={account}
                goBack={() => {
                  setEditMode(false);
                }}
                updateCustomAccount={updateCustomAccount}
              />
            </div>
          )}
        </div>

        {!editMode && (
          <div className="modal-footer p-4 gap-3 flex justify-end border-t bg-white">
            <MainButton type="secondary" size="small" click={closeModal}>
              Close
            </MainButton>

            {!isShared &&
              (account.partner === Partners.CUSTOM ||
                account.partner === Partners.COIN_CAP ||
                account.partner === Partners.MARKET_STACK) && (
                <MainButton
                  type="primary"
                  size="small"
                  click={() => {
                    setEditMode(true);
                  }}
                >
                  Edit Information
                </MainButton>
              )}
          </div>
        )}
      </Modal>
    </>
  );
};
