import { LOGOUT_USER } from "../store/actions/userdata/types";

export const formatedError = (error: any, dispatch: any) => {
  let response = {
    message: "",
    data: {},
    status: {},
    headers: {},
    request: {},
  };
  if (error.response) {
    response.message = error.response?.data?.message || error.message;
    response.data = error.response?.data;
    response.status = error.response.status;
    response.headers = error.response.headers;
  } else if (error.request) {
    response.message = error.message;
    response.request = error.request;
  } else {
    response.message = error.message;
  }
  if (Number(response.status) === 500) {
    response.message = "An error occured, please try again later.";
  }
  if (Number(response.status) === 403 || Number(response.status) === 401) {
    localStorage.setItem("sessionState", window.location.pathname);

    dispatch({
      type: LOGOUT_USER,
      payload: {},
    });
    let token = localStorage.getItem("token") ?? null;

    response.message = token ? "Your session is timed out , kindly login again." : "An error occured please try again";
  }

  return response;
};
