import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import MainButton from "../../buttons/mainButton";
import CustomAsset from "../../../assets/svg/custom-color/asset.svg";
import { useReconnectPlaid } from "../../../hooks/usePlaidMono";
import useAccountConnect from "../../../hooks/useAccountConnect";
import { ConvertNumberCur } from "../../../services/utilty";
import { loaderData, setUtilityData } from "../../../store/actions/utility";
import CheckCir from "../../../assets/svg/successcheck.svg";
import "./reconnect.scss";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface IReconnectAccount {
  modalOpen: boolean;
  closeModal: () => void;
  disconnectedAccounts: any[];
  reconnectCallback: () => void;
}

const ReconnectAccount = ({ modalOpen, closeModal, disconnectedAccounts, reconnectCallback }: IReconnectAccount) => {
  const { reconnectDisconnectedPlaid } = useReconnectPlaid();
  const { handleRemoveConnection } = useAccountConnect();

  const dispatch = useDispatch();

  const handleReconnect = async (reconnect_data: any) => {
    reconnectDisconnectedPlaid(reconnect_data);
  };

  const deleteConnection = (connectionId: string) => {
    dispatch(setUtilityData(loaderData(true, "")));
    handleRemoveConnection(connectionId)
      .then((res) => {
        if (!res?.errors || res?.data?.removeAccount) {
          toast.success(
            <div className="toast-div">
              <img src={CheckCir} alt="check circle" />
              Institution deleted successfully
            </div>
          );
          reconnectCallback();
          closeModal();
        } else {
          toast.error("Something went wrong, please try again");
          dispatch(setUtilityData(loaderData(false, "")));
        }
      })
      .catch((error: any) => {
        dispatch(setUtilityData(loaderData(false, "")));
        toast.error(error.message);
      });
  };

  const isNative = Capacitor.isNativePlatform();

  return (
    <div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        contentLabel="Add Account Modal"
        className={classNames(`reconnectAccount postionmoal relative`, isNative && "native-modal")}
        overlayClassName={"modal-container-overlay"}
      >
        <div className="header flex justify-end -translate-y-1 mb-1">
          <button type="button" onClick={closeModal} className="closeicon cursor-pointer">
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>

        <div>
          <h3 className="text-h4 font-semibold">Reconnect your Institutions</h3>

          <p className="text-p2 mt-3 text-type-100">
            When there is an update in the security procedures from your financial institution, you&#39;ll need to
            refresh your asset connection. Click the institutions to reconnect.
          </p>
        </div>
        <div className="mt-8 scrollcontainer">
          {disconnectedAccounts.map(
            (acc: any, index: number) =>
              acc.accounts.length > 0 && (
                <div className="flex justify-between items-center border p-4 rounded mb-4" key={`acct-${index}`}>
                  <div className="flex items-start">
                    <img
                      src={acc?.institution?.logo ? `data:image/jpeg;base64,${acc?.institution?.logo}` : CustomAsset}
                      alt="institution logo"
                      className="mr-2.5 p-1 h-8 w-8 bg-blue-100 rounded-lg"
                    />
                    <div>
                      <p className="text-p1 font-medium">{acc?.institution?.name}</p>

                      {acc?.accounts.map((acct: any, idx: number) => (
                        <small className="reconnect-err" key={`acc-${idx}`}>
                          {acct?.display_name}
                          {"  -  "}
                          {ConvertNumberCur(acct?.balance?.value, acct?.balance?.symbol)}
                        </small>
                      ))}
                    </div>
                  </div>
                  <div className="reconnect-btn">
                    {acc?.fixable ? (
                      <MainButton
                        size="small"
                        type="primary"
                        click={() => handleReconnect(acc)}
                        extraClasses="px-3 py-1"
                      >
                        Reconnect
                      </MainButton>
                    ) : (
                      <MainButton
                        size="small"
                        type="delete"
                        click={() => deleteConnection(acc.id)}
                        extraClasses="px-3 py-1"
                      >
                        Delete
                      </MainButton>
                    )}
                  </div>
                </div>
              )
          )}
        </div>
        <div className="absolute bottom-0 p-5 border-t right-0 flex justify-end w-full">
          <MainButton type="secondary" size="big" click={closeModal}>
            Continue Later
          </MainButton>
        </div>
      </Modal>
    </div>
  );
};

export default ReconnectAccount;
