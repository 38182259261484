import classNames from "classnames";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as SearchIcon } from "../../assets/svg/search.svg";
import { AddEditContactModal } from "../lifecheck-v2/AddEditContactModal";
import { ContactType } from "../../types/lifecheckTypes";
import { IndividualContact } from "./ContactRow";
import ContactsSkeletons from "./ContactsSkeletons";
import ContactsDescription from "./ContactsDescription";
import ContactsMeta from "./ContactsMeta";
import MainButton from "../../components/buttons/mainButton";
import { useGetContactsQuery } from "../../api/contact/contact-queries";
import ContactsEmpty from "./ContactsEmpty";
import { PlusIcon } from "@heroicons/react/24/outline";

const Contacts = () => {
  const { id: portfolioId } = useSelector((state: any) => state.activePortfolio);
  const [searchParams, setSearchParams] = useState<string>("");
  const [isAddContactModalOpen, openAddContactModal] = useState(false);
  const {
    data: contacts,
    isFetching: isFetchingContacts,
    isFetched: didFetchContacts,
  } = useGetContactsQuery({
    id: portfolioId,
    search: searchParams,
  });
  const filteredContacts = contacts?.filter((contact: any) =>
    (contact.first_name + " " + contact.last_name).match(new RegExp(searchParams, "ig"))
  );

  // Info Card
  const [showContactsInfoCard, setShowContactsInfoCard] = useState(true);

  useEffect(() => {
    const storedValue = localStorage.getItem("showContactsInfoCard");
    if (!storedValue) return;
    setShowContactsInfoCard(storedValue === "true");
  }, []);

  const onCloseContactsInfoCard = () => {
    setShowContactsInfoCard(false);
    localStorage.setItem("showContactsInfoCard", "false");
  };

  return (
    <div className="p-4 pb-12 md:p-8 flex justify-center">
      {isAddContactModalOpen && <AddEditContactModal isOpen={true} closeModal={() => openAddContactModal(false)} />}

      <div className="w-full max-w-[1440px]">
        <ContactsMeta />
        {showContactsInfoCard ? (
          <ContactsDescription onCloseContactsInfoCard={onCloseContactsInfoCard} />
        ) : (
          <h2 className="font-semibold text-xl md:text-2xl">Contacts</h2>
        )}

        <div className="my-6 flex flex-row flex-wrap gap-y-4 justify-end md:justify-between items-center">
          <div className="relative w-full md:w-auto">
            <span className="absolute top-1/2 -translate-y-1/2 left-3">
              <SearchIcon width={22} height={22} />
            </span>
            <input
              className={classNames(
                "w-full md:w-96",
                "px-8 p-3 pl-10",
                "font-normal",
                "bg-neutral-300 rounded",
                "transition focus:border-type focus:ring-0 border-neutral-200"
              )}
              autoComplete={"off"}
              placeholder="Search Contact"
              onChange={({ target }) => setSearchParams(target.value)}
            />
          </div>
          <MainButton
            size="small"
            type="primary"
            click={() => openAddContactModal(true)}
            extraClasses="flex items-center gap-1"
          >
            <PlusIcon className="h-5 w-5" /> Add Contact
          </MainButton>
        </div>

        <table className="md:table-auto md:border border-slate-400 w-full">
          {!!contacts?.length && (
            <thead className="hidden md:table-header-group">
              <tr className="uppercase">
                <th className="text-left border-b border-slate-300 p-4">Contact Name</th>
                <th className="text-left border-b border-slate-300 p-4">Last Reviewed</th>
                <th className="text-left border-b border-slate-300 p-4">Contact Details</th>
                <th className="text-center border-b border-slate-300 p-4">Actions</th>
              </tr>
            </thead>
          )}

          {isFetchingContacts ? (
            <ContactsSkeletons />
          ) : !filteredContacts?.length && didFetchContacts ? (
            <ContactsEmpty onClickAddContact={() => openAddContactModal(true)} />
          ) : (
            filteredContacts?.map((contact: ContactType) => <IndividualContact contact={contact} key={contact.id} />)
          )}
        </table>

        {!showContactsInfoCard && (
          <div className="w-full flex justify-center">
            <p className="max-w-[600px] leading-5 mt-14 text-center px-10">
              These are the individuals or organizations you'd love to collaborate with or transfer your portfolio to in
              case of emergencies or unforeseen circumstances.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
export default Contacts;
