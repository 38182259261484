import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { loaderData, setUtilityData } from "../store/actions/utility";
import { useCurrentUserGql } from "./current-user.gql-hook";
import { CREATE_TOTP_SECRET, DISABLE_SMS, DISABLE_TOTP, ENABLE_TOTP } from "./mfa.gql-query";

export const useMFAGql = () => {
  const dispatch = useDispatch();
  const { fetchUserPreferences } = useCurrentUserGql();

  const completedCallback = (data: any) => {
    dispatch(setUtilityData(loaderData(false, "")));
    fetchUserPreferences();
  };

  const onErrorCallback = (error: any) => {
    toast.error(error.message);
    dispatch(setUtilityData(loaderData(false, "")));
  };

  const [createTotpSecret] = useMutation(CREATE_TOTP_SECRET, {
    errorPolicy: "all",
    onCompleted: completedCallback,
    onError: onErrorCallback,
  });

  const [enableTotp] = useMutation(ENABLE_TOTP, {
    errorPolicy: "all",
    onCompleted: completedCallback,
    onError: onErrorCallback,
  });

  const [disableTotp] = useMutation(DISABLE_TOTP, {
    errorPolicy: "all",
    onCompleted: completedCallback,
    onError: onErrorCallback,
  });

  const [disableSms] = useMutation(DISABLE_SMS, {
    errorPolicy: "all",
    onCompleted: completedCallback,
    onError: onErrorCallback,
  });

  return {
    enableTotp,
    disableTotp,
    disableSms,
    createTotpSecret,
  };
};
