import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/svg/logo-light.svg";
import { ReactComponent as LifecheckLogo } from "../../assets/svg/logo-lifecheck-light.svg";
import { useSwitchActivePortfolio } from "../../graphql/portfolio.gql-hook";
import { FeedbackModal } from "../../pages/portfolio/assetsmodals";
import { logoutaUser } from "../../store/actions/userdata";
import { SubscriptionStatus } from "../../types/subscriptionTypes";
import PortfolioSwitcher from "../loggedin/PortfolioSwitcher";
import UpgradeBtn from "../buttons/UpgradeBtn";
import FeedbackBtn from "../buttons/FeedbackBtn";
import { SidebarCategories } from "./sidebar-categories";
import { SidebarCategoryTitle } from "./SidebarCategoryTitle";
import { SidebarCategoryRoute } from "./SidebarCategoryRoute";
import { SidebarLogoutBtn } from "./SidebarLogoutBtn";
import { SidebarBacktoProfileBtn } from "./SidebarBacktoProfileBtn";
import { ConfirmationModal } from "../modals/ConfirmationModal";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { useGetSubscriptionQuery } from "../../api/subscription/subscription-queries";
import { useTranslation } from "react-i18next";
import "../../pages/collaborations/collaborations.scss";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface SidebarProps {
  toggleSidebar: () => void;
  showSidebar: boolean;
}

// TODO: Clean up file to use new Redux states and actions.
// Beginning of changes making use of new reducers.
const Sidebar = ({ toggleSidebar, showSidebar }: SidebarProps) => {
  const isProd = process.env.NODE_ENV === "production";
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Subscription
  const { id: activePortfolioId } = useSelector((state: any) => state.activePortfolio);
  const { data: subscription, isFetched: isFetchedSubscription } = useGetSubscriptionQuery({
    id: activePortfolioId,
  });
  const hasActiveSubscription = subscription && subscription.status === SubscriptionStatus.ACTIVE;

  const onClickUpgrade = () => navigate("/settings?tab=billing");

  // Active Portfolio
  const switchActivePortfolio = useSwitchActivePortfolio();
  const { portfolios } = useSelector((state: any) => state.generalStore);
  const activePortfolio = useSelector((state: any) => state.activePortfolio);
  const showPortfolioSwitcher = false;

  const onClickBackToProfile = (e: any) => {
    e.stopPropagation();
    switchActivePortfolio(portfolios.filter((next: any) => next.owner)[0]?.id);
    navigate("/collaboration");
  };

  // Sidebar Menu Display Logic
  const location = useLocation();
  const showMenu = (showOnlyOwner: boolean) => {
    const { owner: isOwner, id } = activePortfolio;
    return !id || isOwner === undefined || showOnlyOwner === false || isOwner;
  };

  // Auth Actions
  const logOutUser = async () => {
    try {
      await dispatch(logoutaUser({}));
      navigate("/signin");
    } catch (error) {
      console.error("fail: logOutUser:", error);
    }
  };

  // Feedback
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  // Click Outside
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (showSidebar && sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        toggleSidebar();
      }
    };

    const handleOutsideClickWrapper = (event: MouseEvent) => {
      handleOutsideClick(event);
    };

    document.addEventListener("mousedown", handleOutsideClickWrapper);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClickWrapper);
    };
  }, [showSidebar, toggleSidebar]);

  // Sidebar Dimensions
  useEffect(() => {
    const updateSidebarHeight = () => {
      const sidebar = sidebarRef.current;
      if (sidebar) {
        const headerHeight = 36; // Height of the header section
        const footerHeight = 7; // Height of the footer section
        sidebar.style.height = `calc(100vh + ${headerHeight}px + ${footerHeight}px)`;
      }
    };

    updateSidebarHeight();
    window.addEventListener("resize", updateSidebarHeight);

    return () => {
      window.removeEventListener("resize", updateSidebarHeight);
    };
  }, []);

  const [showConfirmLogoutModal, setShowConfirmLogoutModal] = useState(false);

  return (
    <>
      {showFeedbackModal && (
        <FeedbackModal
          modalIsOpen={showFeedbackModal}
          closeModal={() => {
            setShowFeedbackModal(false);
          }}
        />
      )}

      {showConfirmLogoutModal && (
        <ConfirmationModal
          modalIsOpen={showConfirmLogoutModal}
          closeModal={() => {
            setShowConfirmLogoutModal(false);
          }}
          submitAction={logOutUser}
          confirmationTitle="Logout"
          confirmationText={<span>Are you sure you want to logout of {t("orgName")}?</span>}
        />
      )}

      <div
        className={classNames(
          `
          sidebar flex-col safe-bottom
          w-60
          absolute px-4 py-4 bg-primary-cobalt text-white select-none
          ${showSidebar ? "sidebar--open" : "sidebar--closed"}
        `,
          Capacitor.isNativePlatform() && "w-4/5"
        )}
        ref={sidebarRef}
      >
        <div>
          {/* Section 1: Logo + Close Btn */}
          <div className="relative w-full py-2 px-2 flex justify-start items-center">
            {isProd ? <Logo /> : <LifecheckLogo width={100} height={40} />}
            <div onClick={toggleSidebar} className="sidebar-close-btn absolute right-2 hover:opacity-50 cursor-pointer">
              <XMarkIcon className="w-6 h-6" />
            </div>
          </div>

          {/* Section 2: Portfolio Switcher. Currently permanently hidden. */}
          {showPortfolioSwitcher && (
            <div className="px-4 mt-2 mb-6">
              <PortfolioSwitcher
                portfolios={portfolios}
                portfolio={activePortfolio}
                switchPortfolio={switchActivePortfolio}
                hide={true}
              />
            </div>
          )}
        </div>

        {/* Section 3: Sidebar Menu */}
        <div
          className={"relative flex flex-col justify-between overflow-y-auto"}
          style={{ height: showSidebar ? "calc(100vh + 56px)" : "auto" }}
        >
          {/* Section 3A: Back to My Profile */}
          {activePortfolio.owner === false && (
            <div className="mt-2 mb-8">
              <SidebarBacktoProfileBtn onClickBackToProfile={onClickBackToProfile} />
            </div>
          )}

          {/* Section 3B: Sidebar Menu */}
          <div className="mt-8">
            {SidebarCategories.filter((category) => showMenu(category?.onlyOwner)).map((category, index) => (
              <div className="mb-8" key={index}>
                {/* Category */}
                {category.title && <SidebarCategoryTitle title={category.title} />}

                {/* Category Routes */}
                {category.routes
                  .filter((item) => showMenu(item?.onlyOwner))
                  .map((item) => (
                    <SidebarCategoryRoute
                      key={item.name}
                      item={item}
                      location={location}
                      showSidebar={showSidebar}
                      toggleSidebar={toggleSidebar}
                      classNames="mb-1"
                    />
                  ))}

                {/* Logout Btn */}
                {index === 2 && (
                  <SidebarLogoutBtn onClick={() => setShowConfirmLogoutModal(true)} activePortfolio={activePortfolio} />
                )}
              </div>
            ))}
          </div>

          {/* Section 3C: Sidebar Footer */}
          <div className="pb-2">
            {!hasActiveSubscription && isFetchedSubscription && <UpgradeBtn onClick={onClickUpgrade} />}
            <hr className="my-3" />
            <FeedbackBtn onClick={() => setShowFeedbackModal(true)} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
