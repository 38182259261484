import { useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { loaderData, setUtilityData } from "../store/actions/utility";
import { FETCH_PREFERENCES, FETCH_USER, UPDATE_PREFERENCES } from "./current-user.gql-query";
import { UPDATE_CURRENT_USER } from "../store/actions/current-user.actions";
import { UserSortKey, UserSortRule } from "../types/sortTypes";

export const useCurrentUserGql = () => {
  const dispatch = useDispatch();

  const updateCurrentUserCompletedCallback = (data: any) => {
    dispatch({
      type: UPDATE_CURRENT_USER,
      payload: data?.currentUser,
    });

    dispatch(setUtilityData(loaderData(false, "")));
  };

  const updateCurrentUserErrorCallback = (error: any) => {
    toast.error(error.message);
    dispatch(setUtilityData(loaderData(false, "")));
  };

  const [fetchUserPreferences] = useLazyQuery(FETCH_PREFERENCES, {
    errorPolicy: "all",
    onCompleted: updateCurrentUserCompletedCallback,
    onError: updateCurrentUserErrorCallback,
  });

  const [updatePreferences] = useMutation(UPDATE_PREFERENCES, {
    errorPolicy: "all",
    onCompleted: updateCurrentUserCompletedCallback,
    onError: updateCurrentUserErrorCallback,
  });

  const [fetchUser] = useLazyQuery(FETCH_USER, {
    errorPolicy: "all",
    onCompleted: updateCurrentUserCompletedCallback,
    onError: updateCurrentUserErrorCallback,
  });

  const updateUserSortOrder = (key: UserSortKey, sortOrder: UserSortRule) => {
    updatePreferences({
      variables: {
        preferences: {
          [key]: sortOrder,
        },
      },
    });
  };

  return {
    fetchUserPreferences,
    updateUserSortOrder,
    fetchUser,
  };
};
