import { SortableGroup } from "../../types/categoryTypes";
import {
  ADD_OR_UPDATE_ACTIVE_PORTFOLIO_CONTACT,
  REMOVE_ACTIVE_PORTFOLIO_CONTACT,
  SWITCH_ACTIVE_PORTFOLIO,
  UPDATE_ACTIVE_PORTFOLIO,
  UPDATE_ACTIVE_PORTFOLIO_LIFECHECK,
} from "../actions/portfolio.actions";

const initialState = {
  id: undefined,
  institutions: undefined,
  classes: undefined,
  labels: undefined,
  connections: undefined,
  lifecheck: undefined,
  contacts: undefined,
};

const computeGrowthOnSortableGroup = (group: SortableGroup[]) => {
  if (!group) return group;

  return group.map((next: SortableGroup) => {
    return {
      ...next,
      accounts: (next.accounts || []).map((account) => {
        const {
          balance_original: { value: originalValue },
          balance: { value: currentValue },
        } = account;

        return { ...account, growth: originalValue !== 0 ? ((currentValue - originalValue) / originalValue) * 100 : 0 };
      }),
    };
  });
};

const computeGrowthOnSate = (state: any) => {
  let { institutions, classes, labels } = state;

  return {
    ...state,
    institutions: computeGrowthOnSortableGroup(institutions),
    classes: computeGrowthOnSortableGroup(classes),
    labels: computeGrowthOnSortableGroup(labels),
  };
};

export default function portfolioReducer(state = initialState, action: any) {
  const { contacts } = state as any;
  const { lifecheck } = state as any;

  switch (action.type) {
    case UPDATE_ACTIVE_PORTFOLIO:
      // Doing this "growth" computation at the reducer level only because it is used in multiple places
      return computeGrowthOnSate({
        ...state,
        ...action.payload,
      });
    case SWITCH_ACTIVE_PORTFOLIO:
      // Doing this "growth" computation at the reducer level only because it is used in multiple places
      return computeGrowthOnSate({
        ...initialState,
        id: action.id,
      });
    case ADD_OR_UPDATE_ACTIVE_PORTFOLIO_CONTACT:
      if (!contacts) {
        return state;
      }

      const contactIndex = contacts.findIndex((contact: any) => contact.id === action.payload.id);
      if (contactIndex === -1) {
        contacts.push(action.payload);
      } else {
        contacts[contactIndex] = action.payload;
      }

      return {
        ...state,
        ...{ contacts },
      };

    case REMOVE_ACTIVE_PORTFOLIO_CONTACT:
      if (!contacts) {
        return state;
      }

      return {
        ...state,
        contacts: contacts.filter((contact: any) => contact.id !== action.payload),
      };

    case UPDATE_ACTIVE_PORTFOLIO_LIFECHECK:
      if (!lifecheck) {
        return state;
      }

      return {
        ...state,
        lifecheck: {
          ...lifecheck,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}
