import AxoisApi from "../../api.ts";
import { ALLAPIS } from "../../api.ts/apilog";

export class Lifecheckservice {
  getQuestions(hash: string) {
    return AxoisApi.get(`${ALLAPIS.LIFE_CHECK.GET_QUESTIONS(hash)}`).then((res) => {
      return res.data;
    });
  }

  fetchInheritedPortfolios() {
    return AxoisApi.get(`${ALLAPIS.LIFE_CHECK.RECIPIENT}/invitations`).then((res) => {
      return res.data;
    });
  }

  verifyAnswer(data: { recipientEmail: string; userId: number; questionId: string; answer: string }) {
    return AxoisApi.post(`${ALLAPIS.LIFE_CHECK.VERIFY_ANSWER}`, data).then((res) => {
      return res.data;
    });
  }

  nonUserPasswordSetup(data: { recipientEmail: string; password: string }) {
    return AxoisApi.post(`${ALLAPIS.LIFE_CHECK.NON_USER_PASSWORD_SETUP}`, data).then((res) => {
      return res.data;
    });
  }

  fetchSharedPortfolio(recipientId: string) {
    return AxoisApi.get(`${ALLAPIS.LIFE_CHECK.GET_RECIPIENT_PORTFOLIO(recipientId)}`).then((res) => {
      return res.data;
    });
  }
}
