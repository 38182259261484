import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUtilityData } from "../../store/actions/utility";
import { useAddAccountModalToggle } from "../../graphql/general-store.gql-hook";
import { useOnboardingSteps } from "../../hooks/useOnboardingSteps";
import { ReactComponent as ManExploringGlobe } from "./../../assets/svg/man-exploring-globe.svg";
import CircleChecked from "./../../assets/svg/circle-checked.svg";
import CircleUnchecked from "./../../assets/svg/circle-unchecked.svg";

const DashboardOnboardingSteps = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { 1: openAccountModal } = useAddAccountModalToggle();
  const { onboardingSteps, completed } = useOnboardingSteps();

  const steps = [
    {
      step: "Connect accounts",
      desc: "Add your bank accounts, crypto, stocks, others.",
      completed: onboardingSteps.isAccountConnected,
      action: () => openAccountModal(true),
    },
    {
      step: "Set up 2FA",
      desc: "Protect your portfolio with 2FA Authentication.",
      completed: onboardingSteps.is2FAauthenticated,
      action: () => {
        navigate("/settings?security=update");
      },
    },
    {
      step: "Add recipients",
      desc: "Save the beneficiaries of your portfolio.",
      completed: onboardingSteps.isRecipientAdded,
      action: () => {
        navigate("/life-check");
      },
    },
    {
      step: "Set up your vault",
      desc: "Keep important documents and files safe.",
      completed: onboardingSteps.isVaultConnected,
      action: () => {
        navigate("/vault");
      },
    },
    {
      step: "Share portfolio",
      desc: "Collaborate with trusted friends and family on your wealth ",
      completed: onboardingSteps.isPortfolioShared,
      action: () => {},
    },
  ];

  if (completed < 3) {
    return <></>;
  }

  return (
    <div className={`useronboarding ${!onboardingSteps.is2FAauthenticated ? "extra-space" : ""}`}>
      <div className="row-one">
        <div className="left">
          <ManExploringGlobe className="manicon" />
          <div className="text">
            <div className="title">Let’s get you started</div>
            <div className="description">Connect your institutions, create your portfolio.</div>
          </div>
          <div className="completed">{completed}/5 completed</div>
        </div>
        <div
          className="right"
          onClick={() => {
            dispatch(setUtilityData({ showOnboardingSteps: false }));
            dispatch(setUtilityData({ userClosedOnboarding: true }));
          }}
        >
          Close and continue later
        </div>
      </div>

      <div className="row-two">
        {steps.map((item, index) => (
          <div className="onboardingstep" key={index}>
            <div className="iconwrapper">
              <img className="checkicon" src={item.completed ? CircleChecked : CircleUnchecked} alt="" />
            </div>
            <div className="stepinfo">
              <div className="action" onClick={item.action}>
                {item.step}
              </div>
              <div className="desc">{item.desc}</div>
            </div>
          </div>
        ))}
      </div>

      <div className="continue-mobile">
        <div
          className="px-3 py-2 text-sm rounded font-semibold"
          style={{ backgroundColor: "#EBEFF4", width: "fit-content", color: "#4F5D6D" }}
          onClick={() => {
            dispatch(setUtilityData({ showOnboardingSteps: false }));
            dispatch(setUtilityData({ userClosedOnboarding: true }));
          }}
        >
          Continue Later
        </div>
      </div>
    </div>
  );
};

export default DashboardOnboardingSteps;
