import { Authservice } from "../../../services/auth";
import { formatedError } from "../../../services/formaterrors";
import { SET_USER_DATA, LOGOUT_USER } from "./types";

let authservice = new Authservice();

export const setUserData = (payload: any) => (dispatch: any) => {
  dispatch({
    type: SET_USER_DATA,
    payload,
  });
};

export const logoutaUser = (payload: any) => (dispatch: any) => {
  localStorage.removeItem("x2cvd");
  dispatch({
    type: LOGOUT_USER,
    payload,
  });
  return Promise.resolve({});
};

export const sendDeviceDetails = (payload: any) => (dispatch: any) => {
  return authservice.sendDeviceDetails(payload).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      let response = formatedError(error, dispatch);
      return Promise.reject(response);
    }
  );
};

export const getReferralDetails = () => (dispatch: any) => {
  return authservice.getReferralDetails().then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      let response = formatedError(error, dispatch);
      return Promise.reject(response);
    }
  );
};

export const setupReferralEmail = (data: { email: string; paypal_email: string }) => (dispatch: any) => {
  return authservice.setupReferralEmail(data).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      let response = formatedError(error, dispatch);
      return Promise.reject(response);
    }
  );
};

export const claimReward = () => (dispatch: any) => {
  return authservice.claimReward().then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      let response = formatedError(error, dispatch);
      return Promise.reject(response);
    }
  );
};
