import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { APPCONFIG } from "../config";
import { getVezgoDetails } from "../store/actions/assetsaction";
import { loaderData, setUtilityData } from "../store/actions/utility";
import { ALLAPIS } from "../api.ts/apilog";

export const useCryptoAsset = () => {
  const dispatch = useDispatch();

  const initiateVezgo = async () => {
    dispatch(setUtilityData(loaderData(true, "")));
    const vezgoDetails: any = await dispatch(getVezgoDetails());
    const params = {
      client_id: APPCONFIG.vezgoClientId,
      origin: APPCONFIG.origin,
      token: vezgoDetails?.token,
      lang: "en",
      redirect_uri: vezgoDetails?.redirect_uri,
    };

    if (vezgoDetails?.redirect_uri && vezgoDetails?.token) {
      window.open(ALLAPIS.VEZGO.GET_VEZGO_LINK(params), "_self");
    } else {
      dispatch(setUtilityData(loaderData(false, "")));
      toast.error("Something went wrong. Please try again!");
    }
  };

  return {
    initiateVezgo,
  };
};
