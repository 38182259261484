import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList } from "react-tabs";
import MainButton from "../../buttons/mainButton";
import useAccountConnect from "../../../hooks/useAccountConnect";
import { loaderData, setUtilityData } from "../../../store/actions/utility";
import "./connect-account.scss";
import { toast } from "react-toastify";
import CheckCir from "../../../assets/svg/successcheck.svg";
import { Partners, CreateAccountInput, AddCustomAccountData } from "../../../types/accountTypes";
import { SET_USER_DATA } from "../../../store/actions/userdata/types";
import classNames from "classnames";
import { getConnectAccountTabs } from "./tabs";
import { PanelActions } from "./PanelActions";
import { ViewCategories } from "./Categories";

interface Props {
  closeModal: () => void;
  setActiveView: (value: string) => void;
  fromMobileApp?: boolean;
}

export const Manual = ({ closeModal, setActiveView, fromMobileApp }: Props) => {
  const [indexVal, setIndex] = useState(0);
  const [hasSelectedClass, setHasSelectedClass] = useState(false);

  const { handleAddCustomAccount } = useAccountConnect();
  const activePortfolioId = useSelector((state: any) => state.portfolios.activePortfolioId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addAccount = (values: CreateAccountInput, partner?: Partners) => {
    const payload: AddCustomAccountData = {
      account: { ...values, portfolio_id: activePortfolioId },
      portfolioId: activePortfolioId,
      partner: partner ?? Partners.CUSTOM,
    };

    dispatch(setUtilityData(loaderData(true, "")));

    handleAddCustomAccount(payload)
      .then((res: any) => {
        if (!res?.errors) {
          const response: { id: string }[] = res?.data?.addCustomAccount;

          if (response.length > 0) {
            dispatch({
              type: SET_USER_DATA,
              payload: {
                valueX: response[0]?.id,
              },
            });
          }

          toast.success(
            <div className="toast-div">
              <img src={CheckCir} alt="check circle" />
              Account added successfully
            </div>
          );
          closeModal();
          dispatch(setUtilityData(loaderData(false, "")));
          fromMobileApp ? navigate("/portfolio/institutions?mobile-connect=true") : navigate("/portfolio/institutions");
        } else {
          toast.error("Something went wrong, please try again");
          dispatch(setUtilityData(loaderData(false, "")));
        }
      })
      .catch(() => {
        dispatch(setUtilityData(loaderData(false, "")));
      });
  };

  return (
    <div className="">
      {/* Body */}
      <div className="modal-body px-4 md:px-8 pt-5 pb-10">
        <div className="flex w-full justify-between items-center">
          {indexVal === 0 && !hasSelectedClass && (
            <PanelActions
              onClickBack={() => {
                setIndex(0);
                setHasSelectedClass(false);
                setActiveView(ViewCategories.categories);
              }}
            />
          )}
        </div>
        <div className={classNames("header md:mb-5", hasSelectedClass ? "hidden md:flex" : "flex")}>
          <p className={classNames("h4-semibold text-h4 font-semibold text-type mb-2")}>Add Manually</p>
        </div>

        <Tabs
          className="flex gap-x-10"
          selectedIndex={indexVal}
          onSelect={(index: any) => {
            setHasSelectedClass(true);
            setIndex(index);
          }}
        >
          {/* Options */}
          <div id="tabListEl" className={classNames(hasSelectedClass ? "hidden md:flex flex-col" : "flex flex-col")}>
            <p className="text-md mb-4">Choose a class</p>
            <TabList className="flex flex-col gap-y-1.5">
              {getConnectAccountTabs().map((item, index) => (
                <Tab
                  data-cy={item.cy}
                  key={index}
                  className="flex items-center gap-x-2 py-2.5 md:py-2 px-12 md:px-6 rounded-md cursor-pointer text-sm hover:bg-blue-100"
                  selectedClassName="bg-blue-100"
                >
                  <item.icon />
                  <p className="whitespace-nowrap">{item.label}</p>
                </Tab>
              ))}
            </TabList>
          </div>

          {/* Panels */}
          <div id="tabPanelEl" className={classNames("w-full", hasSelectedClass ? "flex" : "hidden md:flex")}>
            {getConnectAccountTabs().map((item, index) => {
              if (index === indexVal) {
                const ComponentToRender = item.component;
                return (
                  <ComponentToRender
                    key={index}
                    submit={addAccount}
                    onClickBack={() => {
                      setHasSelectedClass(false);
                      setIndex(0);
                    }}
                  />
                );
              }
              return null;
            })}
          </div>
        </Tabs>
      </div>

      {/* Footer */}
      <div className="modal-footer p-4 gap-4 flex justify-end items-center border-t bg-white">
        <MainButton size="small" type="secondary" click={() => setActiveView(ViewCategories.categories)}>
          Go back
        </MainButton>
        <MainButton size="small" type="primary" id="cryptoFormBtn">
          Submit
        </MainButton>
      </div>
    </div>
  );
};

export default Manual;
