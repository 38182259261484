import { PaymentMethod } from "../../../../types/subscriptionTypes";
import { StripePaymentMethod } from "./StripePaymentMethod";
import { useUserQuery } from "../../../../api/user/user-queries";
import { useRemovePaymentMethodMutation } from "../../../../api/subscription/subscription-mutations";

export const StripePaymentMethods = ({
  setAddPaymentMethodModal,
  setAddPaymentMethodActionType,
}: {
  setAddPaymentMethodModal: (value: boolean) => void;
  setAddPaymentMethodActionType: (value: "add-card" | "make-payment" | "") => void;
}) => {
  const { mutateAsync: removePaymentMethod } = useRemovePaymentMethodMutation();
  const deletePaymentMethod = async (removePaymentMethodId: string) => {
    try {
      await removePaymentMethod(removePaymentMethodId);
    } catch (error) {
      console.error("fail: deletePaymentMethod", error);
    }
  };

  const { data: user } = useUserQuery();
  const userDefaultPaymentMethod = user?.payment_methods?.find((p: PaymentMethod) => p.is_default);

  return (
    <div className="w-full">
      <p className="text-xl text-center md:text-left mb-5 md:mb-2">Payment Methods</p>
      <div className="flex flex-col gap-y-4 mb-4">
        {userDefaultPaymentMethod && (
          <StripePaymentMethod
            paymentMethod={userDefaultPaymentMethod}
            deletePaymentMethod={deletePaymentMethod}
            active
          />
        )}

        {user?.payment_methods
          ?.filter((paymentMethod: PaymentMethod) =>
            userDefaultPaymentMethod ? paymentMethod.id !== userDefaultPaymentMethod.id : true
          )
          .map((paymentMethod: PaymentMethod) => (
            <StripePaymentMethod
              paymentMethod={paymentMethod}
              key={paymentMethod.id}
              deletePaymentMethod={deletePaymentMethod}
            />
          ))}

        <div className="mt-2 flex justify-center w-full md:max-w-md">
          <button
            type="button"
            className="text-primary font-semibold underline hover:text-gray-700"
            onClick={() => {
              setAddPaymentMethodModal(true);
              setAddPaymentMethodActionType("add-card");
            }}
          >
            + Add new payment method
          </button>
        </div>
      </div>
    </div>
  );
};
