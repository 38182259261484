import AxoisApi from "../../api.ts";
import { ALLAPIS } from "../../api.ts/apilog";

export class VaultService {
  setVaultpin(data: any) {
    return AxoisApi.post(`${ALLAPIS.VAULT.SET_VAULT_PIN}`, data).then((res) => {
      return res.data;
    });
  }

  changeVaultpin(data: any) {
    return AxoisApi.post(`${ALLAPIS.VAULT.CHANGE_VAULT_PIN}`, data).then((res) => {
      return res.data;
    });
  }

  getAllVaultWithPin(data: any) {
    return AxoisApi.post(`${ALLAPIS.VAULT.FETCH_ALL_VAULT}`, data).then((res) => {
      return res.data;
    });
  }
  getAllVault() {
    return AxoisApi.get(`${ALLAPIS.VAULT.VAULT_FOLDER}`).then((res) => {
      return res.data;
    });
  }

  sendVaultOTP() {
    return AxoisApi.get(`${ALLAPIS.VAULT.VAULT_OTP}`).then((res) => {
      return res.data;
    });
  }

  verifyVaultOTP(otp: string) {
    return AxoisApi.get(`${ALLAPIS.VAULT.VAULT_OTP}/verify?token=${otp}`).then((res) => {
      return res.data;
    });
  }
}
