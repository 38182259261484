import { useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
//@ts-expect-error
import MonoConnect from "@mono.co/connect.js";
import { Partners } from "../types/accountTypes";
import { Capacitor } from "@capacitor/core";

const usePlaidMonoV2 = (country: string, callback: (partner: Partners, data: string) => void) => {
  const [config, setConfig] = useState<any>({
    token: null,
    onSuccess: (public_token: any, _metadata: any) => {
      callback(Partners.PLAID, public_token);
    },
    onLoad: () => {},
    onExit: (_err: any, _metadata: any) => {
      setConfig({ ...config, token: null });
    },
    onEvent: (_eventName: any, _metadata: any) => {},
  });

  const { open, ready } = usePlaidLink(config);
  const isNative = Capacitor.isNativePlatform();

  useEffect(() => {
    if (ready) {
      open();
    }

    if (open && isNative) {
      const body = document.getElementsByTagName("body")[0];
      body.style.setProperty("padding-top", "60px", "important");
    } else {
      const body = document.getElementsByTagName("body")[0];
      body.style.removeProperty("padding-top");
    }

    // Cleanup function to reset padding when component unmounts
    return () => {
      const body = document.getElementsByTagName("body")[0];
      body.style.removeProperty("padding-top");
    };
  }, [ready, open, isNative]);

  const getPlaidorMono = (token: string) => {
    if (country === "Nigeria") {
      getMono();
    } else {
      setConfig({ ...config, token });
    }
  };

  const getMono = () => {
    const getMonoInstance = monoConnect("");
    getMonoInstance.open();
  };

  const monoConnect = (reauth_token: string) => {
    const monoInstance = new MonoConnect({
      onClose: () => {},
      onLoad: () => {},
      onSuccess: ({ code }: any) => {
        callback(Partners.MONO, code);
      },
      key: process.env.REACT_APP_MONO_KEY,
    });

    if (reauth_token) {
      monoInstance.reauthorise(reauth_token);
    } else {
      monoInstance.setup();
    }

    return monoInstance;
  };

  return {
    getPlaidorMono,
  };
};

export default usePlaidMonoV2;
