import React from "react";
import dayjs from "dayjs";
import classNames from "classnames";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { SubscriptionStatus } from "../../../types/subscriptionTypes";

type BillingAlertProps = {
  subscription: any;
};

const BillingAlert: React.FC<BillingAlertProps> = ({ subscription }) => {
  if (!subscription) {
    return null;
  }

  const renderAlert = () => {
    if (subscription && subscription.status === SubscriptionStatus.ACTIVE && subscription.cancel_at) {
      return (
        <div
          className={classNames(
            "bg-red-100",
            "flex justify-center items-center gap-x-2",
            "text-xs mb-6 p-3 rounded-md text-center"
          )}
        >
          <InformationCircleIcon className="w-6 h-6 text-red-500" />
          You have cancelled your subscription. You will be downgraded to our Free Plan from
          <span className="font-semibold">{dayjs(subscription.current_period_end).format("MMM DD, YYYY")}</span>
        </div>
      );
    }

    if (subscription.status === SubscriptionStatus.PAYMENT_PENDING) {
      return (
        <div
          className={classNames(
            "bg-blue-50",
            "flex justify-center items-center gap-x-2",
            "text-xs mb-6 p-3 rounded-md text-center"
          )}
        >
          <InformationCircleIcon className="w-6 h-6 text-yellow-500" />
          Issue with your payment method
        </div>
      );
    }

    return null;
  };

  return renderAlert();
};

export default BillingAlert;
