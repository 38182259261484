import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { SubscriptionStatus } from "../../types/subscriptionTypes";

dayjs.extend(utc);

export const subStringofName = (name: string, quan: number) => {
  let final = "";
  if (name) {
    if (name.length < quan) {
      return name;
    }
    final = name.substring(0, quan - 3) + "...";
  }

  return final;
};

export let convertStampDate = (unixtimestamp: number) => {
  // Months array
  var months_arr = ["Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

  // Convert timestamp to milliseconds
  var date = new Date(unixtimestamp);

  // Year
  var year = date.getFullYear();

  // Month
  var month = months_arr[date.getMonth()];

  // Day
  var day = date.getDate();

  // filtered fate
  var convdataTime = month + " " + day + ", " + year;
  return convdataTime;
};

export let convertStampDate22 = (unixtimestamp: number) => {
  // Months array
  // var months_arr = ['January','February','March','April','May','June','July','August','September','October','November','December'];
  var months_arr = ["Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

  // Convert timestamp to milliseconds
  var date = new Date(unixtimestamp);

  // Year
  var year = date.getFullYear();

  // Month

  var month = months_arr[date.getMonth() === 11 ? 0 : date.getMonth() + 1];

  // Day
  var day = date.getDate();

  // filtered fate
  var convdataTime = month + " " + day + ", " + year;
  return convdataTime;
};

export let convertStampDatev2 = (unixtimestamp: string) => {
  // Unixtimestamp

  // Months array
  // var months_arr = ['January','February','March','April','May','June','July','August','September','October','November','December'];
  var months_arr = ["Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

  // Convert timestamp to milliseconds
  var date = new Date(unixtimestamp);

  // Year
  var year = date.getFullYear();

  // Month
  var month = months_arr[date.getMonth()];

  // Day
  var day = date.getDate();

  // Hours
  // var hours = date.getHours();

  // Minutes
  // var minutes = "0" + date.getMinutes();

  // Seconds
  // var seconds = "0" + date.getSeconds();

  // Display date time in MM-dd-yyyy h:m:s format
  // var fulldate = month + " " + day + "-" + year + " " + hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);

  // filtered fate
  var convdataTime = month + " " + day + "," + year;
  if (month && day && year) return convdataTime;
  return "";
};

export let convertBytestoMB = (bytes: number, decimals = 1) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getFileNameWithoutType = (str: string) => {
  let arrayRes = str.split(".");
  return arrayRes.slice(0, arrayRes.length - 1).join("");
};

export const lessThanTimeAgo = (
  date: string,
  timeAgo: { duration: number; unit: "minutes" | "seconds" | "hours" | "days" }
) => {
  const setUtcStamp = dayjs.utc(date);
  const convertToLocal = setUtcStamp.local();

  return convertToLocal.isAfter(dayjs().subtract(timeAgo.duration, timeAgo.unit));
};

export const hasActiveSubscription = (subscription: any) => {
  return !!(subscription && subscription.status === SubscriptionStatus.ACTIVE);
};

export const getNumberBetween = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
