import { GET_MAIN_VAULT, GET_VAULT_FOLDERS, UPDATE_MAIN_VAULT, UPDATE_FOLDERS } from "../../actions/vaultaction";

const initialState = {
  mainVault: {},
  otherFolders: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
  switch (action.type) {
    case GET_MAIN_VAULT:
      return {
        ...state,
        mainVault: { ...action.payload },
      };
    case UPDATE_MAIN_VAULT:
      return {
        ...state,
        mainVault: { ...action.payload },
      };
    case GET_VAULT_FOLDERS:
      return {
        ...state,
        otherFolders: [...action.payload],
      };
    case UPDATE_FOLDERS:
      return {
        ...state,
        otherFolders: [...action.payload],
      };
    default:
      return state;
  }
}
