import { useCallback, useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Textinput } from "../../inputs/textinput";
import { NumberFormatter } from "../../inputs/numberformatter";
import { useAssetClassSubmitBtn } from "../../../hooks/useAssetClassSubmitBtn";
import { useSelector } from "react-redux";
import { AccountTypes, DepositoryAccountSubtype, InvestmentAccountSubtype } from "../../../types/accountTypes";
import { UtilityClass } from "../../../services/utilty";
import { UserState } from "../../../types/authTypes";
import { PanelActions } from "./PanelActions";
import { Props } from "./manual-types";

export const AddRealEstate = ({ isEdit, account, submit, onClickBack }: Props) => {
  const assetsCurrency = useSelector((state: any) => state.assets.currency);
  const currencies = useSelector((state: { user: UserState }) => state.user.config.currencies);
  const [currency, setCurrency] = useState<string>("");

  useEffect(() => {
    if (account) {
      setCurrencySymbol(account?.balance?.symbol);
    } else {
      setCurrencySymbol(assetsCurrency.code ? assetsCurrency.code : "USD");
    }
    // eslint-disable-next-line
  }, []);

  const { handleSubmit, errors, control } = useForm({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: {
      ...account,
      postal_code: account?.address.postal_code,
      street: account?.address.street,
      balance: account?.balance?.value,
      balance_original: account?.balance_original?.value,
      currency: account?.balance?.currency,
    },
  });

  const cb = useCallback(() => {
    handleSubmit((payload) => {
      submit({
        currency: payload.currency,
        number: "",
        name: `${payload.street}, ${payload.postal_code} - ${payload.name}`,
        institution_id: "Real Estate",
        type: AccountTypes.Investment,
        balance: new UtilityClass().formatNumber(payload.balance),
        balance_original: new UtilityClass().formatNumber(payload.balance_original),
        subtype: InvestmentAccountSubtype.RealEstate,
        address: {
          postal_code: payload.postal_code,
          street: payload.street,
          country: payload.currency,
        },
      });
    })();
    // eslint-disable-next-line
  }, [handleSubmit]);

  useAssetClassSubmitBtn(cb);

  const panelRef = useRef(null);

  const setCurrencySymbol = (currency: string) => {
    const selectedCurrency = currencies.find((item: { code: string; symbol: string }) => currency === item.code);

    if (selectedCurrency) {
      setCurrency(selectedCurrency?.symbol);
    } else {
      setCurrency("");
    }
  };

  return (
    <div ref={panelRef} className="w-full max-h-[500px] overflow-y-auto">
      <div className={`mb-4 ${!isEdit ? "block" : "hidden"}`}>
        <PanelActions onClickBack={onClickBack} />
        <div className="mt-2">
          <h4 className="text-sm font-semibold">Real Estate</h4>
        </div>
      </div>
      <form
        className="grid grid-cols-1 gap-4 pb-10 md:pb-0"
        onKeyUp={(e) => {
          if (e.code === "Enter") {
            handleSubmit((payload) => {
              submit({
                ...payload,
                institution_id: "Other",
                type: AccountTypes.Other,
                balance: new UtilityClass().formatNumber(payload.balance),
                balance_original: new UtilityClass().formatNumber(payload.balance_original),
                subtype: DepositoryAccountSubtype.CashManagement,
              });
            })();
          }
        }}
        onSubmit={(e) => e.preventDefault()}
      >
        <Controller
          name="name"
          defaultValue={null}
          rules={{ required: true }}
          control={control}
          render={(props) => (
            <Textinput
              onChange={(e: any) => {
                props.onChange(e.target.value);
              }}
              value={props.value}
              label="Property Name"
              inputid="name"
              type="text"
              placeholder=""
              iserror={errors.name}
              message={"Please fill this field."}
              autocomplete="off"
            />
          )}
        />
        <Controller
          name="street"
          defaultValue={null}
          rules={{ required: true }}
          control={control}
          render={(props) => (
            <Textinput
              onChange={(e: any) => {
                props.onChange(e.target.value);
              }}
              value={props.value}
              label="Property Address"
              inputid="name"
              type="text"
              placeholder=""
              iserror={errors.street}
              message={"Please fill this field."}
              autocomplete="off"
            />
          )}
        />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4 md:gap-y-0">
          <Controller
            name="balance"
            defaultValue={null}
            rules={{ required: true }}
            control={control}
            render={(props) => (
              <NumberFormatter
                onChange={(e: any) => {
                  props.onChange(e.target.value);
                }}
                value={props.value || ""}
                label="Current Value"
                prefix={`${currency} `}
                placeholder={`${currency} 1000`}
                iserror={errors.balance}
                message={"Please fill this field"}
              />
            )}
          />
          <Controller
            name="postal_code"
            defaultValue={null}
            rules={{ required: true }}
            control={control}
            render={(props) => (
              <Textinput
                onChange={(e: any) => {
                  props.onChange(e.target.value);
                }}
                value={props.value}
                label="Zip Code"
                inputid="name"
                type="text"
                placeholder=""
                iserror={errors.postal_code}
                message={"Please fill this field."}
                autocomplete="off"
              />
            )}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4 md:gap-y-0">
          <Controller
            name="balance_original"
            defaultValue={null}
            rules={{ required: true }}
            control={control}
            render={(props) => (
              <NumberFormatter
                onChange={(e: any) => {
                  props.onChange(e.target.value);
                }}
                value={props.value || ""}
                label="Purchase Price"
                prefix={`${currency} `}
                placeholder={`${currency} 1000`}
                iserror={errors.balance_original}
                message={"Please fill this field"}
              />
            )}
          />
          <Controller
            name="currency"
            defaultValue={assetsCurrency.code ? assetsCurrency?.code : "USD"}
            rules={{ required: true }}
            control={control}
            render={(props) => (
              <div className="forminput">
                <label className="text-p2 font-medium text-type mb-1">Currency</label>
                <select
                  onChange={(e: any) => {
                    props.onChange(e.target.value);

                    setCurrencySymbol(e.target.value);
                  }}
                  value={props.value}
                  className="bg-neutral-300 border-neutral-200 rounded placeholder-type-200 text-type text-p2 font-normal px-2 py-3 transition focus:border-type focus:ring-0"
                  name="currency"
                  id="currency"
                >
                  {currencies.map(
                    (
                      currency: {
                        code: string;
                        name: string;
                      },
                      index: number
                    ) => (
                      <option key={index} value={currency.code}>
                        {currency.name}
                      </option>
                    )
                  )}
                </select>
              </div>
            )}
          />
        </div>
      </form>
    </div>
  );
};

export default AddRealEstate;
