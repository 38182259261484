export enum SortType {
  NAME = "NAME",
  VALUE = "VALUE",
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum UserSortKey {
  INSTITUTIONS = "institutions_sort",
  CLASSES = "classes_sort",
  LABELS = "labels_sort",
}

export type SortRule = {
  type: SortType;
  order: SortOrder;
};

export type UserSortRule = {
  group: SortRule;
  account: SortRule;
};
