import { GoogleIcon } from "../icons/googleicon";

const GoogleAuthBtn = ({ onClick, label = "Sign In with Google" }: { onClick: () => void; label?: string }) => {
  return (
    <button
      onClick={onClick}
      className="w-full justify-center flex items-center h-11 rounded border bg-neutral-300 border-neutral-200"
    >
      <GoogleIcon /> <span className="text-p1 font-semibold ml-1.5 text-type">{label}</span>
    </button>
  );
};

export default GoogleAuthBtn;
