import {
  SET_INHERITED_PORTFOLIO_DATA,
  SET_BENEFICIARY_DATA,
  RESET_LIFECHECK_DATA,
} from "../../actions/lifecheckaction/types";

const initialState = {
  beneficiaryReminderCount: 0,
  daysToRemember: 0,
  trustedAngelReminderCount: 0,
  recipients: [],
  inheritedPortfolios: [],
  activePortfolio: null,
  trustedAngel: {
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  },
  beneficiaries: {
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  },
  recipientFetched: false,
  inheritedFetched: false,
};

export default function LifecheckReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_BENEFICIARY_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case SET_INHERITED_PORTFOLIO_DATA:
      return {
        ...state,
        inheritedPortfolios: [...action.payload],
      };

    case RESET_LIFECHECK_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
