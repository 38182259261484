import Skeleton from "react-loading-skeleton";
import { getNumberBetween } from "../../components/utilities/utilityFunctions";

const DashboardTopPerformingCardSkeleton = () => (
  <div className="flex flex-col gap-y-6">
    {[...Array(2)].map((_, index) => (
      <div key={index}>
        <Skeleton width="100%" height={30} className="mb-1" />
        <Skeleton width={getNumberBetween(140, 200)} height={16} />
      </div>
    ))}
  </div>
);

export default DashboardTopPerformingCardSkeleton;
