import { Currency } from "./accountTypes";

export type SetupIntent = {
  client_secret: string;
  id: string;
  payment_method_options: {
    card: Card;
  };
  payment_method_types: string[];
  status: string;
  usage: string;
};

interface Card {
  mandate_options: any;
  network: any;
  request_three_d_secure: string;
}

export type PaymentMethod = {
  is_default: boolean;
  card_brand: string;
  card_country: string;
  card_exp_month: number;
  card_exp_year: number;
  card_last4: string;
  created_at: string;
  id: string;
  partner: string;
  type: string;
};

export type Subscription = {
  cancel_at: Date | null;
  created_at: Date;
  current_period_end: Date;
  current_period_start: Date;
  customer_id: string;
  ended_at: Date;
  id: string;
  partner: string;
  partner_ref: string;
  partner_status: string;
  payment_method: PaymentMethod | null;
  plan: Plan;
  quantity: number;
  started_at: string;
  status: SubscriptionStatus;
};

export type Plan = {
  active: boolean;
  amount: number;
  code: string;
  currency: Currency;
  id: string;
  interval: string;
  partner: string;
};

export enum PaymentPartners {
  STRIPE = "STRIPE",
  APPLE = "APPLE",
}

export enum PlanIntervals {
  DAY = "day",
  MONTH = "month",
  YEAR = "year",
}

export type updateSubscriptionInfo = {
  id: string;
  cancelAtPeriodEnd?: boolean;
  planId?: string;
};

export enum SubscriptionStatus {
  PAYMENT_PENDING = "payment_pending",
  ACTIVE = "active",
  CANCELED = "canceled",
  PAUSED = "paused",
}

export enum CardBrands {
  visa = "visa",
  mastercard = "mastercard",
  amex = "amex",
  jcb = "jcb",
  unionpay = "unionpay",
  discover = "discover",
}

export type Invoice = {
  amount_due: number;
  amount_paid: number;
  attempt_count: number;
  attempted: boolean;
  created_at: string;
  currency: string;
  description: string;
  id: string;
  invoice_pdf_url: string;
  invoice_url: string;
  next_payment_attempt: string;
  paid: boolean;
  partner: string;
  partner_ref: string;
  partner_status: string;
  subscription_id: string;
  user_id: number;
};

export enum PartnerStatusTypes {
  paid = "paid",
  open = "open",
  void = "void",
}
