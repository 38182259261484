import { ReactComponent as BankIcon2 } from "../../../assets/svg/bank.svg";
import { ReactComponent as CryptoIcon } from "../../../assets/svg/bitcoin.svg";
import { ReactComponent as RealEstateIcon2 } from "../../../assets/svg/real-estate.svg";
import { ReactComponent as StocksIcon } from "../../../assets/svg/stocks.svg";
import { ReactComponent as StartupIcon } from "../../../assets/svg/startup.svg";
import { ReactComponent as DiamondIcon } from "../../../assets/svg/asset.svg";
import AddBankAccount from "./PanelManualAddBankAccount";
import AddCreditCard from "./PanelManualAddCreditCard";
import AddLoan from "./PanelManualAddLoan";
import AddCrypto from "./PanelManualAddCrypto";
import AddStock from "./PanelManualAddStock";
import AddStartup from "./PanelManualAddStartup";
import AddRealEstate from "./PanelManualAddRealEstate";
import AddOther from "./PanelManualAddOther";

export const getConnectAccountTabs = () => [
  { cy: "banksAndCash", icon: BankIcon2, label: "Bank Account", component: AddBankAccount },
  { cy: "creditCard", icon: BankIcon2, label: "Credit Card", component: AddCreditCard },
  { cy: "loan", icon: BankIcon2, label: "Loan", component: AddLoan },
  { cy: "Cryptocurrency", icon: CryptoIcon, label: "Cryptocurrency", component: AddCrypto },
  { cy: "Stocks", icon: StocksIcon, label: "Brokerage", component: AddStock },
  { cy: "StartupInvestments", icon: StartupIcon, label: "Startup Investment", component: AddStartup },
  { cy: "RealEstate", icon: RealEstateIcon2, label: "Real Estate", component: AddRealEstate },
  { cy: "CustomAssets", icon: DiamondIcon, label: "Other", component: AddOther },
];
