import { useLazyQuery, useMutation } from "@apollo/client";
import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Avatar from "../../assets/images/profileimageblue.png";
import { ReactComponent as ArrowRight } from "../../assets/svg/arrow-right.svg";
import { ReactComponent as ManWithWoman } from "../../assets/svg/man-discuss-with-woman.svg";
import { ReactComponent as SharePortfolioIcon } from "../../assets/svg/share-portfolio.svg";
import { ReactComponent as ShareMobile } from "../../assets/svg/share-white.svg";
import { ReactComponent as Share } from "../../assets/svg/share.svg";
import MainButton from "../../components/buttons/mainButton";
import { CollaborationModal } from "../../components/modals/collaborations/collaborations";
import { ConfirmationModal } from "../../components/modals/ConfirmationModal";
import {
  DECLINE_PORTFOLIO_INVITE,
  LEAVE_PORTFOLIO_SHARED_WITH_ME,
  PORTFOLIOS_SHARED_WITH_ME,
  SHARE_PORTFOLIO,
  UNSHARE_PORTFOLIO,
  UPDATE_PORTFOLIO_SHARED,
} from "../../graphql/portfolio";
import { useSwitchActivePortfolio } from "../../graphql/portfolio.gql-hook";
import usePortfolio from "../../hooks/usePortfolio";
import { SET_PORTFOLIO_SHARED_WITH_ME } from "../../store/actions/portfoliosaction/types";
import { CollaboratorType } from "../../types/userTypes";
import "./collaboration.scss";
import ActionStateToast from "../../components/ActionStateToast";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const Collaboration = () => {
  const dispatch = useDispatch();
  const currency = useSelector((state: any) => state.assets.currency.country);
  const { activePortfolioId, collaborators, portfolioSharedWithMe } = useSelector((state: any) => state.portfolios);
  const [portfolioModalView, setPortfolioModalView] = useState<string>("");
  const [emails, setEmails] = useState<string[]>([]);
  const [labelIds, setLabelIds] = useState<string[]>([]);
  const [selectedCollaborator, setSelectedCollaborator] = useState<any>({});
  const [reloadTrigger, setReloadTrigger] = useState<number>(0);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const { handleGetPortfolioData } = usePortfolio();
  const [collaborationView, setCollaborationView] = useState<string>("Collaborators");
  const showSharePortfolio = () => setPortfolioModalView("share-portfolio");
  const [portfolioActionToast, setPortfolioActionToast] = useState<any>(null);

  const [sharePortfolio] = useMutation(SHARE_PORTFOLIO, {
    onCompleted(data: any) {
      toast.dismiss(portfolioActionToast);
      toast.success("Successfully sent an invite to collaborator");
      setPortfolioModalView("");
      setLabelIds([]);
      setEmails([]);
      handleGetPortfolioData(activePortfolioId, false);
    },
    onError(error: any) {
      toast.dismiss(portfolioActionToast);
      toast.error(error.message);
    },
  });

  const handleSharePortfolio = () => {
    if (labelIds.length < 1) {
      toast.error("Select at least one label");
      return;
    }
    setPortfolioActionToast(toast(<ActionStateToast message="Sharing portfolio..." />));
    sharePortfolio({
      variables: {
        portfolioShareInput: {
          emails,
          label_ids: labelIds,
          portfolio_id: activePortfolioId,
        },
      },
    });
  };

  const [fetchportfolioSharedWithMe] = useLazyQuery(PORTFOLIOS_SHARED_WITH_ME, {
    onCompleted(data: any) {
      toast.dismiss(portfolioActionToast);
      dispatch({
        payload: data.currentUser.portfolioSharedWithMe,
        type: SET_PORTFOLIO_SHARED_WITH_ME,
      });
      setLabelIds([]);
    },
    onError(error: any) {
      toast.dismiss(portfolioActionToast);
      toast.error(error.message);
    },
  });

  const [leavePortfolio] = useMutation(LEAVE_PORTFOLIO_SHARED_WITH_ME, {
    onCompleted(data: any) {
      toast.dismiss(portfolioActionToast);
      toast.success("Portfolio collaboration deleted successfully");
      fetchportfolioSharedWithMe();
    },
    onError(error: any) {
      toast.dismiss(portfolioActionToast);
      toast.error(error.message);
    },
  });

  const [removeCollaborator] = useMutation(UNSHARE_PORTFOLIO, {
    onCompleted(data: any) {
      toast.dismiss(portfolioActionToast);
      toast.success("Successfully removed collaborator");
      handleGetPortfolioData(activePortfolioId, false);
      setLabelIds([]);
    },
    onError(error: any) {
      toast.dismiss(portfolioActionToast);
      toast.error(error.message);
    },
  });

  const [updatePortfolioShared] = useMutation(UPDATE_PORTFOLIO_SHARED, {
    onCompleted(data: any) {
      toast.dismiss(portfolioActionToast);
      toast.success("Successfully updated collaborator access");
      handleGetPortfolioData(activePortfolioId, false);
      setLabelIds([]);
    },
    onError(error: any) {
      toast.dismiss(portfolioActionToast);
      toast.error(error.message);
    },
  });

  const [declinePortofolioInvite] = useMutation(DECLINE_PORTFOLIO_INVITE, {
    onCompleted(data: any) {
      toast.dismiss(portfolioActionToast);
      toast.success("Portfolio invite declined");
      fetchportfolioSharedWithMe();
    },
    onError(error: any) {
      toast.dismiss(portfolioActionToast);
      toast.error(error.message);
    },
  });

  const handlePortfolioSharedWithMe = () => {
    fetchportfolioSharedWithMe();
  };

  const fetchAllData = async () => handlePortfolioSharedWithMe();

  useEffect(() => {
    if (activePortfolioId) {
      fetchAllData();
      setTimeout(() => {
        setFirstRender(false);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePortfolioId]);

  useEffect(() => {
    if (reloadTrigger !== 0 && activePortfolioId) {
      handleGetPortfolioData(activePortfolioId, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadTrigger]);

  useEffect(() => {
    if (!firstRender) {
      setReloadTrigger(Math.random());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);

  return (
    <div className="p-4 md:p-8 collaboration">
      <div style={{ maxWidth: "1440px", margin: "0px auto" }}>
        <Helmet>
          <title>Portfolio Collaborations</title>
          <meta name="description" content="View all your portfolio by Institutions" />
          <meta property="og:title" content="Collaborations" />
          <meta property="og:description" content="View all your portfolio collaborations" />
          <meta property="og:url" content="https://app.getcova.com/collaboration" />
          <meta name="twitter:card" content="View all your portfolio collaborations" />
        </Helmet>
        <div>
          {portfolioModalView !== "" && (
            <CollaborationModal
              portfolioModalView={portfolioModalView}
              modalIsOpen={portfolioModalView !== ""}
              setPortfolioModalView={setPortfolioModalView}
              setEmails={setEmails}
              emails={emails}
              labelIds={labelIds}
              setLabelIds={setLabelIds}
              handleSharePortfolio={handleSharePortfolio}
              selectedCollaborator={selectedCollaborator}
              removeCollaborator={removeCollaborator}
              leavePortfolio={leavePortfolio}
              declinePortofolioInvite={declinePortofolioInvite}
              updatePortfolioShared={updatePortfolioShared}
            />
          )}

          {collaborators.length < 1 && portfolioSharedWithMe.portfolios.length < 1 && <CollaborationDescription />}
          {(collaborators.length > 0 || portfolioSharedWithMe.portfolios.length > 0) && (
            <CollaborationHeader
              showSharePortfolio={showSharePortfolio}
              collaborationView={collaborationView}
              setCollaborationView={setCollaborationView}
            />
          )}
          {collaborationView === "Collaborators" ? (
            <CollaboratorsTable
              showSharePortfolio={showSharePortfolio}
              setPortfolioModalView={setPortfolioModalView}
              setSelectedCollaborator={setSelectedCollaborator}
              selectedCollaborator={selectedCollaborator}
              removeCollaborator={removeCollaborator}
              setPortfolioActionToast={setPortfolioActionToast}
            />
          ) : (
            <SharedWithMeTable
              showSharePortfolio={showSharePortfolio}
              setPortfolioModalView={setPortfolioModalView}
              selectedCollaborator={selectedCollaborator}
              setSelectedCollaborator={setSelectedCollaborator}
              declinePortofolioInvite={declinePortofolioInvite}
              leavePortfolio={leavePortfolio}
              setPortfolioActionToast={setPortfolioActionToast}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Collaboration;

const CollaborationDescription = () => {
  return (
    <div className="bg-primary-alice description">
      <div className="desc-text">
        <h2 className="text-h2 text-type font-semibold mb-2">Collaboration</h2>
        <p className="text-p2 text-type">
          Portfolio collaborations allows you include your family, legal advisors or other trusted people to participate
          together in your asset tracking process.{" "}
          <span>
            This feature helps you share your portfolio, control access rights, remove collaborators and see the
            portfolios that have been shared with you by other people.
          </span>
        </p>
      </div>
      <ManWithWoman />
    </div>
  );
};

interface ICollaborationHeader {
  showSharePortfolio: () => void;
  collaborationView: string;
  setCollaborationView: React.Dispatch<React.SetStateAction<string>>;
}

const CollaborationHeader = ({ showSharePortfolio, collaborationView, setCollaborationView }: ICollaborationHeader) => {
  const collaborationStates: string[] = ["Collaborators", "Shared with you"];

  return (
    <div className="collaborationheader flex justify-between items-start mb-4">
      <div>
        <h2 className="font-semibold text-xl md:text-2xl mb-2">Collaboration</h2>
        <p className="mb-6 md:mb-8">See who you have shared your portfolio with and who has shared theirs with you.</p>
        <div className="collabview">
          {collaborationStates.map((state: string, i: number) => (
            <button
              key={i}
              className={`text-p2 font-medium ${collaborationView === state ? "active-btn" : "text-type-100"}`}
              onClick={() => setCollaborationView(state)}
            >
              {state}
            </button>
          ))}
        </div>
      </div>
      <MainButton type="secondary" size="small" click={showSharePortfolio} extraClasses="flex items-center">
        <span className="mr-2">
          <SharePortfolioIcon />
        </span>
        Share Portfolio
      </MainButton>
    </div>
  );
};

interface IshowSharePortfolio {
  showSharePortfolio: () => void;
}

const SharePortfolio = ({ showSharePortfolio }: IshowSharePortfolio) => {
  return (
    <div className="shareportfolio">
      <Share />
      <h3 className="font-semibold text-h3 mt-2 text-type">Share your Portfolio</h3>
      <p className="mt-4 mb-6 text-type-100">
        Start by sharing a part or the whole of your accounts. Know that this is a sensitive process and only you can
        grant access to others to view or edit your portfolio.
      </p>
      <MainButton type="primary" size="small" click={showSharePortfolio}>
        Share Portfolio
      </MainButton>
    </div>
  );
};

interface ICollaboratorsTable {
  showSharePortfolio: () => void;
  setPortfolioModalView: React.Dispatch<React.SetStateAction<string>>;
  selectedCollaborator: CollaboratorType;
  setSelectedCollaborator: React.Dispatch<React.SetStateAction<CollaboratorType>>;
  removeCollaborator: (args: { variables: {} }) => void;
  setPortfolioActionToast: (toast: any) => void;
}

const CollaboratorsTable = ({
  showSharePortfolio,
  setPortfolioModalView,
  selectedCollaborator,
  setSelectedCollaborator,
  removeCollaborator,
  setPortfolioActionToast,
}: ICollaboratorsTable) => {
  const { activePortfolioId, collaborators } = useSelector((state: any) => state.portfolios);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const handleRemovePortfolio = () => {
    setPortfolioActionToast(toast(<ActionStateToast message="Removing collaborator..." />));
    setDeleteModal(false);
    removeCollaborator({
      variables: {
        portfolioUnShareInput: {
          emails: [selectedCollaborator.email],
          portfolio_id: activePortfolioId,
        },
      },
    });
  };

  const handleDeleteModal = (data: CollaboratorType) => {
    setSelectedCollaborator(data);
    setDeleteModal(true);
  };

  const handleEditCollaborator = (data: CollaboratorType) => {
    setSelectedCollaborator(data);
    setPortfolioModalView("edit-portfolio");
  };

  return (
    <Fragment>
      <CollaboratorMobile
        setPortfolioModalView={setPortfolioModalView}
        setSelectedCollaborator={setSelectedCollaborator}
      />
      {collaborators.length > 0 ? (
        <>
          <div className="collaborators mt-8">
            <div className="table-data font-medium text-type">
              <p>COLLABORATOR</p>
              <p>DATE SHARED</p>
              <p className="text-center">STATUS</p>
              <p className="text-center">ACCESS</p>
              <p>PORTFOLIO</p>
            </div>
            {deleteModal && (
              <ConfirmationModal
                modalIsOpen={deleteModal}
                closeModal={() => {
                  setDeleteModal(false);
                }}
                submitAction={handleRemovePortfolio}
                confirmationText={
                  <span>
                    Are you sure you want to remove{" "}
                    <span className="font-semibold">
                      {selectedCollaborator.user
                        ? selectedCollaborator.user.first_name + " " + selectedCollaborator.user.last_name
                        : selectedCollaborator.email}
                    </span>{" "}
                    from this portfolio?
                  </span>
                }
              />
            )}
            {collaborators.map((person: CollaboratorType, i: number) => (
              <div key={i} className="table-data body">
                <div className="flex items-center">
                  <img
                    src={person.user && person.user.image_url ? person.user.image_url : Avatar}
                    alt=""
                    className="rounded-full"
                    width={32}
                    height={32}
                    style={{
                      minHeight: "32px",
                      minWidth: "32px",
                      maxHeight: "32px",
                      maxWidth: "32px",
                    }}
                  />
                  <p className="ml-2">
                    {person.user ? person.user.first_name + " " + person.user.last_name : person.email}
                  </p>
                </div>
                <p className="text-type">{dayjs(person.created_at).format("D/MM/YYYY")}</p>
                <div>
                  <p className={`text-center status mx-auto text-p2 capitalize ${person.status.toLocaleLowerCase()}`}>
                    {person.status.toLocaleLowerCase()}
                  </p>
                </div>
                <p className="text-center text-type">Can {person.access_level.toLocaleLowerCase()}</p>
                <div className="flex items-center justify-stretch">
                  <MainButton
                    type="primary"
                    size="small"
                    click={() => handleEditCollaborator(person)}
                    extraClasses="w-full"
                  >
                    Edit
                  </MainButton>
                  <MainButton
                    type="secondary"
                    size="small"
                    extraClasses="ml-4 w-full"
                    click={() => handleDeleteModal(person)}
                  >
                    Remove
                  </MainButton>
                </div>
              </div>
            ))}
          </div>
          <button className="mobile-share-btn" onClick={showSharePortfolio}>
            <ShareMobile />
          </button>
        </>
      ) : (
        <SharePortfolio showSharePortfolio={showSharePortfolio} />
      )}
    </Fragment>
  );
};

interface ISharedWithMeTable {
  showSharePortfolio: () => void;
  declinePortofolioInvite: (args: { variables: {} }) => void;
  selectedCollaborator: CollaboratorType;
  setSelectedCollaborator: React.Dispatch<React.SetStateAction<CollaboratorType>>;
  setPortfolioModalView: React.Dispatch<React.SetStateAction<string>>;
  leavePortfolio: (args: { variables: {} }) => void;
  setPortfolioActionToast: (toast: any) => void;
}

const SharedWithMeTable = ({
  showSharePortfolio,
  declinePortofolioInvite,
  selectedCollaborator,
  setSelectedCollaborator,
  setPortfolioModalView,
  leavePortfolio,
  setPortfolioActionToast,
}: ISharedWithMeTable) => {
  const portfolioSharedWithMe = useSelector((state: any) => state.portfolios.portfolioSharedWithMe);
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [modalAction, setModalAction] = useState<string>("");
  const switchActivePortfolio = useSwitchActivePortfolio();

  const declineInvite = () => {
    setPortfolioActionToast(toast(<ActionStateToast message="Declining invite..." />));
    declinePortofolioInvite({
      variables: {
        portfolioId: selectedCollaborator.portfolio.id,
      },
    });
    setDeleteModal(false);
  };

  const removePortfolio = () => {
    setPortfolioActionToast(toast(<ActionStateToast message="Removing portfolio..." />));
    leavePortfolio({
      variables: {
        portfolioId: selectedCollaborator.portfolio.id,
      },
    });
    setDeleteModal(false);
  };

  const handleDeclineInvite = (data: CollaboratorType) => {
    setModalAction("decline");
    setSelectedCollaborator(data);
    setDeleteModal(true);
  };
  const handleDeleteInvite = (data: CollaboratorType) => {
    setModalAction("delete");
    setSelectedCollaborator(data);
    setDeleteModal(true);
  };

  const handleLeavePortfolio = (data: CollaboratorType) => {
    setModalAction("leave");
    setSelectedCollaborator(data);
    setDeleteModal(true);
  };

  const openPortfolio = (data: CollaboratorType) => {
    switchActivePortfolio(data.portfolio.id);
    navigate("/portfolio/labels");
  };

  const acceptInvite = (data: CollaboratorType) => {
    navigate("/verify-portfolio-access?id=" + data.portfolio.id);
  };

  return (
    <Fragment>
      {portfolioSharedWithMe.portfolios.length > 0 ? (
        <>
          <SharedWithMeMobile
            setSelectedCollaborator={setSelectedCollaborator}
            setPortfolioModalView={setPortfolioModalView}
          />
          <div className="collaborators mt-8">
            <div className="table-data font-medium text-type">
              <p>COLLABORATOR</p>
              <p>DATE SHARED</p>
              <p className="text-center">STATUS</p>
              <p className="text-center">ACCESS</p>
              <p>PORTFOLIO</p>
            </div>
            {deleteModal && (
              <ConfirmationModal
                modalIsOpen={deleteModal}
                closeModal={() => {
                  setDeleteModal(false);
                }}
                submitAction={modalAction === "decline" ? declineInvite : removePortfolio}
                confirmationText={
                  modalAction === "delete" ? (
                    "Are you sure to delete this invite? You can always be reinvited"
                  ) : modalAction === "leave" ? (
                    <span>
                      Are you sure to leave{" "}
                      <span className="font-semibold">{selectedCollaborator?.portfolio?.user?.first_name + "'s"}</span>{" "}
                      portfolio?
                    </span>
                  ) : (
                    modalAction === "decline" && "Decline portfolio invite? You can always be reinvited"
                  )
                }
              />
            )}
            {portfolioSharedWithMe.portfolios.map((person: CollaboratorType, i: number) => (
              <div key={i} className="table-data body">
                <div className="flex items-center">
                  <img
                    src={person.portfolio.user.image_url ? person.portfolio.user.image_url : Avatar}
                    alt=""
                    width={32}
                    height={32}
                    className="rounded-full"
                  />
                  <p className="ml-2">{person.portfolio.user.first_name + " " + person.portfolio.user.last_name}</p>
                </div>
                <p className="text-type">{dayjs(person.created_at).format("D/MM/YYYY")}</p>
                <div>
                  <p className={`text-center status mx-auto text-p2 capitalize ${person.status.toLocaleLowerCase()}`}>
                    {person.status.toLocaleLowerCase()}
                  </p>
                </div>
                <p className="text-center text-type">Can {person.access_level.toLocaleLowerCase()}</p>
                {person.status === "ACCEPTED" && (
                  <div className="flex items-center justify-stretch">
                    <MainButton type="primary" size="small" click={() => openPortfolio(person)} extraClasses="w-full">
                      Open
                    </MainButton>
                    <MainButton
                      type="secondary"
                      size="small"
                      extraClasses="ml-4 w-full"
                      click={() => handleLeavePortfolio(person)}
                    >
                      Leave
                    </MainButton>
                  </div>
                )}

                {person.status === "PENDING" && (
                  <div className="flex items-center justify-stretch">
                    <MainButton type="primary" size="small" click={() => acceptInvite(person)} extraClasses="w-full">
                      Accept
                    </MainButton>
                    <MainButton
                      type="secondary"
                      size="small"
                      extraClasses="ml-4 w-full"
                      click={() => handleDeclineInvite(person)}
                    >
                      Decline
                    </MainButton>
                  </div>
                )}
                {person.status === "DECLINED" && (
                  <div className="flex items-center justify-stretch">
                    <MainButton type="primary" size="small" extraClasses="w-full">
                      Request
                    </MainButton>
                    <MainButton
                      type="secondary"
                      size="small"
                      extraClasses="ml-4 w-full"
                      click={() => handleDeleteInvite(person)}
                    >
                      Delete
                    </MainButton>
                  </div>
                )}
              </div>
            ))}
          </div>
          <button className="mobile-share-btn" onClick={showSharePortfolio}>
            <ShareMobile />
          </button>
        </>
      ) : (
        <SharePortfolio showSharePortfolio={showSharePortfolio} />
      )}
    </Fragment>
  );
};

interface ICollaboratorMobile {
  setPortfolioModalView: React.Dispatch<React.SetStateAction<string>>;
  setSelectedCollaborator: React.Dispatch<React.SetStateAction<CollaboratorType>>;
}

const CollaboratorMobile = ({ setPortfolioModalView, setSelectedCollaborator }: ICollaboratorMobile) => {
  const collaborators = useSelector((state: any) => state.portfolios.collaborators);
  const handleEditCollaborator = (data: CollaboratorType) => {
    setSelectedCollaborator(data);
    setPortfolioModalView("edit-portfolio");
  };

  return (
    <div className="mobile-collaborator">
      {collaborators.map((person: CollaboratorType, i: number) => (
        <div
          className="flex justify-between px-4 py-3 mb-2 collabcontainer"
          key={i}
          onClick={() => handleEditCollaborator(person)}
        >
          <div className="flex">
            <img
              src={
                person.user
                  ? person.user.image_url
                    ? `data:image/jpeg;base64,${person.user.image_url}`
                    : Avatar
                  : Avatar
              }
              alt=""
              width={48}
              height={48}
              style={{
                minHeight: "48px",
                minWidth: "48px",
                maxHeight: "48px",
                maxWidth: "48px",
              }}
            />
            <div className="ml-4">
              <p className="text-p1 font-medium">
                {person.user ? person.user.first_name + " " + person.user.last_name : person.email}
              </p>
              <p className="text-p3 my-1">Can {person.access_level.toLocaleLowerCase()}</p>
              <p className={`mobile-status capitalize ${person.status.toLocaleLowerCase()}`}>
                {person.status.toLocaleLowerCase()}
              </p>
            </div>
          </div>
          <ArrowRight />
        </div>
      ))}
    </div>
  );
};

const SharedWithMeMobile = ({ setPortfolioModalView, setSelectedCollaborator }: ICollaboratorMobile) => {
  const portfolioSharedWithMe = useSelector((state: any) => state.portfolios.portfolioSharedWithMe);

  const handleEditCollaborator = (data: CollaboratorType) => {
    setSelectedCollaborator(data);
    setPortfolioModalView("view-shared-portfolio");
  };

  return (
    <div className="mobile-collaborator">
      {portfolioSharedWithMe.portfolios.map((person: CollaboratorType, i: number) => (
        <div
          className="flex justify-between px-4 py-3 mb-2 collabcontainer"
          key={i}
          onClick={() => handleEditCollaborator(person)}
        >
          <div className="flex">
            <img
              src={person.portfolio.user.image_url ? person.portfolio.user.image_url : Avatar}
              alt=""
              className="displaypicture"
            />
            <div className="ml-4">
              <p className="text-p1 font-medium">
                {person.portfolio.user.first_name + " " + person.portfolio.user.last_name}
              </p>
              <p className="text-p3 my-1">Can {person.access_level.toLocaleLowerCase()}</p>
              <p className={`mobile-status capitalize ${person.status.toLocaleLowerCase()}`}>
                {person.status.toLocaleLowerCase()}
              </p>
            </div>
          </div>
          <ArrowRight />
        </div>
      ))}
    </div>
  );
};
