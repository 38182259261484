import Modal from "react-modal";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Capacitor } from "@capacitor/core";
import { ReactComponent as Logo } from "../../../assets/svg/logo1.svg";
import { useGetPlansByPartnerQuery, useGetSubscriptionQuery } from "../../../api/subscription/subscription-queries";
import { PaymentPartners, SubscriptionStatus } from "../../../types/subscriptionTypes";
import { Purchases, PurchasesStoreProduct, LOG_LEVEL, PRODUCT_CATEGORY } from "@revenuecat/purchases-capacitor";
import { toast } from "react-toastify";
import ActionStateToast from "../../ActionStateToast";
import BillingFeatures from "../../billing/BillingFeatures";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface IAPModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export const IAPModal = ({ isOpen = false, closeModal }: IAPModalProps) => {
  const isNative = Capacitor.isNativePlatform();
  const { id: activePortfolioId } = useSelector((state: any) => state.activePortfolio);

  const {
    data: subscription,
    refetch: refetchSubscription,
    isFetching: isFetchingSubscription,
  } = useGetSubscriptionQuery({
    id: activePortfolioId,
  });

  const hasActiveAppleSub =
    subscription?.partner === PaymentPartners.APPLE && subscription?.status === SubscriptionStatus.ACTIVE;
  const [products, setProducts] = useState<PurchasesStoreProduct[]>([]);

  const { data: plans } = useGetPlansByPartnerQuery({
    partner: PaymentPartners.APPLE,
  });
  const productIdentifiers = plans?.map((plan: { partner_ref: string }) => plan.partner_ref);

  // Initialize RevenueCat SDK
  useEffect(() => {
    (async function () {
      if (!process.env.REACT_APP_REVENUECAT_API_KEY) return;
      await Purchases.setLogLevel({ level: process.env.NODE_ENV === "production" ? LOG_LEVEL.INFO : LOG_LEVEL.DEBUG });
      await Purchases.configure({ apiKey: process.env.REACT_APP_REVENUECAT_API_KEY });
      showProducts();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [actionStateToast, setActionStateToast] = useState<any>();
  const onClickSubscribe = async (product: PurchasesStoreProduct) => {
    try {
      await Purchases.purchaseStoreProduct({ product });

      // Once the purchase is successful, RevenueCat sends an event to the webhook /apple/revenuecat/webhook.
      // Here a match is made to the user in the db using the userAppID. This should be set on the user on signup/login if it doesn't exist.
      // The subscription is then created/updated in the db.
      // Time to refresh the subscription query. It should be updated with the new subscription status.
      setActionStateToast(
        toast(<ActionStateToast message="Checking purchases..." />, {
          autoClose: false,
        })
      );
      await refetchSubscription();

      setTimeout(() => {
        toast.dismiss(actionStateToast);
        closeModal();
      }, 2000);
    } catch (e: any) {
      console.error("fail: handleSubscribeClick", e);
    }
  };

  const showProducts = async () => {
    try {
      const catProducts = await Purchases.getProducts({
        productIdentifiers,
        type: PRODUCT_CATEGORY.SUBSCRIPTION,
      });
      setProducts(catProducts.products);
    } catch (error) {
      console.error("fail: displayUpsellScreen", error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="In App Purchases Modal"
      className={classNames("modal-container", isNative && "native-modal")}
      overlayClassName={"modal-container-overlay"}
    >
      {/* Header */}
      <div className="modal-header px-4 flex items-center justify-end">
        <button type="button" onClick={closeModal} className="cursor-pointer">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>

      {/* Body */}
      <div className="modal-body px-4 md:px-8 pb-10">
        <div className="w-full flex flex-col items-center justify-center mb-4">
          <Logo />
        </div>

        {/* Premium Info */}
        {!hasActiveAppleSub && <BillingFeatures />}

        {isFetchingSubscription ? (
          <div className="text-center">Loading...</div>
        ) : (
          <>
            {/*
             * Product List
             * If the user has an active subscription, don't show the product list.
             * Users can only have one subscription at a time.
             * If the user wants to change subscription e.g. go from monthly to yearly, use "Manage Subscriptions".
             **/}
            {!hasActiveAppleSub && products?.length ? (
              <div className="flex flex-col gap-y-7">
                {products.map((product: PurchasesStoreProduct) => (
                  <div key={product.identifier}>
                    <div className="text-center">
                      <h3 className="text-lg font-semibold">{product.title}</h3>
                      <p className="text-sm">{product.description}</p>
                    </div>
                    <div className="text-md text-center mb-3">
                      <span>
                        {product.price} {product.currencyCode}
                      </span>
                    </div>
                    <div className="flex justify-center">
                      <button className="btn btn-primary py-3 px-10" onClick={() => onClickSubscribe(product)}>
                        Subscribe
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </>
        )}
      </div>
    </Modal>
  );
};
