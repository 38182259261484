import { forwardRef } from "react";

type autoCompleteType = "on" | "off";

interface ITextInput {
  label?: string;
  inputid?: string;
  message?: string;
  iserror?: any;
  onChange?: (e?: any) => void;
  onFocus?: (e?: any) => void;
  checked?: boolean;
  type?: string;
  placeholder?: string;
  value?: string;
  name?: string;
  style?: any;
  disabled?: boolean;
  autocomplete?: autoCompleteType;
}

export const Textinput = forwardRef<HTMLInputElement, ITextInput>((props, ref) => {
  const { autocomplete, label, inputid, message, iserror, disabled, ...rest } = props;
  return (
    <div className="flex flex-col">
      <label className="text-p2 font-medium text-type mb-1 capitalize" htmlFor={inputid}>
        {label}
      </label>
      <input
        ref={ref}
        className={`bg-neutral-300 rounded placeholder-type-200 text-p2 font-normal px-2 py-3 transition focus:border-type focus:ring-0
          ${iserror ? "border-status-danger" : "border-neutral-200 "} ${disabled ? "text-type-200" : "text-type"}`}
        id={inputid}
        autoComplete={autocomplete || "off"}
        disabled={disabled}
        {...rest}
      />
      {iserror ? <small className="text-p3 text-status-danger mt-1">{message}</small> : null}
    </div>
  );
});
