import { useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import MainButton from "../../../../components/buttons/mainButton";
import MasterCard from "../../../../assets/svg/master-card.svg";
import AmericanExpress from "../../../../assets/svg/american-express.svg";
import JCB from "../../../../assets/svg/JCB.svg";
import UnionPay from "../../../../assets/svg/UnionPay.svg";
import Discover from "../../../../assets/svg/discover-network.svg";
import Visa from "../../../../assets/svg/visa.svg";
import { Plan, PlanIntervals, CardBrands, PaymentMethod, Subscription } from "../../../../types/subscriptionTypes";
import { ReactComponent as Whitetick } from "../../../../assets/svg/whitetick.svg";
import { UserState } from "../../../../types/authTypes";
import { PortfolioState } from "../../../../types/portfolioTypes";
import AddPaymentMethod from "./AddStripePaymentMethodModal";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { useCreateSubscriptionMutation } from "../../../../api/subscription/subscription-mutations";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface IChosePaymentMethod {
  closeModal: () => void;
  isOpen: boolean;
  plan: Plan | null;
  status: "PAYMENT_PENDING" | "NEW_PAYMENT" | "";
  updatePaymentMethod: (paymentMethodId: string) => void;
  updateUserData: () => void;
  setAsDefaultPaymentMethod?: boolean;
}

const ChooseStripePaymentModal = ({
  closeModal,
  isOpen,
  plan,
  status,
  updatePaymentMethod,
  updateUserData,
  setAsDefaultPaymentMethod = false,
}: IChosePaymentMethod) => {
  const [selected, setSelected] = useState<string>("");
  const paymentMethods = useSelector((state: { user: UserState }) => state.user.currentUser.payment_methods);
  const subscription = useSelector(
    (state: { portfolios: { portfolio: PortfolioState } }) => state.portfolios.portfolio.subscription
  );
  const [addPaymentMethodModal, setAddPaymentMethodModal] = useState<boolean>(false);

  const addPaymentMethodCallback = (paymentMethodId?: string) => {
    if (paymentMethodId) {
      updatePaymentMethod(paymentMethodId);
      closeModal();
    }
  };

  const { mutateAsync: createStripeSubscription } = useCreateSubscriptionMutation();
  const makePayment = async () => {
    switch (status) {
      case "PAYMENT_PENDING":
        updatePaymentMethod(selected);
        closeModal();
        break;

      case "NEW_PAYMENT":
        if (!plan || !selected) break;

        try {
          const res = await createStripeSubscription(plan.id);

          if (!res.errors) {
            const response: { createSubscription: Subscription } = res.data;

            if (response.createSubscription) {
              updateUserData();
              closeModal();
            }
          } else {
            toast.error(res?.errors[0].message ?? "Unable to create subscription");
          }
        } catch (err) {
          console.error("fail: makePayment", err);
        }

        break;

      default:
        break;
    }
  };

  const isNative = Capacitor.isNativePlatform();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      className={classNames(`addAssetClass postionmoal relative share12`, isNative && "native-modal")}
      overlayClassName={"modal-container-overlay"}
    >
      {addPaymentMethodModal && (
        <AddPaymentMethod
          closeModal={() => setAddPaymentMethodModal(false)}
          addPaymentMethodActionType={status === "PAYMENT_PENDING" ? "add-card" : "make-payment"}
          addPaymentMethodModal={addPaymentMethodModal}
          callback={addPaymentMethodCallback}
          setDefault={setAsDefaultPaymentMethod}
        />
      )}

      <div className="header">
        <div className="header-assetsheader h4-semibold "></div>
        <button type="button" onClick={closeModal} className="cursor-pointer">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>
      <h2 className="header-assetsheader h4-semibold mb-2">
        {status === "PAYMENT_PENDING" ? "Update Payment Method" : "Select Payment Method"}
      </h2>

      <p className="p-normal mb-5 text-type-100 text-p2">
        {status === "PAYMENT_PENDING" &&
          "List of all your credit cards. Select one of these to change your payment method."}
        {status === "NEW_PAYMENT" && "Make payment by selecting a card from your saved cards."}
      </p>

      {plan && status === "NEW_PAYMENT" && (
        <div className="p-change-card">
          <div className="c-c-left">
            <div className="l-top">
              {`${plan.currency.symbol}${plan.amount / 100}/${
                plan.interval === PlanIntervals.DAY ? "dai" : plan.interval
              }ly ${plan.interval === PlanIntervals.YEAR ? "(save $20)" : ""}`}
            </div>

            <div className="l-bottom">Chosen Plan</div>
          </div>
        </div>
      )}

      <div className={`overflow-y-auto chose-cards-list ${status === "NEW_PAYMENT" ? "new-payment" : ""}`}>
        {subscription && status === "PAYMENT_PENDING" && subscription.payment_method && (
          <PaymentMethodComp paymentMethod={subscription.payment_method} active />
        )}

        {paymentMethods
          .filter((paymentMethod: PaymentMethod) =>
            subscription && status === "PAYMENT_PENDING"
              ? subscription.payment_method
                ? paymentMethod.id !== subscription.payment_method.id
                : true
              : true
          )
          .map((paymentMethod: PaymentMethod) => (
            <PaymentMethodComp
              paymentMethod={paymentMethod}
              key={paymentMethod.id}
              selected={selected}
              setSelected={setSelected}
            />
          ))}

        <button
          type="button"
          className="text-primary font-semibold text-p2 click-item underline mt-3 no-select"
          onClick={() => setAddPaymentMethodModal(true)}
        >
          + Add new card
        </button>

        <div className="text-xs mt-2 text-gray-500">This will switch your default payment method</div>
      </div>

      <div className="asset-class-footer">
        <MainButton size="small" type="secondary" click={closeModal}>
          Cancel
        </MainButton>
        <MainButton
          size="small"
          type="primary"
          extraClasses="ml-4"
          disabled={selected === "" || addPaymentMethodModal}
          click={makePayment}
        >
          {status === "PAYMENT_PENDING" && "Update"}
          {status === "NEW_PAYMENT" && "Make Payment"}
        </MainButton>
      </div>
    </Modal>
  );
};

interface IPaymentMethodComp {
  paymentMethod: PaymentMethod;
  active?: boolean;
  selected?: string;
  setSelected?: (id: string) => void;
}

const PaymentMethodComp = ({ paymentMethod, active, selected, setSelected }: IPaymentMethodComp) => {
  return (
    <div
      className={`flex flex-row flex-wrap justify-between items-center gap-2 card-info no-maxz ${
        active ? "" : "click-item"
      }`}
      onClick={() => {
        if (!active) {
          setSelected && setSelected(paymentMethod.id);
        }
      }}
    >
      <div className="flex flex-row gap-4">
        {paymentMethod.card_brand === CardBrands.visa && <img src={Visa} alt="card" className="mr-3 my-auto w-12" />}
        {paymentMethod.card_brand === CardBrands.mastercard && (
          <img src={MasterCard} alt="card" className="mr-3 my-auto w-12" />
        )}
        {paymentMethod.card_brand === CardBrands.amex && (
          <img src={AmericanExpress} alt="card" className="mr-3 my-auto w-12" />
        )}
        {paymentMethod.card_brand === CardBrands.jcb && <img src={JCB} alt="card" className="mr-3 my-auto w-12" />}
        {paymentMethod.card_brand === CardBrands.unionpay && (
          <img src={UnionPay} alt="card" className="mr-3 my-auto w-12" />
        )}
        {paymentMethod.card_brand === CardBrands.discover && (
          <img src={Discover} alt="card" className="mr-3 my-auto w-12" />
        )}

        <div className="block pr-2 my-auto">
          <p className="text-sm">{`**** **** **** ${paymentMethod?.card_last4} ${active ? "(Default)" : ""}`}</p>
          <p className="text-xs text-gray-600">
            Expires {paymentMethod.card_exp_month}/{paymentMethod.card_exp_year}
          </p>
          {active && <p className="text-xs text-red-600 mt-2">Payment failed</p>}
        </div>
      </div>

      {!active && (
        <div className={`select-circle ${selected === paymentMethod.id ? "circle-selected" : "circle-unselected"}`}>
          {selected === paymentMethod.id && <Whitetick />}
        </div>
      )}
    </div>
  );
};

export default ChooseStripePaymentModal;
