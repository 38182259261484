import { useState } from "react";
import { print } from "graphql";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ActionStateToast from "../../components/ActionStateToast";
import { CREATE_VAULT_FOLDER } from "../../graphql/vault";
import { CREATE_VAULT_NOTE } from "../../graphql/vault";
import CheckCir from "../../assets/svg/successcheck.svg";
import { CreateVaultNoteInput, UpdateVaultNoteInput } from "../../types/vaultTypes";
import {
  RENAME_VAULT_DOCUMENT,
  RENAME_VAULT_FOLDER,
  UPLOAD_VAULT_DOCUMENT,
  REMOVE_VAULT_DOCUMENT,
  REMOVE_VAULT_FOLDER,
  REMOVE_VAULT_NOTE,
  UPDATE_VAULT_NOTE,
  MOVE_VAULT_DOCUMENT,
  MOVE_VAULT_NOTE,
} from "../../graphql/vault";
import { useApi } from "../../hooks/useAPI";

export function useUploadFileMutation() {
  const queryClient = useQueryClient();
  const Api = useApi({ "Content-Type": "multipart/form-data" });

  const [uploadFileToast, setUploadFileToast] = useState<any>();

  return useMutation(
    async (variables: { file: File; vaultFolderId?: string }) => {
      const formData = new FormData();

      formData.append(
        "operations",
        JSON.stringify({
          operationName: "UploadVaultDocument",
          variables: { file: null, vaultFolderId: variables.vaultFolderId },
          query: print(UPLOAD_VAULT_DOCUMENT),
        })
      );

      formData.append("map", JSON.stringify({ "1": ["variables.file"] }));
      formData.append("1", variables.file);

      const data = await Api.post("", formData);
      return data?.data?.data?.uploadVaultDocument;
    },
    {
      onMutate: () => {
        setUploadFileToast(
          toast(<ActionStateToast message="Uploading File..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: (response) => {
        if (response) {
          toast.dismiss(uploadFileToast);

          toast.success(
            <div className="toast-div">
              <img src={CheckCir} alt="check circle" />
              Successfully uploaded file.
            </div>
          );

          queryClient.invalidateQueries(["vaultFolders"]);
        } else {
          console.error("upload file response is null");
        }
      },
      onError: (error) => {
        toast.dismiss(uploadFileToast);
        toast("Something went wrong..", {
          autoClose: 3000,
          type: "error",
        });
      },
      onSettled: () => {
        toast.dismiss(uploadFileToast);
        setUploadFileToast(null);
      },
    }
  );
}

export function useCreateVaultFolderMutation() {
  const queryClient = useQueryClient();
  const Api = useApi({});
  const [createFolderToast, setCreateFolderToast] = useState<any>();

  return useMutation(
    async (variables: { name: string }) => {
      const payload = {
        operationName: "CreateVaultFolder",
        variables: {
          name: variables.name,
        },
        query: print(CREATE_VAULT_FOLDER),
      };
      const data = await Api.post("", JSON.stringify(payload));
      return data?.data?.data?.createVaultFolder;
    },
    {
      onMutate: () => {
        setCreateFolderToast(
          toast(<ActionStateToast message="Creating folder..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: () => {
        toast.dismiss(createFolderToast);

        toast.success(
          <div className="toast-div">
            <img src={CheckCir} alt="check circle" />
            Successfully created folder.
          </div>
        );

        queryClient.invalidateQueries(["vaultFolders"]);
      },
      onError: () => {
        toast.dismiss(createFolderToast);
        toast.error("Something went wrong. Please try again.");
      },
      onSettled: () => {
        toast.dismiss(createFolderToast);
        setCreateFolderToast(null);
      },
    }
  );
}

export function useCreateVaultNoteMutation() {
  const queryClient = useQueryClient();
  const Api = useApi({});
  const [createNoteToast, setCreateNoteToast] = useState<any>();

  return useMutation(
    async (variables: { createVaultNoteInput: CreateVaultNoteInput }) => {
      const payload = {
        operationName: "CreateVaultNote",
        variables: {
          createVaultNoteInput: variables.createVaultNoteInput,
        },
        query: print(CREATE_VAULT_NOTE),
      };
      const data = await Api.post("", JSON.stringify(payload));
      return data?.data?.data?.createVaultNote;
    },
    {
      onMutate: () => {
        setCreateNoteToast(
          toast(<ActionStateToast message="Creating note..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: (result) => {
        toast.dismiss(createNoteToast);

        if (result) {
          toast.success(
            <div className="toast-div">
              <img src={CheckCir} alt="check circle" />
              Successfully added note.
            </div>
          );

          queryClient.invalidateQueries(["vaultFolders"]);
        }
      },
      onError: (error: Error) => {
        toast.dismiss(createNoteToast);
        toast.error(error.message);
      },
      onSettled: () => {
        toast.dismiss(createNoteToast);
        setCreateNoteToast(null);
      },
    }
  );
}

interface RenameVaultInput {
  id: string;
  name: string;
  fileType: "folder" | "document"; // Including fileType in the input
}

export function useRenameVaultItemMutation() {
  const queryClient = useQueryClient();
  const Api = useApi({});
  const [fileActionToast, setFileActionToast] = useState<any>();

  return useMutation(
    async (renameVaultInput: RenameVaultInput) => {
      const payload = {
        operationName: renameVaultInput.fileType === "folder" ? "RenameVaultFolder" : "RenameVaultDocument",
        variables: {
          renameVaultFolder:
            renameVaultInput.fileType === "folder"
              ? { id: renameVaultInput.id, name: renameVaultInput.name }
              : undefined,
          renameVaultDocument:
            renameVaultInput.fileType !== "folder"
              ? { id: renameVaultInput.id, name: renameVaultInput.name }
              : undefined,
        },
        query: print(renameVaultInput.fileType === "folder" ? RENAME_VAULT_FOLDER : RENAME_VAULT_DOCUMENT),
      };

      const data = await Api.post("", JSON.stringify(payload));
      return data?.data?.data?.renameVaultDocument || data?.data?.data?.renameVaultFolder;
    },
    {
      onMutate: (variables) => {
        setFileActionToast(
          toast(<ActionStateToast message={`Renaming ${variables.fileType}...`} />, {
            autoClose: false,
          })
        );
      },
      onSuccess: (result, variables) => {
        toast.dismiss(fileActionToast);

        if (result?.id) {
          toast.success(
            <div className="toast-div">
              <img src={CheckCir} alt="check circle" />
              Successfully updated {variables.fileType}.
            </div>
          );

          queryClient.invalidateQueries(["vaultFolders"]);
        } else {
          toast.error("Something went wrong. Please try again.");
        }
      },
      onError: (error) => {
        console.error("fail: useRenameVaultMutation", error);
      },
      onSettled: () => {
        toast.dismiss(fileActionToast);
        setFileActionToast(null);
      },
    }
  );
}

interface RemoveVaultInput {
  id: string;
  type: "folder" | "file" | "note";
}

export function useRemoveVaultItemMutation() {
  const queryClient = useQueryClient();
  const Api = useApi({});
  const [fileActionToast, setFileActionToast] = useState<any>();

  return useMutation(
    async (removeVaultInput: RemoveVaultInput) => {
      let variableKey: string;

      if (removeVaultInput.type === "folder") {
        variableKey = "removeVaultFolderId";
      } else if (removeVaultInput.type === "file") {
        variableKey = "removeVaultDocumentId";
      } else {
        variableKey = "removeVaultNoteId";
      }

      // Convert the ID to a number if the type is 'file'
      const variables = {
        [variableKey]: removeVaultInput.type === "file" ? parseFloat(removeVaultInput.id) : removeVaultInput.id,
      };

      const payload = {
        operationName:
          removeVaultInput.type === "folder"
            ? "RemoveVaultFolder"
            : removeVaultInput.type === "file"
            ? "RemoveVaultDocument"
            : "RemoveVaultNote",
        variables,
        query: print(
          removeVaultInput.type === "folder"
            ? REMOVE_VAULT_FOLDER
            : removeVaultInput.type === "file"
            ? REMOVE_VAULT_DOCUMENT
            : REMOVE_VAULT_NOTE
        ),
      };

      const data = await Api.post("", JSON.stringify(payload));
      return {
        removeVaultFolder: data?.data?.data?.removeVaultFolder,
        removeVaultDocument: data?.data?.data?.removeVaultDocument,
        removeVaultNote: data?.data?.data?.removeVaultNote,
      };
    },

    {
      onMutate: (variables) => {
        setFileActionToast(
          toast(<ActionStateToast message={`Removing ${variables.type}...`} />, {
            autoClose: false,
          })
        );
      },
      onSuccess: (result, variables) => {
        toast.dismiss(fileActionToast);

        if (result?.removeVaultFolder || result?.removeVaultDocument || result?.removeVaultNote) {
          queryClient.invalidateQueries(["vaultFolders"]);
          toast.success(
            <div className="toast-div">
              <img src={CheckCir} alt="check circle" />
              Successfully deleted {variables.type}.
            </div>
          );
        } else {
          toast.error("Something went wrong. Please try again.");
        }
      },
      onError: (error) => {
        console.error("fail: useRenameVaultMutation", error);
      },
      onSettled: () => {
        toast.dismiss(fileActionToast);
        setFileActionToast(null);
      },
    }
  );
}

export function useUpdateVaultNoteMutation() {
  const queryClient = useQueryClient();
  const Api = useApi({});
  const [fileActionToast, setFileActionToast] = useState<any>();

  return useMutation(
    async (updateVaultNoteInput: UpdateVaultNoteInput) => {
      const payload = {
        operationName: "UpdateVaultNote",
        variables: { updateVaultNoteInput },
        query: print(UPDATE_VAULT_NOTE),
      };

      const data = await Api.post("", JSON.stringify(payload));
      return data?.data?.data?.updateVaultNote;
    },
    {
      onMutate: () => {
        setFileActionToast(
          toast(<ActionStateToast message="Updating note..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: (result) => {
        toast.dismiss(fileActionToast);

        if (result) {
          queryClient.invalidateQueries(["vaultFolders"]);
          toast.success(
            <div className="toast-div">
              <img src={CheckCir} alt="check circle" />
              Successfully updated note.
            </div>
          );
        } else {
          toast.error("Something went wrong. Please try again.");
        }
      },
      onError: (error) => {
        console.error("fail: useUpdateVaultNoteMutation", error);
      },
      onSettled: () => {
        toast.dismiss(fileActionToast);
        setFileActionToast(null);
      },
    }
  );
}

export interface MoveVaultItemInput {
  id: string;
  vault_folder_id: string;
  type: "file" | "note";
}

export function useMoveVaultItemMutation() {
  const queryClient = useQueryClient();
  const Api = useApi({});
  const [fileActionToast, setFileActionToast] = useState<any>();

  return useMutation(
    async (input: MoveVaultItemInput) => {
      const variableKey = input.type === "file" ? "moveVaultDocumentInput" : "moveVaultNoteInput";
      const payload = {
        operationName: input.type === "file" ? "MoveVaultDocument" : "MoveVaultNote",
        variables: {
          [variableKey]: {
            id: input.id,
            vault_folder_id: input.vault_folder_id,
          },
        },
        query: print(input.type === "file" ? MOVE_VAULT_DOCUMENT : MOVE_VAULT_NOTE),
      };

      const data = await Api.post("", JSON.stringify(payload));
      return data?.data?.data?.moveVaultDocument || data?.data?.data?.moveVaultNote;
    },
    {
      onMutate: (input) => {
        setFileActionToast(
          toast(<ActionStateToast message={`Moving ${input.type}...`} />, {
            autoClose: false,
          })
        );
      },
      onSuccess: (result, variables) => {
        toast.dismiss(fileActionToast);

        if (result) {
          queryClient.invalidateQueries(["vaultFolders"]);
          toast.success(
            <div className="toast-div">
              <img src={CheckCir} alt="check circle" />
              Successfully moved {variables.type}.
            </div>
          );
        } else {
          toast.error("Something went wrong. Please try again.");
        }
      },
      onError: (error) => {
        console.error("fail: useMoveVaultItemMutation", error);
      },
      onSettled: () => {
        toast.dismiss(fileActionToast);
        setFileActionToast(null);
      },
    }
  );
}
