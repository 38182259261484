import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import useLifeCheck from "../../hooks/useLifeCheck";
import { ReactComponent as EmptyVaultsd } from "../../assets/svg/vault-empty.svg";
import { SingleFolder } from "./portfoliovaultfolder";
import { VaultFolderType } from "../../models/assets";
import { ReactComponent as VaultFolderIcon } from "../../assets/svg/vault-folder.svg";
import { ReactComponent as VaultNoteIcon } from "../../assets/svg/vault-note.svg";
import { VaultFileIcon } from "../../components/icons/vaultfileicon";
import {
  convertStampDate,
  convertStampDatev2,
  subStringofName,
  convertBytestoMB,
} from "../../components/utilities/utilityFunctions";
import { getFileData } from "../../components/modals/viewassets";
import { VaultDocumentDropdown } from "../../components/inputs/vaultdropdowns";
import { useTranslation } from "react-i18next";

const PortfolioVault = () => {
  const { t } = useTranslation();
  const { handleFetchSharedPortfolio } = useLifeCheck();
  const { portfolioInfo } = useSelector((state: any) => state.utility);
  const { activePortfolio } = useSelector((state: any) => state.lifecheck);

  useEffect(() => {
    if (!activePortfolio && portfolioInfo?.recipientId) {
      handleFetchSharedPortfolio(`${portfolioInfo?.recipientId}`);
    }

    //eslint-disable-next-line
  }, [portfolioInfo]);

  return (
    <div className="pagescroll vaultpage">
      <div style={{ maxWidth: "1440px", margin: "0px auto" }}>
        <Helmet>
          <title>Vault</title>
          <meta name="description" content={`Secure your most important documents with ${t("orgName")}`} />
          <meta property="og:title" content="Vault" />
          <meta property="og:description" content={`Secure your most important documents with ${t("orgName")}`} />
          <meta property="og:url" content="https://app.getcova.com/vault" />
          <meta name="twitter:card" content={`Secure your most important documents with ${t("orgName")}`} />
        </Helmet>

        {activePortfolio?.vaultFiles?.length === 0 ? <EmptyVault /> : <VaultNotEmpty />}
      </div>
    </div>
  );
};

const EmptyVault = () => {
  return (
    <div className="emptyvault">
      <div className="emptyvaults3">
        <EmptyVaultsd />

        <p className="emptyvaults3-t1">Important Saved Documents</p>
        <p className="emptyvaults3-t2">
          The portfolio owner is yet to upload any important file, image or document into the vault.
        </p>
      </div>
    </div>
  );
};

export const VaultNotEmpty = () => {
  const [folderView, setFolderView] = useState(false);
  const [currentFolder, setCurrentFolder] = useState<VaultFolderType>({} as VaultFolderType);
  const {
    portfolioInfo: { ownerId },
  } = useSelector((state: any) => state.utility);

  const openFolder = (payload: any) => {
    setCurrentFolder(payload);
    setFolderView(true);
  };

  const backToMainVault = () => {
    setFolderView(false);
  };

  return (
    <>
      <div className="filledstate90">
        {folderView ? (
          <SingleFolder goBack={backToMainVault} folder={currentFolder} ownerId={ownerId} />
        ) : (
          <>
            <div className="header01 mb-14">
              <p className="h2-size">Vault</p>
            </div>
            <VaultList openFolder={openFolder} />
          </>
        )}
      </div>
    </>
  );
};

export const VaultList = ({ openFolder }: any) => {
  const { activePortfolio } = useSelector((state: any) => state.lifecheck);
  const {
    portfolioInfo: { ownerId },
  } = useSelector((state: any) => state.utility);

  return (
    <>
      <div className="flex flex-wrap gap-x-4 gap-y-4">
        {activePortfolio &&
          activePortfolio?.vaultFiles.slice(1).map((folder: any, index: number) => {
            return (
              <div
                key={`folder-${index}`}
                className="singleli1 p-5 border border-solid border-gray-200 rounded-md w-full md:w-48"
              >
                <div className="singleli1top">
                  <div
                    className="singleli1top-12 cursor-pointer"
                    onClick={() => {
                      openFolder(folder);
                    }}
                  >
                    <VaultFolderIcon />
                  </div>

                  <div className="singleli1top-13">
                    <VaultDocumentDropdown
                      folderId={folder?.id}
                      name={subStringofName(folder?.name, 37)}
                      type="folder"
                      isShared
                      sharedFileOwnerId={ownerId}
                    />
                  </div>
                </div>

                <div className="singleli1bottom">
                  <p className="singleli1bottom-name">{subStringofName(folder?.name, 37)}</p>
                  <p className="singleli1bottom-na4">
                    <span className="t22">
                      {convertStampDatev2(folder?.dateUpdated !== "" ? folder?.dateUpdated : folder?.dateCreated)}
                    </span>
                  </p>
                </div>
              </div>
            );
          })}

        {activePortfolio &&
          activePortfolio?.vaultFiles[0]?.vaultNotes.map((note: any, note_index: number) => {
            return (
              <div
                key={`note-${note_index}`}
                className="singleli1 p-5 border border-solid border-gray-200 rounded-md w-full md:w-48"
              >
                <div className="singleli1top">
                  <div className="singleli1top-12 cursor-pointer">
                    <VaultNoteIcon />
                  </div>
                  <div className="singleli1top-13">
                    <VaultDocumentDropdown
                      fileId={note?.id}
                      name={subStringofName(note?.title, 37)}
                      type="note"
                      isShared
                      sharedFileOwnerId={ownerId}
                    />
                  </div>
                </div>
                <div className="singleli1bottom">
                  <p className="singleli1bottom-name">{subStringofName(note.title, 37)}</p>
                  <p className="singleli1bottom-na4">
                    <span className="t22">{convertStampDatev2(note.lastEdited ?? note.dateCreated)}</span>
                  </p>
                </div>
              </div>
            );
          })}
        {activePortfolio &&
          activePortfolio?.vaultFiles[0]?.vaultDocumentViewList.map((value: any, document_index: any) => {
            return (
              <div
                key={`${value.fileUrl}_${document_index}`}
                className="singleli1 p-5 border border-solid border-gray-200 rounded-md w-full md:w-48"
              >
                <div className="singleli1top">
                  <div className="singleli1top-12 cursor-pointer">
                    <div className="relative vault-file-icon">
                      <VaultFileIcon
                        mainColor={getFileData(value.fileName).backgroundColor}
                        foldColor={getFileData(value.fileName).foldColor}
                      />
                      <p className="absolute font-semibold text-white text-p2">
                        {" "}
                        {getFileData(value.fileName).filetype}
                      </p>
                    </div>
                  </div>
                  <div className="singleli1top-13">
                    <VaultDocumentDropdown
                      fileId={value.id}
                      name={value.fileName}
                      type="file"
                      isShared
                      sharedFileOwnerId={ownerId}
                    />
                  </div>
                </div>
                <div className="singleli1bottom">
                  <p className="singleli1bottom-name">{subStringofName(value.fileName, 37)}</p>
                  <p className="singleli1bottom-na4">
                    <span className="t11">{convertBytestoMB(value.fileSize)} .</span>{" "}
                    <span className="t22">{convertStampDate(value.dateAdded)}</span>
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default PortfolioVault;
