import { useEffect, useState } from "react";
import { ReactComponent as EmptyFolder } from "../../assets/svg/girlwithflower.svg";
import { VaultNoteModal } from "./VaultNoteModal";
import MainButton from "../../components/buttons/mainButton";
import { FolderDropdown } from "../../components/inputs/vaultdropdowns";
import { BackarrowIcon } from "../../components/icons/backarrow";
import { VaultManageFileFolderModal } from "./VaultManageFileFolderModal";
import { VaultMoveDocumentModal } from "./VaultMoveDocumentModal";
import { ConfirmationModal } from "../../components/modals/ConfirmationModal";
import { useVault } from "./VaultProvider";
import { CreateVaultNoteInput, VaultDocumentType, VaultFolderType, VaultNoteType } from "../../types/vaultTypes";
import {
  useCreateVaultNoteMutation,
  useRemoveVaultItemMutation,
  useUploadFileMutation,
} from "../../api/vault/vault-mutations";
import { VaultFolderDocumentsModal } from "./VaultFolderDocumentsModal";
import VaultDocumentItem from "./VaultDocumentItem";
import VaultNoteItem from "./VaultNoteItem";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "@heroicons/react/24/outline";
import "./vault.scss";

/*
 * VaultFolder
 * There are only 2 levels of folders. This component is the second level.
 * VaultList handles the first level.
 * Contains only documents and notes.
 */
export const VaultFolder = ({ folders }: { folders: VaultFolderType[] }) => {
  const { t } = useTranslation();
  const [isFolderEmpty, setIsFolderEmpty] = useState(false);
  const [documentModal, setDocumentModal] = useState(false);
  const { currentFolder, setCurrentFolder } = useVault();

  useEffect(() => {
    const getFolder = folders.find((item) => item.id === currentFolder?.id);

    if (getFolder) {
      setCurrentFolder(getFolder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folders]);

  useEffect(() => {
    if (currentFolder && currentFolder.vaultDocuments.length === 0 && currentFolder.vaultNotes.length === 0) {
      setIsFolderEmpty(true);
    } else setIsFolderEmpty(false);
  }, [currentFolder]);

  const { mutateAsync: uploadFile, isLoading } = useUploadFileMutation();
  const handleUploadFile = async (files: File[]) => {
    try {
      await uploadFile({ vaultFolderId: currentFolder?.id, file: files[0] });
    } catch (error) {
      console.error(error);
    }
  };

  const {
    currentNote,
    currentManagedItem,
    currentMovingItem,
    currentDeletingItem,
    setCurrentNote,
    setCurrentManagedItem,
    setCurrentMovingItem,
    setCurrentDeletingItem,
  } = useVault();
  const { mutateAsync: removeVaultDocument } = useRemoveVaultItemMutation();
  const { mutateAsync: createNote } = useCreateVaultNoteMutation();

  const openRenameActionModal = (type: "folder" | "file" | "note", doc?: VaultDocumentType) => {
    setCurrentManagedItem({
      type,
      item: doc,
    });
  };

  const handleCreateVaultNote = async (data: CreateVaultNoteInput) => {
    try {
      await createNote({
        createVaultNoteInput: data,
      });
      setCurrentNote(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveVaultItem = async () => {
    try {
      if (!currentDeletingItem?.item?.id || !currentDeletingItem?.type) return;

      await removeVaultDocument({
        id: currentDeletingItem?.item?.id,
        type: currentDeletingItem?.type,
      });

      setCurrentDeletingItem(null);
    } catch (error) {
      console.error("fail: handleRemoveVaultItem", error);
    }
  };

  const openManageFileFolderModal = ({ type, item }: { type: "folder" | "file" | "note"; item?: any }) => {
    setCurrentManagedItem({
      type,
      item,
    });
  };

  const openMoveDocumentModal = (type: "file" | "note", documentId: string) => {
    setCurrentMovingItem({
      type,
      documentId,
    });
  };

  return (
    <>
      {/* Modals */}
      <VaultManageFileFolderModal
        modalIsOpen={!!currentManagedItem}
        vaultItem={currentManagedItem}
        folders={folders}
        onCloseModal={() => setCurrentManagedItem(null)}
        inputLabel={`${currentManagedItem?.type} name`}
        placeholder={`Enter ${currentManagedItem?.type} name`}
        header={`Rename ${currentManagedItem?.type}`}
      />

      <ConfirmationModal
        modalIsOpen={!!currentDeletingItem}
        closeModal={() => setCurrentDeletingItem(null)}
        verificationText={currentDeletingItem?.type === "folder" && "DELETE"}
        submitAction={handleRemoveVaultItem}
        submitText="Yes, I’m Sure"
        confirmationText={`This will permanently delete this ${
          currentDeletingItem?.type === "folder" ? "folder and all the files in it" : currentDeletingItem?.type
        }. They will no longer be retrievable on ${t("orgName")}.`}
      />

      {documentModal && (
        <VaultFolderDocumentsModal
          modalIsOpen={documentModal}
          closeModal={() => {
            setDocumentModal(false);
          }}
          folder={currentFolder}
          uploadAction={handleUploadFile}
          addNoteAction={handleCreateVaultNote}
          disabled={isLoading}
        />
      )}

      <VaultNoteModal
        modalIsOpen={!!currentNote?.id}
        details={currentNote}
        folderId={folders[0].id}
        closeModal={() => setCurrentNote(null)}
      />

      <VaultMoveDocumentModal
        folders={folders}
        modalIsOpen={!!currentMovingItem}
        closeModal={() => setCurrentMovingItem(null)}
      />

      <>
        {/* Folder View - Topbar */}
        <div className="flex cursor-pointer items-center mb-6" onClick={() => setCurrentFolder(null)}>
          <BackarrowIcon />
          <span className="ml-2">Back</span>
        </div>
        <div className="mb-10 flex justify-between items-center">
          <div className="flex items-center">
            <h2 className="h2-size">{currentFolder?.name}</h2>
            <FolderDropdown
              openRenameModal={() => openRenameActionModal("folder")}
              openDeleteModal={() => setCurrentDeletingItem({ item: currentFolder, type: "folder" })}
            />
          </div>
          <MainButton click={() => setDocumentModal(true)} type="primary" size="small" extraClasses="flex items-center">
            <PlusIcon className="w-5 h-5" />
            <span className="ml-2 btn-text-hide">Documents</span>
          </MainButton>
        </div>

        {/* Folder View - Body */}
        {isFolderEmpty ? (
          <div className="flex flex-col items-center gap-y-6">
            <EmptyFolder />
            <div className="text-center">
              <p className="text-xl font-semibold mb-3">This folder is empty</p>
              <p className="text-md text-gray-600 mb-6 leading-snug max-w-[420px]">
                Use the '+<span className="btn-text-hide"> Documents</span>' button to upload files and add notes.
              </p>
            </div>
          </div>
        ) : (
          <div className="flex flex-wrap gap-x-4 gap-y-4">
            {/* Notes */}
            {currentFolder?.vaultNotes?.map((note: VaultNoteType, i: number) => (
              <VaultNoteItem
                key={`note-${i}`}
                note={note}
                note_index={i}
                openNoteModal={(details) => setCurrentNote(details)}
                openMoveDocumentModal={openMoveDocumentModal}
                setConfirmDelete={(data) => setCurrentDeletingItem(data)}
              />
            ))}

            {/* Documents */}
            {currentFolder &&
              currentFolder.vaultDocuments.map((document: VaultDocumentType, i: any) => (
                <VaultDocumentItem
                  key={`${document.file_url}_${i}`}
                  document={document}
                  document_index={i}
                  folders={folders}
                  openManageFileFolderModal={(data) => openManageFileFolderModal(data)}
                  openMoveDocumentModal={openMoveDocumentModal}
                  setConfirmDelete={(data) => setCurrentDeletingItem(data)}
                />
              ))}
          </div>
        )}
      </>
    </>
  );
};
