import { print } from "graphql";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../hooks/useAPI";
import { X_FETCH_CONTACTS } from "../../graphql/contacts.gql-query";

export const useGetContactsQuery = ({ id, search = "" }: { id: number; search?: string }) => {
  const Api = useApi();
  const enabled = id !== undefined;
  const variables = { id, search };

  return useQuery(
    ["portfolio", variables],
    async () => {
      const payload = {
        operationName: "FetchContacts",
        variables,
        query: print(X_FETCH_CONTACTS),
      };

      const response = await Api.post("", JSON.stringify(payload));
      const contacts = response?.data?.data?.portfolio?.contacts;
      return contacts || [];
    },
    {
      enabled,
      onError: (error) => {
        console.error("fail: useGetContactsQuery", error);
      },
      placeholderData: [],
      staleTime: 1000 * 60 * 60, // 1 hour
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    }
  );
};
