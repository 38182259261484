import React, { useEffect, useState } from "react";
import { Network } from "@capacitor/network";
import OfflineScreen from "./OfflineScreen";

const NetworkStatusHandler: React.FC = ({ children }) => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    let networkListener: any;

    const checkNetworkStatus = async () => {
      const status = await Network.getStatus();
      setIsOnline(status.connected);

      networkListener = Network.addListener("networkStatusChange", (status) => {
        setIsOnline(status.connected);
      });
    };

    checkNetworkStatus();

    return () => {
      if (networkListener) {
        networkListener.remove();
      }
    };
  }, []);

  if (!isOnline) {
    return <OfflineScreen />;
  }

  return <>{children}</>;
};

export default NetworkStatusHandler;
