export const SIGNUP_USER_SAGA = "SIGNUP_USER_SAGA";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_ERROR = "SIGNUP_USER_ERROR";
export const SIGNIN_USER_SAGA = "SIGNIN_USER_SAGA";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNIN_USER_ERROR = "SIGNIN_USER_ERROR";
export const RESET_USER_DATA = "RESET_USER_DATA";
export const SET_USER_DATA = "SET_USER_DATA";
export const LOGOUT_USER = "LOGOUT_USER";

export const isActive = "ACTIVE";
export const isTrial = "TRIAL";
export const isFreemium = "FREEMIUM";
