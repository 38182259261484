import { AppleIcon } from "../icons/appleicon";

const AppleAuthBtn = ({ onClick, label = "Sign In with Apple" }: { onClick: () => void; label?: string }) => {
  return (
    <button
      onClick={onClick}
      className="w-full justify-center flex items-center h-11 rounded border bg-black border-neutral-200"
    >
      <AppleIcon /> <span className="text-p1 font-semibold ml-2.5 text-type text-white">{label}</span>
    </button>
  );
};

export default AppleAuthBtn;
