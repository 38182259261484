import { useState } from "react";
import { ConfirmationModal } from "../../components/modals/ConfirmationModal";
import { ContactType } from "../../types/lifecheckTypes";
import { AddEditContactModal } from "../lifecheck-v2/AddEditContactModal";
import { useRemoveContactMutation } from "../../api/contact/contact-mutations";
import { ContactRowDesktop } from "./ContactRowDesktop";
import { ContactRowMobile } from "./ContactRowMobile";

interface IIndividualContact {
  contact: ContactType;
}

export const IndividualContact = ({ contact }: IIndividualContact) => {
  const [isDeleteModalOpen, openDeleteModal] = useState<boolean>(false);
  const [isEditModalOpen, openEditModal] = useState<boolean>(false);
  const { mutateAsync: removeContact } = useRemoveContactMutation();

  const handleRemoveContact = async () => {
    try {
      await removeContact({ contactId: contact.id });
      openDeleteModal(false);
    } catch (e) {
      console.error("fail: handleRemoveContact", e);
    }
  };

  return (
    <>
      {isDeleteModalOpen && (
        <ConfirmationModal
          modalIsOpen={isDeleteModalOpen}
          closeModal={() => openDeleteModal(false)}
          submitAction={handleRemoveContact}
          confirmationText="Are you sure to remove this contact ?"
        />
      )}

      {isEditModalOpen && (
        <AddEditContactModal isOpen={isEditModalOpen} contact={contact} closeModal={() => openEditModal(false)} />
      )}

      <ContactRowMobile
        contact={contact}
        onClickEdit={() => openEditModal(true)}
        onClickDelete={() => openDeleteModal(true)}
      />

      <ContactRowDesktop
        contact={contact}
        onClickEdit={() => openEditModal(true)}
        onClickDelete={() => openDeleteModal(true)}
      />
    </>
  );
};
