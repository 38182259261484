import { VaultDocumentDropdown } from "../../components/inputs/vaultdropdowns";
import { subStringofName } from "../../components/utilities/utilityFunctions";
import { SubscriptionStatus } from "../../types/subscriptionTypes";
import { ReactComponent as VaultNoteIcon } from "../../assets/svg/vault-note.svg";
import classNames from "classnames";
import { VAULT_FREEMIUM_LIMIT } from "./vault-utils";
import { useSelector } from "react-redux";
import { PortfolioState } from "../../types/portfolioTypes";
import dayjs from "dayjs";

const VaultNoteItem = ({
  note,
  note_index,
  openNoteModal,
  openMoveDocumentModal,
  setConfirmDelete,
}: {
  note: any;
  note_index: number;
  openNoteModal: (note: any) => void;
  openMoveDocumentModal: (type: "file" | "note", id: string) => void;
  setConfirmDelete: (confirmDelete: any) => void;
}) => {
  const subscription = useSelector(
    (state: { portfolios: { portfolio: PortfolioState } }) => state.portfolios.portfolio.subscription
  );
  const noteName = subStringofName(note.title, 37);
  const noteTimestamp = dayjs(note.updated_at ?? note.created_at).format("D/MM/YYYY");

  return (
    <div
      key={`note-${note_index}`}
      className={classNames(
        "w-full md:w-48",
        "p-5 rounded-md",
        "border border-1 border-solid border-gray-200 hover:border-blue-400",
        "flex flex-col",
        "gap-y-4",
        !subscription ||
          (subscription &&
            subscription.status !== SubscriptionStatus.ACTIVE &&
            note_index >= VAULT_FREEMIUM_LIMIT &&
            "opacity-50 cursor-not-allowed")
      )}
    >
      <div className="flex justify-between">
        {/* Icon */}
        <div
          className={classNames(
            !subscription || (subscription.status !== SubscriptionStatus.ACTIVE && note_index >= VAULT_FREEMIUM_LIMIT)
              ? "cursor-not-allowed"
              : "pointer"
          )}
        >
          <VaultNoteIcon />
        </div>

        {/* Dropdown */}
        <VaultDocumentDropdown
          openEditNoteModal={() => openNoteModal(note)}
          openMoveModal={() => openMoveDocumentModal("note", note.id)}
          openDeleteModal={() => {
            setConfirmDelete({
              type: "note",
              item: note,
            });
          }}
          fileId={note?.id}
          name={subStringofName(note?.title, 37)}
          type="note"
          disabled={
            (!subscription || (subscription && subscription.status !== SubscriptionStatus.ACTIVE)) &&
            note_index >= VAULT_FREEMIUM_LIMIT
          }
        />
      </div>

      {/* Info */}
      <div>
        <p className="font-semibold font-medium text-xs leading-normal text-gray-700 mb-3 break-all">{noteName}</p>
        <p className="font-normal font-medium text-xs text-gray-600 leading-tight">{noteTimestamp}</p>
      </div>
    </div>
  );
};

export default VaultNoteItem;
