import { useState } from "react";
import Modal from "react-modal";
import MainButton from "../../buttons/mainButton";
import { ReactComponent as Whitetick } from "../../../assets/svg/whitetick.svg";
import { Plan, PlanIntervals } from "../../../types/subscriptionTypes";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface IChangeBillingType {
  closeModal: () => void;
  showChangeBilling: boolean;
  plans: Plan[];
  changePlan: (id: string) => void;
  currentPlanId: string;
}

const ChangeBillingType = ({ closeModal, showChangeBilling, changePlan, plans, currentPlanId }: IChangeBillingType) => {
  const isNative = Capacitor.isNativePlatform();
  const [select, setSelect] = useState(currentPlanId);

  return (
    <Modal
      isOpen={showChangeBilling}
      onRequestClose={closeModal}
      contentLabel="Change Plan Modal"
      className={classNames("modal-container", isNative && "native-modal")}
      overlayClassName={"modal-container-overlay"}
    >
      {/* Header */}
      <div className="modal-header px-4 flex items-center justify-between">
        <h1 className="text-h4 font-semibold">Change your Billing Type</h1>
        <button type="button" onClick={closeModal} className="cursor-pointer">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>

      {/* Body */}
      <div className="modal-body px-4 md:px-8 pt-5 pb-10">
        <p className="p-normal benmessage mb-5 text-type-100 text-p2">
          The new subscription will start after your current billing expires.
        </p>

        {plans.slice(0, 6).map((plan: Plan) => (
          <SinglePlan
            key={plan.id}
            text={`${plan.interval === PlanIntervals.DAY ? "dai" : plan.interval}ly billing - ${plan.currency.symbol}${
              plan.amount / 100
            } ${plan.interval === PlanIntervals.YEAR ? "(save $20)" : ""}`}
            current={plan.id === currentPlanId}
            click={() => setSelect(plan.id)}
            selected={select === plan.id}
          />
        ))}
      </div>

      {/* Footer */}
      <div className="modal-footer p-4 gap-4 flex justify-end items-center border-t bg-white">
        <MainButton type="secondary" size="small" click={closeModal}>
          Cancel
        </MainButton>
        <MainButton
          type="primary"
          size="small"
          click={() => changePlan(select)}
          disabled={select === currentPlanId || select === ""}
        >
          Change Billing
        </MainButton>
      </div>
    </Modal>
  );
};

const SinglePlan = ({ text, current, selected, click }: any) => {
  return (
    <div
      className={`w-full my-2.5 ${
        selected ? "border-primary-beau bg-primary-alice" : "border-neutral-100 bg-white"
      }  border rounded my-2.5 text-p2 text-type px-4 py-4 flex transition-all justify-between items-center cursor-pointer`}
      onClick={click}
    >
      <div className="flex justify-start items-center font-medium capitalize">
        {text}{" "}
        {current && <p className=" text-type bg-primary-alice px-1 py-0.5 text-p3 rounded ml-1.5">Current plan</p>}
      </div>
      <div
        className={`w-5 h-5 rounded-full flex justify-center items-center  ${
          selected ? "bg-primary border-primary" : "bg-neutral-100 border-neutral-100"
        }`}
      >
        {selected && <Whitetick />}
      </div>
    </div>
  );
};

export default ChangeBillingType;
