import { useState } from "react";
import "./auth.scss";
import { Authheader } from "./authheader";
import { TextinputwithLeftIcon } from "../../components/inputs/textinputwithlefticon";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { loaderData, setUtilityData } from "../../store/actions/utility";
import { useDispatch } from "react-redux";
import { Errornotf } from "../../components/notifications/errornotf";
import queryString from "query-string";
import { toast } from "react-toastify";
import { SuccessToast } from "../../components/notifications/toasts";
import { Helmet } from "react-helmet";
import MainButton from "../../components/buttons/mainButton";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "../../graphql/auth";
import { useTranslation } from "react-i18next";

export const SetPasswordpage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted(data: any) {
      navigate("/signin");
      toast(<SuccessToast message={"Your password was updated successfully."} />, {
        className: "custom-toast-success",
      });
      dispatch(setUtilityData(loaderData(false, "")));
    },
    onError(error: any) {
      seterrorMessage(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  let parseddata = queryString.parse(location.search);

  const [errorMessage, seterrorMessage] = useState(null);
  const [ispassword, setispassword] = useState(true);

  const [ispassword2, setispassword2] = useState(true);

  const { handleSubmit, errors, control, getValues } = useForm({ mode: "onChange" });
  const changeinputField = () => {
    setispassword(!ispassword);
  };

  const changeinputField2 = () => {
    setispassword2(!ispassword2);
  };
  const onSubmit = (data: any) => {
    dispatch(setUtilityData(loaderData(true, "")));
    resetPassword({
      variables: {
        resetPasswordInput: {
          email: parseddata.email,
          password: data.password,
          token: parseddata.token,
        },
      },
    });
  };

  return (
    <div className="authpage">
      <Helmet>
        <title>Create A Password</title>
        <meta name="description" content={`Create a secure password to your ${t("orgName")} account`} />
        <meta property="og:title" content="Create A Password" />
        <meta property="og:description" content={`Create a secure password to your ${t("orgName")} account`} />
        <meta property="og:url" content="https://app.getcova.com/set-password" />
      </Helmet>
      <Authheader />
      <div className="forgotpassword">
        <div className="inner">
          <p className="text-h3 font-medium">Reset your Password</p>
          <Errornotf style={{ marginBottom: "20px", marginTop: "40px" }} message={errorMessage} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="my-9">
              <div className="mb-4">
                <Controller
                  name="password"
                  defaultValue={""}
                  rules={{ required: true }}
                  control={control}
                  render={(props) => (
                    <TextinputwithLeftIcon
                      onChange={(e: any) => {
                        props.onChange(e.target.value);
                      }}
                      onclickicon={changeinputField}
                      checked={props.value}
                      label="New Password"
                      name="password"
                      inputid="password"
                      type={ispassword ? "password" : "text"}
                      iserror={errors.password}
                      message={"This field is required"}
                    />
                  )}
                />
              </div>
              <div className="mb-4">
                <Controller
                  name="newpassword"
                  defaultValue={""}
                  control={control}
                  rules={{
                    validate: {
                      passValid: (value) => {
                        return value === getValues().password || "Password doesn't match";
                      },
                    },
                  }}
                  render={(props) => (
                    <TextinputwithLeftIcon
                      onChange={(e: any) => {
                        props.onChange(e.target.value);
                      }}
                      onclickicon={changeinputField2}
                      checked={props.value}
                      label="Re-enter New Password"
                      name="newpassword"
                      inputid="newpassword"
                      type={ispassword2 ? "password" : "text"}
                      iserror={errors.newpassword}
                      message={"Password fields don't match"}
                    />
                  )}
                />
              </div>
            </div>
            <div className="mb-4">
              <MainButton size="big" type="primary" extraClasses="w-full">
                <span className="text-p1">Set Password</span>
              </MainButton>
            </div>
          </form>
          <div>
            <p className="text-center  text-p2 text-type w-11/12 mx-auto">
              Go back to{" "}
              <Link to="/signin" className="cursor-pointer text-primary font-semibold text-center">
                Sign In
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
