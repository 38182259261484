import Modal from "react-modal";
import { useEffect, useState } from "react";
import { Textinput } from "../inputs/textinput";
import { ALLCOUNTRIESDATA } from "../../config/countries";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import MainButton from "../buttons/mainButton";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const SelectCountryCodeModal = ({ modalIsOpen, afterOpenModal, closeModal, customStyles, setCodeData }: any) => {
  const [countriesd, setcountries] = useState([]);
  const [countriesd2, setcountries2] = useState([]);
  useEffect(() => {
    let allthecountries = [];
    for (let country in ALLCOUNTRIESDATA.countries) {
      let acountry = {
        // @ts-expect-error
        ...ALLCOUNTRIESDATA.countries[country],
        shortName: country,
      };

      allthecountries.push(acountry);
      //@ts-expect-error
      setcountries(allthecountries);

      //@ts-expect-error
      setcountries2(allthecountries);
    }
  }, []);
  const searchList = (e: any) => {
    let value = e.target.value;
    let searchResult = countriesd.filter(
      (country: any) => country.name.toLowerCase().indexOf(value.toLowerCase()) > -1
    );
    setcountries2(searchResult);
  };

  const isNative = Capacitor.isNativePlatform();

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Select Country Code Modal"
      className={classNames("modal-container", isNative && "native-modal")}
      overlayClassName={"modal-container-overlay"}
    >
      {/* Header */}
      <div className="modal-header px-4 flex items-center justify-between">
        <p className="text-h4 font-semibold">Select Country Code</p>
        <button type="button" onClick={closeModal} className="cursor-pointer">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>

      {/* Body */}
      <div className="modal-body px-4 md:px-8 pb-10 searchcountry">
        <div className="searchinput-t1">
          <Textinput onChange={searchList} label="" inputid="name" type="text" placeholder="Search Countries" />
        </div>
        <div>
          {countriesd2.map((country: any, index) => {
            return (
              <div
                key={`country-${index}`}
                className="hover:bg-gray-100 cursor-pointer flex justify-between items-center p-4 border-b"
                onClick={() => {
                  setCodeData(country);
                }}
              >
                <p className="text-sm font-semibold">
                  {country.name} <span className="">{country.emoji}</span>
                </p>
                <p className="text-sm">+ {country.phone}</p>
              </div>
            );
          })}
        </div>
      </div>

      {/* Footer */}
      <div className="modal-footer p-4 gap-3 flex justify-end border-t bg-white">
        <MainButton type="secondary" size="small" click={closeModal}>
          Cancel
        </MainButton>
      </div>
    </Modal>
  );
};
