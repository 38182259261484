export const RESET_ASSETDATA = "RESET_ASSETDATA";
export const ADD_BANK_ASSETDATA = "ADD_BANK_ASSETDATA";
export const ADD_CRYPTO_ASSETDATA = "ADD_CRYPTO_ASSETDATA";
export const ADD_STOCKS_ASSETDATA = "ADD_STOCKS_ASSETDATA";
export const ADD_CUSTOM_ASSETDATA = "ADD_CUSTOM_ASSETDATA";
export const ADD_FILES_TO_ASSETS = "ADD_FILES_TO_ASSETS";
export const SET_CURRENCY_DATA = "SET_CURRENCY_DATA";
export const ADD_FILES = "ADD_FILES";
export const DELETE_FILES = "DELETE_FILES";
export const SET_COUNTRY_CURRENCY = "SET_COUNTRY_CURRENCY";
export const EDIT_GENERAL_ASSETDATA = "EDIT_GENERAL_ASSETDATA";
export const CREATE_ASSETS_FOLDERS = "CREATE_ASSETS_FOLDERS";
export const ADD_STARTUP_ASSETDATA = "ADD_STARTUP_ASSETDATA";
export const EDIT_ASSET_NOTE = "EDIT_ASSET_NOTE";
