import React, { Fragment, useState, useEffect } from "react";
import MainButton from "../../buttons/mainButton";
import AddBankAccount from "./PanelManualAddBankAccount";
import AddCreditCard from "./PanelManualAddCreditCard";
import AddLoan from "./PanelManualAddLoan";
import AddCrypto from "./PanelManualAddCrypto";
import AddStock from "./PanelManualAddStock";
import AddStartup from "./PanelManualAddStartup";
import AddOther from "./PanelManualAddOther";
import AddRealEstate from "./PanelManualAddRealEstate";
import { CreateAccountInput, AccountTypes, InvestmentAccountSubtype, Partners } from "../../../types/accountTypes";
import { ManualAccountTypes } from "./manual-types";

interface IEditAccount {
  goBack: () => void;
  account: any;
  updateCustomAccount: (values: CreateAccountInput) => void;
}

const EditAccount = ({ goBack, account, updateCustomAccount }: IEditAccount) => {
  const [accountType, setAccountType] = useState<ManualAccountTypes | null>(null);

  useEffect(() => {
    switch (account.type) {
      case AccountTypes.Depository.toLowerCase():
        setAccountType(ManualAccountTypes.BankAccount);
        break;

      case AccountTypes.Credit.toLowerCase():
        setAccountType(ManualAccountTypes.CreditCard);
        break;

      case AccountTypes.Loan.toLowerCase():
        setAccountType(ManualAccountTypes.Loan);
        break;

      case AccountTypes.Investment.toLowerCase():
        if (account.partner === Partners.COIN_CAP) {
          setAccountType(ManualAccountTypes.Crypto);
          break;
        }

        if (account.partner === Partners.MARKET_STACK) {
          setAccountType(ManualAccountTypes.Stock);
          break;
        }

        if (account.subtype === InvestmentAccountSubtype.RealEstate) {
          setAccountType(ManualAccountTypes.RealEstate);
          break;
        }

        if (account.subtype === InvestmentAccountSubtype.Startup) {
          setAccountType(ManualAccountTypes.Startup);
          break;
        }
        break;

      case AccountTypes.Other.toLowerCase():
        setAccountType(ManualAccountTypes.Other);
        break;

      case AccountTypes.Brokerage.toLowerCase():
        setAccountType(ManualAccountTypes.Stock);
        break;

      default:
        break;
    }
  }, [account]);

  return (
    <Fragment>
      {accountType === ManualAccountTypes.BankAccount && (
        <AddBankAccount isEdit account={account} onClickBack={goBack} submit={updateCustomAccount} />
      )}

      {accountType === ManualAccountTypes.CreditCard && (
        <AddCreditCard isEdit account={account} onClickBack={goBack} submit={updateCustomAccount} />
      )}

      {accountType === ManualAccountTypes.Loan && (
        <AddLoan isEdit account={account} onClickBack={goBack} submit={updateCustomAccount} />
      )}

      {accountType === ManualAccountTypes.Crypto && (
        <AddCrypto isEdit account={account} onClickBack={goBack} submit={updateCustomAccount} />
      )}

      {accountType === ManualAccountTypes.Stock && (
        <AddStock isEdit account={account} onClickBack={goBack} submit={updateCustomAccount} />
      )}

      {accountType === ManualAccountTypes.Startup && (
        <AddStartup isEdit account={account} onClickBack={goBack} submit={updateCustomAccount} />
      )}

      {accountType === ManualAccountTypes.Other && (
        <AddOther isEdit account={account} onClickBack={goBack} submit={updateCustomAccount} />
      )}

      {accountType === ManualAccountTypes.RealEstate && (
        <AddRealEstate isEdit account={account} onClickBack={goBack} submit={updateCustomAccount} />
      )}

      <div className="border-t absolute bottom-0 w-full right-0 p-5 flex justify-end gap-2">
        <MainButton type="secondary" size="small" click={goBack}>
          Go back
        </MainButton>

        <MainButton type="primary" size="small" id="cryptoFormBtn">
          Update Account
        </MainButton>
      </div>
    </Fragment>
  );
};

export default EditAccount;
