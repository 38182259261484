import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUtilityData } from "../store/actions/utility";
import { UserState } from "../types/authTypes";

export interface OnboardingStepsType {
  is2FAauthenticated: boolean;
  isAccountConnected: boolean;
  isRecipientAdded: boolean;
  isVaultConnected: boolean;
  isPortfolioShared: boolean;
}

export const useOnboardingSteps = () => {
  const userClosedOnboarding = useSelector((state: any) => state.utility.userClosedOnboarding);
  const onboardingSteps: OnboardingStepsType = useSelector(
    (state: { user: UserState }) => state.user.currentUser.onboardingSteps
  );
  const { sms_mfa_enabled, totp_mfa_enabled } = useSelector((state: any) => state.currentUser.preferences);
  onboardingSteps.is2FAauthenticated = sms_mfa_enabled || totp_mfa_enabled;
  const [completed, setCompleted] = useState<number>(5);
  const dispatch = useDispatch();

  useEffect(() => {
    calcOnboardingSteps();
    // eslint-disable-next-line
  }, [onboardingSteps]);

  const calcOnboardingSteps = () => {
    const completedSteps = Object.values(onboardingSteps).filter((item) => item === true);
    setCompleted(completedSteps.length);

    if (completedSteps.length < 3 && !userClosedOnboarding) {
      dispatch(setUtilityData({ showOnboardingSteps: true }));
    }
  };
  return { onboardingSteps, completed };
};
