import Modal from "react-modal";
import classNames from "classnames";
import { useState } from "react";
import { useAddAccountModalToggle } from "../../../graphql/general-store.gql-hook";
import { Capacitor } from "@capacitor/core";
import { Categories } from "./Categories";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Manual from "./PanelManual";
import "./connect-account.scss";

enum ViewCategories {
  categories = "categories",
  zillow = "zillow",
  manual = "manual",
}

interface IConnectAccount {
  fromMobileApp?: boolean;
}

const ConnectAccountModal = ({ fromMobileApp }: IConnectAccount) => {
  const [isOpen, openAccountModal] = useAddAccountModalToggle();
  const [activeView, setActiveView] = useState<string>(ViewCategories.categories);
  const [country, setCountry] = useState<string>("USA");
  const [selectedCountry, setSelectedCountry] = useState<string>("US");
  const closeModal = () => (fromMobileApp ? null : openAccountModal(false));
  const isNative = Capacitor.isNativePlatform();

  return (
    <Modal
      isOpen={fromMobileApp ? true : isOpen}
      onRequestClose={closeModal}
      contentLabel="Add Account Modal"
      className={classNames("modal-container max-w-3xl", isNative && "native-modal")}
      overlayClassName={"modal-container-overlay"}
    >
      {!fromMobileApp && (
        <div className="modal-header px-4 flex items-center justify-end">
          <button type="button" onClick={closeModal} className="cursor-pointer">
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>
      )}

      {activeView === ViewCategories.categories && (
        <Categories
          closeModal={closeModal}
          setActiveView={setActiveView}
          country={country}
          setCountry={setCountry}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
        />
      )}

      {activeView === ViewCategories.manual && (
        <Manual closeModal={closeModal} setActiveView={setActiveView} fromMobileApp={fromMobileApp} />
      )}
    </Modal>
  );
};

export default ConnectAccountModal;
