import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Textinput } from "../../components/inputs/textinput";
import { loaderData, setUtilityData } from "../../store/actions/utility";
import { logoutaUser } from "../../store/actions/userdata";
import { toast } from "react-toastify";
import { SuccessToast } from "../../components/notifications/toasts";
import { ChangeEmailModal } from "../portfolio/assetsmodals";
import MainButton from "../../components/buttons/mainButton";
import { ApolloError, useMutation } from "@apollo/client";
import useAuth from "../../hooks/useAuth";
import { VERIFY_EMAIL_UPDATE } from "../../graphql/auth";
import { useNavigate } from "react-router-dom";
import { useUpdateUserAvatarMutation, useUpdateUserdataMutation } from "../../api/user/user-mutations";
import { useUserQuery } from "../../api/user/user-queries";
import UserAvatar from "../../components/user/Avatar";

export const UserProfileTab = () => {
  const { data: user } = useUserQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleSubmit, errors, control, setValue } = useForm({
    criteriaMode: "all",
    mode: "onChange",
  });
  const [showUpdateEmailModal, setShowUpdateEmailModal] = useState<boolean>(false);
  const { getCurrentUser } = useAuth();

  useEffect(() => {
    if (user?.first_name) {
      setValue("first_name", user?.first_name);
    }
    if (user?.last_name) {
      setValue("last_name", user?.last_name);
    }
  }, [user?.first_name, user?.last_name, setValue]); // include the dependencies here

  const [verifyemailchange] = useMutation(VERIFY_EMAIL_UPDATE, {
    onCompleted() {
      setShowUpdateEmailModal(false);
      getCurrentUser();
      toast(<SuccessToast message="Email updated successfully" />, {
        className: "custom-toast-success",
      });
      dispatch(setUtilityData(loaderData(false, "")));
      dispatch(logoutaUser({}))
        // @ts-expect-error
        .then(() => {
          navigate("/signin");
        });
    },
    onError(error: ApolloError) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  const verifyEmail = (code: string, email: string) => {
    verifyemailchange({
      variables: {
        verifyInput: {
          code,
          email,
        },
      },
    });
  };

  const { mutateAsync: updateUserdata } = useUpdateUserdataMutation();
  const { mutateAsync: updateUserAvatar, isLoading: isLoadingAvatar } = useUpdateUserAvatarMutation();

  const onSubmitUsers = async (data: { first_name: string; last_name: string }) => {
    try {
      await updateUserdata(data);
    } catch (error) {
      console.error("fail: onSubmitUsers");
    }
  };

  let uploadImage = () => {
    let x: any = document.querySelector(".image123");
    x.click();
  };

  let onChange = async (files: any) => {
    try {
      await updateUserAvatar({
        file: files.target.files[0],
        updateDataInput: {},
      });
    } catch (error) {
      console.error("fail: onChange");
    }
  };

  return (
    <div className="userprofile-tab">
      <ChangeEmailModal
        modalIsOpen={showUpdateEmailModal}
        closeModal={() => setShowUpdateEmailModal(false)}
        verifyEmail={verifyEmail}
      />
      <div className="flex justify-center items-center sm:justify-start gap-x-4">
        <UserAvatar avatarUrl={user?.image_url} size="lg" onClickAvatar={uploadImage} />
        <p onClick={uploadImage} className="mt-1 text-blue-700 font-semibold cursor-pointer">
          {user.image_url ? "Change Image" : "Upload Image"}
        </p>
        <input
          className="image123"
          style={{ display: "none" }}
          type="file"
          accept="image/png, image/jpeg"
          id="image123"
          name="myImage"
          onChange={onChange}
        />
      </div>

      <div className="usernameform">
        <form onSubmit={handleSubmit(onSubmitUsers)}>
          <div className="form-control">
            <Controller
              name="first_name"
              defaultValue={user?.first_name}
              rules={{ required: true }}
              control={control}
              render={(props) => (
                <Textinput
                  onChange={(e: any) => {
                    props.onChange(e.target.value);
                  }}
                  value={props.value}
                  label="First Name"
                  inputid="firstname"
                  name="firstname"
                  type="text"
                  iserror={errors.firstName}
                  message={"This field is required"}
                />
              )}
            />
          </div>
          <div className="form-control">
            <Controller
              name="last_name"
              defaultValue={user?.last_name}
              rules={{ required: true }}
              control={control}
              render={(props) => (
                <Textinput
                  onChange={(e: any) => {
                    props.onChange(e.target.value);
                  }}
                  value={props.value}
                  label="Last Name"
                  inputid="lastname"
                  name="lastname"
                  type="text"
                  iserror={errors.lastName}
                  message={"This field is required"}
                />
              )}
            />
          </div>

          <div className="mb-2">
            <button
              onClick={handleSubmit(onSubmitUsers)}
              type="submit"
              id="login-submit"
              className="btn-primary btn-md "
              disabled={isLoadingAvatar}
            >
              Save
            </button>
          </div>
        </form>
      </div>

      <div className="changeemailform">
        {/* <div className="header1 pheader"> */}
        <p className="text-p2 font-medium text-type">My Email</p>
        {/* </div> */}
        <div className="emailformcontainer">
          <div className="inputholder12">
            <div className="form-control">
              <Textinput
                style={{ color: "#808386" }}
                disabled
                inputid="email"
                value={user.email}
                name="email"
                type="email"
              />
            </div>
          </div>

          <MainButton type="primary" size="small" click={() => setShowUpdateEmailModal(true)}>
            Change Email
          </MainButton>
        </div>
      </div>
    </div>
  );
};
