import { useState } from "react";
import { print } from "graphql";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../../hooks/useAPI";
import ActionStateToast from "../../components/ActionStateToast";
import {
  CREATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  REMOVE_PAYMENT_METHOD,
  CREATE_STRIPE_INTENT,
  CONVERT_STRIPE_INTENT_TO_PAYMENT_METHOD,
} from "../../graphql/subscription";
import { useSelector } from "react-redux";

export const useUpdateSubscriptionMutation = () => {
  const queryClient = useQueryClient();
  const Api = useApi();

  const { id: activePortfolioId } = useSelector((state: any) => state.activePortfolio);
  const [updateSubToast, setUpdateSubToast] = useState<any>();

  return useMutation(
    async (variables: any) => {
      const payload = {
        operationName: "UpdateSubscription",
        variables: {
          updateInfo: variables,
        },
        query: print(UPDATE_SUBSCRIPTION),
      };
      const data = await Api.post("", JSON.stringify(payload));

      const hasErrors = data?.data?.errors?.length > 0;
      if (hasErrors) {
        throw new Error(data?.data?.errors[0]?.message);
      }

      return data?.data?.data?.updateSubscription;
    },
    {
      onMutate: () => {
        setUpdateSubToast(
          toast(<ActionStateToast message="Updating subscription..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: (data) => {
        toast.dismiss(updateSubToast);
        queryClient.invalidateQueries(["user"]);
        queryClient.invalidateQueries([`X_FETCH_SUBSCRIPTION_${activePortfolioId}`]);
        toast("Subscription updated!", {
          autoClose: 3000,
          type: "success",
        });
      },
      onError: (error) => {
        toast.dismiss(updateSubToast);
        toast("Something went wrong..", {
          autoClose: 3000,
          type: "error",
        });
        console.error("fail: useUpdateSubscriptionMutation", error);
      },
    }
  );
};

export const useCreateSubscriptionMutation = () => {
  const queryClient = useQueryClient();
  const Api = useApi();
  const [createSubToast, setCreateSubToast] = useState<any>();
  const { id: activePortfolioId } = useSelector((state: any) => state.activePortfolio);

  return useMutation(
    async (planId: string) => {
      const payload = {
        operationName: "CreateSubscription",
        variables: {
          planId,
        },
        query: print(CREATE_SUBSCRIPTION),
      };
      const data = await Api.post("", JSON.stringify(payload));

      const hasErrors = data?.data?.errors?.length > 0;
      if (hasErrors) {
        throw new Error(data?.data?.errors[0]?.message);
      }

      return data?.data?.data?.createSubscription;
    },
    {
      onMutate: () => {
        setCreateSubToast(
          toast(<ActionStateToast message="Creating subscription..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: (data) => {
        toast.dismiss(createSubToast);
        queryClient.invalidateQueries(["user"]);
        queryClient.invalidateQueries([`X_FETCH_SUBSCRIPTION_${activePortfolioId}`]);
        toast("Subscription created!", {
          autoClose: 3000,
          type: "success",
        });
      },
      onError: (error) => {
        toast.dismiss(createSubToast);
        toast("Something went wrong..", {
          autoClose: 3000,
          type: "error",
        });
        console.error("fail: useCreateSubscriptionMutation", error);
      },
    }
  );
};

export const useRemovePaymentMethodMutation = () => {
  const queryClient = useQueryClient();
  const Api = useApi();
  const [removePaymentMethodToast, setRemovePaymentMethodToast] = useState<any>();

  return useMutation(
    async (removePaymentMethodId: string) => {
      const payload = {
        operationName: "RemovePaymentMethod",
        variables: {
          removePaymentMethodId,
        },
        query: print(REMOVE_PAYMENT_METHOD),
      };
      const data = await Api.post("", JSON.stringify(payload));

      const hasErrors = data?.data?.errors?.length > 0;
      if (hasErrors) {
        throw new Error(data?.data?.errors[0]?.message);
      }

      return data?.data?.data?.removePaymentMethod;
    },
    {
      onMutate: () => {
        setRemovePaymentMethodToast(
          toast(<ActionStateToast message="Removing payment method..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: (data) => {
        toast.dismiss(removePaymentMethodToast);
        queryClient.invalidateQueries(["user"]);
        toast("Payment method removed!", {
          autoClose: 3000,
          type: "success",
        });
      },
      onError: (error) => {
        toast.dismiss(removePaymentMethodToast);
        toast("Something went wrong..", {
          autoClose: 3000,
          type: "error",
        });
        console.error("fail: useRemovePaymentMethodMutation", error);
      },
    }
  );
};

export const useCreateStripeSetupIntentMutation = () => {
  const queryClient = useQueryClient();
  const Api = useApi();

  return useMutation(
    async () => {
      const payload = {
        operationName: "CreateStripeSetupIntent",
        query: print(CREATE_STRIPE_INTENT),
      };
      const data = await Api.post("", JSON.stringify(payload));

      const hasErrors = data?.data?.errors?.length > 0;
      if (hasErrors) {
        throw new Error(data?.data?.errors[0]?.message);
      }

      return data?.data?.data?.createStripeSetupIntent;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user"]);
      },
      onError: (error) => {
        console.error("fail: useCreateStripeSetupIntent", error);
      },
    }
  );
};

export const useConvertStripeSetupIntentToPaymentMethodMutation = () => {
  const queryClient = useQueryClient();
  const Api = useApi();

  return useMutation(
    async (setupIntentId: string) => {
      const payload = {
        operationName: "ConvertStripeSetupIntentToPaymentMethod",
        variables: {
          setupIntentId,
        },
        query: print(CONVERT_STRIPE_INTENT_TO_PAYMENT_METHOD),
      };
      const data = await Api.post("", JSON.stringify(payload));

      const hasErrors = data?.data?.errors?.length > 0;
      if (hasErrors) {
        throw new Error(data?.data?.errors[0]?.message);
      }

      return data?.data?.data?.convertStripeSetupIntentToPaymentMethod;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user"]);
        toast("Payment method added!", {
          autoClose: 3000,
          type: "success",
        });
      },
      onError: (error) => {
        console.error("fail: useConvertStripeSetupIntentToPaymentMethod", error);
      },
    }
  );
};
