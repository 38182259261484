import classNames from "classnames";

export const ToggleSwitch = ({
  isActive,
  toggleAction,
  disabled = false,
}: {
  isActive: boolean;
  toggleAction: () => void;
  disabled?: boolean;
}) => {
  return (
    <div className="toggle-switch h-5">
      <input type="checkbox" id="toggleSwitch" name="toggleSwitch" className="hidden" disabled={disabled} />
      <label
        onClick={toggleAction}
        htmlFor="toggleSwitch"
        className={classNames(disabled ? "cursor-not-allowed" : "cursor-pointer")}
      >
        <span className={`${isActive ? "active-track" : "inactive-track"}`}></span>
      </label>
    </div>
  );
};
