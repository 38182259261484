import Skeleton from "react-loading-skeleton";
import { getNumberBetween } from "../../components/utilities/utilityFunctions";

const DashboardNetworthHistoryCardSkeleton = () => (
  <div>
    <Skeleton width={getNumberBetween(200, 320)} height={18} className="mb-2" />
    <Skeleton width="100%" height={300} />
  </div>
);

export default DashboardNetworthHistoryCardSkeleton;
