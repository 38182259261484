import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import { ReactComponent as PremiumCrown } from "../../assets/svg/premium-crown.svg";

export const AssetTab = ({ Icon, title, description, action, disabled, premium }: any) => (
  <button
    onClick={() => {
      if (!premium || !disabled) {
        action();
      }
    }}
    className={classNames(
      "relative w-full flex items-center",
      "px-4 py-2 gap-x-5",
      "border border-gray-200 rounded-md shadow-sm",
      "text-left",
      disabled && "cursor-not-allowed"
    )}
    data-tip=""
    data-for={premium && "premiumTip"}
  >
    <ReactTooltip id="premiumTip" place="bottom" effect="solid">
      Available to premium users only.
    </ReactTooltip>

    {premium && <PremiumCrown className="absolute right-3 top-3" />}

    <div className={classNames("py-3", disabled && "opacity-50 cursor-not-allowed")}>
      <Icon height="32" width="32" />
    </div>

    <div className="my-2">
      <h3 className={classNames("text-sm font-medium mb-1", disabled && "opacity-50")}>{title}</h3>
      <p className={classNames("text-xs text-gray-500 line-clamp-2 max-w-md ", disabled && "opacity-50")}>
        {description}
      </p>
    </div>
  </button>
);
