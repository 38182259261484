import { gql } from "@apollo/client";

const COMMON_FIELDS_FRAGMENT = gql`
  fragment CommonFields on Contact {
    first_name
    email
    id
    last_name
    phone_number
    phone_number_country_code
    lifecheck_steps {
      action
      created_at
      custom_note
      id
      portfolio_id
      sort_index
      trigger_after
      trigger_after_unit
      updated_at
    }
    security_questions {
      answer
      question
    }
  }
`;

export const X_FETCH_CONTACTS = gql`
  query FetchContacts($id: Int!, $search: String) {
    portfolio(id: $id) {
      contacts(search: $search) {
        ...CommonFields
      }
    }
  }

  ${COMMON_FIELDS_FRAGMENT}
`;

export const UPDATE_CONTACT_GQL = gql`
  mutation UpdateContact($contact: UpdateContactInput!) {
    contact: updateContact(contact: $contact) {
      ...CommonFields
    }
  }

  ${COMMON_FIELDS_FRAGMENT}
`;

export const ADD_CONTACT_GQL = gql`
  mutation CreateContact($contact: CreateContactInput!) {
    contact: createContact(contact: $contact) {
      ...CommonFields
    }
  }

  ${COMMON_FIELDS_FRAGMENT}
`;

export const REMOVE_CONTACT_GQL = gql`
  mutation RemoveContact($removeContactId: String!) {
    contactId: removeContact(id: $removeContactId)
  }
`;
