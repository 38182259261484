import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import usePortfolio from "../../../hooks/usePortfolio";
import MainButton from "../../buttons/mainButton";
import { ReactComponent as Plus } from "../../../assets/svg/plus-blue.svg";
import { ConfirmationModal } from "../ConfirmationModal";
import GirlwithFlower from "../../../assets/svg/girlwithflower.svg";
import { AccountType } from "../../../types/accountTypes";
import { LabelType } from "../../../types/categoryTypes";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import "./labels.scss";

interface IEditAccountLabels {
  closeModal: () => void;
  isOpen: boolean;
  label: LabelType;
  handleAddNewLabel: () => void;
  setIsChoosen?: any;
}
const EditAccountLabels: React.FC<IEditAccountLabels> = ({
  closeModal,
  isOpen,
  handleAddNewLabel,
  label,
  setIsChoosen,
}) => {
  const [targetLabel, setTargetLabel] = useState<string>("");
  const { handleUpdateLabel, handleAccountLabel } = usePortfolio();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [accountChoosen, setAccountChoosen] = useState<any>({});

  useEffect(() => {
    setTargetLabel(label.name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveLabels = () => {
    handleUpdateLabel(targetLabel, label.id);
    // closeModal();
  };

  const handleAccountRemoval = (account: AccountType) => {
    setAccountChoosen(account);
    setDeleteModal(true);
  };
  const handleAccountLabelChange = async () => {
    if (accountChoosen.id) {
      await handleAccountLabel(accountChoosen.id, null);
      setDeleteModal(false);
      setIsChoosen({});
      closeModal();
    }
    return;
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      className={"viewassetsdetails postionmoal"}
      overlayClassName={"modal-container-overlay"}
    >
      {deleteModal && (
        <ConfirmationModal
          key={Math.random()}
          modalIsOpen={deleteModal}
          closeModal={() => {
            setDeleteModal(false);
          }}
          submitAction={() => {
            handleAccountLabelChange();
          }}
          confirmationText={
            <span>
              Remove {<span className="capitalize font-bold">{accountChoosen.name}</span>} from {targetLabel}.
            </span>
          }
        />
      )}
      <button type="button" onClick={closeModal} className="closeicon cursor-pointer flex justify-end my-3 w-full">
        <XMarkIcon className="h-5 w-5" />
      </button>
      <h2 className="header-assetsheader h4-semibold">Edit Label</h2>
      <div className="mb-8">
        <h3 className="font-bold text-p2 my-4">Label</h3>
        <div className="border-neutral-200 p-3 rounded-md bg-neutral-300 flex justify-start items-center">
          {label && targetLabel !== label.name && (
            <del className="inline-block text-p2 text-type-300 mr-3">{label.name}</del>
          )}
          <input
            type="text"
            id="label"
            className={`text-p2 bg-transparent border-none focus:border rounded-lg p-1 ${
              label && targetLabel === label.name ? "w-full" : "w-auto"
            }`}
            placeholder="Create or search label name"
            value={targetLabel}
            onChange={(e) => setTargetLabel(e.target.value)}
          />
        </div>
        <div className="flex justify-end my-4">
          <button className="flex items-center text-primary font-semibold" onClick={handleAddNewLabel}>
            <Plus />
            Add a new label
          </button>
        </div>

        <div>
          {label?.accounts?.length > 0 && (
            <>
              <h3 className="font-bold text-p2 mt-6">Accounts in this label</h3>
              <p className="text-p3 mb-3">Removing an account from this label does not mean the account is deleted</p>
            </>
          )}
          <div className="overflow-y-auto accounts-label">
            {label?.accounts?.length > 0 ? (
              label?.accounts?.map((account: AccountType) => (
                <div
                  key={Math.random()}
                  className="p-2 flex justify-between items-center rounded-md my-1 capitalize border gap-2"
                >
                  <span className="text-p2">{account.name}</span>
                  <button className="brightness-0" onClick={() => handleAccountRemoval(account)}>
                    <TrashIcon className="w-5 h-5" />
                  </button>
                </div>
              ))
            ) : (
              <div>
                <p className="text-center mt-4">There are no accounts in this label</p>
                <img src={GirlwithFlower} alt="" width={120} height={120} className="block mx-auto mt-6" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex bg-white justify-end gap-3 border-t mt-8 absolute bottom-0 w-full right-0 p-6">
        <MainButton type="secondary" size="small" click={closeModal}>
          Go Back
        </MainButton>
        <MainButton type="primary" size="small" click={handleSaveLabels}>
          Save
        </MainButton>
      </div>
    </ReactModal>
  );
};
export default EditAccountLabels;
