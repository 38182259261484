import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider, DefaultOptions } from "@apollo/client";
import Modal from "react-modal";
import { ForgotPasswordpage } from "../../pages/auth/forgotpasswordpage";
import { Loginpage } from "../../pages/auth/login";
import { PrivacyPage } from "../../pages/auth/privacypage";
import { SetPasswordpage } from "../../pages/auth/setforgotpassword";
import { Signuppage } from "../../pages/auth/signuppage";
import { TermsandConditionPage } from "../../pages/auth/termsandcondition";
import { Verifyemailpage } from "../../pages/auth/verifyemailpage";
import VerifySecurityQuestion from "../../pages/lifecheck/verifysecurityquestion";
import { VerifyPortfolioOtp } from "../../pages/collaborations/auth/verifyportfoliootp";
import { Security } from "../../pages/auth/security";
import { APPCONFIG } from "../../config";
import { createUploadLink } from "apollo-upload-client";
import LoggedIn from "../loggedin";
import { UserState } from "../../types/authTypes";
import { logoutaUser } from "../../store/actions/userdata";
import MobileConnection from "../../pages/portfolio/mobile-connection";
import { WelcomePage } from "../../pages/auth/welcome";
import { SplashScreen } from "@capacitor/splash-screen";
import { Capacitor } from "@capacitor/core";
import { useNavigate } from "react-router-dom";

Modal.setAppElement("#root");

export const MyRouter = () => {
  const token = useSelector((state: { user: UserState }) => state.user.token);
  const { token: oldToken } = useSelector((state: any) => state.userdata);
  const dispatch = useDispatch();

  useEffect(() => {
    if (oldToken) {
      localStorage.clear();
      sessionStorage.clear();
      dispatch(logoutaUser({}));
      setTimeout(() => {
        window.location.reload();
      }, 200);
    }
    // eslint-disable-next-line
  }, [oldToken]);

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  };

  const getHeaders = () => {
    const headers: any = {};
    if (token) {
      headers.authorization = `Bearer ${token}`;
    }
    return headers;
  };

  const client = new ApolloClient({
    //@ts-ignore
    link: createUploadLink({
      uri: process.env.REACT_APP_GRAPHQL_BASE_URI,
      headers: getHeaders(),
    }),
    cache: new InMemoryCache({
      addTypename: false,
    }),
    connectToDevTools: true,
    defaultOptions: defaultOptions,
    name: "web",
    version: "",
  });

  useEffect(() => {
    const showSplashScreen = async () => {
      await SplashScreen.show({
        showDuration: 1500,
        autoHide: true,
      });
      localStorage.setItem("didShowSplash", "true");
    };

    if (!Capacitor.isNativePlatform()) return;
    const didShowSplash = localStorage.getItem("didShowSplash") && localStorage.getItem("didShowSplash") === "true";
    if (didShowSplash) return;
    showSplashScreen();
  }, []);

  return (
    <>
      <ApolloProvider client={client}>
        <Router>
          <Routes>
            <Route path="/signin" element={<Loginpage />} />
            <Route path="/signup" element={<Signuppage />} />
            <Route path="/welcome" element={<WelcomePage />} />
            <Route
              path="/forgot-password"
              element={token ? <Navigate replace to="/portfolio/institutions" /> : <ForgotPasswordpage />}
            />
            <Route
              path="/verify-email"
              element={token ? <Navigate replace to="/portfolio/institutions" /> : <Verifyemailpage />}
            />
            <Route
              path="/set-password"
              element={token ? <Navigate replace to="/portfolio/institutions" /> : <SetPasswordpage />}
            />
            <Route path="/verify-portfolio-access" element={<VerifyPortfolioOtp />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/terms-and-condition" element={<TermsandConditionPage />} />
            <Route path="/security" element={<Security />} />
            <Route path="/verify-security-question" element={<VerifySecurityQuestion />} />
            <Route path="/mobile-connection" element={<MobileConnection />} />

            {/* Logged In Routes */}
            <Route path="/*" element={<RouterComp />} />

            {/* Not Found */}
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
      </ApolloProvider>
    </>
  );
};

const RouterComp = () => {
  const token = useSelector((state: { user: UserState }) => state.user.token);
  const isNative = Capacitor.isNativePlatform();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate(isNative ? "/welcome" : "/signin", {
        state: { from: window.location.href.split(`${APPCONFIG.origin}`)[1] },
      });
    }
  }, [token, navigate, isNative]);

  return token ? <LoggedIn /> : null;
};
