import { ReactNode } from "react";
import classNames from "classnames";
import { useDropzone } from "react-dropzone";

interface VaultFileDropzoneProps {
  onDrop: (acceptedFiles: File[]) => void;
  message?: ReactNode;
  subtitle?: ReactNode;
  children?: ReactNode;
  disabled?: boolean;
}

export const VaultFileDropzone = ({ onDrop, message, subtitle, children, disabled }: VaultFileDropzoneProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, disabled });
  return (
    <div
      className={classNames(
        "border border-dashed",
        `w-full h-96 flex flex-col justify-center rounded-md`,
        isDragActive ? "border-blue-500" : "border-gray-400",
        disabled ? "bg-neutral-200 cursor-default" : "bg-neutral-300 hover:bg-blue-100 cursor-pointer"
      )}
      {...getRootProps()}
    >
      <div className="flex flex-col justify-center h-full gap-y-8 px-14 pb-14 text-center cursor-pointer">
        <input {...getInputProps()} />
        <p className="text-sm text-gray-400 p-mobile">{subtitle}</p>
        <p className="text-sm text-gray-800 p-big ">{message || children}</p>
      </div>
    </div>
  );
};
