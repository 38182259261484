import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { changeVaultPin, setVaultPin, getAllVaultWithPin, sendVaultOTP } from "../../store/actions/vaultaction";
import { setUtilityData, loaderData } from "../../store/actions/utility";
import Modal from "react-modal";
import { SuccessToast } from "../../components/notifications/toasts";
import { ReactComponent as VaultLock } from "../../assets/svg/vaultfirstlock.svg";
import { ReactComponent as Check201 } from "../../assets/svg/successcheck.svg";
import PinInput from "react-pin-input";
import MainButton from "../../components/buttons/mainButton";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const SetPinModal = ({ closeModal }: any) => {
  const [isFirstStage, setIsFirstStage] = useState(true);
  const [isSecondStage, setisSecondStage] = useState(false);

  const [isThirdStage, setisThirdStage] = useState(false);
  const [pin, setPin] = useState("");
  const [pin1, setPin1] = useState("");
  const [errormessage, seterrormessage] = useState("");
  const [errormessage1, seterrormessage1] = useState("");

  const dispatch = useDispatch();

  let updatePin = (value: any) => {
    setPin(value);
  };

  let updatePin1 = (value: any) => {
    setPin1(value);
  };

  const openEnterPin = () => {
    setIsFirstStage(false);
    setisSecondStage(true);
  };

  let submit = () => {
    seterrormessage("");
    seterrormessage1("");
    if (pin && pin.length !== 4) {
      seterrormessage("Enter a valid pin");
      return;
    }
    if (pin1 && pin1.length !== 4) {
      seterrormessage1("Enter a valid pin");
      return;
    }
    if (pin !== pin1) {
      toast.error("Your pins are not identical.");
      return;
    }
    let requestdata = {
      pin: pin,
    };

    dispatch(setUtilityData(loaderData(true, "")));
    dispatch(setVaultPin(requestdata))
      // @ts-expect-error
      .then((data) => {
        dispatch(setUtilityData(loaderData(false, "")));
        setisThirdStage(true);
        setisSecondStage(false);
      })
      .catch((error: any) => {
        dispatch(setUtilityData(loaderData(false, "")));
        toast.error(error.message);
      });
  };

  return (
    <div className="modll12">
      <div className={" securevault   dfit-the-width "}>
        {isFirstStage ? (
          <div className="securevault-s1">
            <div className="securevault-s2"></div>
            <p className="securevault-s6">
              <VaultLock />
            </p>
            <p className="securevault-s3 h4-semibold">Secure your vault first</p>
            <p className="securevault-s4 p-big">
              Your vault PIN let’s you secure access to documents inside your vault. Create a PIN to protect your vault.
            </p>
            <MainButton click={openEnterPin} type="primary" id="add assets" size="big">
              <span>Create a Pin</span>
            </MainButton>
          </div>
        ) : null}

        {isSecondStage ? (
          <div className="">
            <div className="header2">
              <p className="header2-in h4-semibold">Create a Vault PIN</p>
            </div>
            <div>
              <div style={{ marginBottom: "15px" }} className="form-control">
                <p className="pinlabel  p-big">Enter your PIN</p>
                <PinInput
                  type="numeric"
                  inputMode="number"
                  initialValue=""
                  secret
                  focus
                  length={4}
                  onComplete={(value, index) => {
                    updatePin(value);
                  }}
                  onChange={(value, index) => {
                    updatePin(value);
                  }}
                />
                {errormessage ? <small className="helper-text errormessage danger">{errormessage}</small> : null}
              </div>
              <div style={{ marginBottom: "40px" }} className="form-control">
                <p className="pinlabel p-big">Re-enter your PIN</p>
                <PinInput
                  type="numeric"
                  inputMode="number"
                  initialValue=""
                  secret
                  length={4}
                  onComplete={(value, index) => {
                    updatePin1(value);
                  }}
                  onChange={(value, index) => {
                    updatePin1(value);
                  }}
                />
                {errormessage1 ? <small className="helper-text errormessage danger">{errormessage1}</small> : null}
              </div>

              <div style={{ justifyContent: "center" }} className="saveitop">
                {/* <button onClick={closeModal} type="button" id="add assets" className="cancel btn-primary btn-md "><span>Cancel</span></button> */}

                <MainButton click={submit} type="primary" id="add assets" size="big">
                  <span>Save Pin</span>
                </MainButton>
              </div>
            </div>
          </div>
        ) : null}

        {isThirdStage ? (
          <div className="securevault-d6">
            <div className="securevault-s2"></div>

            <p className="securevault-d7">
              {" "}
              <Check201 />
            </p>

            <p className="securevault-d8 h4-semibold">You’re all set!</p>
            <p className="securevault-d9 p-big">
              We will request for your vault PIN everytime you want to access your vault.
            </p>
            <MainButton click={closeModal} type="primary" id="add assets" size="big">
              <span>Done</span>
            </MainButton>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const EnterPin = ({ fetchFiles, forgotPinAction }: any) => {
  const [pin, setPin] = useState("");
  const [errormessage, seterrormessage] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const dispatch = useDispatch();

  let updatePin = (value: any) => {
    setPin(value);
  };
  let submit = () => {
    seterrormessage("");
    if (pin === "" || pin.length !== 4) {
      seterrormessage("Enter a valid pin");
      return;
    }

    let requestdata = {
      pin,
    };

    fetchFiles(requestdata);
  };
  const toggleConfirmModal = () => {
    setConfirmModal((currVal) => !currVal);
  };
  const sendOTP = () => {
    dispatch(setUtilityData(loaderData(true, "")));
    dispatch(sendVaultOTP())
      // @ts-expect-error
      .then(() => {
        dispatch(setUtilityData(loaderData(false, "")));
        toggleConfirmModal();
        forgotPinAction();
      })
      .catch((error: any) => {
        dispatch(setUtilityData(loaderData(false, "")));
        toast.error(error.message);
      });
  };

  return (
    <>
      {confirmModal && (
        <ConfirmActionModal modalIsOpen={confirmModal} closeModal={toggleConfirmModal} submitAction={sendOTP} />
      )}
      <div className="modll12">
        <div className={" securevault  dfit-the-width "}>
          <div className="">
            <div className="header2">
              <p className="header2-in h4-semibold">Enter Vault PIN</p>
            </div>
            <div>
              <form
                onKeyUp={(e) => {
                  if (e.code === "Enter" && (pin !== "" || pin.length === 4)) {
                    submit();
                  }
                }}
                className="form-control mb-9"
              >
                <p className="pinlabel  p-big">Please enter your Vault PIN</p>
                <PinInput
                  type="numeric"
                  inputMode="number"
                  initialValue=""
                  secret
                  focus
                  length={4}
                  onComplete={(value, index) => {
                    updatePin(value);
                  }}
                  onChange={(value, index) => {
                    updatePin(value);
                  }}
                />
                {errormessage ? <small className="helper-text errormessage danger">{errormessage}</small> : null}
              </form>

              <div className="saveitop justify-center">
                <MainButton
                  click={submit}
                  disabled={pin === "" || pin.length !== 4}
                  id="add assets"
                  type="primary"
                  size="big"
                  extraClasses="w-full"
                >
                  <span>Open Vault</span>
                </MainButton>
              </div>
              <div
                onClick={() => setConfirmModal(true)}
                className="w-full mt-5 text-center text-p2 text-primary font-semibold cursor-pointer"
              >
                Forgot Vault Pin?
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ChangePin = ({ modalIsOpen, afterOpenModal, closeModal, customStyles }: any) => {
  const notify = () =>
    toast(<SuccessToast message={"Your vault pin was changed successfully."} />, {
      className: "custom-toast-success",
    });
  const [isFirstStage, setIsFirstStage] = useState(true);
  const [isSecondStage, setisSecondStage] = useState(false);

  const [pin, setPin] = useState("");
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [errormessage, seterrormessage] = useState("");
  const [errormessage1, seterrormessage1] = useState("");
  const [errormessage2, seterrormessage2] = useState("");

  const dispatch = useDispatch();

  const checkEnteredPin = (pinData: any) => {
    dispatch(setUtilityData(loaderData(true, "")));
    dispatch(getAllVaultWithPin(pinData))
      //@ts-expect-error
      .then((data) => {
        dispatch(setUtilityData(loaderData(false, "")));
        setisSecondStage(true);
        setIsFirstStage(false);
      });
  };
  let updatePin = (value: any) => {
    setPin(value);
  };
  let updatePin1 = (value: any) => {
    setPin1(value);
  };
  let updatePin2 = (value: any) => {
    setPin2(value);
  };

  const enterNewPin = () => {
    if (pin === "" || pin.length !== 4) {
      seterrormessage("Enter a valid pin");
      return;
    }
    checkEnteredPin({ pin: pin });
  };

  let submit = () => {
    seterrormessage1("");
    seterrormessage2("");

    if (pin1 === "" || pin1.length !== 4) {
      seterrormessage1("Enter a valid pin");
      return;
    }
    if (pin2 === "" || pin2.length !== 4) {
      seterrormessage2("Enter a valid pin");
      return;
    }
    if (pin2 !== pin1) {
      toast.error("Your pins are not identical.");
      return;
    }
    let requestdata = {
      oldPin: pin,
      newPin: pin1,
    };

    dispatch(setUtilityData(loaderData(true, "")));
    dispatch(changeVaultPin(requestdata))
      // @ts-expect-error
      .then((data) => {
        dispatch(setUtilityData(loaderData(false, "")));
        notify();
        closeModal();
      })
      .catch((error: any) => {
        dispatch(setUtilityData(loaderData(false, "")));
        toast.error(error.message);
      });
  };

  const isNative = Capacitor.isNativePlatform();

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        className={classNames("securevault postionmoal dfit-the-width", isNative && "native-modal")}
        overlayClassName={"modal-container-overlay"}
      >
        {isFirstStage ? (
          <div className="">
            <div className="header2">
              <p className="header2-in h4-semibold">Change your Vault PIN</p>
              <p onClick={closeModal}>
                {" "}
                <XMarkIcon className="h-5 w-5" />
              </p>
            </div>
            <div>
              <div className="w-full flex flex-col items-center">
                <div style={{ marginBottom: "70px" }} className="form-control max-w-md">
                  <p className="pinlabel  p-big text-center">Enter your old PIN</p>
                  <PinInput
                    type="numeric"
                    inputMode="number"
                    initialValue=""
                    secret
                    focus
                    length={4}
                    onComplete={(value: any) => {
                      updatePin(value);
                    }}
                    onChange={(value, index) => {
                      updatePin(value);
                    }}
                  />
                  {errormessage ? <small className="helper-text errormessage danger">{errormessage}</small> : null}
                </div>
              </div>

              <div className="saveitop">
                <MainButton click={closeModal} type="secondary" id="add assets" size="small" extraClasses="mr-4">
                  <span>Cancel</span>
                </MainButton>

                <MainButton click={enterNewPin} type="primary" id="add assets" size="small">
                  <span>Next</span>
                </MainButton>
              </div>
            </div>
          </div>
        ) : null}

        {isSecondStage ? (
          <div className="">
            <div className="header2">
              <p className="header2-in h4-semibold">Enter Vault PIN</p>
              <p onClick={closeModal}>
                {" "}
                <XMarkIcon className="h-5 w-5" />
              </p>
            </div>
            <div>
              <div style={{ marginBottom: "20px" }} className="form-control">
                <p className="pinlabel  p-big">Enter your new PIN</p>
                <PinInput
                  type="numeric"
                  inputMode="number"
                  initialValue=""
                  secret
                  focus
                  length={4}
                  onComplete={(value, index) => {
                    updatePin1(value);
                  }}
                  onChange={(value, index) => {
                    updatePin1(value);
                  }}
                />
                {errormessage1 ? <small className="helper-text errormessage danger">{errormessage1}</small> : null}
              </div>

              <div style={{ marginBottom: "50px" }} className="form-control">
                <p className="pinlabel  p-big">Re-enter your new PIN</p>
                <PinInput
                  type="numeric"
                  inputMode="number"
                  initialValue=""
                  secret
                  length={4}
                  onComplete={(value, index) => {
                    updatePin2(value);
                  }}
                  onChange={(value, index) => {
                    updatePin2(value);
                  }}
                />
                {errormessage2 ? <small className="helper-text errormessage danger">{errormessage2}</small> : null}
              </div>

              <div className="saveitop">
                <MainButton click={closeModal} type="secondary" id="add assets" size="small" extraClasses="mr-4">
                  <span>Cancel</span>
                </MainButton>

                <MainButton click={submit} type="primary" id="add assets" size="small">
                  <span>Submit</span>
                </MainButton>
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
};

export const SetPinModal2 = ({ modalIsOpen, afterOpenModal, closeModal, customStyles }: any) => {
  const [isFirstStage, setIsFirstStage] = useState(true);
  const [isSecondStage, setisSecondStage] = useState(false);

  const [isThirdStage, setisThirdStage] = useState(false);
  const [pin, setPin] = useState("");
  const [pin1, setPin1] = useState("");
  const [errormessage, seterrormessage] = useState("");
  const [errormessage1, seterrormessage1] = useState("");

  useEffect(() => {}, []);
  const dispatch = useDispatch();
  let updatePin = (value: any) => {
    setPin(value);
  };

  let updatePin1 = (value: any) => {
    setPin1(value);
  };

  const openEnterPin = () => {
    setIsFirstStage(false);
    setisSecondStage(true);
  };

  let submit = () => {
    seterrormessage("");
    seterrormessage1("");
    if (pin && pin.length !== 4) {
      seterrormessage("Enter a valid pin");
      return;
    }
    if (pin1 && pin1.length !== 4) {
      seterrormessage1("Enter a valid pin");
      return;
    }
    if (pin !== pin1) {
      toast.error("Your pins are not identical.");
      return;
    }
    let requestdata = {
      pin: pin,
    };

    dispatch(setUtilityData(loaderData(true, "")));
    dispatch(setVaultPin(requestdata))
      // @ts-expect-error
      .then((data) => {
        dispatch(setUtilityData(loaderData(false, "")));
        setisThirdStage(true);
        setisSecondStage(false);
      })
      .catch((error: any) => {
        dispatch(setUtilityData(loaderData(false, "")));
        toast.error(error.message);
      });
  };

  const isNative = Capacitor.isNativePlatform();

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        className={classNames("securevault postionmoal  dfit-the-width ", isNative && "native-modal")}
        overlayClassName={"modal-container-overlay"}
      >
        {isFirstStage ? (
          <div className="securevault-s1">
            <div className="securevault-s2">
              <p onClick={closeModal} className="cursor-pointer">
                {" "}
                <XMarkIcon className="h-5 w-5" />
              </p>
            </div>
            <p className="securevault-s6">
              <VaultLock />
            </p>
            <p className="securevault-s3 h4-semibold">Secure your vault first</p>
            <p className="securevault-s4 p-big">
              Your vault PIN let’s you secure access to documents inside your vault. Create a PIN to protect your vault.
            </p>
            <button onClick={openEnterPin} type="button" id="add assets" className="btn-primary btn-md ">
              <span>Create a Pin</span>
            </button>
          </div>
        ) : null}

        {isSecondStage ? (
          <div className="">
            <div className="header2">
              <p className="header2-in h4-semibold">Create a Vault PIN</p>

              <p onClick={closeModal} className="cursor-pointer">
                {" "}
                <XMarkIcon className="h-5 w-5" />
              </p>
            </div>
            <div>
              <div style={{ marginBottom: "15px" }} className="form-control">
                <p className="pinlabel  p-big">Enter your PIN</p>
                <PinInput
                  type="numeric"
                  inputMode="number"
                  initialValue=""
                  secret
                  focus
                  length={4}
                  onComplete={(value, index) => {
                    updatePin(value);
                  }}
                  onChange={(value, index) => {
                    updatePin(value);
                  }}
                />
                {errormessage ? <small className="helper-text errormessage danger">{errormessage}</small> : null}
              </div>
              <div style={{ marginBottom: "40px" }} className="form-control">
                <p className="pinlabel p-big">Re-enter your PIN</p>
                <PinInput
                  type="numeric"
                  inputMode="number"
                  initialValue=""
                  secret
                  length={4}
                  onComplete={(value, index) => {
                    updatePin1(value);
                  }}
                  onChange={(value, index) => {
                    updatePin1(value);
                  }}
                />
                {errormessage1 ? <small className="helper-text errormessage danger">{errormessage1}</small> : null}
              </div>

              <div className="saveitop">
                <button onClick={closeModal} type="button" id="add assets" className="cancel btn-primary btn-md ">
                  <span>Cancel</span>
                </button>

                <button onClick={submit} type="button" id="add assets" className="btn-primary btn-md ">
                  <span>Save Pin</span>
                </button>
              </div>
            </div>
          </div>
        ) : null}

        {isThirdStage ? (
          <div className="securevault-d6">
            <div className="securevault-s2">
              <p onClick={closeModal} className="cursor-pointer">
                {" "}
                <XMarkIcon className="h-5 w-5" />
              </p>
            </div>

            <p className="securevault-d7">
              {" "}
              <Check201 />
            </p>

            <p className="securevault-d8 h4-semibold">You’re all set!</p>
            <p className="securevault-d9 p-big">
              We will request for your vault PIN everytime you want to access your vault.
            </p>
            <button onClick={closeModal} type="button" id="add assets" className="btn-primary btn-md ">
              <span>Done</span>
            </button>
          </div>
        ) : null}
      </Modal>
    </div>
  );
};

const ConfirmActionModal = ({ modalIsOpen, afterOpenModal, closeModal, submitAction, customStyles }: any) => {
  const isNative = Capacitor.isNativePlatform();
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        className={classNames("confirmationModal modal-width relative", isNative && "native-modal")}
        overlayClassName={"modal-container-overlay"}
      >
        <div className="flex justify-end">
          <button type="button" onClick={closeModal} className="closeicon cursor-pointer">
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>
        <div className="text-content pb-20">
          <h3 className="text-type mb-2">We can help!</h3>
          <p className="text-type-100 text-p2">
            An OTP will be sent to your registered email address to help you with this.{" "}
            <span className="font-semibold">Please click on “Send OTP” to confirm.</span>
          </p>
        </div>
        <div className="asset-class-footer">
          <MainButton size="small" type="secondary" click={closeModal}>
            Cancel
          </MainButton>
          <MainButton size="small" type="primary" click={submitAction} extraClasses="ml-4">
            Send OTP
          </MainButton>
        </div>
      </Modal>
    </div>
  );
};
