import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { BackarrowIcon } from "../../icons/backarrow";
import { useCreateNoteMutation } from "../../../api/note/note-mutations";
import { useUpdateNoteMutation } from "../../../api/note/note-mutations";
import { useDispatch, useSelector } from "react-redux";
import { SET_DID_OPTIMISTIC_UPDATE } from "../../../store/actions/general-store.actions";
import { useQueryClient } from "@tanstack/react-query";
import MainButton from "../../buttons/mainButton";

export const AccountNotesAddEdit = ({
  accountId,
  note,
  isViewingNote,
  setNote,
  setIsViewingNote,
  goBack,
}: {
  accountId: string;
  note: any;
  isViewingNote: boolean;
  setNote: (note: any) => void;
  setIsViewingNote: (isViewingNote: boolean) => void;
  goBack: () => void;
}) => {
  const [isNewNote, setIsNewNote] = useState(false);

  const { control } = useForm({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: {
      title: "",
      content: "",
    },
  });

  useEffect(() => {
    if (!note?.title && !note?.content) {
      setIsNewNote(true);
    }
  }, [note?.title, note?.content]);

  const dispatch = useDispatch();
  const { mutateAsync: createNote } = useCreateNoteMutation();
  const { mutateAsync: updateNote } = useUpdateNoteMutation();

  // Manage optimistic updates
  const onDidOptimisticUpdate = () => {
    dispatch({
      type: SET_DID_OPTIMISTIC_UPDATE,
      payload: {
        value: true,
        page: "InstitutionsPage",
      },
    });
  };

  const { id: portfolioId } = useSelector((state: any) => state.activePortfolio);
  const xCurrency = useSelector((state: any) => state.assets.currency.code);
  const queryClient = useQueryClient();

  const updateInstitutionsForNewOrUpdateNote = (newOrUpdatedNote: any, accountId: string) => {
    queryClient.setQueryData(
      ["X_FETCH_INSTITUTIONS", { id: portfolioId, currency: xCurrency }],
      (oldQueryData: any) => {
        return oldQueryData.map((institution: any) => {
          // Check if this institution has the account for the new or updated note
          return {
            ...institution,
            accounts: institution.accounts.map((account: any) => {
              // When we find the account, we either add the new note or update the existing note
              if (account.id === accountId) {
                const existingNoteIndex = account.notes.findIndex((note: any) => note.id === newOrUpdatedNote.id);
                const updatedNotes =
                  existingNoteIndex !== -1
                    ? account.notes.map((note: any) => (note.id === newOrUpdatedNote.id ? newOrUpdatedNote : note)) // Update note
                    : [...account.notes, newOrUpdatedNote]; // Add new note

                return { ...account, notes: updatedNotes };
              }

              return account;
            }),
          };
        });
      }
    );
  };

  // Note actions
  const submitNote = async () => {
    try {
      const newNote = await createNote({
        createNoteInput: {
          title: note?.title === "" ? note?.content?.substring(0, 20) : note?.title,
          content: note?.content,
          account_id: accountId,
        },
      });

      updateInstitutionsForNewOrUpdateNote(newNote, accountId); // Optimistically update cache
      onDidOptimisticUpdate(); // Register optimistic update with store to invalidate cache on exit
      goBack();
    } catch (error) {
      console.error("fail: submitNote", error);
    }
  };

  const onUpdateNote = async () => {
    try {
      const updatedNote = await updateNote({
        id: note?.id,
        updateNoteInput: {
          title: note?.title === "" ? note?.content?.substring(0, 20) : note?.title,
          content: note?.content,
        },
      });

      updateInstitutionsForNewOrUpdateNote(updatedNote, accountId); // Optimistically update cache
      onDidOptimisticUpdate(); // Register optimistic update with store to invalidate cache on exit
      goBack();
    } catch (error) {
      console.error("fail: onUpdateNote", error);
    }
  };

  return (
    <>
      {/* Header */}
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-medium text-black">
          {isNewNote ? "Add Note" : isViewingNote ? "View Note" : "Edit Note"}
        </h2>
        {!isNewNote && (
          <div className="flex justify-between items-center py-4">
            <div className="flex cursor-pointer items-center" onClick={goBack}>
              <BackarrowIcon className="mr-2" />
              <span className="ml-2">Back</span>
            </div>
          </div>
        )}
      </div>

      {/* Add Edit Form */}
      <div className="py-4">
        <Controller
          name="title"
          rules={{ required: true }}
          control={control}
          render={(props) => (
            <div className="flex flex-col">
              <input
                className="mb-2 rounded-md placeholder-neutral px-2 py-3 border border-neutral-200 text-sm font-medium focus:border-primary"
                id="note-name"
                autoComplete="off"
                type="text"
                value={note?.title}
                placeholder="Title"
                maxLength={60}
                onChange={(e: any) => {
                  setNote({ ...note, title: e.target.value });
                }}
                onFocus={() => setIsViewingNote(false)}
              />
            </div>
          )}
        />
        <div>
          <textarea
            value={note?.content}
            onChange={(e) => {
              setNote({ ...note, content: e.target.value });
            }}
            onFocus={() => setIsViewingNote(false)}
            placeholder="Type your notes here"
            name=""
            id="note-details"
            maxLength={2000}
            className="w-full resize-none h-80 md:h-44 rounded-md placeholder-neutral px-2 py-3 border border-neutral-200 text-sm font-medium"
          />
        </div>

        <div className="flex justify-end mt-1 text-type-200 text-xs">
          {isViewingNote ? " Maximum of 2000 characters" : `${note?.content?.length ?? 0}/2000`}
        </div>
      </div>

      {/* Footer */}
      {isViewingNote ? (
        <div className="asset-class-footer">
          <MainButton click={goBack} type="secondary" size="small">
            Go back
          </MainButton>
        </div>
      ) : (
        <div className="asset-class-footer">
          <MainButton click={isNewNote ? goBack : () => setIsViewingNote(true)} type="secondary" size="small">
            Back
          </MainButton>
          <MainButton click={isNewNote ? submitNote : onUpdateNote} extraClasses="ml-4" type="primary" size="small">
            {isNewNote ? "Save" : "Update Note"}
          </MainButton>
        </div>
      )}
    </>
  );
};
