import i18next, { InitOptions, FormatFunction } from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./en/translation.json";
import translationFR from "./fr/translation.json";

const orgName = process.env.NODE_ENV === "production" ? "Cova" : "Lifecheck";

const formatFunction: FormatFunction = (value: any, format?: string) => {
  if (format === "org") {
    return orgName;
  }
  return value;
};

const options: InitOptions = {
  lng: "en",
  debug: true,
  resources: {
    en: {
      translation: translationEN,
    },
    fr: {
      translation: translationFR,
    },
  },
  interpolation: {
    format: formatFunction,
  },
};

i18next
  .use(initReactI18next)
  .init(options)
  .catch((error) => console.error("i18next initialization error:", error));

export default i18next;
