import { useMemo, useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
} from "@stripe/react-stripe-js";
import { StripeCardElement, loadStripe } from "@stripe/stripe-js";
import MainButton from "../../../../components/buttons/mainButton";
import { toast } from "react-toastify";
import CheckCir from "../../../../assets/svg/successcheck.svg";
import { SetupIntent, Subscription } from "../../../../types/subscriptionTypes";
import { PortfolioState } from "../../../../types/portfolioTypes";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import ActionStateToast from "../../../../components/ActionStateToast";
import {
  useCreateSubscriptionMutation,
  useCreateStripeSetupIntentMutation,
  useConvertStripeSetupIntentToPaymentMethodMutation,
} from "../../../../api/subscription/subscription-mutations";
import { XMarkIcon } from "@heroicons/react/24/outline";

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: "#1E2329",
          letterSpacing: "0.025em",
          "::placeholder": {
            color: "#b7b7b7",
          },
        },
        invalid: {
          color: "#EE425A",
        },
      },
    }),
    []
  );

  return options;
};

interface IAddPaymentMethod {
  closeModal: () => void;
  addPaymentMethodActionType: "add-card" | "make-payment" | "";
  callback: (paymentMethodId?: string) => void;
  setDefault?: boolean;
}

const AddStripePaymentMethodModal = ({
  closeModal,
  addPaymentMethodModal,
  addPaymentMethodActionType,
  callback,
  setDefault,
}: IAddPaymentMethod & { addPaymentMethodModal: boolean }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");
  const isNative = Capacitor.isNativePlatform();

  return (
    <Elements stripe={stripePromise}>
      <Modal
        isOpen={addPaymentMethodModal}
        onRequestClose={closeModal}
        contentLabel="Add Payment Method Modal"
        className={classNames("modal-container", isNative && "native-modal")}
        overlayClassName={"modal-container-overlay"}
      >
        <AddPaymentMethod {...{ closeModal, addPaymentMethodActionType, callback, setDefault }} />
      </Modal>
    </Elements>
  );
};

const AddPaymentMethod = ({ closeModal, addPaymentMethodActionType, callback, setDefault }: IAddPaymentMethod) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [setupIntent, setSetupIntent] = useState<SetupIntent | null>(null);
  const subscription = useSelector(
    (state: { portfolios: { portfolio: PortfolioState } }) => state.portfolios.portfolio.subscription
  );
  const [setAsDefault, setSetAsDefault] = useState<boolean>(setDefault ?? false);
  const [actionToast, setActionToast] = useState<any>();

  useEffect(() => {
    handleGetSetupIntent();
    //eslint-disable-next-line
  }, []);

  const { mutateAsync: createStripeSetupIntent } = useCreateStripeSetupIntentMutation();
  const { mutateAsync: convertStripeSetupIntentToPaymentMethod } = useConvertStripeSetupIntentToPaymentMethodMutation();
  const { mutateAsync: createStripeSubscription } = useCreateSubscriptionMutation();

  const handleGetSetupIntent = async () => {
    try {
      const response = await createStripeSetupIntent();

      if (response.errors) {
        throw new Error("Unable to create setup intent");
      }

      const setupIntentResponse: SetupIntent = response;
      setSetupIntent(setupIntentResponse);
    } catch (error) {
      console.error("fail: handleGetSetupIntent", error);
    }
  };

  const handleAddCard = async () => {
    if (addPaymentMethodActionType === "add-card") {
      callback();
      closeModal();
    } else if (addPaymentMethodActionType === "make-payment" && subscription) {
      try {
        const res = await createStripeSubscription(subscription.plan.id);
        if (!res.errors) {
          const response: { createSubscription: Subscription } = res.data;

          if (response.createSubscription) {
            callback();

            toast.success(
              <div className="toast-div">
                <img src={CheckCir} alt="check circle" />
                Payment Successfull
              </div>
            );

            closeModal();
          }
        } else {
          toast.error("Unable to create subscription");
        }
      } catch (error) {
        toast.error("Unable to create subscription");
      }
    }
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    setActionToast(
      toast(<ActionStateToast message="Adding payment method..." />, {
        autoClose: false,
      })
    );

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      toast.dismiss(actionToast);
      return;
    }

    const card = elements.getElement(CardNumberElement);
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement) as unknown as StripeCardElement,
    });

    if (!card || !setupIntent) {
      toast.dismiss(actionToast);
      return;
    }

    if (payload.error) {
      toast.dismiss(actionToast);
      toast("Something went wrong..", {
        autoClose: 3000,
        type: "error",
      });
      console.error("fail: onSubmit createPaymentMethod", payload.error);
      return;
    }

    try {
      const confirmCardResponse = await stripe.confirmCardSetup(setupIntent.client_secret, {
        payment_method: {
          card: card,
        },
      });

      if (confirmCardResponse.error) {
        throw confirmCardResponse.error;
      } else {
        if (
          confirmCardResponse.setupIntent.status === "processing" ||
          confirmCardResponse.setupIntent.status === "succeeded"
        ) {
          const convertToPaymentMethodResponse = await convertStripeSetupIntentToPaymentMethod(setupIntent.id);
          if (!convertToPaymentMethodResponse.errors) {
            handleAddCard();
          } else {
            throw new Error("Unable to add card");
          }
        } else {
          throw new Error("Unable to add card");
        }
      }
    } catch (error) {
      toast.dismiss(actionToast);
      toast("Something went wrong..", {
        autoClose: 3000,
        type: "error",
      });
      console.error("fail: onSubmit confirmCardSetup", error);
    } finally {
      toast.dismiss(actionToast);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      {/* Header */}
      <div className="modal-header px-4 flex items-center justify-between">
        <h1 className="text-h4 font-semibold">Add a new card</h1>
        <button type="button" onClick={closeModal} className="cursor-pointer">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>

      {/* Body - Add Payment Method Form */}
      <div className="modal-body px-4 md:px-8 pt-5 pb-10">
        <p className="text-md mb-6">Please provide your new card details.</p>
        <label className="text-p2 font-medium text-type mb-4">
          Card number
          <CardNumberElement
            options={options}
            className="bg-neutral-300 border my-2 rounded placeholder-type-200 text-type text-p2 font-normal px-2 py-3 transition focus:border-type focus:ring-0 border-neutral-200"
          />
        </label>

        <div className="w-full flex justify-between mb-4 items-center flex-col sm:flex-row sm:gap-4">
          <label className="text-p2 font-medium text-type flex-auto w-full sm:w-auto">
            Expiry
            <CardExpiryElement
              options={options}
              className="bg-neutral-300 border my-2 rounded placeholder-type-200 text-type text-p2 font-normal px-2 py-3 transition focus:border-type focus:ring-0 border-neutral-200"
            />
          </label>
          <label className="text-p2 font-medium text-type flex-auto w-full sm:w-auto">
            CVV
            <CardCvcElement
              options={options}
              className="bg-neutral-300 border my-2 rounded placeholder-type-200 text-type text-p2 font-normal px-2 py-3 transition focus:border-type focus:ring-0 border-neutral-200"
            />
          </label>
        </div>

        {addPaymentMethodActionType === "add-card" && (
          <div className="form-control">
            <input
              checked={setAsDefault}
              onChange={(e) => {
                setSetAsDefault(e.target.checked);
              }}
              id="sendnotif"
              name="sendnotif"
              className="checkbox"
              type="checkbox"
            />
            <label className="text-xs cursor-pointer" htmlFor="sendnotif">
              Make this your default card
            </label>
          </div>
        )}
      </div>

      {/* Footer */}
      <div className="modal-footer p-4 gap-4 flex justify-end items-center border-t bg-white">
        <MainButton type="secondary" size="small" click={closeModal}>
          Cancel
        </MainButton>
        <MainButton type="primary" size="small" disabled={!stripe}>
          {addPaymentMethodActionType === "add-card" && "Add card"}
          {addPaymentMethodActionType === "make-payment" && "Make payment"}
        </MainButton>
      </div>
    </form>
  );
};

export default AddStripePaymentMethodModal;
