import CheckCir from "../../assets/svg/successcheck.svg";

const ToastSuccess = ({ message }: { message: string }) => {
  return (
    <div className="toast-div">
      <img src={CheckCir} alt="check circle" />
      {message}
    </div>
  );
};

export default ToastSuccess;
