export class UtilityClass {
  formatNumber(amount: string) {
    if (typeof amount === "string") {
      let newAmount = amount?.replace(/[^0-9.]+/g, "");
      return Number(newAmount);
    }
    return amount;
  }
}

export const ConvertNumberCur = (amount: number, currency: string, minDecimal?: number, maxDecimal?: number) => {
  let cur = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: minDecimal ?? 2,
    maximumFractionDigits: maxDecimal ?? 2,
  }).format(Number(Math.abs(amount)));
  let newcur = currency + " " + cur;
  if (amount < 0) {
    newcur = `- ${newcur}`;
  }
  return newcur;
};

export const forMatterNumber = (num: any, decimal?: number) => {
  num = Number(Math.round(num));
  let res = "0";
  if (num % 1 !== 0) {
    num = Math.floor(num);
  }

  if (num > 999 && num < 1000000) {
    res = (num / 1000).toFixed(decimal ?? 1) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num >= 1000000 && num < 1000000000) {
    res = (num / 1000000).toFixed(decimal ?? 2) + "M"; // convert to M for number from > 1 million
  } else if (num >= 1000000000) {
    res = (num / 1000000000).toFixed(decimal ?? 2) + "B"; // convert to B for number from > 1 billion
  } else if (num < 999) {
    res = num; // if value < 1000, nothing to do
  }
  return res;
};
