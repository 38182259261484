export const TickIcon = ({ width, height, color }: any) => {
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8996 35.1C18.6996 35.1 18.5163 35.0667 18.3496 35C18.1829 34.9333 18.0163 34.8167 17.8496 34.65L8.79961 25.6C8.49961 25.3 8.34961 24.9333 8.34961 24.5C8.34961 24.0667 8.49961 23.7 8.79961 23.4C9.09961 23.1 9.44961 22.95 9.84961 22.95C10.2496 22.95 10.5996 23.1 10.8996 23.4L18.8996 31.4L37.0496 13.25C37.3496 12.95 37.7079 12.8 38.1246 12.8C38.5413 12.8 38.8996 12.95 39.1996 13.25C39.4996 13.55 39.6496 13.9083 39.6496 14.325C39.6496 14.7417 39.4996 15.1 39.1996 15.4L19.9496 34.65C19.7829 34.8167 19.6163 34.9333 19.4496 35C19.2829 35.0667 19.0996 35.1 18.8996 35.1Z"
        fill={color || "#FFFFFF"}
      />
    </svg>
  );
};
