import { useCallback, useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { Textinput } from "../../../components/inputs/textinput";
import { useAssetClassSubmitBtn } from "../../../hooks/useAssetClassSubmitBtn";
import { useSelector } from "react-redux";
import { AccountTypes, InvestmentAccountSubtype, Partners } from "../../../types/accountTypes";
import { AsyncSelectCustomStyles } from "../../assetClass/panels/StocksPanel";
import { UserState } from "../../../types/authTypes";
import { PanelActions } from "./PanelActions";
import { InstitutionOption, Props } from "./manual-types";

export const AddCrypto = ({ isEdit, account, submit, onClickBack }: Props) => {
  const assetsCurrency = useSelector((state: any) => state.assets.currency);
  const currencies = useSelector((state: { user: UserState }) => state.user.config.currencies);
  const institutions = useSelector((state: any) => state.portfolios.institutions);
  const [institutionOptions, setInstitutionOptions] = useState<InstitutionOption[]>([]);

  useEffect(() => {
    setInstitutionOptions(
      institutions
        .filter((item: { partner: Partners }) => item.partner === Partners.COIN_CAP)
        .map((item: { name: string; id: string; partner: Partners }) => ({
          label: item.name,
          value: item.id,
        }))
    );

    // eslint-disable-next-line
  }, []);

  const { handleSubmit, errors, control } = useForm({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: {
      ...account,
      balance: account?.balance?.value,
      number: account?.number,
      currency: account?.balance?.currency,
      institution_id: { value: account?.institution?.id, label: account?.institution?.name },
    },
  });

  const cb = useCallback(() => {
    handleSubmit((payload) => {
      submit(
        {
          ...payload,
          institution_id: payload.institution_id.value,
          type: AccountTypes.Investment,
          subtype: InvestmentAccountSubtype.CryptoExchange,
        },
        Partners.COIN_CAP
      );
    })();
    // eslint-disable-next-line
  }, [handleSubmit]);

  useAssetClassSubmitBtn(cb);

  const panelRef = useRef(null);

  return (
    <div ref={panelRef} className="w-full max-h-[500px] overflow-y-auto">
      <div className={`mb-4 ${!isEdit ? "block" : "hidden"}`}>
        <PanelActions onClickBack={onClickBack} />
        <div className="mt-2">
          <h4 className="text-sm font-semibold">Cryptocurrency</h4>
        </div>
      </div>
      <form
        className="grid grid-cols-1 grid-rows-4 gap-y-4"
        onKeyUp={(e) => {
          if (e.code === "Enter") {
            handleSubmit((payload) => {
              submit(
                {
                  ...payload,
                  institution_id: payload.institution_id.value,
                  type: AccountTypes.Investment,
                  subtype: InvestmentAccountSubtype.CryptoExchange,
                },
                Partners.COIN_CAP
              );
            })();
          }
        }}
        onSubmit={(e) => e.preventDefault()}
      >
        <Controller
          name="institution_id"
          defaultValue={null}
          rules={{ required: true }}
          control={control}
          render={(props) => (
            <div className="flex flex-col">
              <label className="text-p2 font-medium text-type mb-1">Exchange or Wallet provider</label>
              <CreatableSelect
                formatCreateLabel={(inputText) => `Add "${inputText}"`}
                options={institutionOptions}
                styles={AsyncSelectCustomStyles}
                placeholder="Select or add exchange"
                value={props.value}
                onChange={(value: InstitutionOption) => {
                  props.onChange(value);
                }}
                onCreateOption={(input: string) => {
                  setInstitutionOptions([...institutionOptions, { value: input, label: input }]);
                  props.onChange({ value: input, label: input });
                }}
                maxMenuHeight={200}
              />
              {errors.institution_id && (
                <small className="text-p3 text-status-danger mt-1">Please fill this field</small>
              )}
            </div>
          )}
        />
        <Controller
          name="name"
          defaultValue={null}
          rules={{ required: true }}
          control={control}
          render={(props) => (
            <Textinput
              onChange={(e: any) => {
                props.onChange(e.target.value);
              }}
              value={props.value}
              label="Wallet name"
              inputid="name"
              type="text"
              placeholder="E.g. Binance wallet"
              iserror={errors.name}
              message={"Please fill this field."}
              autocomplete="off"
            />
          )}
        />
        <Controller
          name="number"
          defaultValue={null}
          rules={{ required: true }}
          control={control}
          render={(props) => (
            <Textinput
              onChange={(e: any) => {
                props.onChange(e.target.value);
              }}
              value={props.value}
              label="Wallet address"
              inputid="number"
              type="text"
              placeholder=""
              iserror={errors.number}
              message={"Please fill this field."}
              autocomplete="off"
            />
          )}
        />
        <Controller
          name="currency"
          defaultValue={assetsCurrency.code ? assetsCurrency?.code : "USD"}
          rules={{ required: true }}
          control={control}
          render={(props) => (
            <div className="forminput">
              <label className="text-p2 font-medium text-type mb-1">Fiat Currency</label>
              <select
                onChange={(e: any) => {
                  props.onChange(e.target.value);
                }}
                value={props.value}
                className="bg-neutral-300 border-neutral-200 rounded placeholder-type-200 text-type text-p2 font-normal px-2 py-3 transition focus:border-type focus:ring-0"
                name="currency"
                id="currency"
              >
                {currencies.map(
                  (
                    currency: {
                      code: string;
                      name: string;
                    },
                    index: number
                  ) => (
                    <option key={index} value={currency.code}>
                      {currency.code} - {currency.name}
                    </option>
                  )
                )}
              </select>
            </div>
          )}
        />
      </form>
    </div>
  );
};

export default AddCrypto;
