import { RESET_USER_DATA, SET_USER_DATA } from "../../actions/userdata/types";

const initialState = {
  token: null,
  user: null,
  firstName: "",
  lastName: "",
  username: "",
  email: "",
  provider: null,
  profilePic: null,
  otpEnabled: false,
  isSignUpModal: false,
  vaultPinEnabled: true,
  valueX: null,
  paymentMethod: {
    brand: "",
    priceId: "",
    number: "",
    userId: 0,
  },
  recipient: false,
};

export default function UserData(state = initialState, action: any) {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_USER_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
