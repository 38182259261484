import { Helmet } from "react-helmet";

const LifecheckMeta = () => (
  <Helmet>
    <title>Life Check</title>
    <meta name="description" content="Add a beneficiary and trusted angel" />
    <meta property="og:title" content="Beneficiary" />
    <meta property="og:description" content="Add a beneficiary and trusted angel" />
    <meta property="og:url" content="https://app.getcova.com/beneficiaries" />
    <meta name="twitter:card" content="Add a beneficiary and trusted angel" />
  </Helmet>
);

export default LifecheckMeta;
