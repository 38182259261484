import dayjs from "dayjs";
import { Subscription } from "../../../../types/subscriptionTypes";

export const StripeCancelPrompt = ({
  subscription,
  setCancelSubscriptionModal,
}: {
  subscription: Subscription;
  setCancelSubscriptionModal: (isOpen: boolean) => void;
}) => {
  return (
    <div>
      <p className="text-xl text-center md:text-left mb-2">Cancel Subscription</p>
      <div className="flex flex-col items-center md:items-start justify-start gap-1 lg:mb-6 mb-3 text-center md:text-left">
        <div className="block">
          <p className="text-type-100 text-p2 mb-2">
            You can cancel at least one day before your subscription expires. If you cancel, you still have access to
            your premium features until {dayjs(subscription.current_period_end).format("MMM DD, YYYY")}.
          </p>
        </div>
        <button
          type="button"
          className="font-semibold text-p2 text-status-danger underline"
          onClick={() => setCancelSubscriptionModal(true)}
        >
          Cancel Subscription
        </button>
      </div>
    </div>
  );
};
