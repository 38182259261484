import { gql } from "@apollo/client";

const STEPS_COMMON_FIELDS_FRAGMENT = gql`
  fragment StepsCommonFields on LifeCheckStep {
    action
    created_at
    custom_note
    id
    portfolio_id
    sort_index
    trigger_after
    trigger_after_unit
    updated_at
    contacts {
      created_at
      email
      first_name
      id
      last_name
      phone_number
      phone_number_country_code
      updated_at
      security_questions {
        answer
        question
      }
    }
  }
`;

const LIFECHECK_COMMON_FIELDS_FRAGMENT = gql`
  fragment LifecheckCommonFields on LifeCheck {
    enabled
    history(offset: $offset, size: $size, types: $types) {
      data {
        created_at
        details
        id
        message
        portfolio_id
        type
      }
      meta {
        total
        actualSize
        requestedSize
        offset
      }
    }
    steps {
      ...StepsCommonFields
    }
  }

  ${STEPS_COMMON_FIELDS_FRAGMENT}
`;

export const X_FETCH_LIFECHECK = gql`
  query FetchLifecheck($id: Int!, $offset: Float, $size: Float, $types: [LifeCheckHistoryType!]) {
    portfolio(id: $id) {
      lifecheck {
        ...LifecheckCommonFields
      }
    }
  }

  ${LIFECHECK_COMMON_FIELDS_FRAGMENT}
`;

export const UPDATE_LIFECHECK_STEP_GQL = gql`
  mutation UpdateLifecheckStep($step: LifeCheckStepUpdateInput!) {
    steps: updateLifecheckStep(step: $step) {
      ...StepsCommonFields
    }
  }

  ${STEPS_COMMON_FIELDS_FRAGMENT}
`;

export const ADD_LIFECHECK_STEP_GQL = gql`
  mutation CreateLifecheckStep($step: LifeCheckStepCreateInput!) {
    steps: createLifecheckStep(step: $step) {
      ...StepsCommonFields
    }
  }

  ${STEPS_COMMON_FIELDS_FRAGMENT}
`;

export const UPDATE_LIFECHECK_GQL = gql`
  mutation UpdateLifecheck($portfolio: UpdatePortfolioInput!) {
    portfolio: updatePortfolio(portfolio: $portfolio) {
      lifecheck {
        enabled
      }
    }
  }
`;

export const REMOVE_LIFECHECK_STEP_GQL = gql`
  mutation RemoveLifeCheckStep($removeLifeCheckStepId: String!) {
    steps: removeLifecheckStep(id: $removeLifeCheckStepId) {
      ...StepsCommonFields
    }
  }

  ${STEPS_COMMON_FIELDS_FRAGMENT}
`;
