import "./auth.scss";
import { Link } from "react-router-dom";
import { Authheader } from "./authheader";
import { TermsContainer, TermsHeaderComponent, TermsTextBodyComponent } from "./termsandcondition";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import GetStartedCTA from "../../components/ctas/GetStartedCTA";
import { useTranslation } from "react-i18next";

export const PrivacyPage = () => {
  const isNative = Capacitor.isNativePlatform();
  const { t } = useTranslation();

  return (
    <div className={classNames("authpage", isNative && "authpage--native")}>
      <Helmet>
        <title>{t("orgName")} | Privacy Policy</title>
        <meta name="description" content={`Read more about {t("orgName")}'s Privacy Policy"`} />
        <meta property="og:title" content={`{t("orgName")} | Privacy Policy`} />
        <meta property="og:description" content={`Read more about {t("orgName")}'s Privacy Policy`} />
        <meta property="og:url" content="https://app.getcova.com/privacy" />
      </Helmet>
      {!isNative && <Authheader />}
      <div className="max-w-7xl m-auto">
        <div className="terms_intro">
          <div className="terms_container">
            {isNative && (
              <div className="w-full flex justify-end py-4 px-8 fixed">
                <Link to="/signup">
                  <button className="underline">
                    <span>Back</span>
                  </button>
                </Link>
              </div>
            )}

            <h1>
              <span>{t("orgName")}'s</span>&nbsp;<span>Privacy Policy</span>
            </h1>
            <p>
              Please read these Terms of Use (“Terms”), which set forth the legally binding terms and conditions between
              you and {t("orgName")} Inc. (“{t("orgName")}”). It governs your access to and the use of the Platform and
              all or any related services (collectively referred to as the “Service”) offered by {t("orgName")}.
            </p>
            <br />
            <br />
          </div>
        </div>
        <ul className="terms_list_body_contaner">
          <TermsContainer>
            <TermsHeaderComponent>Introduction</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Please read these Terms of Use (“Terms”), which set forth the legally binding terms and conditions
                between you and {t("orgName")} Inc. (“{t("orgName")}”). It governs your access to and the use of the
                Platform and all or any related services (collectively referred to as the “Service”) offered by{" "}
                {t("orgName")}.
              </p>
              <br />
              <br />
              <p>Throughout the website, the terms “we”, “us” and “our” refer to {t("orgName")}.</p>
              <br />
              <br />
              <p>
                This Privacy Policy explains how we collect, use, share and protect your personal information in
                connection with your relationship with us as a client or potential client. It applies to all our
                clients, potential clients, consultants, partners and every other person we hold information about.
              </p>
              <br />
              <br />
              <p>This policy also sets out your rights and who you may contact for further information.</p>
              <br />
              <br />
              <p>You agree to this Privacy Policy by visiting our website and when you use our services.</p>
              <br />
              <br />
              <p>
                Our website is not directed at you if we are prohibited by any law of any jurisdiction from making the
                information on our website available to you and is not intended for any use that would be contrary to
                local law or regulation.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Age Restriction</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                You affirm that you are over the age of 18 and have the right to contract in your own name, and that you
                have read the above authorization and fully understand its contents.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Information We Collect.</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                There are two categories of information we collect. We collect a variety of information from our users
                and visitors to our website. As described below, some information is automatically collected when you
                visit our website, and some you provide to us when registering an account or filling out a form, or
                communicating with us.
              </p>
              <ul className="roman">
                <li>
                  <p>
                    <span>
                      <b>Information Collected Automatically:</b>
                    </span>{" "}
                    Whenever you visit our website, our web servers automatically collect non-personal information such
                    as the domain name of the internet access provider, the internet protocol address used to connect
                    the computer to the internet, the average time spent on our website, pages viewed, information
                    searched for, access times, and other relevant statistics.
                  </p>
                </li>

                <li>
                  <p>
                    <span>
                      <b>Information You Provide Us:</b>
                    </span>{" "}
                    If you provide us with personal information by contacting us, we collect that personal information.
                  </p>
                </li>
              </ul>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Using Your Personally Identifiable Information</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                “Personally Identifiable Information” means any information that (a) identifies or can be used to
                identify, contact, or locate the person to whom such information pertains, or (b) from which
                identification or contact information of an individual person can be derived. This includes but is not
                limited to your name, email address, phone number, physical address, assets and debts,
                next-of-kin/beneficiary’s details (name and email address), and credit card information. We primarily
                collect your information to ensure that we provide the most efficient service to you, monitor the use
                and improve our website and other legitimate interests. Your information will solely be used and
                disclosed for the following purposes:
              </p>
              <ul className="roman">
                <li>To help us verify your identity;</li>

                <li>To carry out our obligations ensuing from any contracts entered into between you and us;</li>
                <li>To provide you with the products, services and information you request from us.</li>
                <li>To assist you with enquiries and improve our customer service;</li>

                <li>
                  To assist us in carrying out marketing analysis and customer profiling (including transactional
                  information), conduct research, including creating statistical and testing information;
                </li>
                <li>
                  To allow us communicate with you in any way (including e-mail, telephone, visit, and text or
                  multimedia messages);
                </li>
                <li>For our billing and account purposes;</li>
                <li>To help prevent and detect fraud or loss;</li>
                <li>To update our records;</li>
                <li>
                  To make recommendations and suggestions to you about services offered by us unless you have previously
                  asked us not to do so;
                </li>
                <li>
                  Send you service or support messages, such as updates, security alerts, email notifications and /or
                  newsletters;
                </li>
                <li>Conduct investigations and risk assessments; and</li>
                <li>For compliance with a legal and regulatory obligations.</li>
              </ul>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Other Information We Collect </TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Other information which may be automatically collected from you when you visit our website include;
                domain name of your internet access provider, the internet protocol address used to connect the computer
                to the internet, the average time spent on our website, pages viewed, information searched for, access
                times, your geographical location, operating system, referral source, and other relevant statistics.
              </p>
              <br />
              <br />
              <p>
                We may also collect information from you using cookies and other analytical tools especially when you
                use our products and services. More details are provided below in our section on Cookies.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Service Providers</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <ul className="roman">
                <li>
                  We employ third party companies or individuals (“Service Providers”) to process data on our behalf for
                  the purposes disclosed above. When you sign in to your {t("orgName")} account, your banking
                  credentials are directly sent from your browser to the relevant Service Providers. We collect
                  information such as your financial accounts, and assets value from these Service Providers.{" "}
                  {t("orgName")} will not make any transactions on your behalf.;
                </li>
                <li>
                  Our Service Providers will only use your information for the purposes outlined and perform their tasks
                  on our behalf. Under no circumstance will your information be disclosed to other third parties or use
                  it for any other purpose.
                </li>
                <li>
                  We will ensure that standard contractual clauses are included in all agreements with our Service
                  Providers and your information is not retained on their servers for longer than necessary.
                </li>
              </ul>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Disclosures</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <ul className="roman">
                <li>
                  We will not sell, publish, or disclose to third parties your personally identifiable information
                  collected on our website, through our servers or otherwise obtained by us, other than to provide our
                  services and as set forth in this privacy policy. We may share your information with our service
                  providers, business partners, trusted affiliates, professional advisers and advertisers for the
                  purposes outlined above. We may share your information with these third parties for those limited
                  purposes provided that you have given us your permission and in compliance with the applicable laws.
                  We may also share or transfer your information in connection with or during negotiations of, any
                  merger, sale of company assets, financing, or acquisition of all or a portion of our business to
                  another company.
                </li>
                <li>We may request information about you from third parties to provide our services.</li>
                <li>
                  We will notify you as soon as we become aware of a harmful data breach which may result in a risk of
                  your rights and freedom.
                </li>
                <li>You have the right to request an erasure of your data at any time.</li>
                <li>We will notify you if we are transferring your data.</li>
                <li>
                  You may request at any time that we halt further dissemination of your data or cease to use your data.
                </li>
                <li>
                  If you submit content in a public forum or a social media post, or use a similar feature on our
                  website, that content is publicly visible.
                </li>
                <li>
                  We may disclose Personally Identifiable Information if required to do so by law or in the good faith
                  belief that such action is necessary to (a) conform with the requirements of the law or comply with
                  legal process served on us, or (b) act in urgent circumstances to protect the personal safety of users
                  of our service or members of the public.
                </li>
                <li>
                  To the extent practicable and legally permitted, we will attempt to advise you prior to any such
                  disclosure, so that you may seek a protective order or other relief limiting such disclosure.
                </li>
              </ul>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Administrative Access to your Information</TermsHeaderComponent>
            <TermsTextBodyComponent>
              We operate strict internal procedures to ensure that your information is well protected. In accordance
              with our internal guidelines, our employees are not permitted to access your account or your information
              except for information necessary to solve issues you may raise, such as triggering confirmation emails. We
              log and regularly audit all access to your {t("orgName")} account.
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>International Transfers</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                The transfer of your information to a foreign country or international organisation will only be done
                with your consent and in accordance with applicable laws. We will ensure that there are adequate data
                protection laws in the recipient country or organisation before transferring your information.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Your Rights</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Subject to certain limitations and exceptions, you are entitled to the following principal rights in
                accordance with the applicable laws:
              </p>
              <ul className="roman">
                <li>You have the right to be notified if we are transferring your personal information.</li>
                <li>You have the right to request an erasure of your personal information at any time.</li>
                <li>You have the right to request that we rectify inaccurate personal information.</li>
                <li>
                  You may request at any time that we halt further dissemination of your data or cease to use your
                  personal information.
                </li>
                <li>You have the right to request for copies of your personal information.</li>
                <li>You have the right to request that we restrict the processing of your personal information.</li>
              </ul>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Deleting Information from your Account</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                When you delete any portion of your information from your account, the information is deleted from our
                primary database and our Service Providers are notified to do the same. We purge our backup every 15
                days and your information will be permanently removed from our backup in the next purge cycle.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Website Security</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                We are committed to ensuring that your information is secure. In order to prevent unauthorised access or
                disclosure, we use bank level encryption of 256-bit AES as recommended by NIST Special Publication
                800-132. with 2FA and user PIN which serves as a third security layer. All communications between you
                and {t("orgName")} are encrypted via SSL using 2048-bit certificates.
                <br />
                <br />
                In the event that our server is breached, your information will not be compromised. We regularly audit
                our code and processes for security issues and work with industry security leaders to ensure our
                security protocols are up to date.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Compliance with Laws and Law Enforcement</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                We cooperate with government and law enforcement officials to enforce and comply with the law. We will
                disclose any information about Users upon valid request by government or law officials as we, in our
                sole discretion, believe necessary or appropriate to respond to claims and legal process (including
                without limitation subpoenas), to protect your property and rights, or the property and rights of a
                third party, to protect the safety of the public or any person, or stop activity we consider illegal or
                unethical.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Use of Cookies</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                We use cookies to identify you as a User and make your user experience easier, customise our services,
                content and advertising; help you ensure that your account security is not compromised, mitigate risk
                and prevent fraud; and to promote trust and safety on our website. Cookies allow our servers to remember
                your account log-in information when you visit our website, IP addresses, date and time of visits,
                monitor web traffic and prevent fraudulent activities. If your browser or browser add-on permits, you
                have the choice to disable cookies on our website; however, this may limit your ability to use our
                website.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>The Data We Retain</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                We will retain your information for as long as needed to provide you with our services, comply with our
                legal and statutory obligations or verify your information with a financial institution.
              </p>
              <br />
              <p>
                We are statutory obligated to retain the data you provide us with in order to resolve disputes make
                refunds, identify fraud and in compliance with laws and regulatory guidelines applicable to us, our
                banking providers and credit card processors.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Links to Third Party Websites</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <ul className="roman">
                <li>
                  Our website may contain links to third-party websites or services that are not owned or controlled by
                  us.
                </li>
                <li>
                  have no control over, and assumes no responsibility for, the content, privacy policies, or practices
                  of any third-party websites or services. You further acknowledge and agree that we shall not be
                  responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused
                  by or in connection with use of or reliance on any such content, goods or services available on or
                  through any such web sites or services.
                </li>
                <li>
                  We strongly advise you to read the terms and conditions and privacy policies of any third-party web
                  sites or services that you visit.
                </li>
              </ul>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Limitation to Liability</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                We exercise reasonable efforts to safeguard the security and confidentiality of your personal data;
                however, we will not be liable for unauthorized disclosure of personal data that occurs through no fault
                of ours.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Changes to this Privacy Policy</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Changes may be made to this Privacy Policy from time. Whenever such changes are made, we will notify
                you. These changes will take effect immediately after you have been notified.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Contact Us</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                If you would like more information or you have any comments or questions on our privacy policy, please
                contact us at <a href="mailto:support@getcova.com">support@getcova.com</a>.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Changes to Terms &amp; Conditions</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                {t("orgName")} reserves the right, in its sole discretion, to change the Terms under which the Service
                is offered. The most current version of the Terms will supersede all previous versions. {t("orgName")}{" "}
                encourages you to periodically review the Terms to stay informed of our updates.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Contact Us</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>If you have any questions about these Terms of Use, please contact us at support@getcova.com</p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <div className="terms_container">
            <TermsTextBodyComponent>
              <p>
                This policy is effective as of 18th of April, 2021
                <br />
                Last updated: 18th of April, 2021
              </p>
            </TermsTextBodyComponent>
          </div>
        </ul>
        <div className="pb-20">
          <GetStartedCTA />
        </div>
      </div>
    </div>
  );
};
