import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/svg/logo1.svg";
import { ReactComponent as LifecheckLogo } from "../../../assets/svg/logo-lifecheck.svg";
import Mockup from "../../../assets/images/mockup.png";
import QRBanner from "../../../assets/images/qr-banner.png";

const LoginPanelInfo = () => {
  const isProd = process.env.NODE_ENV === "production";
  return (
    <div className="firsthalf">
      <Link to="/" className="w-36 mb-11">
        {isProd ? <Logo /> : <LifecheckLogo />}
      </Link>
      <img src={Mockup} alt="Mockup" className="mockup" />
      <img src={QRBanner} alt="" className="banner" />
    </div>
  );
};

export default LoginPanelInfo;
