import AxoisApi from "../../api.ts";
import { ALLAPIS } from "../../api.ts/apilog";

export class Authservice {
  sendDeviceDetails(data: any) {
    return AxoisApi.post(`${ALLAPIS.AUTH.SEND_DEVICE_DETAILS}`, data).then((res) => {
      return res.data;
    });
  }

  getReferralDetails() {
    return AxoisApi.get(`${ALLAPIS.AUTH.GET_REFERRAL_DETAILS}`).then((res) => {
      return res.data;
    });
  }

  setupReferralEmail(data: { email: string; paypal_email: string }) {
    return AxoisApi.post(`${ALLAPIS.AUTH.SETUP_REFERRAL_EMAIL}`, data).then((res) => {
      return res.data;
    });
  }

  claimReward() {
    return AxoisApi.post(`${ALLAPIS.AUTH.CLAIM_REWARD}`, {}).then((res) => {
      return res.data;
    });
  }
}
