import { Helmet } from "react-helmet";

const InstitutionsMeta = ({ pageTitle }: { pageTitle: string }) => {
  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content="View all your portfolio by Institutions" />
      <meta property="og:title" content="Beneficiary" />
      <meta property="og:description" content="View all your portfolio by Institutions" />
      <meta property="og:url" content="https://app.getcova.com/portfolio/institutions" />
      <meta name="twitter:card" content="View all your portfolio by Institutions" />
    </Helmet>
  );
};

export default InstitutionsMeta;
