import { useEffect, useMemo, useState } from "react";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import { StylesConfig } from "react-select";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import { ReactComponent as CheckCircle } from "../../../assets/svg/check-circle-green-fill.svg";
import { ReactComponent as Search } from "../../../assets/svg/search-blue-fill.svg";
import { useAddAccountModalToggle } from "../../../graphql/general-store.gql-hook";
import usePortfolio from "../../../hooks/usePortfolio";
import { AccountType } from "../../../types/accountTypes";
import MainButton from "../../buttons/mainButton";
import "./labels.scss";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface ILabelAccounts {
  closeModal: () => void;
  closeManageLabelModal?: any;
  isOpen: boolean;
  title?: string;
  defaultLabel?: any;
  handleSaveLabel: (accountIds: string[], labelId: string) => void;
}
const LabelAccounts: React.FC<ILabelAccounts> = ({
  closeModal,
  isOpen,
  title = "Label Account",
  closeManageLabelModal,
  defaultLabel,
  handleSaveLabel,
}) => {
  const { labels, institutions, activePortfolioId } = useSelector((state: any) => state.portfolios);
  const [targetLabel, setTargetLabel] = useState<any>({});
  const { handleCreateLabel } = usePortfolio();
  const [accountIds, setAccountIds] = useState<string[]>([]);
  const [newLabelAdded, setNewLabelAdded] = useState<boolean>(false);

  useEffect(() => {
    if (defaultLabel?.label && defaultLabel?.id) {
      setNewLabelAdded(true);
      setTargetLabel(defaultLabel);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customStylesSelect: StylesConfig<any, true> = {
    control: (provided: any) => ({
      ...provided,
      border: "1px solid #EDEFF2",
      outline: "0px",
      boxShadow: "none",
      fontSize: "14px",
      padding: "0rem",
      background: "#F6F7F9;",
    }),
    option: (provided: any) => ({
      ...provided,
      fontSize: "14px",
      width: "100%",
      color: "#1E2329",
      background: "#fff",
      "&:hover": {
        background: "#F6F7F9",
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      boxShadow: "0px 9px 20px 6px rgba(79, 93, 109, 0.2)",
    }),
  };

  const handleInputChange = (e: any, account: AccountType) => {
    let arr: string[] = [...accountIds];
    if (e.target.checked) {
      arr.push(account.id);
      setAccountIds(arr);
    } else {
      arr.splice(arr.indexOf(account.id), 1);
      setAccountIds(arr);
    }
  };

  const refined_lables = useMemo(() => {
    let all_labels: { label: string; id: string }[] = [];
    labels.forEach((group: any) => {
      all_labels.push({
        label: group.name,
        id: group.id,
      });
    });
    return all_labels;
  }, [labels]);

  useEffect(() => {
    let ids: any = [];
    institutions?.forEach((acc: any) => {
      acc.accounts.forEach((account: AccountType) => {
        if (account.label && account.label.name === targetLabel.label) ids.push(account.id);
      });
    });

    setAccountIds(ids);
  }, [institutions, targetLabel.label]);

  const non_labeled_account = useMemo(() => {
    let unlabelled: any = [];
    institutions?.forEach((acc: any) => {
      acc.accounts.forEach((account: AccountType) => {
        if (!account.label) unlabelled.push(account);
      });
    });
    return unlabelled;
  }, [institutions]);

  const selected_accounts = useMemo(() => {
    let ids: any = [];
    institutions?.forEach((acc: any) => {
      acc.accounts.forEach((account: AccountType) => {
        if (account.label && account.label.name === targetLabel.label) ids.push(account);
      });
    });

    return ids;
  }, [institutions, targetLabel.label]);

  const handleSelectOnchange = (data: any) => {
    setTargetLabel(data);
  };

  const saveLabel = () => {
    if (!targetLabel.id || accountIds?.length < 1) {
      toast.error("Please select a label to edit");
      return;
    }
    handleSaveLabel(accountIds, targetLabel.id);
    closeModal();
    closeManageLabelModal();
  };

  const handleAddNewLabel = async (labelName: string) => {
    const res = await handleCreateLabel(labelName, activePortfolioId);
    setTargetLabel({
      label: res.name,
      id: res.id,
    });
    setNewLabelAdded(true);
  };

  const isNative = Capacitor.isNativePlatform();

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      className={classNames("viewassetsdetails postionmoal", isNative && "native-modal")}
      overlayClassName={"modal-container-overlay"}
    >
      <button type="button" onClick={closeModal} className="closeicon cursor-pointer flex justify-end my-3 w-full">
        <XMarkIcon className="h-5 w-5" />
      </button>
      <h2 className="header-assetsheader h4-semibold">{title}</h2>
      <div className="mb-8 relative">
        <h3 className="font-bold text-p2 my-4">Label</h3>
        {newLabelAdded ? (
          <CreatableSelect
            formatCreateLabel={(inputText) => `Create "${inputText}"`}
            styles={customStylesSelect}
            options={refined_lables}
            onChange={handleSelectOnchange}
            value={targetLabel}
            onCreateOption={handleAddNewLabel}
            placeholder="Create or search label name"
          />
        ) : (
          <CreatableSelect
            formatCreateLabel={(inputText) => `Create "${inputText}"`}
            styles={customStylesSelect}
            options={refined_lables}
            onChange={handleSelectOnchange}
            onCreateOption={handleAddNewLabel}
            placeholder="Create or search label name"
          />
        )}
        <div>
          <h3 className="font-bold text-p2 mt-6">Accounts</h3>
          <p className="text-p3 mb-3">Select one or more accounts you want to label.</p>
          <div className="overflow-y-auto accounts-label-new acct-labels pr-2">
            {non_labeled_account.map((acc: any, index: number) => (
              <label key={`input-${index}`} className="block border p-3 my-2 rounded-md text-p2">
                <input
                  type="checkbox"
                  className="checkbox"
                  defaultChecked={false}
                  onChange={(e: any) => handleInputChange(e, acc)}
                  name={acc?.name}
                />
                <span>{acc?.name}</span>
              </label>
            ))}
            {labels.length < 1 ? (
              <EmptyLabelsState
                title="No accounts found in this portfolio"
                desc="Click the button below to add an account to this portfolio"
                buttonText="Connect new account"
                icon={<Search />}
              />
            ) : (
              selected_accounts.length < 1 &&
              non_labeled_account.length < 1 && (
                <EmptyLabelsState
                  title="All your accounts are currently labelled"
                  desc="You do not have any account without a label. Connect new accounts or remove existing accounts from other labels."
                  buttonText="Add account"
                  icon={<CheckCircle />}
                />
              )
            )}
            {selected_accounts.map((acc: any, index: number) => (
              <label key={`input-${index}`} className="block border p-3 my-2 rounded-md text-p2">
                <input
                  type="checkbox"
                  className="checkbox"
                  defaultChecked={true}
                  onChange={(e: any) => handleInputChange(e, acc)}
                  name={acc?.name}
                />
                <span>{acc.name}</span>
              </label>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-3 border-t mt-8 absolute bottom-0 w-full right-0 p-6 bg-white">
        <MainButton type="secondary" size="small" click={closeModal}>
          Cancel
        </MainButton>
        {accountIds.length > 0 ? (
          <MainButton type="primary" size="small" click={saveLabel}>
            Save
          </MainButton>
        ) : (
          <MainButton type="primary" size="small" click={closeModal}>
            Done
          </MainButton>
        )}
      </div>
    </ReactModal>
  );
};
export default LabelAccounts;

interface EmptyLabelsStateTypes {
  title: string;
  desc: string;
  buttonText: string;
  icon: any;
}
const EmptyLabelsState: React.FC<EmptyLabelsStateTypes> = ({ title, desc, buttonText, icon }) => {
  const { 1: openAccountModal } = useAddAccountModalToggle();

  return (
    <div className="svg-size flex justify-center items-center flex-col">
      {icon}
      <h3 className="text-p1 text-type mt-5 mb-2 font-semibold">{title}</h3>
      <p className="text-p2 text-type-200 info mb-4">{desc}</p>
      <MainButton
        click={() => openAccountModal(true)}
        type="secondary"
        size="small"
        id="add assets"
        extraClasses="flex"
      >
        + {buttonText}
      </MainButton>
    </div>
  );
};
