import { useEffect, useRef, useState } from "react";
import { CanadaFlag } from "../icons/canada";
import { NigeriaFlag } from "../icons/nigeria";
import { UsaFlag } from "../icons/usa";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

export const getCountryName = (country: string) => {
  switch (country) {
    case "US":
      return "USA";
    case "CA":
      return "Canada";
    case "NGA":
      return "Nigeria";
    case "*":
      return "Others";
    default:
      return "Others";
  }
};

export const CountrySelector = ({ setcountry, country, options }: any) => {
  const [isShow, setIsShow] = useState(false);
  const [dropdown, setdropdown] = useState("none");

  useEffect(() => {
    setdropdown(isShow ? "flex" : "none");
  }, [isShow]);
  let opendropdown = () => {
    setIsShow(!isShow);
  };

  const selectcountry = (country: string) => {
    opendropdown();
    setcountry(country);
  };

  const wrapperRef: any = useRef(null);

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsShow(false);
    }
  };

  const getCountryFlag = (country: string) => {
    switch (country) {
      case "US":
        return <UsaFlag />;
      case "CA":
        return <CanadaFlag />;
      case "NGA":
        return <NigeriaFlag />;
      case "*":
        return null;
    }
  };

  return (
    <div ref={wrapperRef}>
      <div x-data={`{ dropdownOpen: ${isShow} }`} className="relative">
        <button
          onClick={opendropdown}
          className={classNames(
            "p-2 px-3",
            "relative z-10 cursor-pointer w-26",
            "flex items-center justify-between gap-x-2",
            "border border-gray-300 rounded-md shadow-sm",
            "hover:bg-gray-100"
          )}
        >
          <div className="flex items-center mr-2">
            <span className={country === "*" ? "hidden" : "mr-2"}>
              {country === "NGA" ? <NigeriaFlag /> : country === "US" ? <UsaFlag /> : <CanadaFlag />}
            </span>
            <span className="min-w-max text-sm">{getCountryName(country)}</span>
          </div>
          <span className={`dropdown ${isShow ? "svg-rotate" : ""}`}>
            <ChevronDownIcon className="h-5 w-5" />
          </span>
        </button>

        <div
          x-show="dropdownOpen"
          className={classNames(
            "w-full flex flex-col bg-white rounded-sm shadow-md w-26",
            "min-w-max absolute z-10 countrydropdown overflow-hidden transition transform origin-top",
            dropdown === "flex" ? "flex opacity-1 translate-y-0" : "h-0 opacity-0 -translate-y-2 p-0"
          )}
        >
          {options?.map((country: { name: string }, index: number) => {
            return (
              country.name !== "*" && (
                <div
                  key={`country-icon-${index}`}
                  onClick={() => selectcountry(country.name)}
                  className={classNames(
                    "flex items-center justify-start gap-x-2 py-2 px-3 cursor-pointer",
                    "hover:bg-gray-100",
                    "text-sm"
                  )}
                >
                  <span className={getCountryFlag(country.name) ? "icon" : "hidden"}>
                    {getCountryFlag(country.name)}
                  </span>
                  <span className="text-sm">{getCountryName(country.name)}</span>
                </div>
              )
            );
          })}
          <div
            onClick={() => selectcountry("*")}
            className="text-center py-2 px-3 cursor-pointer hover:bg-gray-100 text-sm"
          >
            Others
          </div>
        </div>
      </div>
    </div>
  );
};
