import { APPCONFIG } from "../config";

export const ALLAPIS = {
  AUTH: {
    SEND_DEVICE_DETAILS: `${APPCONFIG.api}/api/user/device-details`,
    GET_REFERRAL_DETAILS: `${APPCONFIG.api}/api/referral/details`,
    SETUP_REFERRAL_EMAIL: `${APPCONFIG.api}/api/onboard/setup-referral`,
    CLAIM_REWARD: `${APPCONFIG.api}/api/referral/convert`,
  },
  ASSETS: {
    GET_VEZGO_DETAILS: `${APPCONFIG.api}/api/vezgo/connect`,
  },
  LIFE_CHECK: {
    RECIPIENT: `${APPCONFIG.api}/api/recipients`,
    GET_QUESTIONS: (hash: string) => `${APPCONFIG.api}/api/recipients/no-auth/${hash}`,
    VERIFY_ANSWER: `${APPCONFIG.api}/api/recipients/no-auth/questions`,
    NON_USER_PASSWORD_SETUP: `${APPCONFIG.api}/api/recipients/no-auth/password/setup`,
    GET_RECIPIENT_PORTFOLIO: (recipientId: string) => `${APPCONFIG.api}/api/recipients/${recipientId}/portfolio`,
  },
  VAULT: {
    SET_VAULT_PIN: `${APPCONFIG.api}/api/vault/pin`,
    CHANGE_VAULT_PIN: `${APPCONFIG.api}/api/vault/pin/change`,
    FETCH_ALL_VAULT: `${APPCONFIG.api}/api/vault/documents`,
    VAULT_OTP: `${APPCONFIG.api}/api/vault/pin/recover`,
    VAULT_FOLDER: `${APPCONFIG.api}/api/vault/folder`,
    VAULT_NOTE: `${APPCONFIG.api}/api/vault/note`,
    MOVE_TO_FOLDER: `${APPCONFIG.api}/api/vault/folder/move`,
    EDIT_FILE_NAME: (fileId: number, fileName: string) => `${APPCONFIG.api}/api/vault/edit/${fileId}?name=${fileName}`,
  },
  FEEDBACK: {
    GOOGLE_FORM: "https://feedback-cova.netlify.app/.netlify/functions/server",
  },
  VEZGO: {
    GET_VEZGO_LINK: ({ client_id, origin, token, lang, redirect_uri }: any): string =>
      `https://connect.vezgo.com/connect?client_id=${client_id}&origin=${origin}&token=${token}&lang=${lang}&redirect_uri=${redirect_uri}`,
  },
};
