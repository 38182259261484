import { Helmet } from "react-helmet";

const LabelsMeta = ({ pageTitle }: { pageTitle: string }) => {
  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content="View all your portfolio by Labels" />
      <meta property="og:title" content="Beneficiary" />
      <meta property="og:description" content="View all your portfolio by Labels" />
      <meta property="og:url" content="https://app.getcova.com/portfolio/labels" />
      <meta name="twitter:card" content="View all your portfolio by Labels" />
    </Helmet>
  );
};

export default LabelsMeta;
