import { Capacitor } from "@capacitor/core";
import classNames from "classnames";
import { useState } from "react";
import Modal from "react-modal";
import PinInput from "react-pin-input";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CheckCir from "../../../assets/svg/successcheck.svg";
import MainButton from "../../../components/buttons/mainButton";
import { AlertIcon } from "../../../components/icons/alertcircle";
import { CopyIcon } from "../../../components/icons/copyicon";
import { Textinput } from "../../../components/inputs/textinput";
import { ConfirmationModal } from "../../../components/modals/ConfirmationModal";
import { Errornotf } from "../../../components/notifications/errornotf";
import { SuccessToast } from "../../../components/notifications/toasts";
import { useMFAGql } from "../../../graphql/mfa.gql-hook";
import { t } from "i18next";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const TotpMFA = () => {
  const { createTotpSecret } = useMFAGql();
  const { totp_mfa_enabled } = useSelector((state: any) => state.currentUser.preferences);
  const [isDisableDialogOpen, setIsDisableDialogOpen] = useState(false);
  const [isConfirmDisableDialogOpen, setIsConfirmDisableDialogOpen] = useState(false);
  const defaultEnableDetails = { dialogOpened: false, imgURI: "", secret: "" };
  const [enableDetails, setEnableDetails] = useState(defaultEnableDetails);

  // console.log("NEW_UPDATE_CURRENT_USER 1", totp_mfa_enabled);

  const createSecret = () => {
    createTotpSecret({
      variables: {
        tOtpAuthInput: {
          deactivate: false,
          token: null,
        },
      },
    }).then(({ data }) => {
      setEnableDetails(
        Object.assign({}, enableDetails, {
          dialogOpened: true,
          imgURI: data?.info?.image,
          secret: data?.info?.secret,
        })
      );
    });
  };

  return (
    <>
      <div className="flex mb-8 justify-between items-end securitylist-items">
        <div className="flex flex-col first-side-1">
          <p className="text-type font-medium mb-1 text-p1">Authenticator app</p>
          <p className="text-type-100 text-p2">The app will provide unique codes for you to log in with.</p>
        </div>
        <div className="flex first-side-2">
          {totp_mfa_enabled && (
            <p
              className="first-side-21 p-semibold cursor-pointer mr-1"
              onClick={() => setIsConfirmDisableDialogOpen(true)}
            >
              Remove
            </p>
          )}

          {!totp_mfa_enabled && (
            <p className="first-side-21 p-semibold cursor-pointer mr-1" onClick={() => createSecret()}>
              Set up
            </p>
          )}
        </div>
      </div>

      <TurnOnModal
        modalIsOpen={enableDetails.dialogOpened}
        closeModal={() => setEnableDetails(defaultEnableDetails)}
        details={enableDetails}
      />

      <TurnOffModal modalIsOpen={isDisableDialogOpen} closeModal={() => setIsDisableDialogOpen(false)} />

      <ConfirmationModal
        modalIsOpen={isConfirmDisableDialogOpen}
        closeModal={() => setIsConfirmDisableDialogOpen(false)}
        submitAction={() => {
          setIsConfirmDisableDialogOpen(false);
          setIsDisableDialogOpen(true);
        }}
        submitText="Turn Off"
        confirmationText={
          "Turning off your authenticator app means you can no longer use codes from your app for 2FA. You will now be required to authenticate via OTP sent to your registered phone number or email."
        }
      />
    </>
  );
};

interface ITurnOnModal {
  modalIsOpen: boolean;
  closeModal: () => void;
  details: { imgURI: string; secret: string };
}
const TurnOnModal = ({ modalIsOpen, closeModal, details }: ITurnOnModal) => {
  const { enableTotp: enableTotpApi } = useMFAGql();
  const [authCode, setAuthCode] = useState("");

  const copyOtp = () => {
    navigator.clipboard.writeText(details?.secret);
    toast.success(
      <div className="toast-div">
        <img src={CheckCir} alt="check circle" />
        Key copied to clipboard
      </div>
    );
  };

  const enableTotp = () => {
    enableTotpApi({
      variables: { code: authCode },
    }).then(({ errors }) => {
      if (errors) {
        toast.error(errors[0].message, { autoClose: 3000 });

        return;
      }

      toast(<SuccessToast message="Two-factor authentication is on" />, {
        className: "custom-toast-success",
      });
      closeModal();
    });
  };

  const isNative = Capacitor.isNativePlatform();

  if (!modalIsOpen) {
    return <></>;
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className={classNames("confirmationModal authApp-Modal relative", isNative && "native-modal")}
        overlayClassName={"modal-container-overlay"}
      >
        <div className="flex justify-end -mr-3 -translate-y-4">
          <button type="button" onClick={closeModal} className="closeicon cursor-pointer">
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>

        <div className="overflow-y-auto pb-4 md:pb-0 -mr-3 pr-2 auth262">
          <div className="text-content">
            <h3 className="text-type mb-2">Authentication App</h3>
            <p className="text-type-100 text-p2">
              Using an authenticator app like
              <span className="text-primary text-p2"> Google Authenticator</span>,
              <span className="text-primary text-p2"> Microsoft Authenticator</span>,
              <span className="text-primary text-p2"> Authy</span> or
              <span className="text-primary text-p2"> iPassword</span>, scan this QR code. It will generate a 6 digit
              code for you to enter below.
            </p>
          </div>
          <div className="my-4 flex justify-center">
            <div className="qr-code">
              <img className="mx-auto" src={details?.imgURI} alt="Authenticator app QR code" />
            </div>
          </div>
          <form
            className="mt-2"
            onSubmit={(e) => {
              e.preventDefault();
              if (authCode !== "") {
                enableTotp();
              }
            }}
          >
            <Errornotf style={{ marginBottom: "20px" }} message={null} />
            <div className="mb-2">
              <Textinput
                onChange={(e: any) => {
                  const { value } = e.target;
                  setAuthCode(value);
                }}
                label="Enter Authentication Code"
                inputid="name"
                type="text"
                value={authCode}
                autocomplete="off"
              />
            </div>
          </form>
          <div className="mt-2 mb-5 flex items-start align-middle">
            <span className="mr-2 translate-y-0.5">
              <AlertIcon color="#4F5D6D" />{" "}
            </span>
            <p className="text-type-100 text-p3 font-normal">
              If your app asks for an account name, you can use “<span className="font-medium">{t("orgName")}</span>”.
            </p>
          </div>
          <div className="p-4 mt-5 bg-primary-alice rounded-md text-center">
            <p className="text-p3 font-normal mb-4">
              <span className="font-medium">Scan not working?</span> Copy this code key and enter it manually in your
              authentication app.
            </p>
            <div
              className="text-p3 md:text-p1 text-primary font-medium flex flex-row justify-between items-center py-2 mx-auto click-item"
              onClick={copyOtp}
            >
              <span className="mr-1 qr-key">{details?.secret}</span>
              <span className="w-12 ml-4">
                <CopyIcon color="#0056CD" />
              </span>
            </div>
          </div>
        </div>

        <div className="asset-class-footer">
          <MainButton size="small" type="secondary" click={closeModal}>
            Cancel
          </MainButton>
          <MainButton
            size="small"
            type="primary"
            id="setupAuthApp"
            disabled={authCode === ""}
            click={enableTotp}
            extraClasses="ml-4"
          >
            Set up
          </MainButton>
        </div>
      </Modal>
    </div>
  );
};

const TurnOffModal = ({ modalIsOpen, closeModal }: any) => {
  const { disableTotp: disableTotpApi } = useMFAGql();
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const isNative = Capacitor.isNativePlatform();

  const disableTotp = () => {
    setErrorMessage("");

    if (code && code.length !== 6) {
      setErrorMessage("Enter a valid code");
      return;
    }

    disableTotpApi({
      variables: { code: code },
    }).then(({ errors }) => {
      if (errors) {
        setErrorMessage(errors[0].message);
        return;
      }
      toast(<SuccessToast message="Two-factor authentication is off" />, {
        className: "custom-toast-success",
      });
      closeModal();
    });
  };

  if (!modalIsOpen) {
    return <></>;
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      className={classNames("ArchiveAssetmodal postionmoal   changeemailmodal", isNative && "native-modal")}
      overlayClassName={"modal-container-overlay"}
    >
      <div>
        <div className="inner-modal-holder">
          <div className="header justify-end">
            <p onClick={closeModal} className="cursor-pointer">
              <XMarkIcon className="h-5 w-5" />
            </p>
          </div>
          <div className="achrivemessage">
            <p className="p-big">Please enter the 6 digit authentication code from your authenticator app.</p>
          </div>
          <div className="form-control mb-14">
            <PinInput
              type="numeric"
              inputMode="number"
              initialValue=""
              secret
              length={6}
              onComplete={(value) => {
                setCode(value);
              }}
              onChange={(value) => {
                setCode(value);
              }}
            />
            {errorMessage ? <small className="helper-text errormessage danger">{errorMessage}</small> : null}
          </div>
          <div className="flex justify-end mb-3 gap-4">
            <MainButton click={closeModal} type="secondary" size="big">
              Cancel
            </MainButton>
            <MainButton click={disableTotp} type="primary" size="big">
              Turn off
            </MainButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};
