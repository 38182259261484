import * as Sentry from "@sentry/browser";
import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { SWITCH_ACTIVE_PORTFOLIO, UPDATE_ACTIVE_PORTFOLIO } from "../store/actions/portfolio.actions";
import {
  X_FETCH_CLASSES,
  X_FETCH_CONNECTIONS,
  X_FETCH_INSTITUTIONS,
  X_FETCH_LABELS,
  X_FETCH_PORTFOLIO,
} from "./portfolio.gql-query";

import { DocumentNode } from "graphql/language/ast";

export const usePortfolioFactory = (gql: DocumentNode, field: string | undefined, defaultVariables = {}) => {
  const dispatch = useDispatch();

  const [fetcher, { loading, data: response, error }] = useLazyQuery(gql, {
    errorPolicy: "all",
    onCompleted: (data: any) => {
      dispatch({ type: UPDATE_ACTIVE_PORTFOLIO, payload: data.portfolio });
    },
    onError: (error) => {
      console.error("fail: usePortfolioFactory", error);
      Sentry.captureException(error);
    },
    variables: { ...defaultVariables },
  });

  const { portfolio } = response || {};
  let data = portfolio;

  if (field && portfolio) {
    data = portfolio[field];
  }

  return {
    fetcher,
    loading: loading || data === undefined,
    data,
    error,
  };
};

export const useInstitutionsLazyQuery = () => {
  return usePortfolioFactory(X_FETCH_INSTITUTIONS, "institutions");
};

export const useLabelsLazyQuery = () => {
  return usePortfolioFactory(X_FETCH_LABELS, "labels");
};

export const useClassesLazyQuery = () => {
  return usePortfolioFactory(X_FETCH_CLASSES, "classes");
};

export const usePortfolioDetailsLazyQuery = () => {
  return usePortfolioFactory(X_FETCH_PORTFOLIO, undefined);
};

export const useConnectionsLazyQuery = () => {
  return usePortfolioFactory(X_FETCH_CONNECTIONS, "connections");
};

export const useSwitchActivePortfolio = () => {
  const dispatch = useDispatch();

  return (id: number) => {
    return dispatch({ type: SWITCH_ACTIVE_PORTFOLIO, id });
  };
};
