import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useGetPlansByPartnerQuery, useGetSubscriptionQuery } from "../../../../api/subscription/subscription-queries";
import MainButton from "../../../../components/buttons/mainButton";
import { PaymentPartners, SubscriptionStatus } from "../../../../types/subscriptionTypes";
import BillingFeatures from "../../../../components/billing/BillingFeatures";
import { useUserQuery } from "../../../../api/user/user-queries";
import { useSetRevenueCatUserIdMutation } from "../../../../api/user/user-mutations";
import { Purchases, LOG_LEVEL } from "@revenuecat/purchases-capacitor";
import ActionStateToast from "../../../../components/ActionStateToast";
import SubscriptionInfo from "../../Billing/SubscriptionInfo";
import { useTranslation } from "react-i18next";
import { Capacitor } from "@capacitor/core";
import { IAPModal } from "../../../../components/modals/iap/IAPModal";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const AppleManager = ({ setShowFaq }: { setShowFaq: (showFaq: boolean) => void }) => {
  const { t } = useTranslation();
  const isNative = Capacitor.isNativePlatform();

  const { id: activePortfolioId } = useSelector((state: any) => state.activePortfolio);
  const [showPlans, setShowPlans] = useState<boolean>(false);
  const [showIAPModal, setShowIAPModal] = useState(false);

  const {
    data: subscription,
    isFetched: isFetchedSubscription,
    refetch: refetchSubscription,
  } = useGetSubscriptionQuery({
    id: activePortfolioId,
  });

  useEffect(() => {
    if (activePortfolioId) {
      refetchSubscription();
    }
  }, [activePortfolioId, refetchSubscription]);

  // In App Purchases with RevenueCat
  const { data: user } = useUserQuery();
  const { mutateAsync: setRevenueCatUserId } = useSetRevenueCatUserIdMutation();
  const { data: applePlans } = useGetPlansByPartnerQuery({
    partner: PaymentPartners.APPLE,
  });

  // Initialize RevenueCat SDK
  useEffect(() => {
    if (!isNative || subscription?.partner === PaymentPartners.STRIPE) return;

    (async function () {
      if (!process.env.REACT_APP_REVENUECAT_API_KEY) return;

      await Purchases.setLogLevel({ level: process.env.NODE_ENV === "production" ? LOG_LEVEL.INFO : LOG_LEVEL.DEBUG });
      await Purchases.configure({ apiKey: process.env.REACT_APP_REVENUECAT_API_KEY });

      const { appUserID } = await Purchases.getAppUserID();
      const revenueCatUserId = user?.revenue_cat_user_id;

      if (!revenueCatUserId || !revenueCatUserId.length) {
        await setRevenueCatUserId(appUserID);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNative, subscription?.partner]);

  const onClickManageSubscriptions = async () => {
    try {
      const data = await Purchases.getCustomerInfo();
      const { managementURL } = data?.customerInfo;
      if (!managementURL) throw new Error("No managementURL");
      window.location.href = managementURL;
    } catch (e) {
      console.error("fail: onClickManageSubscriptions", e);
    }
  };

  const onClickRestorePurchases = async () => {
    try {
      toast(<ActionStateToast message="Restoring purchases..." />);
      await Purchases.restorePurchases();
      await refetchSubscription();
    } catch (e) {
      console.error("fail: onClickRestore", e);
    }
  };

  return (
    <>
      {applePlans?.length && (
        <IAPModal
          isOpen={showIAPModal}
          closeModal={() => {
            setShowIAPModal(false);
            refetchSubscription();
          }}
        />
      )}

      {/* TODO: create AppleManager */}
      {((isFetchedSubscription && !subscription) || showPlans) && (
        <div className="no-payment m-auto max-w-2xl">
          <div className="">
            {showPlans && (
              <div className="flex justify-end">
                <button type="button" onClick={() => setShowPlans(false)} className="cursor-pointer">
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </div>
            )}

            <div className="flex justify-center w-full text-center text-gray-700 mb-6">
              <div className="text-xl font-semibold max-w-[300px]">
                {showPlans ? "Choose a Plan" : `Upgrade to get access to all ${t("orgName")} features`}
              </div>
            </div>

            <div className="w-full flex flex-col justify-center items-center gap-y-10">
              <BillingFeatures setShowFaq={setShowFaq} />

              {!subscription && isNative && (
                <MainButton type="primary" size="big" click={() => setShowIAPModal(true)}>
                  Subscribe
                </MainButton>
              )}

              <div className="bg-gray-100 py-4 px-8 text-center">
                Need help with making a choice or want to learn more?{" "}
                <span className="cursor-pointer underline" onClick={() => setShowFaq(true)}>
                  FAQs
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {subscription && !showPlans && (
        <div className="flex flex-col overflow-x-auto">
          {subscription?.partner && (
            <SubscriptionInfo subscription={subscription}>
              {!isNative ? (
                <p>Please manage your subscription through the App Store. </p>
              ) : (
                <div className="flex gap-x-3">
                  {subscription.status === SubscriptionStatus.ACTIVE ? (
                    <button
                      type="button"
                      className="font-semibold text-p2 text-primary underline"
                      onClick={onClickManageSubscriptions}
                    >
                      Manage
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="font-semibold text-p2 text-primary underline"
                      onClick={() => setShowIAPModal(true)}
                    >
                      Subscribe
                    </button>
                  )}
                  <button
                    type="button"
                    className="font-semibold text-p2 text-primary underline"
                    onClick={onClickRestorePurchases}
                  >
                    Restore Purchases
                  </button>
                </div>
              )}
            </SubscriptionInfo>
          )}

          {subscription.status !== SubscriptionStatus.ACTIVE && (
            <div className="mt-6 flex flex-col">
              <div className="flex justify-center w-full text-center text-gray-700 mb-6">
                <div className="text-xl font-semibold max-w-[300px]">
                  {showPlans ? "Choose a Plan" : `Upgrade to get access to all ${t("orgName")} features`}
                </div>
              </div>
              <BillingFeatures />
              <MainButton type="primary" size="big" extraClasses="mt-10" click={() => setShowIAPModal(true)}>
                Upgrade To Premium
              </MainButton>
            </div>
          )}
        </div>
      )}
    </>
  );
};
