import React from "react";

export const AlertIcon = ({ color }: any) => {
  return (
    <svg width="18" height="18" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.15 34C24.5833 34 24.9417 33.8583 25.225 33.575C25.5083 33.2917 25.65 32.9333 25.65 32.5V23.45C25.65 23.05 25.5 22.7083 25.2 22.425C24.9 22.1417 24.55 22 24.15 22C23.7167 22 23.3583 22.1417 23.075 22.425C22.7917 22.7083 22.65 23.0667 22.65 23.5V32.55C22.65 32.95 22.8 33.2917 23.1 33.575C23.4 33.8583 23.75 34 24.15 34ZM24 18.3C24.4667 18.3 24.8583 18.15 25.175 17.85C25.4917 17.55 25.65 17.1667 25.65 16.7C25.65 16.2333 25.4917 15.8333 25.175 15.5C24.8583 15.1667 24.4667 15 24 15C23.5333 15 23.1417 15.1667 22.825 15.5C22.5083 15.8333 22.35 16.2333 22.35 16.7C22.35 17.1667 22.5083 17.55 22.825 17.85C23.1417 18.15 23.5333 18.3 24 18.3ZM24 44C21.1667 44 18.5333 43.4917 16.1 42.475C13.6667 41.4583 11.55 40.05 9.75 38.25C7.95 36.45 6.54167 34.3333 5.525 31.9C4.50833 29.4667 4 26.8333 4 24C4 21.2 4.50833 18.5833 5.525 16.15C6.54167 13.7167 7.95 11.6 9.75 9.8C11.55 8 13.6667 6.58333 16.1 5.55C18.5333 4.51667 21.1667 4 24 4C26.8 4 29.4167 4.51667 31.85 5.55C34.2833 6.58333 36.4 8 38.2 9.8C40 11.6 41.4167 13.7167 42.45 16.15C43.4833 18.5833 44 21.2 44 24C44 26.8333 43.4833 29.4667 42.45 31.9C41.4167 34.3333 40 36.45 38.2 38.25C36.4 40.05 34.2833 41.4583 31.85 42.475C29.4167 43.4917 26.8 44 24 44ZM24 41C28.6667 41 32.6667 39.3333 36 36C39.3333 32.6667 41 28.6667 41 24C41 19.3333 39.3333 15.3333 36 12C32.6667 8.66667 28.6667 7 24 7C19.3333 7 15.3333 8.66667 12 12C8.66667 15.3333 7 19.3333 7 24C7 28.6667 8.66667 32.6667 12 36C15.3333 39.3333 19.3333 41 24 41Z"
        fill={color || "#1E2329"}
      />
    </svg>
  );
};
