import { RESET_UTILITY, SWITCH_UTILITY } from "../../actions/utility/types";

export interface UTILITY_DATA_INTERFACE {
  loader: {
    message: string;
    disappear: boolean;
  };
  sidebarclass: "";
  showSideBar: false;
  showOnboardingSteps: boolean;
  userClosedOnboarding: boolean;
  isRenew: boolean;
  showExpiredScreen: boolean;
  portfolioView: boolean;
  portfolioInfo: {
    ownerEmail: string;
    ownerName: string;
    ownerImageURL: string;
    ownerId: string;
    recipientId: string;
    totalAssetValue: number;
    totalLiabilityValue: number;
  };
  defaultCurrencySelected: boolean;
  addAccountModal: boolean;
}
const initialState = {
  showOnboardingSteps: false,
  userClosedOnboarding: false,
  isRenew: false,
  showExpiredScreen: false,
  loader: {
    message: "",
    disappear: false,
  },
  portfolioView: false,
  portfolioInfo: {
    ownerEmail: "",
    ownerName: "",
    ownerImageURL: "",
    ownerId: "",
    recipientId: "",
    totalAssetValue: 0,
    totalLiabilityValue: 0,
  },
  defaultCurrencySelected: true,
  addAccountModal: false,
  currencyDropdown: false,
};

// eslint-disable-next-line  import/no-anonymous-default-export
export default function (state = initialState, action: any) {
  switch (action.type) {
    case SWITCH_UTILITY:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_UTILITY:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
