import Modal from "react-modal";
import classNames from "classnames";
import { useState } from "react";
import { ReactComponent as Whitetick } from "../../../../assets/svg/whitetick.svg";
import { Plan, PlanIntervals } from "../../../../types/subscriptionTypes";
import { Capacitor } from "@capacitor/core";
import { useCreateSubscriptionMutation } from "../../../../api/subscription/subscription-mutations";
import MainButton from "../../../../components/buttons/mainButton";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface IChangeBillingType {
  closeModal: () => void;
  showChangeBilling: boolean;
  plans: Plan[];
}

const StripeResubscribeModal = ({ closeModal, showChangeBilling, plans }: IChangeBillingType) => {
  const [select, setSelect] = useState("");
  const isNative = Capacitor.isNativePlatform();

  const { mutateAsync: handleCreateSubscription } = useCreateSubscriptionMutation();

  const onCreateSubscription = async () => {
    const planId = select;

    try {
      await handleCreateSubscription(planId);
      closeModal();
    } catch (error) {
      console.error("fail: onCreateSubscription", error);
    }
  };

  return (
    <Modal
      isOpen={showChangeBilling}
      onRequestClose={closeModal}
      contentLabel="Change Plan Modal"
      className={classNames("Addassets manageAssets postionmoal relative h-95", isNative && "native-modal")}
      overlayClassName={"modal-container-overlay"}
    >
      <div className="header">
        <div className="header-assetsheader h4-semibold "></div>
        <button type="button" onClick={closeModal} className="cursor-pointer">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>
      <h2 className="header-assetsheader h4-semibold mb-2">Choose Plan</h2>
      <p className="p-normal benmessage mb-5 text-type-100 text-p2">
        Please choose the plan you would like to subscribe to. The new subscription will start immediately.
      </p>

      {plans.slice(0, 6).map((plan: Plan) => (
        <SinglePlan
          key={plan.id}
          text={`${plan.interval === PlanIntervals.DAY ? "dai" : plan.interval}ly billing - ${plan.currency.symbol}${
            plan.amount / 100
          } ${plan.interval === PlanIntervals.YEAR ? "(save $20)" : ""}`}
          click={() => setSelect(plan.id)}
          selected={select === plan.id}
        />
      ))}

      <div className="flex justify-end items-center w-full mt-6">
        <MainButton type="secondary" size="small" extraClasses="mr-2" click={closeModal}>
          Cancel
        </MainButton>
        <MainButton
          type="primary"
          size="small"
          click={() => {
            onCreateSubscription();
          }}
          disabled={select === ""}
        >
          Subscribe
        </MainButton>
      </div>
    </Modal>
  );
};

const SinglePlan = ({ text, current, selected, click }: any) => {
  return (
    <div
      className={`w-full my-2.5 ${
        selected ? "border-primary-beau bg-primary-alice" : "border-neutral-100 bg-white"
      }  border rounded my-2.5 text-p2 text-type px-4 py-4 flex transition-all justify-between items-center cursor-pointer`}
      onClick={click}
    >
      <div className="flex justify-start items-center font-medium capitalize">
        {text}{" "}
        {current && <p className=" text-type bg-primary-alice px-1 py-0.5 text-p3 rounded ml-1.5">Current plan</p>}
      </div>
      <div
        className={`w-5 h-5 rounded-full flex justify-center items-center  ${
          selected ? "bg-primary border-primary" : "bg-neutral-100 border-neutral-100"
        }`}
      >
        {selected && <Whitetick />}
      </div>
    </div>
  );
};

export default StripeResubscribeModal;
