import { SpinnerCircularFixed } from "spinners-react";
import classNames from "classnames";

const LoadingSpinner = ({ show, wrapperClasses, spinnerStyles = { marginTop: "12px" } }: LoadingSpinnerProps) => (
  <div className={classNames(`flex justify-center ${show ? "" : "hidden"}`, wrapperClasses)}>
    <SpinnerCircularFixed size={45} thickness={150} color="#0045A4" secondaryColor="#F5F9FF" style={spinnerStyles} />
  </div>
);

interface LoadingSpinnerProps {
  show: boolean;
  wrapperClasses?: string;
  spinnerStyles?: React.CSSProperties;
}

export default LoadingSpinner;
