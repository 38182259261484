import DashboardNetWorthCardSkeleton from "./DashboardNetWorthCardSkeleton";

const DashboardNetworthCard = ({
  balances,
  currency,
  openCurrencyPicker,
  isLoading,
  openAddAccountModal,
}: NetworthCardProps) => {
  const isEmpty = balances && balances.length === 0;

  return (
    <div className="p-4 h-full bg-white rounded-md border border-neutral-200 md:border-none">
      <div className="flex flex-col">
        <small className="text-type-100 mb-4 inline-block mr-2 text-p2">Net worth</small>

        {isLoading ? <DashboardNetWorthCardSkeleton /> : <BalancesView balances={balances} currency={currency} />}

        {isEmpty && (
          <div className="bg-gray-100 rounded-md flex justify-center">
            <div className="my-8 py-8 text-type-200 text-center">
              <p className="mb-1">No data to display yet.</p>
              <button className="underline" onClick={openAddAccountModal}>
                Connect Account
              </button>
            </div>
          </div>
        )}

        {currency && (
          <div>
            <p className="mt-5 leading-6">
              Your net worth can be distributed into their default currency. Use{" "}
              <button className="underline" onClick={openCurrencyPicker}>
                currency picker
              </button>{" "}
              to change.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

interface NetworthCardProps {
  balances: [];
  currency: string;
  openCurrencyPicker: () => void;
  isLoading: boolean;
  openAddAccountModal: () => void;
}

const BalancesView = ({ balances, currency }: BalancesViewProps) => {
  if (!balances || balances.length === 0) return null;

  return (
    <div className="grid grid-cols-2 gap-4">
      {balances
        .sort(
          (a: any, b: any) =>
            +Math.abs(a.value.toFixed(2)).toLocaleString().length - Math.abs(b.value.toFixed(2)).toLocaleString().length
        )
        .map((balance: any, i: number) => (
          <div key={i}>
            <small className="text-p3 text-type-100 font-semibold">{balance.currency}</small>
            <h2 className={`text-type ${currency ? "text-h1" : "text-h4"}`}>
              {balance.value < 0 && "-"}
              {(+Math.abs(balance.value).toFixed(2)).toLocaleString()}
            </h2>
          </div>
        ))}
    </div>
  );
};

interface BalancesViewProps {
  balances: [];
  currency: string;
}

export default DashboardNetworthCard;
