import AxoisApi from "../../api.ts";
import { ALLAPIS } from "../../api.ts/apilog";

export class Assetsservice {
  getVezgoDetails() {
    return AxoisApi.get(`${ALLAPIS.ASSETS.GET_VEZGO_DETAILS}`).then((res) => {
      return res.data;
    });
  }
}
