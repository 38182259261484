import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { CURRENT_USER } from "../../graphql/auth";
import { print } from "graphql";
import { useApi } from "../../hooks/useAPI";

export function useUserQuery(opts = {}) {
  const Api = useApi();

  const fetchCurrentUser = async () => {
    try {
      const payload = {
        operationName: "CurrentUser",
        variables: {},
        query: print(CURRENT_USER),
      };

      const data = await Api.post("", JSON.stringify(payload));
      return data?.data?.data?.currentUser;
    } catch (error) {
      console.error("fail: fetchCurrentUser", error);
      return {};
    }
  };

  return useQuery(["user"], fetchCurrentUser, {
    retry: false,
    refetchOnWindowFocus: false,
    initialData: {},
    staleTime: 1000 * 60 * 60, // 1 hour
    cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    initialDataUpdatedAt: dayjs().subtract(10, "minute").unix(),
    ...opts,
  });
}

export {};
