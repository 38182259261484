import { print } from "graphql";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../../hooks/useAPI";
import {
  ADD_LIFECHECK_STEP_GQL,
  UPDATE_LIFECHECK_GQL,
  UPDATE_LIFECHECK_STEP_GQL,
} from "../../graphql/lifecheck.gql-query";
import { REMOVE_LIFECHECK_STEP_GQL } from "../../graphql/lifecheck.gql-query";
import ToastSuccess from "../../components/toasts/ToastSuccess";
import ActionStateToast from "../../components/ActionStateToast";
import { useState } from "react";

export function useUpdateLifecheckMutation() {
  const queryClient = useQueryClient();
  const Api = useApi();
  const [lifecheckUpdateToast, setLifecheckUpdateToast] = useState<any>();

  return useMutation(
    async (variables: { id: string; lifecheck_enabled: boolean }) => {
      const payload = {
        operationName: "UpdateLifecheck",
        variables: {
          portfolio: variables,
        },
        query: print(UPDATE_LIFECHECK_GQL),
      };
      const data = await Api.post("", JSON.stringify(payload));
      return data?.data?.data?.portfolio?.lifecheck || {};
    },
    {
      onMutate: () => {
        setLifecheckUpdateToast(
          toast(<ActionStateToast message="Updating lifecheck..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: () => {
        // portfolio was updated, refetch the portfolio query
        queryClient.invalidateQueries(["portfolio"]);

        toast.dismiss(lifecheckUpdateToast);
        toast.success(<ToastSuccess message="Lifecheck updated" />, {
          autoClose: 3000,
        });
      },
      onError: () => {
        toast.dismiss(lifecheckUpdateToast);
        toast.error("Something went wrong..", {
          autoClose: 3000,
        });
      },
    }
  );
}

export function useRemoveLifecheckStepMutation() {
  const queryClient = useQueryClient();
  const Api = useApi();
  const [lifecheckStepRemoveToast, setLifecheckStepRemoveToast] = useState<any>();

  return useMutation(
    async (variables: { id: string; removeLifeCheckStepId: string }) => {
      const payload = {
        operationName: "RemoveLifeCheckStep",
        variables,
        query: print(REMOVE_LIFECHECK_STEP_GQL),
      };
      const data = await Api.post("", JSON.stringify(payload));
      return data?.data?.data?.portfolio?.lifecheck || {};
    },
    {
      onMutate: () => {
        setLifecheckStepRemoveToast(
          toast(<ActionStateToast message="Removing lifecheck step..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: () => {
        // portfolio was updated, refetch the portfolio query
        queryClient.invalidateQueries(["portfolio"]);

        toast.dismiss(lifecheckStepRemoveToast);
        toast.success(<ToastSuccess message="Lifecheck step removed." />, {
          autoClose: 3000,
        });
      },
      onError: () => {
        toast.dismiss(lifecheckStepRemoveToast);
        toast.error("Something went wrong..", {
          autoClose: 3000,
        });
      },
    }
  );
}

export function useAddLifecheckStepMutation() {
  const queryClient = useQueryClient();
  const Api = useApi();
  const [lifecheckStepAddToast, setLifecheckStepAddToast] = useState<any>();

  return useMutation(
    async (variables: {
      portfolioId: string;
      action?: string;
      triggerAfter?: number;
      triggerAfterUnit?: string;
      customNote?: string;
    }) => {
      const payload = {
        operationName: "CreateLifecheckStep",
        variables: {
          step: {
            portfolio_id: variables.portfolioId,
            ...(variables.action && { action: variables.action }),
            ...(variables.triggerAfter && { trigger_after: variables.triggerAfter }),
            ...(variables.triggerAfterUnit && { trigger_after_unit: variables.triggerAfterUnit }),
            ...(variables.customNote && { custom_note: variables.customNote }),
          },
        },
        query: print(ADD_LIFECHECK_STEP_GQL),
      };

      const data = await Api.post("", JSON.stringify(payload));
      return data?.data?.data?.steps || {};
    },
    {
      onMutate: () => {
        setLifecheckStepAddToast(
          toast(<ActionStateToast message="Adding lifecheck step..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: () => {
        // portfolio was updated, refetch the portfolio query
        queryClient.invalidateQueries(["portfolio"]);

        toast.dismiss(lifecheckStepAddToast);
        toast.success(<ToastSuccess message="Lifecheck step added." />, {
          autoClose: 3000,
        });
      },
      onError: () => {
        toast.error("Something went wrong..", {
          autoClose: 3000,
        });
      },
    }
  );
}

export function useUpdateLifecheckStepMutation() {
  const queryClient = useQueryClient();
  const Api = useApi();
  const [lifecheckStepUpdateToast, setLifecheckStepUpdateToast] = useState<any>();

  return useMutation(
    async (variables: {
      stepId: string;
      action?: string;
      triggerAfter?: number;
      triggerAfterUnit?: string;
      customNote?: string;
      contacts?: string[];
    }) => {
      // todo: When the API gets no contacts, it will remove all contacts from the step.
      const payload = {
        operationName: "UpdateLifecheckStep",
        variables: {
          step: {
            id: variables.stepId,
            ...(variables.action && { action: variables.action }),
            ...(variables.triggerAfter && { trigger_after: variables.triggerAfter }),
            ...(variables.triggerAfterUnit && { trigger_after_unit: variables.triggerAfterUnit }),
            ...(variables.customNote && { custom_note: variables.customNote }),
            ...(variables.contacts && { contacts: variables.contacts }),
          },
        },
        query: print(UPDATE_LIFECHECK_STEP_GQL),
      };
      const data = await Api.post("", JSON.stringify(payload));
      return data?.data?.data?.steps || {};
    },
    {
      onMutate: () => {
        setLifecheckStepUpdateToast(
          toast(<ActionStateToast message="Updating lifecheck step..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: () => {
        // portfolio was updated, refetch the portfolio query
        queryClient.invalidateQueries(["portfolio"]);

        toast.dismiss(lifecheckStepUpdateToast);
        toast.success(<ToastSuccess message="Lifecheck step updated." />, {
          autoClose: 3000,
        });
      },
      onError: () => {
        toast.dismiss(lifecheckStepUpdateToast);
        toast.error("Something went wrong..", {
          autoClose: 3000,
        });
      },
    }
  );
}
