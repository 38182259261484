import React, { useRef } from "react";
import { Line } from "react-chartjs-2";
import useWindowSize from "../../../hooks/useWindowSize";
import useElementSize from "../../../hooks/useElementSize";
import { ReactComponent as AlertIcon } from "../../../assets/svg/alert-circle.svg";
import { HistoryType } from "../../../types/accountTypes";

interface IGraphView {
  data: HistoryType[];
  days: number;
}

export const GraphView = ({ data, days }: IGraphView) => {
  const { width } = useWindowSize();
  const isMobile = width < 558 ? true : false;

  const divRef = useRef(null);
  const dimensions = useElementSize(divRef);

  const graphData = {
    labels: data.map((item: HistoryType) => new Date(item.interval).toDateString()),
    datasets: [
      {
        data: data.map((item: HistoryType) => item.money.value),
        fill: true,
        backgroundColor: "#EBF3FF",
        borderColor: "#0056CD",
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    },
    pointHitRadius: 1,
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return value.toLocaleString();
        },
      },
      mode: "index",
      intersect: false,
    },
    scales: {
      xAxes: [
        {
          display: false,
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          type: "logarithmic",
          display: false,
          gridLines: {
            display: false,
          },
          userCallback: function (value: any, index: any, values: any) {
            return Number(value).toLocaleString();
          },
        },
      ],
    },
    maintainAspectRatio: false,
    aspectRatio: 1,
    hover: {
      mode: "index",
      intersect: false,
    },
    elements: {
      point: {
        radius: days === 30 ? 2 : 0,
      },
    },
  };

  return (
    <React.Fragment>
      {data.length < 2 ? (
        <div className="text-sm p-3 rounded-md notification-message mx-auto history">
          <AlertIcon className="mr-2 my-auto inline" />
          Gathering history data for this asset, please come back.
        </div>
      ) : (
        <div className="chartdatachart" style={{ height: "100%" }} ref={divRef.current}>
          <Line data={graphData} height={dimensions.height} width={isMobile ? 400 : 500} options={options} redraw />
        </div>
      )}
    </React.Fragment>
  );
};
