import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

interface FeedbackBtnProps {
  label?: string;
  onClick?: () => void;
}

const FeedbackBtn = (props: FeedbackBtnProps) => {
  const { t } = useTranslation();
  const { label = t("giveFeedback"), onClick } = props;

  return (
    <button
      type="button"
      onClick={onClick}
      className="w-full flex items-center justify-center gap-x-2 rounded-md hover:opacity-80"
    >
      <ChatBubbleBottomCenterTextIcon className="w-5 h-5" />
      <span className="text-sm text-white">{label}</span>
    </button>
  );
};

export default FeedbackBtn;
