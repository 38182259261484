import Skeleton from "react-loading-skeleton";

const BillingSkeletons = () => (
  <div className="flex flex-col gap-y-4 w-full">
    {[...Array(2)].map((_, index) => (
      <div key={index} className="w-full">
        <Skeleton width={"100%"} height={50} borderRadius={4} />
      </div>
    ))}
  </div>
);

export default BillingSkeletons;
