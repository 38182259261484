import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import GoogleAuthBtn from "../../../components/buttons/GoogleAuthBtn";
import AppleAuthBtn from "../../../components/buttons/AppleAuthBtn";
import { Capacitor } from "@capacitor/core";
import { APPCONFIG } from "../../../config";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CURRENT_USER, FETCH_APPLE_URL, SIGNUP_USER } from "../../../graphql/auth";
import { toast } from "react-toastify";
import { loaderData, setUtilityData } from "../../../store/actions/utility";
import { CurrentUser } from "../../../types/authTypes";
import { SET_USER_STATE } from "../../../store/reducers/userdatav2/types";
import { useForm, Controller } from "react-hook-form";
import { Errornotf } from "../../../components/notifications/errornotf";
import { Textinput } from "../../../components/inputs/textinput";
import { TextinputwithLeftIcon } from "../../../components/inputs/textinputwithlefticon";
import { TickIcon } from "../../../components/icons/tickicon";
import MainButton from "../../../components/buttons/mainButton";
import { useTranslation } from "react-i18next";
import { XMarkIcon } from "@heroicons/react/24/outline";
interface ISignup {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}

const SignupPanelSignin = () => {
  const isNative = Capacitor.isNativePlatform();
  const isAndroid = Capacitor.getPlatform() === "android";
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [ispassword, setispassword] = useState(true);
  const [errorMessage, seterrorMessage] = useState(null);
  const [token, setToken] = useState<string>("");

  const [getCurrentUser, { data: currentUserResponse }] = useLazyQuery(CURRENT_USER, {
    context: {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
    errorPolicy: "all",
    onCompleted: () => {
      const payload: { currentUser: CurrentUser } = { ...currentUserResponse, token };

      dispatch({
        type: SET_USER_STATE,
        payload: payload,
      });

      dispatch(setUtilityData(loaderData(false, "")));

      localStorage.setItem("token", token);
      navigate("/report");
    },
    onError() {
      toast.error("Something went wrong");
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  // Sign up with Google
  const googlesignin = () => {
    window.location.href = `${APPCONFIG.api?.trim()}/oauth2/authorize/google?redirect_uri=${process.env.REACT_APP_GOOGLE?.trim()}`;
  };

  const onClickGoogleSignin = async () => {
    try {
      if (isNative) {
        const result = await GoogleAuth.signIn();
        navigate(`/signin?code=${result?.serverAuthCode}`);
      } else {
        googlesignin();
      }
    } catch (error) {
      console.error("Google Sign-In failed:", error);
    }
  };

  // Sign up with Apple
  const [fetchAppleUrl] = useMutation(FETCH_APPLE_URL, {
    onCompleted(data: any) {
      window.location.href = data.signInWithAppleUrl;
    },
    onError(error: any) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  const onClickAppleSignin = async () => {
    if (isNative) {
      try {
        // TODO: This isn't ideal, but passing the authorizationCode to the signin page results in a clientId mismatch error
        navigate(`/signin?apple_sso_code=${"signup"}`);

        // TODO: Preferred approach
        // const result: SignInWithAppleResponse = await SignInWithApple.authorize(appleSignInOptions);
        // if (result && result?.response?.authorizationCode) {
        //   const { authorizationCode } = result?.response;
        //   history.push(`/signin?apple_sso_code=${authorizationCode}`);
        // }
      } catch (error) {
        console.error("Apple Sign-In failed:", error);
      }
      return;
    } else {
      // Web-based Apple Sign-In
      fetchAppleUrl({
        variables: {
          clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
          subdomain: process.env.REACT_APP_APPLE_SUBDOMAIN,
        },
      });
    }
  };

  // Sign up with Email + Password
  const { handleSubmit, errors, control } = useForm({ criteriaMode: "all", mode: "onChange" });

  const onSubmit = (data: any) => {
    let requestdata = {
      first_name: data.firstname,
      last_name: data.lastname,
      email: data.email,
      password: data.password,
    };

    dispatch(setUtilityData(loaderData(true, "")));
    handleSignup(requestdata);
  };

  const changeinputField = () => {
    setispassword(!ispassword);
  };

  useEffect(() => {
    if (token) {
      dispatch(setUtilityData(loaderData(true, "")));
      getCurrentUser();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    let parseddata: any = queryString.parse(location.search);

    if (parseddata?.token) {
      setToken(parseddata.token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [signup] = useMutation(SIGNUP_USER, {
    errorPolicy: "all",
    // onCompleted(data) {},
    // onError(error) {},
  });

  const handleSignup = (payload: ISignup) => {
    signup({
      variables: {
        signUpInput: payload,
      },
    })
      .then((data) => {
        navigate(`/verify-email?email=${encodeURIComponent(payload.email)}`);
        dispatch(setUtilityData(loaderData(false, "")));
      })
      .catch((error) => {
        dispatch(setUtilityData(loaderData(false, "")));
        seterrorMessage(error.message);
      });
  };

  const { t } = useTranslation();

  return (
    <div className="secondhalf">
      <div className="max-w-md w-full">
        <div className="flex justify-between mb-8">
          <h3 className="text-h3 font-medium">{t("auth.signup.header")}</h3>
          <small
            onClick={() => {
              navigate("/signin");
            }}
            className="flex justify-center items-center text-p1 font-semibold text-primary cursor-pointer"
          >
            Sign in
          </small>
        </div>

        {/* Single Sign On Options */}
        <div className="flex flex-col gap-y-4 mb-4">
          <GoogleAuthBtn onClick={onClickGoogleSignin} />
          {!isAndroid && <AppleAuthBtn onClick={onClickAppleSignin} />}
        </div>

        <div className="border-b border-neutral-200 mb-4 pb-2">
          <p className="text-p2 text-type-200 text-center">Or</p>
        </div>

        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Errornotf style={{ marginBottom: "20px" }} message={errorMessage} />
            <div className="grid grid-cols-1 gap-0 md:grid-cols-2 md:gap-4">
              <div className="mb-4">
                <Controller
                  name="firstname"
                  defaultValue={null}
                  rules={{ required: true }}
                  control={control}
                  render={(props) => (
                    <Textinput
                      onChange={(e: any) => {
                        props.onChange(e.target.value);
                      }}
                      checked={props.value}
                      label="Firstname"
                      inputid="firstname"
                      type="text"
                      placeholder="John"
                      iserror={errors.firstname}
                      message={"This field is required"}
                    />
                  )}
                />
              </div>
              <div className="mb-4">
                <Controller
                  name="lastname"
                  defaultValue={null}
                  rules={{ required: true }}
                  control={control}
                  render={(props) => (
                    <Textinput
                      onChange={(e: any) => {
                        props.onChange(e.target.value);
                      }}
                      checked={props.value}
                      label="Lastname"
                      inputid="lastname"
                      type="text"
                      placeholder="Doe"
                      iserror={errors.lastname}
                      message={"This field is required"}
                    />
                  )}
                />
              </div>
            </div>
            <div className="mb-4">
              <Controller
                name="email"
                defaultValue={null}
                rules={{ required: true }}
                control={control}
                render={(props) => (
                  <Textinput
                    onChange={(e: any) => {
                      props.onChange(e.target.value);
                    }}
                    checked={props.value}
                    label="Email"
                    inputid="email"
                    type="email"
                    placeholder="name@email.com"
                    iserror={errors.email}
                    message={"Please provide a valid email address."}
                  />
                )}
              />
            </div>
            <div className="mb-4">
              <Controller
                name="password"
                defaultValue={null}
                rules={{
                  validate: {
                    isUpper: (value) => /[A-Z]/.test(value),
                    is8Digit: (value) => value && value.length >= 8,
                    isNumber: (value) => /\d/.test(value),
                  },
                }}
                control={control}
                render={(props) => (
                  <TextinputwithLeftIcon
                    onChange={(e: any) => {
                      props.onChange(e.target.value);
                    }}
                    checked={props.value}
                    type={ispassword ? "password" : "text"}
                    onclickicon={changeinputField}
                    label="Password"
                    inputid="password"
                    placeholder="8 characters, 1 upper case letter and 1 number."
                    iserror={errors.email}
                  />
                )}
              />
              {errors.password ? (
                <div className="">
                  <p className="flex text-type mb-1 font-medium items-center text-p3 justify-start">
                    {errors && errors.password && errors.password.types && errors.password.types.is8Digit ? (
                      <span className="mr-1">
                        <XMarkIcon className="h-5 w-5 text-red-500" />
                      </span>
                    ) : (
                      <span className="mr-1">
                        <TickIcon color="#4EB547" />
                      </span>
                    )}
                    <span>8 characters</span>
                  </p>
                  <p className="flex text-type mb-1 font-medium items-center text-p3 justify-start">
                    {errors && errors.password && errors.password.types && errors.password.types.isUpper ? (
                      <span className="mr-1">
                        <XMarkIcon className="h-5 w-5 text-red-500" />
                      </span>
                    ) : (
                      <span className="mr-1">
                        <TickIcon color="#4EB547" />
                      </span>
                    )}
                    <span>1 UPPER CASE letter</span>
                  </p>
                  <p className="flex text-type mb-1 font-medium items-center text-p3 justify-start">
                    {errors && errors.password && errors.password.types && errors.password.types.isNumber ? (
                      <span className="mr-1">
                        <XMarkIcon className="h-5 w-5 text-red-500" />
                      </span>
                    ) : (
                      <span className="mr-1">
                        <TickIcon color="#4EB547" />
                      </span>
                    )}
                    <span>1 number</span>
                  </p>
                </div>
              ) : null}
            </div>
            <div className="">
              <MainButton type="primary" size="big" extraClasses="w-full mb-4">
                <span className="text-p1">Sign Up</span>
              </MainButton>
            </div>
          </form>
        </div>
        <div className="w-11/12 mx-auto">
          <p className="text-center text-p2 text-type">
            By clicking Sign Up, I agree to the{" "}
            <span
              onClick={() => {
                navigate("/terms-and-condition");
              }}
              className="cursor-pointer text-primary font-semibold"
            >
              Terms & Condition{" "}
            </span>
            and{" "}
            <span
              onClick={() => {
                navigate("/privacy");
              }}
              className="cursor-pointer text-primary font-semibold"
            >
              Privacy Policy
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignupPanelSignin;
