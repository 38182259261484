import { useState } from "react";
import { print } from "graphql";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SET_DEFAULT_PAYMENT_METHOD, SET_REVENUE_CAT_USER_ID, UPDATE_USERDATA } from "../../graphql/auth";
import { useApi } from "../../hooks/useAPI";
import ActionStateToast from "../../components/ActionStateToast";

export function useUpdateUserdataMutation() {
  const queryClient = useQueryClient();
  const Api = useApi();
  const [userUpdateToast, setUserUpdateToast] = useState<any>();

  return useMutation(
    async (variables: any) => {
      const payload = {
        operationName: "UpdateUserData",
        variables: {
          updateDataInput: variables,
        },
        query: print(UPDATE_USERDATA),
      };
      const data = await Api.post("", JSON.stringify(payload));
      return data?.data?.data?.updateUserData;
    },
    {
      onMutate: () => {
        setUserUpdateToast(
          toast(<ActionStateToast message="Updating user data..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: () => {
        toast.dismiss(userUpdateToast);

        toast("User updated!", {
          autoClose: 3000,
          type: "success",
        });

        // user was updated, refetch the user query
        queryClient.invalidateQueries(["user"]);
      },
      onError: () => {
        toast.dismiss(userUpdateToast);
        toast("Something went wrong..", {
          autoClose: 3000,
          type: "error",
        });
      },
      onSettled: () => {
        toast.dismiss(userUpdateToast);
        setUserUpdateToast(null);
      },
    }
  );
}

export function useUpdateUserAvatarMutation() {
  const queryClient = useQueryClient();
  const Api = useApi({ "Content-Type": "multipart/form-data" });

  const [userUpdateToast, setUserUpdateToast] = useState<any>();

  return useMutation(
    async (variables: { file: File; updateDataInput: any }) => {
      const formData = new FormData();

      formData.append(
        "operations",
        JSON.stringify({
          operationName: "UpdateUserData",
          variables: { file: null, updateDataInput: variables.updateDataInput },
          query: print(UPDATE_USERDATA),
        })
      );

      formData.append("map", JSON.stringify({ "1": ["variables.file"] }));
      formData.append("1", variables.file);

      const data = await Api.post("", formData);
      return data?.data?.data?.updateUserData;
    },
    {
      onMutate: () => {
        setUserUpdateToast(
          toast(<ActionStateToast message="Updating user avatar..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: () => {
        toast.dismiss(userUpdateToast);
        toast("Avatar updated!", {
          autoClose: 3000,
          type: "success",
        });
        queryClient.invalidateQueries(["user"]);
      },
      onError: () => {
        toast.dismiss(userUpdateToast);
        toast("Something went wrong..", {
          autoClose: 3000,
          type: "error",
        });
      },
      onSettled: () => {
        toast.dismiss(userUpdateToast);
        setUserUpdateToast(null);
      },
    }
  );
}

export function useSetRevenueCatUserIdMutation() {
  const queryClient = useQueryClient();
  const Api = useApi();

  return useMutation(
    async (revenueCatUserId: string) => {
      const payload = {
        operationName: "SetRevenueCatUserId",
        variables: {
          revenueCatUserId,
        },
        query: print(SET_REVENUE_CAT_USER_ID),
      };

      await Api.post("", JSON.stringify(payload));
      return true;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user"]);
      },
      onError: () => {
        console.error("Something went wrong..");
      },
    }
  );
}

export function useUpdateUserDefaultPaymentMethodMutation() {
  const queryClient = useQueryClient();
  const Api = useApi();

  const [userUpdateToast, setUserUpdateToast] = useState<any>();

  return useMutation(
    async (paymentMethodId: string) => {
      const payload = {
        operationName: "SetDefaultPaymentMethod",
        variables: {
          id: paymentMethodId,
        },
        query: print(SET_DEFAULT_PAYMENT_METHOD),
      };

      const data = await Api.post("", JSON.stringify(payload));

      const hasErrors = data?.data?.errors?.length > 0;
      if (hasErrors) {
        throw new Error(data?.data?.errors[0]?.message);
      }

      return true;
    },
    {
      onMutate: () => {
        setUserUpdateToast(
          toast(<ActionStateToast message="Updating default payment method..." />, {
            autoClose: false,
          })
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["user"]);
        toast.dismiss(userUpdateToast);
        toast("Payment method updated!", {
          autoClose: 3000,
          type: "success",
        });
      },
      onError: () => {
        toast.dismiss(userUpdateToast);
        toast("Something went wrong..", {
          autoClose: 3000,
          type: "error",
        });
      },
    }
  );
}
