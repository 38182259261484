import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Modal from "react-modal";
import MainButton from "../../buttons/mainButton";
import Tick from "../../../assets/svg/tick.svg";
import { Errornotf } from "../../notifications/errornotf";
import { loaderData, setUtilityData } from "../../../store/actions/utility";
import { SuccessToast } from "../../notifications/toasts";
import { useMutation } from "@apollo/client";
import { REMOVE_USER_ACCOUNT, REMOVE_USER_ACCOUNT_FEEDBACK } from "../../../graphql/auth";
import { logoutaUser } from "../../../store/actions/userdata";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { XMarkIcon } from "@heroicons/react/24/outline";

const feedbackOptions = [
  {
    slug: "AFP",
    comment: "I can’t afford the plans",
  },
  {
    slug: "TRP",
    comment: "I only wanted to try out the product",
  },
  {
    slug: "SER",
    comment: "The service didn’t meet my expectations",
  },
  {
    slug: "CAS",
    comment: "I couldn’t connect my assets",
  },
  {
    slug: "EGH",
    comment: "I don’t use it enough",
  },
];

const DeleteAccountModal = ({ closeModal, afterOpenModal, modalIsOpen, customStyles, secondaryText }: any) => {
  const subscriptionId = useSelector((state: any) => state.portfolios.subscriptionId);
  const [selectedOption, setSelectedOption] = useState("");
  const [manualReason, setManualReason] = useState("");
  const [errorMessage, seterrorMessage] = useState<any>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [removeuseraccount] = useMutation(REMOVE_USER_ACCOUNT, {
    onCompleted: () => {
      toast(<SuccessToast message="Thanks for sharing your feedback. You have successfully deleted your account." />, {
        className: "custom-toast-success",
      });
      dispatch(setUtilityData(loaderData(false, "")));
      dispatch(logoutaUser({}))
        // @ts-expect-error
        .then(() => {
          navigate("/signin");
        });
    },
    onError(error: any) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  const [removeuseraccountfeedback] = useMutation(REMOVE_USER_ACCOUNT_FEEDBACK, {
    onCompleted: () => {
      removeuseraccount({
        variables: {
          subscriptionId: `${subscriptionId}`,
        },
      });
    },
    onError(error: any) {
      toast.error("Could not send feedback, please try again later.");
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  const submitAction = () => {
    seterrorMessage(null);
    if (selectedOption === "" && manualReason === "") {
      seterrorMessage("Please tell us a reason");
    } else {
      seterrorMessage(null);
      let data;
      if (selectedOption !== "") {
        const option = feedbackOptions.find((el) => el.slug === selectedOption);
        data = {
          feedback_slug: selectedOption,
          comment: option?.comment,
        };
      } else {
        data = {
          feedback_slug: "OTH",
          comment: manualReason,
        };
      }
      dispatch(setUtilityData(loaderData(true, "")));

      removeuseraccountfeedback({
        variables: {
          input: data,
        },
      });
    }
  };

  const isNative = Capacitor.isNativePlatform();

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        className={classNames("postionmoal relative h-full delete-account", isNative && "native-modal")}
        overlayClassName={"modal-container-overlay"}
      >
        <div className="flex justify-between items-center">
          <div className="text-type h4-semibold"></div>
          <button type="button" onClick={closeModal} className="closeicon cursor-pointer">
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>
        <h2 className="header-assetsheader h4-semibold mb-3">Delete Your Account</h2>
        <div className="mt-2 mb-6 text-sm text-type-100">
          We are sorry to hear this. If it is something we can fix, we would be happy to help{" "}
          <a className="font-semibold text-primary underline" href="mailto:support@getcova.com">
            here
          </a>
          . If not, we would love to get some feedback from you to help us improve our product.
        </div>

        <Errornotf style={{ marginBottom: "20px" }} message={errorMessage} />

        <div className="border-neutral-200 border rounded px-4 py-5 mb-14 sm:mb-10 reasons-list">
          <p className="text-p1 sm:text-h4 mb-4 font-medium">Why are you leaving?</p>
          {feedbackOptions.map((el, index) => (
            <div
              key={`option-${index}`}
              className={`w-full mb-2 text-type px-3 py-2.5  flex justify-between items-center gap-3 border rounded font-medium
              ${selectedOption === el.slug ? "border-primary-beau bg-primary-alice" : "bg-none border-neutral-200"}`}
              onClick={() => {
                setSelectedOption(el.slug);
                setManualReason("");
              }}
            >
              <div className="text-p3 sm:text-p2 text-type font-normal flex justify-start items-center gap-1 w-full ">
                <div
                  className={`border rounded-full w-4 h-4 p-0.5 transition grid mr-2 place-items-center cursor-pointer ${
                    selectedOption === el.slug ? "border-primary-cobalt bg-primary-cobalt" : "border-neutral-200 "
                  }`}
                >
                  {selectedOption === el.slug && <img src={Tick} alt="tick" className="w-3 h-3" />}
                </div>

                <div className="flex justify-between items-center cursor-pointer w-full">
                  <p>{el.comment}</p>
                </div>
              </div>
            </div>
          ))}
          <form
            className="mt-6"
            onSubmit={(e) => {
              e.preventDefault();
              if (selectedOption !== "" || manualReason !== "") {
                submitAction();
              }
            }}
          >
            <div className="flex flex-col">
              <label className="text-p2 font-medium text-type-100 mb-1" htmlFor="reasonForDeletion">
                Other Reason
              </label>
              <input
                className="bg-neutral-300 rounded placeholder-type-200 text-type text-p3 sm:text-p2 font-normal px-2 py-3 transition focus:border-type focus:ring-0 border-neutral-200"
                id="reasonForDeletion"
                autoComplete="off"
                value={manualReason}
                placeholder="State your reason here"
                type="text"
                onChange={(e: any) => {
                  const { value } = e.target;
                  setManualReason(value);
                  setSelectedOption("");
                }}
              />
            </div>
          </form>
        </div>

        <div className="asset-class-footer">
          <button
            type="button"
            className={`font-semibold rounded text-p2 px-4 py-2 mr-4 text-center transition text-status-danger bg-status-danger-bg hover:bg-status-danger-hover
            ${selectedOption === "" && manualReason === "" ? `opacity-40 cursor-not-allowed` : `opacity-100`}
            `}
            onClick={submitAction}
          >
            Delete Account
          </button>
          <MainButton size="small" type="secondary" click={closeModal}>
            {secondaryText ? secondaryText : "Cancel"}
          </MainButton>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteAccountModal;
