import { VaultService } from "../../../services/vault";
import { formatedError } from "../../../services/formaterrors";
import { SET_USER_DATA } from "../userdata/types";

export const GET_MAIN_VAULT = "GET_MAIN_VAULT";
export const GET_VAULT_FOLDERS = "GET_VAULT_FOLDERS";
export const UPDATE_FOLDERS = "UPDATE_FOLDERS";
export const UPDATE_MAIN_VAULT = "UPDATE_MAIN_VAULT";

let vaultService = new VaultService();

export const setVaultPin = (payload: any) => (dispatch: any) => {
  return vaultService.setVaultpin(payload).then(
    (data) => {
      dispatch({
        type: SET_USER_DATA,
        payload: {
          vaultPinEnabled: true,
        },
      });
      return Promise.resolve(data);
    },

    (error) => {
      dispatch({
        type: "",
      });
      let response = formatedError(error, dispatch);
      return Promise.reject(response);
    }
  );
};

export const getAllVaultWithPin = (payload: any) => (dispatch: any) => {
  return vaultService.getAllVaultWithPin(payload).then(
    (data) => {
      let mainVault = data.find((el: any) => el?.name === "vault"); //Shina to give a more cryptic name for mainVault/add restriction
      dispatch({
        type: GET_MAIN_VAULT,
        payload: mainVault,
      });
      dispatch({
        type: GET_VAULT_FOLDERS,
        payload: data.filter((el: any) => el?.id !== mainVault?.id), //other folders
      });
      return Promise.resolve(data);
    },

    (error) => {
      dispatch({
        type: "",
      });
      let response = formatedError(error, dispatch);
      return Promise.reject(response);
    }
  );
};
export const getAllVault = () => (dispatch: any) => {
  return vaultService.getAllVault().then(
    (data) => {
      let mainVault = data.find((el: any) => el?.name === "vault"); //Shina to give a more cryptic name for mainVault/add restriction
      dispatch({
        type: GET_MAIN_VAULT,
        payload: mainVault,
      });
      dispatch({
        type: GET_VAULT_FOLDERS,
        payload: data.filter((el: any) => el?.id !== mainVault?.id), //other folders
      });
      return Promise.resolve(data);
    },

    (error) => {
      dispatch({
        type: "",
      });
      let response = formatedError(error, dispatch);
      return Promise.reject(response);
    }
  );
};

export const changeVaultPin = (payload: any) => (dispatch: any) => {
  return vaultService.changeVaultpin(payload).then(
    (data) => {
      return Promise.resolve(data);
    },

    (error) => {
      dispatch({
        type: "",
      });
      let response = formatedError(error, dispatch);
      return Promise.reject(response);
    }
  );
};

export const sendVaultOTP = () => (dispatch: any) => {
  return vaultService.sendVaultOTP().then(
    (data) => {
      return Promise.resolve(data);
    },

    (error) => {
      dispatch({
        type: "",
      });
      let response = formatedError(error, dispatch);
      return Promise.reject(response);
    }
  );
};

export const verifyVaultOTP = (payload: any) => (dispatch: any) => {
  return vaultService.verifyVaultOTP(payload).then(
    (data) => {
      return Promise.resolve(data);
    },

    (error) => {
      dispatch({
        type: "",
      });
      let response = formatedError(error, dispatch);
      return Promise.reject(response);
    }
  );
};
