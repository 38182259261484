import {
  SET_PORTFOLIOS,
  SET_PORTFOLIO,
  SET_ACTIVE_PORTFOLIO_ID,
  RESET_PORTFOLIO_DATA,
  SET_PORTFOLIO_DATA,
  SET_PORTFOLIO_SHARED_WITH_ME,
  SET_SHARED_PORTTFOLIO_COLLABORATOR,
} from "../../actions/portfoliosaction/types";

const initialState = {
  portfolios: [],
  activePortfolioId: null,
  dataFetched: false,
  portfolio: {
    created_at: "",
    id: null,
    migrated_accounts: false,
    name: "",
    connect_plaid_us: {
      expiration: "",
      link_token: "",
      request_id: "",
    },
    connect_plaid_ca: {
      expiration: "",
      link_token: "",
      request_id: "",
    },
    subscription: null,
  },
  institutions: [],
  classes: [],
  labels: [],
  balances: [],
  collaborators: [],
  collaboratorDetails: null,
  subscriptionId: "",
  portfolioSharedWithMe: {
    dataFetched: false,
    portfolios: [],
  },
  config: {
    app: {
      currencies: [],
      countries: [],
    },
  },
  lifecheck: {
    contacts: [],
    steps: [],
    enabled: false,
    history: {
      data: [],
      meta: {
        actualSize: 0,
        offset: 0,
        requestedSize: 0,
        total: 0,
      },
    },
  },
};

export default function PortfolioReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_PORTFOLIOS:
      return {
        ...state,
        portfolios: action.payload,
      };
    case SET_ACTIVE_PORTFOLIO_ID:
      return {
        ...state,
        activePortfolioId: action.payload,
      };
    case SET_PORTFOLIO:
      return {
        ...state,
        portfolio: action.payload,
      };
    case SET_PORTFOLIO_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SET_PORTFOLIO_SHARED_WITH_ME:
      return {
        ...state,
        portfolioSharedWithMe: {
          dataFetched: true,
          portfolios: action.payload,
        },
      };
    case SET_SHARED_PORTTFOLIO_COLLABORATOR:
      return {
        ...state,
        collaboratorDetails: action.payload,
      };
    case RESET_PORTFOLIO_DATA:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
