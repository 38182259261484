import { SET_NETWORTH_HISTORY } from "../../actions/report/types";
import { SET_REPORT_DATA, SET_PORTFOLIO_CHART } from "../../actions/portfoliosaction/types";
import { AccountLegacyType } from "../../../types/accountTypes";

const initialState = {
  topAssets: [],
  topLiabilities: [],
  totalAssetValueUsd: 0,
  totalLiabilityValueUsd: 0,
  history: {
    history: [],
    dataFetched: false,
  },
  topPerformingAccounts: [],
  portfolioInstitutionsChart: {
    datasets: [
      {
        backgroundColor: ["#E1E7EC", "#CDD7DF"],
        data: [100, 4],
      },
    ],
    labels: ["No data to display yet"],
  },
  portfolioClassesChart: {
    datasets: [
      {
        backgroundColor: ["#E1E7EC", "#CDD7DF"],
        data: [100, 4],
      },
    ],
    labels: ["No data to display yet"],
  },
  portfolioLabelsChart: {
    datasets: [
      {
        backgroundColor: ["#E1E7EC", "#CDD7DF"],
        data: [100, 4],
      },
    ],
    labels: ["No data to display yet"],
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
  let institutions = action.payload;

  let assets: any[] = [];
  let liabilities: any[] = [];

  interface AssetType {
    legacy_type: string;
    balance: {
      currency: string;
      symbol: string;
      value: number;
    };
    balance_usd: {
      currency: string;
      symbol: string;
      value: number;
    };
    balance_original: number;
    balance_original_usd: {
      value: number;
    };
  }

  for (let i = 0; i < institutions?.length; i++) {
    institutions[i].accounts?.forEach((acc: AssetType) => {
      if (acc.legacy_type === AccountLegacyType.asset) assets.push(acc);
      else if (acc.legacy_type === AccountLegacyType.liability) liabilities.push(acc);
    });
  }

  const totalAssetValueUsd = assets.reduce((sum: number, asset: AssetType) => sum + asset.balance_usd.value, 0);

  const totalLiabilityValueUsd = liabilities.reduce(
    (sum: number, asset: AssetType) => sum + asset.balance_usd.value,
    0
  );

  const topPerformingAccounts = assets
    .map((account) => ({
      ...account,
      growth:
        account.balance_original.value !== 0
          ? ((account.balance.value - account.balance_original.value) / account.balance_original.value) * 100
          : 0,
    }))
    .sort((a, b) => b.growth - a.growth)
    .splice(0, 2);

  const topAssets = assets.sort((a: AssetType, b: AssetType) => b.balance_usd.value - a.balance_usd.value).slice(0, 4);

  const topLiabilities = liabilities
    .sort((a: AssetType, b: AssetType) => a.balance_usd.value - b.balance_usd.value)
    .slice(0, 4);

  switch (action.type) {
    case SET_REPORT_DATA:
      return {
        ...state,
        topAssets,
        topLiabilities,
        totalAssetValueUsd,
        totalLiabilityValueUsd,
        topPerformingAccounts,
      };
    case SET_PORTFOLIO_CHART:
      return {
        ...state,
        ...action.payload,
      };
    case SET_NETWORTH_HISTORY:
      return {
        ...state,
        history: action.payload,
      };
    default:
      return state;
  }
}
