import { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { colorArray } from "../../config";
import { ClassType, InstitutionType, LabelType, SortableGroup } from "../../types/categoryTypes";
import { PageTypes } from "../../types/pageTypes";
import { BuildingLibraryIcon, TagIcon } from "@heroicons/react/24/outline";
import { AssetsIcon } from "../../components/icons/assetsicon";
import { Dropdown } from "../../components/Dropdown";
import DashboardPortfolioDistributionCardSkeleton from "./DashboardPortfolioDistributionCardSkeleton";

const SelectedViewIcons = {
  [PageTypes.institutions]: <BuildingLibraryIcon className="w-5 h-5" />,
  [PageTypes.classes]: <AssetsIcon />,
  [PageTypes.labels]: <TagIcon className="w-5 h-5" />,
};

const DashboardPortfolioDistributionCard = ({
  isLoading = false,
  institutions = [],
  classes = [],
  labels = [],
  openAddAccountModal,
}: PortfolioDistributionProps) => {
  const isMobile = window.innerWidth < 768;
  const defaultView = labels.length ? PageTypes.labels : PageTypes.institutions;
  const [selectedView, setSelectedView] = useState(defaultView);
  const isEmpty = !institutions.length && !classes.length && !labels.length;

  let groups: SortableGroup[] = institutions;

  if (selectedView === PageTypes.classes) {
    groups = classes;
  } else if (selectedView === PageTypes.labels) {
    groups = labels;
  }

  return (
    <div className="p-4 bg-white rounded-md border border-neutral-200 md:border-none w-full chartdata">
      <div className="flex flex-row justify-between items-center mb-5">
        <p className="text-p2">Portfolio Distribution</p>
        <Dropdown
          buttonContent={
            <div className="p-2 px-3 flex items-center gap-x-2 bg-gray-50 hover:bg-gray-100 border border-gray-200 rounded-md shadow-sm capitalize">
              {SelectedViewIcons[selectedView]}
              <span className="text-md font-normal">{selectedView}</span>
            </div>
          }
          contentClassName="rounded-md mt-2 mr-3 md:mr-0"
        >
          <div className="w-40 bg-white shadow-lg rounded-md px-2 py-4 flex flex-col border border-solid border-gray-100">
            {Object.values(PageTypes).map((option, i) => (
              <div
                key={i}
                className="p-2 rounded-md flex items-center gap-x-2 cursor-pointer hover:bg-gray-100 capitalize"
                onClick={() => setSelectedView(option)}
              >
                <span className="w-5 h-5 text-gray-500">{SelectedViewIcons[option]}</span>
                By {option}
              </div>
            ))}
          </div>
        </Dropdown>
      </div>

      {isLoading ? (
        <DashboardPortfolioDistributionCardSkeleton />
      ) : isEmpty ? (
        <div className="bg-gray-100 rounded-md flex justify-center items-center w-full h-full py-12">
          <div className="text-type-200 text-center">
            <p className="mb-1">No data to display yet.</p>
            <button className="underline" onClick={openAddAccountModal}>
              Connect Account
            </button>
          </div>
        </div>
      ) : (
        <div style={{ position: "relative" }}>
          <Doughnut
            redraw={false}
            data={{
              datasets: [
                {
                  backgroundColor: colorArray.slice(0, groups.length),
                  data: groups.map((group) => group.balance_usd[0].value),
                },
              ],
              labels: groups.map((group) => group.name),
            }}
            height={isMobile ? 450 : 300}
            width={isMobile ? 180 : 300}
            options={{
              maintainAspectRatio: false,
              legend: {
                position: isMobile ? "bottom" : "right",
                labels: {
                  boxWidth: 12,
                },
              },
              tooltips: {
                callbacks: {
                  label: function (tooltipItem: any, data: any) {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    const total = meta.total;
                    const currentValue = dataset.data[tooltipItem.index];
                    const percentage = parseFloat(((currentValue / total) * 100)?.toFixed(1));
                    return `${percentage}%`;
                  },
                  title: function (tooltipItem: any, data: any) {
                    return data.labels[tooltipItem[0].index];
                  },
                },
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

interface PortfolioDistributionProps {
  isLoading: boolean;
  classes: ClassType[];
  institutions: InstitutionType[];
  labels: LabelType[];
  openAddAccountModal: () => void;
}

export default DashboardPortfolioDistributionCard;
