import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import MainButton from "../../components/buttons/mainButton";
import { AlertIcon } from "../../components/icons/alertcircle";
import AddStockCrypto from "../../components/modals/connectAccount/AddStockCrypto";
import LabelAccountsModal from "../../components/modals/labels/labelAccounts";
import { AccountManager } from "../../components/modals/portfolio/AccountManager";
import SortDataDropdown from "../../components/sortcomp/SortDataDropdown";
import useAccountConnect from "../../hooks/useAccountConnect";
import usePortfolio from "../../hooks/usePortfolio";
import { ConvertNumberCur } from "../../services/utilty";
import { AccountType, CreateHoldingInput } from "../../types/accountTypes";
import { InstitutionType, LabelType } from "../../types/categoryTypes";
import { PageTypes } from "../../types/pageTypes";
import { UserSortKey } from "../../types/sortTypes";
import { lastSyncTime } from "../../components/modals/viewassets";
import { IndividualTable } from "./sharedcomponents";
import { sortGroup } from "./sort-helper";
import PortfolioSkeletons from "../portfolio/PortfolioSkeletons";
import LabelsMeta from "./labels/LabelsMeta";
import { useGetLabelsQuery } from "../../api/label/label-queries";
import { ArrowPathIcon, ChevronDownIcon, PlusIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import "./assets-v2.scss";

const PortfolioByLabels = () => {
  const { handleAccountLabel, handleUpdateLabelAccounts } = usePortfolio();
  const [labelModal, setLabelModal] = useState<boolean>(false);
  const closeModal = () => setLabelModal(false);
  const [selectedLabel, setSelectedLabel] = useState<any>({});

  const { id: portfolioId, owner: ownsPortfolio } = useSelector((state: any) => state.activePortfolio);
  const xCurrency = useSelector((state: any) => state.assets.currency.code);

  const {
    preferences: { labels_sort },
  } = useSelector((state: any) => state.currentUser);

  const {
    data: labels,
    isFetched: isFetchedLabels,
    isFetching: isFetchingLabels,
    refetch: refetchLabels,
  } = useGetLabelsQuery({
    id: portfolioId,
    currency: xCurrency,
  });

  sortGroup(labels, labels_sort); //Sorts in place

  const handleRemoveAccountLabel = async (id: string) => {
    await handleAccountLabel(id, null);
  };

  const handleSaveLabel = (accountIds: any, labelId: string) => {
    handleUpdateLabelAccounts(accountIds, labelId);
  };

  // Collapse/Expand State
  let savedClosedLabels: any = localStorage.getItem("savedClosedLabels");
  const [collapsedLabels, setCollapsedLabels] = useState<any>([]);
  const isAllCollapsed = collapsedLabels?.length === labels?.length;

  useEffect(() => {
    if (savedClosedLabels) setCollapsedLabels(JSON.parse(savedClosedLabels));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem("savedClosedLabels", JSON.stringify(collapsedLabels));
  }, [collapsedLabels]);

  // Page Text
  const pageTitle =
    ownsPortfolio === undefined || ownsPortfolio || !portfolioId ? "Portfolio by Labels" : "Shared Labels";

  return (
    <div className="p-4 md:p-8 dashboardbage acc-container">
      <div style={{ maxWidth: "1440px", margin: "0px auto" }}>
        <LabelsMeta pageTitle={pageTitle} />

        <div className="relative">
          <div className="py-2 md:py-0 flex flex-col md:flex-row gap-y-2 md:gap-y-0 justify-between items-center mb-4 sm:mb-10">
            <h2 className="font-semibold text-xl md:text-2xl">{pageTitle}</h2>

            {/* Action Bar */}
            <div className="flex items-center gap-x-2">
              {/* Add New Label */}
              <button
                className={classNames(
                  "flex bg-gray-100 hover:bg-gray-200 py-2 px-4 rounded-md items-center justify-center gap-x-1",
                  isFetchingLabels && "cursor-not-allowed",
                  "font-medium"
                )}
                onClick={() => setLabelModal(true)}
                disabled={isFetchingLabels}
              >
                <PlusIcon className="w-4 h-5" />
                <span>New Label</span>
              </button>

              {/* Reload */}
              <button
                className={classNames(
                  "bg-gray-100 hover:bg-gray-200 p-2 rounded-md flex items-center justify-center text-gray-500",
                  isFetchedLabels && isFetchingLabels && "resync"
                )}
                onClick={() => refetchLabels()}
              >
                <ArrowPathIcon className="w-5 h-5" />
              </button>

              {/* Sort */}
              <SortDataDropdown parent="Label" sortPreferenceKey={UserSortKey.LABELS} rule={labels_sort} />

              {/* Collapse All */}
              <button
                className={classNames(
                  "hidden sm:block bg-gray-100 hover:bg-gray-200 p-2 rounded-md flex items-center justify-center",
                  isFetchingLabels && "cursor-not-allowed"
                )}
                onClick={() => setCollapsedLabels(collapsedLabels?.length > 0 ? [] : labels)}
              >
                <span className={`${!isAllCollapsed ? "rotate-180" : ""} text-gray-500 transition-all`}>
                  <ChevronDownIcon className="w-5 h-5" />
                </span>
              </button>
            </div>

            {labelModal && (
              <LabelAccountsModal
                closeModal={closeModal}
                isOpen={labelModal}
                closeManageLabelModal={() => null}
                defaultLabel={selectedLabel}
                handleSaveLabel={handleSaveLabel}
              />
            )}
          </div>

          {/* Labels Table */}
          {!isFetchedLabels || isFetchingLabels ? (
            <PortfolioSkeletons />
          ) : (
            <>
              {!isFetchingLabels && labels?.length === 0 ? (
                <EmptyAssets />
              ) : (
                <div className="pb-10">
                  <div className="table-wr-sm">
                    {labels?.map((label: LabelType) => (
                      <MobileAssetsList
                        key={label.id}
                        label={label}
                        setLabelModal={setLabelModal}
                        setSelectedLabel={setSelectedLabel}
                        handleRemoveAccountLabel={handleRemoveAccountLabel}
                        handleSaveLabel={handleSaveLabel}
                      />
                    ))}
                  </div>
                  <div className="table-wr-lg labels-table">
                    {labels?.length > 0 &&
                      labels?.map((label: InstitutionType) => (
                        <IndividualTable
                          key={label.id}
                          institution={label}
                          accounts={label.accounts}
                          closedInstitutions={collapsedLabels}
                          setClosedInstitutions={setCollapsedLabels}
                          page={PageTypes.labels}
                          handleRemoveAccountLabel={handleRemoveAccountLabel}
                          handleSaveLabel={handleSaveLabel}
                        />
                      ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PortfolioByLabels;

const EmptyAssets = ({ click }: any) => {
  return (
    <div className="emptyassets">
      <div className="emptyassets-inner placecenter emptylabels">
        <p className="h3-size font-semibold mb-2 text-center">Ready to connect your accounts?</p>
        <p className="assets text-p2 md:text-p1 text-center">
          Personalize your portfolio as much as you want by labelling. Our Label feature allows you group your assests
          or liabilities according to the names you give them. This way, it&#39;s easier for you to know which is which
          and gain more control of your finances.
        </p>
        <div className="video">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/pU0PqLfb1gk"
            title="How to Create a Label"
          ></iframe>
        </div>

        <MainButton click={click} type="primary" size="big" id="add assets" extraClasses="flex">
          <span className="ml-1">Start Labeling</span>
        </MainButton>
      </div>
    </div>
  );
};

interface IMobileAssetsList {
  label: LabelType;
  setLabelModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedLabel: any;
  handleRemoveAccountLabel: (id: string) => Promise<void>;
  handleSaveLabel: (accountIds: string[], labelId: string) => void;
}
const MobileAssetsList = ({
  label,
  setLabelModal,
  setSelectedLabel,
  handleRemoveAccountLabel,
  handleSaveLabel,
}: IMobileAssetsList) => {
  const [list, showList] = useState(false);
  const activePortfolioId = useSelector((state: any) => state.portfolios.activePortfolioId);
  const { handleGetPortfolioData } = usePortfolio();
  const [modal, setModal] = useState("");
  const [addStockCryptoType, setAddStockCryptoType] = useState<"stock" | "crypto" | "">("");
  const { handleAddHolding } = useAccountConnect(addStockCryptoType);
  const [addStockCryptoModal, setAddStockCryptoModal] = useState<boolean>(false);

  const handleAddAccountClick = (label: any) => {
    setSelectedLabel({
      label: label.name,
      id: label.id,
    });
    setLabelModal(true);
  };

  const openHoldingModal = (type: "stock" | "crypto") => {
    setAddStockCryptoType(type);
    setAddStockCryptoModal(true);
  };

  const addHolding = async (values: CreateHoldingInput) => {
    await handleAddHolding(values);
    setAddStockCryptoModal(false);
    setAddStockCryptoType("");
    handleGetPortfolioData(activePortfolioId, false);
  };

  return (
    <div className="mobile-assets-list my-2">
      <div className="flex justify-between px-3 py-3" onClick={() => showList(!list)}>
        <p className="font-semibold text-p2 capitalize txt2s3">{label?.name}</p>
      </div>
      <div>
        {list && <hr />}
        {list &&
          label?.accounts?.length > 0 &&
          label?.accounts?.map((account: AccountType, index: number) => (
            <Fragment key={`row-${account.id}`}>
              {modal === `${index}` && (
                <AccountManager
                  isOpen={modal === `${index}`}
                  closeModal={() => setModal("")}
                  account={label?.accounts?.[index]}
                  handleAddCrypto={() => openHoldingModal("crypto")}
                  handleAddStocks={() => openHoldingModal("stock")}
                  handleRemoveAccountLabel={handleRemoveAccountLabel}
                  handleSaveLabel={handleSaveLabel}
                />
              )}
              {addStockCryptoModal && (
                <AddStockCrypto
                  modalOpen={addStockCryptoModal}
                  closeModal={() => {
                    setAddStockCryptoModal(false);
                    setAddStockCryptoType("");
                  }}
                  submit={addHolding}
                  type={addStockCryptoType}
                  accountId={account.id}
                />
              )}
              <div className="row flex justify-between py-2" onClick={() => setModal(`${index}`)}>
                <div className="flex items-center">
                  {account?.logo && <img src={account?.logo} alt={account?.name} className="logo" />}
                  <div className="ml-2">
                    <p className="font-medium text-p3 capitalize txt2s3 vv2">{account?.name}</p>
                    {account?.number && (
                      <small>
                        {account?.number?.length > 25 ? `${account?.number?.substring(0, 25)}...` : account?.number}
                      </small>
                    )}
                  </div>
                </div>
                <div>
                  <p className="font-semibold text-p3 text-right">
                    {ConvertNumberCur(account?.balance?.value, account?.balance?.symbol)}
                  </p>
                  <small className="text-type-200 text-right">{lastSyncTime(account?.synced_at)}</small>
                  {/* <img src={ArrowRight} alt="" className="w-1.5 h-3 ml-2" /> */}
                </div>
              </div>
            </Fragment>
          ))}
        {list && label?.accounts?.length < 1 && (
          <div className="w-full flex justify-center py-4">
            <button className="font-bold flex items-center text-p2 gap-1" onClick={() => handleAddAccountClick(label)}>
              <PlusIcon className="w-5 h-5" />
              Add account
            </button>
          </div>
        )}
        {list && (
          <div className="flex items-start justify-between table-total">
            <div className="flex items-center">
              Total{" "}
              <span className="ml-2 cursor-pointer" data-tip data-for="knowMoreTip">
                <AlertIcon />
                <ReactTooltip id="knowMoreTip" place="bottom" effect="solid" className="text-xs">
                  This is a breakdown of your accounts in their default currencies.
                </ReactTooltip>
              </span>
            </div>
            <div className="flex flex-col items-end">
              {label.balances.map(
                (
                  amount: {
                    currency: string;
                    symbol: string;
                    value: number;
                  },
                  index: number
                ) => (
                  <div className="mb-2" key={index}>
                    {ConvertNumberCur(amount.value, amount.symbol)}
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
