import Skeleton from "react-loading-skeleton";
import { getNumberBetween } from "../../components/utilities/utilityFunctions";

const ContactsSkeletons = () => {
  return (
    <div className="flex flex-col">
      {[...Array(3)].map((_, index) => (
        <div key={index} className="h-[70px] border border-neutral-300 flex items-center gap-x-12">
          <Skeleton height={20} width={100} className="hidden md:inline-block ml-6" />
          <div className="md:hidden">
            <Skeleton height={20} width={getNumberBetween(100, 250)} />
          </div>
          <div className="hidden md:inline-block">
            <Skeleton height={20} width={getNumberBetween(300, 450)} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContactsSkeletons;
