import "./auth.scss";
import { Authheader } from "./authheader";
import { TermsContainer, TermsHeaderComponent, TermsTextBodyComponent } from "./termsandcondition";
import { Helmet } from "react-helmet";
import GetStartedCTA from "../../components/ctas/GetStartedCTA";
import { useTranslation } from "react-i18next";

const TermsSubHeaderComponent = (props: any) => (
  <h4 className="terms_subhead" {...props}>
    {props.children}
  </h4>
);

export const Security = () => {
  const { t } = useTranslation();
  return (
    <div className="authpage">
      <Helmet>
        <title>Security | Your information is safe and encrypted</title>
        <meta name="description" content={`Learn more about how we secure your asset data at ${t("orgName")}`} />
        <meta property="og:title" content="Security | Your information is safe and encrypted" />
        <meta property="og:description" content={`Learn more about how we secure your asset data at ${t("orgName")}`} />
        <meta property="og:url" content="https://app.getcova.com/security" />
      </Helmet>
      <Authheader />
      <div className="max-w-7xl m-auto">
        <div className="terms_intro">
          <div className="terms_container">
            <h1>
              <span>Your</span>&nbsp;<span>security</span>
            </h1>
            <p>
              When you use {t("orgName")} to track your wealth and plan your inheritance, you are trusting us with your
              sensitive information. We understand this and are committed to keeping your information protected and
              encrypted. Please read about how we implement industry security standards with bank-grade technology and
              practices.
            </p>
          </div>
        </div>
        <div className="terms_list_body_contaner">
          <TermsContainer isSecurity>
            <TermsHeaderComponent>
              Does {t("orgName")} have access to my online bank, investment, or crypto accounts?
            </TermsHeaderComponent>
            <TermsTextBodyComponent>
              No. {t("orgName")} DOES NOT have access to your banking or crypto account credentials. We use third-party
              financial account aggregator services to connect to your accounts. Your banking credentials are directly
              sent to the respective service from your browser. {t("orgName")} uses these aggregators’ APIs to collect
              only information like your bank account; hence, {t("orgName")} cannot make any transactions on your
              behalf. All our aggregators adhere to industry-leading practices for data security, regulatory compliance,
              and privacy.
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer isSecurity>
            <TermsHeaderComponent>
              Why does {t("orgName")} enforce me to Enable Two-Factor Authentication?
            </TermsHeaderComponent>
            <TermsTextBodyComponent>
              By enabling Two Factor Authentication (2FA) on your {t("orgName")} account, whenever you sign in from a
              new computer, device, or browser, we will send a unique code to your phone number that you must input as
              part of your login; this helps keep your {t("orgName")} account secured. Security is not just about
              protecting your data and protecting access to your account. 2FA is an extra layer of protection that makes
              sure that even if anyone has access to your password, they cannot log into your {t("orgName")} account.
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer isSecurity>
            <TermsHeaderComponent>How does {t("orgName")} secure your data?</TermsHeaderComponent>
            <TermsSubHeaderComponent>At rest</TermsSubHeaderComponent>
            <TermsTextBodyComponent>
              Within {t("orgName")}’s systems, all your data is stored using AES-256 encryption with a uniquely derived
              key for each user as recommended by NIST Special Publication 800-132. Every identifiable field is
              encrypted; these include your name, email, and phone number. We also encrypt your uploaded files and are
              also encrypted. We advise {t("orgName")} users to choose a strong password, set up their two-factor
              authentication and create a PIN for their {t("orgName")} document vault. Never share your login details of
              vault PIN with anyone.
            </TermsTextBodyComponent>
            <TermsSubHeaderComponent>In Transit</TermsSubHeaderComponent>
            <TermsTextBodyComponent>
              All communications between you and {t("orgName")} are encrypted via SSL using 2048-bit certificates, and
              we require SSL on all communications. If someone tries to hijack your communication, they will still never
              be able to decrypt the data.
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer isSecurity>
            <TermsHeaderComponent>Administrative Access to your Information</TermsHeaderComponent>
            <TermsTextBodyComponent>
              {t("orgName")} operates strict internal procedures that ensure every {t("orgName")} user is protected. Our
              strict internal guidelines prevent any {t("orgName")} employee or administrator from gaining access to
              your account beyond a limited approved set of data that is important to help solve issues you raise, for
              example, e.g., triggering confirmation emails. {t("orgName")} administrators can never see your asset
              values, financial data or view your beneficiaries. {t("orgName")} employees can never see your uploaded
              documents and files. {t("orgName")} logs and regularly audits all accesses to your account.
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer isSecurity>
            <TermsHeaderComponent>What happens if {t("orgName")} is breached?</TermsHeaderComponent>
            <TermsSubHeaderComponent>At rest</TermsSubHeaderComponent>
            <TermsTextBodyComponent>
              Within {t("orgName")}’s systems, all your data is stored using AES-256 encryption with a uniquely derived
              key for each user as recommended by NIST Special Publication 800-132. Every identifiable field is
              encrypted; these include your name, email, and phone number. We also encrypt your uploaded files and are
              also encrypted. We advise {t("orgName")} users to choose a strong password, set up their two-factor
              authentication and create a PIN for their {t("orgName")} document vault. Never share your login details of
              vault PIN with anyone.
            </TermsTextBodyComponent>
            <TermsSubHeaderComponent>In Transit</TermsSubHeaderComponent>
            <TermsTextBodyComponent>
              All communications between you and {t("orgName")} are encrypted via SSL using 2048-bit certificates, and
              we require SSL on all communications. If someone tries to hijack your communication, they will still never
              be able to decrypt the data.
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer isSecurity>
            <TermsHeaderComponent>
              What happens to the data I delete from my {t("orgName")} account?{" "}
            </TermsHeaderComponent>
            <TermsTextBodyComponent>
              When you delete data from your account, we delete all the data from our primary database and notify our
              aggregators to stop connecting your account and delete everything from their end. We purge our backup
              every 15 days. Your data will be removed from the backup FOREVER in the next backup purge cycle.
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer isSecurity>
            <TermsHeaderComponent>Regular audits</TermsHeaderComponent>
            <TermsTextBodyComponent>
              Security for us is not a race; it’s a marathon. We regularly audit our code and processes for security
              issues and apply patches as we move along. We work with industry security leaders to ensure our security
              protocols are up to date.
            </TermsTextBodyComponent>
          </TermsContainer>
        </div>
        <div className="pb-20">
          <GetStartedCTA />
        </div>
      </div>
    </div>
  );
};
