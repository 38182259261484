import "react-tabs/style/react-tabs.css";

export const EmptyNoteComponent = ({ title, message }: { title: string; message: string }) => {
  return (
    <div className="w-full text-center">
      <div className="text-lg font-semibold">{title}</div>
      <div className="text-sm">
        <p>{message}</p>
      </div>
    </div>
  );
};
