import { PaymentMethod, Plan } from "./subscriptionTypes";
import { Currency } from "./accountTypes";

export enum TwoFaTypes {
  OTP = "OTP",
  TOTP = "TOTP",
}

export type CurrentUser = {
  about_us: string | null;
  coupon: string | null;
  auth_methods: AuthMethods[];
  currencies: Currency[];
  email: string;
  first_name: string;
  id: number;
  image_url: string | null;
  is_referred: boolean | null;
  is_verified: boolean;
  last_name: string;
  onboardingSteps: OnboardingSteps;
  payment_methods: PaymentMethod[];
  phone_number: string;
  preferences: Preferences;
  signup_date: Date;
};

export type Config = {
  countries: Country[];
  currencies: Currency[];
  environment: "production" | "development";
  plans: Plan[];
  null_currency_name: string;
};

export interface OnboardingSteps {
  is2FAauthenticated: boolean;
  isAccountConnected: boolean;
  isPortfolioShared: boolean;
  isRecipientAdded: boolean;
  isVaultConnected: boolean;
}

export interface Preferences {
  default_currency: null | string;
  two_fa_auth: {
    hasSmsEnabled: boolean;
    hasTotpEnabled: boolean;
  };
}

export interface Country {
  name: string;
  flag: any;
  partners: string[];
}

export type UserState = {
  currentUser: CurrentUser;
  config: Config;
  token: string;
};

export enum AuthMethods {
  USERNAME_PASSWORD = "USERNAME_PASSWORD",
  GOOGLE = "GOOGLE",
}
