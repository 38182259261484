import { ReactComponent as Arrow } from "../../assets/svg/arrow-left.svg";

export const SidebarBacktoProfileBtn = (props: { onClickBackToProfile: (e: any) => void }) => {
  const { onClickBackToProfile } = props;

  return (
    <div onClick={onClickBackToProfile} className="w-full flex items-center text-sm text-white cursor-pointer">
      <span className="filter brightness-0 invert-100 mr-2">
        <Arrow />
      </span>
      Back to my profile
    </div>
  );
};
