import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { usePlaidLink } from "react-plaid-link";
//@ts-expect-error
import MonoConnect from "@mono.co/connect.js";
import { loaderData, setUtilityData } from "../store/actions/utility";
import useReconnectAsset from "./useReconnectAsset";
import usePortfolio from "./usePortfolio";
import { Capacitor } from "@capacitor/core";

export const useReconnectPlaid = () => {
  const dispatch = useDispatch();
  const { getReconnectData } = useReconnectAsset();
  const { handleResync } = usePortfolio();
  const activePortfolioId = useSelector((state: any) => state.portfolios.portfolio);
  const [plaidTrigger, setPlaidTrigger] = useState(0);

  const [config, setConfig] = useState({
    token: "",
    onSuccess: (public_token: any, _metadata: any) => {
      handleResync();
      toast.success("Sucessfully reconnected, refreshing portfolio");
    },
    onLoad: () => {},
    onExit: (_err: any, _metadata: any) => {
      toast.error("Reconnection cancelled");
    },
    onEvent: (_eventName: any, _metadata: any) => {},
  });

  const { ready, open } = usePlaidLink(config);

  const isNative = Capacitor.isNativePlatform();

  useEffect(() => {
    if (ready) {
      open();
    }

    if (open && isNative) {
      const body = document.getElementsByTagName("body")[0];
      body.style.setProperty("padding-top", "60px", "important");
    } else {
      const body = document.getElementsByTagName("body")[0];
      body.style.removeProperty("padding-top");
    }

    // Cleanup function to reset padding when component unmounts
    return () => {
      const body = document.getElementsByTagName("body")[0];
      body.style.removeProperty("padding-top");
    };
  }, [ready, open, isNative]);

  const monoConnect = (reauth_token: string) => {
    const monoInstance = new MonoConnect({
      onClose: (reponse: any) => {
        handleResync();
        toast.success("Sucessfully reconnected, refreshing portfolio");
      },
      onLoad: () => {},
      onSuccess: () => {},
      key: process.env.REACT_APP_MONO_KEY,
    });

    if (reauth_token) {
      monoInstance.reauthorise(reauth_token);
    } else {
      monoInstance.setup();
    }

    return monoInstance;
  };

  useEffect(() => {
    if (plaidTrigger !== 0) open();

    // eslint-disable-next-line
  }, [plaidTrigger]);

  const reconnectDisconnectedPlaid = async (reconnect_data: any) => {
    dispatch(setUtilityData(loaderData(true, "")));
    const res = await getReconnectData(activePortfolioId.id, reconnect_data.id);

    switch (reconnect_data.partner) {
      case "PLAID":
        if (!res?.data?.portfolio?.reconnect_data) {
          toast.error("Error reconnecting account");
          dispatch(setUtilityData(loaderData(false, "")));
          return;
        }
        setConfig({
          ...config,
          token: res.data.portfolio.reconnect_data?.link_token,
        });
        setTimeout(() => {
          setPlaidTrigger(Math.random());
          dispatch(setUtilityData(loaderData(false, "")));
        }, 1200);
        break;
      case "MONO":
        {
          if (!res?.data?.portfolio?.reconnect_data?.token) {
            dispatch(setUtilityData(loaderData(true, "")));
            return;
          }
          const getMonoInstance = monoConnect(res.data.portfolio.reconnect_data.token);
          getMonoInstance.open();
        }
        break;
      default:
        break;
    }
  };

  return {
    reconnectDisconnectedPlaid,
  };
};
