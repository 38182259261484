import { useEffect, useState } from "react";
import { ReactComponent as Info } from "../../assets/svg/alert-circle.svg";
import { ReactComponent as EmptyPageIllustration } from "../../assets/svg/lifecheck-girl.svg";
import { ReactComponent as GirlIllus } from "../../assets/svg/lifecheck1.svg";
import { LifecheckActions, LifecheckStepType } from "../../types/lifecheckTypes";
import { AddEditLifecheckStep } from "./AddEditStepModal";
import StepsLayout from "./Step";
import SetupSkeletons from "./SetupSkeletons";
import MainButton from "../../components/buttons/mainButton";
import { AddContactsToStepModal } from "./ChooseContactsModal";
import { XMarkIcon } from "@heroicons/react/24/outline";

const SetUp = ({
  contacts,
  lifecheck,
  isLoading = false,
}: {
  contacts: any[];
  lifecheck: any;
  isLoading?: boolean;
}) => {
  const steps = lifecheck?.steps;

  const [modalsState, setModalsState] = useState({
    isAddOpen: false,
    isAddContactsOpen: false,
    isDeleteOpen: false,
    step: Object.assign({}),
  });

  // Info Card
  const [showLifeCheckInfoCard, setShowLifecheckInfoCard] = useState(true);

  useEffect(() => {
    const storedValue = localStorage.getItem("showLifeCheckInfoCard");
    if (!storedValue) return;
    setShowLifecheckInfoCard(storedValue === "true");
  }, []);

  const onCloseLifecheckInfoCard = () => {
    setShowLifecheckInfoCard(false);
    localStorage.setItem("showLifeCheckInfoCard", "false");
  };

  return (
    <div>
      <AddContactsToStepModal
        isOpen={modalsState.isAddContactsOpen && modalsState.step}
        contacts={contacts}
        closeModal={() => setModalsState({ ...modalsState, isAddContactsOpen: false })}
        step={modalsState.step}
      />

      {modalsState.isAddOpen && (
        <AddEditLifecheckStep
          contacts={contacts}
          closeModal={(latestStep?: LifecheckStepType) => {
            setModalsState({ ...modalsState, isAddOpen: false });

            if (latestStep) {
              setModalsState({ ...modalsState, step: latestStep, isAddOpen: false, isAddContactsOpen: true });
            }
          }}
          step={undefined}
          onAddContact={(latestStep: LifecheckStepType) => {
            setModalsState({ ...modalsState, step: latestStep, isAddOpen: false, isAddContactsOpen: true });
          }}
        />
      )}

      <div>
        {showLifeCheckInfoCard && (
          <div className={`relative bg-primary-alice rounded-lg mt-5`}>
            <div className="absolute top-4 right-4">
              <button
                type="button"
                onClick={onCloseLifecheckInfoCard}
                className="cursor-pointer hover:bg-gray-200 hover:text-gray-700"
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>

            <div className="px-4 md:px-7 flex pb-4 md:pb-0 pt-5 items-center justify-between">
              <div className="max-w-[500px] sm:pb-5">
                <p className="text-lg font-semibold mb-3">How We Transfer Your Portfolio</p>
                <p>
                  Life Check is here to ensure that your assets are protected and that your loved ones have access to
                  your wealth in case of unexpected events.{" "}
                  <a
                    href="https://getcova.com/blog/managing-beneficiary-to-your-wealth/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-600 font-semibold hidden sm:inline md:ml-1"
                  >
                    Learn More
                  </a>
                </p>
              </div>
              <div className="hidden sm:block h-[150px]">
                <EmptyPageIllustration />
              </div>
            </div>
            <div className="flex sm:hidden items-center justify-between">
              <a
                href="https://getcova.com/blog/managing-beneficiary-to-your-wealth/"
                target="_blank"
                rel="noreferrer"
                className="text-blue-600 font-semibold pl-4"
              >
                Learn More
              </a>
              <GirlIllus />
            </div>
          </div>
        )}

        {lifecheck?.enabled && (
          <div className="mt-5 flex items-center">
            <Info />
            <p className="font-medium text-status-warning ml-2">
              Lifecheck rules can only be updated when it is disabled/inactive
            </p>
          </div>
        )}

        {isLoading ? (
          <div className="mt-8">
            <SetupSkeletons />
          </div>
        ) : (
          <div className="pb-20">
            {steps?.map((step: any) => (
              <StepsLayout key={step.id} step={step} />
            ))}

            {!steps?.find((step: any) => step.action === LifecheckActions.SHARE_PORTFOLIO) && (
              <div className="w-full md:w-auto md:ml-20 mt-10 flex justify-center md:justify-start">
                <MainButton
                  size="small"
                  type="primary"
                  click={() => setModalsState({ ...modalsState, isAddOpen: true, step: undefined })}
                >
                  + Add step
                </MainButton>
              </div>
            )}

            {!showLifeCheckInfoCard && (
              <div className="w-full flex justify-center lg:justify-end px-5 lg:px-0 mt-14">
                <a
                  href="https://getcova.com/blog/managing-beneficiary-to-your-wealth/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-600 font-semibold hover:underline"
                >
                  Learn More About Lifecheck
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SetUp;
