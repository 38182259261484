import { gql } from "@apollo/client";

export const SIGNUP_USER = gql`
  mutation SignUp($signUpInput: SignUpInput!) {
    signUp(signUpInput: $signUpInput)
  }
`;

export const VERIFY_USER_EMAIL = gql`
  mutation VerifyAccount($verifyInput: VerifyEmailInput!) {
    verifyAccount(verifyInput: $verifyInput) {
      email
    }
  }
`;

export const RESEND_VERIFY_CODE = gql`
  mutation ResendAccountVerifyCode($email: String!) {
    resendAccountVerifyCode(email: $email)
  }
`;

export const LOGIN_USER = gql`
  mutation SignIn($signInInput: SignInInput!) {
    signIn(signInInput: $signInInput) {
      accessToken
      hasSmsEnabled
      hasTotpEnabled
      is2FAAuthenticated
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($resetPasswordInput: ResetPasswordInput!) {
    resetPassword(resetPasswordInput: $resetPasswordInput)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($password: PasswordInput!) {
    changePassword(password: $password) {
      email
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      about_us
      coupon
      auth_methods
      currencies
      email
      first_name
      id
      image_url
      is_referred
      is_verified
      last_name
      apple_sso_id
      revenue_cat_user_id
      onboardingSteps {
        is2FAauthenticated
        isAccountConnected
        isPortfolioShared
        isRecipientAdded
        isVaultConnected
      }
      payment_methods {
        is_default
        card_brand
        card_country
        card_exp_month
        card_exp_year
        card_last4
        created_at
        id
        partner
        type
      }
      phone_number
      preferences {
        default_currency
        two_fa_auth {
          hasSmsEnabled
          hasTotpEnabled
        }
      }
      signup_date
    }
  }
`;

export const USER_CONFIG = gql`
  query Config($partner: String!) {
    config {
      countries
      currencies
      environment
      plans(partner: $partner) {
        active
        amount
        code
        currency
        id
        interval
        partner
      }
      null_currency_name
    }
  }
`;

export const UPDATE_PREFERENCES = gql`
  mutation UpdatePreferences($preferences: PreferencesInput!) {
    updatePreferences(preferences: $preferences) {
      default_currency
    }
  }
`;

export const SETUP_SMS_2FA = gql`
  mutation SetupSmsAuth($smsAuthInput: SmsAuthInput!) {
    setupSmsAuth(smsAuthInput: $smsAuthInput)
  }
`;

// TODO: Remove this
export const SETUP_TOTP_2FA = gql`
  mutation SetupTOTPAuth($tOtpAuthInput: TOTPAuthInput) {
    setupTOTPAuth(tOTPAuthInput: $tOtpAuthInput) {
      imageData
      secret
    }
  }
`;

export const FETCH_GOOGLE_URL = gql`
  mutation SignInWithGoogleUrl($redirectUri: String!) {
    signInWithGoogleUrl(redirectUri: $redirectUri)
  }
`;

export const LOGIN_WITH_GOOGLE_URL = gql`
  mutation SignInWithGoogle($code: String!, $redirectUri: String) {
    signInWithGoogle(code: $code, redirectUri: $redirectUri) {
      email
      accessToken
      hasSmsEnabled
      hasTotpEnabled
      is2FAAuthenticated
    }
  }
`;

export const LOGIN_WITH_MFA = gql`
  mutation SignInWithMFA($mfaCode: String!) {
    signInWithMFA(mfa_code: $mfaCode) {
      accessToken
      email
      hasSmsEnabled
      hasTotpEnabled
      is2FAAuthenticated
      refreshToken
    }
  }
`;

export const UPDATE_USERDATA = gql`
  mutation UpdateUserData($updateDataInput: UpdateDataInput!, $file: Upload) {
    updateUserData(updateDataInput: $updateDataInput, file: $file) {
      about_us
      auth_methods
      coupon
      currencies
      email
      first_name
      id
      image_url
      is_referred
      is_verified
      last_name
      onboardingSteps {
        is2FAauthenticated
        isAccountConnected
        isPortfolioShared
        isRecipientAdded
        isVaultConnected
      }
      payment_methods {
        card_brand
        card_country
        card_exp_month
        card_exp_year
        card_last4
        created_at
        id
        partner
        type
      }
      phone_number
      preferences {
        default_currency
        two_fa_auth {
          hasSmsEnabled
          hasTotpEnabled
        }
      }
      signup_date
    }
  }
`;

export const SET_REVENUE_CAT_USER_ID = gql`
  mutation SetRevenueCatUserId($revenueCatUserId: String!) {
    setRevenueCatUserId(revenueCatUserId: $revenueCatUserId)
  }
`;

export const CHANGE_PHONE_NUMBER = gql`
  mutation ChangePhoneNumber($phoneNumber: String!) {
    changePhoneNumber(phoneNumber: $phoneNumber)
  }
`;

export const VERIFY_PHONE_NUMBER_UPDATE = gql`
  mutation VerifyChangePhoneNumber($verifyInput: VerifyPhoneNumberInput!) {
    verifyChangePhoneNumber(verifyInput: $verifyInput)
  }
`;

export const CHANGE_EMAIL = gql`
  mutation ChangeEmail($email: String!) {
    changeEmail(email: $email)
  }
`;

export const VERIFY_EMAIL_UPDATE = gql`
  mutation VerifyChangeEmail($verifyInput: VerifyEmailInput!) {
    verifyChangeEmail(verifyInput: $verifyInput)
  }
`;

export const REMOVE_USER_ACCOUNT = gql`
  mutation RemoveUserAccount($subscriptionId: String) {
    removeUserAccount(subscriptionId: $subscriptionId) {
      id
    }
  }
`;

export const REMOVE_USER_ACCOUNT_FEEDBACK = gql`
  mutation RemoveUserAccountFeedback($input: AccountDeletionFeedbackInput!) {
    removeUserAccountFeedback(input: $input)
  }
`;

// Apple
export const FETCH_APPLE_URL = gql`
  mutation signInWithAppleUrl($clientId: String!, $subdomain: String!) {
    signInWithAppleUrl(clientId: $clientId, subdomain: $subdomain)
  }
`;

export const LOGIN_WITH_APPLE_URL = gql`
  mutation SignInWithApple($code: String!, $clientId: String) {
    signInWithApple(code: $code, clientId: $clientId) {
      accessToken
      email
      hasSmsEnabled
      hasTotpEnabled
      is2FAAuthenticated
      isNewUser
      refreshToken
    }
  }
`;

export const SET_DEFAULT_PAYMENT_METHOD = gql`
  mutation SetDefaultPaymentMethod($id: String!) {
    setDefaultPaymentMethod(id: $id) {
      id
    }
  }
`;
