import Modal from "react-modal";
import { memo, useCallback, useState } from "react";
import { toast } from "react-toastify";
import { VaultNoteManage } from "./VaultNoteManage";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { EmptyNoteComponent } from "../portfolio/assets";
import { VaultFileDropzone } from "./VaultFileDropzone";
import MainButton from "../../components/buttons/mainButton";
import "./vault.scss";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const VaultFolderDocumentsModal = memo(
  ({ modalIsOpen, afterOpenModal, closeModal, customStyles, folder, uploadAction, addNoteAction, disabled }: any) => {
    const [note, setNote] = useState({ id: 0, title: "", entry: "" });
    const [addNote, setaddNote] = useState(false);

    let openAddNote = () => {
      setaddNote(true);
    };

    const onDrop = useCallback((files) => {
      uploadAction(files);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitAddNote = () => {
      if (note?.entry === "" || note?.title === "") {
        toast.error("Please enter a title and description");
        return;
      }
      let data = {
        description: note?.entry,
        title: note?.title,
        vault_folder_id: folder?.id,
      };

      addNoteAction(data);
    };

    const isNative = Capacitor.isNativePlatform();

    return (
      <div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          className={classNames("viewassetsdetails postionmoal", isNative && "native-modal")}
          overlayClassName={"modal-container-overlay"}
        >
          <div className="flex w-full justify-end items-center">
            <button type="button" onClick={closeModal} className="cursor-pointer">
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>
          {addNote ? (
            <VaultNoteManage
              note={note}
              setNote={setNote}
              submitAction={submitAddNote}
              cancelAction={() => setaddNote(false)}
              closeModal={closeModal}
              isNewNote
            />
          ) : (
            <>
              <div className="header">
                <h2 className="header-assetsheader h4-semibold">Documents</h2>
              </div>
              <div className="viewassets-tabs">
                <Tabs>
                  <TabList>
                    <Tab data-cy="VaultFolderNotes">Notes</Tab>
                    <Tab data-cy="VaultFolderFiles">Files</Tab>
                  </TabList>
                  <TabPanel>
                    <div className="mb-4">
                      <div>
                        <EmptyNoteComponent
                          title={"Add a new note"}
                          message="Save special notes, instructions and important information e.g reference numbers, contact persons."
                        />
                        <div className="placecenter mt-6">
                          <MainButton click={openAddNote} type="primary" size="big">
                            Add a Note
                          </MainButton>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="mt-6">
                      <VaultFileDropzone
                        onDrop={onDrop}
                        subtitle="Upload documents, images and files about this asset e.g contracts, receipts, agreements."
                        disabled={disabled}
                      >
                        Drag and drop here or <span className="text-blue-500">Click</span> to upload. Max upload size is
                        500MB.
                      </VaultFileDropzone>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </>
          )}
        </Modal>
      </div>
    );
  }
);
