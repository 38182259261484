import React from "react";

export const VerticalMoreIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.9996 40C23.3329 40 22.7663 39.7667 22.2996 39.3C21.8329 38.8333 21.5996 38.2667 21.5996 37.6C21.5996 36.9333 21.8329 36.3667 22.2996 35.9C22.7663 35.4333 23.3329 35.2 23.9996 35.2C24.6663 35.2 25.2329 35.4333 25.6996 35.9C26.1663 36.3667 26.3996 36.9333 26.3996 37.6C26.3996 38.2667 26.1663 38.8333 25.6996 39.3C25.2329 39.7667 24.6663 40 23.9996 40ZM23.9996 26.4C23.3329 26.4 22.7663 26.1667 22.2996 25.7C21.8329 25.2333 21.5996 24.6667 21.5996 24C21.5996 23.3333 21.8329 22.7667 22.2996 22.3C22.7663 21.8333 23.3329 21.6 23.9996 21.6C24.6663 21.6 25.2329 21.8333 25.6996 22.3C26.1663 22.7667 26.3996 23.3333 26.3996 24C26.3996 24.6667 26.1663 25.2333 25.6996 25.7C25.2329 26.1667 24.6663 26.4 23.9996 26.4ZM23.9996 12.8C23.3329 12.8 22.7663 12.5667 22.2996 12.1C21.8329 11.6333 21.5996 11.0667 21.5996 10.4C21.5996 9.73333 21.8329 9.16667 22.2996 8.7C22.7663 8.23333 23.3329 8 23.9996 8C24.6663 8 25.2329 8.23333 25.6996 8.7C26.1663 9.16667 26.3996 9.73333 26.3996 10.4C26.3996 11.0667 26.1663 11.6333 25.6996 12.1C25.2329 12.5667 24.6663 12.8 23.9996 12.8Z"
        fill="#4B5D6D"
      />
    </svg>
  );
};
