import "./auth.scss";
import { useState, useRef, useEffect } from "react";
import { Authheader } from "./authheader";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { useTranslation } from "react-i18next";

export const WelcomePage = () => {
  const { t } = useTranslation();

  const WelcomeSlides = [
    {
      step: 1,
      title: "Connect and organize all your accounts securely.",
      description: "Keep track of everything you own in one dashboard.",
      image: "/welcome-step-1.svg",
      imageTransformScale: 1.7,
    },
    {
      step: 2,
      title: "Assign a recipient to your portfolio.",
      description: "Securely transfer your wealth records to your recipient in case the unexpected happens.",
      image: "/welcome-step-2.svg",
      imageTransformScale: 1.6,
    },
    {
      step: 3,
      title: "Share your portfolio with others.",
      description: "Collaborate with loved ones, financial advisors and trusted friends on your portfolios.",
      image: "/welcome-step-3.svg",
      imageTransformScale: 0.9,
    },
    {
      step: 4,
      title: `100% Peace of mind with ${t("orgName")}.`,
      description: "Create a Free Account Now. No Credit Card Required.",
      image: "/welcome-step-4.svg",
      imageTransformScale: 1.5,
    },
  ];

  const isNative = Capacitor.isNativePlatform();
  const [activeSlide, setActiveSlide] = useState(0);
  const [autoScroll, setAutoScroll] = useState(true);
  const carouselRef = useRef<HTMLDivElement>(null);
  const isUserScroll = useRef(false);

  const handleScroll = () => {
    if (isUserScroll.current) {
      setAutoScroll(false); // Disable auto-scroll on first user interaction
    }
    if (carouselRef.current) {
      const index = Math.round(carouselRef.current.scrollLeft / carouselRef.current.clientWidth);
      setActiveSlide(index);
    }
    isUserScroll.current = false; // Reset the flag
  };

  useEffect(() => {
    const container = carouselRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (autoScroll && carouselRef.current) {
      timer = setTimeout(() => {
        isUserScroll.current = false; // Set the flag to false before auto-scrolling
        const nextScrollPosition = carouselRef.current!.clientWidth * (activeSlide + 1);
        carouselRef.current!.scrollTo({
          left: nextScrollPosition,
          behavior: "smooth",
        });
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [activeSlide, autoScroll]);

  return (
    <div className={classNames("bg-white h-screen overflow-hidden", isNative && "safe-top ")}>
      <Helmet>
        <title>Welcome To {t("orgName")}</title>
        <meta name="description" content={`Welcome to ${t("orgName")}`} />
        <meta property="og:title" content={`Welcome to ${t("orgName")}`} />
        <meta
          property="og:description"
          content={`Learn about what ${t("orgName")} can do for you. Protect your wealth.`}
        />
        <meta property="og:url" content="https://app.getcova.com/welcome" />
      </Helmet>
      <Authheader />
      <div className={classNames("p-4 h-full", isNative && "safe-bottom")}>
        {/* Carousel */}
        <div ref={carouselRef} className="carousel-container my-8 mt-12">
          {WelcomeSlides.map((slide, index) => (
            <div key={index} className="carousel-item flex flex-col items-center justify-center">
              <div className="h-[200px] w-[320px] flex items-center justify-center mb-8 overflow-hidden">
                <img
                  src={slide.image}
                  alt={slide.title}
                  className={classNames("object-contain h-full w-full")}
                  style={{ transform: `scale(${slide.imageTransformScale})` }}
                />
              </div>
              <h2 className="text-xl text-blue-700 font-semibold text-center mb-4 max-w-[280px]">{slide.title}</h2>
              <p className="text-sm text-center max-w-[340px]">{slide.description}</p>
            </div>
          ))}
        </div>

        {/* Carousel Slide Indicators */}
        <div className="flex justify-center space-x-2 mb-10">
          {WelcomeSlides.map((_, index) => (
            <div
              key={index}
              className={`w-4 h-4 rounded-full ${index === activeSlide ? "bg-blue-600" : "bg-gray-300"}`}
            ></div>
          ))}
        </div>

        {/* Actions */}
        <div className="flex flex-col justify-center items-center gap-y-5 px-3">
          <a href="/signup" className="w-full text-center text-white bg-blue-600 rounded-md px-4 py-3">
            Create Account
          </a>
          <a href="/signin" className="text-blue-700 font-semibold">
            I already have an account
          </a>
        </div>
      </div>
    </div>
  );
};
