import { print } from "graphql";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../hooks/useAPI";
import { X_FETCH_LIFECHECK } from "../../graphql/lifecheck.gql-query";

export const useGetLifecheckQuery = ({
  id,
  offset = 0,
  size = 10,
  types = null,
}: {
  id: number;
  offset?: number;
  size?: number;
  types?: string[] | null;
}) => {
  const Api = useApi();
  const enabled = id !== undefined;
  const variables = { id, offset, size, types };

  return useQuery(
    ["portfolio", variables],
    async () => {
      const payload = {
        operationName: "FetchLifecheck",
        variables,
        query: print(X_FETCH_LIFECHECK),
      };

      const response = await Api.post("", JSON.stringify(payload));
      const lifecheck = response?.data?.data?.portfolio?.lifecheck;
      return lifecheck || {};
    },
    {
      enabled,
      onError: (error) => {
        console.error("fail: useGetLifecheckQuery", error);
      },
      placeholderData: {},
      staleTime: 1000 * 60 * 60, // 1 hour
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    }
  );
};
