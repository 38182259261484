import ReactTooltip from "react-tooltip";
import { useState } from "react";
import { useSelector } from "react-redux";
import VaultMeta from "./VaultMeta";
import VaultManage from "./VaultManage";
import VaultUploadToMain from "./VaultUploadToMain";
import VaultSkeleton from "./VaultSkeleton";
import { MainVaultDropdown } from "../../components/inputs/vaultdropdowns";
import { PortfolioState } from "../../types/portfolioTypes";
import { useGetFoldersQuery } from "../../api/vault/vault-queries";
import { VaultManageFileFolderModal } from "./VaultManageFileFolderModal";
import { VaultNoteModal } from "./VaultNoteModal";
import { hasActiveSubscription } from "../../components/utilities/utilityFunctions";
import { isVaultUploadEnabled } from "./vault-utils";
import "./vault.scss";

export const VaultPage = () => {
  // todo: update api to with query for subscription only? or can do a graphql query for subscription??
  const subscription = useSelector(
    (state: { portfolios: { portfolio: PortfolioState } }) => state.portfolios.portfolio.subscription
  );
  const isActiveSubscription = hasActiveSubscription(subscription);

  const [showUploader, setShowUploader] = useState(false);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [showCreateNoteModal, setShowCreateNoteModal] = useState(false);
  const { data: folders, isFetching: isFetchingVaultData } = useGetFoldersQuery({});
  const isUploadEnabled = isVaultUploadEnabled(folders, subscription);

  return (
    <div className="p-4 md:p-8 vaultpage">
      <div style={{ maxWidth: "1440px", margin: "0px auto" }}>
        <VaultMeta />
        <div className="relative">
          <div className="flex justify-between items-center mb-6 sm:mb-10">
            <h2 className="font-semibold text-xl md:text-2xl">Vault</h2>

            {/* Topbar */}
            <div className="flex">
              <MainVaultDropdown
                type="upload"
                openUploadPage={() => setShowUploader(true)}
                extraBtnClass="mr-2 sm:mr-4"
                disabled={isUploadEnabled}
              />

              <MainVaultDropdown
                type="create"
                openNewNoteModal={() => setShowCreateNoteModal(true)}
                openNewFolderModal={() => setShowCreateFolderModal(true)}
                disabled={isUploadEnabled}
              />

              {isActiveSubscription && (
                <ReactTooltip id="mainVaultTip" place="bottom" effect="solid">
                  Available to premium users only.
                </ReactTooltip>
              )}
            </div>
          </div>

          {/* Modals */}
          <VaultManageFileFolderModal
            folders={folders}
            modalIsOpen={showCreateFolderModal}
            header="Add New Folder"
            inputLabel="Folder Name"
            placeholder="Enter folder name"
            onCloseModal={() => setShowCreateFolderModal(false)}
          />

          <VaultNoteModal
            folderId={folders?.[0]?.id}
            modalIsOpen={showCreateNoteModal}
            closeModal={() => setShowCreateNoteModal(false)}
          />

          {/* Vault States */}
          {isFetchingVaultData ? (
            <VaultSkeleton />
          ) : showUploader ? (
            <VaultUploadToMain done={() => setShowUploader(false)} />
          ) : (
            <VaultManage folders={folders} onClickUpload={() => setShowUploader(true)} />
          )}
        </div>
      </div>
    </div>
  );
};

export default VaultPage;
