import dayjs from "dayjs";
import classNames from "classnames";
import { Invoice, PartnerStatusTypes } from "../../../../types/subscriptionTypes";

export const StripeInvoices = ({ invoices }: { invoices: Invoice[] }) => {
  return (
    <div>
      <p className="text-xl text-center md:text-left mb-5 md:mb-2">Invoices</p>
      <div className="overflow-x-auto">
        <table className="w-full table-auto whitespace-nowrap ">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-3 px-4 uppercase text-left">Description</th>
              <th className="py-3 px-4 uppercase text-left">Amount Paid</th>
              <th className="py-3 px-4 uppercase text-left">Date Paid</th>
              <th className="py-3 px-4 uppercase text-left">Status</th>
              <th className="py-3 px-4 uppercase text-left"></th>
            </tr>
          </thead>
          <tbody>
            {invoices?.length ? (
              invoices?.map((invoice: Invoice) => {
                return (
                  <tr key={invoice.id}>
                    {/* Description */}
                    <td className="py-3 px-4">
                      <div>{invoice.description}</div>
                    </td>

                    {/* Amount Paid */}
                    <td>
                      <div className="py-3 px-4">
                        <p>
                          {invoice.currency} {invoice.amount_paid / 100}
                        </p>
                      </div>
                    </td>

                    {/* Date Paid */}
                    <td className="py-3 px-4">
                      <p>{dayjs(invoice.created_at).format("MMM DD, YYYY")}</p>
                    </td>

                    {/* Status */}
                    <td className="py-3 px-4">
                      <p className="p-semibold">{invoice.partner_status.toUpperCase()}</p>
                    </td>

                    {/* View Receipt */}
                    <td className="py-3 px-4">
                      <a
                        href={invoice.invoice_url}
                        target="_blank"
                        rel="noreferrer"
                        className={classNames(
                          "font-semibold hover:text-gray-700",
                          invoice.partner_status === PartnerStatusTypes.paid ? "text-primary" : "text-red-500"
                        )}
                      >
                        {invoice.partner_status === PartnerStatusTypes.paid ? "View receipt" : "View invoice"}
                      </a>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  <p className="text-sm text-gray-500 py-4">No invoices found</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
