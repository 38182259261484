import React from "react";

export const NigeriaFlag = () => {
  return (
    <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="26" height="15.1667" rx="2" fill="#008751" />
      <rect x="8.66699" width="8.66667" height="15.1667" fill="white" />
    </svg>
  );
};
