import { useState } from "react";
import { toast } from "react-toastify";
import { LifecheckDuration, LifecheckStepType } from "../../types/lifecheckTypes";
import { useUpdateLifecheckStepMutation } from "../../api/lifecheck/lifecheck-mutations";
import Modal from "react-modal";
import MainButton from "../../components/buttons/mainButton";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface ILifeCheckDayModifier {
  closeModal: () => void;
  step: LifecheckStepType;
}
export const LifeCheckDayModifier = ({ closeModal, step: defaultStep }: ILifeCheckDayModifier) => {
  const { mutateAsync: updateLifecheckStep } = useUpdateLifecheckStepMutation();
  const [step, setStep] = useState(Object.assign({}, defaultStep));

  const performUpdate = async () => {
    // validation
    if (step.trigger_after_unit === LifecheckDuration.DAYS && step.trigger_after < 5) {
      toast.error("Minimum duration of 5 days");
      return;
    }

    try {
      await updateLifecheckStep({
        stepId: step.id,
        triggerAfter: step.trigger_after,
        triggerAfterUnit: step.trigger_after_unit,
        contacts: step.contacts.map((c) => c.id),
      });
      closeModal();
    } catch (e) {
      console.error("fail: performUpdate", e);
    }
  };

  const isNative = Capacitor.isNativePlatform();

  return (
    <Modal
      isOpen={true}
      onRequestClose={closeModal}
      contentLabel="Day Modifier Modal"
      className={classNames("modal-container modal-container--sm", isNative && "native-modal")}
      overlayClassName="modal-container-overlay"
    >
      {/* Header */}
      <div className="modal-header px-4 flex items-center justify-between">
        <p className="text-h4 font-semibold">Change life check period</p>
        <button type="button" onClick={closeModal} className="cursor-pointer">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>

      {/* Body */}
      <div className="modal-body px-4 md:px-8 pb-10">
        <p className="text-md text-center my-3">When would you like us to do this?</p>
        <div className="flex items-center justify-center gap-x-3">
          <select
            className="bg-neutral-200 text-p2 rounded-md border-neutral-300 cursor-pointer"
            defaultValue={step.trigger_after}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setStep({ ...step, trigger_after: +e.target.value })}
          >
            <option>1</option>
            <option>3</option>
            <option>7</option>
            <option>14</option>
            <option>30</option>
            <option>60</option>
          </select>

          <select
            className="bg-neutral-200 text-p2 rounded-md border-neutral-300 cursor-pointer"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setStep({ ...step, trigger_after_unit: e.target.value });
            }}
            defaultValue={step.trigger_after_unit}
          >
            <option value={LifecheckDuration.DAYS}>DAYS</option>
            <option value={LifecheckDuration.WEEKS}>WEEKS</option>
            <option value={LifecheckDuration.MONTHS}>MONTHS</option>
          </select>
        </div>
      </div>

      {/* Footer */}
      <div className="modal-footer p-4 gap-3 flex justify-end border-t bg-white">
        <MainButton type="secondary" size="small" click={closeModal}>
          Cancel
        </MainButton>
        <MainButton type="primary" size="small" click={() => performUpdate()}>
          Update
        </MainButton>
      </div>
    </Modal>
  );
};
