import AxoisApi from "../../api.ts";
import { ALLAPIS } from "../../api.ts/apilog";

export class Feedbackservice {
  async submit(type: string, comments: string, name: string, email: string) {
    return AxoisApi.post(
      `${ALLAPIS.FEEDBACK.GOOGLE_FORM}`,
      {
        comments,
        type,
        name,
        email,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Cookie:
            "S=spreadsheet_forms=jjx_OvH_UBBB7Mg72ZB34uJ7YtMwl72wi1pc7WYtww8; NID=214=EeUdtCG63r52WGnU0S_XGW_dmw0eBpFzP5ImHQxdOFfsNNtGbK2xe8_CHKhME95Wy_5wu321ovGkvuPjAvS-AXdsSmnJ4Ns5c5yaJK3-gNt-jPYRXVVMmkQeEHmtCEKTr9E7YaSPSNnLz-Kn8dUS-xxcMDTi99ipw0VGjlcey6c",
        },
      }
    ).then((res) => {
      return res.data;
    });
  }
}
