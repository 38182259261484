import Skeleton from "react-loading-skeleton";
import { getNumberBetween } from "../../components/utilities/utilityFunctions";

const DashboardNetWorthCardSkeleton = () => (
  <div>
    <Skeleton width={35} height={15} className="mb-2" />
    <Skeleton width={getNumberBetween(250, 320)} height={38} />
  </div>
);

export default DashboardNetWorthCardSkeleton;
