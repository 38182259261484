import { ReactComponent as Tilita } from "../../assets/svg/tilita.svg";

const BillingFeatures = ({ setShowFaq }: { setShowFaq?: (showFaq: boolean) => void }) => {
  return (
    <div className="flex flex-col justify-center gap-y-8">
      <div className="flex flex-col gap-y-3 text-md">
        <div className="flex gap-x-4">
          <Tilita />
          <p>Track your banks and brokerage accounts</p>
        </div>
        <div className="flex gap-x-4">
          <Tilita />
          <p>Track cryptocurrency exchange and wallets</p>
        </div>
        <div className="flex gap-x-4">
          <Tilita />
          <p>Enjoy unlimited vault storage for vital documents </p>
        </div>
        <div className="flex gap-x-4">
          <Tilita />
          <p>Get live cryptocurrency and stock tickers</p>
        </div>
        <div className="flex gap-x-4">
          <Tilita />
          <p>Get personalized monthly portfolio performance reports</p>
        </div>
      </div>

      {setShowFaq && (
        <div className="bg-gray-100 py-4 px-8 text-center">
          Need help with making a choice or want to learn more?{" "}
          <span className="cursor-pointer underline" onClick={() => setShowFaq(true)}>
            FAQs
          </span>
        </div>
      )}
    </div>
  );
};

export default BillingFeatures;
