import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { GraphView } from "../historyModal/index";
import { toast } from "react-toastify";
import { HistoryTypes, HistoryIntervalTypes } from "../../../types/accountTypes";
import usePortfolio from "../../../hooks/usePortfolio";

export const AccountHistory = ({ accountId }: { accountId: string }) => {
  const [history, setHistory] = useState([]);
  const [days, setDays] = useState<number>(7);
  const daysOptions = [
    { days: "7", label: "7 days" },
    { days: "30", label: "30 days" },
    { days: "90", label: "90 days" },
    { days: "180", label: "180 days" },
    { days: "365", label: "1 year" },
    { days: "100000", label: "Lifetime" },
  ];
  const { handleGetHistory } = usePortfolio();
  const defaultCurrencySelected = useSelector((state: any) => state.utility.defaultCurrencySelected);
  const currency = useSelector((state: any) => state.assets.currency.country);

  useEffect(() => {
    getHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  const getHistory = () => {
    handleGetHistory({
      currency: defaultCurrencySelected ? null : currency,
      for: HistoryTypes.ACCOUNT,
      historyId: accountId,
      interval: HistoryIntervalTypes.DAY,
      from: dayjs().subtract(days, "day").format("YYYY-MM-DD"),
      to: dayjs(new Date()).format("YYYY-MM-DD"),
    }).then((res: any) => {
      if (!res?.errors) {
        setHistory(res?.data?.history);
      } else {
        toast.error("Unable to fetch account history");
      }
    });
  };

  return (
    <div className="history-modal">
      {!(history.length === 0) ? (
        <div className="flex justify-end items-center">
          <select
            value={days}
            onChange={(e) => setDays(parseInt(e.target.value))}
            defaultValue={days}
            className="form-select border-neutral-100 bg-neutral-200 rounded-md mb-4 focus:ring focus:ring-primary-beau-hover focus:ring-opacity-50 focus:border-primary"
          >
            {daysOptions.map((item: any): any => (
              <option key={item.label} value={item.days}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
      ) : null}

      <div className={history.length > 0 ? "graph-table-wrapper" : ""}>
        <GraphView data={history} days={days} />
      </div>
    </div>
  );
};
