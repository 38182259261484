import axios from "axios";
import { APPCONFIG } from "../config";

function gettoken() {
  let token = localStorage.getItem("token") ?? null;
  return token;
}

let AxoisApi = axios.create({
  baseURL: APPCONFIG.api,
});

// Set default headers for all methods
AxoisApi.defaults.headers.common = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  Accept: "application/json",
};

AxoisApi.interceptors.response.use(
  function (response) {
    let datares = response.data;
    if (typeof datares == "object") {
      if (Number(datares?.status) === 400 || Number(datares?.status) === 401 || Number(datares?.status) === 500) {
        return Promise.reject(response);
      }
    }

    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

AxoisApi.interceptors.request.use(function (config) {
  const token = gettoken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default AxoisApi;
