import LoadingSpinner from "./LoadingSpinner";

const ActionStateToast = ({ message }: { message: string }) => (
  <div className="flex flex-row items-center align-center justify-center space-x-4">
    <LoadingSpinner show={true} wrapperClasses="w-6 h-6 mt-0" spinnerStyles={{ marginTop: "0px" }} />
    <span className="w-full">{message}</span>
  </div>
);

export default ActionStateToast;
