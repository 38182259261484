import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import MainButton from "../../components/buttons/mainButton";
import { XMarkIcon } from "@heroicons/react/24/outline";

/**
 * VaultNoteManage
 *
 * Manage the creation and editing of a note.
 */
export const VaultNoteManage = ({
  isNewNote,
  note,
  setNote,
  submitAction,
  cancelAction,
  closeModal,
  isLoading,
}: {
  isNewNote: boolean;
  note: any;
  setNote: (note: any) => void;
  submitAction: () => void;
  cancelAction: () => void;
  closeModal: () => void;
  isLoading?: boolean;
}) => {
  const [typingMode, setTypingMode] = useState(false);

  const { control } = useForm({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: {
      title: "",
    },
  });

  const startTyping = () => {
    setTypingMode(true);
  };

  return (
    <>
      {/* Header */}
      <div className="modal-header px-4 flex items-center justify-between">
        <h1 className="text-h4 font-semibold">{isNewNote ? "Add Note" : "Edit Note"}</h1>
        <button type="button" onClick={closeModal} className="cursor-pointer">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>

      {/* Add Edit Form */}
      <div className="modal-body px-4 md:px-8 pt-5 pb-10">
        <Controller
          name="title"
          rules={{ required: true }}
          control={control}
          render={() => (
            <div className="flex flex-col">
              <input
                className="mb-4 rounded-md placeholder-neutral px-2 py-3 border border-neutral-200 text-sm font-medium focus:border-primary"
                id="note-name"
                autoComplete="off"
                type="text"
                value={note?.title}
                placeholder="Title"
                maxLength={60}
                onChange={(e: any) => {
                  setNote({ ...note, title: e.target.value });
                }}
              />
            </div>
          )}
        />
        <div className="">
          <textarea
            value={note?.entry}
            onFocus={() => startTyping()}
            onChange={(e) => {
              setNote({ ...note, entry: e.target.value });
            }}
            placeholder="Type your notes here"
            name=""
            id="note-details"
            maxLength={2000}
            className="w-full resize-none h-80 md:h-44 rounded-md placeholder-neutral px-2 py-3 border border-neutral-200 text-sm font-medium"
            disabled={isLoading}
          ></textarea>
        </div>
        <div className="flex justify-end text-type-200 text-xs">
          {typingMode ? `${note?.entry?.length ?? 0}/2000` : "Maximum of 2000 characters"}
        </div>
      </div>

      {/* Footer */}
      <div className="modal-footer p-4 gap-4 flex justify-end items-center border-t bg-white">
        <MainButton click={cancelAction} type="secondary" size="small">
          Cancel
        </MainButton>
        <MainButton click={submitAction} type="primary" size="small" disabled={isLoading}>
          {isNewNote ? "Save" : "Update Note"}
        </MainButton>
      </div>
    </>
  );
};
