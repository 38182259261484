import dayjs from "dayjs";
import { ReactComponent as Mail } from "../../assets/svg/mail.svg";
import { ReactComponent as Phone } from "../../assets/svg/phone.svg";
import { ContactType } from "../../types/lifecheckTypes";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

interface IIndividualContact {
  contact: ContactType;
  onClickEdit: () => void;
  onClickDelete: () => void;
}

export const ContactRowDesktop = ({ contact, onClickEdit, onClickDelete }: IIndividualContact) => {
  return (
    <tbody className="hidden md:table-row-group">
      <tr className="w-full text-left hover:bg-gray-100">
        {/* Name */}
        <td className="border-b border-slate-300 px-4 py-3">
          <p>{contact.first_name + " " + contact.last_name}</p>
        </td>

        {/* Last Reviewed */}
        <td className="border-b border-slate-300 px-4 py-3">
          <p>{dayjs(contact.updated_at).format("D/MM/YYYY")}</p>
        </td>

        {/* Contact Details */}
        <td className="border-b border-slate-300 px-4 py-3">
          <div className="flex items-center justify-start">
            <Mail />
            <span className="inline-block ml-1">{contact.email}</span>
          </div>
          <div className="flex items-center justify-start">
            <Phone />
            <span className="inline-block ml-1">
              {"+" + contact.phone_number_country_code + "" + contact.phone_number}
            </span>
          </div>
        </td>

        {/* Actions */}
        <td className="border-b border-slate-300 px-4 py-3">
          <div className="flex items-center justify-center gap-x-3">
            <button onClick={onClickEdit}>
              <PencilIcon className="w-5 h-5 hover:text-blue-700" />
            </button>
            <button onClick={onClickDelete}>
              <TrashIcon className="w-5 h-5 hover:text-red-500" />
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  );
};
