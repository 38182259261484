import { ReactComponent as Logo } from "../assets/svg/logo1.svg";
import { ReactComponent as LifecheckLogo } from "../assets/svg/logo-lifecheck.svg";

const isProd = process.env.NODE_ENV === "production";

const OfflineScreen = () => {
  return (
    <div className="w-full h-screen flex justify-center">
      <div className="mb-48 flex flex-col justify-center items-center">
        {isProd ? <Logo width={100} height={70} /> : <LifecheckLogo width={160} height={70} />}
        <h1>You are offline</h1>
        <p>Please check your internet connection.</p>
      </div>
    </div>
  );
};

export default OfflineScreen;
