import { ReactComponent as Logo } from "../../assets/svg/logo1.svg";
import { ReactComponent as LifecheckLogo } from "../../assets/svg/logo-lifecheck.svg";
import { Link } from "react-router-dom";

export const Authheader = () => {
  const isProd = process.env.NODE_ENV === "production";
  return (
    <Link to="/" className="w-full py-4 px-4 flex items-center mb-10">
      <div className="w-36">{isProd ? <Logo /> : <LifecheckLogo />}</div>
    </Link>
  );
};
