import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { useAddAccountModalToggle, useRefreshActivePortfolio } from "../../graphql/general-store.gql-hook";
import { useConnectionsLazyQuery } from "../../graphql/portfolio.gql-hook";
import usePortfolio from "../../hooks/usePortfolio";
import CurrencySelector from "../inputs/CurrencySelector";
import { ProfileDropdown } from "../inputs/ProfileDropdown";
import ConnectAccountModal from "../modals/connectAccount/ConnectAccountModal";
import ReconnectAccount from "../modals/reconnectAccount/reconnect";
import { Bars3Icon, InformationCircleIcon, PlusIcon } from "@heroicons/react/24/outline";
interface HeaderProps {
  toggleSidebar: () => void;
}

const Header = ({ toggleSidebar }: HeaderProps) => {
  const [isReconnectUIOpened, openReconnectUI] = useState<boolean>(false);
  const { handleResyncPortfolio } = usePortfolio();
  const [refreshActivePortfolioIndicator, refreshActivePortfolio] = useRefreshActivePortfolio();

  const { id: portfolioId, connections, owner: ownsPortfolio } = useSelector((state: any) => state.activePortfolio);
  const { fetcher: fetchConnections } = useConnectionsLazyQuery();
  const { 1: openAccountModal } = useAddAccountModalToggle();

  useEffect(() => {
    if (!portfolioId) return;

    fetchConnections({ variables: { id: portfolioId } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioId, refreshActivePortfolioIndicator]);

  const inactiveConnections = (connections || []).filter(
    (next: any) => next.reconnect && ["PLAID", "MONO", "VEGZO"].indexOf(next.partner) !== -1
  );

  const reconnectCallback = () => {
    handleResyncPortfolio(portfolioId).then((res) => {
      setTimeout(() => {
        refreshActivePortfolio();
      }, 1000 * 3); // Wait for 3 seconds before refreshing the portfolio. Hopefully resync is done by then
    });
  };

  return (
    <>
      <ConnectAccountModal />

      <ReconnectAccount
        modalOpen={isReconnectUIOpened}
        closeModal={() => openReconnectUI(false)}
        disconnectedAccounts={inactiveConnections}
        reconnectCallback={reconnectCallback}
      />

      <div className="loggedin-header">
        {/* Header Left - Hamburger Menu */}
        <div className="sidebar-ham p-2 rounded-lg hover:bg-neutral-200 cursor-pointer" onClick={toggleSidebar}>
          <Bars3Icon className="w-6 h-6" />
        </div>
        <div className="hidden md:flex"></div>

        {/* Header Right */}
        <div className="h-right">
          {inactiveConnections.length > 0 && (
            <button onClick={() => openReconnectUI(true)} data-tip data-for="reconnectaccount">
              <InformationCircleIcon className="w-6 h-6 text-yellow-500" />
              <ReactTooltip id="reconnectaccount" place="bottom" effect="solid" className="reconnect-tip">
                Disconnected institutions found
              </ReactTooltip>
            </button>
          )}
          {(ownsPortfolio === undefined || ownsPortfolio) && (
            <button className="add-account-btn cursor-pointer" onClick={() => openAccountModal(true)}>
              <div className="flex items-center gap-1">
                <PlusIcon className="h-5 w-5" />
                <span>Connect</span>
              </div>
            </button>
          )}

          <div className="countries">
            <CurrencySelector />
          </div>
          <div className="hidden md:flex">
            <ProfileDropdown />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
