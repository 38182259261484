import { ConvertNumberCur } from "../../services/utilty";
import { AccountLegacyType, AccountType } from "../../types/accountTypes";
import { Doughnut } from "react-chartjs-2";
import { lastSyncTime } from "../../components/modals/viewassets";
import DashboardTopAccountsCardAssetsLiabilitiesSkeleton from "./DashboardTopAccountsCardAssetsLiabilitiesSkeleton";
import DashboardTopAccountsCardTopAccountsSkeleton from "./DashboardTopAccountsCardTopAccountsSkeleton";

const DashboardTopAccountsCard = ({ isLoading, accounts, openAddAccountModal }: TopAccountsProps) => {
  const assets = accounts
    .filter((account) => account.legacy_type === AccountLegacyType.asset)
    .sort((a, b) => b.balance_usd.value - a.balance_usd.value);
  const liabilities = accounts
    .filter((account) => account.legacy_type === AccountLegacyType.liability)
    .sort((a, b) => a.balance_usd.value - b.balance_usd.value);
  const liabilitiesUSDTotal = assets.reduce((total, account) => total + account.balance_usd.value, 0);
  const assetsUSDTotal = liabilities.reduce((total, account) => total + account.balance_usd.value, 0);

  const isEmpty = assets.length === 0 && liabilities.length === 0;

  const chartData = {
    colors: ["#2F80ED", "#F2C94C"],
    values: [assetsUSDTotal, liabilitiesUSDTotal],
    labels: ["Assets", "Liabilities"],
  };

  return (
    <div className="p-4 h-full bg-white rounded-md border border-neutral-200 md:border-none">
      <h3 className="text-p2 text-type-100 mt-0 mb-6">Assets/Liabilities</h3>

      {isLoading && <DashboardTopAccountsCardAssetsLiabilitiesSkeleton />}
      {!isLoading && !isEmpty && (
        <div>
          <Doughnut
            redraw={false}
            data={{
              datasets: [
                {
                  backgroundColor: chartData.colors,
                  data: chartData.values,
                },
              ],
              labels: chartData.labels,
            }}
            height={200}
            width={200}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                position: "bottom",
                labels: {
                  boxWidth: 12,
                },
              },
              tooltips: {
                callbacks: {
                  label: function (
                    tooltipItem: { datasetIndex: number; index: number },
                    data: {
                      datasets: Array<{ data: number[]; _meta: Record<string, { total: number }> }>;
                      labels: string[];
                    }
                  ) {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    const total = meta.total;
                    const currentValue = dataset.data[tooltipItem.index];
                    const percentage = parseFloat(((currentValue / total) * 100).toFixed(1));
                    return `${percentage}%`;
                  },
                  title: function (tooltipItem: Array<{ index: number }>, data: { labels: string[] }) {
                    return data.labels[tooltipItem[0].index];
                  },
                },
              },
            }}
          />
        </div>
      )}

      {!isLoading && isEmpty && (
        <div className="bg-gray-100 rounded-md flex justify-center">
          <div className="my-8 py-8 text-type-200 text-center">
            <p className="mb-1">No data to display yet.</p>
            <button className="underline" onClick={openAddAccountModal}>
              Connect Account
            </button>
          </div>
        </div>
      )}

      <h3 className="mt-12 text-p2 text-type-100 mb-4">Top Accounts</h3>

      {isLoading && <DashboardTopAccountsCardTopAccountsSkeleton />}

      {!isLoading &&
        assets.length > 0 &&
        assets.slice(0, 4).map((asset, idx) => (
          <div className="flex justify-between mb-4" key={idx}>
            <div>
              <p className="text-p2 text-type">
                {asset?.display_name.substring(0, 20)}
                {asset?.display_name.length > 20 && "..."}
              </p>
              <p className="text-p3 text-type-300">
                {asset?.institution?.name.substring(0, 20)}
                {asset?.institution?.name.length > 20 && "..."}
              </p>
            </div>
            <div className="ml-8 accbalance">
              <p className="text-p2 type-text text-right">
                {ConvertNumberCur(asset?.balance?.value, asset?.balance?.symbol)}
              </p>
              <p className="text-p3 text-type-300 text-right">{lastSyncTime(asset?.synced_at)}</p>
            </div>
          </div>
        ))}

      {!isLoading &&
        liabilities.length > 0 &&
        liabilities.slice(0, 4).map((liability, idx) => (
          <div className="flex justify-between mb-4" key={idx}>
            <div>
              <p className="text-p2 text-type">
                {liability?.display_name ? liability?.display_name.substring(0, 20) : liability?.name.substring(0, 25)}
                {liability?.display_name
                  ? liability?.display_name.length > 20 && "..."
                  : liability?.name.length > 20 && "..."}
              </p>
              <p className="text-p3 text-type-300">
                {liability?.institution?.name.substring(0, 20)}
                {liability?.institution?.name.length > 20 && "..."}
              </p>
            </div>
            <div className="ml-8">
              <p className="text-p2 type-text text-right">
                {ConvertNumberCur(liability?.balance?.value, liability?.balance?.symbol)}
              </p>
              <p className="text-p3 text-type-300 text-right">{lastSyncTime(liability?.synced_at)}</p>
            </div>
          </div>
        ))}

      {!isLoading && !assets.length && !liabilities.length && (
        <div className="bg-gray-100 rounded-md flex justify-center items-center">
          <div className="my-8 py-8 text-type-200 text-center">
            <p className="mb-1">No data to display yet.</p>
            <button className="underline" onClick={openAddAccountModal}>
              Connect Account
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

interface TopAccountsProps {
  accounts: AccountType[];
  isLoading: boolean;
  openAddAccountModal: () => void;
}

export default DashboardTopAccountsCard;
