import { useState } from "react";
import Modal from "react-modal";
import { useForm, Controller } from "react-hook-form";
import { TextinputwithLeftIcon } from "../../inputs/textinputwithlefticon";
import MainButton from "../../buttons/mainButton";
import { ClosedangerIcon } from "../../icons/closedanger";
import { MarksuccessIcon } from "../../icons/marksuccess";
import "./setup-password.scss";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";

const RecipientSetupPassword = ({ afterOpenModal, modalIsOpen, customStyles, submit }: any) => {
  const { handleSubmit, errors, control } = useForm({ criteriaMode: "all", mode: "onChange" });
  const [ispassword, setispassword] = useState(true);

  const onSubmit = (data: any) => {
    submit(data?.password);
  };

  const changeinputField = () => {
    setispassword(!ispassword);
  };

  const isNative = Capacitor.isNativePlatform();

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => {}}
        style={customStyles}
        contentLabel="Example Modal"
        className={classNames(`join-cova-modal postionmoal relative`, isNative && "native-modal")}
        overlayClassName={"modal-container-overlay"}
      >
        <div className="join-cova-container">
          <h2 className="header-assetsheader h4-semibold text-h4 font-semibold text-type block mb-3">
            Register your password
          </h2>

          <div className="text-type-100 mb-5 text-sm">
            Create a password to help you access this portfolio anytime without the need for the security question.
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
            <Controller
              name="password"
              defaultValue={null}
              rules={{
                validate: {
                  isUpper: (value: any) => /[A-Z]/.test(value),
                  is8Digit: (value: any) => value && value.length >= 8,
                  isNumber: (value: any) => /\d/.test(value),
                },
              }}
              control={control}
              render={(props: any) => (
                <TextinputwithLeftIcon
                  onChange={(e: any) => {
                    props.onChange(e.target.value);
                  }}
                  checked={props.value}
                  type={ispassword ? "password" : "text"}
                  onclickicon={changeinputField}
                  label="Enter password"
                  inputid="password"
                  placeholder="8 characters, 1 upper case letter and 1 number."
                  iserror={errors.email}
                />
              )}
            />
            {errors.password ? (
              <div className="">
                <p className="err-deck-1">
                  {errors && errors.password && errors.password.types && errors.password.types.is8Digit ? (
                    <span className="mr-1">
                      <ClosedangerIcon />
                    </span>
                  ) : (
                    <span className="mr-1">
                      <MarksuccessIcon />
                    </span>
                  )}
                  <span>8 characters</span>
                </p>
                <p className="err-deck-1">
                  {errors && errors.password && errors.password.types && errors.password.types.isUpper ? (
                    <span className="mr-1">
                      <ClosedangerIcon />
                    </span>
                  ) : (
                    <span className="mr-1">
                      <MarksuccessIcon />
                    </span>
                  )}
                  <span>1 UPPER CASE letter</span>
                </p>
                <p className="err-deck-1">
                  {errors && errors.password && errors.password.types && errors.password.types.isNumber ? (
                    <span className="mr-1">
                      <ClosedangerIcon />
                    </span>
                  ) : (
                    <span className="mr-1">
                      <MarksuccessIcon />
                    </span>
                  )}
                  <span>1 number</span>
                </p>
              </div>
            ) : null}

            <div className="mt-6">
              <Controller
                name="confirmPassword"
                defaultValue={null}
                rules={{
                  validate: {
                    isUpper: (value: any) => /[A-Z]/.test(value),
                    is8Digit: (value: any) => value && value.length >= 8,
                    isNumber: (value: any) => /\d/.test(value),
                  },
                }}
                control={control}
                render={(props: any) => (
                  <TextinputwithLeftIcon
                    onChange={(e: any) => {
                      props.onChange(e.target.value);
                    }}
                    checked={props.value}
                    type={ispassword ? "password" : "text"}
                    onclickicon={changeinputField}
                    label="Re-enter password"
                    inputid="confirmPassword"
                    placeholder=""
                    iserror={errors.confirmPassword}
                  />
                )}
              />
              {errors.confirmPassword && <div className="text-p3 text-status-danger mt-1">Passwords do not match</div>}
            </div>

            <MainButton type="primary" size="big" extraClasses="w-full mt-8">
              <span className="text-p1">Register Password</span>
            </MainButton>
          </form>

          <div className="text-center text-xs mt-2 select-none text-type-100">
            Click{" "}
            <span className="click-item text-primary font-semibold">
              <a
                className="text-primary font-semibold text-p2"
                href="https://getcova.com"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            </span>{" "}
            to know more about us
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RecipientSetupPassword;
