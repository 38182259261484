import { useState } from "react";
import "./auth.scss";
import { Link, useNavigate } from "react-router-dom";
import { Textinput } from "../../components/inputs/textinput";
import { Controller, useForm } from "react-hook-form";
import { loaderData, setUtilityData } from "../../store/actions/utility";
import { useDispatch } from "react-redux";
import { Errornotf } from "../../components/notifications/errornotf";
import { toast } from "react-toastify";
import { SuccessToast } from "../../components/notifications/toasts";
import { Helmet } from "react-helmet";
import MainButton from "../../components/buttons/mainButton";
import { useMutation } from "@apollo/client";
import { FORGOT_PASSWORD } from "../../graphql/auth";
import { Capacitor } from "@capacitor/core";
import classNames from "classnames";
import { ReactComponent as Logo } from "../../assets/svg/logo1.svg";
import { ReactComponent as LifecheckLogo } from "../../assets/svg/logo-lifecheck.svg";
import { useTranslation } from "react-i18next";

export const ForgotPasswordpage = () => {
  const isProd = process.env.NODE_ENV === "production";
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [forgotpassword] = useMutation(FORGOT_PASSWORD, {
    onCompleted(data) {
      navigate("/signin");
      toast(<SuccessToast message={"We sent a recovery link to your email"} />, {
        className: "custom-toast-success",
      });
      dispatch(setUtilityData(loaderData(false, "")));
    },
    onError(error: any) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  const [errorMessage, seterrorMessage] = useState(null);
  const { handleSubmit, errors, control } = useForm({});

  const onSubmit = (data: any) => {
    seterrorMessage(null);
    dispatch(setUtilityData(loaderData(true, "")));
    forgotpassword({
      variables: {
        email: data.email,
      },
    });
  };

  const isNative = Capacitor.isNativePlatform();
  return (
    <div className={classNames("authpage h-screen", isNative && "authpage--native")}>
      <Helmet>
        <title>Reset Password</title>
        <meta name="description" content={`Reset the password to your ${t("orgName")} account`} />
        <meta property="og:title" content="Reset Password" />
        <meta property="og:description" content={`Reset the password to your ${t("orgName")} account`} />
        <meta property="og:url" content="https://app.getcova.com/forgot-password" />
      </Helmet>
      <div className={classNames("forgotpassword px-4 flex justify-center", isNative && "safe-top")}>
        <div className="w-full h-full flex flex-col justify-start items-center">
          <Link to="/" className="w-full py-4 flex items-center mb-10">
            <div className="w-36">{isProd ? <Logo /> : <LifecheckLogo />}</div>
          </Link>
          <div className="max-w-xl w-full">
            <p className="text-h3 font-medium">Recover your Password</p>
            <Errornotf style={{ marginBottom: "20px", marginTop: "40px" }} message={errorMessage} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="my-9">
                <Controller
                  name="email"
                  defaultValue={null}
                  rules={{ required: true }}
                  control={control}
                  render={(props) => (
                    <Textinput
                      onChange={(e: any) => {
                        props.onChange(e.target.value);
                      }}
                      checked={props.value}
                      label="Enter your registered email"
                      inputid="email"
                      type="email"
                      placeholder="name@email.com"
                      iserror={errors.email}
                      message={"Please provide a valid email address."}
                    />
                  )}
                />
              </div>
              <div className="mb-4">
                <MainButton size="big" type="primary" extraClasses="w-full">
                  <span className="text-p1">Recover Password</span>
                </MainButton>
              </div>
            </form>
            <div>
              <p className="text-center text-p2 text-type w-11/12 mx-auto">
                Go back to{" "}
                <Link to="/signin" className="cursor-pointer text-primary font-semibold text-center">
                  Sign In
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
