import { Dispatch, SetStateAction, useState } from "react";
import "./auth.scss";
import { toast } from "react-toastify";
import PinInput from "react-pin-input";
import { loaderData, setUtilityData } from "../../store/actions/utility";
import { Errornotf } from "../../components/notifications/errornotf";
import { useDispatch } from "react-redux";
import CheckCir from "../../assets/svg/successcheck.svg";
import MainButton from "../../components/buttons/mainButton";
import { TwoFaTypes } from "../../types/authTypes";
import { useMutation } from "@apollo/client";
import { LOGIN_WITH_MFA, RESEND_VERIFY_CODE } from "../../graphql/auth";

interface IVerify2faPage {
  twoFaType: string;
  userEmail: string;
  tempToken: string;
  setToken: Dispatch<SetStateAction<string>>;
}

export const Verify2faPage = ({ twoFaType, setToken, tempToken, userEmail }: IVerify2faPage) => {
  const [temporaryDisable, setTemporaryDisable] = useState(false);
  const [retries, setRetries] = useState(false);
  const [errormessage, seterrormessage] = useState("");
  const [errorMessage, seterrorMessage] = useState(null);
  const [otpCode, setOtpCode] = useState<string>("");

  const dispatch = useDispatch();

  const [signinwithmfa] = useMutation(LOGIN_WITH_MFA, {
    context: {
      headers: {
        authorization: `Bearer ${tempToken}`,
      },
    },
    onCompleted(data) {
      if (data.signInWithMFA.is2FAAuthenticated) {
        setToken(data.signInWithMFA.accessToken);
      }
      dispatch(setUtilityData(loaderData(false, "")));
    },
    onError(error: any) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  const [resendcode] = useMutation(RESEND_VERIFY_CODE, {
    onCompleted(data) {
      setTemporaryDisable(true);
      if (!retries) {
        setRetries(true);
      }
      toast.success(
        <div className="toast-div">
          <img src={CheckCir} alt="check circle" />A code has been sent to your registered
          {twoFaType === TwoFaTypes.OTP ? " email" : " email and phone number"}.
        </div>
      );
      dispatch(setUtilityData(loaderData(false, "")));
      setTimeout(() => {
        setTemporaryDisable(false);
      }, 60000);
    },
    onError(error: any) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  let submit = () => {
    seterrorMessage(null);
    seterrormessage("");
    if (otpCode && otpCode.length !== 6) {
      seterrormessage("Enter a valid pin");
      return;
    }

    dispatch(setUtilityData(loaderData(true, "")));
    signinwithmfa({
      variables: {
        mfaCode: otpCode,
      },
    });
  };

  const sendOtp = () => {
    dispatch(setUtilityData(loaderData(true, "")));
    resendcode({
      variables: {
        email: userEmail,
      },
    });
  };

  return (
    <div className="inner">
      <p className="text-h3 font-medium mb-3">
        {twoFaType === TwoFaTypes.OTP
          ? "SMS Authentication"
          : twoFaType === TwoFaTypes.TOTP && "Enter your authentication code"}
      </p>

      <p className="text-p2 text-type-100 mb-8">
        {twoFaType === TwoFaTypes.OTP || retries
          ? `Please enter the 6 digit verification code we sent to your registered phone number or email address. `
          : twoFaType === TwoFaTypes.TOTP &&
            "Use the authenticator app to view your security code and confirm your sign in."}
      </p>

      <Errornotf style={{ marginTop: "40px", marginBottom: "25px" }} message={errorMessage} />
      <div style={{ marginBottom: "70px" }} className="mb-10">
        <PinInput
          type="numeric"
          inputMode="number"
          initialValue=""
          secret
          focus
          length={6}
          onComplete={(value) => setOtpCode(value)}
          onChange={(value) => setOtpCode(value)}
        />
        {errormessage ? <small className="text-status-danger mt-1">{errormessage}</small> : null}
      </div>
      <div className="mb-6">
        <MainButton click={submit} size="big" type="primary" extraClasses="w-full">
          <span className="text-p1">Continue</span>
        </MainButton>
      </div>
      <div>
        <p className="text-p2 text-center">
          {twoFaType === TwoFaTypes.OTP || retries ? (
            <span className="text-type">Didn't get the code?</span>
          ) : (
            twoFaType === TwoFaTypes.TOTP && <span className="text-type">Don't have your auth app anymore? </span>
          )}
          <span
            className={`p-semibold ${
              temporaryDisable ? " text-type-200 pointer-events-none cursor-not-allowed" : "cursor-pointer"
            }`}
            onClick={() => {
              if (!temporaryDisable) {
                sendOtp();
              }
            }}
          >
            {twoFaType === TwoFaTypes.OTP || retries
              ? "Resend code"
              : twoFaType === TwoFaTypes.TOTP && "Try other options"}
          </span>
        </p>
      </div>
    </div>
  );
};
