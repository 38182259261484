import { gql } from "@apollo/client";

export const GET_PORTFOLIOS = gql`
  query portfolios {
    portfolios {
      created_at
      id
      name
      owner
    }
  }
`;

export const GET_ACTIVE_PORTFOLIO = gql`
  query getActivePortfolio($id: Int!) {
    portfolio(id: $id) {
      id
      name
      created_at
      migrated_accounts
      connect_plaid_us: connect_data(partner: "PLAID", country: "US")
      connect_plaid_ca: connect_data(partner: "PLAID", country: "CA")
      connections {
        id
        partner
        reconnect
        fixable
        accounts {
          display_name
          balance
          currency
        }
        institution {
          name
          logo
        }
      }
      subscription {
        cancel_at
        created_at
        current_period_end
        current_period_start
        ended_at
        id
        partner
        partner_status
        payment_method {
          card_brand
          card_country
          card_exp_month
          card_exp_year
          card_last4
          created_at
          id
          partner
          type
        }
        plan {
          active
          amount
          code
          currency
          id
          interval
          partner
        }
        quantity
        started_at
        status
      }
    }
  }
`;

export const GET_PORTFOLIO_DATA = gql`
  query portfolioData($id: Int!, $currency: String!) {
    portfolio(id: $id) {
      id
      name
      created_at
      balances(currency: $currency)
      subscription_id
      user {
        first_name
        image_url
      }
      shares {
        access_level
        created_at
        status
        id
        shared_labels {
          label_id
          collaborator_id
        }
        user {
          email
          first_name
          last_name
          image_url
        }
        email
      }
      otp {
        code
      }
      institutions {
        id
        name
        balances(currency: $currency)
        balance_usd: balances(currency: "USD")
        logo
        partner
        accounts {
          address {
            city
            country
            postal_code
            region
            street
          }
          balance(currency: $currency)
          balance_usd: balance(currency: "USD")
          balance_original(currency: $currency)
          balance_original_usd: balance_original(currency: "USD")
          id
          name
          display_name
          number
          synced_at
          created_at
          currency
          legacy_type
          legacy_id
          partner
          subtype
          type
          updated_at
          holdings {
            id
            name
            currency
            type
            symbol
            quantity
            price
            value
            value_original
          }
          institution {
            name
            logo
            id
          }
          label {
            id
            name
          }
          connection {
            id
            partner
            reconnect
            created_at
          }
          files {
            name
            id
            path(sign: true)
            size
            created_at
          }
          notes {
            content
            created_at
            id
            title
            updated_at
          }
        }
      }
      classes {
        name
        slug
        type
        subtype
        balances(currency: $currency)
        balance_usd: balances(currency: "USD")
        accounts {
          address {
            city
            country
            postal_code
            region
            street
          }
          balance(currency: $currency)
          balance_usd: balance(currency: "USD")
          balance_original(currency: $currency)
          balance_original_usd: balance_original(currency: "USD")
          id
          name
          display_name
          number
          synced_at
          created_at
          currency
          legacy_type
          legacy_id
          partner
          subtype
          type
          updated_at
          holdings {
            id
            name
            currency
            type
            symbol
            quantity
            price
            value
            value_original
          }
          institution {
            name
            logo
            id
          }
          label {
            id
            name
          }
          connection {
            id
            partner
            reconnect
            created_at
          }
          files {
            name
            id
            path(sign: true)
            size
            created_at
          }
          notes {
            content
            created_at
            id
            title
            updated_at
          }
        }
      }
      labels {
        id
        name
        updated_at
        balances(currency: $currency)
        balance_usd: balances(currency: "USD")
        accounts {
          address {
            city
            country
            postal_code
            region
            street
          }
          balance(currency: $currency)
          balance_usd: balance(currency: "USD")
          balance_original(currency: $currency)
          balance_original_usd: balance_original(currency: "USD")
          id
          name
          display_name
          number
          synced_at
          created_at
          currency
          legacy_type
          legacy_id
          partner
          subtype
          type
          updated_at
          holdings {
            id
            name
            currency
            type
            symbol
            quantity
            price
            value
            value_original
          }
          institution {
            name
            logo
            id
          }
          label {
            id
            name
          }
          connection {
            id
            partner
            reconnect
            created_at
          }
          files {
            name
            id
            path(sign: true)
            size
            created_at
          }
          notes {
            content
            created_at
            id
            title
            updated_at
          }
        }
      }
      lifecheck {
        enabled
        history(offset: 0, size: 10, types: null) {
          data {
            created_at
            details
            id
            message
            portfolio_id
            type
          }
          meta {
            total
            actualSize
            requestedSize
            offset
          }
        }
        steps {
          sort_index
          trigger_after
          trigger_after_unit
          action
          id
          custom_note
          contacts {
            first_name
            email
            id
            last_name
          }
        }
        contacts {
          first_name
          email
          id
          last_name
          phone_number
          phone_number_country_code
        }
      }
    }
  }
`;

export const CREATE_LABEL = gql`
  mutation createLabel($createLabelInput: CreateLabelInput!) {
    createLabel(createLabelInput: $createLabelInput) {
      id
      name
      portfolio_id
      created_at
      balances
      user_id
      updated_at
    }
  }
`;

export const DELETE_LABEL = gql`
  mutation removeLabel($id: ID!) {
    removeLabel(id: $id) {
      portfolio_id
    }
  }
`;

export const UPDATE_LABEL = gql`
  mutation updateLabel($id: ID!, $updateLabelInput: UpdateLabelInput!) {
    updateLabel(id: $id, updateLabelInput: $updateLabelInput) {
      id
      name
      portfolio_id
      created_at
      balances
      user_id
      updated_at
    }
  }
`;

export const UPDATE_ACCOUNT_LABEL = gql`
  mutation updateAccount($updateAccountInput: UpdateAccountInput!) {
    updateAccount(updateAccountInput: $updateAccountInput) {
      id
      display_name
      legacy_type
      user_id
      subtype
      partner_ref
      synced_at
      created_at
      updated_at
      label_id
    }
  }
`;

export const GET_RECONNECT_DATA = gql`
  query reconnect_data($id: Int!, $connection_id: String!) {
    portfolio(id: $id) {
      reconnect_data(connection_id: $connection_id)
    }
  }
`;

export const GET_HISTORY = gql`
  query history(
    $for: String!
    $from: String!
    $historyId: String!
    $interval: String!
    $to: String!
    $currency: String
  ) {
    history(for: $for, from: $from, id: $historyId, interval: $interval, to: $to, currency: $currency) {
      interval
      money
    }
  }
`;

export const RESYNC_PORTFOLIO = gql`
  mutation resyncPortfolio($portfolioId: Float!) {
    resyncPortfolio(portfolioId: $portfolioId)
  }
`;

export const SHARE_PORTFOLIO = gql`
  mutation SharePortfolioWith($portfolioShareInput: PortfolioShareInput!) {
    sharePortfolioWith(portfolioShareInput: $portfolioShareInput) {
      email
      id
    }
  }
`;

export const VERIFY_PORTFOLIO_INVITE = gql`
  mutation VerifyPortfolioInvite($token: String!) {
    verifyPortfolioInvite(token: $token) {
      email
    }
  }
`;

export const VERIFY_PORTFOLIO_OTP = gql`
  mutation VerifyPortfolioOtp($portfolioId: Float!, $token: String!) {
    verifyPortfolioOtp(portfolioId: $portfolioId, token: $token) {
      accepted
    }
  }
`;

export const UNSHARE_PORTFOLIO = gql`
  mutation UnSharePortfolioWith($portfolioUnShareInput: PortfolioUnShareInput!) {
    unSharePortfolioWith(portfolioUnShareInput: $portfolioUnShareInput)
  }
`;

export const PORTFOLIOS_SHARED_WITH_ME = gql`
  query PortfolioSharedWithMe {
    currentUser {
      portfolioSharedWithMe {
        access_level
        created_at
        status
        portfolio {
          id
          user {
            email
            image_url
            first_name
            last_name
          }
        }
      }
    }
  }
`;

export const LEAVE_PORTFOLIO_SHARED_WITH_ME = gql`
  mutation RemoveAccount($portfolioId: Float!) {
    leavePortfolioShared(portfolioId: $portfolioId)
  }
`;

export const DECLINE_PORTFOLIO_INVITE = gql`
  mutation DeclinePortfolioInvite($portfolioId: Float!) {
    declinePortfolioInvite(portfolioId: $portfolioId) {
      email
    }
  }
`;

export const UPDATE_PORTFOLIO_SHARED = gql`
  mutation UpdatePortfolioShared($portfolioShareEditInput: PortfolioShareEditInput!) {
    updatePortfolioShared(portfolioShareEditInput: $portfolioShareEditInput) {
      email
    }
  }
`;
