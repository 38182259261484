import "./auth.scss";
import { useEffect, useState } from "react";
import PinInput from "react-pin-input";
import { Link, useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { loaderData, setUtilityData } from "../../store/actions/utility";
import { sendDeviceDetails } from "../../store/actions/userdata";
import { Errornotf } from "../../components/notifications/errornotf";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { SuccessToast } from "../../components/notifications/toasts";
import { Helmet } from "react-helmet";
import { osVersion, osName, browserName, deviceType, mobileVendor, mobileModel } from "react-device-detect";
import { useMutation } from "@apollo/client";
import { RESEND_VERIFY_CODE, VERIFY_USER_EMAIL } from "../../graphql/auth";
import useAuth from "../../hooks/useAuth";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { ReactComponent as Logo } from "../../assets/svg/logo1.svg";
import { useTranslation } from "react-i18next";

export const Verifyemailpage = () => {
  const { t } = useTranslation();
  const notify = (message: string) =>
    toast(<SuccessToast message={message} />, {
      className: "custom-toast-success",
    });

  const dispatch = useDispatch();
  const [pin, setPin] = useState("");
  const [pinError, setPinError] = useState("");
  const [errorMessage, seterrorMessage] = useState(null);
  const location = useLocation();
  const [emailshort, setemailshort] = useState("***@***.com");
  const { getCurrentUser } = useAuth();
  const navigate = useNavigate();
  let parseddata = queryString.parse(location.search);
  let deviceDetails = {
    osVersion,
    osName,
    browserName,
    mobileVendor,
    mobileModel,
    deviceType,
  };

  const [verifyemail] = useMutation(VERIFY_USER_EMAIL, {
    onCompleted(data) {
      notify(`Verified successfully , welcome to ${t("orgName")}`);

      getCurrentUser();
      navigate("/report");
      dispatch(sendDeviceDetails(deviceDetails));
      dispatch(setUtilityData(loaderData(false, "")));
    },
    onError(error: any) {
      seterrorMessage(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  const [resendcode] = useMutation(RESEND_VERIFY_CODE, {
    onCompleted(data) {
      notify("A new token has been sent to your email");
      dispatch(setUtilityData(loaderData(false, "")));
    },
    onError(error: any) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  useEffect(() => {
    if (parseddata.email) {
      let r: any = parseddata.email;
      let br = r.split("@");
      let newEmail = br[0][0] + "***" + br[0][br[0].length - 1] + "@" + br[1];
      setemailshort(newEmail);
    } else navigate("/signin");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let updatePin = (value: any) => {
    setPin(value);
  };

  let submit = () => {
    seterrorMessage(null);
    setPinError("");
    if (pin && pin.length !== 6) {
      setPinError("Enter a valid pin");
      return;
    }

    dispatch(setUtilityData(loaderData(true, "")));
    verifyemail({
      variables: {
        verifyInput: {
          code: pin,
          email: parseddata.email,
        },
      },
    });
  };

  let VERIFYEMAIL = () => {
    seterrorMessage(null);

    dispatch(setUtilityData(loaderData(true, "")));
    resendcode({
      variables: {
        email: parseddata.email,
      },
    });
  };

  const isNative = Capacitor.isNativePlatform();
  return (
    <div className={classNames("authpage h-screen", isNative && "authpage--native")}>
      <Helmet>
        <title>Verify Your Email Address</title>
        <meta name="description" content={`Verify your email address to access your ${t("orgName")} account`} />
        <meta property="og:title" content="Verify Your Email Address" />
        <meta property="og:description" content={`Verify your email address to access your ${t("orgName")} account`} />
        <meta property="og:url" content="https://app.getcova.com/verify-email" />
      </Helmet>
      <div className={classNames("forgotpassword px-4 flex justify-center", isNative && "safe-top")}>
        <div className="w-full h-full flex flex-col justify-start items-center">
          <Link to="/" className="w-full py-4 flex items-center mb-10">
            <Logo />
          </Link>
          <p className="h3-size mb-4">We Sent A Code To</p>
          <p className="h4-size mb-10">{emailshort}</p>
          <p className="max-w-[500px] text-center mb-8">
            Please enter the 6 digit verification code we sent to your email address. The code will expire in 5 minutes.
          </p>

          {errorMessage && (
            <div className="mb-5">
              <Errornotf message={errorMessage} />
            </div>
          )}

          <div className="mb-14">
            <PinInput
              type="numeric"
              inputMode="number"
              initialValue=""
              secret
              focus
              length={6}
              onComplete={(value, index) => {
                updatePin(value);
              }}
              onChange={(value, index) => {
                updatePin(value);
              }}
            />
            {pinError ? <small className="helper-text errormessage danger">{pinError}</small> : null}
          </div>

          <div className="mb-4">
            <button onClick={submit} className="btn-primary btn-md">
              <span className="whitespace-nowrap">Verify my email address</span>
            </button>
          </div>

          <div>
            <p className="text-center">
              <span>Didn't get a code?</span>{" "}
              <span onClick={VERIFYEMAIL} className="cursor-pointer font-semibold">
                Resend Code{" "}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
