import { ReactComponent as EmptyVaultImg } from "../../assets/svg/vault-empty.svg";
import MainButton from "../../components/buttons/mainButton";

export const VaultEmpty = ({ onClickUpload }: { onClickUpload: () => void }) => {
  return (
    <div className="flex flex-col items-center">
      <EmptyVaultImg />
      <div className="text-center">
        <p className="text-xl font-semibold mb-3">Save Important Documents</p>
        <p className="text-md text-gray-600 mb-6 leading-snug max-w-[420px]">
          Upload and secure important files, images or documents into your safety vault.
        </p>
        <MainButton click={onClickUpload} type="primary" id="add assets" size="big">
          <span>Upload Documents</span>
        </MainButton>
      </div>
    </div>
  );
};

export default VaultEmpty;
