export type CreateNoteInput = {
  account_id: string;
  content: string;
  title: string;
};

export type CreateFileInput = {
  account_id: string;
};

export type GetHistoryParams = {
  currency: string;
  for: string;
  from: string;
  historyId: string;
  interval: string;
  to: string;
};

export enum HistoryTypes {
  ACCOUNT = "ACCOUNT",
  PORTFOLIO = "PORTFOLIO",
  INSTITUTION = "INSTITUTION",
  LABEL = "LABEL",
  CLASS = "CLASS",
}

export enum HistoryIntervalTypes {
  HOUR = "HOUR",
  WEEK = "WEEK",
  DAY = "DAY",
  MONTH = "MONTH",
}

export type HistoryType = {
  interval: Date;
  money: Money;
};

export type Money = {
  currency: string;
  symbol: string;
  value: number;
};

export type CreateAccountInput = {
  balance: number;
  balance_original?: number;
  currency: string;
  institution_id: string;
  name: string;
  number: string;
  subtype: DepositoryAccountSubtype | LoanAccountSubtype | InvestmentAccountSubtype | CreditAccountSubtype;
  type: AccountTypes;
  portfolio_id?: number;
  address?: Address;
};

export type UpdateAccountInput = {
  address?: Address;
  balance: number;
  balance_original?: number;
  currency: string;
  display_name: string;
  id: string;
  institution_id: string;
  number: string;
};

interface Address {
  city?: string;
  country: string;
  postal_code: string;
  street: string;
  region?: string;
}

export enum AccountTypes {
  Brokerage = "Brokerage",
  Credit = "Credit",
  Depository = "Depository",
  Investment = "Investment",
  Loan = "Loan",
  Other = "Other",
}

export enum DepositoryAccountSubtype {
  Checking = "checking",
  Savings = "savings",
  MoneyMarket = "money market",
  Paypal = "paypal",
  Prepaid = "prepaid",
  CashManagement = "cash management",
}

export enum LoanAccountSubtype {
  Auto = "auto",
  Business = "business",
  Commercial = "commercial",
  Construction = "construction",
  Consumer = "consumer",
  HomeEquity = "home equity",
  Loan = "loan",
  Mortgage = "mortgage",
  LineOfCredit = "line of credit",
  Student = "student",
  Other = "other",
  All = "all",
}

export enum InvestmentAccountSubtype {
  Startup = "startup",
  RealEstate = "real estate",
  CryptoExchange = "crypto exchange",
  Stock = "stock",
  Other = "other",
}

export enum CreditAccountSubtype {
  CreditCard = "credit card",
  Paypal = "paypal",
  All = "all",
}

export type HandlePartnerCallbackData = {
  portfolioId: number;
  partner: Partners;
  data: string;
};

export type AddCustomAccountData = {
  account: CreateAccountInput;
  portfolioId: number;
  partner: Partners;
};

export enum Partners {
  PLAID = "PLAID",
  ZILLOW = "ZILLOW",
  VEZGO = "VEZGO",
  CUSTOM = "CUSTOM",
  MONO = "MONO",
  MARKET_STACK = "MARKET_STACK",
  COIN_CAP = "COIN_CAP",
}

export type TickerType = {
  currency: string;
  last_price_checked_at: Date;
  name: string;
  price: number;
  symbol: string;
};

export type CreateHoldingInput = {
  symbol: string;
  quantity: number;
  name: string;
  cost: number;
  account_id: string;
};

export type UpdateHoldingInput = {
  account_id: string;
  holdings: {
    id: string;
    cost: number;
    quantity: number;
  }[];
};

export enum AccountLegacyType {
  asset = "asset",
  liability = "liability",
}

export type AccountType = {
  balance: BalanceType;
  balance_usd: BalanceType;
  balance_original: BalanceType;
  balance_original_usd: BalanceType;
  id: string;
  name: string;
  logo: string;
  growth: number;
  display_name: string;
  number: string | null;
  synced_at: string;
  created_at: Date;
  currency: string;
  legacy_type: string;
  legacy_id: number | null;
  partner: string;
  subtype: string;
  type: string;
  updated_at: string;
  holdings: Holding[];
  institution: Institution;
  label: Label | null;
  connection: Connection | null;
  files: File[];
  notes: Note[];
};

export type BalanceType = {
  currency: string;
  symbol: string;
  value: number;
};

type Institution = {
  name: string;
  logo: string;
};

type Label = {
  id: string;
  name: string | undefined;
};

export type Connection = {
  id: string;
  partner: string;
  reconnect: boolean;
  created_at: string;
};

export type Holding = {
  id: string;
  name: string;
  currency: string;
  type: string;
  symbol: string;
  quantity: number;
  price: number;
  value: number;
  value_original: number | null;
};

export type File = {
  name: string;
  id: string;
  path: string;
  size: number;
  created_at: string;
};

export type Note = {
  content: string;
  created_at: Date;
  id: string;
  title: string;
  updated_at: Date;
};

export interface Currency {
  code: string;
  symbol: string;
  name: string;
  base?: number;
}
