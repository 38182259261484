import { useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { CURRENT_USER, USER_CONFIG, UPDATE_PREFERENCES } from "../graphql/auth";
import { CurrentUser } from "../types/authTypes";
import { SET_USER_STATE, SET_CONFIG } from "../store/reducers/userdatav2/types";

const useAuth = () => {
  const dispatch = useDispatch();

  // TODO: This should will be simplified / broken down into individual hooks
  // eg. we already have fetchPreferences in current-user.hooks.ts
  const [getCurrentUser, { data: currentUserResponse }] = useLazyQuery(CURRENT_USER, {
    errorPolicy: "all",
    onCompleted: () => {
      const payload: { currentUser: CurrentUser } = currentUserResponse;
      dispatch({
        type: SET_USER_STATE,
        payload: payload,
      });
    },
    onError() {},
  });

  const [getUserConfig, { data: userConfigResponse }] = useLazyQuery(USER_CONFIG, {
    errorPolicy: "all",
    onCompleted: () => {
      dispatch({
        type: SET_CONFIG,
        payload: userConfigResponse.config,
      });
    },
    onError() {},
  });

  const [updateCurrency] = useMutation(UPDATE_PREFERENCES, {
    errorPolicy: "all",
    onCompleted() {
      getCurrentUser();
    },
    onError(error: any) {},
  });

  return {
    getCurrentUser,
    getUserConfig,
    updateCurrency,
  };
};

export default useAuth;
