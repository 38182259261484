import { useState } from "react";
import Modal from "react-modal";
import MainButton from "../../buttons/mainButton";
import { Textinput } from "../../inputs/textinput";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { useTranslation } from "react-i18next";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface IAddPaymentEmail {
  closeModal: () => void;
  modalIsOpen: boolean;
  customStyles?: any;
  afterOpenModal?: any;
  setupEmail: (email: string) => void;
}

const AddPaypalEmail = ({ closeModal, afterOpenModal, modalIsOpen, customStyles, setupEmail }: IAddPaymentEmail) => {
  const { t } = useTranslation();
  const [emailInput, setEmailInput] = useState<string>("");
  const [hasError, setHasError] = useState<boolean>(false);

  const submit = () => {
    setHasError(false);
    if (!/^\S+@\S+$/.test(emailInput)) {
      setHasError(true);
    } else {
      setupEmail(emailInput);
    }
  };

  const isNative = Capacitor.isNativePlatform();

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Add Paypal Email Modal"
      className={classNames("modal-container", isNative && "native-modal")}
      overlayClassName={"modal-container-overlay"}
    >
      {/* Header */}
      <div className="modal-header px-4 flex items-center justify-between">
        <h1 className="text-h4 font-semibold">Start earning on {t("orgName")}</h1>
        <button type="button" onClick={closeModal} className="cursor-pointer">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>

      {/* Body */}
      <div className="modal-body px-4 md:px-8 pt-5 pb-10">
        <div className="text-type-100 mt-2 mb-6 text-sm">
          Earn up to <span className="text-primary font-semibold">$10</span> for each friend you invite to{" "}
          {t("orgName")}.
        </div>

        <div className="mb-10">
          <Textinput
            onChange={(e: any) => {
              setEmailInput(e.target.value);
            }}
            label="Enter your current Paypal email address"
            inputid="email"
            type="text"
            value={emailInput}
            placeholder="paypalemail@gmail.com"
            message={"Email is not valid"}
            iserror={hasError}
            autocomplete="on"
          />
        </div>

        <div className="text-xs mb-6 py-2 px-3 rounded-md notification-message text-center">
          We will be sending your earnings to this account. Please make sure the account you entered is an active one.
          If you don’t have a Paypal account, kindly create one{" "}
          <span
            className="text-primary font-semibold underline select-none cursor-pointer"
            onClick={() => {
              window.open("https://www.paypal.com/", "_blank");
            }}
          >
            here
          </span>
        </div>
      </div>

      {/* Footer */}
      <div className="modal-footer px-4 flex items-center justify-end">
        <MainButton size="small" type="secondary" click={closeModal}>
          Cancel
        </MainButton>
        <MainButton size="small" type="primary" extraClasses="ml-4" click={submit}>
          Generate link
        </MainButton>
      </div>
    </Modal>
  );
};

export default AddPaypalEmail;
