import { gql } from "@apollo/client";

const PREFERENCES_FRAGMENT = gql`
  fragment PreferenceFields on Preferences {
    classes_sort {
      account {
        order
        type
      }
      group {
        order
        type
      }
    }
    institutions_sort {
      account {
        type
        order
      }
      group {
        order
        type
      }
    }
    labels_sort {
      account {
        order
        type
      }
      group {
        order
        type
      }
    }
    default_currency
    totp_mfa_enabled
    sms_mfa_enabled
    two_fa_auth {
      hasSmsEnabled
      hasTotpEnabled
    }
  }
`;

export const FETCH_PREFERENCES = gql`
  query FetchUserPreferences {
    currentUser {
      preferences {
        ...PreferenceFields
      }
    }
  }

  ${PREFERENCES_FRAGMENT}
`;

export const UPDATE_PREFERENCES = gql`
  mutation UpdatePreferences($preferences: PreferencesInput!) {
    updatePreferences(preferences: $preferences) {
      ...PreferenceFields
    }
  }

  ${PREFERENCES_FRAGMENT}
`;

export const FETCH_USER = gql`
  query FetchUser {
    currentUser {
      id
      about_us
      coupon
      auth_methods
      currencies
      email
      first_name
      image_url
      is_referred
      is_verified
      last_name
      phone_number
      signup_date
      auth_methods
    }
  }
`;
