import * as Sentry from "@sentry/browser";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  CREATE_LABEL,
  DELETE_LABEL,
  GET_ACTIVE_PORTFOLIO,
  GET_HISTORY,
  GET_PORTFOLIOS,
  GET_PORTFOLIO_DATA,
  RESYNC_PORTFOLIO,
  UPDATE_ACCOUNT_LABEL,
  UPDATE_LABEL,
} from "../graphql/portfolio";
import { useSwitchActivePortfolio } from "../graphql/portfolio.gql-hook";
import {
  SET_ACTIVE_PORTFOLIO_ID,
  SET_PORTFOLIO,
  SET_PORTFOLIOS,
  SET_PORTFOLIO_DATA,
  SET_REPORT_DATA,
} from "../store/actions/portfoliosaction/types";
import { loaderData, setUtilityData } from "../store/actions/utility";
import { GetHistoryParams } from "../types/accountTypes";
import { PortfoliosType } from "../types/portfolioTypes";

const usePortfolio = (controlLoaderFromOutside?: boolean) => {
  const dispatch = useDispatch();
  const { id: activePortfolioId } = useSelector((state: any) => state.activePortfolio);
  const { code } = useSelector((state: any) => state.assets.currency);
  const [syncInProgress, setSyncInProgress] = useState<boolean>(false);
  const switchActivePortfolio = useSwitchActivePortfolio();

  const handleGetPortfolio = () => {
    dispatch(setUtilityData(loaderData(true, "")));
    getPortfolios();
  };

  const [getPortfolios] = useLazyQuery(GET_PORTFOLIOS, {
    errorPolicy: "all",
    onCompleted(data) {
      const payload = data?.portfolios;

      const userPortfolio = payload.find((portfolio: PortfoliosType) => portfolio.owner === true);

      dispatch({
        type: SET_PORTFOLIOS,
        payload,
      });

      if (userPortfolio) {
        // TODO: Remove this once we migrate to using SWITCH_ACTIVE_PORTFOLIO exclusively
        dispatch({
          type: SET_ACTIVE_PORTFOLIO_ID,
          payload: userPortfolio.id,
        });

        // This sets the active portfolio id so SET_ACTIVE_PORTFOLIO_ID will no longer be necessary
        switchActivePortfolio(userPortfolio.id);
      }

      dispatch(setUtilityData(loaderData(false, "")));
    },
    onError(error: any) {
      console.error("fail: getPortfolios", error);
      Sentry.captureException(error);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  const handleGetActivePortfolio = (id: string) => {
    getActivePortfolio({
      variables: {
        id,
      },
    });
  };

  const [getActivePortfolio] = useLazyQuery(GET_ACTIVE_PORTFOLIO, {
    errorPolicy: "all",
    onCompleted(data) {
      const payload = data?.portfolio;
      dispatch({
        type: SET_PORTFOLIO,
        payload: payload,
      });
      if (!controlLoaderFromOutside) dispatch(setUtilityData(loaderData(false, "")));
    },
    onError(error: any) {
      console.error("fail: getActivePortfolio", error);
      Sentry.captureException(error);
    },
  });

  const handleGetPortfolioData = async (id: string, dataFetched: boolean) => {
    await getPortfolioData({
      variables: {
        id,
        currency: !code ? "" : code,
      },
    });
  };

  const [getPortfolioData] = useLazyQuery(GET_PORTFOLIO_DATA, {
    errorPolicy: "all",
    onCompleted(data) {
      dispatch({
        type: SET_PORTFOLIO_DATA,
        payload: {
          institutions: data?.portfolio?.institutions,
          classes: data?.portfolio?.classes,
          labels: data?.portfolio?.labels,
          balances: data?.portfolio?.balances,
          portfolioOtp: data?.portfolio?.otp?.code,
          collaborators: data?.portfolio?.shares,
          lifecheck: data?.portfolio?.lifecheck,
          subscriptionId: data?.portfolio?.subscription_id,
          dataFetched: true,
        },
      });
      dispatch({
        type: SET_REPORT_DATA,
        payload: data?.portfolio?.institutions,
      });

      if (!controlLoaderFromOutside) dispatch(setUtilityData(loaderData(false, "")));
    },
    onError(error: any) {
      dispatch(setUtilityData(loaderData(false, "")));
      console.error("fail: getPortfolioData", error);
      Sentry.captureException(error);
    },
  });

  const [createLabel] = useMutation(CREATE_LABEL, {
    errorPolicy: "all",
    onCompleted(data) {
      handleGetPortfolioData(data.createLabel.portfolio_id, false);
      dispatch(setUtilityData(loaderData(false, "")));
    },
    onError(error: any) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  const handleCreateLabel = async (name: string, portfolio_id: number) => {
    dispatch(setUtilityData(loaderData(true, "")));
    const res = await createLabel({
      variables: {
        createLabelInput: {
          name,
          portfolio_id,
        },
      },
    });
    return res.data.createLabel;
  };

  const [deleteLabel] = useMutation(DELETE_LABEL, {
    errorPolicy: "all",
    onCompleted(data) {
      handleGetPortfolioData(data.removeLabel.portfolio_id, false);
      // dispatch(setUtilityData(loaderData(false, "")));
    },
    onError(error: any) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  const handleDeleteLabel = (id: string) => {
    dispatch(setUtilityData(loaderData(true, "")));
    deleteLabel({
      variables: {
        id,
      },
    });
  };

  const [updateLabel] = useMutation(UPDATE_LABEL, {
    errorPolicy: "all",
    onCompleted(data) {
      // dispatch(setUtilityData(loaderData(false, "")));
    },
    onError(error: any) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  const handleUpdateLabel = async (new_name: string, id: string) => {
    dispatch(setUtilityData(loaderData(true, "")));
    await updateLabel({
      variables: {
        id,
        updateLabelInput: {
          name: new_name,
        },
      },
    });
    await handleGetPortfolioData(activePortfolioId, false);
    toast.success("Label updated successfully");
  };

  const handleUpdateLabelAccounts = (account_ids: [], id: string) => {
    dispatch(setUtilityData(loaderData(true, "")));
    updateLabel({
      variables: {
        id,
        updateLabelInput: {
          account_ids,
        },
      },
    });
    handleGetPortfolioData(activePortfolioId, false);
  };

  const [updateAccountLabel] = useMutation(UPDATE_ACCOUNT_LABEL, {
    errorPolicy: "all",
    onCompleted(data) {
      dispatch(setUtilityData(loaderData(false, "")));
    },
    onError(error: any) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  const handleAccountLabel = async (account_id: string, label_id: any) => {
    dispatch(setUtilityData(loaderData(true, "")));
    await updateAccountLabel({
      variables: {
        updateAccountInput: {
          id: account_id,
          label_id,
        },
      },
    });

    handleGetPortfolioData(activePortfolioId, false);
  };

  const handleAccountName = async (id: string, display_name: string, activePortfolioId: any) => {
    dispatch(setUtilityData(loaderData(true, "")));
    await updateAccountLabel({
      variables: {
        updateAccountInput: {
          id,
          display_name,
        },
      },
    });

    // dispatch(setUtilityData(loaderData(true, "")));
    handleGetPortfolioData(activePortfolioId, false);
  };

  const [getHistory] = useLazyQuery(GET_HISTORY, {
    errorPolicy: "all",
    onCompleted(data) {},
    onError(error: any) {
      toast.error(error.message);
    },
  });

  const handleGetHistory = async (params: GetHistoryParams) => {
    const res = await getHistory({
      variables: params,
    });

    return res;
  };

  const [resyncPortfolio] = useMutation(RESYNC_PORTFOLIO, {
    errorPolicy: "all",
    onCompleted(data) {},
    onError(error: any) {
      toast.error(error.message);
    },
  });

  const handleResyncPortfolio = async (portfolioId: string) => {
    const res = await resyncPortfolio({
      variables: { portfolioId },
    });

    return res;
  };

  const handleResync = async () => {
    setSyncInProgress(true);
    await handleResyncPortfolio(activePortfolioId)
      .then((res) => {
        setTimeout(async () => {
          if (!res?.errors || res?.data?.resyncPortfolio) {
            await handleGetPortfolioData(activePortfolioId, true);
            setSyncInProgress(false);
          } else {
            toast.error("Something went wrong, please try again");
          }
        }, 15000);
      })
      .catch((error: any) => {
        console.error("fail: handleResync", error);
        Sentry.captureException(error);
      });
  };

  return {
    handleGetPortfolio,
    handleGetActivePortfolio,
    handleGetPortfolioData,
    handleCreateLabel,
    handleDeleteLabel,
    handleUpdateLabelAccounts,
    handleUpdateLabel,
    handleAccountLabel,
    handleAccountName,
    handleGetHistory,
    handleResync,
    handleResyncPortfolio,
    syncInProgress,
  };
};

export default usePortfolio;
