import { XMarkIcon } from "@heroicons/react/24/outline";
import { ReactComponent as WomanWritingLetter } from "../../assets/svg/woman-writing-letter.svg";

const ContactsDescription = ({ onCloseContactsInfoCard }: { onCloseContactsInfoCard: () => void }) => {
  return (
    <div className="relative bg-primary-alice rounded-lg p-6 sm:p-8 flex flex-col sm:flex-row items-end justify-between">
      <div className="absolute top-4 right-4">
        <button
          type="button"
          onClick={onCloseContactsInfoCard}
          className="cursor-pointer hover:bg-gray-200 hover:text-gray-700"
        >
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>
      <div className="md:w-full h-full">
        <h2 className="text-xl sm:text-3xl font-semibold mb-2 sm:mb-3">Contacts</h2>
        <p className="max-w-[600px] leading-4 sm:leading-6">
          These are the individuals or organizations you'd love to collaborate with or transfer your portfolio to in
          case of emergencies or unforeseen circumstances.
        </p>
      </div>
      <WomanWritingLetter className="-mt-8 sm:mt-0 w-[120px] sm:w-[190px] md:w-[260px]" />
    </div>
  );
};

export default ContactsDescription;
