import { useMemo, useState, useEffect } from "react";
import Modal from "react-modal";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import MainButton from "../../../../components/buttons/mainButton";
import { loaderData, setUtilityData } from "../../../../store/actions/utility";
import { ReactComponent as CheckIcon } from "../../../../assets/svg/check-halfcircle.svg";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Tilita } from "../../../../assets/svg/tilita.svg";
import { ReactComponent as Premium } from "../../../../assets/svg/premium.svg";
import { ReactComponent as StripeLogo } from "../../../../assets/svg/stripelogo.svg";
import { toast } from "react-toastify";
import { SetupIntent, Plan, PlanIntervals } from "../../../../types/subscriptionTypes";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { useTranslation } from "react-i18next";
import {
  useCreateStripeSetupIntentMutation,
  useConvertStripeSetupIntentToPaymentMethodMutation,
} from "../../../../api/subscription/subscription-mutations";
import ActionStateToast from "../../../../components/ActionStateToast";
import { XMarkIcon } from "@heroicons/react/24/outline";

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: "#1E2329",
          letterSpacing: "0.025em",
          "::placeholder": {
            color: "#b7b7b7",
          },
        },
        invalid: {
          color: "#EE425A",
        },
      },
    }),
    []
  );

  return options;
};

interface IStripeForm {
  closeModal: () => void;
  plan: Plan;
  setActiveView: React.Dispatch<React.SetStateAction<"success" | "stripe">>;
  userdata: any;
  abandonedCart: boolean;
}

const StripeForm = ({ setActiveView, closeModal, plan, userdata, abandonedCart }: IStripeForm) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const dispatch = useDispatch();
  const [changeDiscount] = useState<boolean>(true);
  const [setupIntent, setSetupIntent] = useState<SetupIntent | null>(null);

  const [actionToast, setActionToast] = useState<any>();
  const { mutateAsync: createStripeSetupIntent } = useCreateStripeSetupIntentMutation();
  const { mutateAsync: convertStripeSetupIntentToPaymentMethod } = useConvertStripeSetupIntentToPaymentMethodMutation();

  useEffect(() => {
    handleGetSetupIntent();
    //eslint-disable-next-line
  }, []);

  const handleGetSetupIntent = async () => {
    try {
      const response = await createStripeSetupIntent();

      if (response.errors) {
        throw new Error("Unable to create setup intent");
      }

      const setupIntentResponse: SetupIntent = response;
      setSetupIntent(setupIntentResponse);
    } catch (error) {
      console.error("fail: handleGetSetupIntent", error);
    }
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    setActionToast(
      toast(<ActionStateToast message="Processing..." />, {
        autoClose: false,
      })
    );

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      toast.dismiss(actionToast);
      return;
    }

    const card = elements.getElement(CardNumberElement);

    if (!card || !setupIntent) {
      toast.dismiss(actionToast);
      return;
    }

    try {
      const confirmCardResponse = await stripe.confirmCardSetup(setupIntent.client_secret, {
        payment_method: {
          card: card,
        },
      });

      if (confirmCardResponse.error) {
        throw confirmCardResponse.error;
      } else {
        if (
          confirmCardResponse.setupIntent.status === "processing" ||
          confirmCardResponse.setupIntent.status === "succeeded"
        ) {
          const convertToPaymentMethodResponse = await convertStripeSetupIntentToPaymentMethod(setupIntent.id);
          if (!convertToPaymentMethodResponse.errors) {
            setActiveView("success");
          } else {
            throw new Error("Unable to add card");
          }
        } else {
          throw new Error("Unable to add card");
        }
      }
    } catch (error) {
      toast.dismiss(actionToast);
      toast("Something went wrong..", {
        autoClose: 3000,
        type: "error",
      });
      console.error("fail: onSubmit confirmCardSetup", error);
    } finally {
      toast.dismiss(actionToast);
    }
  };

  return (
    <div className="">
      <form
        onSubmit={(e) => {
          dispatch(setUtilityData(loaderData(true, "")));
          onSubmit(e);
        }}
        className="stripe-form bg-white rounded-xl mt-6 w-full p-4 lg:p-8 py-8 m-auto "
      >
        <div className="header relative">
          <div className="header-assetsheader h4-semibold "></div>
          <button type="button" onClick={closeModal} className="cursor-pointer absolute right-0">
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>
        <h2 className="header-assetsheader h4-semibold mb-2 pt">Complete your Subscription</h2>
        <p className="p-normal benmessage text-type-100 text-p2">Please provide your card details to subscribe.</p>
        <p className="p-normal benmessage mb-6 text-type-100 text-p2">
          {`Your card will be charged ${plan.currency.symbol}${plan.amount / 100}/${
            plan.interval === PlanIntervals.DAY ? "dai" : plan.interval
          }ly.`}
        </p>

        <div className="p-change-card">
          <div className="c-c-left">
            <div className="l-top">
              {`${plan.currency.symbol}${plan.amount / 100}/${
                plan.interval === PlanIntervals.DAY ? "dai" : plan.interval
              }ly ${plan.interval === PlanIntervals.YEAR ? "(save $20)" : ""}`}
            </div>

            <div className="l-bottom">Chosen Plan</div>
          </div>
        </div>

        <div className={`discount-code ${!changeDiscount ? "add-space" : ""}`}>
          <div className="d-c-input">
            {!changeDiscount && (
              <div className="remove-discount-title">
                <span>Discount successfully applied</span> <CheckIcon />
              </div>
            )}
          </div>
        </div>

        <label className="text-p2 font-medium text-type mb-4">
          Card number
          <CardNumberElement
            options={options}
            className="bg-neutral-300 border my-2 rounded placeholder-type-200 text-type text-p2 font-normal cc-input transition focus:border-type focus:ring-0 border-neutral-200"
          />
        </label>
        <div className="w-full flex justify-between mb-4 items-center flex-col sm:flex-row sm:gap-4">
          <label className="text-p2 font-medium text-type flex-auto w-full sm:w-auto">
            Expiry
            <CardExpiryElement
              options={options}
              className="bg-neutral-300 border my-2 rounded placeholder-type-200 text-type text-p2 font-normal cc-input transition focus:border-type focus:ring-0 border-neutral-200"
            />
          </label>
          <label className="text-p2 font-medium text-type flex-auto w-full sm:w-auto">
            CVV
            <CardCvcElement
              options={options}
              className="bg-neutral-300 border my-2 rounded placeholder-type-200 text-type text-p2 font-normal cc-input transition focus:border-type focus:ring-0 border-neutral-200"
            />
          </label>
        </div>
        <div className="flex justify-end items-center w-full my-6">
          <MainButton type="secondary" size="small" extraClasses="mr-2" click={closeModal}>
            Cancel
          </MainButton>
          <MainButton type="primary" size="small" disabled={!stripe}>
            Subscribe
          </MainButton>
        </div>
        <p className="p-normal benmessage my-4 text-type-100 text-p2 text-center flex flex-wrap items-center gap-2 justify-center">
          All your transactions are powered by
          <a
            href="https://stripe.com/docs/security"
            target="_blank"
            rel="noreferrer"
            className="inline-flex self-center"
          >
            <StripeLogo />
          </a>
        </p>
      </form>
    </div>
  );
};

const Success = ({ closeModal, onSuccess }: any) => {
  const { t } = useTranslation();
  const closeSuccessModal = () => {
    closeModal();
    onSuccess();
  };

  return (
    <div className="p-8 flex flex-col justify-center">
      <Premium className="mx-auto mb-5" />
      <h2 className="header-assetsheader h4-semibold mb-4 text-center">Welcome to {t("orgName")} Premium</h2>
      <p className="p-normal benmessage mb-6 text-type-100 text-p2 max-w-lg text-center">
        It’s great to have you here! With {t("orgName")} Premium you can:
      </p>
      <div className="p-bills-info mb-10">
        <div className="p-bills5000">
          <div className="p-bills9 flex justify-start items-center my-4 gap-2 text-p2">
            <Tilita />
            <div className="p-bills92">
              <p>Track your bank and brokerage accounts</p>
            </div>
          </div>
          <div className="p-bills9 flex justify-start items-center my-4 gap-2 text-p2">
            <Tilita />
            <div className="p-bills92">
              <p>Track cryptocurrency exchange and wallets</p>
            </div>
          </div>
          <div className="p-bills9 flex justify-start items-center my-4 gap-2 text-p2">
            <Tilita />
            <div className="p-bills92">
              <p>Enjoy unlimited vault storage for vital documents </p>
            </div>
          </div>
          <div className="p-bills9 flex justify-start items-center my-4 gap-2 text-p2">
            <Tilita />
            <div className="p-bills92">
              <p>Get live cryptocurrency and stock tickers</p>
            </div>
          </div>
          <div className="p-bills9 flex justify-start items-center my-4 gap-2 text-p2">
            <Tilita />
            <div className="p-bills92">
              <p>Get personalized monthly portfolio performance reports</p>
            </div>
          </div>
        </div>
      </div>

      <MainButton type="primary" size="small" click={closeSuccessModal}>
        Let's Go!
      </MainButton>
    </div>
  );
};

export const StripeSubscriptionModal = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  customStyles,
  placeholder,
  action,
  plan,
  value,
  onSuccess,
  abandonedCart,
}: any) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");
  const userdata = useSelector((state: any) => state.userdata);
  const [activeView, setActiveView] = useState<"success" | "stripe">("stripe");
  const isNative = Capacitor.isNativePlatform();

  return (
    <Elements stripe={stripePromise}>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Subscription Modal"
        className={classNames("subscriptionModal bg-white p-8 rounded-md", isNative && "native-modal")}
        overlayClassName={"modal-container-overlay"}
      >
        {activeView === "success" && <Success closeModal={closeModal} onSuccess={onSuccess} />}
        {activeView === "stripe" && (
          <StripeForm
            {...{
              setActiveView,
              closeModal,
              value,
              action,
              placeholder,
              plan,
              userdata,
              abandonedCart,
            }}
          />
        )}
      </Modal>
    </Elements>
  );
};
