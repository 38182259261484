import { RESET_UTILITY, SWITCH_UTILITY } from "./types";

export const setUtilityData = (payload: any) => (dispatch: any) => {
  return dispatch({
    type: SWITCH_UTILITY,
    payload,
  });
};

export const resetUtilityData = (payload: any) => (dispatch: any) => {
  return dispatch({
    type: RESET_UTILITY,
    payload,
  });
};

export const loaderData = (disappear: boolean, message: string) => {
  return {
    loader: { message, disappear },
  };
};
