import Skeleton from "react-loading-skeleton";
import { getNumberBetween } from "../../components/utilities/utilityFunctions";

const HistorySkeletons = () => (
  <div className="flex flex-col gap-y-4 w-full">
    {[...Array(getNumberBetween(3, 8))].map((_, index) => (
      <div key={index} className="w-full">
        <Skeleton width={getNumberBetween(100, 320)} height={34} borderRadius={4} />
      </div>
    ))}
  </div>
);

export default HistorySkeletons;
