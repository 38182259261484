import { gql } from "@apollo/client";

export const CREATE_NOTE = gql`
  mutation createNote($createNoteInput: CreateNoteInput!) {
    createNote(createNoteInput: $createNoteInput) {
      content
      created_at
      id
      title
      updated_at
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation updateNote($id: ID!, $updateNoteInput: UpdateNoteInput!) {
    updateNote(id: $id, updateNoteInput: $updateNoteInput) {
      content
      created_at
      id
      title
      updated_at
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation removeNote($id: ID!) {
    removeNote(id: $id) {
      content
      created_at
      id
      title
      updated_at
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation uploadFile($createFileInput: CreateFileInput!, $file: Upload!) {
    uploadFile(createFileInput: $createFileInput, file: $file) {
      name
      id
      path
      size
      created_at
    }
  }
`;

export const REMOVE_FILE = gql`
  mutation removeFile($id: String!) {
    removeFIle(id: $id)
  }
`;
