import { useVault } from "./VaultProvider";
import { VaultFolderType } from "../../types/vaultTypes";
import { VaultFolder } from "./VaultFolder";
import { isVaultEmpty } from "./vault-utils";
import VaultEmpty from "./VaultEmpty";
import VaultList from "./VaultList";

const VaultManage = ({
  folders,
  onClickUpload,
}: {
  folders: VaultFolderType[]; // so children don't refetch unnecessarily
  onClickUpload: () => void;
}) => {
  const { currentFolder } = useVault();
  const isEmpty = isVaultEmpty(folders);

  if (isEmpty) {
    return <VaultEmpty onClickUpload={onClickUpload} />;
  }

  return currentFolder ? <VaultFolder folders={folders} /> : <VaultList folders={folders} />;
};

export default VaultManage;
