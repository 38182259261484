import { VaultFileIcon } from "../../components/icons/vaultfileicon";
import { VaultDocumentDropdown } from "../../components/inputs/vaultdropdowns";
import { getFileData } from "../../components/modals/viewassets";
import { subStringofName, convertBytestoMB } from "../../components/utilities/utilityFunctions";
import { SubscriptionStatus } from "../../types/subscriptionTypes";
import classNames from "classnames";
import { VAULT_FREEMIUM_LIMIT } from "./vault-utils";
import { useSelector } from "react-redux";
import { PortfolioState } from "../../types/portfolioTypes";
import dayjs from "dayjs";

const VaultDocumentItem = ({
  document,
  document_index,
  folders,
  openManageFileFolderModal,
  openMoveDocumentModal,
  setConfirmDelete,
}: {
  document: any;
  document_index: number;
  folders: any;
  openManageFileFolderModal: (data: { type: "folder" | "file" | "note"; item: any }) => void;
  openMoveDocumentModal: (type: "file" | "note", id: string) => void;
  setConfirmDelete: (confirmDelete: any) => void;
}) => {
  const subscription = useSelector(
    (state: { portfolios: { portfolio: PortfolioState } }) => state.portfolios.portfolio.subscription
  );
  const documentName = subStringofName(document.file_name, 37);
  return (
    <div
      key={`${document.file_url}_${document_index}`}
      className={classNames(
        "w-full md:w-48",
        "p-5 rounded-md",
        "border border-1 border-solid border-gray-200 hover:border-blue-400",
        "flex flex-col",
        "gap-y-4",
        !subscription ||
          (subscription &&
            subscription.status !== SubscriptionStatus.ACTIVE &&
            folders?.[0]?.vaultNotes?.length + document_index >= VAULT_FREEMIUM_LIMIT &&
            "opacity-50 cursor-not-allowed")
      )}
    >
      <div className="flex justify-between">
        {/* Icon */}
        <div
          className={classNames(
            !subscription ||
              (subscription &&
                subscription.status !== SubscriptionStatus.ACTIVE &&
                folders[0].vaultNotes.length + document_index >= VAULT_FREEMIUM_LIMIT)
              ? "cursor-not-allowed"
              : "pointer"
          )}
        >
          <div className="relative vault-file-icon">
            <VaultFileIcon
              mainColor={getFileData(document.file_name).backgroundColor}
              foldColor={getFileData(document.file_name).foldColor}
            />
            <p className="absolute font-semibold text-white text-p2"> {getFileData(document.file_name).filetype}</p>
          </div>
        </div>

        {/* Dropdown */}
        <VaultDocumentDropdown
          openRenameModal={() => {
            openManageFileFolderModal({
              type: "file",
              item: document,
            });
          }}
          openMoveModal={() => openMoveDocumentModal("file", document.id)}
          openDeleteModal={() => {
            setConfirmDelete({
              type: "file",
              item: document,
            });
          }}
          fileId={document.id}
          name={document.file_name}
          type="file"
          disabled={
            (!subscription || (subscription && subscription.status !== SubscriptionStatus.ACTIVE)) &&
            folders?.[0]?.vaultNotes?.length + document_index >= VAULT_FREEMIUM_LIMIT
          }
        />
      </div>

      {/* Info */}
      <div>
        <p className="font-semibold font-medium text-xs leading-normal text-gray-700 mb-3 break-all">{documentName}</p>
        <p className="flex justify-between font-normal font-medium text-xs leading-tight">
          <span className="text-gray-900">{convertBytestoMB(Number(document.file_size))} .</span>
          <span className="text-gray-600">{dayjs(document.created_at).format("D/MM/YYYY")}</span>
        </p>
      </div>
    </div>
  );
};

export default VaultDocumentItem;
