import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const GetStartedCTA = () => {
  const { t } = useTranslation();

  return (
    <div className="terms_get_started">
      <div className="terms_container">
        <div className="content">
          <h4>{t("getStarted.header")}</h4>
          <br />
          <p>{t("getStarted.info")}</p>
        </div>
        <div className="button">
          <Link to="/signin">{t("getStarted.signupBtn")}</Link>
        </div>
      </div>
    </div>
  );
};

export default GetStartedCTA;
