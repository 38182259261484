export const AppleIcon = (props: { classNames?: string }) => {
  return (
    <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7689 12.3007C15.7796 11.466 16.0013 10.6477 16.4134 9.9217C16.8254 9.19575 17.4143 8.58581 18.1254 8.14864C17.6737 7.50348 17.0777 6.97253 16.3849 6.59798C15.6921 6.22343 14.9215 6.01559 14.1343 5.99096C12.455 5.8147 10.8271 6.99579 9.97137 6.99579C9.0991 6.99579 7.7816 6.00846 6.36287 6.03765C5.4452 6.06729 4.55085 6.33415 3.76697 6.81221C2.98309 7.29026 2.33642 7.96322 1.88995 8.76551C-0.0440497 12.1139 1.39854 17.035 3.25115 19.7416C4.17805 21.0669 5.26133 22.5474 6.67878 22.4949C8.06584 22.4374 8.58388 21.6104 10.2582 21.6104C11.9169 21.6104 12.403 22.4949 13.8492 22.4615C15.3376 22.4374 16.2753 21.1303 17.1697 19.7924C17.8357 18.848 18.3481 17.8043 18.6881 16.6999C17.8234 16.3341 17.0855 15.722 16.5664 14.9397C16.0472 14.1574 15.7699 13.2396 15.7689 12.3007Z"
        fill="white"
      />
      <path
        d="M13.0372 4.2109C13.8488 3.23669 14.2486 1.98451 14.1518 0.720276C12.9119 0.850496 11.7667 1.44306 10.9442 2.37989C10.542 2.83755 10.234 3.36999 10.0378 3.94676C9.84155 4.52353 9.7609 5.13332 9.80047 5.74128C10.4206 5.74766 11.0341 5.61325 11.5948 5.34816C12.1554 5.08307 12.6486 4.69422 13.0372 4.2109Z"
        fill="white"
      />
    </svg>
  );
};
