import { useTranslation } from "react-i18next";
import { PowerIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

export const SidebarLogoutBtn = (props: { activePortfolio: any; onClick: () => void }) => {
  const { t } = useTranslation();
  const { activePortfolio, onClick } = props;

  return (
    <button
      onClick={onClick}
      className={classNames(
        "w-full max-w-[230px] py-2 px-2 gap-x-4",
        "flex flex-row items-center",
        "text-sm rounded-md cursor-pointer hover:bg-blue2-900",
        activePortfolio.owner || activePortfolio.owner === undefined ? "" : "hidden"
      )}
    >
      <PowerIcon className="w-5 h-5" />
      <span>{t("manage.logout")}</span>
    </button>
  );
};
