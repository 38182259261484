export const GoogleIcon = (props: { classs?: string }) => {
  return (
    <svg
      className={props.classs}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.7141 10.4599H21.875V10.4166H12.5V14.5833H18.387C17.5281 17.0088 15.2203 18.75 12.5 18.75C9.04843 18.75 6.25 15.9515 6.25 12.5C6.25 9.04842 9.04843 6.24998 12.5 6.24998C14.0932 6.24998 15.5427 6.85102 16.6464 7.83279L19.5927 4.88644C17.7323 3.15258 15.2437 2.08331 12.5 2.08331C6.74739 2.08331 2.08333 6.74738 2.08333 12.5C2.08333 18.2526 6.74739 22.9166 12.5 22.9166C18.2526 22.9166 22.9167 18.2526 22.9167 12.5C22.9167 11.8015 22.8448 11.1198 22.7141 10.4599Z"
        fill="#FFC107"
      />
      <path
        d="M3.28438 7.65154L6.70677 10.1614C7.63282 7.86873 9.87552 6.24998 12.5 6.24998C14.0932 6.24998 15.5427 6.85102 16.6464 7.83279L19.5927 4.88644C17.7323 3.15258 15.2438 2.08331 12.5 2.08331C8.49896 2.08331 5.02917 4.34217 3.28438 7.65154Z"
        fill="#FF3D00"
      />
      <path
        d="M12.5 22.9167C15.1906 22.9167 17.6354 21.887 19.4839 20.2125L16.2599 17.4844C15.1789 18.3064 13.8581 18.7511 12.5 18.75C9.79062 18.75 7.4901 17.0224 6.62344 14.6115L3.22656 17.2286C4.95052 20.6021 8.45156 22.9167 12.5 22.9167Z"
        fill="#4CAF50"
      />
      <path
        d="M22.7141 10.4599H21.875V10.4167H12.5V14.5834H18.387C17.9762 15.7377 17.2361 16.7465 16.2583 17.4849L16.2599 17.4839L19.4839 20.212C19.2557 20.4193 22.9167 17.7084 22.9167 12.5C22.9167 11.8016 22.8448 11.1198 22.7141 10.4599Z"
        fill="#1976D2"
      />
    </svg>
  );
};
