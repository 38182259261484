import { useNavigate } from "react-router-dom";
import { AlertIcon } from "../../components/icons/alertcircle";
import { VAULT_FREEMIUM_LIMIT } from "./vault-utils";
import { useTranslation } from "react-i18next";

const VaultListMaxed = ({ folders }: { folders: any }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const hasExceededFileLimit =
    folders?.[0]?.vaultNotes?.length + folders?.[0]?.vaultDocuments?.length >= VAULT_FREEMIUM_LIMIT;
  const numFilesUploaded = (folders?.[0]?.vaultNotes?.length || 0) + (folders?.[0]?.vaultDocuments?.length || 0);

  return (
    <div className="text-sm mb-6 p-3 rounded-md notification-message -mt-7">
      <AlertIcon className="mr-2 my-auto inline" />
      {hasExceededFileLimit ? (
        <span>You’ve exceeded the maximum number of files for {t("orgName")} Free Plan.</span>
      ) : (
        <span>
          You’ve uploaded {numFilesUploaded} of {VAULT_FREEMIUM_LIMIT} documents.
        </span>
      )}
      <span
        className="ml-2 font-semibold text-primary cursor-pointer"
        onClick={() => {
          navigate("/settings?tab=billing");
        }}
      >
        Upgrade to upload more important documents
      </span>
    </div>
  );
};

export default VaultListMaxed;
