import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const VaultMeta = () => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>Vault</title>
      <meta name="description" content={`Secure your most important documents with ${t("orgName")}`} />
      <meta property="og:title" content="Vault" />
      <meta property="og:description" content={`Secure your most important documents with ${t("orgName")}`} />
      <meta property="og:url" content="https://app.getcova.com/vault" />
      <meta name="twitter:card" content={`Secure your most important documents with ${t("orgName")}`} />
    </Helmet>
  );
};

export default VaultMeta;
