import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ConvertNumberCur, forMatterNumber } from "../../../services/utilty";
import { ConfirmationModal } from "../ConfirmationModal";
import { Partners, AccountType } from "../../../types/accountTypes";
import { LabelType } from "../../../types/categoryTypes";
import EditAccountLabels from "../labels/editAccountLabels";
import LabelAccounts from "../labels/labelAccounts";
import GrowthRate from "../../growthRateV2/growth";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

export const AccountDetails = ({
  account,
  setConfirmDelete,
  handleAddCrypto,
  handleAddStocks,
  closeModal,
  handleRemoveAccountLabel,
  handleSaveLabel,
  isShared,
}: {
  setConfirmDelete: React.Dispatch<React.SetStateAction<boolean>>;
  account: AccountType;
  handleAddStocks: () => void;
  handleAddCrypto: () => void;
  closeModal: () => void;
  handleRemoveAccountLabel: (id: string) => void;
  handleSaveLabel: (accountIds: string[], labelId: string) => void;
  isShared?: boolean;
}) => {
  const labels = useSelector((state: any) => state.portfolios.labels);
  const [scrollActive, setScrollActive] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [addNewLabel, setAddNewLabel] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const selectedLabel = labels.find((label: LabelType) => label.id === account?.label?.id);
  const growth = ((account.balance.value - account.balance_original.value) / account.balance_original.value) * 100;
  const AccountDetails = [
    {
      title: "Account Number",
      value: account?.number ?? "",
    },
    {
      title: "Account Type",
      value: account?.type,
    },
    {
      title: "Bank Name",
      value: account?.institution?.name ?? "",
    },
  ];

  const handleScroll = (e: any) => {
    if (e.currentTarget.scrollLeft > 0) setScrollActive(true);
    else setScrollActive(false);
  };

  const handleLabelRemoval = () => {
    handleRemoveAccountLabel(account?.id);
    setDeleteModal(false);
    closeModal();
  };

  return (
    <div>
      {addNewLabel && (
        <LabelAccounts
          title="Add a new label"
          closeModal={() => setAddNewLabel(false)}
          isOpen={addNewLabel}
          closeManageLabelModal={closeModal}
          handleSaveLabel={handleSaveLabel}
        />
      )}

      {editModal && selectedLabel && (
        <EditAccountLabels
          isOpen={editModal}
          label={selectedLabel}
          closeModal={() => setEditModal(false)}
          handleAddNewLabel={() => {
            setEditModal(false);
            setAddNewLabel(true);
          }}
        />
      )}

      {deleteModal && (
        <ConfirmationModal
          key={Math.random()}
          modalIsOpen={deleteModal}
          closeModal={() => {
            setDeleteModal(false);
          }}
          submitAction={handleLabelRemoval}
          confirmationText={
            <span>
              Remove {<span className="capitalize font-bold">{account?.display_name}</span>} from {selectedLabel?.name}.
            </span>
          }
        />
      )}
      <div className="text-p2 mb-6">
        <p className="font-medium">Growth</p>
        <div className="text-type-100 capitalize">
          <GrowthRate growthRate={growth !== Infinity ? growth : 0} />
        </div>
      </div>
      {AccountDetails.map((account, index) => (
        <div className="text-p2 mb-6" key={`account-${index}`}>
          <p className="font-medium">{account.title}</p>
          <p className="text-type-100 capitalize">{account.value}</p>
        </div>
      ))}
      {account.label && (
        <div className="text-p2 mb-6">
          <p className="font-medium">Label</p>
          <div className="flex justify-between mt-1">
            <span className="bg-neutral-200 text-p3 py-1 px-2 font-semibold text-type rounded-sm">
              {account.label.name}
            </span>
            {!isShared && (
              <div>
                <button onClick={() => setEditModal(true)}>
                  <PencilIcon className="w-5 h-5" />
                </button>
                <button className="delete-btn" onClick={() => setDeleteModal(true)}>
                  <TrashIcon className="w-5 h-5" />
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {account.holdings.length > 0 && (
        <div className="holdings text-p2">
          <p className="text-type font-medium mb-3">Holdings</p>
          <div className="holdings-table mb-4 pb-4" onScroll={handleScroll}>
            <div className="row-header flex">
              <div className="mobile-grid">
                <div className={`fixed-content ${scrollActive ? "shadow font-semibold" : ""}`}>
                  <p>Stock</p>
                </div>
                <p>Quantity</p>
                <p>Cost</p>
                <p>Price</p>
                <p>Change</p>
                <p>Change {"(%)"}</p>
                <p>Value</p>
              </div>
            </div>
            {account.holdings.map((holding: any, i: number) => (
              <div className="flex row-content" key={i}>
                <div className="mobile-grid">
                  <div className={`fixed-content bg-white ${scrollActive ? "shadow" : ""}`}>
                    <p>{holding.name}</p>
                  </div>

                  <p>{forMatterNumber(holding.quantity)}</p>
                  <p>{"$" + forMatterNumber(holding.price)}</p>
                  <p>{ConvertNumberCur(holding.price, holding.currency)}</p>
                  <div>
                    <GrowthRate growthRate={holding.value - holding.value_original} hidePercentage />
                  </div>
                  <div>
                    <GrowthRate
                      growthRate={((holding.value - holding.value_original) / holding.value_original) * 100}
                    />
                  </div>

                  <p>{ConvertNumberCur(holding.value, holding.currency)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {!isShared && (account.partner === Partners.MARKET_STACK || account.partner === Partners.COIN_CAP) && (
        <div>
          {account.partner === Partners.MARKET_STACK && (
            <button
              className="text-primary font-semibold text-p2 text-left underline py-4 click-item"
              onClick={handleAddStocks}
            >
              + Add Stock
            </button>
          )}
          {account.partner === Partners.COIN_CAP && (
            <button
              className="text-primary font-semibold text-p2 text-left underline py-4 click-item"
              onClick={handleAddCrypto}
            >
              + Add Crypto
            </button>
          )}
        </div>
      )}

      {!isShared && (
        <button
          className="text-status-danger font-semibold underline text-p2 mb-8"
          onClick={() => setConfirmDelete(true)}
        >
          Delete Account
        </button>
      )}
    </div>
  );
};
