export const AssetsIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.0001 40.3C23.5667 40.3 23.1501 40.2167 22.7501 40.05C22.3501 39.8833 22.0001 39.6167 21.7001 39.25L5.25008 19.5C4.88341 19.0333 4.66674 18.5083 4.60008 17.925C4.53341 17.3417 4.63341 16.7833 4.90008 16.25L9.15008 7.65C9.41674 7.15 9.79174 6.75 10.2751 6.45C10.7584 6.15 11.2834 6 11.8501 6H36.1501C36.7167 6 37.2417 6.15 37.7251 6.45C38.2084 6.75 38.5834 7.15 38.8501 7.65L43.1001 16.25C43.3667 16.7833 43.4667 17.3417 43.4001 17.925C43.3334 18.5083 43.1167 19.0333 42.7501 19.5L26.3001 39.25C26.0001 39.6167 25.6501 39.8833 25.2501 40.05C24.8501 40.2167 24.4334 40.3 24.0001 40.3ZM17.9001 16.5H30.1001L26.3501 9H21.6501L17.9001 16.5ZM22.5001 35.55V19.5H9.15008L22.5001 35.55ZM25.5001 35.55L38.8501 19.5H25.5001V35.55ZM33.4501 16.5H39.7501L35.9501 9H29.7001L33.4501 16.5ZM8.25008 16.5H14.5501L18.3001 9H12.0501L8.25008 16.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
