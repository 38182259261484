export type LifeCheck = {
  enabled: boolean;
  contacts: ContactType[];
  steps: LifecheckStepType[];
  history: LifecheckHistoryType;
};

export type AddContactType = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  phone_number_country_code: string;
  portfolio_id?: number;
  id?: string;
  security_questions: SecurityQuestionType[];
};

type SecurityQuestionType = {
  answer: string;
  question: string;
};

export type ContactType = {
  first_name: string;
  email: string;
  id: string;
  last_name: string;
  phone_number: string;
  phone_number_country_code: string;
  lifecheck_steps: LifecheckStepType[];
  security_questions: SecurityQuestionType[];
  updated_at: string;
};

export enum LifecheckDuration {
  DAYS = "DAYS",
  MONTHS = "MONTHS",
  WEEKS = "WEEKS",
}

export enum LifecheckActions {
  NOTIFY_CONTACT = "NOTIFY_CONTACT",
  NOTIFY_OWNER = "NOTIFY_OWNER",
  SHARE_PORTFOLIO = "SHARE_PORTFOLIO",
}

export type LifecheckModalTypes = "" | "Add Contact" | "View Contact" | "Add Edit Step";

export type LifecheckSetUpTypes = {
  action: string;
  duration: string;
  length: number | undefined;
  contacts: string[];
  custom_note: string;
};

export type LifecheckStepType = {
  trigger_after: number;
  trigger_after_unit: string;
  action: LifecheckActions;
  contacts: ContactType[];
  sort_index?: number;
  id: string;
  custom_note: string;
};

export type LifecheckHistoryType = {
  data: LifecheckHistoryDataType[];
  meta: {
    actualSize: number;
    offset: number;
    requestedSize: number;
    total: number;
  };
};

export type LifecheckHistoryDataType = {
  id: string;
  portfolio_id: number;
  created_at: string;
  details: any;
  message: string;
  type: string;
};
