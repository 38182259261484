import "../auth.scss";
import { useState, useEffect } from "react";
import { Textinput } from "../../../components/inputs/textinput";
import { TextinputwithLeftIcon } from "../../../components/inputs/textinputwithlefticon";
import { useNavigate, useLocation } from "react-router-dom";
import { Errornotf } from "../../../components/notifications/errornotf";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { loaderData, setUtilityData } from "../../../store/actions/utility";
import queryString from "query-string";
import { toast } from "react-toastify";
import { SuccessToast } from "../../../components/notifications/toasts";
import MainButton from "../../../components/buttons/mainButton";
import { Verify2faPage } from "../verifytwofa";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CURRENT_USER,
  FETCH_APPLE_URL,
  FETCH_GOOGLE_URL,
  LOGIN_USER,
  LOGIN_WITH_GOOGLE_URL,
  LOGIN_WITH_APPLE_URL,
  RESEND_VERIFY_CODE,
} from "../../../graphql/auth";
import { CurrentUser, TwoFaTypes } from "../../../types/authTypes";
import { SET_USER_STATE } from "../../../store/reducers/userdatav2/types";
import jwt_decode from "jwt-decode";
import { VERIFY_PORTFOLIO_INVITE } from "../../../graphql/portfolio";
import { Capacitor } from "@capacitor/core";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import GoogleAuthBtn from "../../../components/buttons/GoogleAuthBtn";
import AppleAuthBtn from "../../../components/buttons/AppleAuthBtn";
import LoginPanelForgotPassword from "./LoginPanelForgotPassword";
import { SignInWithApple, SignInWithAppleResponse } from "@capacitor-community/apple-sign-in";
import { appleSignInOptions } from "../config";

const LoginPanelSignin = () => {
  const isNative = Capacitor.isNativePlatform();
  const isAndroid = Capacitor.getPlatform() === "android";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location: any = useLocation();
  let parseddata: any = queryString.parse(location.search);
  const [redirectRequired, setRedirectRequired] = useState<boolean>(false);
  const [ispassword, setispassword] = useState(true);
  const [switchView, setSwitchView] = useState(false);
  const [twoFaType, setTwoFaType] = useState<string>("");
  const [errorMessage, seterrorMessage] = useState<any>(null);
  const [payload, setPayload] = useState<{
    email: string;
    password: string;
  }>({
    email: "",
    password: "",
  });
  const [token, setToken] = useState<string>("");
  const [tempToken, setTempToken] = useState<string>("");

  const [getCurrentUser, { data: currentUserResponse }] = useLazyQuery(CURRENT_USER, {
    context: {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
    errorPolicy: "all",
    onCompleted: () => {
      const payload: { currentUser: CurrentUser } = { ...currentUserResponse, token };

      dispatch({
        type: SET_USER_STATE,
        payload: payload,
      });

      dispatch(setUtilityData(loaderData(false, "")));

      localStorage.setItem("token", token);
      if (redirectRequired) {
        verifyPortfolio();
      } else {
        navigate(location?.state?.from ?? "/report");
      }
    },
    onError(error: any) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  // Page Loader
  // useEffect(() => {
  // todo: replace this. this causes loading when coming from other pages.
  // this loading states only needed when app opens from scratch or refresh
  // dispatch(setUtilityData(loaderData(true, "")));
  // setTimeout(() => {
  //   dispatch(setUtilityData(loaderData(false, "")));
  // }, 2500);
  // }, [dispatch]);

  // Portfolio Invites
  const [verifyPortfolioInvite] = useMutation(VERIFY_PORTFOLIO_INVITE, {
    onCompleted(data) {
      const decoded: any = jwt_decode(parseddata?.sec);
      navigate(`/verify-portfolio-access?id=${decoded.portfolio_id}`);
      dispatch(setUtilityData(loaderData(false, "")));
    },
    onError(error: any) {
      dispatch(setUtilityData(loaderData(false, "")));
      toast.error(error.message);
    },
  });

  const handleVerifyPortfolioInvite = (token: string) => {
    dispatch(setUtilityData(loaderData(true, "")));
    verifyPortfolioInvite({
      variables: {
        token,
      },
    });
  };

  const verifyPortfolio = async () => {
    handleVerifyPortfolioInvite(parseddata?.sec);
  };

  // Email + Password Form
  const { handleSubmit, errors, control } = useForm({ criteriaMode: "all", mode: "onChange" });

  const [signin] = useMutation(LOGIN_USER, {
    onCompleted(data: any) {
      if (data.signIn.hasTotpEnabled || data.signIn.hasSmsEnabled) {
        setTempToken(data.signIn.accessToken);
        setTwoFaType(data.signIn.hasTotpEnabled ? TwoFaTypes.TOTP : TwoFaTypes.OTP);
        setSwitchView(true);
      } else {
        setToken(data.signIn.accessToken);
      }

      dispatch(setUtilityData(loaderData(false, "")));
    },
    onError(error: any) {
      if (error.message === "Please verify your email address before you can login") {
        VERIFYEMAIL();
        navigate(`/verify-email?email=${encodeURIComponent(payload.email)}`);
      }
      toast.error("Sign-in failed. Please verify your email and password and try again.");
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  const [resendcode] = useMutation(RESEND_VERIFY_CODE, {
    onCompleted(data) {
      toast(<SuccessToast message={"A new token has been sent to your email"} />, {
        className: "custom-toast-success",
      });
      dispatch(setUtilityData(loaderData(false, "")));
    },
    onError(error: any) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  const onSubmit = () => {
    seterrorMessage(null);
    dispatch(setUtilityData(loaderData(true, "")));
    signin({
      variables: {
        signInInput: payload,
      },
    });
  };

  let VERIFYEMAIL = () => {
    seterrorMessage(null);

    dispatch(setUtilityData(loaderData(true, "")));
    resendcode({
      variables: {
        email: payload.email,
      },
    });
  };

  const changeinputField = () => {
    setispassword(!ispassword);
  };

  // Signin with Google
  const [fetchgoogleurl] = useMutation(FETCH_GOOGLE_URL, {
    onCompleted(data: any) {
      window.location.href = data.signInWithGoogleUrl;
    },
    onError(error: any) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  const onClickGoogleSignin = async () => {
    try {
      if (isNative) {
        // Native Google Sign-In
        const result = await GoogleAuth.signIn();
        if (result && result?.serverAuthCode) {
          const { serverAuthCode } = result;
          await signinwithgoogle({
            variables: {
              code: typeof serverAuthCode === "string" ? decodeURI(serverAuthCode) : "",
              redirectUri: window.location.origin + "/signin",
            },
          });
        }
      } else {
        // Web-based Google Sign-In
        fetchgoogleurl({
          variables: {
            redirectUri: window.location.origin + "/signin",
          },
        });
      }
    } catch (error) {
      console.error("Google Sign-In failed:", error);
    }
  };

  const [signinwithgoogle] = useMutation(LOGIN_WITH_GOOGLE_URL, {
    onCompleted(data: any) {
      if (data.signInWithGoogle.hasTotpEnabled || data.signInWithGoogle.hasSmsEnabled) {
        if (data.signInWithGoogle.is2FAAuthenticated) {
          setToken(data.signInWithGoogle.accessToken);
        } else {
          setTwoFaType(data.signInWithGoogle.hasTotpEnabled ? TwoFaTypes.TOTP : TwoFaTypes.OTP);
          setTempToken(data.signInWithGoogle.accessToken);

          setSwitchView(true);
        }
      } else {
        setToken(data.signInWithGoogle.accessToken);
      }
      dispatch(setUtilityData(loaderData(false, "")));
    },
    onError(error: any) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  useEffect(() => {
    let { code } = queryString.parse(location.search);
    if (code) {
      dispatch(setUtilityData(loaderData(true, "")));

      signinwithgoogle({
        variables: {
          code: typeof code === "string" ? decodeURI(code) : "",
          redirectUri: window.location.origin + "/signin",
        },
      });
    }

    // When Google signup occurs, the token is passed as a query param
    let { token: passedToken } = queryString.parse(location.search);
    if (passedToken) {
      setToken(passedToken as string);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Signin with Apple
  const [fetchAppleUrl] = useMutation(FETCH_APPLE_URL, {
    onCompleted(data: any) {
      window.location.href = data.signInWithAppleUrl;
    },
    onError(error: any) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  const onClickAppleSignin = async () => {
    if (isNative) {
      try {
        const result: SignInWithAppleResponse = await SignInWithApple.authorize(appleSignInOptions);

        if (result && result?.response?.authorizationCode) {
          const { authorizationCode } = result?.response;
          await signinWithApple({
            variables: {
              code: typeof authorizationCode === "string" ? decodeURI(authorizationCode) : "",
              redirectUri: window.location.origin + "/signin",
            },
          });
        }
      } catch (error) {
        console.error("Apple Sign-In failed:", error);
      }
    } else {
      // Web-based Apple Sign-In
      fetchAppleUrl({
        variables: {
          clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
          subdomain: process.env.REACT_APP_APPLE_SUBDOMAIN,
        },
      });
    }
  };

  const [signinWithApple] = useMutation(LOGIN_WITH_APPLE_URL, {
    onCompleted(data: any) {
      // todo: isNewUser property can be used for onboarding in the future
      if (data.signInWithApple.hasTotpEnabled || data.signInWithApple.hasSmsEnabled) {
        if (data.signInWithApple.is2FAAuthenticated) {
          setToken(data.signInWithApple.accessToken);
        } else {
          setTwoFaType(data.signInWithApple.hasTotpEnabled ? TwoFaTypes.TOTP : TwoFaTypes.OTP);
          setTempToken(data.signInWithApple.accessToken);

          setSwitchView(true);
        }
      } else {
        setToken(data.signInWithApple.accessToken);
      }

      dispatch(setUtilityData(loaderData(false, "")));
    },
    onError(error: any) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  useEffect(() => {
    let { apple_sso_code } = queryString.parse(location.search);

    if (apple_sso_code) {
      dispatch(setUtilityData(loaderData(true, "")));

      // TODO: This isn't ideal, but passing the authorizationCode to the signin page results in a clientId mismatch error
      if (apple_sso_code === "signup") {
        onClickAppleSignin();
      } else {
        signinWithApple({
          variables: {
            code: decodeURI(apple_sso_code as string),
            clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
          },
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Todo: what is .exed and .sec?
  useEffect(() => {
    if (parseddata?.exed && parseddata?.sec) {
      setRedirectRequired(true);
    }

    if (token) {
      dispatch(setUtilityData(loaderData(true, "")));
      getCurrentUser();
    }
    //eslint-disable-next-line
  }, [token]);

  return (
    <div className="secondhalf">
      <div className="max-w-md w-full">
        {switchView ? (
          <Verify2faPage twoFaType={twoFaType} userEmail={payload.email} setToken={setToken} tempToken={tempToken} />
        ) : (
          <>
            <div className="flex justify-between mb-8">
              <h3 className="text-h3 font-medium">Welcome Back</h3>
              <a
                href="/signup"
                className="flex justify-center items-center text-p1 font-semibold text-primary cursor-pointer"
              >
                Sign up
              </a>
            </div>

            {/* Single Sign On Options */}
            <div className="flex flex-col gap-y-4 mb-4">
              <GoogleAuthBtn onClick={onClickGoogleSignin} />
              {!isAndroid && <AppleAuthBtn onClick={onClickAppleSignin} />}
            </div>

            <div className="border-b border-neutral-200 mb-4 pb-2">
              <p className="text-p2 text-type-200 text-center">Or</p>
            </div>

            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Errornotf style={{ marginBottom: "30px" }} message={errorMessage} />
                <div className="mb-4">
                  <Controller
                    name="email"
                    defaultValue={null}
                    rules={{ required: true }}
                    control={control}
                    render={(props) => (
                      <Textinput
                        onChange={(e: any) => {
                          setPayload({ ...payload, email: e.target.value });
                          props.onChange(e.target.value);
                        }}
                        checked={props.value}
                        label="Email"
                        inputid="email"
                        name="email"
                        type="email"
                        iserror={errors.email}
                        placeholder="name@email.com"
                        message={"Please provide a correct email address."}
                      />
                    )} // props contains: onChange, onBlur and value
                  />
                </div>
                <div className="mb-4">
                  <Controller
                    name="password"
                    defaultValue={null}
                    rules={{ required: true }}
                    control={control}
                    render={(props) => (
                      <TextinputwithLeftIcon
                        onChange={(e: any) => {
                          setPayload({ ...payload, password: e.target.value });
                          props.onChange(e.target.value);
                        }}
                        onclickicon={changeinputField}
                        checked={props.value}
                        label="Password"
                        name="password"
                        inputid="password"
                        type={ispassword ? "password" : "text"}
                        iserror={errors.password}
                        placeholder="8 characters, 1 upper case letter and 1 number."
                        message={"This field is required"}
                      />
                    )}
                  />
                </div>
                <div>
                  <MainButton
                    click={handleSubmit(onSubmit)}
                    type="primary"
                    id="login-submit"
                    extraClasses="w-full mb-4"
                    size="big"
                  >
                    <span className="text-p1">Sign In</span>
                  </MainButton>
                </div>
              </form>
            </div>
            <LoginPanelForgotPassword onClickForgotPassword={() => navigate("/forgot-password")} />
          </>
        )}
      </div>
    </div>
  );
};

export default LoginPanelSignin;
