export const SuccessCheckIcon = ({ color }: any) => {
  return (
    <svg width="25" height="25" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.15 14.05C39.4167 14.9833 39.625 15.95 39.775 16.95C39.925 17.95 40 18.9667 40 20C40 22.8333 39.4833 25.4667 38.45 27.9C37.4167 30.3333 36 32.45 34.2 34.25C32.4 36.05 30.2833 37.4583 27.85 38.475C25.4167 39.4917 22.8 40 20 40C17.1667 40 14.5333 39.4917 12.1 38.475C9.66667 37.4583 7.55 36.05 5.75 34.25C3.95 32.45 2.54167 30.3333 1.525 27.9C0.508333 25.4667 0 22.8333 0 20C0 17.2 0.508333 14.5833 1.525 12.15C2.54167 9.71667 3.95 7.6 5.75 5.8C7.55 4 9.66667 2.58333 12.1 1.55C14.5333 0.516667 17.1667 0 20 0C22.1667 0 24.2083 0.316666 26.125 0.95C28.0417 1.58333 29.8333 2.43333 31.5 3.5C31.8667 3.73333 32.075 4.05 32.125 4.45C32.175 4.85 32.0667 5.21667 31.8 5.55C31.5333 5.88333 31.2 6.08333 30.8 6.15C30.4 6.21667 30.0167 6.13333 29.65 5.9C28.25 5.03333 26.7417 4.33333 25.125 3.8C23.5083 3.26667 21.8 3 20 3C15.1667 3 11.125 4.625 7.875 7.875C4.625 11.125 3 15.1667 3 20C3 24.8333 4.625 28.875 7.875 32.125C11.125 35.375 15.1667 37 20 37C24.8333 37 28.875 35.375 32.125 32.125C35.375 28.875 37 24.8333 37 20C37 19.1667 36.95 18.3667 36.85 17.6C36.75 16.8333 36.6 16.0667 36.4 15.3C36.3 14.9 36.325 14.5 36.475 14.1C36.625 13.7 36.8833 13.4167 37.25 13.25C37.6167 13.0833 37.9917 13.0667 38.375 13.2C38.7583 13.3333 39.0167 13.6167 39.15 14.05ZM16 28.05L9.9 21.9C9.6 21.6 9.45 21.2333 9.45 20.8C9.45 20.3667 9.61667 19.9833 9.95 19.65C10.25 19.35 10.6167 19.2 11.05 19.2C11.4833 19.2 11.8667 19.35 12.2 19.65L17.05 24.55L36.65 4.95C36.95 4.65 37.3167 4.49167 37.75 4.475C38.1833 4.45833 38.5667 4.61667 38.9 4.95C39.2333 5.28333 39.4 5.66667 39.4 6.1C39.4 6.53333 39.2333 6.91667 38.9 7.25L18.1 28.05C17.8 28.35 17.4417 28.5 17.025 28.5C16.6083 28.5 16.2667 28.35 16 28.05Z"
        fill={color || "#4EB547"}
      />
    </svg>
  );
};
