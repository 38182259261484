import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { StatusBar, Style } from "@capacitor/status-bar";
import classNames from "classnames";
import jwt_decode from "jwt-decode";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as Crown } from "../../assets/svg/premium-crown.svg";
import CheckCir from "../../assets/svg/successcheck.svg";
import { useCurrentUserGql } from "../../graphql/current-user.gql-hook";
import { usePortfolioDetailsLazyQuery } from "../../graphql/portfolio.gql-hook";
import { pageContainerId, scrollToPageTop } from "../../helper/helper";
import useAccountConnect from "../../hooks/useAccountConnect";
import useAuth from "../../hooks/useAuth";
import usePortfolio from "../../hooks/usePortfolio";
import Collaboration from "../../pages/collaborations/collaboration";
import Contacts from "../../pages/contacts/contacts";
import Dashboard from "../../pages/dashboard/dashboard";
import LifeCheck from "../../pages/lifecheck-v2/lifecheck";
import PortfolioVault from "../../pages/lifecheck/portfoliovault";
import VerifySecurityQuestion from "../../pages/lifecheck/verifysecurityquestion";
import AssetsByClasses from "../../pages/portfolio/classes";
import AssetsByInstitution from "../../pages/portfolio/institutions";
import AssetsByLabels from "../../pages/portfolio/labels";
import { SettingsPage } from "../../pages/settings/settings";
import { VaultPage } from "../../pages/vault/Vault";
import { setCurrencyData } from "../../store/actions/assetsaction";
import { logoutaUser } from "../../store/actions/userdata";
import { loaderData, setUtilityData } from "../../store/actions/utility";
import { HandlePartnerCallbackData, Partners } from "../../types/accountTypes";
import { UserState } from "../../types/authTypes";
import { PaymentPartners, SubscriptionStatus } from "../../types/subscriptionTypes";
import { AlertIcon } from "../icons/alertcircle";
import { useGetSubscriptionQuery } from "../../api/subscription/subscription-queries";
import { useTranslation } from "react-i18next";
import { usePortfoliosQuery } from "../../api/portfolio/portfolio-queries";
import { INotification, StickyNotification } from "../StickyNotification";
import Sidebar from "../sidebar/Sidebar";
import Header from "./Header";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import "./index.scss";

const LoggedinContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [notification, setNotification] = useState<INotification | null>(null);
  let navigate = useNavigate();
  const location = useLocation();
  const account = new URLSearchParams(location.search).get("account");
  const code = new URLSearchParams(location.search).get("code");
  const activePortfolioId = useSelector((state: any) => state.portfolios.activePortfolioId);
  const { handleGetActivePortfolio, handleGetPortfolioData } = usePortfolio();
  const { handleConnectCallback } = useAccountConnect();
  const { getCurrentUser, getUserConfig } = useAuth();
  const user: UserState = useSelector((state: any) => state.user);

  // TODO: Clean up file to use new Redux states and actions.
  // Beginning of changes making use of new reducers. At some point, most other stuff before this line will be removed.
  const { fetchUserPreferences, fetchUser } = useCurrentUserGql();
  const { fetcher: fetchPortfolio } = usePortfolioDetailsLazyQuery();
  const xCurrency = useSelector((state: any) => state.assets.currency.code);
  const xActivePortfolioId = useSelector((state: any) => state.activePortfolio.id);
  const {
    id: userId,
    preferences: { sms_mfa_enabled, totp_mfa_enabled },
  } = useSelector((state: any) => state.currentUser);

  useEffect(() => {
    // things needed to render the app
    fetchUser();
    fetchUserPreferences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (!xActivePortfolioId) return;

    fetchPortfolio({
      variables: {
        id: xActivePortfolioId,
        currency: xCurrency,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xActivePortfolioId, xCurrency]);
  // End of changes making use of new reducers.

  useEffect(() => {
    getCurrentUser();
    getUserConfig({ variables: { partner: PaymentPartners.STRIPE } });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleDocumentClick = (event: Event) => {
      checkTokenExpiry();
    };
    document.addEventListener("click", handleDocumentClick, false);
    return () => {
      document.removeEventListener("click", handleDocumentClick, false);
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { currentUser, config } = user;
    const default_currency = currentUser.preferences.default_currency;

    const currency = config.currencies.find((cur: any) => cur?.code === default_currency);

    if (currency) {
      dispatch(setCurrencyData(currency));
    } else {
      dispatch(
        setCurrencyData({
          code: "",
          symbol: "",
          name: "",
        })
      );
    }

    //eslint-disable-next-line
  }, [user.currentUser.preferences.default_currency]);

  useEffect(() => {
    scrollToPageTop();
  }, [location.pathname]);

  // Initial load of Portfolios
  const { refetch: refetchPortfolios } = usePortfoliosQuery();

  useEffect(() => {
    // console.info("fetched user portfolios", portfolios);
    refetchPortfolios();

    if (location.pathname === "/" && !account) {
      navigate("/report");
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (activePortfolioId) {
      handleGetActivePortfolio(activePortfolioId);
      handleGetPortfolioData(activePortfolioId, false);
    }
    // eslint-disable-next-line
  }, [activePortfolioId]);

  useEffect(() => {
    if (account && code && activePortfolioId) {
      connectCrypto(account);
      navigate("/portfolio/institutions");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, activePortfolioId]);

  useEffect(() => {
    if (user.token) {
      checkTokenExpiry();
    }
    // eslint-disable-next-line
  }, [user.token, location.pathname]);

  const {
    data: subscription,
    isFetched: isFetchedSubscription,
    refetch: refetchSubscription,
  } = useGetSubscriptionQuery({
    id: activePortfolioId,
  });

  useEffect(() => {
    if (isFetchedSubscription) return;
    const interval = setInterval(() => {
      refetchSubscription();
    }, 10000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [isFetchedSubscription]);

  const [readyForStickyNotification, setReadyForStickyNotification] = useState(false);

  // Wait 10 seconds before showing potential sticky notifications
  useEffect(() => {
    setTimeout(() => {
      setReadyForStickyNotification(true);
    }, 10000);
  }, []);

  useEffect(() => {
    if (!readyForStickyNotification) return;
    if (isFetchedSubscription && (!subscription || subscription.status !== SubscriptionStatus.ACTIVE)) {
      setNotification({
        icon: <Crown />,
        content: `You are on ${t("orgName")} Free Plan.`,
        actionText: "Upgrade to get full access",
        action: () => {
          gotoSettingBilling();
        },
        status: "normal",
      });
    } else if (isFetchedSubscription && subscription && subscription.status === SubscriptionStatus.PAYMENT_PENDING) {
      setNotification({
        icon: <AlertIcon />,
        content: "There is an issue with your payment method,",
        actionText: "please update your billing information",
        action: () => {
          gotoSettingBilling();
        },
        status: "warning",
      });
    } else if (!(sms_mfa_enabled || totp_mfa_enabled)) {
      setNotification({
        icon: <LockClosedIcon className="w-4 h-4" />,
        content: "Keep your account secure.",
        actionText: "Set up Two Factor Authentication",
        action: () => {
          gotoSettingNotifi();
        },
        status: "warning",
      });
    } else {
      setNotification(null);
    }
    // eslint-disable-next-line
  }, [user, subscription, readyForStickyNotification]);

  let gotoSettingNotifi = () => {
    navigate("/settings?tab=security");
  };

  let gotoSettingBilling = () => {
    navigate("/settings?tab=billing");
  };

  const checkTokenExpiry = () => {
    if (!user.token) return;

    const decoded: any = jwt_decode(user.token);

    if (decoded?.exp * 1000 < Date.now()) {
      localStorage.setItem("sessionState", location.pathname);
      dispatch(logoutaUser({}));
      toast.error("Session expired! Please login to continue");
    }
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const connectCrypto = (data: string) => {
    dispatch(setUtilityData(loaderData(true, "")));

    const payload: HandlePartnerCallbackData = {
      portfolioId: activePortfolioId,
      partner: Partners.VEZGO,
      data,
    };

    handleConnectCallback(payload)
      .then((res: any) => {
        if (!res?.errors) {
          toast.success(
            <div className="toast-div">
              <img src={CheckCir} alt="check circle" />
              Account connected successfully
            </div>
          );
          dispatch(setUtilityData(loaderData(false, "")));
          navigate("/portfolio/institutions/?rfr=true");
        } else {
          toast.error("Something went wrong, please try again");
          dispatch(setUtilityData(loaderData(false, "")));
        }
      })
      .catch(() => {
        dispatch(setUtilityData(loaderData(false, "")));
      });
  };

  const isNative = Capacitor.isNativePlatform();

  const logDeviceInfo = async () => {
    const info = await Device.getInfo();
    return info;
  };

  useEffect(() => {
    logDeviceInfo().then((device) => {
      if (device.platform === "ios") {
        StatusBar.setStyle({ style: Style.Light });
        StatusBar.show();
      }
    });
  }, []);

  // Gesture to open/close sidebar
  const mainSectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const swipeThreshold = 100; // You can adjust this value as needed
    let startX: number | null = null;
    let startY: number | null = null;

    const handleTouchStart = (e: TouchEvent) => {
      startX = e.touches[0].clientX;
      startY = e.touches[0].clientY;
    };

    const handleTouchMove = (e: TouchEvent) => {
      if (!startX || !startY) {
        return;
      }

      const xUp = e.touches[0].clientX;
      const yUp = e.touches[0].clientY;

      const xDiff = startX - xUp;
      const yDiff = startY - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > swipeThreshold) {
          // Left swipe
          setShowSidebar(false); // Ensure this line is here to close the sidebar
        } else if (xUp - startX > swipeThreshold) {
          // Right swipe
          setShowSidebar(true);
        }
      }
    };

    const mainSectionElement = mainSectionRef.current;

    if (mainSectionElement) {
      mainSectionElement.addEventListener("touchstart", handleTouchStart, false);
      mainSectionElement.addEventListener("touchmove", handleTouchMove, false);
    }

    return () => {
      if (mainSectionElement) {
        mainSectionElement.removeEventListener("touchstart", handleTouchStart, false);
        mainSectionElement.removeEventListener("touchmove", handleTouchMove, false);
      }
    };
  }, []);

  return (
    <>
      <div
        className={classNames(
          "loggedin-container",
          isNative && "loggedin-container--native",
          notification && "has-notification"
        )}
      >
        {notification && <StickyNotification notification={notification} />}
        <Sidebar {...{ toggleSidebar, showSidebar }} />

        <div className="logged-m21">
          <Header {...{ toggleSidebar, showSidebar }} />

          <div className="main-section" id={pageContainerId} ref={mainSectionRef}>
            <Routes>
              <Route path="/shared/:id/vault" element={<PortfolioVault />} />
              <Route path="/" element={<Dashboard />} />
              <Route path="/report" element={<Dashboard />} />
              <Route path="/collaboration" element={<Collaboration />} />
              <Route path="/portfolio/classes" element={<AssetsByClasses />} />
              <Route path="/portfolio/institutions" element={<AssetsByInstitution />} />
              <Route path="/portfolio/labels" element={<AssetsByLabels />} />
              <Route path="/life-check" element={<LifeCheck />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/life-check/verify-portfolio-access" element={<VerifySecurityQuestion hideAuthHeader />} />
              <Route path="/vault" element={<VaultPage />} />
              <Route path="/settings" element={<SettingsPage />} />

              <Route path="*" element={<Navigate replace to="/report" />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoggedinContainer;
