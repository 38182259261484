import { useState, useEffect } from "react";
import Modal from "react-modal";
import AsyncSelect from "react-select/async";
import MainButton from "../../buttons/mainButton";
import { Textinput } from "../../inputs/textinput";
import { useForm, Controller } from "react-hook-form";
import useAccountConnect from "../../../hooks/useAccountConnect";
import { AsyncSelectCustomStyles } from "../../assetClass/panels/StocksPanel";
import { TickerType, CreateHoldingInput, Holding } from "../../../types/accountTypes";
import { ConvertNumberCur } from "../../../services/utilty";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface IAddStockCrypto {
  type: "stock" | "crypto" | "";
  closeModal: () => void;
  modalOpen: boolean;
  submit: (values: CreateHoldingInput) => void;
  accountId: string;
  holding?: Holding | null;
}

interface TickerOptionType {
  label: string;
  value: string;
}

const AddStockCrypto = ({ type, closeModal, modalOpen, submit, accountId, holding }: IAddStockCrypto) => {
  const { t } = useTranslation();
  const { getCryptoTicker, getStockTicker } = useAccountConnect();
  const [selectedTicker, setSelectedTicker] = useState<any>({
    label: "",
    value: "",
  });
  const [tickers, setTickers] = useState<TickerType[]>([]);
  const [summary, setSummary] = useState({
    pricePerUnit: 0,
    totalValue: 0,
    costPerUnit: 0,
    totalCost: 0,
    quantity: 0,
    time: "",
  });

  useEffect(() => {
    if (holding) {
      searchTicker(holding.symbol).then(() => {});

      setSelectedTicker({
        label: holding.name,
        value: holding.symbol,
      });

      setSummary({
        ...summary,
        pricePerUnit: holding.price,
        costPerUnit: (holding.value_original ?? 0) / holding.quantity,
        quantity: holding.quantity,
        totalValue: holding.value,
        totalCost: holding.value_original ?? 0,
      });
    }
    //eslint-disable-next-line
  }, [holding]);

  useEffect(() => {
    if (selectedTicker.value) {
      const getTicker = tickers.find((item: TickerType) => item.symbol === selectedTicker.value);

      if (getTicker) {
        setSummary({
          ...summary,
          pricePerUnit: getTicker.price,
          totalValue: summary.quantity * getTicker.price,
          time: dayjs(getTicker.last_price_checked_at).format("MMM D, YYYY h:mm a"),
        });
      }
    }

    //eslint-disable-next-line
  }, [selectedTicker]);

  const { control, errors, handleSubmit } = useForm({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: {
      ...holding,
      name: holding?.name || "",
      quantity: holding?.quantity || 0,
      cost: (holding?.value_original ?? 0) / (holding?.quantity ?? 0),
    },
  });

  const searchTicker = (value: string) => {
    return new Promise<TickerOptionType[]>((resolve) => {
      const getTickers = type === "crypto" ? getCryptoTicker : getStockTicker;

      getTickers(value).then((res: any) => {
        const data: TickerType[] = res?.data[type === "crypto" ? "cryptocurrencies" : "stocks"];

        setTickers(data);

        resolve(
          data.map((item: TickerType) => ({
            value: item.symbol,
            label: item.name + " - " + item.symbol,
          }))
        );
      });
    });
  };

  const onSubmit = () => {
    handleSubmit((payload) => {
      submit({
        ...payload,
        name: payload.name,
        cost: Number(payload.cost),
        quantity: Number(payload.quantity),
        symbol: selectedTicker.value,
        account_id: accountId,
      });
    })();
  };

  const isNative = Capacitor.isNativePlatform();

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      contentLabel="Modal"
      className={classNames(`addAssetClass postionmoal relative share12`, isNative && "native-modal")}
      overlayClassName={"modal-container-overlay"}
    >
      <div className="overflow-y-auto pr-2 pb-4" style={{ height: "100%" }}>
        <div className="header -translate-y-1 justify-end">
          <button type="button" onClick={closeModal} className="closeicon cursor-pointer">
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>

        <h2 className="header-assetsheader h4-semibold text-lg font-semibold text-type block mb-1 capitalize">
          {holding ? "Update" : "Add"} {type}
        </h2>
        <div className="text-type-100 mt-2 mb-6 text-sm">
          Input the primary details and let {t("orgName")} handle the rest for you
        </div>

        <form
          onKeyUp={(e) => {
            if (e.code === "Enter") {
              handleSubmit((payload) => {
                const getTicker = tickers.find((item: TickerType) => item.symbol === selectedTicker.value);

                if (getTicker) {
                  submit({
                    ...payload,
                    name: payload.name,
                    cost: Number(payload.cost),
                    quantity: Number(payload.quantity),
                    symbol: getTicker?.symbol,
                    account_id: accountId,
                  });
                }
              })();
            }
          }}
          onSubmit={(e) => e.preventDefault()}
          id="cryptoForm"
        >
          <div className="mb-3">
            <label className="text-p2 font-medium text-type mb-1 capitalize" htmlFor="name">
              {type} Ticker
            </label>
            <Controller
              name="name"
              defaultValue={null}
              rules={{ required: true }}
              control={control}
              render={(props) => (
                <AsyncSelect
                  id="name"
                  cacheOptions
                  defaultOptions
                  loadOptions={searchTicker}
                  isDisabled={holding ? true : false}
                  placeholder={selectedTicker.label || `Search ${type}`}
                  styles={AsyncSelectCustomStyles}
                  value={selectedTicker.label}
                  onChange={(data: TickerOptionType) => {
                    props.onChange(data.label);
                    setSelectedTicker(data);
                  }}
                />
              )}
            />
          </div>

          <div className="mb-3">
            <Controller
              name="quantity"
              defaultValue={null}
              rules={{ required: true }}
              control={control}
              render={(props) => (
                <Textinput
                  onChange={(e: any) => {
                    const value = e.target.value;
                    props.onChange(value);

                    setSummary({
                      ...summary,
                      quantity: Number(value),
                      totalValue: summary.pricePerUnit * Number(value),
                      totalCost: summary.costPerUnit * Number(value),
                    });
                  }}
                  checked={props.value}
                  value={props.value || ""}
                  label="Quantity"
                  inputid="quantity"
                  type="number"
                  placeholder=""
                  iserror={errors.quantity}
                  message={"Please fill this field"}
                />
              )}
            />
          </div>

          <div className="mb-3">
            <Controller
              name="cost"
              defaultValue={null}
              rules={{ required: true }}
              control={control}
              render={(props) => (
                <Textinput
                  onChange={(e: any) => {
                    const value = e.target.value;
                    props.onChange(value);

                    setSummary({
                      ...summary,
                      costPerUnit: Number(value),
                      totalCost: summary.quantity * Number(value),
                    });
                  }}
                  value={props.value || ""}
                  type="number"
                  label="Average cost per unit"
                  iserror={errors.cost}
                  message={"Please fill this field"}
                />
              )}
            />
          </div>
        </form>

        <div className="text-sm mt-4 max-w-sm">
          <div className="font-medium">Summary</div>

          <div className="flex flex-row justify-between items-center mt-4">
            <div>Current Price per unit</div>
            <div>{ConvertNumberCur(summary.pricePerUnit, "$", 2, 12)}</div>
          </div>

          <div className="flex flex-row justify-between items-start mt-4">
            <div>
              <div>Total Value</div>
              <div className="text-xs mt-1 text-gray-400">As of: {summary.time}</div>
            </div>

            <div>{ConvertNumberCur(summary.totalValue, "$", 2, 12)}</div>
          </div>

          <div className="flex flex-row justify-between items-center mt-4">
            <div>Cost per unit</div>
            <div>{ConvertNumberCur(summary.costPerUnit, "$", 2, 12)}</div>
          </div>

          <div className="flex flex-row justify-between items-center mt-4">
            <div>Total Cost</div>
            <div>{ConvertNumberCur(summary.totalCost, "$", 2, 12)}</div>
          </div>
        </div>

        <div className="asset-class-footer">
          <MainButton size="small" type="secondary" click={closeModal}>
            Cancel
          </MainButton>
          <MainButton size="small" type="primary" extraClasses="ml-4" click={onSubmit}>
            {holding ? "Update" : "Submit"}
          </MainButton>
        </div>
      </div>
    </Modal>
  );
};

export default AddStockCrypto;
