import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import { getNumberBetween } from "../../components/utilities/utilityFunctions";

const PortfolioSkeletons = ({ showIcon = false }: { showIcon?: boolean }) => {
  return (
    <div className="flex flex-col gap-y-3 sm:gap-y-6 w-full">
      {[...Array(6)].map((_, index) => (
        <div key={index} className="w-full h-[48px] sm:h-[70px] rounded-xs">
          <div
            className={classNames(
              "w-full h-full",
              "flex flex-row items-center justify-between",
              "border border-neutral-200 rounded-sm"
            )}
          >
            <div className="flex flex-row items-center">
              {showIcon && <Skeleton width={32} height={32} borderRadius={6} className="ml-3 sm:ml-4" />}
              <Skeleton
                width={getNumberBetween(100, 200)}
                height={15}
                borderRadius={4}
                className={classNames(showIcon ? "ml-2" : "ml-5")}
              />
            </div>
            <div
              className={classNames(
                "hidden sm:flex items-center justify-between",
                "w-[312px] h-full",
                "border-l border-neutral-200"
              )}
            >
              <Skeleton width={80} height={15} borderRadius={4} className="ml-6" />
              <Skeleton width={120} height={15} borderRadius={4} className="mr-8" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PortfolioSkeletons;
