import { print } from "graphql";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../hooks/useAPI";
import { X_FETCH_CLASSES } from "../../graphql/portfolio.gql-query";

interface Variables {
  id: number;
  currency: string;
}

export const useGetClassesQuery = (variables: Variables) => {
  const field = "classes";
  const Api = useApi({});

  // Query will only run if the variables are defined
  const enabled = variables.id !== undefined && variables.currency !== undefined;

  return useQuery(
    ["X_FETCH_CLASSES", variables],
    async () => {
      const payload = {
        operationName: "FetchClassesForPortfolio",
        variables,
        query: print(X_FETCH_CLASSES),
      };

      const response = await Api.post("", JSON.stringify(payload));
      const portfolio = response?.data?.data?.portfolio;

      if (field && portfolio) {
        return portfolio[field];
      }

      // todo: not a pure portfolio query?
      return portfolio || [];
    },
    {
      enabled, // Query will be enabled when variables are defined
      onError: (error) => {
        console.error("fail: useGetClassesQuery", error);
      },
      onSuccess: (data) => {
        // dispatch({ type: UPDATE_ACTIVE_PORTFOLIO, payload: data });
      },
      placeholderData: [],
      staleTime: 1000 * 60 * 60, // 1 hour
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    }
  );
};
