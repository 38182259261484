import React, { useEffect, useState, useCallback } from "react";
import classNames from "classnames";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthMethods } from "../../types/authTypes";
import { useUserQuery } from "../../api/user/user-queries";
import { settingsTabs } from "./settings.config";
import { SettingsMeta } from "./SettingsMeta";
import UserAvatar from "../../components/user/Avatar";
import { ReactComponent as ArrowRight } from "../../assets/svg/arrow-right.svg";
import { BackarrowIcon } from "../../components/icons/backarrow";
import { ConfirmationModal } from "../../components/modals/ConfirmationModal";
import { logoutaUser } from "../../store/actions/userdata";
import { useDispatch } from "react-redux";
import "./settings.scss";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";

export const SettingsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: user } = useUserQuery();
  const userAuthMethods = user?.auth_methods;
  const isGoogleAuth = userAuthMethods?.includes(AuthMethods.GOOGLE);
  const location = useLocation();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState<number | null>(null);
  const parseddata = queryString.parse(location.search);
  const filteredTabs = isGoogleAuth ? settingsTabs.filter((tab) => tab.id !== "ChangePassword") : settingsTabs;
  const [showConfirmLogoutModal, setShowConfirmLogoutModal] = useState(false);

  const getIndex = useCallback((tabName: string) => {
    const tabs = settingsTabs;
    return tabs.findIndex((tab) => tab.id === tabName);
  }, []);

  useEffect(() => {
    if (typeof parseddata.tab === "string") {
      setTabIndex(getIndex(parseddata.tab));
    } else {
      setTabIndex(null);
    }
  }, [userAuthMethods, parseddata.tab, getIndex]);

  const switchTab = (index: number | null) => {
    if (index === null) {
      setTabIndex(null);
      navigate("/settings");
      return;
    }

    setTabIndex(index);
    navigate(`/settings?tab=${settingsTabs[index]?.id}`);
  };

  const showTabView = parseddata.tab && tabIndex !== null;

  // Auth Actions
  const logOutUser = async () => {
    try {
      await dispatch(logoutaUser({}));
      navigate("/signin");
    } catch (error) {
      console.error("fail: logOutUser:", error);
    }
  };

  return (
    <>
      {showConfirmLogoutModal && (
        <ConfirmationModal
          modalIsOpen={showConfirmLogoutModal}
          closeModal={() => {
            setShowConfirmLogoutModal(false);
          }}
          submitAction={logOutUser}
          confirmationTitle="Logout"
          confirmationText={<span>Are you sure you want to logout of {t("orgName")}?</span>}
        />
      )}
      <div className="p-4 md:p-8 settingspage">
        <div style={{ maxWidth: "1440px", margin: "0px auto" }}>
          <SettingsMeta />

          {/* Header */}
          <div className="flex justify-between items-center mb-6 sm:mb-10">
            <h1 className="font-semibold text-xl md:text-2xl">
              <button
                className={classNames(
                  "font-semibold text-xl md:text-2xl",
                  showTabView ? "text-blue-700 hover:underline cursor-pointer" : "cursor-default"
                )}
                onClick={() => switchTab(null)}
              >
                Settings
              </button>
              {showTabView && ` > ${filteredTabs?.[tabIndex]?.label}`}
            </h1>
            {showTabView && (
              <button
                className="flex items-center gap-x-2 text-gray-700 font-medium text-sm"
                onClick={() => switchTab(null)}
              >
                <BackarrowIcon /> Back
              </button>
            )}
          </div>

          {/* User */}
          {!showTabView && (
            <div className="flex items-center justify-center md:justify-start gap-x-6 pb-8 mb-5 border-b">
              <UserAvatar avatarUrl={user?.image_url} size="lg" />
              <div className="">
                <h2 className="text-lg font-medium">
                  {user?.first_name ? `${user?.first_name} ${user?.last_name}` : <Skeleton width={100} />}
                </h2>
                <p className="text-sm text-gray-500 mb-1">{user?.email ? user?.email : <Skeleton width={150} />}</p>
                <button
                  className="rounded px-4 py-2 bg-blue-600 text-white font-medium text-sm mt-2"
                  onClick={() => switchTab(0)}
                >
                  Edit Profile
                </button>
              </div>
            </div>
          )}

          {/* Tabs */}
          {!showTabView && (
            <div className="flex flex-col gap-y-4 md:max-w-xs">
              {filteredTabs.map((tab, index) => (
                <button
                  key={tab.id}
                  className={classNames(
                    "flex items-center justify-between rounded-md px-4 py-2 text-md font-medium transition-colors ease-in-out duration-300",
                    {
                      "bg-blue-600 text-white": index === tabIndex,
                      "text-gray-700 hover:bg-gray-200": index !== tabIndex,
                    }
                  )}
                  onClick={() => switchTab(index)}
                >
                  {tab.label} <ArrowRight />
                </button>
              ))}

              {/* Logout */}
              <button
                className="flex items-center justify-between rounded-md px-4 py-2 text-md font-medium transition-colors ease-in-out duration-300 hover:bg-gray-200"
                onClick={() => setShowConfirmLogoutModal(true)}
              >
                Logout <ArrowRight />
              </button>
            </div>
          )}

          {/* Tab Views */}
          {showTabView && React.createElement(filteredTabs[tabIndex].component)}
        </div>
      </div>
    </>
  );
};
