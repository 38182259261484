import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const LoginMeta = () => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>{t("orgName")} Sign in</title>
      <meta name="description" content={`Log in to your ${t("orgName")} Dashboard`} />
      <meta property="og:title" content={`${t("orgName")} Sign in`} />
      <meta property="og:description" content={`Log in to your ${t("orgName")} Dashboard`} />
      <meta property="og:url" content="https://app.getcova.com/signin" />
    </Helmet>
  );
};

export default LoginMeta;
