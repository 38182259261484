import { gql } from "@apollo/client";

export const CREATE_STRIPE_INTENT = gql`
  mutation CreateStripeSetupIntent {
    createStripeSetupIntent {
      client_secret
      id
      payment_method_options
      payment_method_types
      status
      usage
    }
  }
`;

export const CONVERT_STRIPE_INTENT_TO_PAYMENT_METHOD = gql`
  mutation ConvertStripeSetupIntentToPaymentMethod($setupIntentId: String!) {
    convertStripeSetupIntentToPaymentMethod(setupIntentId: $setupIntentId) {
      card_brand
      card_country
      card_exp_month
      card_exp_year
      card_last4
      created_at
      id
      partner
      partner_ref
      type
    }
  }
`;

export const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription($planId: String!) {
    createSubscription(planId: $planId) {
      cancel_at
      created_at
      current_period_end
      current_period_start
      customer_id
      ended_at
      id
      partner
      partner_ref
      partner_status
      plan {
        active
        amount
        code
        currency
        id
        interval
        partner
        partner_ref
      }
      plan_id
      quantity
      started_at
      status
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($updateInfo: UpdateSubscriptionInput!) {
    updateSubscription(updateInfo: $updateInfo) {
      cancel_at
      created_at
      current_period_end
      current_period_start
      customer_id
      ended_at
      id
      partner
      partner_ref
      partner_status
      plan {
        active
        amount
        code
        currency
        id
        interval
        partner
        partner_ref
      }
      plan_id
      quantity
      started_at
      status
    }
  }
`;

export const REMOVE_PAYMENT_METHOD = gql`
  mutation RemovePaymentMethod($removePaymentMethodId: String!) {
    removePaymentMethod(id: $removePaymentMethodId)
  }
`;

export const GET_INVOICES = gql`
  query Invoices($portfolioId: Int!) {
    portfolio(id: $portfolioId) {
      invoices {
        amount_due
        amount_paid
        attempt_count
        attempted
        created_at
        currency
        description
        id
        invoice_pdf_url
        invoice_url
        next_payment_attempt
        paid
        partner
        partner_ref
        partner_status
        subscription_id
        user_id
      }
    }
  }
`;

export const CREATE_APPLE_SUBSCRIPTION = gql`
  mutation CreateAppleSubscriptionFromTransactionId($portfolioId: Int!, $transactionId: String!) {
    createSubscriptionFromAppleTransactionId(portfolioId: $portfolioId, transactionId: $transactionId) {
      id
      status
    }
  }
`;
