import { Capacitor } from "@capacitor/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetSubscriptionQuery } from "../../api/subscription/subscription-queries";
import { PaymentPartners } from "../../types/subscriptionTypes";
import { StripeManager } from "./Billing/Stripe/StripeManager";
import { Partners } from "../../types/accountTypes";
import { AppleManager } from "./Billing/Apple/AppleManager";
import FAQs from "../../components/modals/faq";
import BillingSkeletons from "../../components/billing/BillingSkeletons";
import BillingAlert from "./Billing/BillingAlert";

const Billing = () => {
  const isNative = Capacitor.isNativePlatform();
  const [showFaq, setShowFaq] = useState(false);
  const { id: activePortfolioId } = useSelector((state: any) => state.activePortfolio);

  const {
    data: subscription,
    isFetching: isFetchingSubscription,
    isFetched: isFetchedSubscription,
    refetch: refetchSubscription,
  } = useGetSubscriptionQuery({
    id: activePortfolioId,
  });

  useEffect(() => {
    if (activePortfolioId) {
      refetchSubscription();
    }
  }, [activePortfolioId, refetchSubscription]);

  // Display Logic
  const determinePaymentPartner = () => {
    if (subscription?.partner === Partners.CUSTOM) {
      return PaymentPartners.STRIPE;
    }

    if (subscription?.partner === PaymentPartners.STRIPE || subscription?.partner === PaymentPartners.APPLE) {
      return subscription.partner;
    }

    return isNative ? PaymentPartners.APPLE : PaymentPartners.STRIPE;
  };

  const paymentPartner = determinePaymentPartner();

  return (
    <div className="billingtab">
      {isFetchingSubscription || !isFetchedSubscription ? (
        <BillingSkeletons />
      ) : (
        <>
          {showFaq && <FAQs modalIsOpen={showFaq} closeModal={() => setShowFaq(false)} />}
          {isFetchedSubscription && <BillingAlert subscription={subscription} />}
          <div className="flex flex-col overflow-x-auto">
            {paymentPartner === PaymentPartners.APPLE && <AppleManager setShowFaq={setShowFaq} />}
            {paymentPartner === PaymentPartners.STRIPE && <StripeManager setShowFaq={setShowFaq} />}
          </div>
        </>
      )}
    </div>
  );
};

export default Billing;
