import { SET_DID_OPTIMISTIC_UPDATE, UPDATE_GENERAL_STORE } from "../actions/general-store.actions";

const initialState = {
  // Custom state not found on graphql "Query" root
  networthHistory: undefined,
  isAddAccountModalOpen: false,
  refreshActivePortfolioIndicator: 0, // React Components using useEffect can use this as a dependency to perform a refresh of stuff in useEffect

  // Data found on graphql "Query" root
  portfolios: [],

  // Manage Optimistic UI updates
  didOptimisticUpdate: {
    value: false,
    page: "",
  },
};

// To store custom stuff as well as things on graphql "Query" root that hasn't been givent their own reducer
// But also, to store custom stuff
export default function generalStoreReducer(state = initialState, action: any) {
  switch (action.type) {
    case UPDATE_GENERAL_STORE:
      return {
        ...state,
        ...action.payload,
      };
    case SET_DID_OPTIMISTIC_UPDATE:
      return {
        ...state,
        didOptimisticUpdate: action.payload,
      };
    default:
      return state;
  }
}
