import { useMutation, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import {
  CONNECTION_CALLBACK,
  CUSTOM_ACCOUNT,
  REMOVE_ACCOUNT,
  REMOVE_CONNECTION,
  CRYPTO_TICKER,
  STOCK_TICKER,
  CREATE_HOLDING,
  UPDATE_ACCOUNT,
  UPDATE_HOLDING,
} from "../graphql/accountConnect";
import { loaderData, setUtilityData } from "../store/actions/utility";
import {
  HandlePartnerCallbackData,
  AddCustomAccountData,
  CreateHoldingInput,
  UpdateAccountInput,
  UpdateHoldingInput,
} from "../types/accountTypes";
import CheckCir from "../assets/svg/successcheck.svg";
import { toast } from "react-toastify";

const useAccountConnect = (addStockCryptoType?: string) => {
  const dispatch = useDispatch();

  const [handlePartnerCallback] = useMutation(CONNECTION_CALLBACK, {
    errorPolicy: "all",
    onCompleted(data) {},
    onError(error: any) {},
  });

  const handleConnectCallback = async (variables: HandlePartnerCallbackData) => {
    const res = await handlePartnerCallback({
      variables,
    });
    return res;
  };

  const [addCustomAccount] = useMutation(CUSTOM_ACCOUNT, {
    errorPolicy: "all",
    onCompleted(data) {},
    onError(error: any) {},
  });

  const handleAddCustomAccount = async (variables: AddCustomAccountData) => {
    const res = await addCustomAccount({
      variables,
    });
    return res;
  };

  const [removeAccount] = useMutation(REMOVE_ACCOUNT, {
    errorPolicy: "all",
    onCompleted(data) {},
    onError(error: any) {},
  });

  const handleRemoveAccount = async (accountId: string) => {
    const res = await removeAccount({
      variables: {
        accountId,
      },
    });
    return res;
  };

  const [removeConnection] = useMutation(REMOVE_CONNECTION, {
    errorPolicy: "all",
    onCompleted(data) {},
    onError(error: any) {},
  });

  const handleRemoveConnection = async (connectionId: string) => {
    const res = await removeConnection({
      variables: {
        connectionId,
      },
    });
    return res;
  };

  const [cryptoTicker] = useLazyQuery(CRYPTO_TICKER, {
    errorPolicy: "all",
    onCompleted(data) {},
    onError(error: any) {},
  });

  const getCryptoTicker = async (ticker: string) => {
    const res = await cryptoTicker({
      variables: {
        ticker,
      },
    });
    return res;
  };

  const [stockTicker] = useLazyQuery(STOCK_TICKER, {
    errorPolicy: "all",
    onCompleted(data) {},
    onError(error: any) {},
  });

  const getStockTicker = async (ticker: string) => {
    const res = await stockTicker({
      variables: {
        ticker,
      },
    });
    return res;
  };

  const [createHolding] = useMutation(CREATE_HOLDING, {
    errorPolicy: "all",
    onCompleted(data) {},
    onError(error: any) {},
  });

  const handleCreateHolding = async (values: CreateHoldingInput) => {
    const res = await createHolding({
      variables: {
        createHoldingInput: values,
      },
    });
    return res;
  };

  const handleAddHolding = async (values: CreateHoldingInput) => {
    dispatch(setUtilityData(loaderData(true, "")));

    await handleCreateHolding(values)
      .then((res: any) => {
        if (!res?.errors) {
          toast.success(
            <div className="toast-div capitalize">
              <img src={CheckCir} alt="check circle" />
              {addStockCryptoType} added successfully
            </div>
          );
        } else {
          toast.error("Something went wrong, please try again");
          dispatch(setUtilityData(loaderData(false, "")));
        }
      })
      .catch(() => {
        dispatch(setUtilityData(loaderData(false, "")));
      });
  };

  const [updateHolding] = useMutation(UPDATE_HOLDING, {
    errorPolicy: "all",
    onCompleted(data) {},
    onError(error: any) {},
  });

  const handleUpdateHolding = async (values: UpdateHoldingInput) => {
    const res = await updateHolding({
      variables: {
        updateHoldingInput: values,
      },
    });
    return res;
  };

  const handleEditHolding = async (values: UpdateHoldingInput) => {
    dispatch(setUtilityData(loaderData(true, "")));

    await handleUpdateHolding(values)
      .then((res: any) => {
        if (!res?.errors) {
          toast.success(
            <div className="toast-div capitalize">
              <img src={CheckCir} alt="check circle" />
              {addStockCryptoType} updated successfully
            </div>
          );
        } else {
          toast.error("Something went wrong, please try again");
          dispatch(setUtilityData(loaderData(false, "")));
        }
      })
      .catch(() => {
        dispatch(setUtilityData(loaderData(false, "")));
      });
  };

  const [updateCustomAccount] = useMutation(UPDATE_ACCOUNT, {
    errorPolicy: "all",
    onCompleted(data) {},
    onError(error: any) {},
  });

  const handleUpdateCustomAccount = async (values: UpdateAccountInput) => {
    const res = await updateCustomAccount({
      variables: {
        updateAccountInput: values,
      },
    });
    return res;
  };

  return {
    handleConnectCallback,
    handleAddCustomAccount,
    handleRemoveAccount,
    handleRemoveConnection,
    getCryptoTicker,
    getStockTicker,
    handleAddHolding,
    handleUpdateCustomAccount,
    handleEditHolding,
  };
};

export default useAccountConnect;
