import { forMatterNumber } from "../../services/utilty";

interface Props {
  growthRate: number | null;
  hidePercentage?: boolean;
}

export default function GrowthRate({ growthRate, hidePercentage }: Props) {
  return (
    <>
      {growthRate !== null ? (
        <div
          className={`${
            growthRate === 0.0 || growthRate === +0.0 || growthRate === -0.0
              ? "text-black"
              : growthRate >= 0
              ? "text-status-good"
              : "text-status-danger"
          } percentage my-auto mx-0 rounded py-0.5 px-1`}
        >
          {growthRate === 0.0 || growthRate === +0.0 || growthRate === -0.0 ? (
            <span>{growthRate}%</span>
          ) : (
            <span>
              {growthRate < 0 && "-"}
              {forMatterNumber(Math.abs(growthRate))}
              {hidePercentage ? "" : "%"}
            </span>
          )}
        </div>
      ) : (
        <div className="py-0.5 px-1 ml-2">&nbsp;</div>
      )}
    </>
  );
}
