import { useState } from "react";
import "../../auth/auth.scss";
import { Authheader } from "./../../auth/authheader";
import { ReactComponent as AlertIcon } from "../../../assets/svg/alert-circle.svg";
import PinInput from "react-pin-input";
import { useNavigate, useLocation } from "react-router-dom";
import { loaderData, setUtilityData } from "../../../store/actions/utility";
import { Errornotf } from "../../../components/notifications/errornotf";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import MainButton from "../../../components/buttons/mainButton";
import { toast } from "react-toastify";
import CheckCir from "../../../assets/svg/successcheck.svg";
import { useMutation } from "@apollo/client";
import queryString from "query-string";
import { VERIFY_PORTFOLIO_OTP } from "../../../graphql/portfolio";
import { LockClosedIcon } from "@heroicons/react/24/outline";

export const VerifyPortfolioOtp = () => {
  const [pin, setPin] = useState("");
  const [pinError, setPinError] = useState("");
  const [errorMessage, seterrorMessage] = useState<string | null>(null);
  const location: any = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let parseddata: any = queryString.parse(location.search);
  const portfolioId: string = parseddata.id;
  const [verifyPortfolioOtp] = useMutation(VERIFY_PORTFOLIO_OTP, {
    onCompleted() {
      toast.success(
        <div className="toast-div">
          <img src={CheckCir} alt="check circle" />
          Portfolio invitation accepted
        </div>
      );
      navigate("/collaboration");
      dispatch(setUtilityData(loaderData(false, "")));
    },
    onError(error: any) {
      toast.error(error.message);
      dispatch(setUtilityData(loaderData(false, "")));
    },
  });

  let updatePin = (value: any) => {
    setPin(value);
  };

  let submit = () => {
    seterrorMessage(null);
    setPinError("");

    if (pin && pin.length !== 6) {
      setPinError("Enter a valid pin");
      return;
    }

    dispatch(setUtilityData(loaderData(true, "")));
    verifyPortfolioOtp({
      variables: {
        portfolioId: +portfolioId,
        token: pin,
      },
    });
  };

  return (
    <div className="authpage">
      <Helmet>
        <title>Verify with One Time Password</title>
        <meta name="description" content="Check your email for a One Time Password to access your account" />
        <meta property="og:title" content="Verify with One Time Password" />
        <meta property="og:description" content="Check your email for a One Time Password to access your account" />
        <meta property="og:url" content="https://app.getcova.com/otp-verification" />
      </Helmet>
      <div className="header-wrapper">
        <Authheader />
      </div>
      <div className="forgotpassword">
        <div className="inner portfolio mb-8">
          <p className="text-h4 font-medium" style={{ color: "#1E2329" }}>
            Enter the one-time passcode to access this portfolio
          </p>

          <p className="text-p2 text-gray-500 mt-4 mb-6">
            An OTP is required to access this portfolio. Request for the code privately from the portfolio owner then
            enter it here to verify your access to the portfolio.
          </p>

          <Errornotf style={{ marginTop: "40px", marginBottom: "25px" }} message={errorMessage} />
          <div style={{ marginBottom: "70px" }} className="mb-10 max-w-md mx-auto">
            <PinInput
              type="custom"
              inputMode="number"
              initialValue=""
              secret
              focus
              length={6}
              onComplete={(value, index) => {
                updatePin(value);
              }}
              onChange={(value, index) => {
                updatePin(value);
              }}
            />
            {pinError ? <small className="text-status-danger mt-1">{pinError}</small> : null}
          </div>
          <div className="mb-6  max-w-md mx-auto">
            <MainButton click={submit} size="big" type="primary" extraClasses="w-full">
              <div className="text-p1 flex justify-center">
                <span className="my-auto mr-1">
                  <LockClosedIcon className="w-4 h-4 text-white" />
                </span>
                Open Portfolio
              </div>
            </MainButton>
          </div>

          <div className="p-3 rounded-md notification-message mt-12 w-full mx-auto history text-xs">
            <AlertIcon className="mr-2 my-auto inline" />
            This OTP will expire after 24 hours. Request for another if you are unable to use it now
          </div>
        </div>
      </div>
    </div>
  );
};
