import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export const SettingsMeta = () => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>Settings</title>
      <meta name="description" content={`${t("orgName")} helps you track all your assets in one place`} />
      <meta property="og:title" content="Settings" />
      <meta property="og:description" content={`${t("orgName")} helps you track all your assets in one place`} />
      <meta property="og:url" content="https://app.getcova.com/settings" />
      <meta name="twitter:card" content={`${t("orgName")} helps you track all your assets in one place`} />
    </Helmet>
  );
};
