import { Profileicon2Icon } from "../../components/icons/profileicon2";
import { ContactType } from "../../types/lifecheckTypes";

interface IIndividualContact {
  contact: ContactType;
  onClickEdit: () => void;
  onClickDelete: () => void;
}

export const ContactRowMobile = ({ contact, onClickEdit, onClickDelete }: IIndividualContact) => {
  return (
    <div className="w-full flex flex-col md:flex-row md:hidden my-4">
      <div className="w-full flex">
        <div className="mr-4">
          <Profileicon2Icon />
        </div>
        <div>
          <p className="font-semibold text-type text-p2">{contact.first_name + " " + contact?.last_name}</p>
          <p className="text-type-100 text-p2">{contact.email}</p>
          <p className="text-type-100 text-p2">{"+" + contact.phone_number_country_code + "" + contact.phone_number}</p>
        </div>
      </div>
      <div className="flex items-center justify-end mt-2">
        <button onClick={onClickEdit} className="p-semibold cursor-pointer text-primary mr-2 px-3 pr-2 pl-0 sm:pl-2">
          Edit
        </button>
        <button onClick={onClickDelete} className="p-semibold cursor-pointer text-status-danger px-3 py-2">
          Remove
        </button>
      </div>
    </div>
  );
};
