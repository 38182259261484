export const ContactsEmpty = ({ onClickAddContact }: { onClickAddContact: () => void }) => (
  <div className="flex flex-col items-center p-6 md:p-12">
    <p className="text-lg font-semibold mb-3">You don't have any contacts.</p>
    <div className="text-blue-500 underline cursor-pointer" onClick={onClickAddContact}>
      + Add Contact
    </div>
  </div>
);

export default ContactsEmpty;
