import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAddAccountModalToggle, useNetworthHistoryLazyQuery } from "../../graphql/general-store.gql-hook";
import { useClassesLazyQuery, useInstitutionsLazyQuery, useLabelsLazyQuery } from "../../graphql/portfolio.gql-hook";
import { setUtilityData } from "../../store/actions/utility";
import { AccountType } from "../../types/accountTypes";
import { UserState } from "../../types/authTypes";
import { InstitutionType } from "../../types/categoryTypes";
import DashboardNetworthCard from "./DashboardNetWorthCard";
import DashboardNetworthHistoryCard from "./DashboardNetworthHistoryCard";
import DashboardPortfolioDistributionCard from "./DashboardPortfolioDistributionCard";
import DashboardTopAccountsCard from "./DashboardTopAccountsCard";
import DashboardTopPerforming from "./DashboardTopPerformingCard";
import DashboardMeta from "./DashboardMeta";
import DashboardTopbar from "./DashboardTopbar";
import DashboardOnboardingSteps from "./DashboardOnboardingSteps";
import DASHBOARD_DATERANGE_OPTIONS from "./DashboardDateRangeOptions";
import "./dashboard.scss";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [selectedDateRange, setSelectedDateRange] = useState<any>(DASHBOARD_DATERANGE_OPTIONS[0]);
  const showOnboardingSteps = useSelector((state: any) => state.utility.showOnboardingSteps);

  const { fetcher: fetchNetworthHistory } = useNetworthHistoryLazyQuery();
  const { fetcher: fetchInstitutions } = useInstitutionsLazyQuery();
  const { fetcher: fetchClasses } = useClassesLazyQuery();
  const { fetcher: fetchLabels } = useLabelsLazyQuery();

  const {
    id: portfolioId,
    balances,
    institutions,
    classes,
    labels,
  } = useSelector((state: any) => state.activePortfolio);
  const { networthHistory } = useSelector((state: any) => state.generalStore);
  const accounts = (institutions || []).reduce((accounts: AccountType[], institution: InstitutionType) => {
    return [...accounts, ...institution.accounts];
  }, []);

  const { code: currencyCode } = useSelector((state: any) => state.assets.currency);
  const { currencyDropdown } = useSelector((state: any) => state.utility);

  const usercurrencies = useSelector((state: { user: UserState }) => state.user.currentUser.currencies);
  const to = selectedDateRange.to ? new Date(selectedDateRange.to) : new Date();
  const from = selectedDateRange.from
    ? new Date(selectedDateRange.from)
    : new Date(new Date(to).setDate(to.getDate() - selectedDateRange.days));

  useEffect(() => {
    if (!portfolioId) return;

    fetchInstitutions({ variables: { id: portfolioId, currency: currencyCode } });
    fetchClasses({ variables: { id: portfolioId, currency: currencyCode } });
    fetchLabels({ variables: { id: portfolioId, currency: currencyCode } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioId, currencyCode]);

  useEffect(() => {
    if (!portfolioId) return;

    fetchNetworthHistory({
      variables: {
        from: from.toISOString(),
        to: to.toISOString(),
        interval: selectedDateRange.interval,
        id: `${portfolioId}`,
        currency: currencyCode,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioId, currencyCode, selectedDateRange.text]); //TODO: Include drop down changes as part of options here

  const openCurrencyPicker = () => {
    dispatch(setUtilityData({ currencyDropdown: !currencyDropdown }));
  };

  const { 1: openAccountModal } = useAddAccountModalToggle();

  return (
    <div className="p-4 md:p-8 dashboard">
      <div className="max-w-screen-2xl mx-auto">
        <DashboardMeta />

        {showOnboardingSteps && <DashboardOnboardingSteps />}

        <div className="flex flex-col lg:flex-row justify-between">
          <div className="w-full lg:flex-grow lg:flex-shrink overflow-hidden">
            <div className="mb-6">
              <DashboardTopbar
                selectedDateRange={selectedDateRange}
                selectOptions={DASHBOARD_DATERANGE_OPTIONS}
                setSelectedDateRange={setSelectedDateRange}
              />
            </div>
            <div className="w-full flex flex-col lg:flex-row lg:mb-4">
              <div className="w-full lg:w-1/2 flex-grow">
                <div className="mb-4 lg:mb-0 h-full">
                  <DashboardNetworthCard
                    balances={balances}
                    currency={currencyCode}
                    openCurrencyPicker={openCurrencyPicker}
                    isLoading={!balances}
                    openAddAccountModal={() => openAccountModal(true)}
                  />
                </div>
              </div>
              <div className="w-full lg:w-1/2 lg:ml-0 lg:mt-4 lg:ml-4 lg:mt-0 flex-grow mb-4 lg:mb-0">
                <DashboardTopPerforming
                  isLoading={!institutions}
                  accounts={accounts}
                  openAddAccountModal={() => openAccountModal(true)}
                />
              </div>
            </div>
            <div className="mb-4 pb-10">
              <DashboardNetworthHistoryCard
                config={selectedDateRange}
                isLoading={!networthHistory}
                history={networthHistory}
                currencies={usercurrencies}
                openAddAccountModal={() => openAccountModal(true)}
              />
            </div>
            <DashboardPortfolioDistributionCard
              classes={classes}
              institutions={institutions}
              labels={labels}
              isLoading={!institutions || !classes || !labels}
              openAddAccountModal={() => openAccountModal(true)}
            />
          </div>
          <div className="w-full lg:w-1/4 mt-4 lg:mt-0 lg:ml-6 min-w-80">
            <DashboardTopAccountsCard
              isLoading={!institutions}
              accounts={accounts}
              openAddAccountModal={() => openAccountModal(true)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
