import React from "react";
import { SuccessCheckIcon } from "../icons/successcheckcircle";

export const SuccessToast = ({ closeToast, toastProps, message }: any) => {
  return (
    <div className="successnot">
      <span className="successnot-inner-icon">
        <SuccessCheckIcon />
      </span>
      <p className="successnot-inner-mess p-normal">{message}</p>
    </div>
  );
};
