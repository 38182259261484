import { Helmet } from "react-helmet";

const DashboardMeta = () => {
  return (
    <Helmet>
      <title>Dashboard | Track Your Assets, Add Beneficiaries</title>
      <meta
        name="description"
        content="Track your bank and investments, crypto, stocks, real estate and other assets, add beneficaries, create a vault for important documents"
      />
      <meta property="og:title" content="Home | Track Your Assets, Add Beneficiaries" />
      <meta
        property="og:description"
        content="Track your bank and investments, crypto, stocks, real estate and other assets, add beneficaries, create a vault for important documents"
      />
      <meta property="og:url" content="https://app.getcova.com/dashboard" />
    </Helmet>
  );
};

export default DashboardMeta;
