import { gql } from "@apollo/client";

export const CONNECTION_CALLBACK = gql`
  mutation handlePartnerCallback($partner: String!, $data: String!, $portfolioId: Float!) {
    handlePartnerCallback(partner: $partner, data: $data, portfolioId: $portfolioId) {
      id
    }
  }
`;

export const CUSTOM_ACCOUNT = gql`
  mutation AddCustomAccount($account: CreateAccountInput!, $partner: String!, $portfolioId: Float!) {
    addCustomAccount(account: $account, partner: $partner, portfolioId: $portfolioId) {
      id
    }
  }
`;

export const REMOVE_ACCOUNT = gql`
  mutation RemoveAccount($accountId: String!) {
    removeAccount(accountId: $accountId)
  }
`;

export const REMOVE_CONNECTION = gql`
  mutation RemoveConnection($connectionId: String!) {
    removeConnection(connectionId: $connectionId)
  }
`;

export const CREATE_HOLDING = gql`
  mutation CreateHolding($createHoldingInput: CreateHoldingInput!) {
    createHolding(createHoldingInput: $createHoldingInput) {
      account_id
    }
  }
`;

export const CRYPTO_TICKER = gql`
  query Cryptocurrencies($ticker: String) {
    cryptocurrencies(ticker: $ticker) {
      currency
      last_price_checked_at
      name
      price
      symbol
    }
  }
`;

export const STOCK_TICKER = gql`
  query Stocks($ticker: String) {
    stocks(ticker: $ticker) {
      currency
      last_price_checked_at
      name
      price
      symbol
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($updateAccountInput: UpdateAccountInput!) {
    updateAccount(updateAccountInput: $updateAccountInput) {
      id
    }
  }
`;

export const UPDATE_HOLDING = gql`
  mutation UpdateHolding($updateHoldingInput: UpdateHoldingInput!) {
    updateHolding(updateHoldingInput: $updateHoldingInput)
  }
`;
