import React from "react";

export const Profileicon2Icon = ({ width, height }: any) => {
  return (
    <svg
      width={width || "48"}
      height={height || "51"}
      viewBox="0 0 48 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23.9994" cy="23.5385" r="23.5385" fill="#D6E7FF" />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="12" width="39" height="39">
        <path
          d="M37.2082 46.1941V42.9902C37.2082 41.2908 36.5331 39.661 35.3314 38.4593C34.1297 37.2576 32.4999 36.5825 30.8005 36.5825H17.9851C16.2857 36.5825 14.6558 37.2576 13.4542 38.4593C12.2525 39.661 11.5774 41.2908 11.5774 42.9902V46.1941"
          stroke="#0056CD"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.3923 28.6054C27.9312 28.6054 30.8 25.7366 30.8 22.1977C30.8 18.6589 27.9312 15.79 24.3923 15.79C20.8534 15.79 17.9846 18.6589 17.9846 22.1977C17.9846 25.7366 20.8534 28.6054 24.3923 28.6054Z"
          stroke="#0056CD"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </mask>
      <g mask="url(#mask0)">
        <circle cx="23.9987" cy="23.5385" r="23.5385" fill="#0056CD" />
      </g>
    </svg>
  );
};
