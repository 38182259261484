import { ReactElement } from "react";

export interface INotification {
  icon: ReactElement;
  content: string;
  actionText: string;
  status: "normal" | "warning";
  action: () => void;
}

interface IStickyNotification {
  notification: INotification;
}

export const StickyNotification = ({ notification }: IStickyNotification) => {
  const { icon, content, actionText, action, status } = notification;
  return (
    <div className={`sticky-notification ${status === "warning" ? "warning" : ""}`}>
      {icon} <span className="content">{content}</span>{" "}
      <span className="action cursor-pointer" onClick={action}>
        {actionText}
      </span>
    </div>
  );
};
