import classNames from "classnames";
import { SubscriptionStatus } from "../../types/subscriptionTypes";
import { VaultFolderType } from "../../types/vaultTypes";
import { ReactComponent as VaultFolderIcon } from "../../assets/svg/vault-folder.svg";
import { VaultDocumentDropdown } from "../../components/inputs/vaultdropdowns";
import { convertStampDatev2, subStringofName } from "../../components/utilities/utilityFunctions";
import { useVault } from "./VaultProvider";
import { useSelector } from "react-redux";
import { PortfolioState } from "../../types/portfolioTypes";

const VaultFolderItem = ({
  folder,
  index,
  openManageFileFolderModal,
  setConfirmDelete,
}: {
  folder: VaultFolderType;
  index: number;
  openManageFileFolderModal: (data: { type: "folder" | "file" | "note"; item?: any }) => void;
  setConfirmDelete: (confirmDelete: any) => void;
}) => {
  const subscription = useSelector(
    (state: { portfolios: { portfolio: PortfolioState } }) => state.portfolios.portfolio.subscription
  );
  const { setCurrentFolder } = useVault();
  const folderName = subStringofName(folder?.name, 37);
  const folderTimestamp = convertStampDatev2(folder.updated_at ? folder.updated_at : folder.created_at);

  return (
    <div
      key={`folder-${index}`}
      className={classNames(
        "w-full md:w-48",
        "p-5 rounded-md",
        "border border-1 border-solid border-gray-200 hover:border-blue-400",
        "flex flex-col",
        "gap-y-4",
        !subscription ||
          (subscription && subscription.status !== SubscriptionStatus.ACTIVE && "opacity-50 cursor-not-allowed")
      )}
    >
      <div className="flex justify-between">
        {/* Icon */}
        <div
          className={classNames(
            !subscription || (subscription && subscription.status !== SubscriptionStatus.ACTIVE)
              ? "cursor-not-allowed"
              : "cursor-pointer"
          )}
          onClick={() => {
            if (subscription && subscription.status === SubscriptionStatus.ACTIVE) {
              setCurrentFolder(folder);
            }
          }}
        >
          <VaultFolderIcon />
        </div>

        {/* Dropdown */}
        <VaultDocumentDropdown
          openRenameModal={() => {
            openManageFileFolderModal({
              type: "folder",
              item: folder,
            });
          }}
          openDeleteModal={() => {
            setConfirmDelete({
              type: "folder",
              item: folder,
            });
          }}
          folderId={folder?.id}
          name={folderName}
          type="folder"
          disabled={!subscription || (subscription && subscription.status !== SubscriptionStatus.ACTIVE)}
        />
      </div>

      {/* Info */}
      <div>
        <p className="font-semibold font-medium text-xs leading-normal text-gray-700 mb-3 break-all">{folderName}</p>
        <p className="font-normal font-medium text-xs text-gray-600 leading-tight">{folderTimestamp}</p>
      </div>
    </div>
  );
};

export default VaultFolderItem;
