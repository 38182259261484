import { put, fork, all, takeLatest } from "redux-saga/effects";
import { RESET_ASSETDATA } from "../../actions/assetsaction/types";
import { RESET_LIFECHECK_DATA } from "../../actions/lifecheckaction/types";
import { LOGOUT_USER, RESET_USER_DATA } from "../../actions/userdata/types";
import { RESET_UTILITY } from "../../actions/utility/types";
import { RESET_PORTFOLIO_DATA } from "../../actions/portfoliosaction/types";
import { RESET_USER_STATE } from "../../reducers/userdatav2/types";

export function* logoutUser({ payload }: any) {
  localStorage.removeItem("token");
  localStorage.removeItem("x2cvd");
  yield all([
    put({ type: RESET_ASSETDATA }),
    put({ type: RESET_USER_DATA }),
    put({ type: RESET_UTILITY }),
    put({ type: RESET_LIFECHECK_DATA }),
    put({ type: RESET_PORTFOLIO_DATA }),
    put({ type: RESET_USER_STATE }),
  ]);
}

export function* logoutSaga() {
  yield takeLatest(LOGOUT_USER, logoutUser);
}

const AuthSagas = [
  fork(logoutSaga),
  // fork(
  //     signinSaga,
  // )
];

export default AuthSagas;
