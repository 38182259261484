import classNames from "classnames";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ToggleSwitch } from "../../components/inputs/toggleswitch";
import { useUpdateLifecheckMutation } from "../../api/lifecheck/lifecheck-mutations";
import { useGetLifecheckQuery } from "../../api/lifecheck/lifecheck-queries";
import { useGetContactsQuery } from "../../api/contact/contact-queries";
import History from "./History";
import SetUp from "./SetupTab";
import LifecheckMeta from "./LifecheckMeta";
import "./lifecheck.scss";

type ViewsType = "Set Up" | "History";

const LifeCheck = () => {
  const { id: portfolioId } = useSelector((state: any) => state.activePortfolio);
  const {
    data: lifecheck,
    isFetching: isFetchingLifecheck,
    isFetched: hasFetchedLifecheck,
  } = useGetLifecheckQuery({ id: portfolioId });
  const { data: contacts } = useGetContactsQuery({ id: portfolioId });
  const { mutateAsync: updateLifecheck, isLoading: isUpdatingLifecheck } = useUpdateLifecheckMutation();

  const [pageView, setPageView] = useState<ViewsType>("Set Up");
  const collaborationStates: ViewsType[] = ["Set Up", "History"];

  const toggleLifecheck = async () => {
    if (isFetchingLifecheck || isUpdatingLifecheck) return;

    try {
      await updateLifecheck({
        id: portfolioId,
        lifecheck_enabled: !lifecheck.enabled,
      });
    } catch (error) {
      console.error("fail: toggleLifecheck", error);
    }
  };

  return (
    <div className="p-4 md:p-8 flex justify-center">
      <div className="w-full max-w-[1440px]">
        <LifecheckMeta />

        {/* Topbar */}
        <div className="flex justify-between items-center mb-6">
          <h2 className="font-semibold text-xl md:text-2xl">Life Check</h2>

          {hasFetchedLifecheck && (
            <div className="flex">
              <ToggleSwitch
                isActive={lifecheck?.enabled}
                toggleAction={toggleLifecheck}
                disabled={isFetchingLifecheck || isUpdatingLifecheck}
              />
              <p className="ml-2">{lifecheck?.enabled ? "Active" : "Inactive"}</p>
            </div>
          )}
        </div>

        {/* Tabs */}
        <div className="flex flex-row gap-x-4 mb-2">
          {collaborationStates.map((state: ViewsType, i: number) => (
            <button
              key={i}
              className={classNames(
                "bg-neutral-200",
                "px-4 py-2 rounded-2xl",
                "text-sm font-medium",
                pageView === state && "bg-blue-100 text-blue-700"
              )}
              onClick={() => setPageView(state)}
            >
              {state}
            </button>
          ))}
        </div>

        {pageView === "Set Up" && (
          <SetUp contacts={contacts} lifecheck={lifecheck} isLoading={isFetchingLifecheck || !hasFetchedLifecheck} />
        )}

        {pageView === "History" && <History />}
      </div>
    </div>
  );
};

export default LifeCheck;
