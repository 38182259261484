import { ReactComponent as CollaborationIcon } from "../../assets/svg/navigation/collaboration.svg";
import { ReactComponent as VaultIcon } from "../../assets/svg/navigation/vault.svg";
import { AssetsIcon } from "../icons/assetsicon";
import {
  ChartPieIcon,
  CheckBadgeIcon,
  Cog6ToothIcon,
  UsersIcon,
  TagIcon,
  BuildingLibraryIcon,
} from "@heroicons/react/24/outline";

export interface SidebarCategoriesType {
  title: string;
  routes: RouteItem[];
  onlyOwner: boolean;
}

interface RouteItem {
  name: string;
  targetClassname: string;
  translationKey: string;
  route: string;
  icon: React.ComponentType<any>;
  onlyOwner: boolean;
  migrated?: boolean;
}

export const SidebarCategories: SidebarCategoriesType[] = [
  {
    title: "OVERVIEW",
    onlyOwner: true,
    routes: [
      {
        name: "Report",
        translationKey: "overview.report",
        icon: ChartPieIcon,
        route: "/report",
        targetClassname: "",
        onlyOwner: false,
      },
    ],
  },
  {
    title: "PORTFOLIO",
    onlyOwner: false,
    routes: [
      {
        name: "Institution",
        translationKey: "portfolio.institution",
        icon: BuildingLibraryIcon,
        route: "/portfolio/institutions",
        targetClassname: "institutions-t",
        onlyOwner: false,
      },
      {
        name: "Class",
        translationKey: "portfolio.class",
        icon: AssetsIcon,
        route: "/portfolio/classes",
        targetClassname: "classes-t",
        onlyOwner: false,
      },
      {
        name: "Label",
        translationKey: "portfolio.label",
        icon: TagIcon,
        route: "/portfolio/labels",
        targetClassname: "labels-t",
        onlyOwner: false,
      },
    ],
  },
  {
    title: "MANAGE",
    onlyOwner: true,
    routes: [
      {
        name: "Collaboration",
        translationKey: "manage.collaboration",
        icon: CollaborationIcon,
        route: "/collaboration",
        targetClassname: "",
        onlyOwner: true,
      },
      {
        name: "Lifecheck",
        translationKey: "manage.lifecheck",
        icon: CheckBadgeIcon,
        route: "/life-check",
        targetClassname: "life-check-t",
        onlyOwner: true,
      },
      {
        name: "Contacts",
        translationKey: "manage.contacts",
        icon: UsersIcon,
        route: "/contacts",
        targetClassname: "",
        onlyOwner: true,
      },
      {
        name: "Vault",
        translationKey: "manage.vault",
        icon: VaultIcon,
        route: "/vault",
        targetClassname: "vault-t",
        onlyOwner: true,
        migrated: true,
      },
      {
        name: "Settings",
        translationKey: "manage.settings",
        icon: Cog6ToothIcon,
        route: "/settings",
        targetClassname: "",
        onlyOwner: true,
        migrated: true,
      },
    ],
  },
];
