import * as Sentry from "@sentry/browser";
import { useLazyQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_GENERAL_STORE } from "../store/actions/general-store.actions";
import { loaderData, setUtilityData } from "../store/actions/utility";
import { HistoryTypes } from "../types/accountTypes";
import { FETCH_HISTORY, FETCH_PORTFOLIOS } from "./general-store.gql-query";

export const useGeneralStoreGql = () => {
  const dispatch = useDispatch();

  const updateGeneralCompletedCallback = (data: any) => {
    dispatch({
      type: UPDATE_GENERAL_STORE,
      payload: data,
    });

    dispatch(setUtilityData(loaderData(false, "")));
  };

  const updateGeneralErrorCallback = (error: any, method: string) => {
    console.error(`fail: ${method}`, error);
    Sentry.captureException(error);
    dispatch(setUtilityData(loaderData(false, "")));
  };

  const [fetchPortfolios] = useLazyQuery(FETCH_PORTFOLIOS, {
    errorPolicy: "all",
    onCompleted: updateGeneralCompletedCallback,
    onError: (error) => updateGeneralErrorCallback(error, "fetchPortfolios"),
  });

  const [fetchHistory] = useLazyQuery(FETCH_HISTORY, {
    errorPolicy: "all",
    onCompleted: updateGeneralCompletedCallback,
    onError: (error) => updateGeneralErrorCallback(error, "fetchHistory"),
  });

  return {
    fetchPortfolios,
    fetchHistory,
  };
};

export const useNetworthHistoryLazyQuery = (variables: object = {}) => {
  const dispatch = useDispatch();

  const [fetchHistory, { loading, data }] = useLazyQuery(FETCH_HISTORY, {
    errorPolicy: "all",
    onCompleted: (data: any) => {
      dispatch({
        type: UPDATE_GENERAL_STORE,
        payload: { networthHistory: data.history },
      });
    },
    onError: (error) => {
      console.error("fail: useNetworthHistoryLazyQuery", error);
      Sentry.captureException(error);
    },
    variables: { for: HistoryTypes.PORTFOLIO, ...variables } as any,
  });

  return {
    fetcher: fetchHistory,
    loading: loading || data === undefined,
    data: data?.history,
  };
};

export const useAddAccountModalToggle = () => {
  const dispatch = useDispatch();
  const { isAddAccountModalOpen } = useSelector((state: any) => state.generalStore);

  return [
    isAddAccountModalOpen,
    (status: boolean) => {
      return dispatch({ type: UPDATE_GENERAL_STORE, payload: { isAddAccountModalOpen: status } });
    },
  ];
};

export const useRefreshActivePortfolio = () => {
  const dispatch = useDispatch();
  const { refreshActivePortfolioIndicator } = useSelector((state: any) => state.generalStore);

  return [
    refreshActivePortfolioIndicator,
    (status: boolean) => {
      return dispatch({
        type: UPDATE_GENERAL_STORE,
        payload: { refreshActivePortfolioIndicator: new Date().getTime() },
      });
    },
  ];
};
