import { useSelector } from "react-redux";
import { VaultNoteModal } from "./VaultNoteModal";
import { VaultManageFileFolderModal } from "./VaultManageFileFolderModal";
import { VaultMoveDocumentModal } from "./VaultMoveDocumentModal";
import { ConfirmationModal } from "../../components/modals/ConfirmationModal";
import { PortfolioState } from "../../types/portfolioTypes";
import { SubscriptionStatus } from "../../types/subscriptionTypes";
import { VaultDocumentType, VaultFolderType, VaultNoteType } from "../../types/vaultTypes";
import VaultFolderItem from "./VaultFolderItem";
import VaultNoteItem from "./VaultNoteItem";
import VaultDocumentItem from "./VaultDocumentItem";
import VaultListMaxed from "./VaultListMaxed";
import { useRemoveVaultItemMutation } from "../../api/vault/vault-mutations";
import "./vault.scss";
import { useVault } from "./VaultProvider";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const VaultList = ({ folders }: { folders: VaultFolderType[] }) => {
  const { t } = useTranslation();
  const subscription = useSelector(
    (state: { portfolios: { portfolio: PortfolioState } }) => state.portfolios.portfolio.subscription
  );

  const [hasExceededLimit, setHasExceededLimit] = useState(false);

  useEffect(() => {
    if (!subscription || (subscription && subscription.status !== SubscriptionStatus.ACTIVE)) {
      setTimeout(() => {
        setHasExceededLimit(true);
      }, 1000);
    }
  }, [subscription]);

  const {
    currentNote,
    currentManagedItem,
    currentMovingItem,
    currentDeletingItem,
    setCurrentNote,
    setCurrentManagedItem,
    setCurrentMovingItem,
    setCurrentDeletingItem,
  } = useVault();
  const { mutateAsync: removeVaultDocument } = useRemoveVaultItemMutation();

  const openManageFileFolderModal = ({ type, item }: { type: "folder" | "file" | "note"; item?: any }) => {
    setCurrentManagedItem({
      type,
      item,
    });
  };

  const openMoveDocumentModal = (type: "file" | "note", documentId: string) => {
    setCurrentMovingItem({
      type,
      documentId,
    });
  };

  const handleRemoveVaultItem = async () => {
    try {
      if (!currentDeletingItem?.item?.id || !currentDeletingItem?.type) return;

      await removeVaultDocument({
        id: currentDeletingItem?.item?.id,
        type: currentDeletingItem?.type,
      });

      setCurrentDeletingItem(null);
    } catch (error) {
      console.error("fail: handleRemoveVaultItem", error);
    }
  };

  return (
    <>
      {/* Modals */}
      <VaultNoteModal
        modalIsOpen={!!currentNote?.id}
        details={currentNote}
        folderId={folders[0].id}
        closeModal={() => setCurrentNote(null)}
      />

      <VaultManageFileFolderModal
        modalIsOpen={!!currentManagedItem}
        vaultItem={currentManagedItem}
        folders={folders}
        onCloseModal={() => setCurrentManagedItem(null)}
        inputLabel={`${currentManagedItem?.type} name`}
        placeholder={`Enter ${currentManagedItem?.type} name`}
        header={`Rename ${currentManagedItem?.type}`}
        value={currentManagedItem?.item?.name}
      />

      <ConfirmationModal
        modalIsOpen={!!currentDeletingItem}
        closeModal={() => setCurrentDeletingItem(null)}
        verificationText={currentDeletingItem?.type === "folder" && "DELETE"}
        submitAction={handleRemoveVaultItem}
        submitText="Yes, I’m Sure"
        confirmationText={`This will permanently delete this ${
          currentDeletingItem?.type === "folder" ? "folder and all the files in it" : currentDeletingItem?.type
        }. They will no longer be retrievable on ${t("orgName")}.`}
      />

      <VaultMoveDocumentModal
        folders={folders}
        modalIsOpen={!!currentMovingItem}
        closeModal={() => setCurrentMovingItem(null)}
      />

      {/* Call to Action - Notification */}
      {hasExceededLimit && <VaultListMaxed folders={folders} />}

      {/* Vault Documents */}
      <div className="flex flex-wrap gap-x-4 gap-y-4">
        {/* Folders */}
        {folders?.slice(1)?.map((folder: VaultFolderType, index: number) => (
          <VaultFolderItem
            key={index}
            folder={folder}
            index={index}
            openManageFileFolderModal={(data) => openManageFileFolderModal(data)}
            setConfirmDelete={(data) => setCurrentDeletingItem(data)}
          />
        ))}

        {/* Notes */}
        {folders?.[0]?.vaultNotes?.map((note: VaultNoteType, note_index: number) => (
          <VaultNoteItem
            key={note_index}
            note={note}
            note_index={note_index}
            openNoteModal={(details) => setCurrentNote(details)}
            openMoveDocumentModal={openMoveDocumentModal}
            setConfirmDelete={(data) => setCurrentDeletingItem(data)}
          />
        ))}

        {/* Documents */}
        {folders?.[0]?.vaultDocuments?.map((document: VaultDocumentType, document_index: any) => (
          <VaultDocumentItem
            key={document_index}
            document={document}
            document_index={document_index}
            folders={folders}
            openManageFileFolderModal={(data) => openManageFileFolderModal(data)}
            openMoveDocumentModal={openMoveDocumentModal}
            setConfirmDelete={(data) => setCurrentDeletingItem(data)}
          />
        ))}
      </div>
    </>
  );
};

export default VaultList;
