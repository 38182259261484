import Skeleton from "react-loading-skeleton";

const DashboardTopAccountsCardAssetsLiabilitiesSkeleton = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <Skeleton circle={true} width={165} height={165} className="mb-2" />
      <div className="flex flex-row gap-x-3">
        <Skeleton width={150} height={12} />
      </div>
    </div>
  );
};

export default DashboardTopAccountsCardAssetsLiabilitiesSkeleton;
