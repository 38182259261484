import { gql } from "@apollo/client";

export const CREATE_TOTP_SECRET = gql`
  mutation CreateTotpSecret {
    info: createTotpSecret {
      image
      secret
      url
    }
  }
`;

export const ENABLE_TOTP = gql`
  mutation EnableTotpMFA($code: String!) {
    status: enableTotpMFA(code: $code)
  }
`;

export const DISABLE_TOTP = gql`
  mutation DisableTotpMFA($code: String!) {
    status: disableTotpMFA(code: $code)
  }
`;

export const DISABLE_SMS = gql`
  mutation DisableSmsMFA {
    status: disableSmsMFA
  }
`;
