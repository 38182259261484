import { loaderData, setUtilityData } from "../store/actions/utility";
import { useDispatch } from "react-redux";
import { getQuestions, verifyAnswer, nonUserPasswordSetup } from "../store/actions/lifecheckaction";
import { toast } from "react-toastify";
import { fetchSharedPortfolio } from "../store/actions/lifecheckaction";
import CheckCir from "../assets/svg/successcheck.svg";

const useLifeCheck = () => {
  const dispatch = useDispatch();

  const handleGetQuestions = (hash: string) => {
    return new Promise((resolve, reject) => {
      dispatch(setUtilityData(loaderData(true, "")));
      dispatch(getQuestions(hash))
        // @ts-expect-error
        .then((data) => {
          resolve(data);
        })
        .catch((error: any) => {
          toast.error(error.message);
          reject(error);
        })
        .finally(() => dispatch(setUtilityData(loaderData(false, ""))));
    });
  };

  const handleVerifyAnswer = (data: { recipientEmail: string; userId: number; questionId: string; answer: string }) => {
    return new Promise((resolve) => {
      dispatch(setUtilityData(loaderData(true, "")));

      dispatch(verifyAnswer(data))
        // @ts-expect-error
        .then((data) => {
          resolve(data);
        })
        .catch((error: any) => {
          toast.error(error.message);
        })
        .finally(() => dispatch(setUtilityData(loaderData(false, ""))));
    });
  };

  const handleFetchSharedPortfolio = (recipientId: string) => {
    dispatch(setUtilityData(loaderData(true, "")));

    dispatch(fetchSharedPortfolio(recipientId))
      // @ts-expect-error
      .catch((error: any) => {
        toast.error(error.message);
      })
      .finally(() => dispatch(setUtilityData(loaderData(false, ""))));
  };

  const handleNonUserPasswordSetup = (data: { recipientEmail: string; password: string }) => {
    return new Promise((resolve) => {
      dispatch(setUtilityData(loaderData(true, "")));

      dispatch(nonUserPasswordSetup(data))
        // @ts-expect-error
        .then((data) => {
          toast.success(
            <div className="toast-div">
              <img src={CheckCir} alt="check circle" />
              Password saved. Sign in to continue
            </div>
          );
          resolve(data);
        })
        .catch((error: any) => {
          toast.error(error.message);
        })
        .finally(() => dispatch(setUtilityData(loaderData(false, ""))));
    });
  };

  return {
    handleGetQuestions,
    handleVerifyAnswer,
    handleFetchSharedPortfolio,
    handleNonUserPasswordSetup,
  };
};

export default useLifeCheck;
