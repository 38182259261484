import React from "react";

export const Passwordlockicon = () => {
  return (
    <svg width="43" height="47" viewBox="0 0 43 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.90734 41.6482C-1.65557 33.0378 -2.70997 11.1204 9.90734 2.24907C22.5247 -6.62228 35.9262 12.4248 41.7051 30.1677C47.484 47.9105 21.4703 50.2585 9.90734 41.6482Z"
        fill="#C8DAF4"
      />
      <g clipPath="url(#clip0_17543_94625)">
        <path
          d="M29.3958 22.4287H4.60417C2.64816 22.4287 1.0625 24.2603 1.0625 26.5196V40.8378C1.0625 43.0971 2.64816 44.9287 4.60417 44.9287H29.3958C31.3518 44.9287 32.9375 43.0971 32.9375 40.8378V26.5196C32.9375 24.2603 31.3518 22.4287 29.3958 22.4287Z"
          stroke="#0056CD"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.37549 21.9376V13.5448C6.37549 10.7624 7.44826 8.09393 9.35781 6.12647C11.2674 4.15902 13.8573 3.05371 16.5578 3.05371C19.2583 3.05371 21.8482 4.15902 23.7577 6.12647C25.6673 8.09393 26.7401 10.7624 26.7401 13.5448V21.9376"
          stroke="#0056CD"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17543_94625">
          <rect width="34" height="45" fill="white" transform="translate(0 1)" />
        </clipPath>
      </defs>
    </svg>
  );
};
