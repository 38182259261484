import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const SidebarCategoryRoute = (props: {
  item: {
    translationKey: string;
    route: string;
    icon: any;
  };
  showSidebar: boolean;
  location: any;
  toggleSidebar: () => void;
  classNames?: string;
}) => {
  const { t } = useTranslation();
  const { item, showSidebar, location, toggleSidebar, classNames } = props;

  return (
    <NavLink
      className={({ isActive }) =>
        `max-w-[230px] py-2 px-2 flex flex-row items-center text-sm rounded-md cursor-pointer hover:bg-blue2-900 ${
          isActive ? "bg-blue2-900 border-r-2 border-beau-100" : ""
        } ${classNames}`
      }
      to={item.route}
      onClick={() => {
        if (showSidebar) {
          toggleSidebar();
        }
      }}
    >
      <span className={`mr-2.5 w-6 ${location.pathname.includes(item.route) && "bg-blue2-900"}`}>
        <item.icon className="w-5 h-5" />
      </span>
      <span>{t(item.translationKey)}</span>
    </NavLink>
  );
};
