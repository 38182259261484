import ReactTooltip from "react-tooltip";
import { Fragment, useState } from "react";
import { AlertIcon } from "../../../components/icons/alertcircle";
import { AccountManager } from "../../../components/modals/portfolio/AccountManager";
import { ConvertNumberCur } from "../../../services/utilty";
import { AccountType, CreateHoldingInput } from "../../../types/accountTypes";
import { InstitutionType } from "../../../types/categoryTypes";
import { lastSyncTime } from "../../../components/modals/viewassets";
import CustomAsset from "../../../assets/svg/custom-color/asset.svg";
import useAccountConnect from "../../../hooks/useAccountConnect";
import AddStockCrypto from "../../../components/modals/connectAccount/AddStockCrypto";
import { useSelector } from "react-redux";
import usePortfolio from "../../../hooks/usePortfolio";

export const AssetListMobile: React.FC<{
  institution: InstitutionType;
  handleRemoveAccountLabel: (id: string) => Promise<void>;
  handleSaveLabel: (accountIds: string[], labelId: string) => void;
}> = ({ institution, handleRemoveAccountLabel, handleSaveLabel }) => {
  const activePortfolioId = useSelector((state: any) => state.portfolios.activePortfolioId);
  const [addStockCryptoType, setAddStockCryptoType] = useState<"stock" | "crypto" | "">("");
  const { handleAddHolding } = useAccountConnect(addStockCryptoType);
  const [addStockCryptoModal, setAddStockCryptoModal] = useState<boolean>(false);
  const { handleGetPortfolioData } = usePortfolio(addStockCryptoModal);
  const [modal, setModal] = useState<string>("");
  const [expand, setExpand] = useState<boolean>(false);

  const openHoldingModal = (type: "stock" | "crypto") => {
    setAddStockCryptoType(type);
    setAddStockCryptoModal(true);
  };

  const addHolding = async (values: CreateHoldingInput) => {
    await handleAddHolding(values);
    setAddStockCryptoModal(false);
    setAddStockCryptoType("");
    handleGetPortfolioData(activePortfolioId, false);
  };

  return (
    <div className="mobile-assets-list my-2">
      <div className="flex justify-between px-3 py-2" onClick={() => setExpand(!expand)}>
        <div className="flex items-center">
          <img
            src={institution?.logo ? `data:image/jpeg;base64,${institution?.logo}` : CustomAsset}
            alt="institution logo"
            className="bg-blue-100 rounded-lg p-1 h-8 w-8"
          />
          <div className="ml-2">
            <p className="font-semibold text-p2 txt2s3">{institution?.name ? institution?.name : "Others"}</p>
          </div>
        </div>
      </div>
      <div>
        {expand && <hr />}
        {expand &&
          institution?.accounts.map((account: AccountType, index: number) => (
            <Fragment key={account.id}>
              {modal === `${index}` && (
                <AccountManager
                  isOpen={modal === `${index}`}
                  closeModal={() => setModal("")}
                  account={institution?.accounts[index]}
                  handleAddCrypto={() => openHoldingModal("crypto")}
                  handleAddStocks={() => openHoldingModal("stock")}
                  handleRemoveAccountLabel={handleRemoveAccountLabel}
                  handleSaveLabel={handleSaveLabel}
                />
              )}
              {addStockCryptoModal && (
                <AddStockCrypto
                  modalOpen={addStockCryptoModal}
                  closeModal={() => {
                    setAddStockCryptoModal(false);
                    setAddStockCryptoType("");
                  }}
                  submit={addHolding}
                  type={addStockCryptoType}
                  accountId={account.id}
                />
              )}
              <div
                className="row flex justify-between py-2 px-2"
                style={{ minHeight: 56 }}
                onClick={() => setModal(`${index}`)}
              >
                <div className="flex flex-col justify-center">
                  <p className="font-medium text-p3 txt2s3 vv2 capitalize">{account?.name.toLowerCase()}</p>
                  <small>{account?.number}</small>
                </div>
                <div className="flex items-center">
                  <div className="w-17">
                    <p className="font-semibold text-p3 text-right">
                      {ConvertNumberCur(account?.balance?.value, account?.balance?.symbol)}
                    </p>
                    <p className="text-right text-p4 l-sync">{lastSyncTime(account.synced_at)}</p>
                  </div>
                </div>
              </div>
            </Fragment>
          ))}
        {expand && (
          <div className="flex items-start justify-between table-total">
            <div className="flex items-center">
              Total{" "}
              <span className="ml-2 cursor-pointer" data-tip data-for="knowMoreTip">
                <AlertIcon />
                <ReactTooltip id="knowMoreTip" place="bottom" effect="solid" className="text-xs">
                  This is a breakdown of your accounts in their default currencies.
                </ReactTooltip>
              </span>
            </div>
            <div className="flex flex-col items-end">
              {institution.balances.map(
                (
                  amount: {
                    currency: string;
                    symbol: string;
                    value: number;
                  },
                  index: number
                ) => (
                  <div className="mb-2" key={index}>
                    {ConvertNumberCur(amount.value, amount.symbol)}
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
