import { useState } from "react";
import { useSelector } from "react-redux";
import { lastSyncTime } from "../../components/modals/viewassets";
import { useGetLifecheckQuery } from "../../api/lifecheck/lifecheck-queries";
import HistorySkeletons from "./HistorySkeletons";

const History = () => {
  const { id: portfolioId } = useSelector((state: any) => state.activePortfolio);
  const [metaOffset, setMetaOffset] = useState(0);
  const {
    data: lifecheck,
    isFetching: isFetchingLifecheck,
    isFetched: hasFetchedLifecheck,
  } = useGetLifecheckQuery({
    id: portfolioId,
    offset: metaOffset,
  });
  const history = lifecheck?.history?.data;
  const meta = lifecheck?.history?.meta;

  const handleNextAction = () => setMetaOffset(meta?.offset + meta.requestedSize);
  const handlePrevAction = () => setMetaOffset(meta?.offset - 10 < 0 ? 0 : meta?.offset - 10);

  const isLoading = isFetchingLifecheck || !hasFetchedLifecheck;

  return (
    <div>
      <p className="font-semibold md:text-h4 text-p2 mt-7 mb-4">History</p>

      {isLoading ? (
        <HistorySkeletons />
      ) : (
        <div>
          <div className="flex flex-col gap-y-2">
            {history?.length > 0 &&
              history?.map((data: any, index: number) => (
                <div
                  className="flex justify-between items-center py-2 px-4 hover:bg-gray-100 cursor-default"
                  key={index}
                >
                  <div className={`flex ${data.details ? "" : "items-center"}`}>
                    <p className="font-medium md:text-p2 text-p3">{data.message}</p>
                    {data.details && <p className="md:text-p2 text-p3">{data.details}</p>}
                  </div>

                  <span className="md:text-p3 text-p4 text-right">{lastSyncTime(data.created_at)}</span>
                </div>
              ))}
          </div>

          <div className="flex justify-center items-center text-primary mt-4">
            {meta.offset + meta.actualSize - meta.requestedSize > 0 && <button onClick={handlePrevAction}>Prev</button>}
            {meta.offset + meta.requestedSize < meta.total && (
              <button className="ml-4" onClick={handleNextAction}>
                Next
              </button>
            )}
          </div>
          <p className="text-center text-p3 mt-3">
            Page {Math.ceil(meta.offset / meta.requestedSize) + 1} of {Math.ceil(meta.total / meta.requestedSize)}
          </p>
        </div>
      )}
    </div>
  );
};

export default History;
