import { gql } from "@apollo/client";

const COMMON_FIELDS_FRAGMENT = gql`
  fragment CommonFields on Account {
    address {
      city
      country
      postal_code
      region
      street
    }
    balance(currency: $currency)
    balance_usd: balance(currency: "USD")
    balance_original(currency: $currency)
    balance_original_usd: balance_original(currency: "USD")
    id
    name
    display_name
    number
    synced_at
    created_at
    currency
    legacy_type
    legacy_id
    partner
    subtype
    type
    updated_at
    holdings {
      id
      name
      currency
      type
      symbol
      quantity
      price
      value
      value_original
    }
    institution {
      name
      logo
      id
    }
    label {
      id
      name
    }
    connection {
      id
      partner
      reconnect
      created_at
    }
    files {
      name
      id
      path(sign: true)
      size
      created_at
    }
    notes {
      content
      created_at
      id
      title
      updated_at
    }
  }
`;

export const X_FETCH_INSTITUTIONS = gql`
  query FetchInstitutionsForPortfolio($id: Int!, $currency: String!) {
    portfolio(id: $id) {
      institutions {
        id
        name
        balances(currency: $currency)
        balance_usd: balances(currency: "USD")
        logo
        partner
        accounts {
          ...CommonFields
        }
      }
    }
  }

  ${COMMON_FIELDS_FRAGMENT}
`;

// TODO: Update api so we can query for ID
export const X_FETCH_CLASSES = gql`
  query FetchClassesForPortfolio($id: Int!, $currency: String!) {
    portfolio(id: $id) {
      classes {
        name
        slug
        type
        subtype
        balances(currency: $currency)
        balance_usd: balances(currency: "USD")
        accounts {
          ...CommonFields
        }
      }
    }
  }

  ${COMMON_FIELDS_FRAGMENT}
`;

export const X_FETCH_LABELS = gql`
  query FetchLabelsForPortfolio($id: Int!, $currency: String!) {
    portfolio(id: $id) {
      labels {
        id
        name
        updated_at
        balances(currency: $currency)
        balance_usd: balances(currency: "USD")
        accounts {
          ...CommonFields
        }
      }
    }
  }

  ${COMMON_FIELDS_FRAGMENT}
`;

export const X_FETCH_PORTFOLIO = gql`
  query FetchPortfolio($id: Int!, $currency: String!) {
    portfolio(id: $id) {
      balances(currency: $currency)
      created_at
      id
      name
      owner
      subscription_id
      user_id
    }
  }
`;

export const X_FETCH_CONNECTIONS = gql`
  query FetchConnections($id: Int!) {
    portfolio(id: $id) {
      connections {
        id
        partner
        reconnect
        fixable
        accounts {
          display_name
          balance
          currency
        }
        institution {
          name
          logo
        }
      }
    }
  }
`;

export const X_FETCH_SUBSCRIPTION = gql`
  query FetchSubscription($id: Int!) {
    portfolio(id: $id) {
      subscription {
        cancel_at
        created_at
        current_period_end
        current_period_start
        ended_at
        id
        partner
        partner_status
        payment_method {
          card_brand
          card_country
          card_exp_month
          card_exp_year
          card_last4
          created_at
          id
          partner
          type
        }
        plan {
          active
          amount
          code
          currency
          id
          interval
          partner
        }
        quantity
        started_at
        status
      }
    }
  }
`;

export const FETCH_PARTNER_PLANS = gql`
  query FetchPartnerPlans($partner: String!) {
    config {
      plans(partner: $partner) {
        active
        amount
        code
        currency
        id
        interval
        name
        partner
        partner_ref
      }
    }
  }
`;
