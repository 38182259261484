import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { setCurrencyData } from "../../store/actions/assetsaction";
import { Currency } from "../../types/accountTypes";
import { Config, UserState } from "../../types/authTypes";
import { ChevronDownIcon, StarIcon } from "@heroicons/react/24/outline";
import { StarIcon as StarIconSolid } from "@heroicons/react/24/solid";
import { Dropdown } from "../Dropdown";
import useAuth from "../../hooks/useAuth";

// TODO: test VPN location change and create new account - verify default currency is set correctly

/**
 * NOTE: state.assets.currency.code is the source of truth for the currenc(ies) being viewed.
 * TODO: Update dashboard, report, etc. to use state.assets.currency.code.
 * TODO: always resets on page reload to All Currencies -- we want temp state to persist.
 * If you want to override, need to have state.assets.currency.code set to the default currency on query.
 */

/**
 * TODO: We need to clean up the data model for state.assets.currency.
 * Issue: Inconsistent data model: state.assets.currency. Expecting the `Currency` type but sometimes get `{ "country": "USD", "symbol": "$", "countryName": "USA" }`.
 */

const isAllCurrencies = (currencyCode?: string | null) => !currencyCode || currencyCode === "";

const getDisplayCurrencyLabel = (currency: Currency | string, currencies: Currency[]) => {
  const match =
    typeof currency === "string"
      ? currencies.find((c) => c.code === currency)
      : currencies.find((c) => c.code === currency.code);
  if (!match) return "N/A";
  return `${match?.code} (${match?.symbol})`;
};

const ALL_CURRENCIES: Currency = {
  code: "",
  symbol: "",
  name: "",
};

const CurrencySelector = () => {
  const dispatch = useDispatch();
  const user: UserState = useSelector((state: any) => state.user);
  const config: Config = useSelector((state: any) => state.user.config);
  const userCurrencies: Currency[] = useSelector((state: any) => state.user.currentUser.currencies);
  const displayCurrencyCode = useSelector((state: any) => state.assets.currency.code || state.assets.currency.country);
  const userDefaultCurrencyCode = user.currentUser.preferences.default_currency;
  const userDefaultCurrency = userCurrencies.find((currency) => currency.code === userDefaultCurrencyCode);
  const filteredCurrencies = userCurrencies.filter((currency: any) => currency.code !== userDefaultCurrencyCode);
  const userCurrenciesList = (isAllCurrencies(userDefaultCurrencyCode) ? [] : [ALL_CURRENCIES]).concat(
    filteredCurrencies
  );
  const isCurrencySelected = (currency: Currency) =>
    currency.code === displayCurrencyCode || (!currency.code && !displayCurrencyCode);

  const { updateCurrency } = useAuth();

  const handleSetDisplayCurrency = (currency: Currency) => {
    dispatch(setCurrencyData(currency));
  };

  const handleSetDefaultCurrency = (currency: Currency) => {
    updateCurrency({
      variables: {
        preferences: {
          default_currency: currency.code,
        },
      },
    });
  };

  const onClickSelectDefaultCurrency = () => {
    if (userDefaultCurrency && userDefaultCurrency.code) return handleSetDisplayCurrency(userDefaultCurrency);
    handleSetDisplayCurrency(ALL_CURRENCIES);
  };

  const defaultCurrencyIsSelected = !!(displayCurrencyCode === "" || userDefaultCurrencyCode === displayCurrencyCode);
  const defaultCurrencyLabel = (() => {
    if (isAllCurrencies(userDefaultCurrencyCode)) return config.null_currency_name;
    if (!userDefaultCurrency) return ALL_CURRENCIES.code;
    return userDefaultCurrency && getDisplayCurrencyLabel(userDefaultCurrency, userCurrencies);
  })();

  const dropdownContent = (
    <div className="bg-white shadow-lg rounded-md w-60 p-2 flex flex-col border border-solid border-gray-100">
      {/* Selected Currency */}
      <div
        className={classNames("text-sm flex justify-between items-center gap-x-2")}
        onClick={onClickSelectDefaultCurrency}
      >
        <span
          className={classNames(
            "w-full p-1.5 px-4 rounded-md cursor-pointer",
            defaultCurrencyIsSelected && "bg-blue-100"
          )}
        >
          {defaultCurrencyLabel}
        </span>
        <span className="p-2 rounded-md text-blue-600 cursor-default">
          <StarIconSolid className="w-5 h-5" />
        </span>
      </div>

      {/* List user currencies */}
      {userCurrenciesList.length > 0 && <hr className="my-1 py-1" />}
      <div className="flex flex-col gap-y-2">
        {userCurrenciesList.map((currency: any, index: number) => (
          <div
            key={index}
            onClick={() => handleSetDisplayCurrency(currency)}
            className={classNames("text-sm flex justify-between items-center gap-x-2")}
          >
            <span
              className={classNames(
                "w-full p-1.5 px-4 rounded-md hover:bg-gray-100 cursor-pointer",
                isCurrencySelected(currency) && "bg-blue-100"
              )}
            >
              {isAllCurrencies(currency.code)
                ? config.null_currency_name
                : getDisplayCurrencyLabel(currency, userCurrencies)}
            </span>
            <button
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                handleSetDefaultCurrency(currency);
              }}
              className="p-2 rounded-md text-gray-400 hover:text-blue-600 cursor-pointer"
            >
              <StarIcon className="w-5 h-5" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );

  const dropdownLabel = (() => {
    if (isAllCurrencies(displayCurrencyCode?.code || displayCurrencyCode)) return config.null_currency_name;
    return getDisplayCurrencyLabel(displayCurrencyCode, userCurrencies);
  })();

  return (
    <div className="relative">
      <Dropdown
        buttonContent={
          <button className="p-2 px-3 relative z-10 cursor-pointer w-26 flex items-center justify-between gap-x-2 border border-gray-100 rounded-md shadow-sm bg-gray-50 hover:bg-gray-100">
            <span className="text-sm">{dropdownLabel}</span>
            <ChevronDownIcon className="w-5 h-5" />
          </button>
        }
        contentClassName="rounded-md mt-2 right-4"
        closeOnClick={true}
      >
        {dropdownContent}
      </Dropdown>
    </div>
  );
};

export default CurrencySelector;
