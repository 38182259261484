import NumberFormat from "react-number-format";
import * as React from "react";

interface TextInput {
  label?: string;
  inputid?: string;
  linfo?: React.ReactNode;
  message?: string;
  iserror?: unknown;
  onChange?: (e?: any) => void;
  value?: any;
  prefix?: string;
  suffix?: string;
  placeholder?: string;
  type?: "text" | "tel" | "password" | undefined;
}

export const NumberFormatter = ({ label, linfo, inputid, message, iserror, ...rest }: TextInput) => {
  return (
    <div className="forminput">
      <label className="text-p2 font-medium text-type mb-1" htmlFor={inputid}>
        {label} {linfo ? <span className="text-xs">{linfo}</span> : null}
      </label>
      <NumberFormat
        className={`bg-neutral-300 rounded placeholder-type-200 text-type text-p2 font-normal px-2 py-3 transition focus:border-type focus:ring-0
        ${iserror ? "border-status-danger" : "border-neutral-200"}`}
        displayType={"input"}
        thousandSeparator={true}
        prefix={"₦ "}
        id={inputid}
        {...rest}
      />
      {iserror ? <small className="text-p3 mt-2.5 text-status-danger">{message}</small> : null}
    </div>
  );
};
