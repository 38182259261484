import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import { getNumberBetween } from "../../components/utilities/utilityFunctions";

const SetupSkeletons = () => (
  <div className="flex flex-col gap-y-3 sm:gap-y-8 w-full">
    {[...Array(3)].map((_, index) => (
      <div key={index} className="w-full">
        <div className={classNames("w-full h-full", "flex flex-row items-start justify-between")}>
          <div className="flex flex-row items-start gap-x-8">
            <Skeleton width={50} height={50} borderRadius={6} />

            <div className="flex md:hidden flex-col gap-y-1">
              <Skeleton width={getNumberBetween(75, 150)} height={25} borderRadius={4} />
              <div className="flex flex-col">
                <Skeleton width={getNumberBetween(100, 200)} height={15} borderRadius={4} />
                <Skeleton width={getNumberBetween(100, 200)} height={15} borderRadius={4} />
              </div>
            </div>

            <div className="hidden md:flex flex-col gap-y-1">
              <Skeleton width={getNumberBetween(100, 200)} height={25} borderRadius={4} />
              <div className="flex flex-col gap-y-1">
                <Skeleton width={getNumberBetween(250, 400)} height={15} borderRadius={4} />
                <Skeleton width={getNumberBetween(250, 400)} height={15} borderRadius={4} />
              </div>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default SetupSkeletons;
