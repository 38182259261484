import classNames from "classnames";
import { ReactComponent as SelectedItem } from "../../assets/svg/selected-item.svg";
import { ReactComponent as SelectedEmpty } from "../../assets/svg/selected-empty.svg";
import { SortOrder, SortType, UserSortKey, UserSortRule, SortRule } from "../../types/sortTypes";
import { useCurrentUserGql } from "../../graphql/current-user.gql-hook";
import { ArrowsUpDownIcon } from "@heroicons/react/24/outline";
import { Dropdown } from "../Dropdown";
import "./sort-comp.scss";

export enum SortValues {
  nameAscending = "nameAscending",
  nameDescending = "nameDescending",
  valueHighToLow = "valueHighToLow",
  valueLowToHigh = "valueLowToHigh",
}

interface ISortData {
  parent: string;
  sortPreferenceKey: UserSortKey;
  rule: UserSortRule;
}

const SortDataDropdown = ({ parent, sortPreferenceKey, rule }: ISortData) => {
  const sortOptions = [
    {
      label: "Name (A-Z)",
      type: SortType.NAME,
      order: SortOrder.ASC,
      value: SortValues.nameAscending,
    },
    {
      label: "Name (Z-A)",
      type: SortType.NAME,
      order: SortOrder.DESC,
      value: SortValues.nameDescending,
    },
    {
      label: "Most Value",
      type: SortType.VALUE,
      order: SortOrder.DESC,
      value: SortValues.valueHighToLow,
    },
    {
      label: "Least Value",
      type: SortType.VALUE,
      order: SortOrder.ASC,
      value: SortValues.valueLowToHigh,
    },
  ];

  const { updateUserSortOrder } = useCurrentUserGql();
  const isSelected = (item: any, sortRule?: SortRule) => {
    if (!sortRule) {
      return false;
    }

    return sortRule.type === item.type && sortRule.order === item.order;
  };

  return (
    <Dropdown
      buttonContent={
        <div className="bg-gray-100 hover:bg-gray-200 p-2 rounded-md flex items-center justify-center text-gray-500">
          <ArrowsUpDownIcon className="w-5 h-5" />
        </div>
      }
      contentClassName="rounded-md mt-2 right-4"
    >
      <div
        className={classNames(
          "flex flex-col gap-y-2",
          "bg-white shadow-2xl rounded-md w-48 p-4 pb-1",
          "border border-solid border-gray-100"
        )}
      >
        <div>
          <div className="font-semibold text-lg mb-2">{parent}</div>
          {sortOptions.map((item, index) => (
            <div
              className="flex items-center click-item mb-3 gap-3 cursor-pointer"
              onClick={() => {
                rule.group.type = item.type;
                rule.group.order = item.order;
                updateUserSortOrder(sortPreferenceKey, rule);
              }}
              key={index}
            >
              <div>{isSelected(item, rule?.group) ? <SelectedItem /> : <SelectedEmpty />}</div>
              <div className="text-gray-600 hover:underline">{item.label}</div>
            </div>
          ))}
        </div>

        <div>
          <div className="font-semibold text-lg mb-2">Account</div>
          {sortOptions.map((item, index) => (
            <div
              className="flex items-center click-item mb-3 gap-3 cursor-pointer"
              onClick={() => {
                rule.account.type = item.type;
                rule.account.order = item.order;
                updateUserSortOrder(sortPreferenceKey, rule);
              }}
              key={index}
            >
              <div>{isSelected(item, rule?.account) ? <SelectedItem /> : <SelectedEmpty />}</div>
              <div className="text-gray-600 hover:underline">{item.label}</div>
            </div>
          ))}
        </div>
      </div>
    </Dropdown>
  );
};

export default SortDataDropdown;
