import React from "react";

export const ShowPasswordIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.9996 31.5C26.3663 31.5 28.3746 30.675 30.0246 29.025C31.6746 27.375 32.4996 25.3667 32.4996 23C32.4996 20.6333 31.6746 18.625 30.0246 16.975C28.3746 15.325 26.3663 14.5 23.9996 14.5C21.6329 14.5 19.6246 15.325 17.9746 16.975C16.3246 18.625 15.4996 20.6333 15.4996 23C15.4996 25.3667 16.3246 27.375 17.9746 29.025C19.6246 30.675 21.6329 31.5 23.9996 31.5ZM23.9996 28.6C22.4329 28.6 21.1079 28.0583 20.0246 26.975C18.9413 25.8917 18.3996 24.5667 18.3996 23C18.3996 21.4333 18.9413 20.1083 20.0246 19.025C21.1079 17.9417 22.4329 17.4 23.9996 17.4C25.5663 17.4 26.8913 17.9417 27.9746 19.025C29.0579 20.1083 29.5996 21.4333 29.5996 23C29.5996 24.5667 29.0579 25.8917 27.9746 26.975C26.8913 28.0583 25.5663 28.6 23.9996 28.6ZM23.9996 38C19.3996 38 15.2079 36.75 11.4246 34.25C7.64128 31.75 4.71628 28.45 2.64961 24.35C2.54961 24.1833 2.47461 23.9833 2.42461 23.75C2.37461 23.5167 2.34961 23.2667 2.34961 23C2.34961 22.7333 2.37461 22.4833 2.42461 22.25C2.47461 22.0167 2.54961 21.8167 2.64961 21.65C4.71628 17.55 7.64128 14.25 11.4246 11.75C15.2079 9.25 19.3996 8 23.9996 8C28.5996 8 32.7913 9.25 36.5746 11.75C40.3579 14.25 43.2829 17.55 45.3496 21.65C45.4496 21.8167 45.5246 22.0167 45.5746 22.25C45.6246 22.4833 45.6496 22.7333 45.6496 23C45.6496 23.2667 45.6246 23.5167 45.5746 23.75C45.5246 23.9833 45.4496 24.1833 45.3496 24.35C43.2829 28.45 40.3579 31.75 36.5746 34.25C32.7913 36.75 28.5996 38 23.9996 38ZM23.9996 35C28.0329 35 31.7413 33.9083 35.1246 31.725C38.5079 29.5417 41.0829 26.6333 42.8496 23C41.0829 19.3667 38.5079 16.4583 35.1246 14.275C31.7413 12.0917 28.0329 11 23.9996 11C19.9663 11 16.2579 12.0917 12.8746 14.275C9.49128 16.4583 6.89961 19.3667 5.09961 23C6.89961 26.6333 9.49128 29.5417 12.8746 31.725C16.2579 33.9083 19.9663 35 23.9996 35Z"
        fill="#1E2329"
      />
    </svg>
  );
};
