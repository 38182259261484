import { UPDATE_CURRENT_USER } from "../actions/current-user.actions";

const initialState = {
  preferences: {},
};

export default function currentUserReducer(state = initialState, action: any) {
  switch (action.type) {
    case UPDATE_CURRENT_USER:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
