import { ApolloError, useMutation } from "@apollo/client";
import { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { toast } from "react-toastify";
import { ReactComponent as Check } from "../../assets/svg/check-gray.svg";
import { ReactComponent as Copy } from "../../assets/svg/copy-light.svg";
import { ReactComponent as DollarSign } from "../../assets/svg/dollar-rounded.svg";
import { ReactComponent as Divider1 } from "../../assets/svg/referral-divider-one.svg";
import { ReactComponent as Divider2 } from "../../assets/svg/referral-divider-two.svg";
import { ReactComponent as Earn } from "../../assets/svg/referral-earn.svg";
import { ReactComponent as Onboard } from "../../assets/svg/referral-onboard.svg";
import { ReactComponent as Send } from "../../assets/svg/referral-send.svg";
import Email from "../../assets/svg/social-share/email.svg";
import Facebook from "../../assets/svg/social-share/facebook.svg";
import Linkedin from "../../assets/svg/social-share/linkedin.svg";
import Twitter from "../../assets/svg/social-share/twitter.svg";
import Whatsapp from "../../assets/svg/social-share/whatsapp.svg";
import CheckCir from "../../assets/svg/successcheck.svg";
import MainButton from "../../components/buttons/mainButton";
import { BluetickIcon } from "../../components/icons/bluetick";
import { Passwordlockicon } from "../../components/icons/passwordlockicon";
import { TickIcon } from "../../components/icons/tickicon";
import { Textinput } from "../../components/inputs/textinput";
import { TextinputwithLeftIcon } from "../../components/inputs/textinputwithlefticon";
import AddPaypalEmail from "../../components/modals/addpaypalemail/addpaypalemail";
import DeleteAccountModal from "../../components/modals/deleteAccountModal";
import { Errornotf } from "../../components/notifications/errornotf";
import { SuccessToast } from "../../components/notifications/toasts";
import { CHANGE_PASSWORD } from "../../graphql/auth";
import { claimReward, getReferralDetails, setupReferralEmail } from "../../store/actions/userdata";
import { loaderData, setUtilityData } from "../../store/actions/utility";
import { ChangePin, SetPinModal2 } from "../vault/VaultModals";
import { TwoFactorAuthentication } from "./mfa";
import { useTranslation } from "react-i18next";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const ChangepasswordTab = () => {
  const [errorMessage, seterrorMessage] = useState<string | null>(null);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const dispatch = useDispatch();
  const { handleSubmit, errors, control, watch, reset } = useForm({ criteriaMode: "all", mode: "onChange" });
  const notify = () =>
    toast(<SuccessToast message={"Your password was updated successfully."} />, {
      className: "custom-toast-success",
    });
  const watchpassword = watch("newpassword");

  const [changePassword, { data: changePasswordResponse }] = useMutation(CHANGE_PASSWORD, {
    errorPolicy: "all",
    onCompleted: () => {
      const res = changePasswordResponse;

      if (res?.changePassword) {
        notify();
        reset({
          oldpassword: "",
          newpassword: "",
          nnewpassword: "",
        });
      } else {
        seterrorMessage("Unable to change password, please try again later.");
      }
    },
    onError(error: ApolloError) {
      seterrorMessage(error.message);
    },
  });

  const onSubmit = (data: any) => {
    seterrorMessage(null);
    changePassword({
      variables: {
        password: {
          new_password: data.newpassword,
          old_password: data.oldpassword,
        },
      },
    });
  };

  return (
    <div className="changepassword ">
      <div className="passwordinfoform">
        <div className="passwordform">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Errornotf style={{ marginBottom: "30px" }} message={errorMessage} />
            <div className="form-control">
              <Controller
                name="oldpassword"
                defaultValue={""}
                rules={{ required: true }}
                control={control}
                render={(props) => (
                  <Textinput
                    onChange={(e: any) => {
                      props.onChange(e.target.value);
                    }}
                    value={props.value}
                    label="Current Password"
                    inputid="oldpassword"
                    name="oldpassword"
                    type="password"
                    iserror={errors.oldpassword}
                    message={"This field is required"}
                  />
                )}
              />
            </div>
            <div className="form-control">
              <Controller
                name="newpassword"
                defaultValue={""}
                rules={{
                  validate: {
                    isUpper: (value) => /[A-Z]/.test(value),
                    is8Digit: (value) => value && value.length >= 8,
                    isNumber: (value) => /\d/.test(value),
                  },
                }}
                control={control}
                render={(props) => (
                  <TextinputwithLeftIcon
                    onChange={(e: any) => {
                      props.onChange(e.target.value);
                    }}
                    onclickicon={() => setShowNewPassword(!showNewPassword)}
                    value={props.value}
                    label="New Password"
                    inputid="newpassword"
                    name="newpassword"
                    type={showNewPassword ? "text" : "password"}
                    iserror={errors.oldpassword}
                  />
                )}
              />
              {errors.newpassword ? (
                <div className="">
                  <p className="flex items-center">
                    {errors && errors.newpassword && errors.newpassword.types && errors.newpassword.types.is8Digit ? (
                      <span className="mr-1">
                        <XMarkIcon className="h-5 w-5 text-red-500" />
                      </span>
                    ) : (
                      <span className="mr-1">
                        <TickIcon color="#4EB547" />
                      </span>
                    )}
                    <span>8 characters</span>
                  </p>
                  <p className="flex items-center">
                    {errors && errors.newpassword && errors.newpassword.types && errors.newpassword.types.isUpper ? (
                      <span className="mr-1">
                        <XMarkIcon className="h-5 w-5 text-red-500" />
                      </span>
                    ) : (
                      <span className="mr-1">
                        <TickIcon color="#4EB547" />
                      </span>
                    )}
                    <span>1 UPPER CASE letter</span>
                  </p>
                  <p className="flex items-center">
                    {errors && errors.newpassword && errors.newpassword.types && errors.newpassword.types.isNumber ? (
                      <span className="mr-1">
                        <XMarkIcon className="h-5 w-5 text-red-500" />
                      </span>
                    ) : (
                      <span className="mr-1">
                        <TickIcon color="#4EB547" />
                      </span>
                    )}
                    <span>1 number</span>
                  </p>
                </div>
              ) : null}
            </div>
            <div className="form-control">
              <Controller
                name="nnewpassword"
                defaultValue={""}
                rules={{
                  validate: (value) => {
                    return value === watchpassword;
                  },
                }}
                control={control}
                render={(props) => (
                  <TextinputwithLeftIcon
                    onChange={(e: any) => {
                      props.onChange(e.target.value);
                    }}
                    onclickicon={() => setShowConfirmPassword(!showConfirmPassword)}
                    value={props.value}
                    label="Re-Enter New Password"
                    inputid="nnewpassword"
                    name="nnewpassword"
                    type={showConfirmPassword ? "text" : "password"}
                    iserror={errors.nnewpassword}
                    message={"The passwords do not match"}
                  />
                )}
              />
            </div>
            <div className="mb-2">
              <button onClick={handleSubmit(onSubmit)} type="submit" id="login-submit" className="btn-primary btn-md ">
                Save
              </button>
            </div>
          </form>
        </div>
        <div className="passwordforminfos">
          <div className="passwordlockicon">
            <Passwordlockicon />
          </div>
          <div className="passwordinfosdiv">
            <p className="forprotection">For your protection</p>
            <p className="inorder">In order to protect your account, make sure your password</p>

            <p className="passwordinfo12 text-p3">
              <span>
                <BluetickIcon />
              </span>
              is 8 characters long
            </p>
            <p className="passwordinfo12 text-p3">
              <span>
                <BluetickIcon />
              </span>
              has at least 1 UPPER CASE
            </p>
            <p className="passwordinfo12 text-p3">
              <span>
                <BluetickIcon />
              </span>
              has at least 1 number
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const InviteTab = () => {
  const { t } = useTranslation();
  const [referralDetails, setReferralDetails] = useState<any>(null);
  const dispatch = useDispatch();
  const email: string = useSelector((state: any) => state.userdata.email);
  const [showAddEmailModal, setShowAddEmailModal] = useState<boolean>(false);
  const [extraValues, setExtraValues] = useState<{ totalEarned: number; availableClaim: number }>({
    totalEarned: 0,
    availableClaim: 0,
  });

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line
  }, []);

  const getDetails = () => {
    dispatch(getReferralDetails())
      // @ts-expect-error
      .then((data) => {
        setReferralDetails(data);
        calcExtrasValues(data?.convertsList);
      })
      .catch((error: any) => {
        setShowAddEmailModal(true);
      });
  };

  const calcExtrasValues = (data: Array<{ status: string; purchaseValue: string }>) => {
    let earned: number = 0;
    let available: number = 0;

    for (let i = 0; i < data.length; i++) {
      earned = earned + Number(data[i].purchaseValue) / 10;

      if (data[i].status === "0") {
        available = available + Number(data[i].purchaseValue) / 10;
      }
    }

    setExtraValues({ totalEarned: earned, availableClaim: available });
  };

  const setupEmail = (paypal_email: string) => {
    dispatch(setUtilityData(loaderData(true, "")));
    dispatch(setupReferralEmail({ email, paypal_email }))
      // @ts-expect-error
      .then((data) => {
        setShowAddEmailModal(false);
        toast(<SuccessToast message={"Paypal email saved"} />, {
          className: "custom-toast-success",
        });
        getDetails();
      })
      .catch((error: any) => {
        toast.error(error.message);
      })
      .finally(() => {
        dispatch(setUtilityData(loaderData(false, "")));
      });
  };

  const copyLink = (link: string) => {
    navigator.clipboard.writeText(link);
    toast.success(
      <div className="toast-div">
        <img src={CheckCir} alt="check circle" />
        Link copied to clipboard
      </div>
    );
  };

  const claimAvailableBalance = () => {
    dispatch(setUtilityData(loaderData(true, "")));
    dispatch(claimReward())
      // @ts-expect-error
      .then(() => {
        setShowAddEmailModal(false);
        toast(<SuccessToast message={"Balance claimed successfully"} />, {
          className: "custom-toast-success",
        });
        getDetails();
      })
      .catch((error: any) => {
        toast.error(error.message);
      })
      .finally(() => {
        dispatch(setUtilityData(loaderData(false, "")));
      });
  };

  return (
    <div className="notificationstab">
      <AddPaypalEmail
        modalIsOpen={showAddEmailModal}
        closeModal={() => {
          setShowAddEmailModal(false);
        }}
        setupEmail={setupEmail}
      />

      <div className="notificationstab-header">
        <div className="referral">
          <p className="h5-semibold text-h5">Referrals</p>

          <div className="mt-3 tab-desc">
            Invite a friend to {t("orgName")} using your invite link and{" "}
            <span className="font-medium">earn up to $10</span> on each invite
          </div>

          <div className="referral-info">
            <div className="r-i-item">
              <Send />
              <div>
                <div className="i-title">Send Invitation</div>
                <div className="i-desc">Invite family and friends to join {t("orgName")} using your invite link.</div>
              </div>
            </div>

            <Divider1 className="r-divider" />

            <div className="r-i-item">
              <Onboard />
              <div>
                <div className="i-title">Onboarding</div>
                <div className="i-desc">Have them register to our services using your referral link.</div>
              </div>
            </div>

            <Divider2 className="r-divider" />

            <div className="r-i-item">
              <Earn />
              <div>
                <div className="i-title">Earn your reward</div>
                <div className="i-desc">Get an email notifying you about the reward you just earned.</div>
              </div>
            </div>
          </div>

          {referralDetails ? (
            <div className="r-link">
              <div className="r-l-title">Share your unique referral link</div>

              <div className="link-btn">
                <div className="l-b-text">
                  <span className="lg-only">{referralDetails?.referralLink}</span>
                  <span className="sm-only">{`${referralDetails?.referralLink.substring(0, 26)}...`}</span>
                </div>
                <MainButton
                  type="primary"
                  size="big"
                  extraClasses="l-btn flex items-center"
                  click={() => {
                    copyLink(referralDetails?.referralLink);
                  }}
                >
                  <Copy /> <span className="ml-1">Copy link</span>
                </MainButton>
              </div>

              <div className="flex mt-3 gap-1">
                <EmailShareButton url={referralDetails?.referralLink}>
                  <img src={Email} alt="email" />
                </EmailShareButton>

                <FacebookShareButton url={referralDetails?.referralLink}>
                  <img src={Facebook} alt="facebook" />
                </FacebookShareButton>

                <WhatsappShareButton url={referralDetails?.referralLink}>
                  <img src={Whatsapp} alt="whatsapp" />
                </WhatsappShareButton>

                <TwitterShareButton url={referralDetails?.referralLink}>
                  <img src={Twitter} alt="twitter" />
                </TwitterShareButton>

                <LinkedinShareButton url={referralDetails?.referralLink}>
                  <img src={Linkedin} alt="linkedin" />
                </LinkedinShareButton>

                {/* <FacebookMessengerShareButton url={referralDetails?.referralLink}>
                  <img src={Messenger} alt="facebook" />
                </FacebookMessengerShareButton> */}
              </div>
            </div>
          ) : (
            <div className="flex flex-row justify-center ref-btn-wrapper">
              <MainButton
                type="primary"
                size="big"
                extraClasses="mt-3"
                click={() => {
                  setShowAddEmailModal(true);
                }}
              >
                Generate your unique referral link
              </MainButton>
            </div>
          )}

          {referralDetails && (
            <Fragment>
              <div className="divider-full" />

              <div className="referral-list-lg">
                <div className="r-l-top flex flex-row justify-between">
                  <div>Referral Status</div>

                  <div>Total amount earned: ${extraValues.totalEarned}</div>
                </div>

                <div className="mt-4">
                  <table className="table-fixed assetstable">
                    <thead className="assetsheader r201">
                      <tr>
                        <th className="asseth">
                          <div>YOUR REFERRAL</div>
                        </th>
                        <th className="rth">
                          <div>STATUS</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {referralDetails?.convertsList.map(
                        (
                          item: {
                            email: string;
                            orderID: string;
                            purchaseValue: string;
                            referee_name: string;
                            status: string;
                          },
                          index: number
                        ) => (
                          <tr key={index}>
                            <td className="assetsr py-4 capitalize">
                              <div>{item?.referee_name}</div>
                            </td>

                            <td className="assetsr py-4 flex justify-end">
                              {item?.status === "1" ? (
                                <MainButton size="small" type="secondary" extraClasses="flex items-center">
                                  <Check /> <span className="ml-1">claimed</span>
                                </MainButton>
                              ) : (
                                <button className="claim-btn">${Number(item?.purchaseValue) / 10} available</button>
                              )}
                            </td>
                          </tr>
                        )
                      )}
                      <tr>
                        <td className="assetsr py-4">
                          <div className="total-cl">Total available claims: ${extraValues.availableClaim}</div>
                        </td>

                        <td className="assetsr py-4 text-right">
                          <MainButton
                            type="primary"
                            size="small"
                            extraClasses="c-btn"
                            disabled={extraValues.availableClaim < 10}
                            click={claimAvailableBalance}
                          >
                            Claim all
                          </MainButton>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="referral-list-sm -mt-2">
                <div className="mb-2 font-semibold text-base">Referral Status</div>

                <div className="amount-earned rounded-lg p-4 flex flex-row mb-10">
                  <div className="mr-3">
                    <DollarSign />
                  </div>

                  <div className="e-e-right">
                    <div className="text-gray-500">Total amount earned</div>
                    <div className="font-semibold">${extraValues.totalEarned}</div>
                  </div>
                </div>

                <div className="mb-6 font-semibold text-base">People you have referred</div>

                <div>
                  {referralDetails?.convertsList.map(
                    (
                      item: {
                        email: string;
                        orderID: string;
                        purchaseValue: string;
                        referee_name: string;
                        status: string;
                      },
                      index: number
                    ) => (
                      <div className="flex flex-row justify-between items-center mb-4" key={index}>
                        <div className="capitalize">{item?.referee_name}</div>

                        <div>
                          {item?.status === "1" ? (
                            <MainButton size="small" type="secondary" extraClasses="flex items-center">
                              <Check /> <span className="ml-1">claimed</span>
                            </MainButton>
                          ) : (
                            <button className="claim-btn">${Number(item?.purchaseValue) / 10} available</button>
                          )}
                        </div>
                      </div>
                    )
                  )}
                </div>

                <div className="flex flex-row justify-between items-center mt-4">
                  <div className="total-cl">Total available: ${extraValues.availableClaim}</div>

                  <MainButton
                    type="primary"
                    size="small"
                    disabled={extraValues.availableClaim < 10}
                    extraClasses="c-btn"
                    click={claimAvailableBalance}
                  >
                    Claim all
                  </MainButton>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export const SecurityTab = () => {
  const userdata = useSelector((state: any) => state.userdata);
  const currentUser = useSelector((state: any) => state.currentUser);
  const [isDeleteModal, setisDeleteModal] = useState(false);
  const [isEnterPin, setisEnterPin] = useState(false);
  const [isSetPin, setisSetPin] = useState(false);

  let openDeleteModal = () => {
    setisDeleteModal(true);
  };

  let closeDeleteModal = () => {
    setisDeleteModal(false);
  };

  const openSetPin = () => {
    setisSetPin(true);
  };

  const closeSetPin = () => {
    setisSetPin(false);
  };

  const openEnterPin = () => {
    setisEnterPin(true);
  };

  const closeEnterPin = () => {
    setisEnterPin(false);
  };

  const openVaultPinModal = () => {
    if (!userdata.vaultPinEnabled) {
      openSetPin();
    } else {
      openEnterPin();
    }
  };

  return (
    <>
      <div className="securitytab ">
        <div className="mb-12">
          <div className="text-type font-medium mb-2 text-h4">Email Verification</div>
          <div className="text-type-100 text-p2">
            We need you to verify your email address. We will send codes to it if you never set up 2FA.
          </div>
        </div>
        <div className="securitylist p-0">
          <div className="securitylist-items">
            <div className="first-side-1">
              <p className="text-type font-medium mb-1">Email authentication</p>
              <p className="first-side-12">{currentUser.email}</p>
            </div>
            <div className="first-side-2">
              <p className="first-side-22 p-semibold "> Verified </p>
            </div>
          </div>
        </div>
        <br />
        <br />
        <TwoFactorAuthentication />
        <div className="securitylist p-0">
          <div className="changepinish">
            <div className="text-type font-medium mb-2 text-h4">{userdata.vaultPinEnabled ? "Change" : "Set"} Pin</div>
            <p className="text-type-100 changepinish-t2 text-p2">
              Your vault PIN lets you secure access to documents inside your vault. You will need it to open and
              download files from the vault.
            </p>

            <button onClick={openVaultPinModal} type="submit" id="login-submit" className="btn-primary btn-md ">
              {userdata.vaultPinEnabled ? "Change" : "Set"} Vault PIN
            </button>
          </div>
          <br />

          <div className="deleteaccountdiv">
            <div className="header1 pheader">
              <p className="h5-semibold zlo">Delete My Account</p>
            </div>
            <div className="deletesmessage">
              <p className="first-side-12">
                This will permanently delete your assets and your beneficiaries will no longer be notified.This action
                cannot be undone
              </p>
            </div>
            <div className="mt-8">
              <button
                type="button"
                className="font-semibold rounded text-p2 px-4 py-2 text-center transition text-status-danger bg-status-danger-bg hover:bg-status-danger-hover"
                onClick={openDeleteModal}
              >
                Delete Account
              </button>
            </div>
          </div>
        </div>
        {isDeleteModal && <DeleteAccountModal modalIsOpen={isDeleteModal} closeModal={closeDeleteModal} />}

        {isEnterPin ? <ChangePin modalIsOpen={isEnterPin} closeModal={closeEnterPin} /> : null}
        {isSetPin ? <SetPinModal2 modalIsOpen={isSetPin} closeModal={closeSetPin} /> : null}
      </div>
    </>
  );
};

export const LanguageTab = () => {
  const languages = [
    {
      code: "en",
      name: "English",
    },
    {
      code: "fr",
      name: "French",
    },
  ];

  const { i18n } = useTranslation();
  const onSelectedLanguage = (language: string) => {
    // localStorage.setItem("language", language);
    // window.location.reload();
    i18n.changeLanguage(language);
  };

  return (
    <div className="w-full">
      <div className="max-w-[200px] flex flex-col gap-y-2">
        {languages.map((language) => (
          <MainButton
            size="small"
            type="secondary"
            extraClasses="flex items-center justify-between"
            click={() => onSelectedLanguage(language.code)}
          >
            {language.name} {i18n.language === language.code && <BluetickIcon />}
          </MainButton>
        ))}
      </div>
    </div>
  );
};
