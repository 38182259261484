import axios from "axios";
import { useSelector } from "react-redux";
import { UserState } from "../types/authTypes";

const GRAPHQL_BASE_URI = process.env.REACT_APP_GRAPHQL_BASE_URI as string;

export function useApi(customHeaders: any = null) {
  const token = useSelector((state: { user: UserState }) => state.user.token);

  let Api = axios.create({
    baseURL: GRAPHQL_BASE_URI,
  });

  Api.defaults.headers = {
    "Content-Type": "application/json",
    Accept: "*/*",
    "Access-Control-Allow-Origin": "*",
    ...customHeaders,
  };

  Api.interceptors.request.use((config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  return Api;
}
