import React, { useEffect, useState } from "react";
import { getFileData } from "../../components/modals/viewassets";
import { ReactComponent as EmptyFolder } from "../../assets/svg/girlwithflower.svg";
import { ReactComponent as VaultNoteIcon } from "../../assets/svg/vault-note.svg";
import { VaultDocumentDropdown } from "../../components/inputs/vaultdropdowns";
import { VaultFileIcon } from "../../components/icons/vaultfileicon";
import { BackarrowIcon } from "../../components/icons/backarrow";
import {
  convertStampDate,
  convertStampDatev2,
  subStringofName,
  convertBytestoMB,
} from "../../components/utilities/utilityFunctions";
import { useVault } from "../vault/VaultProvider";

export const SingleFolder = ({ goBack, folder, ownerId }: any) => {
  const [isFolderEmpty, setIsFolderEmpty] = useState(false);
  const { currentFolder, setCurrentFolder } = useVault();

  useEffect(() => {
    setCurrentFolder(folder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentFolder && currentFolder?.vaultDocuments?.length === 0 && currentFolder?.vaultNotes?.length === 0) {
      setIsFolderEmpty(true);
    } else setIsFolderEmpty(false);
  }, [currentFolder]);

  return (
    <>
      <div className="flex cursor-pointer items-center" onClick={goBack}>
        <BackarrowIcon />
        <span className="ml-2">Back</span>
      </div>
      <div className="mt-8 mb-12 flex justify-between items-center">
        <div className="flex items-center">
          <h2 className="h2-size">{currentFolder?.name}</h2>
        </div>
      </div>

      {isFolderEmpty ? (
        <div className="emptyvault">
          <div className="emptyvaults3">
            <EmptyFolder />
            <p className="emptyvaults3-t1">This folder is empty</p>
          </div>
        </div>
      ) : (
        <div className="flex flex-wrap gap-x-4 gap-y-4">
          {currentFolder &&
            currentFolder?.vaultDocuments?.map((value: any, i: any) => {
              return (
                <div
                  key={`${value.fileUrl}_${i}`}
                  className="singleli1 p-5 border border-solid border-gray-200 rounded-md w-full md:w-48"
                >
                  <div className="singleli1top">
                    <div className="singleli1top-12">
                      <div className="relative vault-file-icon">
                        <VaultFileIcon
                          mainColor={getFileData(value.fileName).backgroundColor}
                          foldColor={getFileData(value.fileName).foldColor}
                        />
                        <p className="absolute font-semibold text-white text-p2">
                          {getFileData(value.fileName).filetype}
                        </p>
                      </div>
                    </div>
                    <div className="singleli1top-13">
                      <VaultDocumentDropdown
                        fileId={value.id}
                        name={value.fileName}
                        type="file"
                        isShared
                        sharedFileOwnerId={ownerId}
                      />
                    </div>
                  </div>
                  <div className="singleli1bottom">
                    <p className="singleli1bottom-name">{subStringofName(value.fileName, 37)}</p>
                    <p className="singleli1bottom-na4">
                      <span className="t11">{convertBytestoMB(value.fileSize)} .</span>{" "}
                      <span className="t22">{convertStampDate(value.dateAdded)}</span>
                    </p>
                  </div>
                </div>
              );
            })}
          {currentFolder?.vaultNotes?.map((note: any, i: number) => {
            return (
              <div
                key={`note-${i}`}
                className="singleli1 p-5 border border-solid border-gray-200 rounded-md w-full md:w-48"
              >
                <div className="singleli1top">
                  <div className="singleli1top-12">
                    <VaultNoteIcon />
                  </div>
                  <div className="singleli1top-13">
                    <VaultDocumentDropdown
                      fileId={note?.id}
                      name={subStringofName(note?.title, 37)}
                      type="note"
                      isShared
                      sharedFileOwnerId={ownerId}
                    />
                  </div>
                </div>
                <div className="singleli1bottom">
                  <p className="singleli1bottom-name">{subStringofName(note.title, 37)}</p>
                  <p className="singleli1bottom-na4">
                    <span className="t22">{convertStampDatev2(note.lastEdited ?? note.dateCreated)}</span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
