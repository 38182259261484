import "./index.scss";
import "react-tabs/style/react-tabs.css";
import { AlertIcon } from "../../icons/alertcircle";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(utc);
dayjs.extend(relativeTime);

export const lastSyncTime = (date: string) => {
  const setUtcStamp = dayjs.utc(date);
  const convertToLocal = setUtcStamp.local();
  return convertToLocal.fromNow();
};

export let getFileData = (url: string) => {
  let filetype = getSecondPart(url);
  let response = {
    backgroundColor: "#EE425A",
    foldColor: "#E4213C",
    filetype: filetype.toUpperCase(),
  };

  if (filetype === "pdf" || filetype === "txt") {
    response = {
      backgroundColor: "#E2574C",
      foldColor: "#B53629",
      filetype: filetype.toUpperCase(),
    };
  } else if (filetype === "png" || filetype === "jpg" || filetype === "jpeg" || filetype === "svg") {
    response = {
      backgroundColor: "#0E2A47",
      foldColor: "#091C2F",
      filetype: filetype.toUpperCase(),
    };
  } else if (filetype === "doc" || filetype === "docx") {
    response = {
      backgroundColor: "#42A5F5",
      foldColor: "#187BD2",
      filetype: filetype.toUpperCase(),
    };
  } else if (filetype === "ppt" || filetype === "pptx") {
    response = {
      backgroundColor: "#FF8000",
      foldColor: "#FC6100",
      filetype: filetype.toUpperCase(),
    };
  } else if (filetype === "xls" || filetype === "xlsx" || filetype === "csv") {
    response = {
      backgroundColor: "#26A69A",
      foldColor: "#00897B",
      filetype: filetype.toUpperCase(),
    };
  } else if (filetype === "zip") {
    response = {
      backgroundColor: "#0056CD",
      foldColor: "#0047A8",
      filetype: filetype.toUpperCase(),
    };
  }

  return response;
};

export const DocumentsAlert = () => {
  return (
    <div className="p-3 bg-primary-alice rounded-md flex items-start">
      <span className="mr-2 translate-y-0.5">
        <AlertIcon />{" "}
      </span>
      <p className="text-type text-p3 font-normal">
        Upload sensitive documents to your{" "}
        <NavLink to="/vault" className="font-semibold text-primary">
          Vault
        </NavLink>{" "}
        to keep them private. Documents attached to shared portfolios can be seen by everyone.
      </p>
    </div>
  );
};

export const getNameFromURL = (str: string): string => {
  return unescape(str.split("%20_")[1]).split(" ").join("");
};
export function getSecondPart(str: string) {
  if (!str) return "";
  let arrayRes = str.split(".");
  return arrayRes[arrayRes.length - 1];
}
