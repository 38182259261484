import { SET_USER_STATE, RESET_USER_STATE, SET_CONFIG } from "./types";
import { UserState } from "../../../types/authTypes";

const initialState: UserState = {
  currentUser: {
    about_us: null,
    coupon: null,
    auth_methods: [],
    image_url: null,
    currencies: [],
    email: "",
    first_name: "",
    id: 0,
    is_referred: null,
    is_verified: false,
    last_name: "",
    onboardingSteps: {
      is2FAauthenticated: false,
      isAccountConnected: false,
      isPortfolioShared: false,
      isRecipientAdded: false,
      isVaultConnected: false,
    },
    phone_number: "",
    preferences: {
      default_currency: null,
      two_fa_auth: {
        hasSmsEnabled: false,
        hasTotpEnabled: false,
      },
    },
    payment_methods: [],
    signup_date: new Date(),
  },
  token: "",
  config: {
    countries: [],
    currencies: [],
    environment: "production",
    plans: [],
    null_currency_name: "",
  },
};

export default function UserData(state = initialState, action: any) {
  switch (action.type) {
    case SET_USER_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case SET_CONFIG:
      return {
        ...state,
        config: {
          ...state.config,
          ...action.payload,
        },
      };
    case RESET_USER_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
