import React, { ReactNode } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import { Subscription, SubscriptionStatus, PlanIntervals } from "../../../types/subscriptionTypes";

interface SubscriptionInfoProps {
  subscription: Subscription;
  children: ReactNode;
}

const formatSubscriptionStatus = (status: SubscriptionStatus) => {
  switch (status) {
    case SubscriptionStatus.ACTIVE:
      return "Active";
    case SubscriptionStatus.CANCELED:
      return "Canceled";
    case SubscriptionStatus.PAYMENT_PENDING:
      return "Payment Pending";
    case SubscriptionStatus.PAUSED:
      return "Paused";
    default:
      return "";
  }
};

const SubscriptionInfo: React.FC<SubscriptionInfoProps> = ({ subscription, children }) => {
  return (
    <div className="w-full flex flex-col md:flex-row items-center gap-y-4 md:gap-y-0 md:gap-x-8 lg:gap-x-14 pb-10 border-b md:pb-0 md:border-none">
      {/* Manage Billing Plans*/}
      <div className="flex-grow w-full md:max-w-sm">
        <div className="bg-blue-50 rounded-md p-8 gap-y-6 w-full flex flex-col items-center">
          <div className="w-full flex flex-col items-start">
            {/* Pricing */}
            <div className="flex mb-4 gap-x-2 whitespace-nowrap">
              <p className="text-xl font-semibold">
                {`${subscription?.plan?.currency?.symbol}${subscription?.plan?.amount / 100}`}
              </p>
              <p className="text-sm flex items-end">
                / paid {subscription?.plan?.interval === PlanIntervals.YEAR ? "yearly" : "monthly"}
              </p>
            </div>

            {/* Partner */}
            <div className="flex items-center gap-x-2 cursor-default">
              <p className="font-semibold">Partner</p>
              <p className="py-0.5 px-4 bg-gray-50 border border-black rounded-xl text-xs">{subscription?.partner}</p>
            </div>
          </div>
          {/* Management Links */}
          {children}
        </div>
      </div>

      {/* Status */}
      <div className="flex-grow w-full md:max-w-[200px]">
        <p className="font-semibold mb-1 md:mb-4">Status</p>
        <div className="flex items-center">
          {subscription?.partner_status === "trialing" ? (
            <span
              className={classNames(
                "py-1 px-3 rounded-md text-xs whitespace-nowrap cursor-default",
                "bg-green-500 text-white"
              )}
            >
              Trial
            </span>
          ) : (
            <div
              className={classNames(
                "py-1 px-3 rounded-md text-xs whitespace-nowrap cursor-default",
                subscription?.status === SubscriptionStatus.ACTIVE && "bg-green-500 text-white",
                subscription?.status === SubscriptionStatus.CANCELED && "bg-red-500 text-white",
                subscription?.status === SubscriptionStatus.PAYMENT_PENDING && "bg-yellow-300 text-black"
              )}
            >
              {formatSubscriptionStatus(subscription?.status)}
            </div>
          )}
        </div>
      </div>

      {/* Last Payment Date */}
      <div className="flex-grow w-full md:max-w-[200px]">
        <p className="font-semibold md:mb-4">Last Payment</p>
        <p>
          {subscription?.partner_status === "trialing"
            ? "-- -- --"
            : dayjs(subscription?.current_period_start).format("MMM DD, YYYY")}
        </p>
      </div>

      {/* Next Payment Date */}
      <div className="flex-grow w-full md:max-w-[200px]">
        <p className="font-semibold md:mb-4">Next Payment</p>
        <p>
          {subscription?.status === SubscriptionStatus.CANCELED
            ? "-- -- --"
            : dayjs(subscription?.current_period_end).format("MMM DD, YYYY")}
        </p>
      </div>
    </div>
  );
};

export default SubscriptionInfo;
