import "./auth.scss";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import LoginMeta from "./login/LoginMeta";
import LoginPanelInfo from "./login/LoginPanelInfo";
import LoginPanelSignin from "./login/LoginPanelSignin";

GoogleAuth.initialize({
  clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  scopes: ["profile", "email"],
  grantOfflineAccess: true,
});

export const Loginpage = () => {
  const isNative = Capacitor.isNativePlatform();
  return (
    <div className={classNames("authpage", isNative && "authpage--native")}>
      <LoginMeta />
      <div className="divideauthpage">
        <LoginPanelInfo />
        <LoginPanelSignin />
      </div>
    </div>
  );
};
