import { useState } from "react";
import { useSelector } from "react-redux";
import { AccountType } from "../../../types/accountTypes";
import { XMarkIcon, MagnifyingGlassIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { Dropdown } from "../../Dropdown";
import ManageLabelsModal from "./manageLabels";
import usePortfolio from "../../../hooks/usePortfolio";

interface AccountLabelDropdownProps {
  labelTitle: string | undefined;
  account: AccountType;
  handleSaveLabel: (accountIds: string[], labelId: string) => void;
}

const AccountLabelDropdown: React.FC<AccountLabelDropdownProps> = ({ labelTitle, account, handleSaveLabel }) => {
  const labels = useSelector((state: any) => state.portfolios.labels);
  const [targetLabel, setTargetLabel] = useState<string>("");
  const [isChoosen, setIsChoosen] = useState<any>({});
  const [manageLabels, setManageLabels] = useState<boolean>(false);
  const { handleAccountLabel } = usePortfolio();

  const removeAccountLabel = () => {
    handleAccountLabel(account.id, null);
  };

  const handleCloseNewLabelsModal = () => {
    setManageLabels(false);
  };

  const handleAccountLabelChange = () => {
    if (isChoosen.id) {
      handleAccountLabel(account.id, isChoosen.id);
      setIsChoosen({});
    }
    return;
  };

  return (
    <>
      {manageLabels && (
        <ManageLabelsModal
          labels={labels}
          modalIsOpen={manageLabels}
          closeModal={handleCloseNewLabelsModal}
          handleSaveLabel={handleSaveLabel}
        />
      )}
      {labelTitle ? (
        <div className="flex items-center rounded-2xl bg-gray-100 py-2 px-4 cursor-default">
          <span className="text-xs capitalize">{labelTitle}</span>
          <button onClick={removeAccountLabel} className="ml-2 hover:text-red-500">
            <XMarkIcon className="h-4 w-4" />
          </button>
        </div>
      ) : (
        <Dropdown
          buttonContent={<PlusCircleIcon className="w-6 h-6 text-gray-500 hover:text-blue-700" />}
          contentClassName="rounded-md mt-2 mr-3 md:mr-0"
          closeOnClick={false}
        >
          <div className="w-56 bg-white shadow-lg rounded-md px-4 py-5 flex flex-col border border-solid border-gray-100">
            <div className="relative flex flex-col justify-end gap-y-1 mb-3">
              <input
                className="w-full p-2 border border-gray-200 rounded-md mb-1"
                maxLength={20}
                placeholder="Enter or search Label"
                onChange={(e) => setTargetLabel(e.target.value)}
              />
              <MagnifyingGlassIcon className="h-4 w-4 text-gray-500 absolute right-2 top-2.5 transform" />
              <span className="w-full text-right text-xs text-gray-500">{targetLabel?.length}/20</span>
            </div>

            {labels.map((option: any, i: number) => (
              <div
                key={i}
                className="p-2 rounded-md flex items-center gap-x-2 cursor-pointer hover:bg-gray-100 capitalize"
                onClick={() => handleSaveLabel([account.id], option.id)}
              >
                <span className="w-5 h-5 text-gray-500">
                  {option.name === "Unlabeled" ? <PlusCircleIcon /> : <PlusCircleIcon />}
                </span>
                {option.name}
              </div>
            ))}

            <div className="flex items-center justify-center gap-x-2 text-xs font-medium">
              <button
                className="border border-gray-100 rounded-md px-2 py-1 rounded-md flex items-center gap-x-2 cursor-pointer hover:bg-gray-100 capitalize"
                onClick={() => setManageLabels(true)}
              >
                Manage
              </button>

              <button
                className="border border-gray-100 rounded-md px-2 py-1 rounded-md flex items-center gap-x-2 cursor-pointer hover:bg-gray-100 capitalize"
                onClick={handleAccountLabelChange}
              >
                Save
              </button>
            </div>
          </div>
        </Dropdown>
      )}
    </>
  );
};

export default AccountLabelDropdown;
