import "./auth.scss";
import { Capacitor } from "@capacitor/core";
import classNames from "classnames";
import SignupMeta from "./signup/SignupMeta";
import SignupPanelInfo from "./signup/SignupPanelInfo";
import SignupPanelSignin from "./signup/SignupPanelSignin";

export const Signuppage = () => {
  const isNative = Capacitor.isNativePlatform();
  return (
    <div className={classNames("authpage", isNative && "authpage--native")}>
      <SignupMeta />
      <div className="divideauthpage">
        <SignupPanelInfo />
        <SignupPanelSignin />
      </div>
    </div>
  );
};
