import { Fragment, useEffect, useMemo, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { ReactComponent as Cancel } from "../../assets/svg/cancel-square.svg";
import { ReactComponent as Check } from "../../assets/svg/check-square.svg";
import CustomAsset from "../../assets/svg/custom-color/asset.svg";
import { ReactComponent as Ellipsis } from "../../assets/svg/ellipsis.svg";
import { ReactComponent as Girlwithflower } from "../../assets/svg/girlwithflower.svg";
import { ReactComponent as Plus } from "../../assets/svg/plus-blue.svg";
import { ReactComponent as Shared } from "../../assets/svg/share-grey.svg";
import MainButton from "../../components/buttons/mainButton";
import GrowthRate from "../../components/growthRateV2/growth";
import { AlertIcon } from "../../components/icons/alertcircle";
import AddStockCrypto from "../../components/modals/connectAccount/AddStockCrypto";
import LabelAccountsModal from "../../components/modals/labels/labelAccounts";
import AccountLabelDropdown from "../../components/modals/labels/AccountLabelDropdown";
import { AccountManager } from "../../components/modals/portfolio/AccountManager";
import { lessThanTimeAgo } from "../../components/utilities/utilityFunctions";
import { useAddAccountModalToggle } from "../../graphql/general-store.gql-hook";
import useAccountConnect from "../../hooks/useAccountConnect";
import usePortfolio from "../../hooks/usePortfolio";
import { ConvertNumberCur, forMatterNumber } from "../../services/utilty";
import { AccountType, CreateHoldingInput, Holding, Partners, UpdateHoldingInput } from "../../types/accountTypes";
import { PageTypes } from "../../types/pageTypes";
import { CollaboratorType, LabelsShared } from "../../types/userTypes";
import { lastSyncTime } from "../../components/modals/viewassets";
import { ChevronDownIcon, PencilIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { Dropdown } from "../../components/Dropdown";
import "./assets-v2.scss";

interface IIndividualTable {
  institution: any; // TODO: needs to be generic since this refers to classes and labels as well
  accounts: any;
  closedInstitutions: any[];
  setClosedInstitutions: (closedInstitutions: any) => void;
  page: string;
  handleRemoveAccountLabel: (id: string) => Promise<void>;
  handleSaveLabel: (accountIds: string[], labelId: string) => void;
}
export const IndividualTable = ({
  institution,
  accounts,
  closedInstitutions,
  setClosedInstitutions,
  page,
  handleRemoveAccountLabel,
  handleSaveLabel,
}: IIndividualTable) => {
  institution = institution?.id ? institution : { ...institution, id: institution.slug };
  closedInstitutions = closedInstitutions?.[0]?.id
    ? closedInstitutions
    : closedInstitutions.map((i) => ({ ...i, id: i.slug }));
  const { activePortfolioId, collaborators } = useSelector((state: any) => state.portfolios);
  const [isAccountOpen, setIsAccountOpen] = useState<boolean>(true);
  const [activeInput, setActiveInput] = useState<boolean>(false);
  const [isChoosen, setIsChoosen] = useState<any>({});
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [labelModal, setLabelModal] = useState<boolean>(false);

  useEffect(() => {
    setIsAccountOpen(!closedInstitutions.map((i) => i.id).includes(institution.id));
  }, [closedInstitutions, institution.id]);

  const handleTableCollapse = () => {
    setClosedInstitutions((prevClosedInstitutions: any[]) => {
      prevClosedInstitutions = prevClosedInstitutions?.[0]?.id
        ? prevClosedInstitutions
        : prevClosedInstitutions.map((i) => ({ ...i, id: i.slug }));
      const isCurrentlyOpen = !prevClosedInstitutions.map((i) => i.id).includes(institution.id);
      const newClosedInstitutions = isCurrentlyOpen
        ? prevClosedInstitutions.concat(institution)
        : prevClosedInstitutions.filter((i) => i.id !== institution.id);

      setIsAccountOpen(!isCurrentlyOpen);
      return newClosedInstitutions;
    });
  };

  const closeLabelModal = () => {
    setShowDetailsModal(false);
    setLabelModal(false);
  };

  const getCollaboratorCount = useMemo(() => {
    let count = 0;
    collaborators.forEach((el: CollaboratorType) =>
      el.shared_labels.forEach((v: LabelsShared) => v.label_id === institution.id && count++)
    );
    return count;
  }, [institution, collaborators]);

  return (
    <div className="table">
      {(showDetailsModal || labelModal) && (
        <LabelAccountsModal
          closeModal={closeLabelModal}
          isOpen={showDetailsModal || labelModal}
          closeManageLabelModal={() => null}
          defaultLabel={{
            label: institution.name,
            id: institution.id,
          }}
          handleSaveLabel={handleSaveLabel}
        />
      )}

      {/* Collapsed Row */}
      <div
        className={classNames("flex justify-between items-center", isAccountOpen ? "border-none" : "border border-1")}
      >
        <button className="font-semibold text-p1 flex items-center p-4" onClick={handleTableCollapse}>
          {page === PageTypes.labels && getCollaboratorCount > 0 && (
            <span className="mr-2" data-for="label-shared" data-tip={getCollaboratorCount}>
              <Shared />
              <ReactTooltip
                id="label-shared"
                place="bottom"
                getContent={(count) => ` Shared with ${count} collaborator${+count > 1 ? "s" : ""}`}
              />
            </span>
          )}
          {page === PageTypes.institutions && (
            <img
              src={institution.logo ? `data:image/jpeg;base64,${institution.logo}` : CustomAsset}
              alt="institution logo"
              className="mr-2 bg-blue-100 rounded-lg p-1 h-8 w-8"
            />
          )}
          {institution.name}
          <span className={`ml-2 transition-all ${isAccountOpen ? "rotate-180" : ""}`}>
            <ChevronDownIcon className="w-5 h-5" />
          </span>
        </button>

        <div className={classNames("w-80 p-5 flex items-start justify-end", !isAccountOpen && "border-1 border-l")}>
          {!isAccountOpen && institution.balances.length > 0 && (
            <div className="w-full flex justify-between">
              <div className="flex items-center text-p1 text-type font-semibold">
                TOTAL{" "}
                <span className="ml-2 tooltipwidth" data-tip data-for="knowMoreTip">
                  <AlertIcon />
                  <ReactTooltip id="knowMoreTip" place="bottom" effect="solid" className="text-xs">
                    This is a breakdown of your accounts in their default currencies.
                  </ReactTooltip>
                </span>
              </div>
              <div>
                {institution.balances.map((balance: any, i: number) => (
                  <p className="ml-8 text-p1 text-type font-semibold text-right" key={i}>
                    {ConvertNumberCur(balance?.value, balance.symbol)}
                  </p>
                ))}
              </div>
            </div>
          )}

          {page === "labels" && (
            <div className="ml-4 relative">
              <Dropdown
                buttonContent={
                  <button className="flex">
                    <Ellipsis />
                  </button>
                }
              >
                <div className="w-32 bg-white shadow-lg rounded-md p-2 flex flex-col border border-solid border-gray-100">
                  <button
                    className="flex items-center gap-x-3 hover:bg-gray-100 p-2 rounded-md cursor-pointer"
                    onClick={() => setShowDetailsModal(true)}
                  >
                    <PencilIcon className="w-4 h-4" />
                    <span>Details</span>
                  </button>
                </div>
              </Dropdown>
            </div>
          )}
        </div>
      </div>

      {/* Expanded Row */}
      {isAccountOpen &&
        (accounts.length > 0 ? (
          accounts.map((account: AccountType) => (
            <div className="border-r border-l border-t last:border-b">
              <TableBody
                key={account.id}
                account={account}
                activePortfolioId={activePortfolioId}
                setActiveInput={setActiveInput}
                activeInput={activeInput}
                isChoosen={isChoosen}
                setIsChoosen={setIsChoosen}
                page={page}
                handleRemoveAccountLabel={handleRemoveAccountLabel}
                handleSaveLabel={handleSaveLabel}
              />
            </div>
          ))
        ) : (
          <div className="w-full flex justify-center py-5">
            <button className="font-bold flex items-center text-p2 text-primary" onClick={() => setLabelModal(true)}>
              <Plus />
              Add account
            </button>
          </div>
        ))}

      {/* Bottom Total Row */}
      {isAccountOpen && institution.balances.length > 0 && (
        <div className="w-full border-t flex justify-end">
          <div className="w-80 p-5 flex justify-between border-l border-r border-b">
            <div className="relative flex items-center justify-between text-p1 text-type font-semibold">
              TOTAL{" "}
              <span className="ml-2 tooltipwidth" data-tip data-for="knowMoreInfo">
                <AlertIcon />
                <ReactTooltip id="knowMoreInfo" place="bottom" effect="solid" className="text-xs">
                  This is a breakdown of your accounts in their default currencies.
                </ReactTooltip>
              </span>
            </div>
            <div>
              {institution.balances.map((balance: any, i: number) => (
                <p className="ml-8 text-p1 text-type font-semibold text-right" key={i}>
                  {ConvertNumberCur(balance?.value, balance.symbol)}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

interface ITableBody {
  account: AccountType;
  activePortfolioId: string;
  activeInput: boolean;
  setActiveInput: React.Dispatch<React.SetStateAction<boolean>>;
  setIsChoosen: React.Dispatch<React.SetStateAction<AccountType | null>>;
  isChoosen: AccountType;
  page: string;
  handleRemoveAccountLabel: (id: string) => Promise<void>;
  handleSaveLabel: (accountIds: string[], labelId: string) => void;
}

const TableBody = ({
  account,
  activePortfolioId,
  activeInput,
  setActiveInput,
  setIsChoosen,
  isChoosen,
  page,
  handleRemoveAccountLabel,
  handleSaveLabel,
}: ITableBody) => {
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const { handleGetPortfolioData, handleAccountName } = usePortfolio();
  const [addStockCryptoType, setAddStockCryptoType] = useState<"stock" | "crypto" | "">("");
  const { handleAddHolding, handleEditHolding } = useAccountConnect(addStockCryptoType);
  const [disableClick, setDisableClick] = useState<boolean>(true);
  const [newAccountName, setNewAccountName] = useState<string>(account?.display_name);
  const growth = ((account.balance.value - account.balance_original.value) / account.balance_original.value) * 100;
  const [addStockCryptoModal, setAddStockCryptoModal] = useState<boolean>(false);
  const [showHoldings, setShowHoldings] = useState<boolean>(false);
  const [activeHolding, setActiveHolding] = useState<Holding | null>(null);

  const handleBlur = () => {
    setActiveInput(false);
    setDisableClick(true);
  };

  const handleFocus = () => {
    setDisableClick(false);
    setIsChoosen(account);
    setActiveInput(true);
  };

  const handleAccountRename = async () => {
    setDisableClick(true);
    await handleAccountName(isChoosen?.id, newAccountName, activePortfolioId);
    setActiveInput(false);
  };

  const handleCancelEditName = () => {
    setDisableClick(true);
    setNewAccountName(account?.display_name ? account?.display_name : account?.name);
    setIsChoosen(null);
  };

  const openHoldingModal = (type: "stock" | "crypto") => {
    setAddStockCryptoType(type);
    setAddStockCryptoModal(true);
  };

  const addHolding = async (values: CreateHoldingInput) => {
    const updatedHoldingData: UpdateHoldingInput = {
      account_id: account.id,
      holdings: [{ cost: values.cost, quantity: values.quantity, id: activeHolding?.id ?? "" }],
    };

    const action = activeHolding ? handleEditHolding(updatedHoldingData) : handleAddHolding(values);

    await action;
    setAddStockCryptoModal(false);
    setAddStockCryptoType("");
    handleGetPortfolioData(activePortfolioId, false);
    setActiveHolding(null);
  };

  return (
    <div>
      {showDetailsModal && (
        <AccountManager
          isOpen={showDetailsModal}
          closeModal={() => setShowDetailsModal(false)}
          account={account}
          handleAddCrypto={() => openHoldingModal("crypto")}
          handleAddStocks={() => openHoldingModal("stock")}
          handleRemoveAccountLabel={handleRemoveAccountLabel}
          handleSaveLabel={handleSaveLabel}
        />
      )}

      {addStockCryptoModal && (
        <AddStockCrypto
          modalOpen={addStockCryptoModal}
          closeModal={() => {
            setAddStockCryptoModal(false);
            setAddStockCryptoType("");
            setActiveHolding(null);
          }}
          submit={addHolding}
          type={addStockCryptoType}
          accountId={account.id}
          holding={activeHolding}
        />
      )}

      <div
        id={account.id}
        className={classNames(
          `table-account ${page === PageTypes.labels ? "labels-grid" : ""}
          ${lessThanTimeAgo(account.updated_at, { duration: 1, unit: "minutes" }) ? "highlight" : ""}`
        )}
      >
        {account.holdings.length > 0 ? (
          <button
            onClick={() => setShowHoldings(!showHoldings)}
            className={`flex justify-center transition-all ${showHoldings ? "rotate-180" : ""}`}
          >
            <ChevronDownIcon className="w-5 h-5" />
          </button>
        ) : (
          <span></span>
        )}
        <div className="edit-name flex items-center">
          {page !== PageTypes.institutions && (
            <img
              src={account.institution.logo ? `data:image/jpeg;base64,${account.institution.logo}` : CustomAsset}
              alt="institution logo"
              className="mr-2 bg-blue-100 rounded-lg p-1 h-8 w-8"
            />
          )}

          <div className="w-full">
            <div className={`edit-label w-full flex items-center text-p2 text-type`}>
              {newAccountName !== account?.display_name && (
                <del className="text-left inline pr-2">
                  {account?.display_name ? account?.display_name : account?.name}
                </del>
              )}
              <OutsideClickHandler onOutsideClick={handleBlur} disabled={disableClick}>
                <div
                  className={`input-field flex items-center max-w-full ${
                    activeInput && isChoosen?.id === account.id ? "border-blue-500" : ""
                  }`}
                >
                  <input
                    type="text"
                    value={newAccountName}
                    onChange={(e: any) => setNewAccountName(e.target.value)}
                    onFocus={handleFocus}
                    placeholder={account?.name}
                    style={{ width: `${+newAccountName.length + 2}ch` }}
                    className={`text-p2 ${
                      activeInput && newAccountName !== account?.display_name ? "input-active" : ""
                    }`}
                  />

                  {newAccountName !== account?.display_name && (
                    <Fragment>
                      <button onClick={handleAccountRename}>
                        <Check />
                      </button>
                      <button onClick={handleCancelEditName}>
                        <Cancel />
                      </button>
                    </Fragment>
                  )}
                </div>
              </OutsideClickHandler>
            </div>
            <p className="text-p3 text-type-100 pl-2">{account.number}</p>
          </div>
        </div>
        {page !== PageTypes.labels && (
          <div className="flex justify-center relative">
            <AccountLabelDropdown
              labelTitle={account.label?.name}
              account={account}
              handleSaveLabel={handleSaveLabel}
            />
          </div>
        )}
        <div className="text-center text-p3 text-status-good">
          <GrowthRate growthRate={growth === Infinity ? 0 : growth} />
        </div>

        <div className="text-right">
          <p>{ConvertNumberCur(account.balance.value, account.balance.symbol)}</p>
          <small className="text-p4 text-type-200">
            {account.partner === Partners.CUSTOM ? "Last updated" : "Last sync"} {lastSyncTime(account.synced_at)}
          </small>
        </div>
        <button className="flex justify-center p-2 hover:text-blue-700" onClick={() => setShowDetailsModal(true)}>
          <PencilIcon className="w-5 h-5" />
        </button>
      </div>

      {account.holdings.length > 0 && showHoldings && (
        <Fragment>
          <div className="table-grid row-header">
            <p>Stock</p>
            <p>Quantity</p>
            <p>Cost</p>
            <p>Price</p>
            <p>Change</p>
            <p>Change {"(%)"}</p>
            <p>Value</p>
          </div>
          {account.holdings.map((holding: Holding, i: number) => (
            <div
              className="table-grid row-content click-item"
              key={i}
              onClick={() => {
                setActiveHolding(holding);
                if (account.partner === Partners.COIN_CAP) {
                  openHoldingModal("crypto");
                } else if (account.partner === Partners.MARKET_STACK) {
                  openHoldingModal("stock");
                }
              }}
            >
              <p>{holding.name}</p>
              <p>{forMatterNumber(holding.quantity)}</p>
              <p>{"$" + forMatterNumber(holding.price)}</p>
              <p>{ConvertNumberCur(holding.price, holding.currency)}</p>
              <p>
                {holding.value_original && (
                  <GrowthRate growthRate={holding.value - holding.value_original} hidePercentage />
                )}
              </p>
              <p>
                {holding.value_original && (
                  <GrowthRate growthRate={((holding.value - holding.value_original) / holding.value_original) * 100} />
                )}
              </p>

              <p>{ConvertNumberCur(holding.value, holding.currency)}</p>
            </div>
          ))}
        </Fragment>
      )}

      {account.partner === Partners.COIN_CAP && (
        <button
          className="text-primary font-semibold text-p2 flex items-center p-3 ml-5 click-item"
          onClick={() => {
            openHoldingModal("crypto");
          }}
        >
          <Plus />
          Add Crypto
        </button>
      )}

      {account.partner === Partners.MARKET_STACK && (
        <button
          className="text-primary font-semibold text-p2 flex items-center px-6 py-3 ml-5 click-item"
          onClick={() => {
            openHoldingModal("stock");
          }}
        >
          <Plus />
          Add Stock
        </button>
      )}
    </div>
  );
};

export const EmptyAssets = () => {
  const { 1: openAccountModal } = useAddAccountModalToggle();

  return (
    <div className="emptyassets">
      <div className="emptyassets-inner placecenter space">
        <Girlwithflower />
        <p className="h3-size font-semibold ready text-center">Ready to connect your accounts?</p>
        <p className="assets text-p1  text-center">
          You don't have any thing in your portfolio just yet. Connect your bank accounts, crypto, stocks and more.
        </p>

        <MainButton click={() => openAccountModal(true)} type="primary" size="big" id="add assets" extraClasses="flex">
          + Connect
        </MainButton>
      </div>
    </div>
  );
};

interface IAssetsMobile {
  classes: any;
  handleRemoveAccountLabel: (id: string) => Promise<void>;
  handleSaveLabel: (accountIds: string[], labelId: string) => void;
}
export const AssetsMobile: React.FC<IAssetsMobile> = ({ classes, handleRemoveAccountLabel, handleSaveLabel }) => {
  const [showDetails, setShowDetails] = useState<string>("");
  const [activeAccountIndex, setActiveAccountIndex] = useState<number | null>(null);
  const [modal, setModal] = useState<string>("");
  const activePortfolioId = useSelector((state: any) => state.portfolios.activePortfolioId);
  const { handleGetPortfolioData } = usePortfolio();
  const [addStockCryptoType, setAddStockCryptoType] = useState<"stock" | "crypto" | "">("");
  const [addStockCryptoModal, setAddStockCryptoModal] = useState<boolean>(false);
  const { handleAddHolding } = useAccountConnect();

  const openHoldingModal = (type: "stock" | "crypto") => {
    setAddStockCryptoType(type);
    setAddStockCryptoModal(true);
  };

  const addHolding = async (values: CreateHoldingInput) => {
    await handleAddHolding(values);
    setAddStockCryptoModal(false);
    setAddStockCryptoType("");
    handleGetPortfolioData(activePortfolioId, false);
  };

  return (
    <>
      {classes.map((assetClass: any, index: number) => (
        <div className="mobile-assets-list my-2" key={index}>
          <div className="">
            <div
              className="p-3 flex justify-between click-item"
              onClick={() => {
                setActiveAccountIndex(index);
                if (showDetails === assetClass.id) {
                  setShowDetails("");
                } else {
                  setShowDetails(assetClass.id);
                }
              }}
            >
              <div>
                <div>
                  <p className="font-semibold text-p2 txt2s3">{assetClass.name}</p>
                </div>
              </div>
            </div>

            {activeAccountIndex === index && <hr />}

            {activeAccountIndex === index && (
              <>
                {assetClass.accounts.map((account: AccountType, index: number) => (
                  <Fragment key={account.id}>
                    {modal === `${index}` && (
                      <AccountManager
                        isOpen={modal === `${index}`}
                        closeModal={() => setModal("")}
                        account={assetClass?.accounts[index]}
                        handleAddCrypto={() => openHoldingModal("crypto")}
                        handleAddStocks={() => openHoldingModal("stock")}
                        handleRemoveAccountLabel={handleRemoveAccountLabel}
                        handleSaveLabel={handleSaveLabel}
                      />
                    )}

                    {addStockCryptoModal && (
                      <AddStockCrypto
                        modalOpen={addStockCryptoModal}
                        closeModal={() => {
                          setAddStockCryptoModal(false);
                          setAddStockCryptoType("");
                        }}
                        submit={addHolding}
                        type={addStockCryptoType}
                        accountId={account.id}
                      />
                    )}

                    <div className="row flex justify-between pb-2 px-1" onClick={() => setModal(`${index}`)}>
                      <div>
                        <div className="mr-2">
                          {/* <img className="bg-blue-100 rounded-lg p-1 h-7 w-7" src={asset.institutionImage} alt="" /> */}
                        </div>

                        <div>
                          <p className="font-medium text-p3 txt2s3 vv4">{account.institution.name}</p>
                          <small>
                            {account.name.length > 15 ? `${account.name.substring(0, 15)}...` : account.name}{" "}
                            {account.label?.name && <span className="ml-1">{account.label.name}</span>}
                          </small>
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="w-17">
                          <p className="font-semibold text-p3 text-right">
                            {ConvertNumberCur(account?.balance?.value, account?.balance?.symbol)}
                          </p>
                          <p className="text-right text-p4 l-sync">{lastSyncTime(account.synced_at)}</p>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))}
              </>
            )}
            {activeAccountIndex === index && (
              <div className="flex items-start justify-between table-total">
                <div className="flex items-center">
                  Total{" "}
                  <span className="ml-2 cursor-pointer" data-tip data-for="knowMoreTip">
                    <AlertIcon />
                    <ReactTooltip id="knowMoreTip" place="bottom" effect="solid" className="text-xs">
                      This is a breakdown of your accounts in their default currencies.
                    </ReactTooltip>
                  </span>
                </div>
                <div className="flex flex-col items-end">
                  {assetClass.balances.map(
                    (
                      amount: {
                        currency: string;
                        symbol: string;
                        value: number;
                      },
                      index: number
                    ) => (
                      <div className="mb-2" key={index}>
                        {ConvertNumberCur(amount.value, amount.symbol)}
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
};
